import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: 'ui-table-refresh-tool',
  templateUrl: './ui-table-refresh-tool.component.html',
  styleUrls: [ './ui-table-refresh-tool.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-table-refresh-tool',
  },
})
export class UiTableRefreshTool {
  @Input('loading') public loading = false;
  @Input() public text: string = '';
}
