export class TimeRange {
  firstTimestamp: number;

  lastTimestamp: number;
}

export interface IHoursMinutes {
  hour: number; // 0-23
  minute: number; // 0-59
}

export class TimeUtil {
  public static readonly secondDuration = 1000 /* millisecons */;

  public static readonly minuteDuration = 60 /* seconds */ * 1000 /* millisecons */;

  public static readonly hourDuration = 60 /* minutes */ * 60 /* seconds */ * 1000 /* millisecons */;

  public static readonly dayDuration = 24 /*  hours */ * 60 /* minutes */ * 60 /* seconds */ * 1000 /* millisecons */;

  public static readonly SIX_MONTHS_MS = 26 * 7 * 24 * 60 * 60 * 1000;

  public static get today(): number {
    return new Date().setHours(0, 0, 0, 0);
  }

  public static get yesterday(): number {
    return TimeUtil.today - TimeUtil.dayDuration;
  }

  public static convertTimestampToDays(timestamp?: number): number {
    if (!timestamp) {
      return null;
    }

    return timestamp / this.dayDuration;
  }

  public static getDateMsValueFromTimestamp(timestamp: number): number {
    // ms value of the DATE without the time value (ex: ms value of 2022-12-13 at 0h 0m 0s 0ms)
    const date = new Date(timestamp);
    date.setHours(0, 0, 0, 0); // keep only the value of the day where time is 00h:00m:00s:00ms
    return date.getTime(); // return the value of this date in ms
  }

  public static getTimeMsValueFromTimestamp(timestamp: number): number {
    // ms value of the TIME without the date value (ex: ms value of 5h 30min)
    const date = new Date(timestamp);
    const hoursMs = date.getHours() * this.hourDuration; // 0 to 23 * ms value of an Hour
    const minutesMs = date.getMinutes() * this.minuteDuration; // 0 to 59 * ms value of a Minute
    const secondsMs = date.getSeconds() * this.secondDuration; // 0 to 59 * ms value of a Second
    const milliseconds = date.getMilliseconds(); // 0 to 999
    return hoursMs + minutesMs + secondsMs + milliseconds;;
  }

  public static convertMillisecondsToHours(milliseconds: number): number {
    return Math.floor((milliseconds / this.hourDuration) % 24); // 0-23 hours
  }

  public static convertMillisecondsToMinutes(milliseconds: number): number {
    return Math.floor((milliseconds / this.minuteDuration) % 60); // 0-59 minutes
  }

  public static getHourMinuteFromMilliseconds(milliseconds: number): IHoursMinutes {
    if (milliseconds > TimeUtil.dayDuration) {
      const date = new Date(milliseconds);
      return {
        hour: date.getHours(),
        minute: date.getMinutes(),
      };
    }
    return {
      hour: TimeUtil.convertMillisecondsToHours(milliseconds),
      minute: TimeUtil.convertMillisecondsToMinutes(milliseconds),
    };
  }

  public static convertHoursToMilliseconds(hours: number): number {
    // ex: 15h * value of an hour in milliseconds
    return hours ? hours * TimeUtil.hourDuration : 0;
  }

  public static convertMinutesToMilliseconds(minutes: number): number {
    // ex: 50min * value of a minute in milliseconds
    return minutes ? minutes * TimeUtil.minuteDuration : 0;
  }

  public static getFirstTimestampOfTheDay(timestamp?: number): string {
    if (!timestamp) {
      timestamp = Date.now();
    }
    const date = new Date(timestamp);
    date.setHours(0, 0, 0, 0);

    return `${date.getTime()}`;
  }

  public static getLastTimestampOfTheDay(timestamp?: number): string {
    if (!timestamp) {
      timestamp = Date.now();
    }
    const date = new Date(timestamp);
    date.setHours(23, 59, 59, 999);

    return `${date.getTime()}`;
  }

  public static getLastWeekTimeRange(currentDate: Date): TimeRange {
    const currentDay = currentDate.getDay();
    const firstday = new Date(currentDate.getTime() - this.dayDuration * (currentDay + 7));
    const lastday = new Date(firstday.getTime() + this.dayDuration * 6);

    const firstTimestampOfTheWeek = this.getFirstTimestampOfTheDay(firstday.getTime());
    const lastTimestampOfTheWeek = this.getLastTimestampOfTheDay(lastday.getTime());

    return {
      firstTimestamp: +firstTimestampOfTheWeek,
      lastTimestamp: +lastTimestampOfTheWeek,
    };
  }

  public static getDateWithFormattedTimezone(timestamp: number): Date {
    const timestampTimezoneOffset = new Date(timestamp).getTimezoneOffset();
    return new Date(timestamp + timestampTimezoneOffset * 60 * 1000);
  }

  public static padZero(value: number | string): string {
    // ex: 1, 2,..., 9 --> 01, 02,..., 09
    const num = value ? Number(value) : 0;
    return num.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  }

  public static getTimestampFromNowAndDays(days = 0): { dateFrom: number; } {
    const dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - days);
    return { dateFrom: dateFrom.getTime() };
  };

  public static getNumberOfDaysBetweenTwoDates(dateFrom: number, dateTo: number, getAbs = true): number {
    const dateFromFirstTimestamp = +this.getFirstTimestampOfTheDay(dateFrom);
    const dateToFirstTimestamp = +this.getFirstTimestampOfTheDay(dateTo);

    const difference = dateToFirstTimestamp - dateFromFirstTimestamp;

    return Math.ceil((getAbs ? Math.abs(difference) : difference )/ this.dayDuration);
  }

  public static getOffset(timeZone: string, dateForOffset: Date) {
    const utcDate = new Date(dateForOffset.toLocaleString('en-US')); //  12:00:00
    const timezoneDate = new Date(dateForOffset.toLocaleString('en-US', { timeZone })); // 08:00:00
    return utcDate.getTime() - timezoneDate.getTime(); // == 12:00:00 - 08:00:00 =  +04:00:00
  }
}
