<div class="main-container">
  <div class="hour-wrapper">
    <ui-select-single
      [options]="hourOptions"
      [showOptions]="false"
      [(value)]="hour"
      displayValueKey="viewValue"
      (valueChange)="setHour($event)"
      placeholder="H"
      [readonly]="readonly"
    >
    </ui-select-single>
  </div>
  <div class="hour-wrapper">
    <ui-select-single
      [options]="minuteOptions"
      [showOptions]="false"
      [(value)]="minute"
      displayValueKey="viewValue"
      (valueChange)="setMinute($event)"
      placeholder="Min"
      [readonly]="readonly"
    >
    </ui-select-single>
  </div>
</div>
