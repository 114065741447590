import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { TextareaTypeEnum } from '../json-textarea/json-textarea.component';

export enum UiTypeOfFormEnum {
  DROPDOWN = 'dropdown',
  TEXT_INPUT = 'textInput',
  NUMBER_INPUT = 'numberInput',
  CHECKBOX = 'checkbox',
  OBJECT_TEXTAREA = 'objectTextarea',
  ARRAY_TEXTAREA = 'arrayTextarea',
  MULTI_SELECT = 'multiSelect'
}

export interface DatasourceFormInterface {
  label: ITranslatedField;
  description: ITranslatedField;
  required: boolean;
  typeOfForm: UiTypeOfFormEnum;
  controlName: string;
  group: UntypedFormGroup;
  values?: any[];
  initialValue?: { label: string; value: string | number };
}

@Component({
  selector: 'dynamic-form-template',
  templateUrl: './dynamic-form-template.component.html',
})
export class DynamicFormTemplateComponent {
  public uiTypeOfFormEnum = UiTypeOfFormEnum;
  public textareaTypeEnum = TextareaTypeEnum;

  @Input() formConfig: DatasourceFormInterface;
  @Input() readonly = false;

  @Output() valueSelection = new EventEmitter();

  public get isInError(): boolean {
    const control = this.formConfig.group.get(this.formConfig.controlName);

    return control.invalid && control.touched;
  }

  public onValueSelection(event: { controlName: string; value: string }): void {
    this.valueSelection.emit(event);
  }
}
