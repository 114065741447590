import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'projects/@common/modules/session/session.service';
import { StorageService } from 'projects/@common/services/storage.service';

@Component({
  template: '<app-loader class="private-layout--loader"></app-loader>',
})
export class AuthnRedirectComponent implements OnInit {
  b64redirectUrl: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private storageService: StorageService,
    @Inject('REDIRECT_AFTER_LOGIN') private readonly redirectAfterLogin: string,
    @Inject('ASSERT_ORGANIZATION_TAG') private readonly assertOrganizationTag: string
  ) {
  }

  async ngOnInit() {
    const { code } = this.route.snapshot.queryParams;
    const { state } = this.route.snapshot.queryParams;
    await this.login(code, state);
  }

  private async login(code: string, state: string) {
    const parsedState = JSON.parse(state);

    if (!parsedState.email) {
      this.router.navigate(['signin']);
    }
    const redirectUrl = `${window.location.origin}/login/office`;

    await this.sessionService.login(
      {
        code,
        email: parsedState.email,
        redirectUrl,
      },
      { assertOrganizationTag: this.assertOrganizationTag }
    ).catch(() => {
      this.router.navigate(['/signin']);
    });


    const redirect = this.storageService.getLocal('redirect');
    if (redirect) {
      localStorage.removeItem('redirect');
      this.router.navigateByUrl(redirect);
    } else {
      this.router.navigate([this.redirectAfterLogin]);
    }
  }
}
