import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild, ViewEncapsulation } from "@angular/core";

@Component({
  selector: '[detail]',
  templateUrl: './detail.component.html',
  styleUrls: [ './detail.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'detail',
  },
})
export class Detail implements AfterViewInit, OnDestroy {
  @ViewChild("headerRef") headerRef: ElementRef;

  @Input() useStickyTop: boolean = false;

  public observer: IntersectionObserver;
  public isSticky: boolean = false;

  constructor() { }

  ngAfterViewInit() {
    if (this.useStickyTop) {
      this.observer = new IntersectionObserver(this.handleStickyTop.bind(this), { threshold: 1.0 });
      this.observer.observe(this.headerRef.nativeElement);
    }
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private handleStickyTop(entries: IntersectionObserverEntry[]) {
    if (!this.isSticky && !entries[0].isIntersecting) {
      this.isSticky = true;
      this.headerRef.nativeElement.classList.add('sticky-top');
    } else {
      this.isSticky = false;
      this.headerRef.nativeElement.classList.remove('sticky-top');
    }
  }
}
