import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { SessionService } from 'projects/@common/modules/session/session.service';

@Component({
  template: '<app-loader class="private-layout--loader"></app-loader>',
})
export class SignupRedirectComponent implements OnInit {
  b64redirectUrl: string;

  constructor (
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private notice: NoticeService,
    private i18n: I18nService,
    private router: Router,
    @Inject('REDIRECT_AFTER_LOGIN') private readonly redirectAfterLogin: string
  ) { }

  async ngOnInit() {
    const { code } = this.route.snapshot.queryParams;
    const { state } = this.route.snapshot.queryParams;
    const { idpType } = JSON.parse(state);
    await this.redeem(code, state, idpType as any);
  }

  private async redeem(code: string, state: string, idpType: 'google' | 'azuread') {
    this.sessionService.signup({ code, state, idpType })
      .then((response) => {
        if (response.adminConsentUrl) {
          window.location.href = response.adminConsentUrl;
        } else {
          this.notice.notifyUser(new Notice('Signup successful', NoticeLevels.SUCCESS));
          this.router.navigate([ this.redirectAfterLogin ]);
        }
      })
      .catch((err) => {
        if (err.status == 406) {
          this.handleUserIsUnknown();
        } else {
          console.error(err);
          this.notice.notifyUser(new Notice('Something went wrong, contact mondata for help', NoticeLevels.ERROR));
        }
      });
  }

  private handleUserIsUnknown() {
    const notice = new Notice(
      this.i18n.translate('signup.user.unknown'),
      NoticeLevels.ERROR
    );
    this.notice.notifyUser(notice);
    this.router.navigate([ '/signup' ]);
  }
}
