import { Injectable } from '@angular/core';
import { BaseSidebar, navigationElement } from '@common/modules/layout/definitions/base-sidebar.service';
import { DisplayRequirements } from 'projects/@common/modules/display/display.service';


@Injectable()
export class AdmToolsSidebarService extends BaseSidebar {
  private elements: navigationElement[] = [
    { name: 'MD.TOOLS' },
    {
      rootAction: true,
      name: 'sidebar.navigation.tools.parameters.title',
      route: '/tools/parameters',
      icon: 'sidebar/icon_sidebar_config',
      permissions: [ 'can_list_parameters' ],
    },
    {
      rootAction: true,
      name: 'sidebar.navigation.tools.secrets.title',
      icon: 'icon_menu_secret_key',
      route: '/tools/secrets',
      permissions: [ 'can_list_secrets' ],
    },
  ];

  constructor() {
    super();
  }

  public unsubscribe() {
  }

  public getElements() {
    return this.elements;
  }

  public getRequirements(): DisplayRequirements {
    return {};
  }
}
