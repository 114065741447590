<input
  #durationInput
  ui-input
  class="duration-input"
  [ngClass]="{
    'fullwidth': fullWidth
  }"
  type="text"
  placeholder="0h"
  minLength="0"
  maxLength="8"
  autocomplete="off"
  spellcheck="false"
  [disabled]="isDisabled"
  [class.readonly]="isReadonly"
  [readonly]="isReadonly"
  [ngModel]="duration?.viewValue"
  (ngModelChange)="handleInputChange($event)"
  (blur)="handleInputBlur()"
>