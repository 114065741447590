import { ChangeDetectionStrategy, Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import {
  UiResourceTypeBadgeComponent
} from '../ui-resource-type-badge/ui-resource-type-badge.component';
import { GroupTypeEnum, IconUtil, ResourceTypeEnum } from '../../util/icon-util';
import { LanguageEnum } from '../../interfaces/ILanguage';

export enum AvatarResourceSize {
  SMALL = 'small',
  NORMAL = 'normal'
}

@Component({
  selector: 'ui-avatar-resource',
  templateUrl: './ui-avatar-resource.component.html',
  styleUrls: [ './ui-avatar-resource.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ui-avatar-resource',
  },
})
export class UiAvatarResource implements OnChanges {
  @Input() public resourceType: ResourceTypeEnum;

  @Input() public groupType: GroupTypeEnum;

  @Input() public size = AvatarResourceSize.NORMAL;

  @Input() public locale: LanguageEnum = LanguageEnum.FRENCH;

  public iconSrc = '';

  public iconTooltip = '';

  public avatarResourceSize = AvatarResourceSize;

  public ngOnChanges(): void {
    this.iconSrc = IconUtil.getResourceAvatarIcon({ resourceType: this.resourceType, groupType: this.groupType });

    this.iconTooltip = UiResourceTypeBadgeComponent.getResourceTypeName(this.resourceType, this.groupType);
  }
}
