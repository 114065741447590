<div class="flex-box">
  <label ui-label>{{ label }}</label>
  <ng-content></ng-content>
</div>

<div class="ui-labeled-avatar--container">
  <ng-container *ngIf="user">
    <ng-container *ngTemplateOutlet="avatar"></ng-container>
  </ng-container>

  <div>
    {{ name?.length < 1 ? ('common.na') : name }}
      </div>
  </div>

  <ng-template #avatar>
    <ui-avatar class="ui-labeled-avatar--avatar" [user]="user"></ui-avatar>
  </ng-template>