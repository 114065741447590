<ng-container *ngIf="keyValuePairs?.length; else noContent;">
  <div
    class="flex-box-container"
    *ngFor="let item of keyValuePairs"
  >
    <div
      class="finding-key-cell"
      [title]="item.key"
    >
      <button
        ui-button
        icon="copy"
        size="slim"
        color="listActionFlatGray"
        class="cell-copy-icon"
        [title]="('common.action.copy' | t)"
        (click)="copyToClipboard(item.key)"
      ></button>
      <span class="cell-text">{{item.key}}</span>
    </div>

    <div
      class="finding-value-cell"
      [title]="item.value"
    >
      <button
        ui-button
        icon="copy"
        size="slim"
        color="listActionFlatGray"
        class="cell-copy-icon"
        [title]="('common.action.copy' | t)"
        (click)="copyToClipboard(item.value)"
      ></button>
      <span class="cell-text">{{item.value}}</span>
    </div>

  </div>
</ng-container>

<ng-template #noContent>{{'ui.table-infinite.empty' | t}}</ng-template>