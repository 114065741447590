import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UiAvatarSize } from '../ui-avatar/ui-avatar.component';

@Component({
  selector: 'ui-avatars',
  templateUrl: './ui-avatars.component.html',
  styleUrls: [ './ui-avatars.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ui-avatars',
  },
})
export class UiAvatars implements OnInit {
  @Input() public users: any[];

  @Input() public usersToShowNumber = 3;

  @Input('size') public size: UiAvatarSize = UiAvatarSize.Small;

  public otherUsersNumber: number;

  ngOnInit() {
    this.otherUsersNumber = this.users?.length - this.usersToShowNumber;
  }
}
