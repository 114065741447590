import { Injectable } from "@angular/core";
import { LanguageEnum } from "../interfaces/ILanguage";
import get from 'lodash.get';
import template from 'lodash.template';
import fr from "../assets/i18n/fr.json";
import en from "../assets/i18n/en.json";

type TranslateParams = {
  locale: LanguageEnum,
  [prop: string]: any
};

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  private collection = {
    en,
    fr,
  };

  public translate(key: string, params: TranslateParams = { locale: LanguageEnum.FRENCH }, otherwise: string = key): string {
    const value = get(this.collection, [ params.locale, key ], otherwise);

    if (typeof value !== 'string') {
      return '[i18n] trying to translate a non-string';
    }

    if (value.indexOf('{') === -1 || key.endsWith("_SKIP_INTERPOLATION")) {
      return value;
    }

    return template(value, {
      evaluate: /{%([\s\S]+?)%}/gim,
      interpolate: /{{([\s\S]+?)}}/gim,
    })(params);
  }
}
