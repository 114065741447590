import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { Decisions, DisplayRequirements, DisplayService } from './display.service';
import { DisplayState } from './state/display.state';

@Directive({
  selector: '[display]',
})
export class DisplayDirective implements OnInit, OnDestroy {
  private isHidden = true;
  private subscription: Subscription;
  private requirements: DisplayRequirements;

  @Select(DisplayState.permissions) permissions$: Observable<Decisions>;

  constructor(
    private container: ViewContainerRef,
    private template: TemplateRef<any>,
    private cdr: ChangeDetectorRef,
    private service: DisplayService
  ) { }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.subscription = this.permissions$.subscribe(() => {
      this.updateView();
    });
  }

  @Input()
  set display(requirements: DisplayRequirements) {
    this.requirements = requirements;
    this.updateView();
  }

  private updateView() {
    if (this.service.meetsRequirements(this.requirements)) {
      if (this.isHidden) {
        this.container.clear();
        this.container.createEmbeddedView(this.template);
        this.cdr.markForCheck();
        this.isHidden = false;
      }
    } else {
      this.container.clear();
      this.cdr.markForCheck();
      this.isHidden = true;
    }
  }
}
