import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-static-table-header',
  templateUrl: './ui-static-table-header.component.html',
  styleUrls: [ '../ui-static-table.component.scss', './ui-static-table-header.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-static-table-header',
  },
})
export class UiStaticTableHeader {
  @Input() public columnDef: any;

  @Input() public isSubHeader = false;

  @Input() public dynamicTable = false;

  @Input() public dataSource: any[] = [];

  @Output('checkbox-select-all-event') public checkboxEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get count() {
    return this.dataSource?.length || 0;
  }

  public get allSelected(): boolean {
    return this.count && this.dataSource.every((item: any) => Boolean(item[this.columnDef.selected]));
  }

  public checkboxSelectAllEvent(event: boolean) {
    this.checkboxEventEmitter.emit(event);
  }
}
