<div class="relative">
  <button
          ui-button
          [color]="color"
          [icon]="icon"
          iconRight="icon_chevron_down"
          [custom-icon]="true"
          [isSvg]="true"
          (click)="toggleOptions()">
    {{ buttonName }}
  </button>

  <div
       *ngIf="showOptions"
       class="options-container"
       [class.descriptionButton]="descriptionButton">
    <ng-content></ng-content>
  </div>
</div>