import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { IMobileService } from '../../interfaces/IMobileService';
import { MultiSelectData } from './multi-select-data';

export interface IMultiSelectData {
  option: string;
  isSelected: boolean;
  translated: string;
  isDefaultValue?: boolean;
  translatedShort?: string;
}

@Component({
  selector: 'ui-multi-select',
  templateUrl: './ui-multi-select.component.html',
  styleUrls: [ './ui-multi-select.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-multi-select',
  },
})
export class UiMultiSelect implements OnInit {
  @Input() public multiSelectData: MultiSelectData;

  @Input() leftOrientation = false;

  @Input() disabled = false;

  @Output() public onOptionSelect: EventEmitter<string[]> = new EventEmitter();

  public showOptions = false;

  public constructor (
    private readonly ref: ElementRef,
    @Inject("MobileService") public readonly mobileService: IMobileService
  ) {
  }

  @HostListener('document:click', [ '$event' ])
  clickOut(event: any) {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.showOptions = false;
    }
  }

  @HostListener('document:keydown.escape', [ '$event' ])
  onEscapeKey(event: KeyboardEvent) {
    if (this.showOptions) {
      event.stopPropagation();
      this.toggle();
    }
  }

  public ngOnInit() {
  }

  public toggle() {
    this.showOptions = !this.showOptions;
  }

  public checkOption(option: IMultiSelectData): void {
    this.multiSelectData.handleSelection(option);
    this.onOptionSelect.emit(this.multiSelectData.optionSelected);
  }

  public clearSelection(): void {
    this.setSelected([]);
  }

  public setSelected(options: any[]): void {
    this.multiSelectData.options.map((x) => {
      x.isSelected = options.includes(x.option);
    });
    const selectedCount = this.multiSelectData.options.filter((x) => x.isSelected).length;
    if (!selectedCount || selectedCount === this.multiSelectData.options.length - 1) {
      this.multiSelectData.options.map((x) => {
        x.isSelected = x.isDefaultValue;
      });
    }
  }
}
