import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { clone } from 'projects/@common/utils/utils';
import { I18nService } from '../../../../i18n/i18n.service';


const SNACK_TIMER = 10000;
const SNACK_DESTROY_TIMER = 100;
const MAX_RANDOM_ID = 999999;

export enum SnackTaste {
  DELICIOUS = 'Delicious',
  NORMAL = 'Normal',
  INEDIBLE = 'Inedible',
  SNACKY = 'Snacky'
}

export interface Snack {
  id: number;
  taste: SnackTaste;
  message: string;
  secondMessage?: string;
  hide?: boolean;
  disableTimeout?: boolean;
  image?: string;
}

export interface SnackbarStoreModel {
  snacks: Snack[];
}

export const initialState: SnackbarStoreModel = {
  snacks: [],
};

export class CookSnackySnack {
  static readonly type = '[Snackbar] Cook a snacky snack!';

  public constructor (
    public message: string,
    public params: any = {},
    public secondMessage: string = null,
    public secondParams: any = {},
    public image: string = null
  ) { }
}

export class CookDeliciousSnack {
  static readonly type = '[Snackbar] Cook a delicious snack!';

  public constructor (
    public message: string,
    public params: any = {},
    public secondMessage: string = null,
    public secondParams: any = {},
    public image: string = null
  ) { }
}

export class CookNormalSnack {
  static readonly type = '[Snackbar] Cook a normal snack.';

  public constructor (
    public message: string,
    public params: any = {},
    public secondMessage: string = null,
    public secondParams: any = {},
    public image: string = null
  ) { }
}

export class CookInedibleSnack {
  static readonly type = '[Snackbar] Cook an inedible snack!';

  public constructor (
    public message: string,
    public params: any = {},
    public secondMessage: string = null,
    public secondParams: any = {},
    public image: string = null
  ) { }
}

export class EatSnack {
  static readonly type = '[Snackbar] Eat snack.';

  public constructor (public snack: Snack) { }
}

export class ThrowSnackInTrash {
  static readonly type = '[Snackbar] Throw snack in trash.';

  public constructor (public snack: Snack) { }
}

@State<SnackbarStoreModel>({
  name: 'snackbar',
  defaults: clone(initialState),
})
@Injectable()
export class SnackbarState {
  public constructor (private i18nService: I18nService) { }

  @Selector()
  public static snacks(state: SnackbarStoreModel): Snack[] {
    return state.snacks;
  }

  @Action(CookSnackySnack)
  public createSnackySnack(ctx: StateContext<SnackbarStoreModel>, action: CookDeliciousSnack): void {
    const snacks = [ ...ctx.getState().snacks ];

    const snack: Snack = {
      id: Math.floor(Math.random() * Math.floor(MAX_RANDOM_ID)),
      taste: SnackTaste.SNACKY,
      message: this.i18nService.translate(action.message, action.params),
      image: action.image,
    };
    if (action.secondMessage) {
      snack.secondMessage = this.i18nService.translate(action.secondMessage, action.secondParams);
    }

    snacks.push(snack);
    ctx.patchState({
      snacks,
    });

    setTimeout(() => this.eatSnack(ctx, { snack }), 1500);
  }

  @Action(CookDeliciousSnack)
  public createDeliciousSnack(ctx: StateContext<SnackbarStoreModel>, action: CookDeliciousSnack): void {
    const snacks = [ ...ctx.getState().snacks ];

    const snack: Snack = {
      id: Math.floor(Math.random() * Math.floor(MAX_RANDOM_ID)),
      taste: SnackTaste.DELICIOUS,
      message: this.i18nService.translate(action.message, action.params),
      image: action.image,
    };
    if (action.secondMessage) {
      snack.secondMessage = this.i18nService.translate(action.secondMessage, action.secondParams);
    }

    snacks.push(snack);

    ctx.patchState({
      snacks,
    });

    setTimeout(() => this.eatSnack(ctx, { snack }), SNACK_TIMER);
  }

  @Action(CookNormalSnack)
  public createNormalSnack(ctx: StateContext<SnackbarStoreModel>, action: CookNormalSnack): void {
    const snacks = [ ...ctx.getState().snacks ];

    const snack: Snack = {
      id: Math.floor(Math.random() * Math.floor(MAX_RANDOM_ID)),
      taste: SnackTaste.NORMAL,
      message: this.i18nService.translate(action.message, action.params),
      image: action.image,
    };
    if (action.secondMessage) {
      snack.secondMessage = this.i18nService.translate(action.secondMessage, action.secondParams);
    }

    snacks.push(snack);

    ctx.patchState({
      snacks,
    });

    setTimeout(() => this.eatSnack(ctx, { snack }), SNACK_TIMER);
  }

  @Action(CookInedibleSnack)
  public createInedibleSnack(ctx: StateContext<SnackbarStoreModel>, action: CookInedibleSnack): void {
    const snacks = [ ...ctx.getState().snacks ];
    const snack: Snack = {
      id: Math.floor(Math.random() * Math.floor(MAX_RANDOM_ID)),
      taste: SnackTaste.INEDIBLE,
      message: this.i18nService.translate(action.message, action.params),
      disableTimeout: action.params?.disableTimeout,
      image: action.image,
    };
    if (action.secondMessage) {
      snack.secondMessage = this.i18nService.translate(action.secondMessage, action.secondParams);
    }

    snacks.push(snack);

    ctx.patchState({
      snacks,
    });

    // Only adding the handling of the disableTimeout for the InedibleSnack for the moment
    if (!snack.disableTimeout) {
      setTimeout(() => this.eatSnack(ctx, { snack }), SNACK_TIMER);
    }
  }

  @Action(EatSnack)
  public eatSnack(ctx: StateContext<SnackbarStoreModel>, action: EatSnack): void {
    const snacks = [ ...ctx.getState().snacks ];

    const snackToEat = clone(action.snack);
    snackToEat.hide = true;

    const itemIndex = snacks.findIndex((snack) => snack.id === snackToEat.id);
    snacks[itemIndex] = snackToEat;

    ctx.patchState({
      snacks,
    });
    setTimeout(() => this.throwSnackInTrash(ctx, { snack: snackToEat }), SNACK_DESTROY_TIMER);
  }

  @Action(ThrowSnackInTrash)
  public throwSnackInTrash(ctx: StateContext<SnackbarStoreModel>, action: ThrowSnackInTrash): void {
    let snacks = [ ...ctx.getState().snacks ];

    snacks = snacks.filter((snack) => snack.id !== action.snack.id);

    ctx.patchState({
      snacks,
    });
  }
}
