import { Component, Input, OnChanges } from '@angular/core';
import { GroupTypeEnum, ResourceTypeEnum } from '../../util/icon-util';

@Component({
  selector: 'ui-resource-type-badge',
  templateUrl: './ui-resource-type-badge.component.html',
  styleUrls: [ './ui-resource-type-badge.component.scss' ],
})
export class UiResourceTypeBadgeComponent implements OnChanges {
  @Input() private type: ResourceTypeEnum;

  @Input() private groupType: GroupTypeEnum;

  @Input() public withBackgroundColor = false;

  @Input() public withPadding = true;

  @Input() public withLeftIcon = false;

  @Input() locale = 'fr';

  public color: string;

  public image = '';

  public name = '';

  public ngOnChanges() {
    this.setType();
  }

  private setType() {
    this.name = UiResourceTypeBadgeComponent.getResourceTypeName(this.type, this.groupType);

    if (this.type === ResourceTypeEnum.GROUP) {
      switch (this.groupType) {
        case GroupTypeEnum.O365OFFICETEAMS:
          this.color = this.withBackgroundColor ? 'teams' : null;
          this.image = 'assets/favicons/icon_resource_ms-teams.svg';
          break;
        case GroupTypeEnum.O365OFFICEGROUP:
          this.color = this.withBackgroundColor ? 'office' : null;
          this.image = 'assets/favicons/icon_resource_sharepoint.svg';
          break;
        case GroupTypeEnum.EXTERNAL:
          this.color = this.withBackgroundColor ? 'external' : null;
          this.image = 'assets/favicons/icon_resource_external.svg';
          break;
        case GroupTypeEnum.WEB_SITE:
          this.color = this.withBackgroundColor ? 'website' : null;
          this.image = 'assets/favicons/icon_resource_website.svg';
          break;
        case GroupTypeEnum.MONITORING:
          this.color = this.withBackgroundColor ? 'monitoring' : null;
          this.image = 'assets/favicons/icon_resource_monitoring.svg';
          break;
        case GroupTypeEnum.O365SECURITYGROUP:
          this.color = this.withBackgroundColor ? 'security' : null;
          this.image = 'assets/favicons/icon_resource_security_groups.svg';
          break;
        case GroupTypeEnum.APPLICATION:
          this.color = this.withBackgroundColor ? 'application' : null;
          this.image = 'assets/favicons/icon_resource_avatar_application.svg';
          break;
        default:
          this.color = this.withBackgroundColor ? '' : null;
          this.image = '';
          break;
      }
    } else {
      this.color = this.withBackgroundColor ? 'role' : null;
      this.image = '';
    }
  }

  public static getResourceTypeName(type: ResourceTypeEnum, groupType: GroupTypeEnum): string {
    if (type === ResourceTypeEnum.ROLE) {
      return 'roles.title';
    }
    if (type === ResourceTypeEnum.ONEDRIVE) {
      return 'resources.OneDrive';
    }
    switch (groupType) {
      case GroupTypeEnum.O365OFFICETEAMS:
      case GroupTypeEnum.O365OFFICEGROUP:
      case GroupTypeEnum.O365SITE:
      case GroupTypeEnum.EXTERNAL:
      case GroupTypeEnum.MONITORING:
      case GroupTypeEnum.WEB_SITE:
      case GroupTypeEnum.O365SECURITYGROUP:
      case GroupTypeEnum.APPLICATION:
      case GroupTypeEnum.O365CHANNEL:
      case GroupTypeEnum.O365LICENSE:
        return `resources.type.${groupType}`;
      default:
        return '';
    }
  }
}
