import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { AlertStatusEnum } from 'projects/@common/services/api/respond/alerts/alerts.definitions';
import { Alert } from 'projects/@common/services/api/respond/alerts/models/alert';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';

@Component({
  selector: 'alert-incident-indicators',
  templateUrl: './alert-incident-indicators.component.html',
  styleUrls: [ './alert-incident-indicators.component.scss' ],
})
export class AlertIncidentIndicatorsComponent implements OnInit {
  @Input() public alert: Alert;
  @Input() public incident: Incident;
  @Input() public assetsCount: number = 0;

  @Input() fixedGridDisplay = true;

  public whitelistedIcon = "/assets/favicons/alerts/ico_liste_exception_22x22_list.svg";
  public confidentialityIcon = "/assets/favicons/alerts/ico_bris_confi_22x22_list.svg";
  public silentIcon = "/assets/favicons/alerts/ico_silent_24x24.svg";
  public criticalAssetIcon = "/assets/favicons/alerts/ico_actifs_interet_22x22_list.svg";
  public lateAlertIcon = "/assets/favicons/alerts/ico_late_alert_22x22.png";
  public conversationUpdated = "/assets/favicons/icon_conversation_updated.svg"

  public countAssets: number;
  public hasAssets: boolean;
  public isWhitelisted: boolean;
  public isSilent: boolean;
  public isBreachConfidentiality: boolean;
  public conversationUpdatedClientSide: boolean;
  public isLate: boolean;

  public lateAlertTooltipText: string;

  constructor(
    private readonly i18n: I18nService,
    private readonly datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.countAssets = this.alert?.assetCount || this.incident?.assetCount || this.assetsCount || 0;
    this.hasAssets = this.countAssets > 0;
    this.isWhitelisted = !!this.alert?.whitelisted || this?.incident?.whitelistedAlertCount > 0;
    this.isSilent = this.alert?.status === AlertStatusEnum.SILENCED;
    this.isBreachConfidentiality = !!this.incident?.breachOfConfidentiality;
    this.conversationUpdatedClientSide = !!this.incident?.updatedConversationFromClient
    this.isLate = this.alert?.isLate === true || this.incident?.isLate === true;

    if (this.isLate) {
      const date = new Date(this.alert?.firstEventTimestamp || this.incident?.firstEventTimestamp);

      const formatedDate = this.datepipe.transform(date, "fullDate", null, this.i18n.currentLocale === 'fr' ? 'fr-ca' : 'en');
      const separator = this.i18n.currentLocale === 'fr' ? 'à' : 'at';
      const time = this.datepipe.transform(date, "HH:mm:ss.SSS");

      const alertFirstEventTimestamp = `${formatedDate} ${separator} ${time}`;
      if (this.alert) {
        this.lateAlertTooltipText = this.i18n.translate('alerts.container.page.table.status.LATE.tooltip.alert', {
          date: alertFirstEventTimestamp,
        });
      } else {
        this.lateAlertTooltipText = this.i18n.translate('alerts.container.page.table.status.LATE.tooltip.incident');
      }
    }
  }
}
