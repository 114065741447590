import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import * as Handlebars from 'handlebars';
import { isIP } from 'is-ip';
import { isValidPhoneNumber } from 'libphonenumber-js/min';
import * as uuid from 'uuid';

export const numberRegEx = /^[0-9]*$/;
export const floatRegEx = /^[0-9]*(?:[\.,]([0-9]+))?$/;

export function clearValidator(formGroup: UntypedFormGroup, formControlName: string): void {
  formGroup.get(formControlName).clearValidators();
  formGroup.get(formControlName).updateValueAndValidity();
}

export function setValidator(formGroup: UntypedFormGroup, formControlName: string, validators: ValidatorFn[]): void {
  formGroup.get(formControlName).setValidators(validators);
  formGroup.get(formControlName).updateValueAndValidity();
}

export class CustomValidators {
  public static numberValidator(x: AbstractControl): { isNumber: { valid: boolean } } {
    if (!x?.value?.length) {
      return null;
    }

    return numberRegEx.test(x.value) ? null : { isNumber: { valid: false } };
  }

  public static numberValidatorNotZero(x: AbstractControl): any {
    if (!x?.value?.length) {
      return null;
    }

    if (!numberRegEx.test(x.value)) {
      return { isNumber: { valid: false } };
    }

    if (x.value <= 0) {
      return { smallerThan1Value: { valid: false } };
    }
  }

  public static floatValidator(x: AbstractControl): { isFloat: { valid: boolean } } {
    if (!x?.value?.length) {
      return null;
    }

    return floatRegEx.test(x.value) ? null : { isFloat: { valid: false } };
  }

  public static digitsValidator(maxDigits: number) {
    return (x: AbstractControl): { isFloat: { valid: boolean } } => {
      if (!x?.value?.length) {
        return null;
      }

      const digitsRegExpExec = floatRegEx.exec(x.value);
      if (digitsRegExpExec !== null) {
        const digits = digitsRegExpExec[1]?.toString().length || 0;
        if (digits <= maxDigits) {
          return null;
        }
      }
      return { isFloat: { valid: false } };
    };
  }

  public static isArrayOfString(x: AbstractControl): { isArrayOfString: { valid: boolean } } {
    if (!x?.value?.length) {
      return null;
    }

    try {
      if (Array.isArray(JSON.parse(x.value))) {
        if (JSON.parse(x.value).some((value) => typeof value !== 'string')) {
          return { isArrayOfString: { valid: false } };
        }

        return null;
      }
    } catch (error) {
      return { isArrayOfString: { valid: false } };
    }
  }

  public static isValidJson(x: AbstractControl): { invalidJSON: { valid: boolean } } {
    if (!x?.value?.length) {
      return null;
    }

    try {
      if (JSON.parse(x.value)) {
        return null;
      }
      return { invalidJSON: { valid: false } };
    } catch (error) {
      return { invalidJSON: { valid: false } };
    }
  }

  public static defaultValidator(x: AbstractControl): { isValid: { valid: boolean } } {
    if (!x?.value?.length) {
      return null;
    }
    return null;
  }

  public static conditionalValidator(
    predicate: (x: AbstractControl) => boolean,
    ...validators: ValidatorFn[]
  ): ValidatorFn {
    return (x: AbstractControl) => {
      if (!x.parent) {
        return null;
      }
      if (predicate(x)) {
        return validators.map((validator) => validator(x)).find((validated) => validated) || null;
      }
      return null;
    };
  }

  public static isBase64(value: string): boolean {
    if (value === '') {
      return false;
    }
    try {
      return btoa(atob(value)) === value;
    } catch (error) {
      return false;
    }
  }

  public static isUUID(value: string): boolean {
    return uuid.validate(value);
  }

  public static validateUUID(control: AbstractControl) {
    if (control.value !== '' && !CustomValidators.isUUID(control.value)) {
      return {
        invalidUUID: true,
      };
    }
    return null;
  }

  public static validateBase64(control: AbstractControl) {
    if (!CustomValidators.isBase64(control.value)) {
      return {
        base64Encoded: true,
      };
    }
    return null;
  }

  public static validateIpAddress(x: AbstractControl): { invalidIp: boolean } {
    if (!x?.value?.length) {
      return null;
    }

    return isIP(x.value) ? null : { invalidIp: true };
  }

  public static validateIsADifferentValue(value: string): ValidatorFn {
    return (x: AbstractControl): { isSameValue: true } => {
      if (!x?.value?.length) {
        return null;
      }
      return x.value !== value ? null : { isSameValue: true };
    };
  }

  public static validateEmailAddress(x: AbstractControl): { invalidEmail: boolean } {
    if (!x?.value?.length) {
      return null;
    }
    const emailRegexExp = new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    return emailRegexExp.test(x.value) ? null : { invalidEmail: true };
  }

  public static validateExpirationDuration = (control: AbstractControl) => {
    if (control.value && control.value.length > 0) {
      // eslint-disable-next-line no-useless-escape
      const validRegex = new RegExp(/^[0-9]*$/g);
      if (validRegex.test(control.value)) {
        return null;
      }
      return { invalidExpirationDate: true };
    }
    return null;
  };

  public static isGreaterThan(control: AbstractControl): ValidatorFn {
    return (x: AbstractControl): { isSmaller: boolean } => +x.value > +control.value ? null : { isSmaller: true };
  }

  public static isLesserThan(control: AbstractControl): ValidatorFn {
    return (x: AbstractControl): { isLarger: boolean } => +x.value < +control.value ? null : { isLarger: true };
  }

  public static isLessOrEqualThan(value: number, returnValue: string): ValidatorFn {
    return (x: AbstractControl): { [key in typeof returnValue]: boolean } => +x.value <= value ? null : { [returnValue]: true };
  }

  public static isGreaterThanValue(value: number, returnValue: string): ValidatorFn {
    return (x: AbstractControl): { [key in typeof returnValue]: boolean } => +x.value > value ? null : { [returnValue]: true };
  }

  public static affectedResources(x: AbstractControl): {
    invalidAffectedResourcesTemplate_SKIP_INTERPOLATION: boolean;
  } {
    const value = x?.value;
    if (!value?.length) {
      return null;
    }

    try {
      Handlebars.parse(value);
      return null;
    } catch (error) {
      return { invalidAffectedResourcesTemplate_SKIP_INTERPOLATION: true };
    }
  }

  public static validatePhoneNumber(x: AbstractControl): { invalidPhoneNumber: boolean } {
    const value = x?.value;

    if (!value?.length) {
      return null;
    }

    const isValid: boolean = isValidPhoneNumber(value);
    if (isValid) {
      return null;
    }

    return { invalidPhoneNumber: true };
  }
}
