import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { DialogState } from '@ui-kit/components/ui-dialog/stores/ui-dialog.store';
import { DisplayService } from 'projects/@common/modules/display/display.service';
import { MobileService } from 'projects/@common/services/mobile.service';
import { prune } from 'projects/@common/utils/utils';
import { Observable } from 'rxjs';
import { BaseSidebar, navigationElement } from '../../definitions/base-sidebar.service';
import { DrawerService } from '../drawer/services/drawer.service';
import { DrawerState } from '../drawer/stores/drawer.store';

export const name = 'sidebar';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [ './sidebar.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    role: 'navigation',
  },
})
export class Sidebar implements AfterViewInit, OnChanges, OnDestroy {
  @Input('class') public class: string;

  // @Input('user') public user: IUser;

  // @Input('currentOrganization') public currentOrganization: IOrganization | IIamOrganization;

  // @Input('indicators') public indicators = { emails: 0, notices: 0 };

  @Input('expanded') public isExpanded = false;

  @HostBinding('class') public classes: string;

  @Output('toggle-menu') public menuToggled: EventEmitter<void> = new EventEmitter();

  @Select(DrawerState.isExpanded) public isDrawerExpanded$: Observable<boolean>;

  @Select(DialogState.isShown) public isDialogShown$: Observable<boolean>;

  navigationElements: navigationElement[] = [];

  mondataLogo = 'assets/favicons/mondata-dark-logo.svg';

  currentExpandedMenu: string;

  adminMode = true;

  constructor(
    public router: Router,
    public mobileService: MobileService,
    private cdr: ChangeDetectorRef,
    private drawerService: DrawerService,
    private displayService: DisplayService,
    @Inject('RESPOND_SIDEBAR') private respondSidebar: BaseSidebar,
    @Inject('DETECT_SIDEBAR') private detectSidebar: BaseSidebar,
    @Inject('IAM_SIDEBAR') private iamSidebar: BaseSidebar,
    @Inject('TOOLS_SIDEBAR') private toolsSidebar: BaseSidebar,
    @Inject('AIR_SIDEBAR') private airSidebar: BaseSidebar
  ) {
  }

  public ngOnDestroy(): void {
    this.respondSidebar?.unsubscribe();
    this.detectSidebar?.unsubscribe();
    this.iamSidebar?.unsubscribe();
    this.toolsSidebar?.unsubscribe();
    this.airSidebar?.unsubscribe();
  }

  public ngOnInit(): void {
    this.setup();
    this.createNavigation();
    if (this.mobileService.isMobile()) {
      this.menuToggled.emit();
    }
  }

  public ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public ngOnChanges(): void {
    this.setup();
    this.createNavigation();
    if (!this.isExpanded) {
      this.closeNavigationElement();
    }
  }

  public setup(): void {
    const classes = {
      [name]: true,
      [`${name}_expanded`]: this.isExpanded,
    };

    if (this.class) {
      classes[this.class] = true;
    }

    this.classes = Object.keys(prune(classes)).join(' ');
  }

  public handleClick(path: string, queryParams: any): void {
    this.drawerService.hideDrawer();
    // We use the /externalRedirect& hardcoded in the route element of the menu to manage URL outside of the application
    if (path.startsWith('/externalRedirect')) {
      window.open(path.substring(path.indexOf('&') + 1), '_blank');
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams,
      };

      this.router.navigate([ path ], navigationExtras);
    }
    if (this.mobileService.isMobile()) {
      this.menuToggled.emit();
    }
  }

  public handleExpand(origin: any): void {
    if (origin.rootAction) {
      this.handleClick(origin.route, origin.queryParams);
      this.closeNavigationElement();
    } else if (this.isExpanded) {
      for (const element of this.navigationElements) {
        if (element.state === 'expanded' && origin.name === element.name) {
          element.state = 'closed';
          return;
        }
        element.state = 'closed';
      }

      this.currentExpandedMenu = origin.name;
      origin.state = 'expanded';
    }
  }

  public closeNavigationElement() {
    for (const element of this.navigationElements) {
      element.state = 'closed';
    }
  }

  public getElementChildCount(index: number): number {
    const element = document.getElementById(`itemRef${index}`);
    if (element) {
      let count = 0;
      const children = element.children as any;
      for (const child of children) {
        if (child.offsetHeight > 0) {
          count++;
        }
      }
      return count;
    }

    return 0;
  }

  public onMouseEnter(event: any, parent: any) {
    if (!this.isExpanded && parent.state === 'expanded' && event.originalTarget) {
      setTimeout(() => {
        const top = `${event.originalTarget.offsetTop}px`;
        event.originalTarget.children[0].style.top = top;
      }, 250);
      return;
    }
  }

  public isElementActive(element: navigationElement, activeRouterLink: string) {
    if (element.children) {
      let isActive = false;
      for (const subElement of element.children) {
        isActive = isActive || activeRouterLink.includes(subElement.route);
      }
      return isActive;
    }
    return (
    // Handle routes which contain subsets of other routes
      activeRouterLink.startsWith(element.route) && activeRouterLink.split('/').pop() === element.route.split('/').pop()
    );
  }

  private createNavigation() {
    const respond = this.filterElementsWithPermissions(this.respondSidebar);
    const detect = this.filterElementsWithPermissions(this.detectSidebar);
    const iam = this.filterElementsWithPermissions(this.iamSidebar);
    const tools = this.filterElementsWithPermissions(this.toolsSidebar);
    const air = this.filterElementsWithPermissions(this.airSidebar);

    this.navigationElements = [
      ...respond,
      ...detect,
      ...iam,
      ...tools,
      ...air,
    ];
  }

  private filterElementsWithPermissions(sidebar: BaseSidebar) {
    if (sidebar) {
      if (!this.displayService.meetsRequirements(sidebar.getRequirements())) {
        return [];
      }

      const elements = sidebar.getElements();
      const elementsUserCanView = this.navigationElementsMeetRequirements(elements);

      // There is nothing to show
      if (!elementsUserCanView || elementsUserCanView.length === 0) {
        return [];
      }

      // There is only the title to show
      if (elementsUserCanView.length === 1) {
        return [];
      }

      return elementsUserCanView;
    }

    return [];
  }

  private navigationElementsMeetRequirements(elements: navigationElement[]): navigationElement[] {
    const elementsUserCanView: navigationElement[] = [];

    for (const element of elements) {
      if (this.displayService.meetsRequirements({ permissions: element.permissions, orPermissions: element.orPermissions, flags: element.flags, orgType: element.orgType, services: element.services })) {
        if (element.children) {
          const children = this.navigationElementsMeetRequirements(element.children);
          if (children.length >= 1) {
            element.children = children;
            elementsUserCanView.push(element);
          }
        } else {
          elementsUserCanView.push(element);
        }
      }
    }
    return elementsUserCanView;
  }
}
