<div
  class="container-toggle"
  [ngClass]="{
    'boldLabel':boldLabel,
    'switchRightSide':switchRightSide
  }"
  (click)="!isMidAnimation && !disabled ? toggle($event) : null"
>

  <!-- Left Side Label -->
  <div
    *ngIf="switchRightSide"
    class="label"
    [ngClass]="{
      'disabled-label': disabled || (!toggled && disabledWhenUntoggled)
    }"
  >
    {{ toggled ? labelEnabled : labelDisabled }}
  </div>

  <!-- Toogle switch -->
  <div
    class="background"
    [ngClass]="{
      'toggled-background': toggled,
      'mid-animation': isMidAnimation || disabled,
      'untoggled-background': !toggled,
      'disabled-background': disabled && changeColorWhenDisabled
    }"
  >
    <div
      class="toggle"
      [ngClass]="toggled ? 'toggled-toggle' : 'untoggled-toggle'"
    ></div>
  </div>

  <!-- Right Side Label -->
  <div
    *ngIf="!switchRightSide"
    class="label"
    [ngClass]="{
      'disabled-label': disabled || (!toggled && disabledWhenUntoggled)
    }"
  >
    {{ toggled ? labelEnabled : labelDisabled }}
  </div>
</div>