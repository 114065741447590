import { Component, ElementRef, Input, ViewChild, ViewEncapsulation } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import {
  mdiWeb,
  mdiKeyVariant,
  mdiLogout,
  mdiDownload,
  mdiAccountGroup,
  mdiAccountMinus,
  mdiAccountPlus,
  mdiAccountSettings,
  mdiAlarmLightOutline,
  mdiAlert,
  mdiApplication,
  mdiArrowBottomRight,
  mdiArrowCollapseLeft,
  mdiArrowDown,
  mdiArrowExpand,
  mdiArrowLeft,
  mdiArrowRight,
  mdiArrowTopRight,
  mdiArrowUp,
  mdiBell,
  mdiBellOutline,
  mdiBlinds,
  mdiBlindsOpen,
  mdiCalendarBlankOutline,
  mdiCalendarClock,
  mdiCalendarText,
  mdiCamera,
  mdiCancel,
  mdiCat,
  mdiCellphoneIphone,
  mdiCheck,
  mdiCheckBold,
  mdiCheckCircle,
  mdiChessKing,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiCircleMedium,
  mdiClockCheckOutline,
  mdiClockOutline,
  mdiClose,
  mdiCloseCircle,
  mdiCow,
  mdiCube,
  mdiDelete,
  mdiDesktopClassic,
  mdiDetails,
  mdiDog,
  mdiDotsVertical,
  mdiElephant,
  mdiEmailOutline,
  mdiExclamation,
  mdiEye,
  mdiEyeOffOutline,
  mdiFaceAgent,
  mdiFileExcelOutline,
  mdiFileOutline,
  mdiFileUploadOutline,
  mdiFileWordOutline,
  mdiFilterVariant,
  mdiFish,
  mdiFolder,
  mdiFrequentlyAskedQuestions,
  mdiHelpCircle,
  mdiHomeCityOutline,
  mdiLaptop,
  mdiLinkVariant,
  mdiLinkVariantOff,
  mdiLockReset,
  mdiMagnify,
  mdiMedal,
  mdiMenu,
  mdiMenuDown,
  mdiMenuUp,
  mdiMinus,
  mdiMonitor,
  mdiMonitorDashboard,
  mdiOpenInNew,
  mdiPencil,
  mdiPencilOutline,
  mdiPlus,
  mdiPoliceBadge,
  mdiRefresh,
  mdiRegex,
  mdiReload,
  mdiSecurity,
  mdiSettingsOutline,
  mdiShareVariant,
  mdiShieldAccount,
  mdiShieldLock,
  mdiSitemap,
  mdiStateMachine,
  mdiThumbDown,
  mdiThumbUp,
  mdiToggleSwitchOff,
  mdiViewList,
  mdiContentCopy,
  mdiArrowTopRightBottomLeft,
  mdiArrowCollapse
} from "@mdi/js";

export enum UiIconSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

export enum UiIconModifier {
  Sidenav = 'sidenav',
  SidenavAdmin = 'sidenavAdmin',
  Primary = 'primary',
  SecondaryGray = 'secondary-gray',
  OnBright = 'on-bright',
  OnDark = 'on-dark',
  listAction = 'list-action',
  OnHeader = 'on-header',
  OnInput = 'on-input',
  OnTopbar = 'on-topbar',
  OnButtonHeader = 'on-headerbutton',
  Validation = 'validation',
  Error = 'error',
  Warning = 'warning',
  OnHighlight = 'on-highlight',
  White = 'white',
  Gray = 'gray',
  DashboardWidget = 'dashboard-widget'
}

// Please register additional icons here.
// (we're importing icons on a per-member basis for tree shaking purpose)
export const icons = {
  web: mdiWeb,
  key: mdiKeyVariant,
  logout: mdiLogout,
  download: mdiDownload,
  copy: mdiContentCopy,
  blindsClose: mdiBlinds,
  blindsOpen: mdiBlindsOpen,
  edit: mdiPencil,
  bell: mdiBell,
  close: mdiClose,
  collapseArrow: mdiArrowCollapseLeft,
  trashcan: mdiDelete,
  refresh: mdiRefresh,
  magnify: mdiMagnify,
  menu: mdiMenu,
  cow: mdiCow,
  cat: mdiCat,
  cube: mdiCube,
  dog: mdiDog,
  detail: mdiDetails,
  elephant: mdiElephant,
  enable: mdiToggleSwitchOff,
  fish: mdiFish,
  file: mdiFileOutline,
  folder: mdiFolder,
  laptop: mdiLaptop,
  support: mdiFaceAgent,
  application: mdiApplication,
  site: mdiSitemap,
  setting: mdiSettingsOutline,
  user: mdiAccountSettings,
  clock: mdiClockOutline,
  share: mdiShareVariant,
  group: mdiAccountGroup,
  role: mdiShieldAccount,
  warden: mdiSecurity,
  event: mdiCalendarClock,
  checkBold: mdiCheckBold,
  more: mdiDotsVertical,
  alert: mdiAlarmLightOutline,
  left: mdiArrowLeft,
  right: mdiArrowRight,
  'top-right': mdiArrowTopRight,
  'bottom-right': mdiArrowBottomRight,
  warning: mdiAlert,
  link: mdiLinkVariant,
  'pencil-outline': mdiPencilOutline,
  'link-off': mdiLinkVariantOff,
  'chevron-down': mdiChevronDown,
  'chevron-up': mdiChevronUp,
  'calendar-Outline': mdiCalendarBlankOutline,
  'email-outline': mdiEmailOutline,
  'bell-outline': mdiBellOutline,
  'calendar-text': mdiCalendarText,
  'filter-variant': mdiFilterVariant,
  'arrow-asc': mdiArrowUp,
  'arrow-desc': mdiArrowDown,
  'link-variant': mdiLinkVariant,
  plus: mdiPlus,
  minus: mdiMinus,
  'account-plus': mdiAccountPlus,
  'account-minus': mdiAccountMinus,
  'file-upload': mdiFileUploadOutline,
  camera: mdiCamera,
  'help-circle': mdiHelpCircle,
  'check-circle': mdiCheckCircle,
  'close-circle': mdiCloseCircle,
  'circle-medium': mdiCircleMedium,
  'password-reset': mdiLockReset,
  'open-new': mdiOpenInNew,
  'shield-lock': mdiShieldLock,
  'thumb-up': mdiThumbUp,
  'thumb-down': mdiThumbDown,
  eye: mdiEye,
  hidden: mdiEyeOffOutline,
  king: mdiChessKing,
  organization: mdiHomeCityOutline,
  'microsoft-excel': mdiFileExcelOutline,
  'microsoft-word': mdiFileWordOutline,
  reload: mdiReload,
  medal: mdiMedal,
  faq: mdiFrequentlyAskedQuestions,
  keywordsList: mdiViewList,
  'chevron-right': mdiChevronRight,
  'chevron-left': mdiChevronLeft,
  regex: mdiRegex,
  computer: mdiDesktopClassic,
  state: mdiStateMachine,
  'police-badge': mdiPoliceBadge,
  dashboard: mdiMonitorDashboard,
  triangle: mdiMenuUp,
  'inversed-triangle': mdiMenuDown,
  exclamation: mdiExclamation,
  check: mdiCheck,
  monitor: mdiMonitor,
  cellphone: mdiCellphoneIphone,
  expand: mdiArrowExpand,
  unExpand: mdiArrowCollapse,
  cancel: mdiCancel,
  clockCheck: mdiClockCheckOutline,
  arrowUp: mdiArrowUp,
  arrowDown: mdiArrowDown,

  /* to add icons, please refer here: https://pictogrammers.github.io/@mdi/font/2.0.46/, also here: http://localhost:4200/IMAGES */

  // Custom ICON Manually add
  /* You have too remove some class="" and <defs><style> in the SVG tag for each custom icon */


  'add-user': {
    svg: '<svg class="custom" xmlns="http://www.w3.org/2000/svg" width="15" height="12.456" viewBox="0 0 15 12.456"><g transform="translate(0.5 0.5)"><path d="M11.045,26.319V25.046A2.546,2.546,0,0,0,8.5,22.5H4.045A2.546,2.546,0,0,0,1.5,25.046v1.273" transform="translate(-1.5 -14.863)"/><path d="M11.841,7.046A2.545,2.545,0,1,1,9.3,4.5,2.546,2.546,0,0,1,11.841,7.046Z" transform="translate(-4.523 -4.5)"/><path d="M30,12v3.819" transform="translate(-17.91 -8.818)"/><path d="M29.318,16.5H25.5" transform="translate(-15.318 -11.408)"/></g></svg>',
    custom: true,
  },
  'edit-feather': {
    svg: '<svg class="custom" xmlns="http://www.w3.org/2000/svg" width="15.718" height="15.135" viewBox="0 0 15.718 15.135"><g transform="translate(0.5 0.635)"><path d="M18,30h5.727" transform="translate(-9.009 -16)"/><path d="M15.664,4.826a1.768,1.768,0,0,1,2.481,0,1.721,1.721,0,0,1,0,2.453L7.808,17.5,4.5,18.318l.827-3.271Z" transform="translate(-4.5 -4.318)"/></g></svg>',
    custom: true,
  },
  'add-channel': {
    svg: '<svg class="custom" xmlns="http://www.w3.org/2000/svg" width="20.151" height="16" viewBox="0 0 20.151 16"><defs><style>.a{fill:none;stroke:#47c1bf;}.b{fill:#fff;}.c{fill:#169598;}</style></defs><g transform="translate(0.5 0.5)"><path class="a" d="M.406,5.036H10.969a.438.438,0,0,0,.406-.464V2.714a.438.438,0,0,0-.406-.464H.406A.438.438,0,0,0,0,2.714V4.571A.438.438,0,0,0,.406,5.036ZM12.594,7.357H2.031a.438.438,0,0,0-.406.464V9.679a.438.438,0,0,0,.406.464H12.594A.438.438,0,0,0,13,9.679V7.821A.438.438,0,0,0,12.594,7.357Zm-1.625,5.107H.406A.438.438,0,0,0,0,12.929v1.857a.438.438,0,0,0,.406.464H10.969a.438.438,0,0,0,.406-.464V12.929A.438.438,0,0,0,10.969,12.464Z" transform="translate(0 -2.25)"/><circle class="b" cx="7" cy="7" r="7" transform="translate(5.5 1.5)"/><g transform="translate(1.959 -2.191)"><path class="c" d="M17.291,13.626H14.728V11.062a.551.551,0,1,0-1.1,0v2.564H11.062a.527.527,0,0,0-.551.551.533.533,0,0,0,.551.551h2.564v2.564a.533.533,0,0,0,.551.551.548.548,0,0,0,.551-.551V14.728h2.564a.551.551,0,0,0,0-1.1Z" transform="translate(-3.644 -3.644)"/><path class="c" d="M10.533,4.339A6.192,6.192,0,1,1,6.152,6.152a6.154,6.154,0,0,1,4.381-1.814m0-.964a7.158,7.158,0,1,0,7.158,7.158,7.157,7.157,0,0,0-7.158-7.158Z" transform="translate(0 0)"/></g></g></svg>',
    custom: true,
  },
  resources: {
    svg: '<svg class="custom" xmlns="http://www.w3.org/2000/svg" width="24.705" height="24" viewBox="0 0 24.705 24"><path class="a" d="M14918.381,23732a.557.557,0,0,0-.061-.023h-.016l-5.91-3.277a.189.189,0,0,1-.061-.035c-.018.014-.037.023-.055.035l-5.891,3.277h-.018c-.02,0-.039.018-.061.023s-.049.008-.078.008-.057-.008-.078-.008-.043-.02-.061-.023h-.014l-5.91-3.277a.221.221,0,0,1-.123-.111c-.031-.062-.049-6.7-.049-6.7a.219.219,0,0,1,.018-.082.735.735,0,0,1,.031-.072v-.01h.02a.076.076,0,0,1,.033-.055c.018-.018.035-.035.053-.055h.018v-.02l5.5-3.029c-.021-1.641-.033-6.7-.033-6.7a.205.205,0,0,1,.018-.09c.012-.021.018-.051.029-.074v-.018h.018a.323.323,0,0,1,.043-.059.408.408,0,0,0,.061-.053h.01v-.018l6.365-3.506a.218.218,0,0,1,.156-.049.285.285,0,0,1,.174.049l6.346,3.506c0,.012,0,.018.006.018h.012a.259.259,0,0,1,.057.047.127.127,0,0,0,.039.047.016.016,0,0,0,.014.018h0v.018a.155.155,0,0,1,.043.074.537.537,0,0,1,0,.076v6.717l5.469,3.023c0,.012,0,.02.006.02h.008a.106.106,0,0,1,.053.043.173.173,0,0,0,.043.047c0,.012,0,.02.006.02h0v.01a.137.137,0,0,1,.043.072.2.2,0,0,1,.008.064v6.551a.238.238,0,0,1-.051.152.309.309,0,0,1-.119.121l-5.893,3.277h-.018a.243.243,0,0,0-.059.023c-.023,0-.049.008-.08.008S14918.4,23732,14918.381,23732Zm.4-6.646v5.779l5.232-2.906v-5.783Zm-5.9,2.873,5.254,2.906v-5.779l-5.187-2.869-.066.035Zm-6.328-2.873v5.779l5.236-2.906v-5.719l-.059-.031Zm-5.893,2.873,5.248,2.906v-5.779l-5.248-2.91Zm17.795-3.445,5.219-2.889-4.832-2.68-5.217,2.895Zm-17.443-2.889,5.219,2.889,4.836-2.672-5.225-2.895Zm11.883-6.293v5.725l5.408-2.982a.262.262,0,0,1,.023-.012v-5.867Zm-6.508,2.742,5.354,2.965v-5.707l-5.391-3.137v5.861Zm.338-6.469,5.617,3.109,5.617-3.109-5.617-3.111Zm17.977,17.2Zm-12.225,0Zm6.588-9.475Z" transform="translate(-14899.998 -23708.004)"/></svg>',
    custom: true,
  },
  applications: {
    svg: '<svg class="custom" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path class="a" d="M3,10.5a.5.5,0,1,1-.5-.5A.5.5,0,0,1,3,10.5Z"/><path class="a" d="M5,10.5a.5.5,0,1,1-.5-.5A.5.5,0,0,1,5,10.5Z"/><path class="a" d="M7,10.5a.5.5,0,1,1-.5-.5A.5.5,0,0,1,7,10.5Z"/><path class="a" d="M21.5,0H9.5A2.5,2.5,0,0,0,7,2.5V4H5.5A2.5,2.5,0,0,0,3,6.5V8H2.5A2.5,2.5,0,0,0,0,10.5v11A2.5,2.5,0,0,0,2.5,24h12A2.5,2.5,0,0,0,17,21.5V20h.5A2.5,2.5,0,0,0,20,17.5V16h1.5A2.5,2.5,0,0,0,24,13.5V2.5A2.5,2.5,0,0,0,21.5,0ZM9.5,1h12A1.5,1.5,0,0,1,23,2.5V4H8V2.5A1.5,1.5,0,0,1,9.5,1Zm-4,4h12A1.5,1.5,0,0,1,19,6.5V8H4V6.5A1.5,1.5,0,0,1,5.5,5Zm-3,4h12A1.5,1.5,0,0,1,16,10.5V12H1V10.5A1.5,1.5,0,0,1,2.5,9ZM16,21.5A1.5,1.5,0,0,1,14.5,23H2.5A1.5,1.5,0,0,1,1,21.5V13H16Zm3-4A1.5,1.5,0,0,1,17.5,19H17V10.5A2.471,2.471,0,0,0,16.487,9H19ZM21.5,15H20V6.5A2.471,2.471,0,0,0,19.487,5H23v8.5A1.5,1.5,0,0,1,21.5,15Z"/><path class="a" d="M10,2.5A.5.5,0,1,1,9.5,2,.5.5,0,0,1,10,2.5Z"/><path class="a" d="M12,2.5a.5.5,0,1,1-.5-.5A.5.5,0,0,1,12,2.5Z"/><path class="a" d="M14,2.5a.5.5,0,1,1-.5-.5A.5.5,0,0,1,14,2.5Z"/><path class="a" d="M6,6.5A.5.5,0,1,1,5.5,6,.5.5,0,0,1,6,6.5Z"/><path class="a" d="M8,6.5A.5.5,0,1,1,7.5,6,.5.5,0,0,1,8,6.5Z"/><path class="a" d="M10,6.5A.5.5,0,1,1,9.5,6,.5.5,0,0,1,10,6.5Z"/></svg>',
    custom: true,
  },
  'formation-sensibility': {
    svg: '<svg class="custom" xmlns="http://www.w3.org/2000/svg" width="20.999" height="24" viewBox="0 0 20.999 24"><path class="a" d="M11.538,9.314l-.9,2.239-2.723.454a.5.5,0,0,0-.272.846l1.818,1.818-.458,2.747a.5.5,0,0,0,.75.511L12,16.583l2.243,1.346A.493.493,0,0,0,14.5,18a.518.518,0,0,0,.285-.087.5.5,0,0,0,.212-.5l-.458-2.747,1.818-1.818a.5.5,0,0,0-.272-.846l-2.723-.454-.9-2.239a.52.52,0,0,0-.928,0Zm1,2.871a.5.5,0,0,0,.382.308l2.041.34-1.313,1.313a.5.5,0,0,0-.14.436l.322,1.932-1.572-.943A.5.5,0,0,0,12,15.5a.491.491,0,0,0-.255.071l-1.572.943.322-1.932a.5.5,0,0,0-.14-.436L9.042,12.833l2.041-.34a.5.5,0,0,0,.382-.308L12,10.846l.536,1.339Zm9.606-9.164-10-3a.5.5,0,0,0-.287,0l-10,3A.5.5,0,0,0,1.5,3.5v11c0,4.422,6.742,8.354,10.352,9.478A.491.491,0,0,0,12,24a.48.48,0,0,0,.147-.022C15.757,22.854,22.5,18.922,22.5,14.5V3.5a.5.5,0,0,0-.356-.479ZM21.5,14.5c0,3.511-5.563,7.2-9.5,8.476C8.063,21.7,2.5,18.011,2.5,14.5V7.872L12,5.022l9.5,2.85Zm0-7.672L12.144,4.021a.5.5,0,0,0-.287,0L2.5,6.828V3.872L12,1.022l9.5,2.85Z" transform="translate(-1.5)"/></svg>',
    custom: true,
  },
  'network-file': {
    svg: '<svg class="custom" xmlns="http://www.w3.org/2000/svg" width="15" height="24" viewBox="0 0 15 24"><g transform="translate(-4.5)"><path class="a" d="M14,24H10a.5.5,0,0,1-.5-.5v-2A.5.5,0,0,1,10,21h.5v-.5A.5.5,0,0,1,11,20h2a.5.5,0,0,1,.5.5V21H14a.5.5,0,0,1,.5.5v2A.5.5,0,0,1,14,24Zm-3.5-1h3V22H13a.5.5,0,0,1-.5-.5V21h-1v.5a.5.5,0,0,1-.5.5h-.5Z"/><path class="a" d="M12,21a.5.5,0,0,1-.5-.5v-2a.5.5,0,0,1,1,0v2A.5.5,0,0,1,12,21Z"/><path class="a" d="M19,23H14a.5.5,0,0,1,0-1h5a.5.5,0,0,1,0,1Z"/><path class="a" d="M10,23H5a.5.5,0,0,1,0-1h5a.5.5,0,0,1,0,1Z"/><path class="a" d="M19,19H5a.5.5,0,0,1-.5-.5V6.5a.5.5,0,0,1,.146-.354l6-6A.5.5,0,0,1,11,0h8a.5.5,0,0,1,.5.5v18A.5.5,0,0,1,19,19ZM5.5,18h13V1H11.207L5.5,6.707Z"/><path class="a" d="M11,7H5A.5.5,0,0,1,5,6h5.5V.5a.5.5,0,0,1,1,0v6A.5.5,0,0,1,11,7Z"/></svg>',
    custom: true,
  },
  'user-dashboard': {
    svg: '<svg class="custom" xmlns="http://www.w3.org/2000/svg" width="19.004" height="20.004" viewBox="0 0 19.004 20.004"><path class="a" d="M4.5,14.5h8V4.5h-8Zm0,8h8v-6h-8Zm10,1h8v-10h-8Zm0-19v6h8v-6Z" transform="translate(-4 -4)"/></svg>',
    custom: true,
  },
  associate: {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g transform="translate(0)"><path class="a" d="M8.931,11.022a.726.726,0,0,1-.515-.213,3.419,3.419,0,0,1,0-4.831L11.1,3.29a3.416,3.416,0,1,1,4.832,4.831L14.708,9.35a.728.728,0,0,1-1.03-1.03l1.229-1.229A1.96,1.96,0,0,0,12.134,4.32L9.446,7.008a1.962,1.962,0,0,0,0,2.772.728.728,0,0,1-.515,1.243ZM6.351,16.29a3.416,3.416,0,0,1-2.416-5.831L5.164,9.229a.728.728,0,0,1,1.03,1.03L4.965,11.488a1.96,1.96,0,0,0,2.773,2.772l2.689-2.688a1.962,1.962,0,0,0,0-2.772.728.728,0,1,1,1.03-1.03,3.419,3.419,0,0,1,0,4.831L8.767,15.289a3.4,3.4,0,0,1-2.416,1Z" transform="translate(-2.936 -2.29)"/></g></svg>',
    custom: true,
  },
  'conformity-check': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs><style>.a{fill:none;}.b{fill:#169598;}</style></defs><rect class="a" width="24" height="24"/><path class="b" d="M5.434,16.293l-5.2-5.209a.8.8,0,0,1,0-1.133L1.366,8.818a.8.8,0,0,1,1.131,0L6,12.327l7.5-7.515a.8.8,0,0,1,1.131,0l1.131,1.133a.8.8,0,0,1,0,1.133l-9.2,9.215A.8.8,0,0,1,5.434,16.293Z" transform="translate(4 1.423)"/></svg>',
    custom: true,
  },
  dragdots: {
    svg: '<svg fill="#999" width="20"  viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M686.211 137.143v-.137l68.572.137H686.21Zm0 1508.571c75.566 0 137.143 61.577 137.143 137.143S761.777 1920 686.211 1920c-75.702 0-137.142-61.577-137.142-137.143s61.44-137.143 137.142-137.143Zm548.572 0c75.566 0 137.143 61.577 137.143 137.143S1310.349 1920 1234.783 1920c-75.703 0-137.143-61.577-137.143-137.143s61.44-137.143 137.143-137.143ZM686.21 1097.143c75.566 0 137.143 61.577 137.143 137.143 0 75.565-61.577 137.143-137.143 137.143-75.702 0-137.142-61.578-137.142-137.143 0-75.566 61.44-137.143 137.142-137.143Zm548.572 0c75.566 0 137.143 61.577 137.143 137.143 0 75.565-61.577 137.143-137.143 137.143-75.703 0-137.143-61.578-137.143-137.143 0-75.566 61.44-137.143 137.143-137.143ZM686.21 548.57c75.566 0 137.143 61.578 137.143 137.143 0 75.566-61.577 137.143-137.143 137.143-75.702 0-137.142-61.577-137.142-137.143 0-75.565 61.44-137.143 137.142-137.143Zm548.572 0c75.566 0 137.143 61.578 137.143 137.143 0 75.566-61.577 137.143-137.143 137.143-75.703 0-137.143-61.577-137.143-137.143 0-75.565 61.44-137.143 137.143-137.143ZM686.21 0c75.566 0 137.143 61.577 137.143 137.143S761.776 274.286 686.21 274.286c-75.702 0-137.142-61.577-137.142-137.143S610.509 0 686.21 0Zm548.503 0c75.566 0 137.143 61.577 137.143 137.143s-61.577 137.143-137.143 137.143c-75.565 0-137.143-61.577-137.143-137.143S1159.15 0 1234.714 0Z" fill-rule="evenodd"></path> </g></svg>',
    custom: true,
  },
  chain: {
    svg: '<svg id="icon_link_2" data-name="icon link 2" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">  <g id="Icon_link" transform="translate(2.375 2.375)">    <g id="ico_associer" transform="translate(0 0)">      <path id="Icon_metro-link" data-name="Icon metro-link" d="M9.123,11.3a.749.749,0,0,1-.531-.22,3.529,3.529,0,0,1,0-4.986l2.775-2.774a3.526,3.526,0,1,1,4.987,4.985L15.084,9.576a.751.751,0,0,1-1.063-1.062L15.29,7.245a2.023,2.023,0,0,0-2.861-2.86L9.654,7.159a2.025,2.025,0,0,0,0,2.861A.751.751,0,0,1,9.123,11.3ZM6.46,16.737a3.525,3.525,0,0,1-2.493-6.018L5.235,9.451A.751.751,0,0,1,6.3,10.514L5.029,11.782a2.023,2.023,0,0,0,2.861,2.861l2.775-2.774a2.025,2.025,0,0,0,0-2.861.751.751,0,1,1,1.063-1.062,3.529,3.529,0,0,1,0,4.985L8.953,15.7A3.5,3.5,0,0,1,6.46,16.737Z" transform="translate(-2.936 -2.29)" fill="#999"/>    </g>    <g id="Bounding_Boxes" transform="translate(1.431 1.735)">      <path id="Tracé_2808" data-name="Tracé 2808" d="M0,0H11.281V11.281H0Z" fill="none"/>    </g>  </g>  <rect id="Rectangle_16184" data-name="Rectangle 16184" width="19" height="19" fill="none"/></svg>   <path id="Icon_drag_drop" data-name="Icon drag drop" d="M1.125,2.25A1.125,1.125,0,1,1,2.25,1.125,1.124,1.124,0,0,1,1.125,2.25Zm4.5,0A1.125,1.125,0,1,1,6.75,1.125,1.124,1.124,0,0,1,5.625,2.25Zm4.5,0A1.125,1.125,0,1,1,11.25,1.125,1.124,1.124,0,0,1,10.125,2.25Z" transform="translate(2.25) rotate(90)" fill="#637381"/>   <path id="Icon_drag_drop-2" data-name="Icon drag drop" d="M1.125,2.25A1.125,1.125,0,1,1,2.25,1.125,1.124,1.124,0,0,1,1.125,2.25Zm4.5,0A1.125,1.125,0,1,1,6.75,1.125,1.124,1.124,0,0,1,5.625,2.25Zm4.5,0A1.125,1.125,0,1,1,11.25,1.125,1.124,1.124,0,0,1,10.125,2.25Z" transform="translate(6.25) rotate(90)" fill="#637381"/> </svg> ',
    custom: true,
  },
  hide: {
    svg: '<svg id="icon_non_visible" data-name="icon non visible" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">   <defs>     <clipPath id="clip-path">       <path id="Exclusion_3" data-name="Exclusion 3" d="M20,19H0V0H20V19ZM5.719,3.559,4.481,4.78,15.618,15.773l1.237-1.221L5.719,3.559Z" transform="translate(0.001 -0.001)" fill="#fff"/>     </clipPath>   </defs>   <rect id="Rectangle_17606" data-name="Rectangle 17606" width="24" height="24" fill="none"/>   <g id="Groupe_19739" data-name="Groupe 19739" transform="translate(-371.001 -572.999)">     <g id="Groupe_de_masques_42" data-name="Groupe de masques 42" transform="translate(373 576.001)" clip-path="url(#clip-path)">       <g id="icon_non_visible-2" data-name="icon non visible" transform="translate(0.001 -1.001)">         <rect id="Rectangle_15722" data-name="Rectangle 15722" width="21" height="21" fill="none"/>         <g id="Ico_visibilite" transform="translate(3.45 5.175)">           <path id="Tracé_8667" data-name="Tracé 8667" d="M1.5,11.018S4.009,6,8.4,6s6.9,5.018,6.9,5.018-2.509,5.018-6.9,5.018S1.5,11.018,1.5,11.018Z" transform="translate(-1.5 -6)" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>           <path id="Tracé_8668" data-name="Tracé 8668" d="M17.381,15.284a1.947,1.947,0,0,1-3.881,0,1.947,1.947,0,0,1,3.881,0Z" transform="translate(-8.54 -10.266)" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>         </g>       </g>     </g>     <g id="Groupe_19738" data-name="Groupe 19738" transform="translate(374.725 578.657)">       <path id="Tracé_9837" data-name="Tracé 9837" d="M0,0,13.951,13.839" fill="none" stroke="#999" stroke-linecap="round" stroke-width="2"/>     </g>   </g> </svg> ',
    custom: true,
  },
  delete: {
    svg: '<svg id="Ico_Supprimer" data-name="Ico Supprimer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">   <rect id="Rectangle_15762" data-name="Rectangle 15762" width="24" height="24" fill="none"/>   <path id="Tracé_10371" data-name="Tracé 10371" d="M6,18a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V7H6ZM8,9h8v9H8Zm7.5-5-1-1h-5l-1,1H5V6H19V4Z" transform="translate(0 1)" fill="#999999 "/> </svg> ',
    custom: true,
  },
  deleteActive: {
    svg: '<svg id="Ico_Supprimer" data-name="Ico Supprimer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">   <rect id="Rectangle_15762" data-name="Rectangle 15762" width="24" height="24" fill="none"/>   <path id="Tracé_10371" data-name="Tracé 10371" d="M6,18a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V7H6ZM8,9h8v9H8Zm7.5-5-1-1h-5l-1,1H5V6H19V4Z" transform="translate(0 1)" fill="#169598 "/> </svg> ',
    custom: true,
  },
  skip: {
    svg: '<svg id="icon_skip" data-name="icon skip" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">   <rect id="Rectangle_17607" data-name="Rectangle 17607" width="24" height="24" fill="none"/>   <path id="Union_22" data-name="Union 22" d="M3635,9326a7,7,0,1,1,7,7A7,7,0,0,1,3635,9326Zm2,0a5,5,0,0,0,7.842,4.112l-6.977-6.919A4.966,4.966,0,0,0,3637,9326Zm9.227,2.668a5,5,0,0,0-6.929-6.872Z" transform="translate(-3630 -9314)" fill="#999"/> </svg> ',
    custom: true,
  },
  flag_en: {
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="24.002" height="17"><g data-name="Ico Drapeau Angleterre"><path fill="#fff" d="M1.789 16.5h20.6a1.272 1.272 0 0 0 1.2-1.263V1.762A1.265 1.265 0 0 0 22.335.5H1.843A1.265 1.265 0 0 0 .589 1.762v13.471a1.273 1.273 0 0 0 1.2 1.267Z" data-name="Tracé 40441"/><path fill="#c74343" d="M10.481 10.1v6.4h3.2v-6.4h9.908V6.9h-9.908V.5h-3.2v6.4H.589v3.2Z" data-name="Tracé 40442"/><path fill="#4e7ab5" d="M14.748 5.679V.5h7.6a1.27 1.27 0 0 1 1.158.8l-8.758 4.379Z" data-name="Tracé 40443"/><path fill="#4e7ab5" d="M14.748 11.321V16.5h7.646a1.278 1.278 0 0 0 1.108-.8l-8.754-4.379Z" data-name="Tracé 40444"/><path fill="#4e7ab5" d="M9.414 11.321V16.5H1.785a1.268 1.268 0 0 1-1.108-.808l8.737-4.371Z" data-name="Tracé 40445"/><path fill="#4e7ab5" d="M9.414 5.679V.5H1.835a1.267 1.267 0 0 0-1.158.808l8.737 4.371Z" data-name="Tracé 40446"/><path fill="#c74343" d="m23.589 1.854-7.937 3.979h1.775l6.162-3.083Z" data-name="Tracé 40451"/><path fill="#c74343" d="M8.51 11.166H6.735L.589 14.241v.9l7.937-3.975Z" data-name="Tracé 40452"/><path fill="#c74343" d="M4.972 5.837h1.775L.589 2.754v.89Z" data-name="Tracé 40453"/><path fill="#c74343" d="M19.185 11.162H17.41l6.179 3.1v-.892Z" data-name="Tracé 40454"/><path fill="#4e7ab5" d="M.589 5.834h3.187L.589 4.238Z" data-name="Tracé 40447"/><path fill="#4e7ab5" d="M23.589 5.833h-3.2l3.2-1.6Z" data-name="Tracé 40448"/><path fill="#4e7ab5" d="M23.589 11.166h-3.2l3.2 1.6Z" data-name="Tracé 40449"/><path fill="#4e7ab5" d="M.589 11.166h3.187l-3.187 1.6Z" data-name="Tracé 40450"/><path fill="none" stroke="#4e7ab5" stroke-linejoin="round" d="M1.763.5h20.476A1.265 1.265 0 0 1 23.5 1.763v13.474a1.265 1.265 0 0 1-1.261 1.263H1.763A1.258 1.258 0 0 1 .5 15.241V1.763A1.265 1.265 0 0 1 1.763.5Z" data-name="Tracé 40488"/></g></svg>`,
    custom: true,
  },
  flag_fr: {
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="24.04" height="17"><g fill-rule="evenodd" data-name="Ico Drapeau francais"><path fill="#f2faff" stroke="#a3b7cc" d="M15.86.5H8.175l.009 16h7.671Z" data-name="Tracé 40440"/><path fill="#f78f8f" stroke="#c74343" d="M15.86.5h6.394a1.283 1.283 0 0 1 1.286 1.264v13.472a1.283 1.283 0 0 1-1.294 1.264H15.86Z" data-name="Tracé 40438"/><path fill="#8bb7f0" stroke="#4e7ab5" d="M8.18.5H1.786A1.283 1.283 0 0 0 .5 1.764v13.472A1.283 1.283 0 0 0 1.794 16.5H8.18Z" data-name="Tracé 40439"/></g></svg>`,
    custom: true,
  },
  "error": {
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#de3618" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="8"></circle><line x1="12" y1="9" x2="12" y2="12"></line><line x1="12" y1="15" x2="12.01" y2="15"></line></svg>`,
    custom: true,
  },
  confidentiality_breach_grey: {
    svg: `<svg xmlns="http://www.w3.org/2000/svg" data-name="ico Bris de confidentialité" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 18921"/><path fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.446 13.048a5.333 5.333 0 0 0 .24-1.546v-5.41L11.5 3.773l-2.442.912" data-name="Tracé 50322"/><path fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m5.883 5.888-.564.2v5.411c0 4.638 6.184 7.729 6.184 7.729a15.683 15.683 0 0 0 4.344-3.385" data-name="Tracé 50323"/><path fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m3 3 17 17" data-name="Tracé 50324"/><path fill="#999" d="M6.122 5.601v8.19l2.723 3.672 2.723 2.542 3.937-4Z" data-name="Tracé 50325"/></svg>`,
    custom: true,
  },
  confidentiality_breach_green: {
    svg: `<svg xmlns="http://www.w3.org/2000/svg" data-name="ico Bris de confidentialité" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 18921"/><path fill="none" stroke="#169598" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.446 13.048a5.333 5.333 0 0 0 .24-1.546v-5.41L11.5 3.773l-2.442.912" data-name="Tracé 50322"/><path fill="none" stroke="#169598" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m5.883 5.888-.564.2v5.411c0 4.638 6.184 7.729 6.184 7.729a15.683 15.683 0 0 0 4.344-3.385" data-name="Tracé 50323"/><path fill="none" stroke="#169598" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m3 3 17 17" data-name="Tracé 50324"/><path fill="#169598" d="M6.122 5.601v8.19l2.723 3.672 2.723 2.542 3.937-4Z" data-name="Tracé 50325"/></svg>`,
    custom: true,
  },
  send: {
    svg: `<svg class="custom" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><defs><style>.cls-1{}</style></defs><title>icons8-email-send-96 (1)</title><path class="cls-1" d="M18.23,5.52l.25.25c-.3.39-.59.78-.9,1.16C15,10.1,12.36,13.3,9.69,16.45a1.82,1.82,0,0,0,.18,3c1.33,1.22,2.56,2.56,3.86,3.82s2.1,1,2.68-.57Q20.21,12.34,23.91,2A2.06,2.06,0,0,0,23.67.26,1.86,1.86,0,0,0,22,.11Q11.62,3.83,1.22,7.66c-1.42.52-1.6,1.41-.56,2.5S3,12.51,4.18,13.68c1.82,1.82,1.92,1.83,3.95.16Z" transform="translate(0 0)"/><path class="cls-1" d="M18.23,5.52,8.13,13.85c-2,1.67-2.12,1.66-3.95-.16C3,12.51,1.81,11.36.66,10.16s-.85-2,.56-2.5Q11.62,3.84,22,.11a1.86,1.86,0,0,1,1.62.15A2.06,2.06,0,0,1,23.91,2q-3.68,10.36-7.49,20.67c-.58,1.58-1.47,1.75-2.68.57s-2.53-2.6-3.86-3.82a1.82,1.82,0,0,1-.18-3C12.36,13.3,15,10.1,17.58,6.92c.31-.38.6-.77.9-1.16Z" transform="translate(0 0)"/></svg>`,
    custom: true,
  }
};

@Component({
  selector: 'ui-icon',
  templateUrl: './ui-icon.component.html',
  styleUrls: [ './ui-icon.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-icon',
  },
})
export class UiIcon {
  @Input('size') public size = UiIconSize.Small;

  @Input('modifier') public modifier = UiIconModifier.OnBright;

  @Input('name') public name: string;

  @ViewChild('dataContainer') dataContainer: ElementRef;

  public svgRendered: SafeHtml;
  public classes: any;

  constructor(private sanitizer: DomSanitizer) {
  }

  public ngOnInit(): void {
    if (!icons[this.name]) {
      throw new Error(`'${this.name}' icon cannot be found in collection.`);
    }

    if (this.data.custom) {
      this.sanitizeSVG(this.data);
    }

    this.classes = {
      [`ui-icon--vector_${this.modifier}`]: true,
      [`ui-icon--vector_${this.size}`]: true,
    };
  }

  public get data(): any {
    return icons[this.name] || '';
  }

  public sanitizeSVG(svgPath: any) {
    this.svgRendered = this.sanitizer.bypassSecurityTrustHtml(svgPath.svg);
  }
}
