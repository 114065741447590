import { Pipe, PipeTransform } from '@angular/core';
import { LanguageEnum } from '../interfaces/ILanguage';
import { I18nService } from '../services/I18nService';

interface PipeParams {
  timeOnly: boolean;
  locale: LanguageEnum;
}

@Pipe({
  name: 'dateAgo',
  pure: false, // will update display automatically with re-renders
})
export class DateAgoPipe implements PipeTransform {
  private SECOND = 1;

  private MINUTE = 60 * this.SECOND;

  private HOUR = 60 * this.MINUTE;

  private DAY = 24 * this.HOUR;

  private WEEK = 7 * this.DAY;

  private MONTH = 30 * this.DAY;

  private YEAR = 365 * this.DAY;

  constructor(private i18nService: I18nService) { }

  public transform(value: any, params: PipeParams): any {
    if (!value) {
      return value;
    }

    const locale = params?.locale ? params.locale as LanguageEnum : LanguageEnum.FRENCH;
    const timeOnly = params?.timeOnly ? params.timeOnly : false;

    const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);

    const intervals = {
      year: this.YEAR,
      month: this.MONTH,
      week: this.WEEK,
      day: this.DAY,
      hour: this.HOUR,
      minute: this.MINUTE,
      second: this.SECOND,
    };

    if (seconds < 29) {
      return this.i18nService.translate('interval.justNow', { locale });
    }

    let counter: number;
    for (const interval in intervals) {
      counter = Math.floor(seconds / intervals[interval]);
      if (counter > 0) {
        const intervalTranslation = this.i18nService.translate(interval, { locale });
        if (counter === 1 || intervalTranslation.endsWith('s')) {
          return this.i18nService.translate(timeOnly ? 'interval.ago.timeOnly' : 'interval.ago', {
            counter: `${counter}`,
            interval: intervalTranslation,
            locale,
          });
        }
        return this.i18nService.translate(timeOnly ? 'interval.agoPlurial.timeOnly' : 'interval.agoPlurial', {
          counter: `${counter}`,
          interval: intervalTranslation,
          locale,
        });
      }
    }
  }
}
