<a
  *ngIf="zohoLink"
  target="_blank"
  [href]="zohoLink"
  [tooltip]="'incidents.container.page.table.zoholink.tooltip'|t"
  (click)="$event.stopPropagation();"
>
  {{zohoTicketNumber}}
  <ui-icon
    size="medium"
    [name]="'chain'"
  ></ui-icon>
</a>