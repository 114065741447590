<div class="detail-container">
  <div
    #headerRef
    class="detail-container--header"
  >
    <ng-content select="[header]"></ng-content>
  </div>

  <div class="detail-container--body">
    <ng-content></ng-content>
  </div>
</div>