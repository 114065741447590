import { Component, Input } from "@angular/core";
import { AbstractDrawer } from "@common/modules/layout/components/drawer/AbstractDrawer";
import { Store } from "@ngxs/store";


@Component({
  selector: 'datasources-drawer',
  templateUrl: './datasources.drawer.component.html',
})
export class DatasourcesDrawerContainer extends AbstractDrawer {
  @Input() public data: {
    title: string;
    organizationSelected: { name: string, id: string; };
  };

  constructor(readonly store: Store) {
    super(store);
  }
}
