import { Component } from '@angular/core';

@Component({
  selector: 'td[ui-cell-with-buttons]',
  templateUrl: './ui-cell-with-buttons.component.html',
  host: {
    class: 'ui-table--cell',
    role: 'gridcell',
  },
})
export class UiCellWithButtons {}
