<div class="flex-box">
  <label ui-label>{{ label }}</label>
  <ng-content></ng-content>
</div>

<div class="value-container">
  <div
    *ngIf="!isEditing"
    class="value-container--value"
    [ngClass]="{'force-show-modify-icon': isHovering, 'disabled':disabled}"
    [id]="'inputContainer' + iteration"
    [tooltip]="'common.modify'| t: {locale: locale}"
    (click)="openEdit()"
  >
    <p
      class="value-container--value--input"
      [ngStyle]="inputStyle"
    >{{showEmptyPlaceholder ? emptyPlaceholder : value}}</p>
    <img
      alt="edit-icon"
      class="icon-modify"
      src="assets/favicons/icon_modify_disabled.svg"
    />
  </div>

  <div
    *ngIf="isEditing"
    class="value-container--edit-input"
    [class.showUnsavedWarning]="showUnsavedWarning && isDifferent"
  >
    <input
      *ngIf="normalInput && !textareaInput"
      class="value-container--edit-input--value"
      [placeholder]="placeholder"
      [ngClass]="onError ? 'value-container--edit-input--has-error' : null"
      [ngStyle]="inputStyle"
      [maxlength]="maxlength"
      [(ngModel)]="valueBinder"
      (ngModelChange)="handleInputEvent()"
    />

    <textarea
      *ngIf="textareaInput"
      class="value-container--edit-input--textarea"
      [placeholder]="placeholder"
      [ngClass]="onError ? 'value-container--edit-input--has-error' : null"
      [ngStyle]="inputStyle"
      [maxlength]="maxlength"
      [(ngModel)]="valueBinder"
      (ngModelChange)="handleInputEvent()"
    ></textarea>

    <div class="value-container--edit-input--action-container">

      <div class="value-container--edit-input--action-container--error-box">
        <p
          *ngIf="errorMessage"
          class="value-container--edit-input--action-container--error-box--message"
        >
          {{ errorMessage }}
        </p>
        <p
          *ngIf="showUnsavedWarning && isDifferent"
          class="value-container--edit-input--action-container--error-box--message"
        >
          {{ "common.action.confirm.change" | t:{locale: locale} }}
        </p>
      </div>

      <ui-confirmations-buttons
        [marginTop]="buttonsMarginTop"
        (confirmationEvent)="handleConfirmationEvent($event)"
      ></ui-confirmations-buttons>

    </div>
  </div>
</div>