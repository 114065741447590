import { Pipe, PipeTransform } from "@angular/core";
import { LanguageEnum } from "../interfaces/ILanguage";


@Pipe({
  name: 'timeDuration',
})
export class TimeDurationPipe implements PipeTransform {
  transform(value: number, params: { prefix: boolean, language?: LanguageEnum } = { prefix: true }): string {
    return this.millisToMinutesAndSeconds(value, params);
  }

  public millisToMinutesAndSeconds(duration: number, params: any): string {
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor(duration / (1000 * 60 * 60));
    const textSeconds = params.prefix ? 'sec' : (params.language === LanguageEnum.FRENCH ? 'secondes' : 'seconds');
    const textMinute = params.prefix ? 'min' : 'minute';
    const textMinutes = params.prefix ? 'min' : 'minutes';
    const textHour = params.prefix ? 'h' : (params.language === LanguageEnum.FRENCH ? 'heure' : 'heures');
    const textHours = params.prefix ? 'h' : (params.language === LanguageEnum.FRENCH ? 'heures' : 'hours');

    const hoursToDisplay = hours;
    // const minutesToDisplay = (minutes < 10) ? "0" + minutes : minutes;
    const minutesToDisplay = minutes;

    let valueToDisplay: string;

    if (hours > 0 && minutes > 0) {
      valueToDisplay = `${hoursToDisplay} ${hours > 1 ? textHours : textHour} ${minutesToDisplay} ${minutes > 1 ? textMinutes : textMinute}`;
    }

    if (hours > 0 && minutes === 0) {
      valueToDisplay = `${hoursToDisplay} ${hours > 1 ? textHours : textHour}`;
    }

    if (hours === 0 && minutes > 0) {
      valueToDisplay = `${minutesToDisplay} ${minutes > 1 ? textMinutes : textMinute}`;
    }

    if (duration < 60000) {
      const seconds = Math.floor(duration / 1000);

      valueToDisplay = `${seconds} sec`;
    }

    if (duration === 0) {
      valueToDisplay = `0 h`;
    }

    return valueToDisplay;
  }
}
