<div page [appPagePaths]="['mdRespond', 'datasources']"
[searchMode]="searchMode"
(organizationChanged)="organizationChanged($event)">
  <ng-container toolbar *ngIf="organizationGuid">
    <ui-table-total-tool [total]="total" [locale]="i18n.currentLocale"></ui-table-total-tool>
    <ui-table-refresh-tool
      [loading]="isDatasourceLoading"
      (click)="handleRefresh()"
    ></ui-table-refresh-tool>
  </ng-container>
  <div *ngIf="organizationGuid">
    <ui-card>
      <button
        ui-button
        *display="{ permissions: ['can_activate_datasource'] }"
        color="linkFlat"
        icon="plus"
        icon-size="small"
        (click)="createDatasource()"
      >
        {{ "datasources.table.action.create" | t }}
      </button>

      <button
        ui-button
        *display="{ permissions: ['can_activate_datasource'] }"
        color="linkFlat"
        [disabled]="!currentDatasourceSelected"
        [custom-icon]="true"
        [isSvg]="true"
        icon="icon_delete"
        icon-size="medium"
        (click)="openDeleteDialog()"
      >
        {{ "datasources.table.action.delete" | t }}
      </button>

      <button
        ui-button
        *display="{ permissions: ['can_activate_datasource'] }"
        color="linkFlat"
        [disabled]="!isSelectedDatasourceActivatable()"
        [custom-icon]="true"
        [isSvg]="true"
        icon="icon_power_on_off"
        icon-size="medium"
        (click)="openActivateDialog()"
      >
        {{ "datasources.table.action.activate" | t }}
      </button>

      <ng-container>
        <ui-static-table
          [loading]="isDatasourceLoading"
          [addPaddingOnFirst]="false"
          [dataSource]="datasource"
          (on-row-click)="handleRowClick($event)"
          [canSelectOnlyOne]="true"
          [columnsDef]="USECASES_COLUMN_DEF"
          (checkbox-event)="handleSelected($event)"
          [locale]="i18n.currentLocale"
        >
        </ui-static-table>
      </ng-container>
    </ui-card>

    <ui-dialog
      *ngIf="showDeleteDialog"
      [headtitle]="'datasources.table.action.delete.dialog.title' | t"
      [actionButton]="'datasources.table.action.delete' | t"
      [cancelButton]="'common.action.cancel' | t"
      [message]="deleteDatasourceMessage"
      (onButtonClick)="deleteDatasource($event)"
      [isLoading]="isDatasourceLoading"
    >
    </ui-dialog>
    <ui-dialog
      *ngIf="showActivateDialog"
      [headtitle]="'datasources.table.action.activate.dialog.title' | t"
      [actionButton]="'datasources.table.action.activate' | t"
      [cancelButton]="'common.action.cancel' | t"
      [message]="activateDatasourceMessage"
      (onButtonClick)="activateDatasource($event)"
      [isLoading]="isDatasourceLoading"
    >
    </ui-dialog>
  </div>
  <ng-container *ngIf="!organizationGuid">
    <span class="no-org-transition">{{ 'organizations_select.notSelected.message' | t }}</span>
  </ng-container>
</div>
