import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-labeled-avatar',
  templateUrl: './ui-labeled-avatar.component.html',
  styleUrls: [ './ui-labeled-avatar.component.scss' ],
})
export class UiLabeledAvatarComponent {
  @Input() public label: string;

  @Input() public user: any;

  @Input() public name: string;
}
