<table class="table-daily-data" *ngIf="data.length > 0">
  <thead>
    <tr>
      <th *ngFor="let column of data">{{ column.headerName }}</th>
    </tr>
  </thead>
  <tbody class="scrollable-tbody" [style.max-height]="maxContentHeight">
    <tr *ngFor="let row of data[0].content; let rowIndex = index">
      <td *ngFor="let column of data">
        {{ column.content[rowIndex] }}
      </td>
    </tr>
  </tbody>
  <tfoot *ngIf="displayFooter">
    <tr>
        <td *ngFor="let column of data">{{ column.footerName }}</td>
    </tr>
  </tfoot>
</table>