import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {
  public transform(str: string): any {
    if (!str) {
      return '?';
    }

    return str
      .replace(/[^a-zA-Z0-9 éÉ]/, '')
      .split(' ')
      .map((word) => word[0])
      .join('')
      .slice(0, 2);
  }
}
