import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { LanguageEnum } from '../../../interfaces/ILanguage';
import { normalizeString } from '../../../util/string-utils';
import { AbstractAutocomplete } from '../AbstractAutocomplete';

interface User {
  name: string;
  o365UserId: string;
}

@Component({
  selector: 'user-offline-autocomplete',
  templateUrl: './user-offline-autocomplete.component.html',
  styleUrls: [
    '../autocomplete.component.scss',
    './user-offline-autocomplete.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'user-offline-autocomplete',
  },
})
export class UserOfflineAutocomplete extends AbstractAutocomplete {
  @Input() offlineUsers: User[];
  @Input() canMultiSelect = false;
  @Input() idsToIgnore: string[] = [];
  @Input() locale: LanguageEnum = LanguageEnum.FRENCH;
  @Input() expandHeight = '20rem';

  @Output() elementClicked: EventEmitter<any> = new EventEmitter();
  @Output() selectedUsersChange = new EventEmitter();
  @Output() loadedList = new EventEmitter();

  public selectedUsersValue: User[] = [];
  public usersToDisplay: User[] = [];
  constructor() {
    super();
  }

  @Input()
  get selectedUsers(): User[] {
    return this.selectedUsersValue;
  }

  set selectedUsers(value) {
    this.selectedUsersValue = value;
    this.selectedUsersChange.emit(this.selectedUsersValue);
  }

  public searchItems(query: string, searchID: string, useIAMBackend?: boolean) {
    this.usersToDisplay = this.offlineUsers
      .filter((item) => !this.idsToIgnore.includes(item.o365UserId) && normalizeString(item.name).includes(normalizeString(query)))
      .sort((a, b) => normalizeString(a.name).localeCompare(normalizeString(b.name)));

    this.loadedList.emit(this.offlineUsers);
  }

  public onUserSelected(user: any, event?: MouseEvent): void {
    if (!user.isAlreadyClicked) {
      this.selectedUsersValue.push(user);

      if (this.canMultiSelect) {
        user.isAlreadyClicked = true;
        this.offlineUsers = this.offlineUsers.filter((u) => u.o365UserId !== user.o365UserId);
      }

      this.elementClicked.emit({
        item: user,
        itemName: `${user.name}`,
      });
    }

    event?.stopPropagation();
    event?.preventDefault();
  }

  public onUserClick(
    clickedUser: any,
    elementIndex: number,
    mouseEvent: MouseEvent
  ) {
    this.onUserSelected(clickedUser, mouseEvent);
    this.addRippleEffect(elementIndex, mouseEvent);
  }
}
