import { MobileService } from '../services/mobile.service';
import { ModuleWithProviders } from '@angular/core';
import { UiKitModule } from '@ui-kit/uikit.module';

export function getUikitModule(): ModuleWithProviders<UiKitModule> {
  return UiKitModule.bootstrap({
    // Vieille classe qu'on utilise dans le uikit mais à l'extérieur aussi
    // on l'inject au lieu de la dédoubler
    mobileServiceClass: MobileService,
  });
}
