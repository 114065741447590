import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { interval } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'sidebar-number',
  templateUrl: './sidebar-number.component.html',
  styleUrls: [ './sidebar-number.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'sidebar-number',
  },
})
export class SidebarNumber implements OnInit, OnDestroy {
  public readonly ELEMENT_NUMBER_ATTRIBUTE = 'elementNumber';

  private readonly ELEMENT_NUMBER_FETCHING_ATTRIBUTE = 'elementNumberFetching';

  private readonly TIME_BETWEEN_NUMBER_FETCHING = 10000;

  @Input() public sidebarElement: any;

  private subscribtion: Subscription;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  public ngOnInit() {
    this.subscribtion = interval(this.TIME_BETWEEN_NUMBER_FETCHING).subscribe(() => {
      this.subscribeToNumber();
    });
  }

  public ngOnDestroy() {
    this.subscribtion.unsubscribe();
  }

  private async subscribeToNumber() {
    if (!this.sidebarElement[this.ELEMENT_NUMBER_FETCHING_ATTRIBUTE]) {
      this.sidebarElement[this.ELEMENT_NUMBER_FETCHING_ATTRIBUTE] = true;

      this.sidebarElement
        .numberSubscription()
        .then((number) => {
          this.sidebarElement[this.ELEMENT_NUMBER_ATTRIBUTE] = number;

          setTimeout(() => {
            this.cdr.detectChanges();
          });
        })
        .finally(() => {
          this.sidebarElement[this.ELEMENT_NUMBER_FETCHING_ATTRIBUTE] = false;
        });
    }
  }
}
