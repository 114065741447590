<ng-container [formGroup]="group" *ngIf="isEditing; else static">
  <div class="flex-box center-horizontal">
    <label ui-label [name]="name" [required]="required">{{ label }}</label>
    <ng-content></ng-content>
  </div>
  <div
       [ngClass]="{
      vertical: style === styleEnum.VERTICAL,
      horizontal: style === styleEnum.HORIZONTAL
    }">
    <mat-radio-group
                     class="radio-group"
                     [ngClass]="{
        'radio-group--vertical': style === styleEnum.VERTICAL,
        'radio-group--horizontal': style === styleEnum.HORIZONTAL
      }"
                     [formControlName]="control">
      <mat-radio-button
                        class="radio-button"
                        *ngFor="let option of optionValues"
                        [value]="option"
                        (click)="onOptionSelect.emit(option)">
        {{
        optionsType
        ? (((option | lowercase) + '.' + optionsType) | t:{locale: locale})
        : (option)
        }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</ng-container>

<ng-template #static>
  <ui-static [label]="label" [value]="value"></ui-static>
</ng-template>