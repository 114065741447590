import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-title-description-button',
  templateUrl: './ui-title-description-button.component.html',
  styleUrls: [ './ui-title-description-button.component.scss' ],
})
export class UiTitleDescriptionButtonComponent {
  // Can't be named title because it will put a html title tag on it
  @Input('name') title: string;

  @Input() description: string;

  @Input() image: string;

  @Input() imageSize = '32px';

  @Input() rightImage: string;

  @Input() rightImageSize = '18px';

  @Input() textAlertColor = false;

  @Input() revokeStyle: boolean = false;

  @Input() withBorder = false;
}
