import { Component, Inject, Input } from '@angular/core';
import { IMobileService } from '../../interfaces/IMobileService';

@Component({
  selector: 'widget-title',
  templateUrl: './widget-title.component.html',
  styleUrls: [ './widget-title.component.scss' ],
})
export class WidgetTitle {
  @Input() title = '';

  constructor(@Inject("MobileService") public readonly mobileService: IMobileService) {}
}
