<div
  class="expendable-section"
  [ngClass]="{
    'border-around' : config.borderAround && !isCollapsed,
    'border-around-not-bottom': config.borderAround && !isCollapsed && config.borderStyle && !borderBottomWhenOpen,
    'border-style': config.borderStyle
  }"
  [class.border-bottom-open]="borderBottomWhenOpen"
>
  <div
    class="expendable-section--header"
    [class.pointer]="!readonly"
    [class.min-height]="config.borderStyle"
    (click)="toggleCollapse()"
  >
    <div
      class="expendable-section--header--complete-title"
      [class.min-height]="config.borderStyle"
      [ngStyle]="{backgroundColor : backgroundColor}"
      [class.border-collapsed]="isCollapsed  && !config.borderStyle"
      [class.border-open]="!isCollapsed && !config.borderStyle"
    >
      <ng-container *ngIf="config.chevronSide === expendableSidesEnum.LEFT">
        <ng-container *ngTemplateOutlet="chevron"></ng-container>
      </ng-container>
      <div class="expendable-section--header--complete-title--title">
        <ng-content select="[header]"></ng-content>
      </div>
      <ng-container *ngIf="config.chevronSide === expendableSidesEnum.RIGHT">
        <ng-container *ngTemplateOutlet="chevron"></ng-container>
      </ng-container>
    </div>
  </div>

  <div
    class="expendable-section--content"
    *ngIf="!isCollapsed"
  >
    <ng-content></ng-content>
  </div>
</div>

<ng-template #chevron>
  <ui-icon
    *ngIf="!readonly"
    class="expendable-section--header--chevron"
    modifier="on-header"
    [name]="isCollapsed ? 'chevron-right' : 'chevron-down'"
    size="medium"
  >
  </ui-icon>
</ng-template>