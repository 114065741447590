<label ui-label *ngIf="label">{{ label }}</label>

<div class="ui-autocomplete--input-group" #inputField>
  <ui-icon
           class="ui-autocomplete--clear"
           [name]="query || !isEmptyObject(value) ? 'close' : 'chevron-down'"
           (click)="onClear()"
           size="medium"
           modifier="on-input"></ui-icon>

  <input
         class="ui-autocomplete--input"
         [ngStyle]="inputStyle"
         [readOnly]="!isEmptyObject(value)"
         [placeholder]="placeholder"
         [value]="getDisplayValue() || query"
         (focusin)="showOverlay()"
         (click)="showOverlay()"
         (input)="onQueryInputChange($event)"
         (keydown)="onKeyDown($event)" />
</div>

<ng-template cdk-portal="">
  <div class="ui-autocomplete--overlay">
    <ng-container *ngIf="(status | async) === 'idle'; else spinner">
      <ng-container *ngIf="(data | async).length > 0; else noResults">
        <ng-container *ngFor="let option of data | async">
          <ui-select-autocomplete-option
                                         [template]="optionTemplate"
                                         (click)="onOptionClick($event)"
                                         [value]="option">
          </ui-select-autocomplete-option>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #noResults>
  <div class="ui-autocomplete--no-results">
    {{ 'ui.select-no-results' }}{{ this.query }}
  </div>
</ng-template>

<ng-template #spinner>
  <div class="ui-autocomplete--spinner-container">
    <ui-spinner></ui-spinner>
  </div>
</ng-template>