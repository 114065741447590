<div
  class="dates-container"
  leftSide
  *ngIf="formattedTimestamp"
>
  <img
    *ngIf="!hideIcon"
    [class.small]="intervalTypeEnum.SMALL"
    [class.big]="intervalTypeEnum.BIG"
    [src]="iconPath"
    alt="calendar"
  >
  <p class="history-timestamp">{{formattedTimestamp}}</p>
</div>