<div
  *ngIf="showLabel"
  class="flex-box center-horizontal"
>
  <label
    ui-label
    [required]="false"
  >{{'org_usecase.create_drawer.affectedRessources' | t}}</label>
  <ui-tooltip width="400px">{{'org_usecase.create_drawer.affectedRessources.tooltip._SKIP_INTERPOLATION' | t}}</ui-tooltip>
</div>

<ui-static
  *ngIf="!isEditing"
  [value]="value || '-'"
></ui-static>

<ui-text-field
  *ngIf="isEditing"
  maxlength="2000"
  [name]="controlName"
  [control]="controlName"
  [group]="formGroup"
  [rowsNumber]="1"
  [locale]="locale"
  [placeholder]="'org_usecase.create_drawer.affectedRessources.placeholder._SKIP_INTERPOLATION' | t"
>
</ui-text-field>