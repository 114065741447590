import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-select-autocomplete-option',
  templateUrl: './ui-select-autocomplete-option.component.html',
  styleUrls: [ './ui-select-autocomplete-option.component.scss' ],
})
export class UiSelectAutocompleteOptionComponent {
  @Input() template;

  @Input() displayValueKey: string;

  @Input() value: any;

  @Output('click') optionClickEmitter = new EventEmitter();

  constructor() {}

  public active = false;

  // Both setActiveStyles and setInactiveStyles are used by the keyManager
  // Even though they appear greyed out
  public setActiveStyles(): void {
    this.active = true;
  }

  public setInactiveStyles(): void {
    this.active = false;
  }

  public onOptionClick() {
    this.optionClickEmitter.emit(this);
  }
}
