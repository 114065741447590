<div
  [class]="classes"
  [ngClass]="{
    'normal-font': value < 100000,
    'small-font': value > 99999 && value < 1000000,
    'smaller-font': value > 999999
  }"
>
  {{ value }}
</div>
