<div class="ui-tabs--buttons-container">
  <div
    [ngClass]="{
      'ui-tabs--header--with-bottom-border': borderBelowHeader,
      'ui-tabs--small-header': hasSmallHeader,
      'ui-tabs--header': !hasSmallHeader && !hasNoLeftPadding,
      'ui-tabs--header-no-padding': !hasSmallHeader && hasNoLeftPadding,
      'ui-tabs--header--has-white-background': buttonContainerHasWhiteBackroundColor,
      'ui-tabs--header--has-bottom-margin': buttonContainerHasBottomMargin
    }"
  >
    <div
      *ngFor="let tab of tabs"
      [class.hidden-tab]="!tab.isShown"
      [class.not-clickable]="tabs.length <= 1"
    >
      <ng-container *ngIf="tab.tabImageActive">
        <img class="ui-tabs--image" [src]="tab.active ? tab.tabImageActive : tab.tabImageInactive" />
      </ng-container>
      <button
        *ngIf="tab.tabTitle"
        ui-button
        [disabled]="tab.isDisabled"
        [ngClass]="{
          'ui-tabs--small-header--button': hasSmallHeader,
          'ui-tabs--header--button': !hasSmallHeader,
          dark: color === 'listAction',
          'ui-tabs--icon--text': !!tab.icon,
          'ui-tabs--icon--text--padding-left': tab.tabImageActive
        }"
        [color]="color + 'Flat'"
        (click)="selectTab(tab, !tab.isDisabled); $event.stopPropagation()"
      >
        {{ tab.tabTitle }}
      </button>
      <button
        *ngIf="tab.icon"
        [ngStyle]="{'opacity': tab.iconOpacity, 'padding': '0 1rem 0 0'}"
        [title]="tab.tooltipTitle"
        ui-button
        [disabled]="tab.isDisabled"
        [ngClass]="{
          'ui-tabs--small-header--button': hasSmallHeader,
          'ui-tabs--header--button': !hasSmallHeader && !hasNoLeftPadding,
          'ui-tabs--header-no-padding--button':
            !hasSmallHeader && hasNoLeftPadding,
          dark: color === 'listAction',
          'ui-tabs--icon--icon': !!tab.icon
        }"
        [color]="color + 'Flat'"
        (click)="selectTab(tab, !tab.isDisabled); $event.stopPropagation()"
        [icon]="tab.icon"
        [isSvg]="true"
        [custom-icon]="true"
      ></button>
      <div
        [class]="underscore"
        [ngClass]="{
          'ui-tabs--underscore-hidden': !tab.active,
          'ui-tabs--small-underscore': hasSmallHeader,
          'ui-tabs--underscore': !hasSmallHeader
        }"
      ></div>
    </div>
  </div>

  <div
    class="tabs-actions-button"
    *ngIf="tabsActionsRef.children.length"
    (click)="onActionsClick(); $event.stopPropagation()"
  >
    <img src="assets/favicons/icon_three_dot.svg" />
  </div>
</div>

<div class="ui-tabs--content" [class.white-background]="hasWhiteBackroundColor">
  <ng-content></ng-content>
</div>

<ui-tabs-actions [tabsTitle]="tabsTitle">
  <div #tabsActionsRef>
    <ng-content select="[tabsActions]"></ng-content>
  </div>
</ui-tabs-actions>
