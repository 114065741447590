import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { prune } from 'projects/@common/utils/utils';
import { NEVER, Observable } from "rxjs";
import { DisplayRequirements, DisplayService } from '../display/display.service';

export type HttpHeaderOptions = { [name: string]: string[]; };

@Injectable()
export class FeatureFlagInterceptor implements HttpInterceptor {
  constructor(private displayService: DisplayService) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestHeaders = this.handleRequestHeaders(request);
    const hasFlagsOrServicesOrPermissions = request.headers.has('flags') || request.headers.has('services') || request.headers.has('permissions');

    if (hasFlagsOrServicesOrPermissions) {
      const requirements = this.retrieveRequirementsParams(request.headers);

      if (!this.displayService.meetsRequirements(requirements)) {
        return NEVER;
      }

      const clonedRequest = request.clone({ headers: new HttpHeaders(this.requestHeadersWithoutRequirements(requestHeaders, requirements)) });

      return next.handle(clonedRequest);
    }

    return next.handle(request);
  }

  private retrieveRequirementsParams(headers: HttpHeaders): DisplayRequirements {
    return prune({
      flags: headers.getAll('flags'),
      services: headers.getAll('services'),
      permissions: headers.getAll('permissions'),
    });
  }

  private handleRequestHeaders(request: HttpRequest<any>): HttpHeaderOptions {
    return request.headers.keys().reduce((allHeaders, key) => {
      allHeaders[key] = request.headers.getAll(key);
      return allHeaders;
    }, {});
  }

  private requestHeadersWithoutRequirements(requestHeaders: HttpHeaderOptions, flagRequirements: DisplayRequirements): HttpHeaderOptions {
    const filteredRequesHeaders = JSON.parse(JSON.stringify(requestHeaders));

    Object.keys(flagRequirements).forEach((key) => {
      delete filteredRequesHeaders[key];
    });

    return filteredRequesHeaders;
  }
}
