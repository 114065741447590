import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Eco } from 'projects/@common/definitions/eco';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { ConsoleType } from 'projects/@common/modules/layout/private.layout';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { EcoSessionState, SetUserNotificationEmail } from 'projects/@common/modules/session/state/session.state';
import { NotificationsApiService } from 'projects/@common/services/api/notifications/notifications-api.service';
import { CustomValidators } from 'projects/@common/utils/validators';
import { SessionService } from '../../../../../session/session.service';

@Component({
  selector: 'topbar-user-popover',
  templateUrl: './topbar-user-popover.component.html',
  styleUrls: [ './topbar-user-popover.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'topbar-user-popover',
    role: 'tooltip',
  },
})
export class TopbarUserPopover implements OnInit {
  public user: any;
  public organization: Eco.IOrganization;

  public currentEmail = "";
  public isEditingEmail = false;

  public formGroup: UntypedFormGroup;

  public showEmailAddress = false;

  constructor(
    private readonly sessionService: SessionService,
    private readonly store: Store,
    private readonly noticeService: NoticeService,
    public readonly i18n: I18nService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly notificationsApiService: NotificationsApiService,
    @Inject('CONSOLE_TYPE') public consoleType: ConsoleType
  ) {
    this.formGroup = this.formBuilder.group({
      email: this.formBuilder.control('', [ Validators.required, CustomValidators.validateEmailAddress ]),
    });
    this.showEmailAddress = this.consoleType !== ConsoleType.SG;
  }

  public ngOnInit() {
    this.user = this.store.selectSnapshot(EcoSessionState.user);
    this.organization = this.store.selectSnapshot(EcoSessionState.organization);
    this.refreshCurrentEmail();
  }

  public get currentLocale(): string {
    return this.i18n.currentLocale;
  }

  public toggleLocale() {
    const locale = this.currentLocale === 'fr' ? 'en' : 'fr';
    this.i18n.setCurrentLocale(locale, true);
    this.noticeService.info('topbar.user.popover.actions.locale.changed', { locale: locale });
  }

  public logout() {
    this.sessionService.invalidateSession();
  }

  public copyHmac() {
    const hmacData = this.store.selectSnapshot(EcoSessionState.hmac);
    if (hmacData) {
      try {
        navigator.clipboard.writeText(JSON.stringify(hmacData));
        this.noticeService.snack("common.action.copied");
      } catch (_) { }
    }
  }

  public editEmailIconClicked(e: PointerEvent): void {
    // Canceling event to prevent the popover from closing.
    e.stopPropagation();
    this.toggleEditingEmail();
  }
  public toggleEditingEmail() {
    this.isEditingEmail = !this.isEditingEmail;
  }

  public async handleEmailConfirmation(event: boolean): Promise<void> {
    if (event) {
      try {
        const email = this.formGroup.getRawValue()['email'];
        await this.notificationsApiService.updateEmail(email);
        await this.store.dispatch(new SetUserNotificationEmail(email)).toPromise();
        this.formGroup.patchValue({
          email: '',
        });
        this.formGroup.get('email').updateValueAndValidity();
      } catch (error) {
        console.log(error);
      } finally {
        this.toggleEditingEmail();
        this.refreshCurrentEmail();
      }
    } else {
      this.toggleEditingEmail();
    }
  }

  private refreshCurrentEmail(): void {
    this.currentEmail = this.store.selectSnapshot(EcoSessionState.notificationEmail) || '-';
  }
}
