import { DatePipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { LanguageEnum } from '../interfaces/ILanguage';
import { I18nService } from '../services/I18nService';

export interface DatePipeParams {
  locale: LanguageEnum | string;
  withTime?: boolean;
  withSeconds?: boolean;
  mediumDate?: boolean;
}

@Injectable()
@Pipe({
  name: 'dt',
  pure: false,
})
export class DTPipe implements PipeTransform {
  constructor(private datepipe: DatePipe, private i18n: I18nService) {
  }

  public transform(value: any, params: DatePipeParams = { withTime: false, locale: LanguageEnum.FRENCH, withSeconds: false, mediumDate: false }): string {
    const output: string[] = [];
    if (value) {
      const isFrench = params.locale === LanguageEnum.FRENCH;
      const localeDateTime = isFrench ? 'fr-ca' : 'en';

      output.push(this.datepipe.transform(
        new Date(value),
        params.mediumDate ? (isFrench ? 'd MMM' : 'MMM d') : 'longDate',
        null,
        localeDateTime
      ));

      if (params.withTime) {
        output.push(this.getTimeSeparator(params.locale as LanguageEnum));
        output.push(this.datepipe.transform(
          new Date(value),
          params.withSeconds ? 'HH:mm:ss' : 'HH:mm',
          null,
          localeDateTime
        ));
      }
    }

    return output.join(' ');
  }

  private getTimeSeparator(locale: LanguageEnum): string {
    return this.i18n.translate("datetime.separator", { locale });
  }
}
