import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-card',
  templateUrl: './ui-card.component.html',
  styleUrls: [ './ui-card.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class UiCard {
  @Input() public withShadow = false;

  @Input() public withPadding = true;

  @Input() public isHoverable = false;

  @Input() public hasLargeBorder = false;

  @Input() public isSelected = false;

  @Input() public disabled = false;
}
