import { Component, ViewEncapsulation, Input, EventEmitter, Output, TemplateRef } from '@angular/core';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { UiTableDirection } from '../ui-table/ui-table.component';

@Component({
  selector: 'ui-collapsable-table',
  templateUrl: './ui-collapsable-table.component.html',
  styleUrls: [ './ui-collapsable-table.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-collapsable-table',
  },
})
export class UiCollapsableTable {
  @Input() public tableTitle: string;

  @Input() public headerIcon: string;

  @Input() public avatarOfficeId: string;

  @Input() public dynamicTitle: any;

  @Input() public spaceBetweenDynamicTitle = true;

  @Input() public isAvatar = false;

  @Input() public numberItems: number;

  @Input() public isHeaderIconPng = false;

  @Input() public dataSource: any[];

  @Input() public isLoading = false;

  @Input() public columnsDef: any[];

  @Input() public isCollapsable = true;

  @Input() public isCollapsed = true;

  @Input() public isBigHeaderImage = false;

  @Input() public canSelectItems = true;

  @Input() public addPaddingOnFirst = true;

  @Input() public sortHeader: string = null;

  @Input() public sort: string = null;

  @Input() public direction: UiTableDirection = UiTableDirection.None;

  @Input() public canLoadMore = false;

  @Input() public isLoadingMore = false;

  @Input() public isCustomSorting = false;

  @Input() public locale = LanguageEnum.FRENCH;

  @Input() expendedTemplate: TemplateRef<any>;

  @Output() public onLoadMore: EventEmitter<void> = new EventEmitter();

  @Output() public onRowClick: EventEmitter<any> = new EventEmitter();

  @Output() public handleCustomSort: EventEmitter<any> = new EventEmitter();

  @Output('checkbox-event') public checkboxEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output() public onToggleCollapse = new EventEmitter<boolean>();

  public handleRowClick(item: any) {
    this.onRowClick.emit(item);
  }

  public loadMore() {
    this.onLoadMore.emit();
  }

  public onCustomSortEmit(sortParams: any) {
    this.handleCustomSort.emit(sortParams);
  }

  public handleCheckboxEvent(event: any, item: any) {
    this.checkboxEventEmitter.emit({ event, item });
  }
}
