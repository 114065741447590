<table ui-table [dataSource]="values">
  <ng-container *ngFor="let variable of variables">
    <ng-container *ngIf="variable === 'name'">
      <ng-container uiColumnDef="name">
        <th ui-header-cell *uiHeaderCellDef></th>
        <td ui-cell *uiCellDef="let row">
          {{ row[variable] }}
        </td>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="variable !== 'name'">
      <ng-container [uiColumnDef]="variable">
        <th ui-header-cell ui-header-sort *uiHeaderCellDef>
          {{ variable }}
        </th>
        <td ui-cell ui-cell-with-buttons *uiCellDef="let row; let i = index">
          <ui-checkbox
                       [checked]="row[variable]"
                       [isEditable]="isEditing"
                       [controlName]="variable"
                       [group]="formGroup"
                       [arrayName]="formArrayName"
                       [groupName]="i"></ui-checkbox>
        </td>
      </ng-container>
    </ng-container>
  </ng-container>

  <tr ui-header-row *uiHeaderRowDef="variables"></tr>
  <tr ui-row *uiRowDef="let row; columns: variables"></tr>
</table>