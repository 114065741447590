import { Eco } from "projects/@common/definitions/eco";

export const AdmPermissionsMapping: Eco.PermissionsMapping = {

  // IAM
  create_organization_button: {
    action: 'iam:CreateOrganization',
  },

  organization_list: {
    action: 'iam:ListManagedOrganizations',
    resourceLabel: 'md:::iam:organizations/all',
  },

  // RESPOND
  can_list_toolbox_actions: {
    action: "respond:ListActions",
    resourceLabel: "md:::respond:actions/all",
  },

  can_execute_toolbox_actions: {
    action: "respond:ExecuteAction",
    resourceLabel: "md:::respond:actions/all",
  },

  can_get_kpis: {
    action: "respond:GetKpis",
  },

  can_onboard_respond_organization: {
    action: 'respond:OnboardOrganization',
    resourceLabel: 'md:::respond:organizations/all',
  },

  can_list_stacks: {
    action: 'respond:ListStacks',
    resourceLabel: 'md:::respond:stacks/all',
  },

  can_update_organization: {
    action: 'respond:UpdateOrganization',
    resourceLabel: 'md:::respond:organizations/all',
  },

  can_list_usecase_templates: {
    action: 'respond:ListUsecaseTemplates',
    resourceLabel: 'md:::respond:usecasecatalog/all',
  },

  can_list_usecases: {
    action: 'respond:ListUsecases',
    resourceLabel: 'md:::respond:usecases/all',
  },

  can_create_usecase: {
    action: 'respond:CreateUsecase',
    resourceLabel: 'md:::respond:usecases/all',
  },

  can_update_usecase: {
    action: 'respond:UpdateUsecase',
    resourceLabel: 'md:::respond:usecases/all',
  },

  can_delete_usecase: {
    action: 'respond:DeleteUsecase',
    resourceLabel: 'md:::respond:usecases/all',
  },

  can_list_datasources: {
    action: 'respond:ListDatasources',
    resourceLabel: 'md:::respond:datasources/all',
  },

  can_activate_datasource: {
    action: 'respond:ActivateDatasource',
    resourceLabel: 'md:::respond:datasources/all',
  },

  can_list_respond_organizations: {
    action: 'respond:ListOrganizations',
    resourceLabel: 'md:::respond:organizations/all',
  },

  can_list_playbooks: {
    action: 'respond:ListPlaybooks',
    resourceLabel: 'md:::respond:playbooks/all',
  },
  can_create_playbook: {
    action: 'respond:CreatePlaybook',
    resourceLabel: 'md:::respond:playbooks/all',
  },
  can_describe_playbook: {
    action: 'respond:DescribePlaybook',
    resourceLabel: 'md:::respond:playbooks/all',
  },
  can_delete_playbook: {
    action: 'respond:DeletePlaybook',
    resourceLabel: 'md:::respond:playbooks/all',
  },
  can_update_playbook: {
    action: 'respond:UpdatePlaybook',
    resourceLabel: 'md:::respond:playbooks/all',
  },
  can_update_playbook_version: {
    action: 'respond:UpdatePlaybookVersion',
    resourceLabel: 'md:::respond:playbooks/all',
  },
  can_update_playbook_outdated_tasks: {
    action: 'respond:UpdatePlaybookOutdatedTasks',
    resourceLabel: 'md:::respond:playbooks/all',
  },  
  
  can_list_soar_connectors: {
    action: 'respond:ListConnectors',
    resourceLabel: 'md:::respond:connectors/all',
  },
  can_create_soar_connectors: {
    action: 'respond:CreateConnector',
    resourceLabel: 'md:::respond:connectors/all',
  },
  can_update_soar_connectors: {
    action: 'respond:UpdateConnector',
    resourceLabel: 'md:::respond:connectors/all',
  },
  can_delete_soar_connectors: {
    action: 'respond:DeleteConnector',
    resourceLabel: 'md:::respond:connectors/all',
  },

  can_list_data_connectors: {
    action: 'detect:GetConnectors',
    resourceLabel: 'md:::detect:connectors/all',
  },
  can_create_data_connectors: {
    action: 'detect:CreateConnectorInstance',
    resourceLabel: 'md:::detect:connectors/all',
  },
  can_update_data_connectors: {
    action: 'detect:UpdateConnectorInstance',
    resourceLabel: 'md:::detect:connectors/all',
  },
  can_delete_data_connectors: {
    action: 'detect:DeleteConnectorInstance',
    resourceLabel: 'md:::detect:connectors/all',
  },

  can_update_monitoring_detect_connectors: {
    action: 'detect:UpdateConnectorInstanceMonitoring',
    resourceLabel: 'md:::detect:connectors/all',
  },

  can_list_detect_hosts: {
    action: 'detect:GetHosts',
    resourceLabel: 'md:::detect:mdedge/all',
  },
  can_update_detect_hosts_associations: {
    action: 'detect:UpdateConnectorAssociations',
    resourceLabel: 'md:::detect:mdedge/all',
  },
  can_update_detect_hosts: {
    action: 'detect:UpdateHost',
    resourceLabel: 'md:::detect:mdedge/all',
  },
  can_discover_detect_hosts: {
    action: 'detect:DiscoverHosts',
    resourceLabel: 'md:::detect:mdedge/all',
  },
  can_delete_detect_hosts: {
    action: 'detect:DeleteHost',
    resourceLabel: 'md:::detect:mdedge/all',
  },
  can_list_alerts: {
    action: 'respond:ListAlerts',
    resourceLabel: 'md:::respond:alerts/all',
  },

  can_describe_alert: {
    action: 'respond:DescribeAlert',
    resourceLabel: 'md:::respond:alerts/all',
  },

  can_update_alert: {
    action: 'respond:UpdateAlert',
    resourceLabel: 'md:::respond:alerts/all',
  },

  can_list_incidents: {
    action: 'respond:ListIncidents',
    resourceLabel: 'md:::respond:incidents/all',
  },

  can_describe_incident: {
    action: 'respond:DescribeIncident',
    resourceLabel: 'md:::respond:incidents/all',
  },

  can_update_incident: {
    action: 'respond:UpdateIncident',
    resourceLabel: 'md:::respond:incidents/all',
  },

  can_create_incident: {
    action: 'respond:CreateIncident',
    resourceLabel: 'md:::respond:incidents/all',
  },

  can_reopen_incident: {
    action: 'respond:ReopenIncident',
    resourceLabel: 'md:::respond:incidents/all',
  },

  can_read_escalation: {
    action: "respond:ReadEscalation",
    resourceLabel: "md:::respond:escalationContactLists/all",
  },
  can_write_escalation: {
    action: "respond:WriteEscalation",
    resourceLabel: "md:::respond:escalationContactLists/all",
  },
  can_update_escalation_parameter: {
    action: "respond:UpdateEscalationParameter",
    resourceLabel: "md:::respond:escalationParameters/all",
  },
  can_delete_escalation_contact: {
    action: "respond:DeleteEscalationContact",
    resourceLabel: "md:::respond:contacts/all",
  },
  can_delete_escalation_contact_list: {
    action: "respond:DeleteEscalationContactList",
    resourceLabel: "md:::respond:escalationContactLists/all",
  },

  can_read_custom_queries: {
    action: "respond:ListCustomQuery",
    resourceLabel: "md:::respond:queries/all",
  },

  can_read_histories: {
    action: "respond:ListHistories",
    resourceLabel: "md:::respond:histories/all",
  },

  can_list_assets: {
    action: 'respond:ListAssets',
    resourceLabel: 'md:::respond:assets/all',
  },
  can_describe_asset: {
    action: 'respond:DescribeAsset',
    resourceLabel: 'md:::respond:assets/all',
  },
  can_create_asset: {
    action: 'respond:CreateAsset',
    resourceLabel: 'md:::respond:assets/all',
  },
  can_update_asset: {
    action: 'respond:UpdateAsset',
    resourceLabel: 'md:::respond:assets/all',
  },
  can_delete_asset: {
    action: 'respond:DeleteAsset',
    resourceLabel: 'md:::respond:assets/all',
  },

  // TOOLS
  can_list_parameters: {
    action: 'tools:ListParameters',
    resourceLabel: 'md:::tools:parameter/all',
  },
  can_describe_parameter: {
    action: 'tools:DescribeParameter',
    resourceLabel: 'md:::tools:parameter/all',
  },
  can_create_parameter: {
    action: 'tools:CreateParameter',
    resourceLabel: 'md:::tools:parameter/all',
  },
  can_update_parameter: {
    action: 'tools:UpdateParameter',
    resourceLabel: 'md:::tools:parameter/all',
  },
  can_delete_parameter: {
    action: 'tools:DeleteParameter',
    resourceLabel: 'md:::tools:parameter/all',
  },
  can_list_secrets: {
    action: 'tools:ListSecrets',
    resourceLabel: 'md:::tools:secret/all',
  },
  can_create_secret: {
    action: 'tools:CreateSecret',
    resourceLabel: 'md:::tools:secret/all',
  },
  can_update_secret: {
    action: 'tools:UpdateSecret',
    resourceLabel: 'md:::tools:secret/all',
  },
  can_delete_secret: {
    action: 'tools:DeleteSecret',
    resourceLabel: 'md:::tools:secret/all',
  },
  can_create_var_secret: {
    action: 'tools:CreateSecret',
    resourceLabel: 'md:*::tools:secret/all',
  },
  can_update_var_secret: {
    action: 'tools:UpdateSecret',
    resourceLabel: 'md:*::tools:secret/all',
  },
  can_delete_var_secret: {
    action: 'tools:DeleteSecret',
    resourceLabel: 'md:*::tools:secret/all',
  },

  can_list_whitelists: {
    action: 'respond:ListWhitelists',
    resourceLabel: 'md:::respond:whitelists/all',
  },
  can_describe_whitelist: {
    action: 'respond:DescribeWhitelist',
    resourceLabel: 'md:::respond:whitelists/all',
  },
  can_create_whitelist: {
    action: 'respond:CreateWhitelist',
    resourceLabel: 'md:::respond:whitelists/all',
  },
  can_update_whitelist: {
    action: 'respond:UpdateWhitelist',
    resourceLabel: 'md:::respond:whitelists/all',
  },
  can_delete_whitelist: {
    action: 'respond:DeleteWhitelist',
    resourceLabel: 'md:::respond:whitelists/all',
  },

  can_list_filters: {
    action: 'respond:ListFilters',
    resourceLabel: 'md:::respond:filters/all',
  },
  can_describe_filter: {
    action: 'respond:DescribeFilter',
    resourceLabel: 'md:::respond:filters/all',
  },
  can_create_filter: {
    action: 'respond:CreateFilter',
    resourceLabel: 'md:::respond:filters/all',
  },
  can_update_filter: {
    action: 'respond:UpdateFilter',
    resourceLabel: 'md:::respond:filters/all',
  },
  can_delete_filter: {
    action: 'respond:DeleteFilter',
    resourceLabel: 'md:::respond:filters/all',
  },

  can_read_respond_reports: {
    action: 'respond:ListReports',
  },
};
