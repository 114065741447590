import { Component, OnInit } from '@angular/core';
import { GraphToolkitService } from '@common/services/graph-toolkit.service';
import { DisplayService } from 'projects/@common/modules/display/display.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { SessionService } from 'projects/@common/modules/session/session.service';

@Component({
  selector: 'adm-root',
  templateUrl: './adm.app.component.html',
})
export class AdmAppComponent implements OnInit {
  constructor(
    private i18n: I18nService,
    private session: SessionService,
    private display: DisplayService,
    private graphToolkitService: GraphToolkitService
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.i18n.initialize();
    this.session.loadBrowserSessionIfNeeded();
    await this.display.loadDataFromBrowserIfNeeded();
    await this.graphToolkitService.activateGraphToolkit();
    
  }
}
