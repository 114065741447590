import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sg-expendable-card',
  templateUrl: './sg-expendable-card.component.html',
  styleUrls: [ './sg-expendable-card.component.scss' ],
})
export class SgExpendableCardComponent implements OnInit {
  @Input() public customHeaderStyle = null;
  @Input() public colorOnHover = true;
  @Input() public isOpen = false;
  @Input() public stickyColor = false;
  @Input() public preventCollapse = false;

  @Output() public toggleOpenEvent = new EventEmitter<boolean>();
  @Output() onToggle: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public toggle(): void {
    if (this.preventCollapse) {
      return;
    }
    this.isOpen = !this.isOpen;
    this.toggleOpenEvent.emit(this.isOpen);
    this.onToggle.emit();
  }

  public open(): void {
    this.isOpen = true;
  }

  public close(): void {
    this.isOpen = false;
  }

  public get icon(): string {
    return `assets/favicons/${this.isOpen ? 'icon_chevron_open_dark' : 'icon_chevron_close_dark'}.svg`;
  }
}
