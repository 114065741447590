import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { randomBytes } from 'crypto';
import { LanguageEnum } from '../../interfaces/ILanguage';
import { parseIntSafe } from '../../services/utils';

@Component({
  selector: 'ui-date-picker',
  templateUrl: './ui-date-picker.component.html',
  styleUrls: ['./ui-date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-date-picker',
  },
})
export class UiDatePicker implements OnInit, OnChanges {
  @Input() public label: string;

  @Input() public name: string = randomBytes(4).toString('hex');

  @Input() public value = '';

  @Input() public required = false;

  @Input() public group: UntypedFormGroup;

  @Input() public control: string = this.name;

  @Input() public arrayName: string;

  @Input() public groupName: string;

  @Input() public minDate: Date;

  @Input() public maxDate: Date;

  @Input() public defaultTimestamp: number;

  @Input() public withClearButton = false;

  @Input() public disabled = false;

  @Input() public readonly = false;

  @Input() public placeholder: string = "";

  @Input() public locale: LanguageEnum = LanguageEnum.FRENCH;

  @Input() public uiTooltip: string;

  @Output() public onDateSelection: EventEmitter<number> = new EventEmitter();

  @ViewChild(MatDatepicker) matPicker!: MatDatepicker<any>;

  errorMessage: string;

  constructor(private readonly dateAdapter: DateAdapter<Date>) {
    if (this.locale === LanguageEnum.FRENCH) {
      this.dateAdapter.setLocale("fr-CA");
    }
  }

  public ngOnInit() {
    if (!this.group) {
      this.group = new UntypedFormGroup({
        dateControl: new UntypedFormControl(this.getDefaultDate()),
      });
      this.control = 'dateControl';
    }
  }

  public ngOnChanges() {
    try {
      if (
        this.group &&
        this.group.get(this.control) &&
        this.group.get(this.control).errors &&
        Object.entries(this.group.get(this.control).errors)[0][0] !== 'required'
      ) {
        this.errorMessage = `form.validation.${Object.entries(this.group.get(this.control).errors)[0][0]}`;
      } else {
        this.errorMessage = null;
      }
    } catch (error) {
      console.log(`ERROR on error handling: ${error}`);
    }
  }

  public onDateChange(event: MatDatepickerInputEvent<Date>) {
    this.onDateSelection.emit(event.value?.getTime());
  }

  public reset(): void {
    this.group.get("dateControl")?.setValue(null);
  }

  public getDefaultDate(): Date | null {
    let defaultDate: Date = null;
    const timestamp = parseIntSafe(this.defaultTimestamp);
    if (timestamp) {
      defaultDate = new Date(timestamp);
    }
    return defaultDate;
  }

  public setDefaultState(): void {
    this.group.get("dateControl")?.setValue(this.getDefaultDate());
  }

  public setValueNow(): void {
    const now = new Date();
    this.group.get("dateControl")?.setValue(now);
  }

  public setTimestamp(timestamp: number): void {
    const date = new Date(timestamp);
    this.group.get("dateControl")?.setValue(date);
  }

  public openMatPicker() {
    if (!this.disabled && !this.readonly) {
      this.matPicker.open();
    }
  }
}
