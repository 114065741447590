<div class="popover">
  <span class="popover--text">{{ value }}</span>
  <div
       class="popover-list"
       [ngClass]="{
      'right-aligned': !isLeftAligned,
      'left-aligned': isLeftAligned
    }">
    <h4>{{ popoverTitle }}</h4>
    <div *ngFor="let element of popoverValues">
      <span class="popover--text">{{ element }}</span>
    </div>
  </div>
</div>