import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'ui-table-export-tool',
  templateUrl: './ui-table-export-tool.component.html',
  styleUrls: [ './ui-table-export-tool.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-table-export-tool',
  },
})
export class UiTableExportTool {
  @Input('routerLink') public routerLink;

  @Input('loading') public loading = false;
}
