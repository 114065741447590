import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[uiNumberOnlyInput]',
})
export class UiNumberOnlyInputDirective {
  @Input() uiNumberMin: number;
  @Input() uiNumberMax: number;

  constructor() { }

  @HostListener('keypress', [ '$event' ])
  onKeyPress(event: KeyboardEvent) {
    const newChar = String.fromCharCode(event.charCode);
    if (!this.isCharCodeNumber(newChar)) {
      event.preventDefault();
    } else if (this.uiNumberMin !== undefined || this.uiNumberMax !== undefined) {
      const currentValue = event.target["value"];
      if (!this.isNumberInRange(newChar, currentValue)) {
        event.preventDefault();
      }
    }
  }

  private isCharCodeNumber(newChar: string): boolean {
    const pattern = /[0-9]/;
    const isDigit = pattern.test(newChar);
    return isDigit;
  }

  private isNumberInRange(newChar: string, currentInputValue: string): boolean {
    const value = (currentInputValue || "") + (newChar || "");
    if (!value) {
      return true;
    }

    const num = Number(value);
    if (isNaN(num)) {
      return false;
    }

    let greaterThanMin = true;
    if (this.uiNumberMin !== undefined) {
      greaterThanMin = num >= this.uiNumberMin;
    }

    let lessThanMax = true;
    if (this.uiNumberMax !== undefined) {
      lessThanMax = num <= this.uiNumberMax;
    }

    return greaterThanMin && lessThanMax;
  }
}
