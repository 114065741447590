import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EcoUrlService } from '../../core/url.service';
import { IAutomationOrganization, IOnboardOrganizationRequest, IUpdateOrganizationRequest } from './automation-organization.definitions';
import { IRespondResponse } from '../../../../definitions/IRespondResponse';
import { UrlUtils } from '../../../../utils/utils';
import { OrganizationParametersFilters } from 'projects/@common/services/definitions/fetchOrganizations.definition';

@Injectable({
  providedIn: 'root',
})
export class AutomationOrganizationsService {
  private readonly prefix = '/respond/automation/organizations';
  private readonly host: string;

  constructor(private http: HttpClient, private url: EcoUrlService) {
    this.host = url.baseUrl + this.prefix;
  }

  public getOrganizations(params?: OrganizationParametersFilters): Promise<IRespondResponse<IAutomationOrganization>> {
    return this.http.get<IRespondResponse<IAutomationOrganization>>(`${this.host}/${params ? `?${UrlUtils.jsonToQueryParams(params)}` : ''}`).toPromise();
  }

  public onboardOrganization(params: IOnboardOrganizationRequest): Promise<IAutomationOrganization> {
    return this.http.post<IAutomationOrganization>(this.host, params).toPromise();
  }

  public describeOrganization(organizationId: string): Promise<IAutomationOrganization> {
    return this.http.get<IAutomationOrganization>(`${this.host}/${organizationId}`).toPromise();
  }

  public updateOrganization(
    organizationId: string,
    params: IUpdateOrganizationRequest
  ): Promise<IAutomationOrganization> {
    return this.http.put<IAutomationOrganization>(`${this.host}/${organizationId}`, params).toPromise();
  }
}
