import { Component, Input, OnInit } from '@angular/core';
import { sortObjectKeys } from 'projects/@common/utils/utils';

@Component({
  selector: 'json-sorted-view',
  templateUrl: './json-sorted-view.component.html',
  styleUrls: [ './json-sorted-view.component.scss' ],
})
export class JsonSortedViewComponent implements OnInit {
  @Input() public jsonString: string;

  public sortedJsonString: string;

  constructor() { }

  ngOnInit(): void {
    this.sortJson();
  }

  public sortJson(): void {
    const content = JSON.parse(this.jsonString);
    const sortedContent = sortObjectKeys(content);
    this.sortedJsonString = JSON.stringify(sortedContent);
  }
}
