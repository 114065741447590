<div
  class="page-container"
  [ngClass]="{ 'page-container--editing': isEditing }"
  (scroll)="onScroll($event)"
>

  <div class="page-container--top-org">
    <div
      *ngIf="showOrgSelector && organizationInit"
      class="org-select"
    >
      <div class="org-select--autocomplete">
        <img
          class="image"
          src="assets/favicons/icon_organization.svg"
          rightIcon
          alt="Parameters"
        />
        <autocomplete
          #autocomplete
          [canMultiSelect]="false"
          [oneItemAtATime]="true"
          [showInInputAtInit]="true"
          [placeholder]="'detection.orgfilter.chooseorg' | t"
          name="autocomplete"
          [autocompleteType]="autocompleteTypes.ORGANIZATIONS"
          [initItems]="initItems"
          [searchFunction]="searchOrganizationFunction"
          [showSearchIcon]="true"
          (onItemSelected)="handleOrganizationSelection($event)"
          [allSelectorCustomValue]="allSelectorCustomValue"
        >
        </autocomplete>
      </div>
      <div
        *ngIf="!organizationSubscribed"
        class="org-select--notsubscribed"
      >
        <p>{{ 'organizations_select.notSubscribed.message' | t}}</p>
      </div>
    </div>
  </div>

  <div
    class="page-container--top-section"
    [ngClass]="{
      'page-container--mobile': mobileService.isMobile()
    }"
    *ngIf="hasTopSection"
  >
    <ng-content select="[topSection]"></ng-content>
  </div>

  <div
    class="page-container--top-buttons"
    [ngClass]="{
      'page-container--top-buttons--mobile': mobileService.isMobile()
    }"
    *ngIf="returnIcon"
  >
    <button
      ui-button
      icon="chevron-left"
      icon-size="medium"
      color="listActionFlat"
      (click)="goToPreviousLocation()"
    >
      {{ 'common.action.return' | t }}
    </button>

    <ng-content select="[topButtons]"></ng-content>
  </div>

  <header
    class="page-container--header"
    *ngIf="withPageHeader && !(mobileService.isMobile() && useMobileHeader)"
  >
    <div
      class="flex-box div-title center-horizontal"
      *ngIf="!mobileService.isMobile()"
    >
      <h1 class="page-container--title dashboard-title">
        {{ title$ | async }} {{ titleExtra }}
      </h1>
      <a
        class="contextual-help"
        [href]="contextualHelp"
        *ngIf='contextualHelp'
      > </a>
      <ui-tooltip
        *ngIf="tooltipTitle"
        class="title-tooltip"
        icon="assets/favicons/icon_info_small.svg"
      >
        {{ tooltipTitle | t }}
      </ui-tooltip>
      <div
        *ngIf="previewMode"
        class="page-container--beta"
      >
        {{ 'common.previewMode.' + previewMode | t }}
      </div>

    </div>

    <img
      *ngIf="appPage?.hasPublishedVersion && !mobileService.isMobile()"
      class="page-container--contextual-help-icon"
      src="assets/favicons/icon_contextual_help_small.svg"
      (click)="handleContextualHelpClick()"
    />

  </header>

  <header
    class="page-container--mobile-header"
    *ngIf="mobileService.isMobile() && useMobileHeader"
  >
    <h1 class="page-container--mobile-title">
      {{ title$ | async }} {{ titleExtra }}
    </h1>
  </header>

  <div class="page-container--content">
    <div [ngClass]="mobileService.isMobile() ? 'page-container--description-mobile' : 'page-container--description'">
      <ng-content select="[description]"></ng-content>
    </div>
    <div class="page-container--toolbar">
      <ng-content select="[toolbar]"></ng-content>
      <img
        *ngIf="appPage?.hasPublishedVersion && mobileService.isMobile()"
        class="page-container--contextual-help-icon"
        src="assets/favicons/icon_contextual_help_small.svg"
        (click)="handleContextualHelpClick()"
      />
    </div>
    <div class="page-container--content--with-padding">
      <ng-content></ng-content>
    </div>
    <div>
      <ng-content select="[zoneWithoutPadding]"></ng-content>
    </div>
  </div>

  <div
    class="backdrop"
    *ngIf="mobileService.isMobile() && (isSideBarExpanded$ | async) && !disableSidebar"
    (click)="backdropClick()"
  ></div>
</div>

<ui-form-actions
  *ngIf="isEditing"
  layout="centered"
  class="visible dark-actions-background"
>
  <ng-container *ngIf="isSaving">
    <ui-spinner
      size="medium"
      modifier="secondary"
    ></ui-spinner>
  </ng-container>
  <ng-container *ngIf="!isSaving">
    <button
      ui-button
      color="standard"
      (click)="onAction.emit()"
      [disabled]="!isValid"
    >
      {{ actionButtonText | t }}
    </button>
    <button
      ui-button
      color="secondary"
      (click)="onCancel.emit()"
    >
      {{ 'common.action.cancel' | t }}
    </button>
  </ng-container>
</ui-form-actions>