import { Component, Input, OnInit } from "@angular/core";

import { LanguageEnum } from "../../interfaces/ILanguage";
import { I18nService } from "../../services/I18nService";

interface ITranslatedField {
  en?: string;
  fr?: string;
}

@Component({
  selector: 'ui-preferred-language-tag',
  templateUrl: './ui-preferred-language-tag.component.html',
  styleUrls: [ './ui-preferred-language-tag.component.scss' ],
})
export class UiPreferredLanguageTagComponent implements OnInit {
  @Input()
    locale: LanguageEnum;

  @Input()
    preferedLang: LanguageEnum;

  public tooltipMessage: string;

  constructor(private readonly i18n: I18nService) { }

  ngOnInit(): void {
    this.tooltipMessage = this.getTooltipMessage();
  }

  public get chooseLang(): string {
    const catalogue: { [key in LanguageEnum]?: ITranslatedField } = {
      en: {
        fr: 'French',
        en: 'English',
      },
      fr: {
        fr: 'Français',
        en: 'Anglais',
      },
    };

    return catalogue[this.locale][this.preferedLang];
  }


  private getTooltipMessage(): string {
    const lang = this.i18n.translate(`common.language.${this.preferedLang}`, { locale: this.locale });
    return this.i18n.translate('common.language.communication', { locale: this.locale, language: lang });
  }
}
