import { Component, Input } from "@angular/core";

@Component({
  selector: 'capacity-card',
  templateUrl: './capacity-card.component.html',
  styleUrls: [ './capacity-card.component.scss' ],
})
export class CapacityCardComponent {
  @Input()
    logo?: string;

  @Input()
    capacityNumber: number;

  @Input()
    topDescription: string;

  @Input()
    bottomDescription: string;

  @Input()
    numberColor: string;

  @Input()
    loading = false;

  @Input()
    withBorder = false;
}
