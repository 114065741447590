import { State, StateContext, Action, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { clone } from 'projects/@common/utils/utils';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';


const SNACK_TIMER = 4000;
const SNACK_DESTROY_TIMER = 100;
const MAX_RANDOM_ID = 999999;

export enum DrawerSnackTaste {
  DELICIOUS = 'Delicious',
  NORMAL = 'Normal',
  INEDIBLE = 'Inedible'
}

export interface DrawerSnack {
  id: number;
  taste: DrawerSnackTaste;
  message: string;
  secondMessage?: string;
  hide?: boolean;
}

export interface SnackbarDrawerStoreModel {
  snacks: DrawerSnack[];
}

export const initialState: SnackbarDrawerStoreModel = {
  snacks: [],
};

export class CookDeliciousDrawerSnack {
  static readonly type = '[Snackbar Drawer] Cook a delicious snack!';

  public constructor(
    public message: string,
    public params: any = {},
    public secondMessage: string = null,
    public secondParams: any = {}
  ) {}
}

export class CookNormalDrawerSnack {
  static readonly type = '[Snackbar Drawer] Cook a normal snack.';

  public constructor(
    public message: string,
    public params: any = {},
    public secondMessage: string = null,
    public secondParams: any = {}
  ) {}
}

export class CookInedibleDrawerSnack {
  static readonly type = '[Snackbar Drawer] Cook an inedible snack!';

  public constructor(
    public message: string,
    public params: any = {},
    public secondMessage: string = null,
    public secondParams: any = {}
  ) {}
}

export class EatDrawerSnack {
  static readonly type = '[Snackbar Drawer] Eat snack.';

  public constructor(public snack: DrawerSnack) {}
}

export class ThrowDrawerSnackInTrash {
  static readonly type = '[Snackbar Drawer] Throw snack in trash.';

  public constructor(public snack: DrawerSnack) {}
}

@State<SnackbarDrawerStoreModel>({
  name: 'snackbarDrawer',
  defaults: clone(initialState),
})
@Injectable()
export class SnackbarDrawerState {
  public constructor(private i18nService: I18nService) {}

  @Selector()
  public static snacks(state: SnackbarDrawerStoreModel): DrawerSnack[] {
    return state.snacks;
  }

  @Action(CookDeliciousDrawerSnack)
  public createDeliciousSnack(ctx: StateContext<SnackbarDrawerStoreModel>, action: CookDeliciousDrawerSnack): void {
    const snacks = [ ...ctx.getState().snacks ];

    const snack: DrawerSnack = {
      id: Math.floor(Math.random() * Math.floor(MAX_RANDOM_ID)),
      taste: DrawerSnackTaste.DELICIOUS,
      message: this.i18nService.translate(action.message, action.params),
    };
    if (action.secondMessage) {
      snack.secondMessage = this.i18nService.translate(action.secondMessage, action.secondParams);
    }

    snacks.push(snack);

    ctx.patchState({
      snacks,
    });

    setTimeout(() => this.eatSnack(ctx, { snack }), SNACK_TIMER);
  }

  @Action(CookNormalDrawerSnack)
  public createNormalSnack(ctx: StateContext<SnackbarDrawerStoreModel>, action: CookNormalDrawerSnack): void {
    const snacks = [ ...ctx.getState().snacks ];

    const snack: DrawerSnack = {
      id: Math.floor(Math.random() * Math.floor(MAX_RANDOM_ID)),
      taste: DrawerSnackTaste.NORMAL,
      message: this.i18nService.translate(action.message, action.params),
    };
    if (action.secondMessage) {
      snack.secondMessage = this.i18nService.translate(action.secondMessage, action.secondParams);
    }

    snacks.push(snack);

    ctx.patchState({
      snacks,
    });

    setTimeout(() => this.eatSnack(ctx, { snack }), SNACK_TIMER);
  }

  @Action(CookInedibleDrawerSnack)
  public createInedibleSnack(ctx: StateContext<SnackbarDrawerStoreModel>, action: CookInedibleDrawerSnack): void {
    const snacks = [ ...ctx.getState().snacks ];

    const snack: DrawerSnack = {
      id: Math.floor(Math.random() * Math.floor(MAX_RANDOM_ID)),
      taste: DrawerSnackTaste.INEDIBLE,
      message: this.i18nService.translate(action.message, action.params),
    };
    if (action.secondMessage) {
      snack.secondMessage = this.i18nService.translate(action.secondMessage, action.secondParams);
    }

    snacks.push(snack);

    ctx.patchState({
      snacks,
    });

    setTimeout(() => this.eatSnack(ctx, { snack }), SNACK_TIMER);
  }

  @Action(EatDrawerSnack)
  public eatSnack(ctx: StateContext<SnackbarDrawerStoreModel>, action: EatDrawerSnack): void {
    const snacks = [ ...ctx.getState().snacks ];

    const snackToEat = clone(action.snack);
    snackToEat.hide = true;

    const itemIndex = snacks.findIndex((snack) => snack.id === snackToEat.id);
    snacks[itemIndex] = snackToEat;

    ctx.patchState({
      snacks,
    });

    setTimeout(() => this.throwSnackInTrash(ctx, { snack: snackToEat }), SNACK_DESTROY_TIMER);
  }

  @Action(ThrowDrawerSnackInTrash)
  public throwSnackInTrash(ctx: StateContext<SnackbarDrawerStoreModel>, action: ThrowDrawerSnackInTrash): void {
    let snacks = [ ...ctx.getState().snacks ];

    snacks = snacks.filter((snack) => snack.id !== action.snack.id);

    ctx.patchState({
      snacks,
    });
  }
}
