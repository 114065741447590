import { Injector, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dynamicPipe',
})
export class DynamicPipe implements PipeTransform {
  // Special pipe that receives another pipe as a parameter. Used by dynamic components that can display any value.

  public constructor(private injector: Injector) {}

  transform(value: any, injectedPipe: any, params?: any): any {
    if (!injectedPipe) {
      return value;
    }

    const pipe = this.injector.get<PipeTransform>(injectedPipe);
    if (params) {
      return pipe.transform(value, params);
    }
    return pipe.transform(value);
  }
}
