import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { AutocompleteCustomValue } from '@ui-kit/components/autocomplete/custom-autocomplete/custom-autocomplete.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';

// TypeScript complains about missing types for Intl
declare namespace Intl {
  function supportedValuesOf(input: string): string[];
  function DateTimeFormat(locale: string, options?: any): any;
}

@Component({
  selector: 'timezone-autocomplete',
  templateUrl: './timezone-autocomplete.component.html',
  styleUrls: [ './timezone-autocomplete.component.scss' ],
})
export class TimezoneAutocompleteComponent implements OnInit {
  @Input() defaultTimezoneValue: string;
  @Input() required: boolean;
  @Input() label: string;

  @Output() timezoneChange = new EventEmitter<AutocompleteCustomValue>();

  public customAutocompleteType = AutocompleteTypes.CUSTOM;
  public timezoneOptions: AutocompleteCustomValue[] = [];
  public selectedTimezones: AutocompleteCustomValue[] = [];

  constructor(private i18n: I18nService) { }

  ngOnInit(): void {
    this.initTimezoneOptions();
    if (this.defaultTimezoneValue) {
      const autocompleteOption = this.timezoneOptions.find((timezone) => timezone.value === this.defaultTimezoneValue);
      this.selectedTimezones = autocompleteOption ? [ autocompleteOption ] : [];
    }
  }

  public get selectedTimezone(): string {
    return this.selectedTimezones?.[0]?.value;
  }

  public handleTimezoneSelection(timezone: AutocompleteCustomValue) {
    this.selectedTimezones = timezone ? [ timezone ] : [];
    this.timezoneChange.emit(this.selectedTimezones ? this.selectedTimezones[0] : null);
  }

  private initTimezoneOptions() {
    const now = new Date();
    const timezones = Intl.supportedValuesOf('timeZone'); // sorted List of supported Zone and Link names in the IANA Time Zone Database.

    this.timezoneOptions = timezones.map((timezone) => {
      const dateFormatOptions = {
        timeZone: timezone,
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      };

      const timezonedDate = Intl.DateTimeFormat(this.i18n.dateCurrentLocale, dateFormatOptions).format(now);

      return {
        value: timezone,
        displayValue: `${timezone} (${timezonedDate})`,
      };
    });
  }
}
