<ui-date-picker
  #datePicker
  class="date-picker"
  [label]="label"
  [uiTooltip]="uiTooltip"
  [withClearButton]="false"
  [maxDate]="maxDate"
  [minDate]="minDate"
  [defaultTimestamp]="defaultTimestamp"
  [readonly]="isReadonly"
  [disabled]="isDisabled"
  [locale]="'fr'"
  [required]="required"
  placeholder="yyyy-mm-dd"
  (onDateSelection)="handleDateChangeEvent($event)"
>
</ui-date-picker>

<ui-time-picker
  #timePicker
  *ngIf="withHours"
  class="time-picker"
  [isReadonly]="isReadonly"
  [isDisabled]="isDisabled"
  [defaultTimestamp]="defaultTimestamp"
  (timeChangedEvent)="handleTimeChangeEvent($event)"
></ui-time-picker>

<div
  *ngIf="!isDisabled && !isReadonly"
  class="action-buttons"
>
  <ui-icon
    *ngIf="showNowButton"
    class="action-buttons--now"
    [tooltip]="'timepicker.now' | t:{locale:locale}"
    name="clockCheck"
    size="small"
    modifier="gray"
    (click)="handleClickNow()"
  ></ui-icon>
  <ui-icon
    *ngIf="showResetButton"
    class="action-buttons--reset"
    [tooltip]="'timepicker.reset' | t:{locale:locale}"
    name="close"
    size="medium"
    modifier="gray"
    (click)="handleClickReset()"
  ></ui-icon>
</div>