import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { ComponentInfo, HideDrawer, PreviousComponent, ReplaceComponent, SetDrawerIsEditing, ShowComponent, ToggleDrawerFullWdith } from '../stores/drawer.store';

@Injectable({
  providedIn: 'root',
})
export class DrawerService {
  private componentToShowInDrawer: any[] = [];
  private listNavigationSubject = new Subject<1 | -1>();
  private lastExpandedValue: boolean = false;

  public listNavigationObservable$ = this.listNavigationSubject.pipe(throttleTime(500));
  public readonly drawerIsClosingEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(private store: Store) {
    store.subscribe((state) => {
      if (state.drawer.expanded === false && state.drawer.expanded !== this.lastExpandedValue) {
        this.drawerIsClosingEvent.emit();
      }
      this.lastExpandedValue = state?.drawer?.expanded;
    });
  }

  public get component() {
    return this.componentToShowInDrawer.length
      ? this.componentToShowInDrawer[this.componentToShowInDrawer.length - 1]
      : null;
  }

  public get isEditing() {
    return this.store.selectSnapshot((state) => state.drawer.isEditing);
  }

  public showDrawer(component: any, componentInfo: ComponentInfo) {
    this.componentToShowInDrawer.push(component);
    this.store.dispatch(new ShowComponent(componentInfo));
  }

  public replaceCurrentDrawer(component: any, componentInfo: ComponentInfo) {
    if (this.componentToShowInDrawer.length) {
      this.componentToShowInDrawer[this.componentToShowInDrawer.length - 1] = component;
    } else {
      this.componentToShowInDrawer.push(component);
    }
    this.store.dispatch(new ReplaceComponent(componentInfo));
  }

  public previousDrawer() {
    if (this.componentToShowInDrawer.length) {
      this.componentToShowInDrawer.pop();
    }
    this.store.dispatch(new PreviousComponent());
  }

  public hideDrawer() {
    this.store.dispatch(new HideDrawer());
  }

  public setEditing(isEditing: boolean, tabName?: string) {
    this.store.dispatch(new SetDrawerIsEditing({ isEditing, tabName }));
  }

  public toggleFullWdith() {
    this.store.dispatch(new ToggleDrawerFullWdith());
  }

  public emitListNavigationEvent(direction: 1 | -1) {
    this.listNavigationSubject.next(direction);
  }
}
