import { HttpClient } from '@angular/common/http';
import { EcoUrlService } from '../../core/url.service';
import { GetManagedOrganizationResponse } from './connectors-api-organizations';
import { IConnectorOrganizationsApi, DescribeOrganizationResponse } from './definitions';
import { OrganizationParametersFilters } from 'projects/@common/services/definitions/fetchOrganizations.definition';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { UrlUtils } from 'projects/@common/utils/utils';

export class VarConnectorsApiOrganizationService extends EcoApi implements IConnectorOrganizationsApi {
  protected prefix = ApiEndpointPrefixEnum.detect_connectors;

  constructor(
    http: HttpClient,
    url: EcoUrlService
  ) {
    super(http, url);
  }

  public getManagedOrganizations(params?: OrganizationParametersFilters): Promise<GetManagedOrganizationResponse> {
    return this.http.get<any>(`${this.host}/organizations/managed${params ? `?${UrlUtils.jsonToQueryParams(params)}` : ''}`).toPromise();
  }

  public describeOrganization(organizationId: string): Promise<DescribeOrganizationResponse> {
    return this.http.get<any>(`${this.host}/organizations/${organizationId}/describe`).toPromise();
  }
}
