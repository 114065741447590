import { randomBytes } from 'crypto';
import {
  Component,
  ViewEncapsulation,
  Input,
  HostBinding,
  ChangeDetectionStrategy,
  OnInit,
  OnChanges
} from '@angular/core';
import { prune } from '../../services/utils';

const name = 'ui-label';

@Component({
  selector: 'label[ui-label]',
  templateUrl: './ui-label.component.html',
  styleUrls: [ './ui-label.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UiLabel implements OnInit, OnChanges {
  @Input('class') public class: string;

  @HostBinding('class') public classes: string;

  @HostBinding('attr.for') @Input('name') public name: string = randomBytes(4).toString('hex');

  @Input('required') public required = false;

  public ngOnInit(): void {
    this.setup();
  }

  public ngOnChanges(): void {
    this.setup();
  }

  public setup(): void {
    const classes = {
      [name]: true,
      [`${name}_required`]: this.required,
    };

    if (this.class) {
      classes[this.class] = true;
    }

    this.classes = Object.keys(prune(classes)).join(' ');
  }
}
