import { randomBytes } from 'crypto';
import { Component, Input, ViewEncapsulation } from '@angular/core';

const name = 'ui-spinner';

export enum UiSpinnerModifier {
  Primary = 'primary',
  Secondary = 'secondary',
  OnBright = 'on-bright',
  OnDark = 'on-dark'
}

export enum UiSpinnerSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

@Component({
  selector: 'ui-spinner',
  templateUrl: './ui-spinner.component.html',
  styleUrls: [ './ui-spinner.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-spinner',
  },
})
export class UiSpinner {
  @Input('modifier') public modifier: UiSpinnerModifier = UiSpinnerModifier.OnBright;

  @Input('size') public size: UiSpinnerSize = UiSpinnerSize.Medium;

  public hash = `spinner-${randomBytes(3).toString('hex')}`;

  public get classes(): string[] {
    return [ `${name}--vector_${this.modifier}`, `${name}--vector_${this.size}` ];
  }
}
