import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-indicator-arrow',
  templateUrl: './ui-indicator-arrow.component.html',
  styleUrls: [ './ui-indicator-arrow.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-indicator-arrow',
  },
})
export class UiIndicatorArrow implements OnInit {
  @Input() public value: number;

  @Input() public includePlusSign = true;

  @Input() public isCentered = false;

  @Input() public includeMinusSign = true;

  @Input() public fontColor = '#999';

  @Input() public fontWeight = '600';

  public displayedValue: number;

  public ngOnInit() {
    this.displayedValue = this.includeMinusSign ? this.value : Math.abs(this.value);
  }
}
