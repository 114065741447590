import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
  OnInit,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { randomBytes } from 'crypto';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LanguageEnum } from '../../interfaces/ILanguage';

@Component({
  selector: 'ui-text-field',
  templateUrl: './ui-text-field.component.html',
  styleUrls: [ './ui-text-field.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-text-field',
  },
})
export class UiTextField implements OnInit, OnChanges, OnDestroy {
  @Input('label') public label: string;

  @Input('name') public name: string = randomBytes(4).toString('hex');

  @Input('value') public value = '';

  @Input('placeholder') public placeholder = '';

  @Input('required') public required = false;

  @Input('readonly') public readonly = false;

  @Input('disabled') public disabled = false;

  @Input('group') public group: UntypedFormGroup;

  @Input('control') public control: string;

  @Input('arrayName') public arrayName: string;

  @Input('groupName') public groupName: string;

  @Input('maxlength') public maxlength = '';

  @Input('forceInvalid') public forceInvalid = false;

  @Input('rowsNumber') public rowsNumber = 4;

  @Input('jsonStyle') public jsonStyle = false;

  @Input('description') public description: string;

  @Input('uiTooltip') public uiTooltip: string;

  @Input('locale') public locale: LanguageEnum = LanguageEnum.ENGLISH;

  errorMessage: string;

  inputSubscription: Subscription;

  ngOnInit() {
    try {
      if (this.group?.get(this.control)) {
        this.inputSubscription = this.group.get(this.control).valueChanges.subscribe(() => {
          if (
            this.group?.get(this.control)?.errors &&
            Object.entries(this.group.get(this.control).errors)[0][0] !== 'required'
          ) {
            this.errorMessage = `form.validation.${Object.entries(this.group.get(this.control).errors)[0][0]}`;
          } else {
            this.errorMessage = null;
          }
        });
      }
    } catch (error) {
      console.log(`ERROR on error handling: ${error}`);
    }
  }

  ngOnChanges() {
    try {
      if (
        this.group?.get(this.control)?.errors &&
        Object.entries(this.group.get(this.control).errors)[0][0] !== 'required'
      ) {
        this.errorMessage = `form.validation.${Object.entries(this.group.get(this.control).errors)[0][0]}`;
      } else {
        this.errorMessage = null;
      }
    } catch (error) {
      console.log(`ERROR on error handling: ${error}`);
    }
  }

  ngOnDestroy() {
    this.inputSubscription?.unsubscribe();
  }

  // Helper to get formgroup content!
  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  public get isTextAreaInput(): boolean {
    return Number(this.rowsNumber) > 1;
  }
}
