import { Component, Input } from '@angular/core';

@Component({
  selector: 'mobile-sidebar-button',
  templateUrl: './mobile-sidebar-button.component.html',
  styleUrls: [ './mobile-sidebar-button.component.scss' ],
})
export class MobileSidebarButton {
  @Input() public buttonName: string;

  @Input() public icon: string;

  @Input() public nameSideIcon: string;

  @Input() public indicator = 0;
}
