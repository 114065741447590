import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { LanguageEnum } from '../interfaces/ILanguage';

@Injectable()
@Pipe({
  name: 'translatedObjectLocale',
  pure: true,
})
export class TranslatedObjectLocalePipe implements PipeTransform {
  public constructor() {
  }

  private readonly languages = Object.values(LanguageEnum);

  public transform(object: any, locale: LanguageEnum | string = LanguageEnum.FRENCH): string {
    let transformed = null;
    if (object) {
      if (object[locale]) {
        transformed = object[locale];
      } else {
        const fallbackLocale = this.languages.find((lang) => lang !== locale);
        transformed = object[fallbackLocale];
      }
    }
    return transformed;
  }
}
