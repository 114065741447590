import { Component, Input } from "@angular/core";

@Component({
  selector: 'ui-status-badge',
  templateUrl: './ui-status-badge.component.html',
  styleUrls: [ './ui-status-badge.component.scss' ],
})
export class UiStatusBadgeComponent {
  @Input() state = false;

  @Input() locale = 'fr';

  @Input() value: string;

  public get label(): string {
    const state = this.state ? 'active' : 'inactive';

    return this.value ? this.value : `activation.${state}.badge`;
  }
}
