<div
  class="mat-drawer-transition"
  *ngIf="!mobileService.isMobile() || isExpanded"
>
  <div>
    <div [ngClass]="
        (isDialogShown$ | async) && !(isDrawerExpanded$ | async)
          ? 'dialog-shown-cover'
          : ''
      "></div>
    <div [ngClass]="{ 'sidebar--container--parent': isExpanded }">
      <div [ngClass]="{ 'sidebar--container--child': isExpanded }">
        <div *ngFor="let element of navigationElements; let i = index">
          <ng-container>
            <div
              *ngIf="!element?.children && !element?.rootAction"
              class="sidebar--section"
              [class.notExpandedSection]="!isExpanded"
            >
              <span [class.sidebar-hidden]="!isExpanded">{{ element.name | t | uppercase }}</span>
            </div>
            <ng-container *ngIf="element?.children?.length > 0 || element?.rootAction">
              <div *display="{ permissions: element.permissions, orPermissions: element.orPermissions }">
                <button
                  *ngIf="!element.rootAction"
                  class="sidebar--button"
                  [attr.data-test-id]="'sidebar_button_' + element.name.replaceAll('.', '_')"
                  [class.isAdmin]="adminMode"
                  [class.notExpanded]="!isExpanded"
                  [class.isDropdownOpen]="element.state === 'expanded'"
                  ui-button
                  [ripple]="false"
                  #trigger
                  #origin="cdkOverlayOrigin"
                  cdk-overlay-origin
                  custom-icon="true"
                  custom-icon-size="large"
                  [isSvg]="true"
                  color="{{ adminMode ? 'sidenavAdmin' : 'sidenav' }}"
                  icon="{{ element.icon }}"
                  [activeLink]="isElementActive(element, router.url)"
                  (click)="handleExpand(element)"
                >
                  <div class="parent-element--button">
                    {{ element.name | t }}
                    <ui-icon
                      *ngIf="isExpanded"
                      name="{{
                      element.state === 'expanded'
                        ? 'chevron-down'
                        : 'chevron-right'
                    }}"
                      [modifier]="adminMode ? 'white' : 'sidenav'"
                    ></ui-icon>
                  </div>
                  <div
                    *ngIf="!isExpanded"
                    class="subMenu"
                  >
                    <div
                      class="subMenu--container"
                      [id]="'itemRef' + i"
                      [class.isAdmin]="adminMode"
                    >
                      <ng-container>
                        <div
                          *ngFor="let elementChild of element?.children"
                          class="sidebar--secondary"
                        >
                          <ng-container>
                            <div
                              (click)="
                              handleClick(
                                elementChild.route,
                                elementChild.queryParams
                              )
                            "
                              (mouseenter)="onMouseEnter($event, element)"
                            >
                              <button
                                ui-button
                                #trigger
                                #origin="cdkOverlayOrigin"
                                class="sidebar--secondary--button"
                                [attr.data-test-id]="'sidebar_button_' + elementChild.name.replaceAll('.', '_')"
                                [class.isAdmin]="adminMode"
                                [ripple]="false"
                                [class.notExpandedSubButton]="!isExpanded"
                                cdk-overlay-origin
                                [color]="
                                adminMode
                                  ? 'sidenavAdminChild'
                                  : 'sidenavChild'
                              "
                                routerLink="/{{ elementChild.route }}"
                                routerLinkActive="active"
                              >
                                <div class="child-element">
                                  {{ elementChild.name | t }}
                                </div>
                              </button>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </button>
                <a
                  class="sidebar-link"
                  [routerLink]="element.route"
                  role="navigation"
                >
                  <button
                    *ngIf="element.rootAction"
                    class="sidebar--button"
                    [attr.data-test-id]="'sidebar_button_' + element.name.replaceAll('.', '_')"
                    [class.isAdmin]="adminMode"
                    [class.notExpanded]="!isExpanded"
                    [class.isDropdownOpen]="element.state === 'expanded'"
                    [disabled]="element.disabled"
                    title="{{ (element.disabled ? 'common.coming.soon' : '') | t }}"
                    ui-button
                    [ripple]="false"
                    #trigger
                    #origin="cdkOverlayOrigin"
                    cdk-overlay-origin
                    custom-icon="true"
                    custom-icon-size="large"
                    [isSvg]="true"
                    color="{{ adminMode ? 'sidenavAdmin' : 'sidenav' }}"
                    icon="{{ element.icon }}"
                    [routerLink]="element.route"
                    [activeLink]="isElementActive(element, router.url)"
                    routerLinkActive="active"
                    (click)="handleExpand(element)"
                  >
                    <div class="parent-element--button">
                      {{ element.name | t }}
                      <div class="parent-element--button--beta">
                        <div
                          class="parent-element--button--beta--button"
                          *ngIf="element.preview"
                        >
                          {{ 'common.previewMode.' + element.preview | t }}</div>
                      </div>
                      <sidebar-number
                        *ngIf="element.numberSubscription"
                        [sidebarElement]="element"
                      ></sidebar-number>
                    </div>
                  </button>
                </a>
              </div>
            </ng-container>
            <div
              [id]="'itemRef' + i"
              class="sidebar--secondary--link {{
                element.state === 'expanded'
                  ? 'sidebar--menu_' + getElementChildCount(i)
                  : 'sidebar--menu-hidden'
              }}"
            >
              <ng-container *ngIf="isExpanded">
                <div *ngFor="let elementChild of element?.children" class="sidebar--secondary"
                     [ngClass]=" adminMode ? 'sidebar--secondary--admin-background' : 'sidebar--secondary--user-background'"
                >
                  <ng-container *display="{ permissions: elementChild.permissions, orPermissions:elementChild.orPermissions }">
                    <a
                      class="sidebar-link"
                      [routerLink]="elementChild.route"
                      role="navigation"
                    >
                      <div>
                        <button
                          ui-button
                          #trigger
                          #origin="cdkOverlayOrigin"
                          [attr.data-test-id]="'sidebar_button_' + elementChild.name.replaceAll('.', '_')"
                          class="sidebar--secondary--button"
                          [class.isAdmin]="adminMode"
                          [ripple]="false"
                          cdk-overlay-origin
                          [color]="adminMode ? 'sidenavAdminChild' : 'sidenavChild'"
                          [hide-icon]="isExpanded"
                          routerLink="/{{ elementChild.route }}"
                          routerLinkActive="active"
                          (click)="
                          handleClick(
                            elementChild.route,
                            elementChild.queryParams
                          )
                        "
                          (mouseenter)="onMouseEnter($event, element)"
                        >
                          <div class="child-element">
                            {{ elementChild.name | t }}
                          </div>
                          <div
                            class="parent-element--button--beta--button"
                            *ngIf="elementChild.preview"
                            style="line-height: 14px"
                          >
                            {{ 'common.previewMode.' + element.preview | t }}</div>
                        </button>
                      </div>
                    </a>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div
    class="sidebar-logo"
    *ngIf="mobileService.isMobile()"
  >
    <img
      [src]="mondataLogo"
      alt=""
    />
    <span>{{ 'common.slogan' | t }}</span>
  </div>
</div>
