<div
  [ngClass]="{
    'ui-card': true,
    'ui-card_shadow': withShadow,
    'ui-card_padding': withPadding,
    'ui-card_is-hoverable': isHoverable && !disabled,
    'ui-card_has-large-border': hasLargeBorder,
    'ui-card_is-selected': isSelected,
    'ui-card_is-disabled': disabled
  }"
>
  <ng-content></ng-content>
</div>
