import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UiTableDirection } from '../../ui-table/ui-table.component';

@Component({
  selector: 'ui-table-sort-tool',
  templateUrl: './ui-table-sort-tool.component.html',
  styleUrls: [ './ui-table-sort-tool.component.scss' ],
})
export class UiTableSortToolComponent implements OnInit {
  @Input() public direction: UiTableDirection;

  @Output() public directionEmitter: EventEmitter<UiTableDirection> = new EventEmitter();

  public showOptions = false;

  public options = [ UiTableDirection.Asc, UiTableDirection.Desc ];

  public optionIcons = {
    asc: 'arrow-asc',
    desc: 'arrow-desc',
  };

  constructor(private readonly ref: ElementRef) {}

  ngOnInit(): void {}

  @HostListener('document:click', [ '$event' ])
  clickout(event: any) {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.showOptions = false;
    }
  }

  public toggle() {
    this.showOptions = !this.showOptions;
  }

  public handleOption(direction: UiTableDirection) {
    this.direction = direction;
    this.directionEmitter.emit(direction);
    this.toggle();
  }
}
