<div class="ui-title-container" [ngClass]="{ 'after-space': afterSpace }">
  <div class="title-parent" [ngClass]="{ 'bottom-margin': !bottomBorder }">
    <div class="label-with-item-next-to-it">
      <label [ngClass]="{'large-font':largeFont}">{{ title }}</label>
      <ui-tooltip *ngIf="uiTooltipMessage">{{uiTooltipMessage}}</ui-tooltip>
      <ng-content select="[slot=nextToLabel]"></ng-content>
    </div>
    <ng-content></ng-content>
  </div>
</div>
<div [ngClass]="{ 'bottom-border': bottomBorder }"></div>