<div class="drawer-detail--scrollable visible">
  <div class="drawer-detail--content">
    <ui-title name="Informations"></ui-title>
    <div class="margin--small--top margin--small--bottom">
      <ui-field-group>
        <ui-static
          [label]="'datasources.tab.info.id' | t"
          [value]="data.datasource.id"
        ></ui-static>
      </ui-field-group>

      <ui-field-group>
        <ui-static
          [label]="'datasources.tab.info.implementation' | t"
          [value]="data.datasource.implementation"
        ></ui-static>
      </ui-field-group>

      <ui-field-group>
        <ui-static
          [label]="'datasources.tab.info.status' | t"
          [value]="
            'datasources.table.column.status.' + data.datasource.status | t
          "
          [iconName]="activatedIcon"
          [iconSide]="iconSide"
          [isCustomIcon]="true"
          [isSvg]="true"
        ></ui-static>
      </ui-field-group>

      <ui-field-group>
        <ui-static
          [label]="'datasources.tab.info.createdAt' | t "
          [value]="data.datasource.createdAt | date"
        ></ui-static>
      </ui-field-group>

      <ui-field-group>
        <ui-static
          [label]="'datasources.tab.info.updatedAt' | t"
          [value]="data.datasource.updatedAt | date"
        ></ui-static>
      </ui-field-group>

      <ui-title [name]="'datasources.tab.info.configuration' | t"></ui-title>
      <div class="margin--small--top margin--small--bottom">
        <ng-container *ngFor="let config of configsToDisplay">
          <ui-field-group>
            <ui-static
              [allowLineBreak]="true"
              [label]="config.label | t"
              [value]="config.value"
            ></ui-static>
          </ui-field-group>
        </ng-container>
      </div>
    </div>
  </div>
</div>
