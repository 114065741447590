<div
  class="indicator-arrow"
  [ngClass]="{
    'indicator-arrow--centered': isCentered
  }"
  [ngStyle]="{ color: fontColor, 'font-weight': fontWeight }"
>
  <div
    [ngClass]="{
      'indicator-up': value > 0,
      'indicator-down': value < 0,
      'indicator-stable': value == 0
    }"
  ></div>
  {{ value > 0 && includePlusSign ? '+' + displayedValue : displayedValue }}
</div>
