import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { MobileService } from 'projects/@common/services/mobile.service';
import { ExpendableTableHover } from '../users-table/expendable-table-hover';

export interface ISecurityGroup {
  id: string;
  name: string;
  description: string;
}

@Component({
  selector: 'security-groups-table',
  templateUrl: './security-groups-table.component.html',
  styleUrls: [ '../users-table/tables.component.scss', './security-groups-table.component.scss' ],
})
export class SecurityGroupsTableComponent implements OnInit {
  @Input() public securityGroups: ISecurityGroup[];

  @Input() public isEditing = false;

  @Input() public isLoading = false;

  @Output() public onSecurityGroupDelete = new EventEmitter();

  public direction: UiTableDirection = UiTableDirection.Asc;

  public directions = UiTableDirection;

  public isHovered = ExpendableTableHover.IS_HOVERED;

  constructor(public mobileService: MobileService) {
  }

  ngOnInit() {
  }

  public getDescription(description: string): string {
    if (!description) {
      return '-';
    }

    return `${description?.substring(0, 85).trimEnd()}${description?.length > 85 ? '...' : ''}` || '-';
  }

  public removeSecurityGroup(toRemove: ISecurityGroup): void {
    this.onSecurityGroupDelete.emit(toRemove);
  }

  public sort() {
    if (this.direction === UiTableDirection.Asc) {
      this.direction = UiTableDirection.Desc;
    } else {
      this.direction = UiTableDirection.Asc;
    }

    this.securityGroups.sort((group1, group2) => {
      if (group1.name.toLowerCase() > group2.name.toLowerCase()) {
        return this.direction === UiTableDirection.Asc ? 1 : -1;
      }
      if (group1.name.toLowerCase() < group2.name.toLowerCase()) {
        return this.direction === UiTableDirection.Asc ? -1 : 1;
      }
      return 0;
    });
  }
}
