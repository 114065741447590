<input
  #searchInput
  ui-input
  type="text"
  (valueChange)="search($event)"
>

<div class="image-container">
  <ng-container *ngFor="let image of filteredImages">
    <figure>
      <img
        class="image"
        (click)="copyPath(image)"
        [src]="'assets/favicons/' + image"
        [title]="image"
        alt="image"
      >
    </figure>
  </ng-container>
</div>