<ng-container *ngIf="!isSigninIn; else spinner">
  <div
    class="
    sign-in-background
    flex-box
    center-horizontal center-vertical
    base-layout-theme
  "
    *ngIf="loginForm"
  >
    <mat-card class="sign-in-card">
      <div class="card-container">
        <div class="sign-in-logo-container">
          <picture class="sign-in-logo">
            <source srcset="assets/images/logo-md.avif" type="image/avif">
            <source srcset="assets/images/logo-md.webp" type="image/webp">
            <img
              src="assets/images/logo-md.png"
              alt="logo"
            >
          </picture>
        </div>
        <div class="flex-box center-vertical center-horizontal form-field-container">
          <form
            [formGroup]="loginForm"
            class="sign-in-form"
          >
            <ng-container *ngIf="!isSigninIn; else spinner">
              <div class="flex-box column center-horizontal">
                <mat-form-field class="sign-in-username sign-in-form-field">
                  <input
                    placeholder="{{'sign.in.username' | t}}"
                    formControlName="username"
                    matInput
                  />
                </mat-form-field>
                <div class="checkbox">
                  <ui-checkbox
                    [group]="loginForm"
                    controlName="rememberMe"
                    isEditable="true"
                    [label]="'sign.in.rememberMe' | t"
                  ></ui-checkbox>
                </div>
                <div
                  class="tooltip-container"
                  [matTooltip]="'sign.in.enter.username' | t"
                  [matTooltipDisabled]="loginForm.valid || isSigninIn"
                  matTooltipClass="tooltip-style"
                >
                  <button
                    mat-raised-button
                    class="button sign-in-button"
                    [disabled]="!loginForm.valid || isSigninIn"
                    (click)="login()"
                  >
                    {{ 'sign.in.next.button' | t }}
                  </button>
                </div>
              </div>
            </ng-container>
          </form>
        </div>
        <span
          class="capslock"
          *ngIf="capslockOn === true"
        >{{
          'sign.in.capslock.detector' | t
          }}</span>
      </div>
    </mat-card>
  </div>
</ng-container>

<ng-template #spinner>
  <spinner></spinner>
</ng-template>