import {
  Component,
  Input,
  HostBinding,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  OnInit,
  OnChanges
} from '@angular/core';
import { prune } from '../../services/utils';

const name = 'ui-form-actions';

export enum UiFormActionsLayout {
  OnLeft = 'left',
  OnRight = 'right',
  Centered = 'centered'
}

@Component({
  selector: 'ui-form-actions',
  templateUrl: './ui-form-actions.component.html',
  styleUrls: [ './ui-form-actions.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UiFormActions implements OnInit, OnChanges {
  @Input('class') public class: string;

  @HostBinding('class') public classes = name;

  @Input('layout') public layout: UiFormActionsLayout = UiFormActionsLayout.OnLeft;

  public ngOnInit(): void {
    this.setup();
  }

  public ngOnChanges(): void {
    this.setup();
  }

  public setup(): void {
    const classes = {
      [name]: true,
      [`${name}_${this.layout}`]: true,
    };

    if (this.class) {
      classes[this.class] = true;
    }

    this.classes = Object.keys(prune(classes)).join(' ');
  }
}
