<ng-container *ngIf="!data['custom']">
  <svg class="ui-icon--vector mdi" [ngClass]="classes" viewBox="0 0 24 24">
    <path class="ui-icon--path" [attr.d]="data" />
  </svg>
</ng-container>
<ng-container *ngIf="data['custom']">
  <div
    class="{{ 'custom_' + modifier }} flex-box"
    [innerHTML]="svgRendered"
  ></div>
</ng-container>
