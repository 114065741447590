<ng-container *ngIf="isReady$ | async; else loader">
  <mat-sidenav
    #drawer
    position="end"
    mode="over"
    fixedInViewport="false"
    disableClose="true"
    [opened]="isDrawerExpanded$ | async"
  >
    <drawer [ngClass]="(isDrawerFullWidth$ | async) ? 'is-full-width' : ''"></drawer>
  </mat-sidenav>

  <div
    class="mat-drawer-backdrop"
    *ngIf="isEditing$ | async"
    [ngClass]="(isDrawerExpanded$ | async) ? 'mat-drawer-shown' : ''"
  ></div>

  <main-content class="private-layout--content">
    <ng-container *ngIf="!disableSidebar">
      <sidebar
        class="private-layout--sidebar"
        [class.isAdmin]="true"
        (toggle-menu)="handleToggleMenu()"
        [expanded]="isExpanded$ | async"
      ></sidebar>
    </ng-container>
    <ng-container
      top
      *ngIf="!disableHeader"
    >
      <nav
        class="private-layout--topbar"
        topbar
        [title]="title$ | async"
        (toggle-menu)="handleToggleMenu()"
      ></nav>
    </ng-container>

    <mat-sidenav-container>
      <mat-sidenav-content [class.no-scroll]="(mobileService.isMobile() && (isExpanded$ | async) && !disableSidebar)">
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </main-content>
</ng-container>

<ng-template #loader>
  <app-loader class="private-layout--loader"></app-loader>
</ng-template>