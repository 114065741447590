import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import get from "lodash.get";

export function prune(obj: any): any {
  return Object.entries(obj)
    .filter(([ , value ]) => Boolean(value))
    .reduce((accumulator, [ key, val ]) => Object.assign(accumulator, { [key]: val }), {});
}

export function parseIntSafe(value: any): number {
  if (typeof value === "number") {
    return parseInt(value.toString()); // Ensure clearing leading 0's. Like 00023786 --> 23786
  } else if (typeof value === "string" && value.length > 0) {
    const cleanString = value.replace(/\D+/g, ''); // Remove non-digit chars. Like ALR-00023786 --> 00023786
    if (!cleanString) {
      return null; // Don't parse an empty string to avoid the NaN return value
    }
    return parseInt(cleanString); //  Ensure clearing leading 0's. Like 00023786 --> 23786
  }
  return 0;
}


export function normalizeString(str: string): string {
  // CAfÉ bRûlé -> cafe brule
  if (typeof str !== "string") return "";
  return str.trim()?.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
}

export const clone = cloneDeep;
export const equal = isEqual;
export const getKey = get;
