<div class="container">
  <div
    class="container--badge"
    [ngClass]="
      state ? 'container--badge--active' : 'container--badge--inactive'
    "
  >
    <p
      class="container--badge--text"
      [ngClass]="
        state
          ? 'container--badge--text--active'
          : 'container--badge--text--inactive'
      "
    >
      {{ label | t: {locale:locale} }}
    </p>
  </div>
</div>