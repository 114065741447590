import { ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'copy-value',
  templateUrl: './copy-value.component.html',
  styleUrls: [ './copy-value.component.scss' ],
})
export class CopyValueComponent {
  @Input() valueToCopy: string;

  showTooltip: boolean = false;

  constructor(private ref: ChangeDetectorRef) { }

  copyValue(): void {
    try {
      navigator.clipboard.writeText(this.valueToCopy);
      this.showTooltip = true;
      setTimeout(() => this.hideTooltip(), 800);
    } catch (_) {
      this.hideTooltip();
    }
  }

  private hideTooltip(): void {
    this.showTooltip = false;
    this.ref.markForCheck();
  }
}
