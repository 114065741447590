<div class="collapsable-card" id="containerId">
  <button
          ui-button
          class="ui-table-filter-tool--button filter-button"
          color="linkBordedRegular"
          modifier="gray"
          (click)="toggleCollapse()"
          [wide]="true">
    {{ title }}
  </button>
  <div *ngIf="!isCollapsed" class="collapsable-card--content">
    <ng-content></ng-content>
  </div>
</div>