export class JsonUtils {
  public static prettifyJson(stringToPrettify: string, space = 4): string {
    try {
      return JSON.stringify(JSON.parse(stringToPrettify), undefined, space);
    } catch (error) {
      throw new Error(error);
    }
  }

  public static stringToJson(stringToConvert: string): Record<string, any> | Array<any> {
    try {
      return JSON.parse(stringToConvert);
    } catch (error) {
      throw new Error(error);
    }
  }
}
