import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { clone } from 'projects/@common/utils/utils';
import { I18nService } from '../../i18n/i18n.service';
import { IOrgSelectorOrganization } from '../components/page/page.component';

export enum AppStatus {
  Loading = 'loading',
  Ready = 'ready'
}

export enum AppPages {
  RESOURCES_WITH_SHARES_PAGE = 'resourcesWithShares'
}

export interface AppStateModel {
  status: AppStatus;
  popover: string | null;
  title: string | null;
  expanded: boolean;
  activePage: AppPages | null;
  isRedirecting: boolean;
  isTopbarDisabled: boolean;
  selectedOrganization: IOrgSelectorOrganization
}

export const initialState: AppStateModel = {
  status: AppStatus.Loading,
  popover: null,
  title: null,
  expanded: true,
  activePage: null,
  isRedirecting: false,
  isTopbarDisabled: false,
  selectedOrganization: null,
};

export class ResetApp {
  static readonly type = '[App] Reset';
}

export class SetAppStatus {
  static readonly type = '[App] Set Status';

  public constructor(public payload: { status: AppStatus }) { }
}

export class UpdateAppTitle {
  static readonly type = '[App] Update Title';

  public constructor(public payload: { key: string }) { }
}

export class SetPopover {
  static readonly type = '[App] Set Popover';

  public constructor(public payload: { name: string }) { }
}

export class SetExpanded {
  static readonly type = '[App] Set Expanded';

  public constructor(public payload: { expanded: boolean }) { }
}

export class SetActivePage {
  static readonly type = '[App] Set ActivePage';

  public constructor(public payload: { activePage: AppPages }) { }
}

export class UnsetPopover {
  static readonly type = '[App] Unset Popover';

  public constructor(public payload: { name: string }) { }
}

export class ResetPopover {
  static readonly type = '[App] Reset Popover';
}

export class SetTopbarState {
  static readonly type = '[App] Set Topbar state';

  public constructor(public payload: { disabled: boolean }) { }
}

export class SetSelectedOrganization {
  static readonly type = '[App] Set Selected Organization';

  public constructor(public payload: { selectedOrganization: IOrgSelectorOrganization }) { }
}

export class ClearSelectedOrganization {
  static readonly type = '[App] Clear Selected Organization';
}

@State<AppStateModel>({
  name: 'app',
  defaults: clone(initialState),
})
@Injectable()
export class AppState {
  public constructor(
    private store: Store,
    protected readonly i18n: I18nService,
    protected readonly titleService: Title
  ) { }

  @Selector()
  public static isReady(state: AppStateModel): boolean {
    return state.status === AppStatus.Ready;
  }

  @Selector()
  public static isPopoverActive(state: AppStateModel): (name: string) => boolean {
    return (name) => state.popover === name;
  }

  @Selector()
  public static isExpanded(state: AppStateModel): boolean {
    return state.expanded;
  }

  // @Selector()
  // public static activePage(state: AppStateModel): string {
  //   return state.activePage;
  // }

  @Selector()
  public static title(state: AppStateModel): string | null {
    return state.title;
  }

  @Selector()
  public static isRedirecting(state: AppStateModel): boolean {
    return state.isRedirecting;
  }

  @Selector()
  public static isTopbarDisabled(state: AppStateModel): boolean {
    return state.isTopbarDisabled;
  }

  @Selector()
  public static selectedOrganization(state: AppStateModel): IOrgSelectorOrganization {
    return state.selectedOrganization;
  }

  @Action(SetAppStatus)
  public setStatus(ctx: StateContext<AppStateModel>, { payload }: SetAppStatus): void {
    const { status } = payload;
    ctx.patchState({ status });
  }

  @Action(UpdateAppTitle)
  public updateTitle(ctx: StateContext<AppStateModel>, { payload }: UpdateAppTitle): void {
    const { key } = payload;
    const title = this.i18n.translate(key);
    this.titleService.setTitle(this.i18n.translate('common.document.title', {
      title,
    }));

    ctx.patchState({
      title,
    });
  }

  @Action(ResetApp)
  public reset(ctx: StateContext<AppStateModel>): void {
    ctx.setState(initialState);
  }

  @Action(SetPopover)
  public setPopover(ctx: StateContext<AppStateModel>, { payload }: SetPopover): void {
    const { name } = payload;
    ctx.patchState({
      popover: name,
    });
  }

  @Action(UnsetPopover)
  public unsetPopover(ctx: StateContext<AppStateModel>, { payload }: UnsetPopover): void {
    const { name } = payload;
    if (name !== ctx.getState().popover) {
      return;
    }
    ctx.patchState({
      popover: null,
    });
  }

  @Action(ResetPopover)
  public resetPopover(ctx: StateContext<AppStateModel>): void {
    ctx.patchState({
      popover: null,
    });
  }

  @Action(SetExpanded)
  public setExpanded(ctx: StateContext<AppStateModel>, { payload }: SetExpanded): void {
    const { expanded } = payload;
    ctx.patchState({
      expanded,
    });
  }

  @Action(SetActivePage)
  public setActivePage(ctx: StateContext<AppStateModel>, { payload }: SetActivePage): void {
    const { activePage } = payload;
    ctx.patchState({
      activePage,
    });
  }

  @Action(SetTopbarState)
  public setTopbarDisplay(ctx: StateContext<AppStateModel>, { payload }: SetTopbarState): void {
    const { disabled } = payload;
    ctx.patchState({
      isTopbarDisabled: disabled,
    });
  }

  @Action(SetSelectedOrganization)
  public setSelectedOrganization(ctx: StateContext<AppStateModel>, { payload }: SetSelectedOrganization): void {
    const { selectedOrganization } = payload;
    ctx.patchState({
      selectedOrganization,
    });
  }

  @Action(ClearSelectedOrganization)
  public clearSelectedOrganization(ctx: StateContext<AppStateModel>): void {
    ctx.patchState({
      selectedOrganization: null,
    });
  }
}
