import { Directive, ElementRef } from '@angular/core';
import { CdkHeaderCell, CdkColumnDef } from '@angular/cdk/table';

@Directive({
  selector: 'th[ui-header-cell]',
  host: {
    class: 'ui-table--header-cell',
    role: 'columnheader',
  },
})
export class UiHeaderCell extends CdkHeaderCell {
  constructor(columnDef: CdkColumnDef, elementRef: ElementRef<HTMLElement>) {
    super(columnDef, elementRef);
    elementRef.nativeElement.classList.remove(`cdk-column-${columnDef.cssClassFriendlyName}`);
    elementRef.nativeElement.classList.add(`ui-table--header-cell_${columnDef.cssClassFriendlyName}`);
  }
}
