import { Component, ViewEncapsulation } from '@angular/core';
import { Select } from '@ngxs/store';
import { MobileService } from 'projects/@common/services/mobile.service';
import { Observable } from 'rxjs';
import { MousePopup, MousePopupState } from '../../stores/mouse-popup.state';

@Component({
  selector: 'mouse-popup',
  templateUrl: './mouse-popup.component.html',
  styleUrls: [ './mouse-popup.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'mouse-popup',
  },
})
export class MousePopupComponent {
  @Select(MousePopupState.popups) public popups$: Observable<MousePopup[]>;

  constructor(public mobileService: MobileService) {}
}
