import { Injectable } from '@angular/core';
import { ConfigurationService } from '../../configuration.service';

@Injectable({
  providedIn: 'root',
})
export class EcoUrlService {
  private readonly url: string;
  private readonly alertingLocalUrl: string;
  private readonly automationLocalUrl: string;
  private readonly iamLocalUrl: string;
  private readonly connectorsLocalUrl: string;
  private readonly toolsLocalUrl: string;
  private readonly sgLocalUrl: string;
  private readonly notificationLocalUrl: string;
  private readonly requestsLocalUrl: string;
  private readonly backtestLocalUrl: string;

  constructor (private configService: ConfigurationService) {
    this.url = this.configService.config.ecoUrls.defaultUrl;

    this.alertingLocalUrl     = this.configService.config.ecoUrls.alertingLocalUrl;
    this.automationLocalUrl   = this.configService.config.ecoUrls.automationLocalUrl;
    this.iamLocalUrl          = this.configService.config.ecoUrls.iamLocalUrl;
    this.connectorsLocalUrl   = this.configService.config.ecoUrls.connectorsLocalUrl;
    this.toolsLocalUrl        = this.configService.config.ecoUrls.toolsLocalUrl;
    this.sgLocalUrl           = this.configService.config.ecoUrls.sgLocalUrl;
    this.notificationLocalUrl = this.configService.config.ecoUrls.notificationLocalUrl;
    this.requestsLocalUrl     = this.configService.config.ecoUrls.requestsLocalUrl;
    this.backtestLocalUrl     = this.configService.config.ecoUrls.backtestLocalUrl;
  }

  public get baseUrl(): string {
    return this.url;
  }

  public get alertingUrl(): string {
    return this.alertingLocalUrl || this.baseUrl;
  }

  public get automationUrl(): string {
    return this.automationLocalUrl || this.baseUrl;
  }

  public get iamUrl(): string {
    return this.iamLocalUrl || this.baseUrl;
  }

  public get connectorsUrl(): string {
    return this.connectorsLocalUrl || this.baseUrl;
  }

  public get toolsUrl(): string {
    return this.toolsLocalUrl || this.baseUrl;
  }

  public get sgUrl(): string {
    return this.sgLocalUrl || this.baseUrl;
  }

  public get notificationUrl(): string {
    return this.notificationLocalUrl || this.baseUrl;
  }

  public get requestsUrl(): string {
    return this.requestsLocalUrl || this.baseUrl;
  }

  public get backtestUrl(): string {
    return this.backtestLocalUrl || this.baseUrl;
  }

  public static GetMondataSupportCenterUrl(): string {
    return 'https://support.mondata.ai/';
  }
}

