/* eslint-disable no-use-before-define */
import { Directive } from '@angular/core';
import { CdkRowDef } from '@angular/cdk/table';

@Directive({
  selector: '[uiRowDef]',
  providers: [ { provide: CdkRowDef, useExisting: UiRowDef } ],
  inputs: [ 'columns: uiRowDefColumns', 'when: uiRowDefWhen' ],
})
export class UiRowDef<T> extends CdkRowDef<T> {}
