import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { TimeUtil } from "../../services/time-util";

interface IPickerValue {
  value: number; viewValue: string;
}
interface IHoursMinutes {
  hours: number; minutes: number;
}

@Component({
  selector: 'hours-picker',
  templateUrl: './hours-picker.component.html',
  styleUrls: [ './hours-picker.component.scss' ],
})
export class HoursPickerComponent implements OnInit {
  public hour: IPickerValue;
  public minute: IPickerValue;

  public hourOptions: IPickerValue[];
  public minuteOptions: IPickerValue[];

  @Output()
  public millisecondsEvent = new EventEmitter<number>();

  @Input()
  public defaultHoursMilliseconds: number;

  @Input()
  public readonly: boolean = false;

  constructor() {
    this.hourOptions = this.createTimePickerValue(24, 'h');
    this.minuteOptions = this.createTimePickerValue(60, 'min');
  }

  ngOnInit() {
    this.setDefaultState();
  }

  public reset() {
    this.hour = null;
    this.minute = null;
  }

  public setHour(event: IPickerValue): void {
    this.hour.value = event.value;
    this.getTimeInMiliseconds();
  }

  public setMinute(event: IPickerValue): void {
    this.minute.value = event.value;
    this.getTimeInMiliseconds();
  }

  private getTimeInMiliseconds(): void {
    const hoursToMiliseconds = this.hour?.value ? this.hour.value * TimeUtil.hourDuration : 0;
    const minutesToMiliseconds = this.minute?.value ? this.minute.value * TimeUtil.minuteDuration : 0;

    this.millisecondsEvent.emit(hoursToMiliseconds + minutesToMiliseconds);
  }

  public setDefaultState(): void {
    if (typeof this.defaultHoursMilliseconds === 'number') {
      const parsed = this.getHoursMinutesFromTimestamp(this.defaultHoursMilliseconds);
      this.hour = { value: parsed.hours, viewValue: `${parsed.hours} h` };
      this.minute = { value: parsed.minutes, viewValue: `${parsed.minutes} min` };
    }
  }

  private getHoursMinutesFromTimestamp(milliseconds: number): IHoursMinutes {
    if (milliseconds > TimeUtil.dayDuration) {
      throw new Error("milliseconds value is greater than 24h");
    }
    return {
      hours: TimeUtil.convertMillisecondsToHours(milliseconds),
      minutes: TimeUtil.convertMillisecondsToMinutes(milliseconds),
    };
  }

  private createTimePickerValue(arrayLength: number, key: string): IPickerValue[] {
    return Array.from(Array(arrayLength).keys()).map((item) => ({
      value: item,
      viewValue: `${item} ${key}`,
    }));
  }
}
