import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { CdkConnectedOverlay, ConnectedPosition } from '@angular/cdk/overlay';
import { Select, Store } from '@ngxs/store';
import { AppState, ResetPopover, SetPopover, UnsetPopover } from '../../../stores/app.state';
import { Observable } from 'rxjs';
import { EcoSessionState } from '../../../../session/state/session.state';

@Component({
  selector: 'topbar-user',
  templateUrl: './topbar-user.component.html',
  styleUrls: [ './topbar-user.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'topbar-user',
  },
})
export class TopbarUser {
  @ViewChild(CdkConnectedOverlay, { static: false }) public overlay: CdkConnectedOverlay;
  @Select(AppState.isPopoverActive) public isPopoverActive$?: Observable<(name: string) => boolean>;
  @Select(EcoSessionState.userName) public userName$: Observable<string>;

  name = 'topbar-user';

  public offsetX = -34;

  public offsetY = -12;

  public positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  public get hostname(): string {
    return this.name;
  }

  public constructor(protected readonly ref: ElementRef, protected readonly store: Store) {
  }

  public toggle(): void {
    this.isOpened() ? this.close() : this.open();
  }

  public open(): void {
    if (this.isOpened()) {
      return;
    }

    this.store.dispatch(new SetPopover({ name: this.hostname }));
  }

  public close(): void {
    if (!this.isOpened()) {
      return;
    }

    this.store.dispatch(new ResetPopover());
  }

  public isOpened(): boolean {
    return this.hostname === this.store.selectSnapshot((state) => state.app.popover);
  }

  @HostListener('document:click', [ '$event' ])
  public handleClickOut({ target }: Event): void {
    if (!this.isOpened()) {
      return;
    }

    if (
      this.isOpened() &&
      (this.ref.nativeElement.contains(target) || this.overlay.overlayRef.overlayElement.contains(target as Node))
    ) {
      return;
    }

    this.store.dispatch(new UnsetPopover({ name: this.hostname }));
  }
}
