<div class="ui-table-export-tool--button">
  <button
          ui-button
          modifier="on-highlight"
          [loading]="loading"
          color="linkBorded"
          icon="icon_report_generate"
          [custom-icon]="true"
          custom-icon-size="medium"
          [isSvg]="true"
          [title]="'resources.export.tooltip'">
    {{ 'resources.export.button' }}
  </button>
</div>