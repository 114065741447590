<div
  class="tabs-actions"
  [ngClass]="areActionsShown ? 'tabs-actions--shown' : 'tabs-actions--hidden'"
>
  <ui-tabs-actions-content
    [ngClass]="
      areActionsShown
        ? 'ui-tabs-actions-content--expanded'
        : 'ui-tabs-actions-content--collapsed'
    "
    [tabsTitle]="tabsTitle"
  >
    <ng-content></ng-content>
  </ui-tabs-actions-content>
</div>
