import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  HostBinding,
  OnInit,
  OnChanges,
  Input
} from '@angular/core';
import { prune } from '../../services/utils';

const name = 'ui-field-group';

export enum UiFieldGroupLayout {
  Vertical = 'vertical',
  Inline = 'inline'
}

@Component({
  selector: 'ui-field-group',
  templateUrl: './ui-field-group.component.html',
  styleUrls: [ './ui-field-group.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UiFieldGroup implements OnInit, OnChanges {
  @Input('class') public class: string;

  @HostBinding('class') public classes = name;

  @Input('layout') public layout: UiFieldGroupLayout = UiFieldGroupLayout.Inline;

  @Input() hasMarginBottom = true;

  public ngOnInit(): void {
    this.setup();
  }

  public ngOnChanges(): void {
    this.setup();
  }

  public setup(): void {
    const classes = {
      [name]: true,
      [`${name}_${this.layout}`]: true,
      [`${name}_margin-bottom`]: this.hasMarginBottom,
    };

    if (this.class) {
      classes[this.class] = true;
    }

    this.classes = Object.keys(prune(classes)).join(' ');
  }
}
