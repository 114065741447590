<div class="sign-up-background flex-box
    center-horizontal center-vertical
    base-layout-theme">
  <mat-card class="sign-up-card">
    <div class="card-container">
      <img src="assets/images/logo-md.png" class="sign-up-logo" alt="logo" />
      <ng-container *ngIf="idpType" [ngSwitch]="idpType">
        <ng-container *ngSwitchCase="'aad'">
          <ng-template *ngTemplateOutlet="aad"></ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'google'">
          <ng-template *ngTemplateOutlet="google"></ng-template>
        </ng-container>
      </ng-container>
    </div>
  </mat-card>
</div>

<ng-template #spinner>
  <spinner></spinner>
</ng-template>

<ng-template #aad>
  <div class="text-container">

    <p class="title">
      {{ 'signup.welcome.1' | t }}
    </p>
    <p class="org-name">
      {{ clientName }}
    </p>


    <p class="main-text">
      {{ "signup.welcome.2" | t }}
    </p>
    <ul class="main-text-enum">
      <li>{{ "signup.welcome.3" | t }}</li>
      <li>{{ "signup.welcome.4" | t }}</li>
    </ul>
    <p class="main-text-details">
      {{ "signup.welcome.5" | t }}
    </p>
  </div>
  <button mat-raised-button class="sign-up-button" (click)="onClick()">
    {{ 'signup.button' | t }}
  </button>
</ng-template>

<ng-template #google>
  <div class="text-container">

    <p class="title">
      {{ 'signup.welcome.1' | t }}
    </p>
    <p class="org-name">
      {{ clientName }}
    </p>


    <p class="main-text">
      {{ "signup.welcome.2" | t }}
    </p>
    <ul class="main-text-enum">
      <li>{{ "signup.welcome.google.3" | t }}</li>
      <li>{{ "signup.welcome.4" | t }}</li>
    </ul>

    <label ui-label>
      Subject
      <ui-tooltip>
        {{ 'signup.welcome.google.subject.tooltip' | t }}
      </ui-tooltip>
    </label>
    <input ui-input [(value)]="subject" placeholder="user-to-impersonate@your-domain.com"
      style="margin-bottom: 0.5em;" />


    <p class="main-text-details">
      {{ "signup.welcome.google.5" | t }}
    </p>

  </div>
  <button mat-raised-button class="sign-up-button" (click)="onClick()">
    {{ 'signup.button' | t }}
  </button>
</ng-template>