<label
  ui-label
  *ngIf="label"
>{{ label }}</label>

<div class="ui-select-dropdown-container">
  <div class="ui-select-input-container">
    <input
      ui-input
      [ngStyle]="fieldStyle"
      [value]="value[key]"
      (click)="toggleOptions()"
      autocomplete="off"
      [readonly]="true"
      [placeholder]="placeholder"
    />

    <ui-icon
      *ngIf="options.length > 0 && !readonly"
      [name]="showOptions ? 'chevron-up' : 'chevron-down'"
      modifier="list-action"
      size="medium"
      (click)="toggleOptions()"
    ></ui-icon>
  </div>

  <div
    *ngIf="showOptions"
    class="options-container"
  >
    <ng-container *ngFor="let option of options">
      <div
        (click)="toggleOptions(); onOptionClick(option)"
        class="option"
      >
        {{ option[key] }}
      </div>
    </ng-container>
  </div>
</div>