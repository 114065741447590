import { DatePipe } from "@angular/common";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { clone } from "projects/@common/utils/utils";

export interface ITimeInterval {
  dateFrom?: number;
  dateTo?: number;
}

export enum IntervalTypeEnum {
  SMALL = 'SMALL',
  BIG = 'BIG'
}

export enum DateFormatEnum {
  SHORT_TIME_WITH_UTC = 'shortWithUTC',
  MEDIUM = 'medium'
}

@Component({
  selector: 'app-date-interval',
  templateUrl: './date-interval.component.html',
  styleUrls: [ './date-interval.component.scss' ],
})
export class DateIntervalComponent implements OnChanges {
  @Input()
    period: ITimeInterval;

  @Input()
    type = IntervalTypeEnum.SMALL;

  @Input()
    withTime: boolean = true;

  @Input()
    withPrefix: boolean = false;

  @Input()
    hideIcon: boolean;

  @Input()
    setMissingDateEnd: boolean = true;

  @Input()
    dateFormatEnum = DateFormatEnum.MEDIUM;

  @Input()
    isGMT: boolean = false;

  public formattedTimestamp: string;
  public intervalTypeEnum = IntervalTypeEnum;

  constructor(
    private i18nService: I18nService,
    private datePipe: DatePipe
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateFormattedTimestamp(changes.period.currentValue);
  }

  public get iconPath(): string {
    switch (this.type) {
      case IntervalTypeEnum.SMALL:
        return 'assets/favicons/icon_calendar_grey.svg';
      case IntervalTypeEnum.BIG:
        return 'assets/favicons/icon_calendar_big.svg';
    }
  }

  public updateFormattedTimestamp(period: ITimeInterval): void {
    this.formattedTimestamp = period ? this.getFormattedPeriod(clone(period)) : null;
  }

  private getPrefix(): string {
    if (this.withPrefix) {
      const browserLocale = this.i18nService.currentLocale;
      if (browserLocale === 'fr') {
        return "Du";
      }
      return 'From';
    }
    return "";
  }

  private getSeparator(period: ITimeInterval): string {
    const browserLocale = this.i18nService.currentLocale;
    if (browserLocale === 'fr') {
      return period?.dateTo ? 'au' : 'jusqu\'à';
    }
    return 'to';
  }

  private getDateFormat(): string {
    const browserLocale = this.i18nService.currentLocale;
    const separator = browserLocale === 'fr' ? 'à' : 'at';

    if (this.dateFormatEnum === DateFormatEnum.SHORT_TIME_WITH_UTC) {
      return `d MMM YYYY '${separator}' HH:mm UTC`;
    }
    return this.withTime ? 'medium' : 'mediumDate';
  }

  private getFormattedPeriod(period: ITimeInterval): string {
    if (!period || (!period.dateFrom && !period.dateTo)) {
      return null;
    }

    const dateFormat = this.getDateFormat();

    let from = '-';
    if (period.dateFrom) {
      from = this.datePipe.transform(period.dateFrom, dateFormat, this.isGMT ? 'GMT' : null, this.i18nService.dateCurrentLocale);
    }

    let to = '-';
    if (period.dateTo) {
      to = this.datePipe.transform(period.dateTo, dateFormat, this.isGMT ? 'GMT' : null, this.i18nService.dateCurrentLocale);
    } else if (this.setMissingDateEnd) {
      period.dateTo = Date.now();
      to = this.datePipe.transform(period.dateTo, dateFormat, this.isGMT ? 'GMT' : null, this.i18nService.dateCurrentLocale);
    } else {
      to = this.i18nService.translate('common.now');
    }

    return `${this.getPrefix()} ${from} ${this.getSeparator(period)} ${to}`.trim();
  }
}
