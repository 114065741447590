<div class="toggle-choices">
  <ng-container *ngFor="let toggle of toggleChoices">
    <button
            [ngClass]="toggle.selected ? 'selected' : ''"
            (click)="onButtonClick(toggle); $event.stopPropagation()"
            class="toggle">
      {{ toggle.name }}
      <ng-container *ngIf="toggle.type === 2">
        ({{ actionRequiredAmount }})
      </ng-container>
    </button>
  </ng-container>
</div>