<div class="email-initializer-container">
  <h3 class="email-initializer-container-title">{{ 'email-initializer.title' | t }}</h3>
  <p  *ngIf="!isEditing">{{ data.email }}</p>
  <ui-field
   *ngIf="isEditing"
    [required]="true"
    [label]="'email-initializer.email.label' | t"
    [control]="'email'"
    [locale]="i18n.currentLocale"
    [placeholder]="'email-initializer.email.placeholder' | t"
    [group]="formGroup"
  ></ui-field>
  <p>{{ 'email-initializer.message' | t }}</p>
  <div class="email-initializer-options-container">

    <button ui-button color="standard" (click)="saveCurrentEmail()"  *ngIf="!isEditing">
      {{ 'common.action.confirm' | t }}
    </button>
    <button ui-button color="standard" (click)="saveNewEmail()" [disabled]="!formGroup.valid" *ngIf="isEditing">
      {{ 'common.action.save' | t }}
    </button>
    <button *ngIf="!forceUpdate" ui-button color="secondary" (click)="toggleEditing()">
      {{toogleButtonText}}
    </button>
  </div>
  
  <button
    class="close-button"
    ui-button
    [icon]="'close'"
    color="listActionFlat"
    (click)="closeDialog()"
  ></button>
</div>