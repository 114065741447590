import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { JsonUtils } from '../../util/json-utils';
import { LanguageEnum } from '../../interfaces/ILanguage';
import { TranslatedObjectLocalePipe } from '../../pipes/translatedObjectLocale.pipe';

export interface JsonTextareaConfig {
  controlName: string;
  formGroup: UntypedFormGroup;
  label: string;
  required: boolean;
  placeholder: string;
}

export enum TextareaTypeEnum {
  JSON = 'json',
  ARRAY = 'array'
}

@Component({
  selector: 'ui-json-textarea',
  templateUrl: './ui-json-textarea.component.html',
})
export class UiJsonTextareaComponent implements OnInit {
  @Input() controlName: string;

  @Input() formGroup: UntypedFormGroup;

  @Input() label: string;

  @Input() description: string;

  @Input() required: boolean;

  @Input() placeholder: string;

  @Input() readonly = false;

  @Input() prettifyButton = true;

  @Input() type: TextareaTypeEnum = TextareaTypeEnum.JSON;

  @Input() public locale: LanguageEnum;

  constructor(private translator: TranslatedObjectLocalePipe) { }

  ngOnInit(): void {
    this.label = this.translator.transform(this.label, this.locale);
    this.description = this.translator.transform(this.description, this.locale);
    this.placeholder = this.translator.transform(this.placeholder, this.locale);

    if (this.formGroup && this.formGroup.contains(this.controlName) && this.formGroup.get(this.controlName).value !== "") {
      this.validateAndPrettify(this.controlName);
    }
  }

  public get isInvalid(): boolean {
    const control = this.formGroup.get(this.controlName);
    return control.invalid && control.touched;
  }

  public validateAndPrettify(formControlName: string): void {
    const control = this.formGroup.get(formControlName) as AbstractControl;
    try {
      JSON.parse(control.value);
      if (control.valid) {
        control.setValue(JsonUtils.prettifyJson(control.value));
      }
    } catch (error) {
      console.log('Invalid json format');
    }
  }
}
