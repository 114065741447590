import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EcoUrlService } from '../../core/url.service';
import { ApiEndpointPrefixEnum } from 'projects/@common/definitions/eco-api';
import { OrganizationParametersFilters } from 'projects/@common/services/definitions/fetchOrganizations.definition';
import { DescribeOrganizationResponse, IConnectorOrganizationsApi } from './definitions';
import { Eco } from 'projects/@common/definitions/eco';
import { Store } from '@ngxs/store';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { CustomerConnectorsApiOrganizationService } from './customer-connectors-api-organizations';
import { VarConnectorsApiOrganizationService } from './var-connectors-api-organizations';

export interface ManagedOrganization {
  organization_eco_id: string;
  organization_respond_id: string;
  name: string;
}

export type GetManagedOrganizationResponse = Eco.List<ManagedOrganization>;

@Injectable({
  providedIn: 'root',
})
export class ConnectorsApiOrganizationService implements IConnectorOrganizationsApi {
  protected prefix = ApiEndpointPrefixEnum.detect_connectors;

  private customerService: CustomerConnectorsApiOrganizationService;
  private varService: VarConnectorsApiOrganizationService;

  constructor(
    protected readonly store: Store,
    http: HttpClient,
    url: EcoUrlService
  ) {
    this.customerService = new CustomerConnectorsApiOrganizationService(http, url);
    this.varService = new VarConnectorsApiOrganizationService(http, url);
  }

  public getManagedOrganizations(params?: OrganizationParametersFilters): Promise<GetManagedOrganizationResponse> {
    if (this.isVarMode) {
      return this.varService.getManagedOrganizations(params);
    }
    throw new Error('Method not implemented.');
  }

  public describeOrganization(organizationId: string): Promise<DescribeOrganizationResponse> {
    if (this.isVarMode) {
      return this.varService.describeOrganization(organizationId);
    }
    return this.customerService.describeOrganization(organizationId);
  }

  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }
}
