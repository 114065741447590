import { Directive, ElementRef } from '@angular/core';
import { CdkFooterCell, CdkColumnDef } from '@angular/cdk/table';

@Directive({
  selector: 'td[ui-footer-cell]',
  host: {
    class: 'ui-footer-cell',
    role: 'gridcell',
  },
})
export class UiFooterCell extends CdkFooterCell {
  constructor(columnDef: CdkColumnDef, elementRef: ElementRef) {
    super(columnDef, elementRef);
    elementRef.nativeElement.classList.remove(`cdk-column-${columnDef.cssClassFriendlyName}`);
    elementRef.nativeElement.classList.add(`ui-footer-cell_${columnDef.cssClassFriendlyName}`);
  }
}
