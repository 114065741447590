<img
  *ngIf="isWhitelisted"
  alt="Liste d'exception"
  [src]="whitelistedIcon"
  class="icon-pos-1 grid-item"
  [class.fixedGridDisplay]="fixedGridDisplay"
  [tooltip]="'alert.whitelisted.tooltip' | t"
>

<img
  *ngIf="hasAssets"
  alt="Actifs d'intérêt"
  [src]="criticalAssetIcon"
  class="icon-pos-2 grid-item"
  [class.fixedGridDisplay]="fixedGridDisplay"
  [tooltip]="'alert.criticalAsset.tooltip' | t:{count:countAssets}"
>

<img
  *ngIf="isSilent"
  alt="Silencieuse"
  [src]="silentIcon"
  class="icon-pos-3 grid-item"
  [class.fixedGridDisplay]="fixedGridDisplay"
  [tooltip]="'alerts.container.page.table.status.SILENCED.tooltip' | t"
>

<img
  *ngIf="isBreachConfidentiality"
  alt="Confidentialité"
  [src]="confidentialityIcon"
  class="grid-item"
  [tooltip]="('incidents.container.page.details.tab.detail.summary.confidentiality.tooltip.title'|t) + '. ' + ('incidents.container.page.details.tab.detail.summary.confidentiality.tooltip.content' | t)"
>

<img
  *ngIf="isLate"
  alt="En Retard"
  [src]="lateAlertIcon"
  class="icon-pos-4 grid-item"
  [class.fixedGridDisplay]="fixedGridDisplay"
  [tooltip]="lateAlertTooltipText"
>

<img
  *ngIf="conversationUpdatedClientSide"
  alt="Conversation updated"
  [src]="conversationUpdated"
  class="icon-pos-5 grid-item"
  [tooltip]="'incidents.container.page.table.conversationUpdated.tooltip' | t"
>