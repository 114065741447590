import { Injectable } from '@angular/core';
import { BaseSidebar, navigationElement } from '@common/modules/layout/definitions/base-sidebar.service';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';
import { DisplayRequirements } from 'projects/@common/modules/display/display.service';


@Injectable()
export class AdmAirSidebar extends BaseSidebar {
  private elements: navigationElement[] = [
    { name: 'MD.AIR' },
    {
      rootAction: true,
      name: 'sidebar.navigation.AR.title',
      route: '/air',
      icon: 'sidebar/icon_sidebar_config',
      flags: [ FeaturesEnum.AIR_MANAGEMENT ],
    },
  ];

  constructor() {
    super();
  }

  public unsubscribe() {
  }

  public getElements() {
    return this.elements;
  }

  public getRequirements(): DisplayRequirements {
    return {
      flags: [ FeaturesEnum.AIR_MANAGEMENT ],
    };
  }
}
