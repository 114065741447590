<div class="confirmation-modal-container">
  <h2>{{confirmationData.title}}</h2>
  <p>
    <span *ngFor="let paragraphLine of paragraphLines">
      {{paragraphLine}}<br>
    </span>
  </p>

  <div class="button-wrapper">
    <button
      ui-button
      color="standard"
      (click)="closeModal(true)"
    >{{confirmationData.confirmationText}}</button>
    <button
      *ngIf="confirmationData.cancelText"
      ui-button
      (click)="closeModal(false)"
    >{{confirmationData.cancelText}}</button>
  </div>
</div>