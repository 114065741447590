<div class="ui-table-search-tool--input-group">
  <img
    *ngIf="!removeSearchIcon"
    class="ui-table-search-tool--icon"
    src="assets/favicons/icon_search.svg"
  />
  <input
    class="ui-table-search-tool--input"
    [ngClass]="{
      'show-invalid-style': hasValue && !isValid
    }"
    [placeholder]="placeholder"
    [maxLength]="MAX_LENGTH"
    [(ngModel)]="query"
    (input)="handleQuery($event)"
  />

  <button
    class="ui-table-search-tool--clear"
    type="button"
    (click)="handleClear()"
    *ngIf="hasValue"
  >
    <ui-icon
      class="ui-table-search-tool--clear-icon"
      name="close"
      modifier="OnHighlight"
    ></ui-icon>
  </button>

</div>