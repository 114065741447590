<label
  *ngIf="label"
  ui-label
>{{ label }}</label>
<div
  *ngIf="group && isEditing"
  [formGroup]="group"
  class="edit-mode"
  [ngClass]="{
    'without-padding': withoutPadding,
    'showUnsavedWarning': showUnsavedWarning && hasChanged
  }"
>
  <textarea *ngIf="!ngModel"
    [froalaEditor]="options"
    [formControlName]="controlName"
  ></textarea>

  <p class="confirm-change">{{ "common.action.confirm.change" | t:{locale: locale} }}</p>
</div>

<ng-container *ngIf="useNgModel">
  <textarea *ngIf="isEditing"
    [froalaEditor]="options"
    [ngModelOptions]="{ standalone: true }"
    [froalaModel]="ngModel"
    (froalaModelChange)="valueOnChange($event)"
  ></textarea>

  <div
    *ngIf="!isEditing"
    class="read-only-mode"
    [ngClass]="{
      'without-padding': withoutPadding
    }"
  >
    <ng-container *ngIf="ngModel === null">
      <p>-</p>
    </ng-container>
    <ng-container *ngIf="ngModel !== null">
      <div [innerHTML]="ngModel | safeHtml"></div>
    </ng-container>
  </div>
</ng-container>


<div
  *ngIf="htmlData && !isEditing"
  class="read-only-mode"
  [ngClass]="{
    'without-padding': withoutPadding
  }"
>
  <div [innerHTML]="htmlData | safeHtml"></div>
</div>