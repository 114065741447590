<div class="confirmation-modal-container">
  <h2 *ngIf="confirmationData?.title">{{confirmationData.title}}</h2>
  <p>
    <span *ngFor="let paragraphLine of paragraphLines">
      {{paragraphLine}}<br>
    </span>
  </p>

  <ng-container *ngIf="needsJustification">
    <ui-text-field
      [label]="confirmationData.justificationLabel"
      [required]="true"
      control="justification"
      [group]="formGroup"
      [maxlength]="250"
    >
    </ui-text-field>

  </ng-container>
  <div class="confirmation-container">
    <ui-field
      [label]="confirmationData.justificationConfirmationLabel"
      [placeholder]="confirmationData.justificationConfirmationPlaceholder"
      name="confirmationText"
      control="confirmationText"
      [required]="true"
      [group]="formGroup"
      maxlength="50"
    ></ui-field>

  </div>

  <div class="button-wrapper">
    <button
      [disabled]="!isConfirmationtextValid || formGroup.invalid || forceInvalid"
      ui-button
      color="standard"
      (click)="closeModal(true)"
    >{{confirmationData.confirmationText}}</button>
    <button
      *ngIf="confirmationData.cancelText"
      ui-button
      (click)="closeModal(false)"
    >{{confirmationData.cancelText}}</button>
  </div>
</div>