<div
  class="container"
  [class.with-border]="withBorder"
>
  <div class="container--content">
    <img
      *ngIf="logo"
      alt="'capacity logo'"
      class="container--content--logo"
      [src]="'assets/favicons/' + logo + '.svg'"
    />
    <div
      class="container--content--big-number-wrapper"
      [class.margin-left]="!logo"
    >
      <ui-spinner
        *ngIf="loading"
        size="small"
      ></ui-spinner>
      <span
        *ngIf="!loading"
        [ngStyle]="{color: numberColor ? numberColor : null}"
        class="container--content--big-number-wrapper--big-number"
      >{{capacityNumber}}</span>
    </div>

    <div class="container--content--description">
      <span class="container--content--description--bold">{{topDescription}}</span>
      <span class="container--content--description--normal">{{bottomDescription}}</span>
    </div>
  </div>
</div>