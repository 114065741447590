<ui-input-group [formGroup]="group">
  <ng-container *ngIf="arrayName" [formArrayName]="arrayName">
    <ng-container [formGroupName]="groupName">
      <div class="flex-box">
        <label *ngIf="label" ui-label [name]="name" [required]="required">{{
          label 
        }}</label>
        <ng-content></ng-content>
      </div>
      <input
        class="ui-field--value"
        [ngClass]="{ invalid: forceInvalid }"
        ui-input
        [type]="type"
        [name]="name"
        [numberOnly]="type === 'number' ? true : false"
        [placeholder]="placeholder"
        [textMask]="mask"
        [formControlName]="control"
        [maxlength]="maxlength"
        (ngModelChange)="executeInputedFunction($event)"
        (focus)="onFocus()"
        (blur)="onBlur()"
        [autocomplete]="enableAutocomplete ? 'on' : 'off'"
        *ngIf="mask; else default"
      />
      <ng-template #default>
        <input
          [ngClass]="{ invalid: forceInvalid }"
          ui-input
          [type]="type"
          [name]="name"
          [numberOnly]="type === 'number' ? true : false"
          [placeholder]="placeholder"
          [formControlName]="control"
          [maxlength]="maxlength"
          (ngModelChange)="executeInputedFunction($event)"
          (focus)="onFocus()"
          (blur)="onBlur()"
          [autocomplete]="enableAutocomplete ? 'on' : 'off'"
        />
      </ng-template>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!arrayName">
    <div class="flex-box">
      <label *ngIf="label" ui-label [name]="name" [required]="required">{{
        label 
      }}</label>
      <ng-content></ng-content>
    </div>
    <input
      class="ui-field--value"
      [ngClass]="{ invalid: forceInvalid }"
      ui-input
      [type]="type"
      [name]="name"
      [numberOnly]="type === 'number' ? true : false"
      [placeholder]="placeholder"
      [textMask]="mask"
      [formControlName]="control"
      [maxlength]="maxlength"
      (ngModelChange)="executeInputedFunction($event)"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [autocomplete]="enableAutocomplete ? 'on' : 'off'"
      *ngIf="mask; else default"
    />
    <ng-template #default>
      <input
        [ngClass]="{ invalid: forceInvalid }"
        ui-input
        [type]="type"
        [name]="name"
        [numberOnly]="type === 'number' ? true : false"
        [value]="value"
        [disabled]="disabled"
        [readonly]="readonly"
        [placeholder]="placeholder"
        [formControlName]="control"
        [maxlength]="maxlength"
        (ngModelChange)="executeInputedFunction($event)"
        (keyup)="onInputValue($event)"
        (focus)="onFocus()"
        (blur)="onBlur()"
        [autocomplete]="enableAutocomplete ? 'on' : 'off'"
      />
    </ng-template>
  </ng-container>
  <label class="description-field" *ngIf="description" ui-label>{{ description }}</label>
  <ng-container *ngIf="errorMessage">
    <div class="fielderror">
      {{ errorMessage | t:{locale:locale} }}
    </div>
  </ng-container>
</ui-input-group>
