<div class="flex-box">
  <label ui-label [required]="required">{{ label }}</label>
  <ng-content></ng-content>
</div>

<div class="input-container">
  <input
    *ngIf="isEditing"
    class="tag-list--value"
    ui-input
    [(ngModel)]="tagInputValue"
    (keyup.enter)="addTag()"
    autocomplete="off"
  />
  <button *ngIf="isEditing" ui-button color="standard" (click)="addTag()">
    {{ buttonLabel }}
  </button>
</div>

<div class="clicked-tags">
  <ng-container
    *ngFor="let tag of isEditing ? group.controls[controlName].value : tags"
  >
    <div class="clicked-tag">
      <div class="clicked-tag--name">
        {{ tag }}
      </div>
      <ui-icon
        *ngIf="isEditing"
        size="medium"
        name="close-circle"
        (click)="deleteTag(tag)"
      ></ui-icon>
    </div>
  </ng-container>
</div>
