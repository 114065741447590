import { basename } from 'path';
import { State, StateContext, Action, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { clone } from 'projects/@common/utils/utils';

export interface I18nStateModel {
  collection: any;
  locale: string;
}

export const initialState: I18nStateModel = {
  collection: {},
  locale: 'en',
};

export class LoadI18n {
  static readonly type = '[I18n] Load';
}

export class SetI18nLocale {
  static readonly type = '[I18n] Set Locale';

  public constructor(public payload: { locale: string }) {}
}

@State<I18nStateModel>({
  name: 'i18n',
  defaults: clone(initialState),
})
@Injectable()
export class I18nState {
  @Selector()
  public static currentLocale(state: I18nStateModel): string {
    return state.locale;
  }

  @Action(LoadI18n)
  public async load(ctx: StateContext<I18nStateModel>): Promise<void> {
    const collection = {};
    const context = (import.meta as any).webpackContext('../../../assets/i18n', { recursive: true, regExp: /\.json$/ });
    context.keys().forEach((file) => {
      collection[basename(file, '.json')] = context(file);
    });
    ctx.patchState({
      collection,
    });
  }

  @Action(SetI18nLocale)
  public setLocale(ctx: StateContext<I18nStateModel>, action: SetI18nLocale): void {
    let { locale } = action.payload;
    const locales = Object.keys(ctx.getState().collection);

    if (!locales.includes(locale)) {
      locale = initialState.locale;
    }

    ctx.patchState({
      locale,
    });
  }
}
