import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UiTabsActionsService {
  public areActionsShown: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public showActions() {
    setTimeout(() => {
      this.areActionsShown.next(true);
    }, 1);
  }

  public hideActions() {
    this.areActionsShown.next(false);
  }
}
