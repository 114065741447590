import { Component, ElementRef, Inject, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { IMobileService } from '../../interfaces/IMobileService';

@Component({
  selector: 'ui-tooltip',
  templateUrl: './ui-tooltip.component.html',
  styleUrls: [ './ui-tooltip.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-tooltip',
  },
})
export class UiTooltip {
  public readonly INFO_ICON = 'assets/favicons/icon_info.svg';

  public readonly SMALL_INFO_ICON = 'assets/favicons/icon_info_small.svg';

  public readonly CLOSE_ICON = 'assets/favicons/icon_remove.svg';

  private readonly DEFAULT_PADDING = 32;

  @Input() public isSmall = false;

  @Input() public isBig = false;

  @Input() public data: string;

  @Input() public icon: string;

  @Input() public width = '275px';

  @Input() public placementRight = true;

  @Input() public placementBottom = false;

  @ViewChild('tooltipMessage', { static: false }) tooltipElementRef: ElementRef;
  @ViewChild('tooltipIcon') tooltipIconRef: ElementRef;

  public positionLeft = '30px';

  public positionTop = '-10px';

  public showTooltip = false;

  constructor(@Inject("MobileService") public readonly mobileService: IMobileService) { }

  public onTooltipClick() {
    this.setPlacement();
    this.showTooltip = true;
  }

  public onCloseTooltipClick() {
    this.showTooltip = false;
  }

  private get _width(): number {
    return Number(this.width.replace("px", ""));
  }

  private get _iconPosition(): { x: number, y: number } {
    return this.tooltipIconRef?.nativeElement.getBoundingClientRect();
  }

  private get _overflowRight(): number {
    let overflow = 0;
    if (this.tooltipIconRef?.nativeElement) {
      const tooltipRightX = this._iconPosition.x + this._width;
      const windowWidth = window.document.documentElement.offsetWidth;
      overflow = tooltipRightX - windowWidth;
    }
    return overflow > 0 ? overflow : 0;
  }

  private setPlacement() {
    if (this.placementRight) {
      this.positionLeft = this._overflowRight
        ? `-${this._overflowRight + this.DEFAULT_PADDING}px`
        : `${this.DEFAULT_PADDING}px`;
    } else {
      this.positionLeft = `-${this._width + this.DEFAULT_PADDING}px`;
    }

    if (this.placementBottom) {
      this.positionTop = `${this.DEFAULT_PADDING}px`;
    }
  }
}
