import { Component, OnInit } from '@angular/core';
import { Notice, NoticeLevels, NoticeService } from '../../../notice/notice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IamApiService } from 'projects/@common/services/api/iam/iam.api';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { StorageService } from 'projects/@common/services/storage.service';
import { IamApi } from '@common/services/api/iam/iam.api.definitions';

@Component({
  selector: 'sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: [ './sign-up.component.scss' ],
})
export class SignupComponent implements OnInit {
  public clientName: string;
  public organizationId: string;
  public lang: string;
  public application: string;
  public idpType: string;
  public subject = '';

  constructor(
    private noticeService: NoticeService,
    private route: ActivatedRoute,
    private iamApi: IamApiService,
    private i18n: I18nService,
    private storage: StorageService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.clientName = this.route.snapshot.queryParams?.clientName;
    this.organizationId = this.route.snapshot.queryParams?.organizationId;
    this.lang = this.route.snapshot.queryParams?.lang;
    this.application = this.route.snapshot.queryParams?.application;
    this.idpType = this.route.snapshot.queryParams?.idpType || 'aad';
    this.subject = this.route.snapshot.queryParams?.subject || '';

    if (this.organizationId) {
      this.storage.setLocal('signup.organizationId', this.organizationId);
      this.storage.setLocal('signup.organizationName', this.clientName);
      this.storage.setLocal('signup.lang', this.lang);
    } else {
      this.organizationId = this.storage.getLocal('signup.organizationId');
      this.clientName = this.storage.getLocal('signup.organizationName');
      this.lang = this.storage.getLocal('signup.lang');
    }

    if (!this.organizationId) {
      this.router.navigate([ 'signin' ]);
    }

    if (!this.clientName) {
      this.clientName = '-';
    }

    if (!this.application) {
      this.application = 'md.eco';
    }

    this.i18n.setCurrentLocale(this.lang || 'en');
  }

  public onClick(): void {
    const requestBody: IamApi.SignupUrl.request = this.idpType == 'aad' ? {
      organizationId: this.organizationId,
      redirectUrl: `${window.location.origin}/signup/redirect`,
      application: this.application,
      idpType: 'azuread',
    } : {
      organizationId: this.organizationId,
      redirectUrl: `${window.location.origin}/signup/redirect`,
      idpType: 'google',
      subject: this.subject,
    };

    this.iamApi.getSignupUrl(requestBody).then((response) => {
      window.location.href = response.url;
    })
      .catch((err) => {
        if (err.status === 409) {
          this.handleSignupAlreadyComplete();
        }
      });
  }

  public handleSignupAlreadyComplete(): void {
    const notice = new Notice(
      this.i18n.translate('signup.already.complete'),
      NoticeLevels.SUCCESS
    );

    this.noticeService.notifyUser(notice);

    this.router.navigate([ '/signin' ]);
  }
}
