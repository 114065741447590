import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { IamApiService } from 'projects/@common/services/api/iam/iam.api';

@Component({
  template: '<app-loader class="private-layout--loader"></app-loader>',
})
export class AdminConsentRedirectComponent implements OnInit {
  static path = '/adminconsent/redirect';
  private redirectTo = '';

  constructor (
    private route: ActivatedRoute,
    private iamApi: IamApiService,
    private router: Router,
    private notice: NoticeService
  ) { }

  async ngOnInit() {
    const { admin_consent } = this.route.snapshot.queryParams;
    const { tenant } = this.route.snapshot.queryParams;
    this.updateAdminConsent(admin_consent, tenant);
  }

  private async updateAdminConsent(admin_consent: boolean, tenant: string) {
    this.iamApi.updateAdminConsent({
      admin_consent,
      tenant,
    }).then(() => {
      this.notice.notifyUser(new Notice('Signup successful', NoticeLevels.SUCCESS));
      this.router.navigate([ this.redirectTo ]);
    }).catch((err) => {
      this.notice.notifyUser(new Notice('Something went wrong, contact mondata for help', NoticeLevels.ERROR));
    });
  }
}
