import { ChangeDetectionStrategy, ViewEncapsulation, Component, Input, EventEmitter, Output } from '@angular/core';
import { randomBytes } from 'crypto';
import { UntypedFormGroup } from '@angular/forms';

export enum StyleEnum {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal'
}

@Component({
  selector: 'ui-switch-radio-static',
  templateUrl: './ui-switch-radio-static.component.html',
  styleUrls: [ './ui-switch-radio-static.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-switch-radio-static',
  },
})
export class UiSwitchRadioStatic {
  constructor() {
  }

  @Input() public label: string;

  @Input() public name: string = randomBytes(4).toString('hex');

  @Input() public value: string;

  @Input() public optionsType: string;

  @Input() public required = false;

  @Input() public readonly = false;

  @Input() public disabled = false;

  @Input() public group: UntypedFormGroup;

  @Input() public isEditing = false;

  @Input() public control: string = this.name;

  @Input() public options: any;

  @Input() public style = StyleEnum.VERTICAL;

  @Input() public locale: string = 'fr';

  @Output() public onOptionSelect: EventEmitter<any> = new EventEmitter();

  styleEnum = StyleEnum;

  public get optionValues(): any {
    return Object.values(this.options);
  }
}
