import { Component, Input, OnInit } from '@angular/core';
import { DatasourceListRepresentation } from '../../containers/datasources.container';
import { IconSideEnum } from '@ui-kit/components/ui-static/ui-static.component';
import { DatasourceUiRepresentation, DatasourceStatus } from '@common/services/api/respond/datasources/datasources.definitions';
import { JsonUtils } from '@common/utils/json-utils';

@Component({
  selector: 'datasource-info-details',
  templateUrl: './datasource-info-details.component.html',
})
export class DatasourceInfoDetailsComponent implements OnInit {
  @Input() public data: { datasource: DatasourceUiRepresentation; currentOrganization: string };

  public datasourceConfig: any;

  public iconSide = IconSideEnum.LEFT;

  public configsToDisplay: Array<{ label: string; value: string }> = [];

  ngOnInit(): void {
    this.datasourceConfig = this.data.datasource.config;
    this.configsToDisplay = this.configFormatList();
  }

  private configFormatList(): Array<{ label: string; value: string }> {
    return Object.keys(this.datasourceConfig).map((key) => {
      let configValue = this.datasourceConfig[key];

      if (Array.isArray(configValue)) {
        configValue =
          configValue.length > 4 ? JsonUtils.prettifyJson(JSON.stringify(configValue)) : JSON.stringify(configValue);
      }

      if (typeof configValue === 'object' && configValue !== null && !Array.isArray(configValue)) {
        configValue = JsonUtils.prettifyJson(JSON.stringify(configValue));
      }

      return { label: key, value: configValue };
    });
  }

  public getIsActivatedIcon(datasource: DatasourceListRepresentation | DatasourceUiRepresentation): string {
    return datasource.status === DatasourceStatus.DEPLOYED ? 'favicons/icon_status_on' : 'favicons/icon_status_off';
  }

  public get activatedIcon(): string {
    return this.getIsActivatedIcon(this.data.datasource);
  }
}
