import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { PrioritiesFilterEnum } from '../usecase/usecase.definition';
import { ConnectorFamilies } from '@md.eco/connectors';

export enum DatasourceStatus {
  DEPLOYED = 'deployed',
  PENDING = 'pending',
  DEPLOYING = 'deploying'
}

export enum DatasourceConfigurationParameterTypes {
  NUMBER = 'number',
  STRING = 'string',
  BOOLEAN = 'boolean',
  STRING_LIST = 'string_list',
  OBJECT = 'object',
  CHECKBOX = 'checkbox'
}

export interface ConfigurationBaseTemplate {
  label: ITranslatedField;
  description: ITranslatedField;
  type: DatasourceConfigurationParameterTypes;
  required: boolean;
  default?: any;
  values?: { label: ITranslatedField; value: any }[];
}

export type DatasourceConfigurationTemplate<T> = {
  [key in keyof T]?: ConfigurationBaseTemplate;
};

export interface DatasourceTemplateUI {
  configurationTemplate: DatasourceConfigurationTemplate<any>;
  name: string;
  description: ITranslatedField;
  implementation: DatasourceImplementations | string;
  uiName: ITranslatedField;
}

export interface DatasourceUiRepresentation {
  id: string;
  type: string;
  updatedAt: number;
  createdAt: number;
  organizationId: string;
  implementation: string;
  config: any;
  status: DatasourceStatus;
  dataTypes: string[];
}

// Will be able to remove this type once all datasource are converted to conenctors
export enum DatasourceImplementations {
  GUARDDUTYS3 = 'guardduty',
  CLOUDTRAILS3 = 'cloudtrail',
  DATABRICKSS3 = 'databricks',
  SNOWFLAKES3 = 'snowflake',
  GENERICS3 = 'generic',
  SYSLOG_S3 = 'syslog',
  FLUENTD_SYSLOG_S3 = 'fluentdSyslog',
  WINDOWS_EVENT_LOGS_S3 = 'windowsEventLogs',
  CLOUDWATCH = 'cloudwatch',
  ES_METRICS = 'esMetrics',
  WAZUH = 'wazuh',
  OFFICE = 'office',
  ATP = 'atp',
  SignIns = 'signIns',
  SYSLOG = 'syslog',
  WAFS3 = 'waf',
  INFORMATICA = 'informatica',
  AZURE_ACTIVITY_LOGS = 'azureActivityLogs'
}

export interface IDatasourceFamily {
  type: ConnectorFamilies;
  isActive: boolean;
  createdAt: number;
  updatedAt: number;
}

export interface IDatasourceUsecaseInfo {
  createdAt: number;
  updatedAt: number;
  lastCreatedAt: number;
  datasourceFamily: ConnectorFamilies;
  usecasesCount: number;
}

export enum ModeFilterEnum {
  ALL = 'all',
  SILENCE = 'silence',
  ALERT = 'alert'
}

export interface DatasourcesDetailsQueryParams {
  searchName?: string;
  datasourceFamilies?: string;
  silenced?: ModeFilterEnum;
  priorities?: PrioritiesFilterEnum;
}

export enum DatasourceMode {
  SILENCE = 'silence',
  ALERT = 'alert'
}

export interface Icon {
  name: string;
  icon: string;
}

export interface IDatasourceTableObject {
  name: ITranslatedField;
  mode: DatasourceMode;
  priority: number;
  activationDate: number;
  description: ITranslatedField;
  datasources: string;
  datasourcesIcon: Icon[];
  catalogId: string;
}

export interface IDatasourceVignette {
  title: string;
  subtitle: string;
  number: number;
  icon: string;
}

export interface DatasourceInventoryInfo {
  title?: string;
  description?: string;
  logo?: string;
  activatedNumber?: number;
  activatedAt?: number;
  updatedAt?: number;
  state?: boolean;
  datasourceFamily?: ConnectorFamilies;
}
