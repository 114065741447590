<ng-container
              *ngIf="(isEditable || useFormValueForDisplay) && group"
              [formGroup]="group">
  <ng-container *ngIf="arrayName" [formArrayName]="arrayName">
    <ng-container [formGroupName]="groupName">
      <mat-checkbox
                    [formControlName]="controlName"
                    (change)="onCheckClick($event)"
                    (click)="$event.stopPropagation()">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </mat-checkbox>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!arrayName">
    <mat-checkbox
                  [formControlName]="controlName"
                  (change)="onCheckClick($event)"
                  (click)="$event.stopPropagation()">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </mat-checkbox>
  </ng-container>
</ng-container>

<mat-checkbox
              *ngIf="(isEditable || useFormValueForDisplay) && !group"
              [checked]="checked"
              (change)="onCheckClick($event)"
              (click)="$event.stopPropagation()">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</mat-checkbox>

<mat-checkbox
              *ngIf="!isEditable && !useFormValueForDisplay"
              [checked]="checked"
              disabled="disabled"
              (change)="onCheckClick($event)"
              (click)="$event.stopPropagation()">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</mat-checkbox>

<ng-template #content>
  <div class="flex-box center-horizontal" [ngClass]="fontSizeType">
    {{ label }}
    <ng-content></ng-content>
  </div>
</ng-template>