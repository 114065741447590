<img
  *ngIf="customImage"
  class="ui-avatar--picture"
  [src]="customImage"
  [alt]="customImage"
/>

<ng-container *ngIf="!customImage && officeId; else placeholder">
  <mgt-person [attr.user-id]="officeId">
    <template data-type="no-data">
      <ng-container *ngTemplateOutlet="placeholder"></ng-container>
    </template>
  </mgt-person>
</ng-container>

<ng-template #placeholder>
  <div
    *ngIf="fullname || user?.type === userType.SECURITY_GROUP; else noAvatar"
    class="ui-avatar--placeholder"
    [title]="fullname"
    [ngStyle]="{
      'background-color': backgroundColor,
      'border-color': backgroundColor,
      color: textColor
    }"
  >
    <ng-container *ngIf="user?.type !== userType.SECURITY_GROUP">
      {{ fullname | initials }}</ng-container>
    <ng-container *ngIf="user?.type === userType.SECURITY_GROUP">
      <img [src]="'assets/favicons/icon_users_widget_green.svg'" />
    </ng-container>
  </div>
</ng-template>

<ng-template #noAvatar><ng-container *ngIf="!customImage"> - </ng-container></ng-template>