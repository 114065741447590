export class SeverityBadge {
  label: string;
  color: string;
  textColor: string;

  constructor(label: string, color: string, textColor: string) {
    this.label = label;
    this.color = color;
    this.textColor = textColor;
  }
}
