import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import '@microsoft/mgt-components';
import { prune } from '../../services/utils';
import { UserTypeEnum } from '../autocomplete/user-autocomplete/user-autocomplete.component';

export const optionsBackground = {
  saturation: 70,
  lightness: 90,
};

export const optionsText = {
  saturation: 70,
  lightness: 30,
};

export enum UiAvatarSize {
  Smallest = 'smallest',
  Tiny = 'tiny',
  Small = 'small',
  Medium = 'medium',
  MediumLarge = 'medium_large',
  Large = 'large',
  ExtraLarge = 'extra_large'
}

@Component({
  selector: 'ui-avatar',
  templateUrl: './ui-avatar.component.html',
  styleUrls: [ './ui-avatar.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'aria-hidden': 'true',
    class: 'ui-avatar',
  },
})
export class UiAvatar implements OnInit, OnChanges {
  @Input() public class: string;

  @Input() public user: any;

  @Input() public officeId: string;

  @Input() public name = '';

  @Input() public customImage = null;

  @Input() public size: UiAvatarSize = UiAvatarSize.Small;

  @HostBinding('class') public classes: string;

  public completeSrc: string = null;

  public backgroundColor: string;

  public textColor: string;

  public userType = UserTypeEnum;

  public fullname = '';
  private componentName = 'ui-avatar';

  public ngOnInit(): void {
    this.init();
  }

  public ngOnChanges() {
    this.init();
  }

  public setup(): void {
    this.computeColor();

    const classes = {
      [this.componentName]: this,
      [`${this.componentName}_${this.size}`]: true,
    };

    if (this.class) {
      classes[this.class] = true;
    }

    this.classes = Object.keys(prune(classes)).join(' ');
  }

  public computeColor(): void {
    // gui: whimsical calculations please look elsewhere, lol.
    const hash =
      Array.from(this.fullname || '?').reduce(
        (h, c) => (h = c.charCodeAt(0) + ((h << 6) - h)),
        0
      ) % 360;
    this.backgroundColor =
      this.user?.type === UserTypeEnum.SECURITY_GROUP
        ? `#47c1bf`
        : `hsl(${hash}, ${optionsBackground.saturation}%, ${optionsBackground.lightness}%)`;
    this.textColor = `hsl(${hash}, ${optionsText.saturation}%, ${optionsText.lightness}%)`;
  }

  private init() {
    if (this.user) {
      if (this.user.firstName) {
        this.fullname = `${this.user.firstName} ${this.user.lastName || ''}`;
      } else if (this.user['name']) {
        this.fullname = this.user['name'];
      }

      if (
        (this.user['officeUser'] ||
          this.user['isO365'] ||
          this.user['isPlatformUser'] == false ||
          this.user['platformUser'] == false) &&
        this.user.o365UserId
      ) {
        this.officeId = this.user.o365UserId;
      }
    } else {
      this.fullname = this.name;
    }
    this.setup();
  }
}
