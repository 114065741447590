<div *ngFor="let branch of tree">
  <div
    (click)="toggleBranch(branch)"
    class="branch"
    [ngClass]="{ 'openable-branch': branch.branches?.length }"
  >
    <img
      [src]="
        'assets/favicons/' + (branch.isOpened ? openIcon : closeIcon) + '.svg'
      "
    />
    <span *ngIf="!isEditing">{{ branch.name }}</span>
    <ng-container *ngIf="isEditing">
      <input
        ui-input
        class="branch--name-input"
        [(value)]="branch.name"
        (valueChange)="validate()"
        [ngClass]="{ 'input-invalid': branch.isInvalid }"
      />
      <img
        [src]="'assets/favicons/icon_create.svg'"
        class="branch--action"
        (click)="addBranch(branch.branches)"
      />
      <img
        [src]="'assets/favicons/icon_delete.svg'"
        class="branch--action"
        (click)="removeBranches(branch)"
      />
    </ng-container>
  </div>

  <div *ngIf="branch.isOpened" class="sub-branches">
    <ui-tree
      [tree]="branch.branches"
      [locale]="locale"
      [openIcon]="openIcon"
      [closeIcon]="closeIcon"
      [isEditing]="isEditing"
      [isTopOfTree]="false"
      (onChanges)="onChanges.emit()"
    ></ui-tree>
  </div>
</div>

<button
  *ngIf="isEditing && isTopOfTree"
  icon="icon_create"
  [custom-icon]="true"
  [isSvg]="true"
  ui-button
  color="linkFlat"
  (click)="addBranch(tree)"
>
  {{ 'groupTemplates.newRootFolder.button.text' | t: {locale: locale} }}
</button>
