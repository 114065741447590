import "froala-editor/js/languages/fr";
import "froala-editor/js/plugins.pkgd.min";

import { LayoutModule } from "@angular/cdk/layout";
import { OverlayModule as CdkOverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { CdkTableModule } from "@angular/cdk/table";
import { CommonModule, DatePipe, registerLocaleData } from "@angular/common";
import localeFrExtra from "@angular/common/locales/extra/fr";
import localeFr from "@angular/common/locales/fr";
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatStepperModule } from "@angular/material/stepper";
import { RouterModule } from "@angular/router";
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";

import { TextMaskModule } from "angular2-text-mask";
import { Autocomplete } from "./components/autocomplete/autocomplete.component";
import { CustomAutocompleteComponent } from "./components/autocomplete/custom-autocomplete/custom-autocomplete.component";
import { OrganizationAutocompleteComponent } from "./components/autocomplete/organization-autocomplete/organization-autocomplete.component";
import { ResourceAutocomplete } from "./components/autocomplete/resource-autocomplete/resource-autocomplete.component";
import { UserAutocomplete } from "./components/autocomplete/user-autocomplete/user-autocomplete.component";
import { UserOfflineAutocomplete } from './components/autocomplete/user-offline-autocomplete/user-offline-autocomplete.component';
import { CapacityCardComponent } from "./components/capacity-card/capacity-card.component";
import { ClickOutHandlerComponent } from "./components/click-out-handler/click-out-handler.component";
import { ConfirmationModalComponent } from "./components/confirmation-modal/confirmation-modal.component";
import { DynamicChart } from './components/dynamic-chart/dynamic-chart.component';
import { HoursPickerComponent } from "./components/hours-picker/hours-picker.component";
import { HtmlEditorComponent } from "./components/html-editor/html-editor.component";
import { IndicatorCardComponent } from "./components/indicator-card/indicator-card.component";
import { JustificationConfirmationModalComponent } from './components/justification-confirmation-modal/justification-confirmation-modal.component';
import { TagList } from "./components/tag-list/tag-list.component";
import { ToolbarCollapsableElementComponent } from "./components/toolbar-filter/toolbar-collapsable-element/toolbar-collapsable-element.component";
import { ToolbarFilterComponent } from "./components/toolbar-filter/toolbar-filter.component";
import { UiActionsDropdown } from "./components/ui-actions-dropdown/ui-actions-dropdown.component";
import { UiAnchor } from "./components/ui-anchor/ui-anchor.component";
import { AppLoader } from "./components/ui-app-loader/app-loader.component";
import { UiAvatarResource } from "./components/ui-avatar-resource/ui-avatar-resource.component";
import { UiAvatar } from "./components/ui-avatar/ui-avatar.component";
import { UiAvatars } from "./components/ui-avatars/ui-avatars.component";
import { UiBadge } from "./components/ui-badge/ui-badge.component";
import { UiButton } from "./components/ui-button/ui-button.component";
import { UiCard } from "./components/ui-card/ui-card.component";
import { UiCarouselComponent } from "./components/ui-carousel/ui-carousel.component";
import { UiCheckbox } from "./components/ui-checkbox/ui-checkbox.component";
import { UiCircleIndicatorComponent } from "./components/ui-circle-indicator/ui-circle-indicator.component";
import { UiCollapsableCard } from "./components/ui-collapsable-card/ui-collapsable-card.component";
import { UiCollapsableDropDownComponent } from "./components/ui-collapsable-dropdown/ui-collapsable-dropdown.component";
import { UiCollapsableTable } from "./components/ui-collapsable-table/ui-collapsable-table.component";
import { UiCollapsableToggleImage } from "./components/ui-collapsable-toggle-image/ui-collapsable-toggle-image.component";
import { UiConfirmationsButtonsComponent } from "./components/ui-confirmations-buttons/ui-confirmations-buttons.component";
import { UiDatePicker } from "./components/ui-date-picker/ui-date-picker.component";
import { UiDatetimePickerComponent } from "./components/ui-datetime-picker/ui-datetime-picker.component";
import { UiDialog } from "./components/ui-dialog/ui-dialog.component";
import { UiDotIndicatorComponent } from "./components/ui-dot-indicator/ui-dot-indicator.component";
import { UiDurationTimePickerComponent } from "./components/ui-duration-time-picker/ui-duration-time-picker.component";
import { DynamicComponentHostDirective } from "./components/ui-dynamic-component/dynamic-component-host.directive";
import { DynamicComponent } from "./components/ui-dynamic-component/dynamic-component.component";
import { UiFieldGroup } from "./components/ui-field-group/ui-field-group.component";
import { UiField } from "./components/ui-field/ui-field.component";
import { UiFormActions } from "./components/ui-form-actions/ui-form-actions.component";
import { UiFormArrayCheckboxTable } from "./components/ui-form-array-checkbox-table/ui-form-array-checkbox-table.component";
import { UiForm } from "./components/ui-form/ui-form.component";
import { HubsiteTagComponent } from "./components/ui-hubsite-tag/ui-hubsite-tag.component";
import { UiIcon } from "./components/ui-icon/ui-icon.component";
import { UiIndicatorArrow } from "./components/ui-indicator-arrow/ui-indicator-arrow.component";
import { UiInformationMessage } from "./components/ui-information-message/ui-information-message.component";
import { UiInputGroup } from "./components/ui-input-group/ui-input-group.component";
import { UiInput } from "./components/ui-input/ui-input.component";
import { UiJsonTextareaComponent } from "./components/ui-json-textarea/ui-json-textarea.component";
import { UiJson } from "./components/ui-json/ui-json.component";
import { UiLabelInputWithModifyComponent } from "./components/ui-label-input-with-modify/ui-label-input-with-modify.component";
import { UiLabel } from "./components/ui-label/ui-label.component";
import { UiLabeledAvatarComponent } from "./components/ui-labeled-avatar/ui-labeled-avatar.component";
import { UiLanguageSelectorComponent } from "./components/ui-language-selector/ui-language-selector.component";
import { UiList } from "./components/ui-list/ui-list.component";
import { UiMenuOptionsComponent } from "./components/ui-menu-options/ui-menu-options.component";
import { UiMessageBandComponent } from "./components/ui-message-band/ui-message-band.component";
import { UiMobileCard } from "./components/ui-mobile-card/ui-mobile-card.component";
import { UiModalComponent } from "./components/ui-modal/ui-modal.component";
import { UiMultiSelect } from "./components/ui-multi-select/ui-multi-select.component";
import { UiPopoverComponent } from "./components/ui-popover/ui-popover.component";
import { UiPreferredLanguageFlagComponent } from "./components/ui-preferred-language-flag/ui-preferred-language-flag.component";
import { UiPreferredLanguageTagComponent } from "./components/ui-preferred-language-tag/ui-preferred-language-tag.component";
import { UiRadioButtonCards } from "./components/ui-radio-button-cards/ui-radio-button-cards.component";
import { UiResourceTypeBadgeComponent } from "./components/ui-resource-type-badge/ui-resource-type-badge.component";
import { UiSelectAutocompleteOptionComponent } from "./components/ui-select-autocomplete/ui-select-autocomplete-option/ui-select-autocomplete-option.component";
import { UiSelectAutocompleteComponent } from "./components/ui-select-autocomplete/ui-select-autocomplete.component";
import { UiSelectSingle } from "./components/ui-select-single/ui-select-single.component";
import { UiSelectWithLabelValue } from "./components/ui-select-with-label-value/ui-select-with-label-value.component";
import { UiSelect } from "./components/ui-select/ui-select.component";
import { UiSeverityBadgeComponent } from "./components/ui-severity-badge/ui-severity-badge.component";
import { UiSeverityChip } from "./components/ui-severity-chip/ui-severity-chip.component";
import { UiSimpleMultiSelectComponent } from "./components/ui-simple-multi-select/ui-simple-multi-select.component";
import { UiSpinner } from "./components/ui-spinner/ui-spinner.component";
import { UiResizableColumnComponent } from "./components/ui-static-table/ui-resizable-column/ui-resizable-column.component";
import { UiResizableColumnDirective } from "./components/ui-static-table/ui-resizable-column/ui-resizable-column.directive";
import { UiStaticTableEmpty } from "./components/ui-static-table/ui-static-table-empty/ui-static-table-empty.component";
import { UiStaticTableHeader } from "./components/ui-static-table/ui-static-table-header/ui-static-table-header.component";
import { UiStaticTableRow } from "./components/ui-static-table/ui-static-table-row/ui-static-table-row.component";
import { UiStaticTable } from "./components/ui-static-table/ui-static-table.component";
import { UiStatic } from "./components/ui-static/ui-static.component";
import { UiStatusBadgeComponent } from "./components/ui-status-badge/ui-status-badge.component";
import { UiStep } from "./components/ui-stepper/ui-step.directive";
import { UiStepper } from "./components/ui-stepper/ui-stepper.component";
import { UiSwitchFieldStatic } from "./components/ui-switch-field-static/ui-switch-field-static.component";
import { UiSwitchRadioStatic } from "./components/ui-switch-radio-static/ui-switch-radio-static.component";
import { UiSwitchTextAreaStatic } from "./components/ui-switch-text-area-static/ui-switch-text-area-static.component";
import { UiSwitchTextStatic } from "./components/ui-switch-text-static/ui-switch-text-static.component";
import { UiTableScrollableBodyComponent } from './components/ui-table-scrollable-body/ui-table-scrollable-body.component';
import { UiTableDateToolComponent } from "./components/ui-table-tools/ui-table-date-tool/ui-table-date-tool.component";
import { UiTableExportTool } from "./components/ui-table-tools/ui-table-export-tool/ui-table-export-tool.component";
import { UiTableFilterTool } from "./components/ui-table-tools/ui-table-filter-tool/ui-table-filter-tool.component";
import { UiTableFilterTooltip } from "./components/ui-table-tools/ui-table-filter-tool/ui-table-filter-tooltip/ui-table-filter-tooltip.component";
import { UiTableRefreshTool } from "./components/ui-table-tools/ui-table-refresh-tool/ui-table-refresh-tool.component";
import { UiTableSearchTool } from "./components/ui-table-tools/ui-table-search-tool/ui-table-search-tool.component";
import { UiTableShortidSearchComponent } from "./components/ui-table-tools/ui-table-shortid-search/ui-table-shortid-search.component";
import { UiTableSortToolComponent } from "./components/ui-table-tools/ui-table-sort-tool/ui-table-sort-tool.component";
import { UiTableTotalTool } from "./components/ui-table-tools/ui-table-total-tool/ui-table-total-tool.component";
import { UiCellWithButtons } from "./components/ui-table/ui-cell-with-buttons/ui-cell-with-buttons.component";
import { UiFooterRow } from "./components/ui-table/ui-footer-row/ui-footer-row.component";
import { UiHeaderRow } from "./components/ui-table/ui-header-row/ui-header-row.component";
import { UiHeaderSort } from "./components/ui-table/ui-header-sort/ui-header-sort.component";
import { UiRow } from "./components/ui-table/ui-row/ui-row.component";
import { UiTable } from "./components/ui-table/ui-table.component";
import { UiTab } from "./components/ui-tabs/ui-tab.component";
import { UiTabsActionsContent } from "./components/ui-tabs/ui-tabs-actions/ui-tabs-actions-content/ui-tabs-actions-content.component";
import { UiTabsActions } from "./components/ui-tabs/ui-tabs-actions/ui-tabs-actions.component";
import { UiTabs } from "./components/ui-tabs/ui-tabs.component";
import { UiTextField } from "./components/ui-text-field/ui-text-field.component";
import { UiTimePicker } from "./components/ui-time-picker/ui-time-picker.component";
import { UiTitleDescriptionButtonComponent } from "./components/ui-title-description-button/ui-title-description-button.component";
import { UiTitle } from "./components/ui-title/ui-title.component";
import { UiToggleListComponent } from "./components/ui-toggle-list/ui-toggle-list.component";
import { UiToggle } from "./components/ui-toggle/ui-toggle.component";
import { UiTooltip } from "./components/ui-tooltip/ui-tooltip.component";
import { UiTree } from "./components/ui-tree/ui-tree.component";
import { UserTagComponent } from "./components/ui-user-tag/ui-user-tag.component";
import { UserTagsComponent } from "./components/ui-user-tags/ui-user-tags.component";
import { UiWarning } from "./components/ui-warning/ui-warning.component";
import { UserNotOnboardedIcon } from "./components/user-not-onboarded-icon/user-not-onboarded-icon.component";
import { WidgetContainer } from "./components/widget-container/widget-container.component";
import { WidgetTimeRangeComponent } from "./components/widget-time-range/widget-time-range.component";
import { WidgetTitle } from "./components/widget-title/widget-title.component";
import { UiNumberOnlyInputDirective } from "./directives/number-only-input/ui-number-only-input.directive";
import { UiTooltipDirective } from "./directives/tooltip/ui-tooltip.directive";
import { UiAccessibleChoiceListDirective } from "./directives/ui-accessible-choice-list.directive";
import { UiCellDef } from "./directives/ui-cell-def.directive";
import { UiCell } from "./directives/ui-cell.directive";
import { UiColumnDef } from "./directives/ui-column-def.directive";
import { UiFooterCellDef } from "./directives/ui-footer-cell-def.directive";
import { UiFooterCell } from "./directives/ui-footer-cell.directive";
import { UiFooterRowDef } from "./directives/ui-footer-row-def.directive";
import { UiHeaderCellDef } from "./directives/ui-header-cell-def.directive";
import { UiHeaderCell } from "./directives/ui-header-cell.directive";
import { UiHeaderRowDef } from "./directives/ui-header-row-def.directive";
import { UiRowDef } from "./directives/ui-row-def.directive";
import { DateAgoPipe } from "./pipes/dateAgo.pipe";
import { DateUntilPipe } from "./pipes/dateUntil.pipe";
import { DTPipe } from "./pipes/dt.pipe";
import { DynamicPipe } from "./pipes/dynamic.pipe";
import { EnumPipe } from "./pipes/enum.pipe";
import { FullnamePipe } from "./pipes/fullname.pipe";
import { HoursPipe } from "./pipes/hours.pipe";
import { InitialsPipe } from "./pipes/initials.pipe";
import { JsonPrettierPipe } from "./pipes/json-prettier.pipe";
import { ResourceTranslationPipe } from "./pipes/resourceTranslation.pipe";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { TPipe } from "./pipes/t.pipe";
import { TimeDurationPipe } from "./pipes/time-duration.pipe";
import { TranslatedObjectLocalePipe } from "./pipes/translatedObjectLocale.pipe";
import { UiExpendableSectionComponent } from "./components/ui-expendable-section/ui-expendable-section.component";

registerLocaleData(localeFr, 'fr-CA', localeFrExtra);

const components = [
  UiIcon,
  UiButton,
  UiForm,
  UiFormActions,
  UiLabel,
  UiInput,
  UiInputGroup,
  UiField,
  UiFieldGroup,
  UiHeaderCellDef,
  UiColumnDef,
  UiCellDef,
  UiRowDef,
  UiFooterCellDef,
  UiFooterRowDef,
  UiHeaderCell,
  UiCell,
  UiFooterCell,
  UiHeaderRow,
  UiRow,
  UiCellWithButtons,
  UiFooterRow,
  UiHeaderRowDef,
  UiHeaderSort,
  UiTable,
  UiTableExportTool,
  UiTableRefreshTool,
  UiTableTotalTool,
  UiTableSearchTool,
  UiTableFilterTool,
  UiTableFilterTooltip,
  UiCard,
  UiSpinner,
  UiBadge,
  UiTabs,
  UiTabsActions,
  UiTabsActionsContent,
  UiTab,
  UiStatusBadgeComponent,
  UiStatic,
  UiSwitchFieldStatic,
  UiSwitchRadioStatic,
  UiTitle,
  UiStaticTable,
  UiStaticTableRow,
  UiStaticTableHeader,
  UiStaticTableEmpty,
  UiFormArrayCheckboxTable,
  UiCheckbox,
  UiRadioButtonCards,
  UiActionsDropdown,
  UiCollapsableCard,
  UiMenuOptionsComponent,
  UiSwitchTextAreaStatic,
  UiSelect,
  UiTooltip,
  UiSelectSingle,
  UiSelectAutocompleteComponent,
  UiSelectAutocompleteOptionComponent,
  UiSelectWithLabelValue,
  UiToggleListComponent,
  UiToggle,
  UiTextField,
  UiDatePicker,
  UiSwitchFieldStatic,
  UiSwitchTextStatic,
  UiModalComponent,
  UiTitleDescriptionButtonComponent,
  UiCollapsableTable,
  UiJson,
  UiTimePicker,
  UiSeverityChip,
  UiMessageBandComponent,
  UiLabeledAvatarComponent,
  UiIndicatorArrow,
  UiCircleIndicatorComponent,
  UiPopoverComponent,
  UiTableSortToolComponent,
  UiStepper,
  UiStep,
  UiWarning,
  UiResizableColumnComponent,
  UiResizableColumnDirective,
  DynamicComponent,
  DynamicComponentHostDirective,
  UiList,
  UiLanguageSelectorComponent,
  UiCollapsableDropDownComponent,
  UiDotIndicatorComponent,
  UiMultiSelect,
  UiSimpleMultiSelectComponent,
  UiMobileCard,
  UiCollapsableToggleImage,
  UiAnchor,
  AppLoader,
  UiDialog,
  HoursPickerComponent,
  Autocomplete,
  CustomAutocompleteComponent,
  ResourceAutocomplete,
  OrganizationAutocompleteComponent,
  TagList,
  UiAvatar,
  UiAvatars,
  UserAutocomplete,
  UserOfflineAutocomplete,
  UserNotOnboardedIcon,
  FullnamePipe,
  UiLabelInputWithModifyComponent,
  HtmlEditorComponent,
  UiTableDateToolComponent,
  ToolbarFilterComponent,
  ToolbarCollapsableElementComponent,
  ConfirmationModalComponent,
  WidgetContainer,
  WidgetTimeRangeComponent,
  WidgetTitle,
  UiResourceTypeBadgeComponent,
  UiAvatarResource,
  UiSeverityBadgeComponent,
  UiPreferredLanguageFlagComponent,
  CapacityCardComponent,
  IndicatorCardComponent,
  UiDatetimePickerComponent,
  UiConfirmationsButtonsComponent,
  UiTableShortidSearchComponent,
  UiDurationTimePickerComponent,
  ClickOutHandlerComponent,
  UiPreferredLanguageTagComponent,
  UiTree,
  HubsiteTagComponent,
  UserTagsComponent,
  UserTagComponent,
  UiInformationMessage,
  UiJsonTextareaComponent,
  DynamicChart,
  UiCarouselComponent,
  JustificationConfirmationModalComponent,
  UiTableScrollableBodyComponent,
  UiExpendableSectionComponent
];

const pipes = [
  DynamicPipe,
  DTPipe,
  JsonPrettierPipe,
  TranslatedObjectLocalePipe,
  ResourceTranslationPipe,
  InitialsPipe,
  FullnamePipe,
  SafeHtmlPipe,
  EnumPipe,
  HoursPipe,
  TimeDurationPipe,
  DateAgoPipe,
  DateUntilPipe
];

const materials = [
  MatNativeDateModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  LayoutModule,
  RouterModule,
  CdkTableModule,
  CdkOverlayModule,
  MatSelectModule,
  MatRadioModule,
  ScrollingModule,
  MatMenuModule,
  MatIconModule,
  MatButtonModule,
  MatCheckboxModule,
  PortalModule,
  MatDatepickerModule,
  CdkStepperModule,
  MatStepperModule,
  FroalaEditorModule,
  FroalaViewModule,
  TextMaskModule,
];

const directives = [UiTooltipDirective, UiAccessibleChoiceListDirective, UiNumberOnlyInputDirective];

@NgModule({
  imports: [
    materials,
  ],
  declarations: [
    ...components,
    ...directives,
    ...pipes,
    TPipe,
  ],
  exports: [...components, ...directives, ...pipes],
  providers: [DatePipe, ...pipes],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UiKitModule {
  static bootstrap(config: {
    mobileServiceClass: any;
  }): ModuleWithProviders<UiKitModule> {
    return {
      ngModule: UiKitModule,
      providers: [
        { provide: 'MobileService', useClass: config.mobileServiceClass },
      ],
    };
  }
}
