import { Component, Input, OnDestroy } from '@angular/core';
import * as uuid from 'uuid';
import { UiTabs } from './ui-tabs.component';

@Component({
  selector: 'ui-tab',
  template: '<div [hidden]="!active" style="height: 100%"><ng-content></ng-content></div>',
})
export class UiTab implements OnDestroy {
  @Input() tabTitle: string;

  @Input() isShown = true;

  @Input() isDisabled: boolean = false;

  @Input() tabName: string;

  @Input() icon: string;

  @Input() tooltipTitle: string;

  @Input() iconOpacity = 0.3;

  @Input() public tabImageActive: string;

  @Input() public tabImageInactive: string;

  public active: boolean;

  public id: string;

  private tabs: UiTabs;

  constructor(tabs: UiTabs) {
    this.id = uuid.v4();
    this.tabs = tabs;
    tabs.addTab(this);
  }

  ngOnInit(): void {
    if (!this.tabImageInactive && this.tabImageActive) {
      this.tabImageInactive = this.tabImageActive;
    }
  }

  ngOnDestroy(): void {
    this.tabs.removeTab(this);
  }
}
