import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-message-band',
  templateUrl: './ui-message-band.component.html',
  styleUrls: [ './ui-message-band.component.scss' ],
})
export class UiMessageBandComponent {
  @Input() messageFirst: string;

  @Input() messageClickable: string;

  @Input() messageThird: string;

  @Input() click: void;
}
