import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: [ './app-loader.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-loader',
  },
})
export class AppLoader {}
