import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { Eco } from "projects/@common/definitions/eco";
import { select, tmpl } from "projects/@common/utils/utils";

import { LoadI18n, SetI18nLocale } from "./state/i18n.state";

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  public constructor(protected readonly store: Store) { }

  public static browserLocale(): string {
    const navigator = window.navigator;
    const languages =
      navigator.languages || navigator.language || navigator['browserLanguage'] || navigator['userLanguage'];
    const [ language ] = languages;
    const [ locale ] = language.split('-');
    return locale;
  }

  public get presetLocale(): string | null {
    return localStorage.getItem('locale');
  }

  public set presetLocale(locale: string | null) {
    if (locale) {
      localStorage.setItem('locale', locale);
    } else {
      localStorage.removeItem('locale');
    }
  }

  public get currentLocale(): string {
    return this.store.selectSnapshot<string>((state) => state?.i18n?.locale);
  }

  public get dateCurrentLocale(): string {
    return this.currentLocale === 'fr' ? 'fr-ca' : this.currentLocale;
  }

  public async initialize(): Promise<void> {
    await this.store.dispatch(new LoadI18n()).toPromise(); // load the fr.json and en.json files

    const locale = this.presetLocale || I18nService.browserLocale() || 'en';
    this.setCurrentLocale(locale);
  }

  public async setCurrentLocale(locale: string, savePreset = false): Promise<void> {
    if (savePreset) {
      this.presetLocale = locale;
    }
    this.store.dispatch(new SetI18nLocale({ locale }));
  }

  public translate(key: string, params: any = {}, otherwise: string = key): string {
    const value = this.store.selectSnapshot<string>((state) => select(state, [ 'i18n', 'collection', this.currentLocale, key ], otherwise));

    if (typeof value !== 'string') {
      return '[i18n] trying to translate a non-string';
    }

    if (value.indexOf('{') === -1 || key.endsWith('_SKIP_INTERPOLATION')) {
      return value;
    }

    return tmpl(value, {
      evaluate: /{%([\s\S]+?)%}/gim,
      interpolate: /{{([\s\S]+?)}}/gim,
    })(params);
  }

  public translateWithLanguage(key: string, language: Eco.Languages, params: any = {}): string {
    const value = this.store.selectSnapshot<string>((state) => select(state, [ 'i18n', 'collection', language, key ]));

    if (typeof value !== 'string') {
      return '[i18n] trying to translate a non-string';
    }

    if (value.indexOf('{') === -1) {
      return value;
    }

    return tmpl(value, {
      evaluate: /{%([\s\S]+?)%}/gim,
      interpolate: /{{([\s\S]+?)}}/gim,
    })(params);
  }
}
