import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";

export enum ExpendableSidesEnum {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT'
}

export enum ExpendableTypeEnum {
  BIG_WHITE = 'BIG_WHITE',
  MEDIUM_HEADER = 'MEDIUM_HEADER',
  COLUMN = 'COLUMN'
}

export interface IExpendableConfig {
  chevronSide: ExpendableSidesEnum;
  backgroundColor: string;
  borderAround?: boolean;
  borderStyle?: boolean;
}

@Component({
  selector: 'ui-expendable-section',
  templateUrl: './ui-expendable-section.component.html',
  styleUrls: [ './ui-expendable-section.component.scss' ],
})
export class UiExpendableSectionComponent implements OnChanges {
  @Input() type: ExpendableTypeEnum = ExpendableTypeEnum.BIG_WHITE;
  @Input() borderBottomWhenOpen = false;
  @Input() forceExpanded = false;
  @Input() readonly = false;
  @Input() transparentBackground = false;

  @Output() collapsedEvent = new EventEmitter<void>();
  @Output() expandedEvent = new EventEmitter<void>();

  public expendableSidesEnum = ExpendableSidesEnum;
  private _isCollapsed = true;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.forceExpanded?.currentValue === true) {
      this._isCollapsed = false;
    }
  }

  public get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  public get backgroundColor(): string {
    if (this.transparentBackground) {
      return 'transparent';
    } else if (!this._isCollapsed && this.type === ExpendableTypeEnum.COLUMN) {
      return '#f6f6f6';
    }
    return this.config.backgroundColor;
  }

  public get config(): IExpendableConfig {
    switch (this.type) {
      case ExpendableTypeEnum.BIG_WHITE:
        return {
          backgroundColor: 'white',
          chevronSide: ExpendableSidesEnum.LEFT,
          borderAround: false,
          borderStyle: false,
        };
      case ExpendableTypeEnum.MEDIUM_HEADER:
        return {
          backgroundColor: '#F2F4F6',
          chevronSide: ExpendableSidesEnum.RIGHT,
          borderAround: true,
          borderStyle: false,
        };
      case ExpendableTypeEnum.COLUMN:
        return {
          backgroundColor: 'white',
          chevronSide: ExpendableSidesEnum.LEFT,
          borderAround: true,
          borderStyle: true,
        };
    }
  }

  public toggleCollapse(): void {
    if (!this.readonly) {
      this._isCollapsed = !this._isCollapsed;
      if (this._isCollapsed) {
        this.collapsedEvent.emit();
      } else {
        this.expandedEvent.emit();
      }
    }
  }
}
