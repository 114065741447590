/* eslint-disable no-use-before-define */
import { Directive } from '@angular/core';
import { CdkFooterRowDef } from '@angular/cdk/table';

@Directive({
  selector: '[uiFooterRowDef]',
  providers: [ { provide: CdkFooterRowDef, useExisting: UiFooterRowDef } ],
  inputs: [ 'columns: uiFooterRowDef' ],
})
export class UiFooterRowDef extends CdkFooterRowDef {
  static ngAcceptInputType_sticky: boolean | string | null | undefined;
}
