import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { IMobileService } from '../../interfaces/IMobileService';
import { DialogState, HideDialog, ShowDialog } from './stores/ui-dialog.store';

export enum DialogActions {
  ACTION = 'action',
  CANCEL = 'cancel'
}

@Component({
  selector: 'ui-dialog',
  templateUrl: './ui-dialog.component.html',
  styleUrls: [ './ui-dialog.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-dialog',
  },
})
export class UiDialog implements OnInit {
  @Input() public withActionButton = true;

  @Input() public withCancelButton = true;

  @Input() public showDialogHeader = true;

  @Input() public actionButton = 'common.action.ok';

  @Input() public actionButtonDisabledCondition = false;

  @Input() public disabledReason: string;

  @Input() public cancelButton = 'common.action.cancel';

  @Input('headtitle') public title: string;

  @Input() public message: string;

  @Input() public isLoading = false;

  @Input() public isInDrawer = false;

  @Input() public isSmall = false;

  @Input() public isFullMobileScreen = false;

  @Input() public shouldContentOverflow = true;

  @Output() onButtonClick = new EventEmitter();

  @Select(DialogState.isShown) public isShown$: Observable<boolean>;

  // DEP - store
  constructor(
    private store: Store,
    @Inject('MobileService') public readonly mobileService: IMobileService
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(new ShowDialog());
  }

  cancelClick(): void {
    this.onButtonClick.emit(DialogActions.CANCEL);
    this.store.dispatch(new HideDialog());
  }

  actionClick(): void {
    this.onButtonClick.emit(DialogActions.ACTION);
  }
}
