import { Component, ComponentFactoryResolver, EventEmitter, Input, OnChanges, Type, ViewChild } from "@angular/core";

import { DynamicComponentHostDirective } from "./dynamic-component-host.directive";

export interface DynamicComponentInterface {
  component: Type<any>;
  data: any;
}

@Component({
  selector: 'dynamic-component',
  templateUrl: './dynamic-component.component.html',
})
export class DynamicComponent implements OnChanges {
  @Input() dynamicComponent: DynamicComponentInterface;
  @Input() emitter: EventEmitter<any>;

  @ViewChild(DynamicComponentHostDirective, { static: true }) dynamicComponentHost: DynamicComponentHostDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnChanges() {
    this.loadComponent();
  }

  loadComponent() {
    if (this.dynamicComponent?.component) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.dynamicComponent.component);

      const viewContainerRef = this.dynamicComponentHost.viewContainerRef;
      viewContainerRef.clear();

      const componentType = this.dynamicComponent.component;

      const componentRef = viewContainerRef.createComponent<typeof componentType>(componentFactory);
      for (const key in this.dynamicComponent.data) {
        if (key) {
          componentRef.instance[key] = this.dynamicComponent.data[key];
        }
      }
    }
  }
}
