import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { LanguageEnum } from "../../../interfaces/ILanguage";
import { I18nService } from "../../../services/I18nService";
import { TimeUtil } from "../../../services/time-util";
import { clone, equal } from "../../../services/utils";
import { CustomTimePeriod, CustomTimePeriodChangeEventSource } from "./customTimePeriod";

export type DateTimePeriod = {
  dateFrom: number;
  dateTo?: number
};

export enum PresetOptionValuesEnum {
  today = "today",
  last_24_hours = "last_24_hours",
  last_7_days = "last_7_days",
  last_30_days = "last_30_days",
  last_90_days = "last_90_days",
  last_180_days = "last_180_days",
  last_365_days = "last_365_days"
}

export interface IPresetOption {
  value: PresetOptionValuesEnum;
  translatedLabel: string;
  getPresetTimePeriod: () => DateTimePeriod;
}

@Component({
  selector: 'ui-table-date-tool',
  templateUrl: './ui-table-date-tool.component.html',
  styleUrls: [ './ui-table-date-tool.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class UiTableDateToolComponent implements OnInit {
  @Output() public timeEmitter = new EventEmitter<DateTimePeriod>();
  @Output() public resetDateEmitter = new EventEmitter<void>();

  @Input() public locale = LanguageEnum.FRENCH;
  @Input() public showCustomDatetimePickers = true;
  @Input() public withHours = true;
  @Input() public minDate: Date;
  @Input() public maxDate: Date;
  @Input() public maxDaysInCustomRange: number = null;
  @Input() public excludePresets: PresetOptionValuesEnum[] = [];
  @Input() public defaultTriggerButtonLabel: string;
  @Input() public defaultPresetOption: PresetOptionValuesEnum;
  @Input() public defaultCustomTimePeriod: DateTimePeriod;
  @Input() public displayDropdownToLeft: boolean = false;

  // active while user edits the controls but not applied yet.
  public currentTimePeriod: DateTimePeriod;
  public currentTriggerButtonLabel: string;

  // currently applied because the user pressed the apply button.
  public appliedTimePeriod: DateTimePeriod;
  public appliedTriggerButtonLabel: string;

  public presetOptions: IPresetOption[] = [];

  public showDropdown = false;

  public customTimePeriod: CustomTimePeriod;
  public isUsingCustomTimePeriod = false;

  constructor(
    protected readonly ref: ElementRef,
    private readonly i18n: I18nService
  ) {
  }

  ngOnInit(): void {
    this.customTimePeriod = new CustomTimePeriod();
    this.presetOptions = this.getDefaultPresetOptions();

    if (this.defaultCustomTimePeriod && (this.defaultCustomTimePeriod.dateFrom || this.defaultCustomTimePeriod.dateTo)) {
      this.customTimePeriod.timestampFrom = this.defaultCustomTimePeriod.dateFrom;
      this.customTimePeriod.timestampTo = this.defaultCustomTimePeriod.dateTo;
      this.useCustomTimePeriodState();
      this.appliedTimePeriod = clone(this.currentTimePeriod);
      this.appliedTriggerButtonLabel = this.currentTriggerButtonLabel;
    } else if (this.defaultPresetOption) {
      this.applyDefaultTimePeriod();
    }

    if (!this.defaultTriggerButtonLabel) {
      this.defaultTriggerButtonLabel = this.i18n.translate("ui.table-date-tool.trigger-button", { locale: this.locale }, "Filtre de date");
    }
  }

  @HostListener('keydown.escape', [ '$event' ])
  onEscapeKey(event: KeyboardEvent): void {
    if (this.showDropdown) {
      event.stopPropagation();
      this.toggleDropdown();
    }
  }

  public get dateNow(): Date {
    return new Date();
  }

  public get isValidDateFrom(): boolean {
    return !this.currentTimePeriod?.dateFrom || this.currentTimePeriod.dateFrom > TimeUtil.dayDuration;
  }

  public get isValidDateTo(): boolean {
    return !this.currentTimePeriod?.dateTo || this.currentTimePeriod?.dateTo > TimeUtil.dayDuration;
  }

  public get isValid(): boolean {
    return this.isValidDateFrom && this.isValidDateTo;
  }

  public get hasChanged(): boolean {
    return !equal(this.appliedTimePeriod, this.currentTimePeriod);
  }

  public get isApplyButtonAvailable(): boolean {
    return this.hasChanged && this.isValid && this.currentTimePeriod.dateFrom > 0 && this.currentTimePeriod.dateTo > 0 && this.currentTimePeriod.dateFrom < this.currentTimePeriod.dateTo;
  }

  public toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;

    if (!this.customTimePeriod.isValidStart) {
      this.customTimePeriod.resetStart();
    }
    if (!this.customTimePeriod.isValidEnd) {
      this.customTimePeriod.resetEnd();
    }
  }

  public getDefaultPresetOptions(): IPresetOption[] {
    const options = [
      {
        value: PresetOptionValuesEnum.today,
        translatedLabel: this.i18n.translate(`ui.table-date-tool.periods.${PresetOptionValuesEnum.today}`, { locale: this.locale }, "Aujourd'hui"),
        getPresetTimePeriod: () => ({ dateFrom: this.getTimestampAtFirstHourOfDay(Date.now()) }),
      },
      {
        value: PresetOptionValuesEnum.last_24_hours,
        translatedLabel: this.i18n.translate(`ui.table-date-tool.periods.${PresetOptionValuesEnum.last_24_hours}`, { locale: this.locale }, "Dernières 24 heures"),
        getPresetTimePeriod: () => TimeUtil.getTimestampFromNowAndDays(1),
      },
      {
        value: PresetOptionValuesEnum.last_7_days,
        translatedLabel: this.i18n.translate(`ui.table-date-tool.periods.${PresetOptionValuesEnum.last_7_days}`, { locale: this.locale }, "7 derniers jours"),
        getPresetTimePeriod: () => TimeUtil.getTimestampFromNowAndDays(7),
      },
      {
        value: PresetOptionValuesEnum.last_30_days,
        translatedLabel: this.i18n.translate(`ui.table-date-tool.periods.${PresetOptionValuesEnum.last_30_days}`, { locale: this.locale }, "30 derniers jours"),
        getPresetTimePeriod: () => TimeUtil.getTimestampFromNowAndDays(30),
      },
      {
        value: PresetOptionValuesEnum.last_90_days,
        translatedLabel: this.i18n.translate(`ui.table-date-tool.periods.${PresetOptionValuesEnum.last_90_days}`, { locale: this.locale }, "90 derniers jours"),
        getPresetTimePeriod: () => TimeUtil.getTimestampFromNowAndDays(90),
      },
      {
        value: PresetOptionValuesEnum.last_180_days,
        translatedLabel: this.i18n.translate(`ui.table-date-tool.periods.${PresetOptionValuesEnum.last_180_days}`, { locale: this.locale }, "180 derniers jours"),
        getPresetTimePeriod: () => TimeUtil.getTimestampFromNowAndDays(180),
      },
      {
        value: PresetOptionValuesEnum.last_365_days,
        translatedLabel: this.i18n.translate(`ui.table-date-tool.periods.${PresetOptionValuesEnum.last_365_days}`, { locale: this.locale }, "365 derniers jours"),
        getPresetTimePeriod: () => TimeUtil.getTimestampFromNowAndDays(365),
      },
    ];

    if (!this.withHours) {
      this.excludePresets.push(PresetOptionValuesEnum.last_24_hours);
    }

    return options.filter((option) => !this.excludePresets.includes(option.value));
  }

  public onClickPresetDateFilterButton(presetOption: IPresetOption): void {
    this.isUsingCustomTimePeriod = false;
    this.currentTriggerButtonLabel = presetOption.translatedLabel;
    this.currentTimePeriod = presetOption.getPresetTimePeriod();
    this.applyChanges();
  }

  public onClickCustomTimePeriodFilterButton(): void {
    this.useCustomTimePeriodState();
  }

  public onChangeCustomTimePeriod(milliseconds: number, source: CustomTimePeriodChangeEventSource): void {
    this.customTimePeriod.onChangeCustomTimePeriod(milliseconds, source);
    this.useCustomTimePeriodState();
  }

  public applyDefaultTimePeriod(): void {
    const defaultPeriod = this.presetOptions.find((period) => period.value === this.defaultPresetOption);
    this.currentTriggerButtonLabel = defaultPeriod.translatedLabel;
    this.currentTimePeriod = defaultPeriod.getPresetTimePeriod();

    this.appliedTimePeriod = clone(this.currentTimePeriod);
    this.appliedTriggerButtonLabel = clone(this.currentTriggerButtonLabel);
    this.isUsingCustomTimePeriod = false;
  }

  public onClickResetButton(): void {
    this.clearAll();
    this.toggleDropdown();
  }

  public clearAll(): void {
    this.isUsingCustomTimePeriod = false;
    this.currentTimePeriod = null;
    this.currentTriggerButtonLabel = null;
    this.appliedTimePeriod = null;
    this.appliedTriggerButtonLabel = null;
    this.customTimePeriod.resetAll();
    this.resetDateEmitter.emit();
  }

  public applyChanges(): void {
    this.appliedTimePeriod = clone(this.currentTimePeriod);
    this.appliedTriggerButtonLabel = clone(this.currentTriggerButtonLabel);
    this.toggleDropdown();
    this.emitTimePeriodChange();
  }

  public displayDropdownBelowButton(): boolean {
    const dropdownHeightThreshold = 406;
    return window.outerHeight / 2 > dropdownHeightThreshold;
  }

  public getMinDate(datePickerSource: 'dateFrom' | 'dateTo'): Date {
    if (this.maxDaysInCustomRange) {
      let minTimestamp = 0;
      if (this.customTimePeriod.timestampTo) {
        minTimestamp = this.customTimePeriod.timestampTo - (this.maxDaysInCustomRange * TimeUtil.dayDuration);
      }

      if (datePickerSource === 'dateTo' && this.customTimePeriod.timestampFrom) {
        // do not allow the min date to be less than the dateFrom
        minTimestamp = minTimestamp < this.customTimePeriod.timestampFrom ? this.customTimePeriod.timestampFrom : minTimestamp;
      }

      return new Date(minTimestamp);
    }
    return this.minDate;
  }

  public getMaxDate(datePickerSource: 'dateFrom' | 'dateTo'): Date {
    if (this.maxDaysInCustomRange) {
      const now = this.dateNow.getTime();
      let maxTimestamp = now;
      if (this.customTimePeriod.timestampFrom) {
        maxTimestamp = this.customTimePeriod.timestampFrom + (this.maxDaysInCustomRange * TimeUtil.dayDuration);
      }

      if (datePickerSource === 'dateFrom' && this.customTimePeriod.timestampTo) {
        // do not allow the max date to be greater than the dateTo
        maxTimestamp = maxTimestamp > this.customTimePeriod.timestampTo ? this.customTimePeriod.timestampTo : maxTimestamp;
      }

      return new Date(maxTimestamp > now ? now : maxTimestamp);
    }
    return this.maxDate;
  }

  public refresh(): void {
    this.emitTimePeriodChange();
  }

  private useCustomTimePeriodState(): void {
    this.isUsingCustomTimePeriod = true;
    this.currentTimePeriod = {
      dateFrom: this.customTimePeriod.timestampFrom,
      dateTo: this.customTimePeriod.timestampTo,
    };
    this.currentTriggerButtonLabel = this.i18n.translate("ui.table-date-tool.periods.custom", { locale: this.locale, maxDaysInCustomRange: null });
  }

  private getTimestampAtFirstHourOfDay(timestamp: number): number {
    return timestamp ? Number(TimeUtil.getFirstTimestampOfTheDay(timestamp)) : null;
  }

  private getTimestampAtLastHourOfDay(timestamp: number): number {
    return timestamp ? Number(TimeUtil.getLastTimestampOfTheDay(timestamp)) : null;
  }

  private emitTimePeriodChange(): void {
    if (this.withHours) {
      this.timeEmitter.emit(this.appliedTimePeriod);
    } else {
      this.timeEmitter.emit({
        dateFrom: this.getTimestampAtFirstHourOfDay(this.appliedTimePeriod.dateFrom),
        dateTo: this.getTimestampAtLastHourOfDay(this.appliedTimePeriod.dateTo),
      });
    }
  }
}
