import { Component, Input, OnInit } from '@angular/core';

export enum CircleIndicatorColorEnum {
  GRAY = 'gray',
  RED = 'red',
  GREEN = 'green'
}

export enum CircleIndicatorSizeEnum {
  SMALL = 'small',
  MEDIUM = 'medium'
}

@Component({
  selector: 'ui-circle-indicator',
  templateUrl: './ui-circle-indicator.component.html',
  styleUrls: [ './ui-circle-indicator.component.scss' ],
})
export class UiCircleIndicatorComponent implements OnInit {
  @Input() public value: string | number;

  @Input() size = CircleIndicatorSizeEnum.MEDIUM;

  @Input() color = CircleIndicatorColorEnum.GRAY;

  public classes: string;

  public ngOnInit() {
    this.classes = `circle-indicator ${this.color}-border ${this.size}-circle`;
  }
}
