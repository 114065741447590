import { HttpClient } from '@angular/common/http';
import { EcoUrlService } from '../../core/url.service';
import { EcoApi, ApiEndpointPrefixEnum } from 'projects/@common/definitions/eco-api';
import { OrganizationParametersFilters } from 'projects/@common/services/definitions/fetchOrganizations.definition';
import { GetManagedOrganizationResponse } from './connectors-api-organizations';
import { IConnectorOrganizationsApi, DescribeOrganizationResponse } from './definitions';

export class CustomerConnectorsApiOrganizationService extends EcoApi implements IConnectorOrganizationsApi {
  protected prefix = ApiEndpointPrefixEnum.detect_connectors;

  constructor(
    http: HttpClient,
    url: EcoUrlService
  ) {
    super(http, url);
  }

  public getManagedOrganizations(params?: OrganizationParametersFilters): Promise<GetManagedOrganizationResponse> {
    throw new Error('Method not implemented.');
  }

  public describeOrganization(organizationId: string): Promise<DescribeOrganizationResponse> {
    return this.http.get<any>(`${this.host}/me/organization/describe`).toPromise();
  }
}
