<div class="sidebar-button-container">
  <div class="sidebar-button-container--top-part">
    <img [src]="'assets/favicons/icon_menu_mobile_' + icon + '.svg'" />

    <div class="sidebar-button-container--top-part--badge">
      <ui-badge
        [isHidden]="indicator <= 0"
        [value]="indicator >= 100 ? '99+' : indicator"
      ></ui-badge>
    </div>
  </div>

  <div class="sidebar-button-container--name-part">
    {{ buttonName | t }}
    <img
      *ngIf="nameSideIcon"
      class="sidebar-button-container--name-part--name-side-icon"
      [src]="'assets/favicons/icon_' + nameSideIcon + '.svg'"
    />
  </div>
</div>
