<div class="top-bar-admin-panel">
  <ng-template [ngIf]="canSelectOrganization" [ngIfElse]="fallback">
    <div class="organisation-dropdown">
      <button
        title="Organization"
        class="topbar-organizations--button"
        #origin="cdkOverlayOrigin"
        cdk-overlay-origin
        (click)="toggle()"
      >
        <ng-template *ngTemplateOutlet="company"></ng-template>
      </button>
    </div>

    <ng-template
      cdk-connected-overlay
      cdkConnectedOverlayLockPosition
      [cdkConnectedOverlayOrigin]="origin"
      [cdkConnectedOverlayOpen]="(isPopoverActive$ | async)(hostname)"
      (detach)="close()"
    >
      <!-- <organization-selector
        [currentOrganization]="currentOrganization"
        [organizations]="organizations"
        [IAMorganizations]="IAMorganizations"
        [canManageOrganizations]="
          (isOrganizationsReadPermission$ | async) && currentUser?.isAdmin
        "
        [currentUser]="currentUser"
        [isAssumingOrganizationRole]="isAssumingOrganizationRole"
        (selected)="handleSelectOrg($event)"
        (goBackToMainOrganization)="handleGoBackToMainOrg()"
        (manageOrgEmitter)="handleManagingOrg()"
      ></organization-selector> -->
    </ng-template>
  </ng-template>

  <ng-template #fallback>
    <ng-container *ngTemplateOutlet="company"></ng-container>
  </ng-template>

  <ng-template #company>
    <div class="organisation-dropdown">
      <span class="topbar-organizations--text">{{
        currentOrganization?.name || ('common.unkown' | t)
      }}</span>
      <ui-icon
        *ngIf="canSelectOrganization"
        class="topbar-organizations--chevron"
        name="chevron-down"
        modifier="on-header"
      ></ui-icon>
    </div>
  </ng-template>

  <!-- <ng-container *ngIf="currentUser?.isAdminPermission">
    <ui-toggle
      class="toggle-admin"
      [toggled]="isAdminModeToggled()"
      [class.toggled]="isAdminModeToggled()"
      [labelEnabled]="'common.admin.right' | t"
      [labelDisabled]="'common.admin.right' | t"
      (onClick)="
        isAdminModeToggled() ? revokeAdminPrivileges() : getAdminPrivileges()
      "
    ></ui-toggle>
  </ng-container> -->
</div>
