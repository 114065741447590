<button
  class="edit-button-click-zone"
  [tooltip]="'common.action.edit' | t"
  (click)="handleClicked($event)"
>
  <img
    class="edit-button-icon"
    src="assets/favicons/icon_modify_disabled.svg"
    alt="Edit"
  >
</button>