import { Component, EventEmitter, HostListener, Output } from '@angular/core';

@Component({
  selector: 'ui-click-out-handler',
  template: './click-out-handler.component.html',
  styleUrls: [ './click-out-handler.component.scss' ],
})
export class ClickOutHandlerComponent {
  @Output() private clickEvent = new EventEmitter<void>();

  @HostListener('click')
  clickOut() {
    this.clickEvent.emit();
  }
}

// Listens for click events on an invisible full-page width background element.
// When clicked, it emits an event to the parent component.
// This is used to close a dropdown menu when the user clicks outside of it.
