import { Input, Component, ChangeDetectionStrategy, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'ui-radio-button-cards',
  templateUrl: './ui-radio-button-cards.component.html',
  styleUrls: [ './ui-radio-button-cards.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-radio-button-cards',
  },
})
export class UiRadioButtonCards {
  @Input('options') public options: any[] = [];

  @Input('isEditable') public isEditable: boolean;

  @Input('group') public group: UntypedFormGroup;

  @Input('controlName') public controlName: string;

  @Output('onOptionClick') onOptionClick = new EventEmitter();

  onButtonClick(optionClicked: any) {
    for (const option of this.options) {
      option.selected = false;
    }
    optionClicked.selected = true;
    this.group.controls[this.controlName].setValue(optionClicked.value);
    this.group.controls[this.controlName].updateValueAndValidity();
    this.onOptionClick.emit(optionClicked);
  }
}
