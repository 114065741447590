<div
  class="ui-title-description-container"
  [class.border]="withBorder"
>
  <img
    *ngIf="image"
    [ngStyle]="{'width': imageSize}"
    [src]="'assets/favicons/' + image"
  />
  <div>
    <span [class.title]="revokeStyle">{{ title }}</span>
    <p [class.alert-color]="textAlertColor">{{ description }}</p>
  </div>
  <img
    *ngIf="rightImage"
    class="right-img"
    [ngStyle]="{'width': rightImageSize}"
    [src]="'assets/favicons/' + rightImage"
  />
</div>
