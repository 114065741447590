<div class="drawer-detail--scrollable visible">
  <div class="drawer-detail--content" *ngIf="data.templates">
    <div class="implementation-input--content-width">
      <ui-field-group>
        <ui-select
          [options]="datasourceTypes"
          name="type"
          control="type"
          [placeholder]="'datasources.tab.creation.dropdown.implementation.placeholder' | t"
          [group]="implementation"
          (optionClick)="buildTemplate($event)"
          [label]="'datasources.tab.creation.dropdown.implementation' | t"
        >
        </ui-select>

        <ui-input-group></ui-input-group>

        <ui-select
          [options]="[]"
          name="organization"
          control="organization"
          [placeholder]="'datasources.tab.creation.dropdown.organization.placeholder' | t"
          [group]="organization"
          [label]="'datasources.tab.creation.dropdown.organization' | t"
        >
        </ui-select>
      </ui-field-group>
    </div>

    <div class="activate-input">
      <ui-checkbox
        [label]="'datasources.tab.creation.activate.checkbox' | t"
        name="activate"
        controlName="activate"
        [group]="activate"
        [checked]="false"
        [isEditable]="true"
      >
      </ui-checkbox>
    </div>

    <datasource-dynamic-form
      *ngIf="isCurrentTemplateAvailable; else noTemplate"
      [templateConfig]="this"
    >
    </datasource-dynamic-form>
  </div>
</div>

<ui-form-actions layout="centered" class="visible">
  <ng-container>
    <button [disabled]="!isFormValid" ui-button color="standard" (click)="save()">
      {{ 'common.action.save' | t }}
    </button>
    <button ui-button color="secondary" (click)="cancel()">
      {{ 'common.action.cancel' | t }}
    </button>
  </ng-container>
</ui-form-actions>

<ng-template #noTemplate>
  {{ 'datasources.tab.creation.configuration.template.placeholder' | t }}
</ng-template>
