import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-language-selector',
  templateUrl: './ui-language-selector.component.html',
  styleUrls: [ './ui-language-selector.component.scss' ],
})
export class UiLanguageSelectorComponent {
  @Input() public language: any;

  @Output() public onLanguageChange = new EventEmitter<any>();

  public languageOptions = [
    {
      language: 'fr',
      label: 'common.language.fr',
    },
    {
      language: 'en',
      label: 'common.language.en',
    },
  ];

  public setLanguage(language: any): void {
    this.language = language;
    this.onLanguageChange.emit(language);
  }
}
