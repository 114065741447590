import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Eco } from 'projects/@common/definitions/eco';
import { IamApi } from 'projects/@common/services/api/iam/iam.api.definitions';
import { StorageService } from 'projects/@common/services/storage.service';

import { JwtTokenUtil } from '../../../utils/jwt-token-util';

export class Login {
  static readonly type = '[SESSION] Login';

  constructor(public payload: IamApi.Login.response) { }
}

export class SetVarMode {
  static readonly type = '[SESSION] Var mode';

  constructor(public payload: boolean) { }
}

export class SetUserNotificationEmail {
  static readonly type = '[SESSION] Set user notification email';

  constructor(public email: string) { }
}

export class LoadSessionFromBrowser {
  static readonly type = '[SESSION] Load from browser';
}

export class InvalidateSession {
  static readonly type = '[SESSION] Invalidate session';
}

@State<Eco.ISessionState>({
  name: 'eco',
  defaults: {},
})
@Injectable()
export class EcoSessionState {
  private readonly SESSION_KEY = 'eco.iam.data';

  constructor(private storage: StorageService) { }

  @Selector()
  static user(state: Eco.ISessionState) {
    return state.user;
  }

  @Selector()
  static userName(state: Eco.ISessionState) {
    return state.user?.name;
  }

  @Selector()
  static userGuid(state: Eco.ISessionState) {
    return state.user?.oid;
  }

  @Selector()
  static authentified(state: Eco.ISessionState) {
    return !!state.accessKey;
  }

  @Selector()
  static organization(state: Eco.ISessionState) {
    return state.organization;
  }

  @Selector()
  static organizationName(state: Eco.ISessionState) {
    return state.organization?.name;
  }

  @Selector()
  static services(state: Eco.ISessionState) {
    return state.organization.services || [];
  }

  @Selector()
  static hmac(state: Eco.ISessionState) {
    return { accessKey: state.accessKey, secretKey: state.secretKey };
  }

  @Selector()
  static accessToken(state: Eco.ISessionState): string {
    return state.microsoft.accessToken;
  }

  @Selector()
  static loginMatrix(state: Eco.ISessionState): {
    [key: string]: { [key: string]: string; };
  } {
    return state.loginMatrix;
  }

  @Selector()
  static featureFlags(state: Eco.ISessionState): { [key: string]: boolean; } {
    return state.featureFlags;
  }

  @Selector()
  static varMode(state: Eco.ISessionState): boolean {
    return !!state.varMode;
  }

  @Selector()
  static notificationEmail(state: Eco.ISessionState) {
    return state.notificationEmail;
  }

  @Action(Login)
  login(ctx: StateContext<Eco.ISessionState>, action: Login) {
    ctx.patchState({
      accessKey: action.payload.accessKey,
      secretKey: action.payload.secretKey,
      expiresAt: action.payload.expiresAt,
      organization: {
        id: action.payload.organization.id,
        name: action.payload.organization.name,
        tags: action.payload.organization.tags,
        services: Object.keys(action.payload.services),
        preferredLang: action.payload.organization.preferredLang,
      },
      microsoft: {
        accessToken: action.payload.accessToken,
        idToken: action.payload.idToken,
      },
      user: JwtTokenUtil.decodeJwtToken(action.payload.idToken),
      loginMatrix: action.payload.loginMatrix,
      featureFlags: action.payload.featureFlags,
      services: action.payload.services,
    });
    this.storage.setLocal(this.SESSION_KEY, JSON.stringify(ctx.getState()));
  }

  @Action(SetVarMode)
  setVarMode(ctx: StateContext<Eco.ISessionState>, action: SetVarMode) {
    ctx.patchState({
      varMode: action.payload,
    });
  }


  @Action(LoadSessionFromBrowser)
  loadFromBrowser(ctx: StateContext<Eco.ISessionState>) {
    const sessionStateStorage = this.storage.getLocal(this.SESSION_KEY);
    if (!sessionStateStorage) {
      return;
    }

    // If session in storage is expired, dont load the state
    const sessionState: Eco.ISessionState = JSON.parse(sessionStateStorage);
    if (sessionState.expiresAt < Date.now()) {
      return;
    }

    ctx.patchState(sessionState);
  }

  @Action(InvalidateSession)
  invalidate(ctx: StateContext<Eco.ISessionState>) {
    ctx.setState({});
    this.storage.setLocal(this.SESSION_KEY, '{}');
  }

  @Action(SetUserNotificationEmail)
  setUserEmail(ctx: StateContext<Eco.ISessionState>, action: SetUserNotificationEmail) {
    ctx.patchState({
      notificationEmail: action.email,
    });
  }
}
