import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimeUtil } from '@ui-kit/services/time-util';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';

export type DaysIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type HoursIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23;

export type WeeklyTimeSchedule = { day: DaysIndex; hours: HoursIndex[]; }[];

interface IWeekday {
  index: DaysIndex, label: string
};
interface IHour {
  index: HoursIndex, label: string
};

enum SelectionAction {
  ADD = "add", REMOVE = "remove"
};

@Component({
  selector: 'weekly-time-schedule',
  templateUrl: './weekly-time-schedule.component.html',
  styleUrls: [ './weekly-time-schedule.component.scss' ],
})
export class WeeklyTimeScheduleComponent implements OnInit {
  private _weeklyTimeSchedule : WeeklyTimeSchedule;
  @Input() showAsLocalTime: boolean = false;
  @Input() public readOnly = false;
  @Output() timeChange = new EventEmitter<WeeklyTimeSchedule>();
  @Input() set weeklyTimeSchedule(value: WeeklyTimeSchedule) {
    const loading = true;
    this._weeklyTimeSchedule = this.timeConversion(value, loading);
  }

  public hours;
  public weekdays;

  private startDragCell: { day: DaysIndex, hour: HoursIndex } | null = null;
  private endDragCell: { day: DaysIndex, hour: HoursIndex } | null = null;

  constructor(private readonly i18n: I18nService) { }

  ngOnInit(): void {
    if (!this._weeklyTimeSchedule) {
      this._weeklyTimeSchedule = this.getWeekdays().map((weekday) => ({
        day: weekday.index,
        hours: [],
      }));
    }

    this.hours = this.getHours();
    this.weekdays = this.getWeekdays();
  }

  setDragStartCell(day: DaysIndex, hour: HoursIndex): void {
    this.startDragCell = { day, hour };
    this.endDragCell = null;
  }

  setDragEndCell(day: DaysIndex, hour: HoursIndex): void {
    this.endDragCell = { day, hour };
    this.handleAllSelections();
    this.startDragCell = null;
    this.endDragCell = null;
  }

  isHourSelected(day: DaysIndex, hour: HoursIndex): boolean {
    const daySchedule = this._weeklyTimeSchedule.find((item) => item.day === day);
    return daySchedule ? daySchedule.hours.includes(hour) : false;
  }

  toggleDaySelection(day: DaysIndex): void {
    const daySchedule = this._weeklyTimeSchedule.find((item) => item.day === day);
    if (daySchedule) {
      const isAllHoursSelected = daySchedule.hours.length === 24;
      const action = isAllHoursSelected ? SelectionAction.REMOVE : SelectionAction.ADD;
      this.getHours().forEach((hour) => {
        this.setSelectionState(day, hour.index, action);
      });
    }
    this.emitTimeChange();
  }

  toggleHourSelection(hour: HoursIndex): void {
    const weekdays = this.getWeekdays();
    const isAllHoursSelected = weekdays.every((weekday) => this.isHourSelected(weekday.index, hour));
    const action = isAllHoursSelected ? SelectionAction.REMOVE : SelectionAction.ADD;
    weekdays.forEach((weekday) => {
      this.setSelectionState(weekday.index, hour, action);
    });
    this.emitTimeChange();
  }

  public refresh(): void {
    this.emitTimeChange();
  }

  private setSelectionState(day: DaysIndex, hour: HoursIndex, action: SelectionAction): void {
    const daySchedule = this._weeklyTimeSchedule.find((item) => item.day === day);
    if (daySchedule) {
      const hourIndex = daySchedule.hours.indexOf(hour);
      if (hourIndex > -1 && action === SelectionAction.REMOVE) {
        daySchedule.hours.splice(hourIndex, 1); // remove selection
      } else if (action === SelectionAction.ADD && !daySchedule.hours.includes(hour)) {
        daySchedule.hours.push(hour); // add selection
      }
    }
  }

  private handleAllSelections() {
    if (this.startDragCell && this.endDragCell) {
      const action = this.isHourSelected(this.startDragCell.day, this.startDragCell.hour) ? SelectionAction.REMOVE : SelectionAction.ADD;
      const startHour = Math.min(this.startDragCell.hour, this.endDragCell.hour);
      const endHour = Math.max(this.startDragCell.hour, this.endDragCell.hour);
      for (let hour = startHour; hour <= endHour; hour++) {
        this.setSelectionState(this.startDragCell.day as DaysIndex, hour as HoursIndex, action);
      }
    }
    this.emitTimeChange();
  }

  private emitTimeChange(): void {
    this.timeChange.emit(this.timeConversion(this._weeklyTimeSchedule));
  }

  private timeConversion(schedule: WeeklyTimeSchedule, toLocalTime?: boolean) : WeeklyTimeSchedule {
    const offset = toLocalTime ? Math.floor((new Date()).getTimezoneOffset() / 60) * -1 : Math.floor((new Date()).getTimezoneOffset() / 60);
    if (offset != 0 && this.showAsLocalTime) {
      const translatedTimeSchedule : WeeklyTimeSchedule = [];
      for (const day of [ 0, 1, 2, 3, 4, 5, 6 ]) {
        translatedTimeSchedule.push({ day: day as DaysIndex, hours: [] });
      }
      for (const day in schedule) {
        for (const hour in schedule[day].hours) {
          const translatedHour = +schedule[day].hours[hour] + offset;
          if (translatedHour > 23) {
            if (+day === 6) {
              translatedTimeSchedule[0].hours.push((translatedHour - 24) as HoursIndex);
            } else {
              translatedTimeSchedule[+day + 1].hours.push((translatedHour - 24) as HoursIndex);
            }
          } else if (translatedHour < 0) {
            if (+day === 0) {
              translatedTimeSchedule[6].hours.push((translatedHour + 24) as HoursIndex);
            } else {
              translatedTimeSchedule[+day - 1].hours.push((translatedHour + 24) as HoursIndex);
            }
          } else {
            translatedTimeSchedule[+day].hours.push((translatedHour) as HoursIndex);
          }
        }
      }
      return translatedTimeSchedule;
    }
    return schedule;
  }

  private getWeekdays(): IWeekday[] {
    return Array.from(Array(7).keys()).map((index) => ({
      index: index as DaysIndex,
      label: this.i18n.translate(`common.weekdays.${index}.short`),
    }));
  }

  private getHours(): IHour[] {
    return Array.from(Array(24).keys()).map((hour) => ({
      index: hour as HoursIndex,
      label: `${TimeUtil.padZero(hour)}:00`,
    }));
  }
}
