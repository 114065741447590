import { ElementRef, HostListener, Component, EventEmitter, Output, Input, Inject } from '@angular/core';
import { DynamicQueryTimeRangeEnum } from '../widget-container/widget-container.component';
import { IMobileService } from '../../interfaces/IMobileService';
import { LanguageEnum } from '../../interfaces/ILanguage';

@Component({
  selector: 'widget-time-range',
  templateUrl: './widget-time-range.component.html',
  styleUrls: [ './widget-time-range.component.scss' ],
})
export class WidgetTimeRangeComponent {
  @Output() public selectedTimeRangeChange: EventEmitter<DynamicQueryTimeRangeEnum> = new EventEmitter();

  @Input() public timeRanges: any;

  @Input() public selectedTimeRange: any;

  @Input() public locale = LanguageEnum.FRENCH;

  public showTimeRanges = false;

  constructor(private eRef: ElementRef, @Inject("MobileService") public readonly mobileService: IMobileService) {}

  @HostListener('document:click', [ '$event' ])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showTimeRanges = false;
    }
  }

  public toggleSelection() {
    this.showTimeRanges = !this.showTimeRanges;
  }

  public onTimeRangeSelection(timeRange: DynamicQueryTimeRangeEnum) {
    this.showTimeRanges = false;
    this.selectedTimeRange = timeRange;
    this.selectedTimeRangeChange.emit(this.selectedTimeRange);
  }
}
