import { RequestDirection } from "projects/@common/definitions/consoles.enum";
import { ITranslatedField } from "../../../../definitions/ITranslatedField";
import { IIncident } from "../incidents/incidents.definitions";
import { IWhitelistRepresentation } from "../whitelists/whitelists.definitions";
import { OrganizationPublicInfo } from "../incidentTasks/incidentTasks.definitions";
import { IFinding } from "../finding/finding.definition";

export enum ListAlertsOrderBy {
  name = 'name',
  priority = 'priority',
  createdAt = 'createdAt',
  status = 'status',
  organization = 'organization'
}

export enum ListAlertsDirection {
  asc = "ASC",
  desc = "DESC"
}

export enum AlertActionsEnum {
  ASSOCIATE = "ASSOCIATE",
  CREATE = "CREATE",
  IGNORE = "IGNORE"
}

export interface IAlert {
  id: string;
  createdAt: number;
  updatedAt: number;
  organizationId: string;
  organizationEcoId: string;
  organizationName: string;
  usecaseId: string;
  status: AlertStatusEnum;
  priority: AlertPriorityEnum;
  count: number;
  name: ITranslatedField;
  description: ITranslatedField;
  shortId: string;
  templateId?: string;
  connector?: string;
  assetCount?: number;
  whitelisted?: boolean;
  whitelist?: IWhitelistRepresentation;
  playbookId?: string;
  indexedAt: number;
  isLate: boolean;
}

export interface IAlertStats {
  new: number;
  activeAssigned: number;
}

export interface ITableAlert extends IAlert {
  __Selected?: boolean;
  __highlighted?: boolean;
  isIconShowing?: boolean;
  priorityTranslated?: string;
  statusTranslated?: string;
}

export enum AlertStatusEnum {
  NEW = 'NEW',
  ASSIGNED = 'ASSIGNED',
  IGNORED = 'IGNORED',
  SILENCED = 'SILENCED'
}

export enum AlertStatusFilterEnum {
  NEW = 'NEW',
  ASSIGNED = 'ASSIGNED',
  IGNORED = 'IGNORED'
}

export enum AlertIndicatorsEnum {
  CRITICAL_ASSET = 'CRITICAL_ASSET',
  SILENCED = 'SILENCED',
  WHITELIST = 'WHITELIST',
  LATE = "LATE"
}

export enum AlertPriorityEnum {
  P1 = 1,
  P2 = 2,
  P3 = 3,
  P4 = 4
}

export enum AlertPriorityEnumForDisplay {
  P1 = '1',
  P2 = '2',
  P3 = '3',
  P4 = '4'
}

export interface IAlertFilters {
  language?: string;
  shortId?: string;
  organizationIds?: Array<string>;
  statuses?: Array<AlertStatusEnum>;
  indicators?: Array<AlertIndicatorsEnum>;
  priorities?: Array<AlertPriorityEnum>;
  dateFrom?: number;
  dateTo?: number;
  useUpdatedAtDate?: boolean;
  alertName?: string;
  from?: number;
  size?: number;
  orderBy?: ListAlertsOrderBy;
  direction?: ListAlertsDirection;
  templateId?: string;
  includeSilenced?: boolean;
  connectors?: string[];
  observable?: string;
  exclusionFilters?: string[];
}

export interface IAlertIgnoreRequest {
  alertIds: string[];
  status: AlertStatusEnum;
  comment: string;
}

export interface IGetIncidentAlertsRequest {
  incidentId?: string;
  organizationId?: string;
  from?: number;
  size?: number;
  orderBy?: ListAlertsOrderBy;
  direction?: RequestDirection;
}

export type IAlertDetail = IAlert & { organizationId: string; organizationName: string; };

export interface IAlertModalData {
  alerts: IAlert[];
  status?: AlertStatusEnum;
  findings?: IFinding[];
};

export interface IAlertUpdatedEvent {
  alerts: IAlert[],
  incident?: IIncident
}

export type ListAlertSimilarItemsRequest = {
  alertId: string;
  sameOrganization?: boolean;
  type?: SimilarityItemTypeEnum;
  from?: number;
  size?: number;
};

enum SimilarityItemTypeEnum {
  ALERTS = 'alerts',
  INCIDENTS = 'incidents'
};

export type AlertSimilarityItem = {
  type: SimilarityItemTypeEnum;
  name: string;
  createdAt: number;
  status: string;
  shortId: string;
  id: string;
  score: number;
  canAssociate: boolean;
  sameOrganization: boolean;
  samePlaybook: boolean;
  sameUsecase: boolean;
} & OrganizationPublicInfo;

export type ISplitAlertRequest = {
  findings: ISplitAlertRequestAction[];
  observables: ISplitAlertRequestAction[];
};

export type ISplitAlertRequestAction = {
  id: string;
  action: SplitAlertAction;
};

export enum SplitAlertAction {
  IGNORE = 'IGNORE',
  COPY = 'COPY'
  // MOVE = 'MOVE'
}

export type ISplitAlertResponse = {
  completed: boolean;
  newAlertId?: string;
  newAlertShortId?: string;
  error?: SplitAlertErrorEnum;
};

export enum SplitAlertErrorEnum {
  ALERT_IGNORED = 'ALERT_IGNORED',
  ALERT_NOT_FOUND = 'ALERT_NOT_FOUND',
  FINDINGS_MISSING = 'FINDINGS_MISSING',
  NO_FINDINGS = 'NO_FINDINGS_LEFT',
  OBSERVABLES_MISSING = 'OBSERVABLES_MISSING',
  CANNOT_EXPLICITLY_COPY_ASSET_OBSERVABLES = 'CANNOT_EXPLICITLY_COPY_ASSET_OBSERVABLES'
}

export interface IAlertSplittedEvent {
  alertId: string;
  alertShortId: string;
}
