import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { I18nModule } from '../i18n/i18n.module';
import { AuthnRedirectComponent } from './components/redirect/authn-redirect.component';
import { AuthnSignInComponent } from './components/signin/sign-in.component';
import { AdminConsentRedirectComponent } from './components/signup/adminconsent-redirect.component';
import { SignupRedirectComponent } from './components/signup/signup-redirect.component';
import { SignInMicrosoftComponent } from './components/sign-in-microsoft/sign-in-microsoft.component';
import { SignupComponent } from './components/signup/sign-up.component';
import { AuthnRedirectTeamsComponent } from './components/redirect/authn-redirect-teams.component';
import { UiKitModule } from '@ui-kit/uikit.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    I18nModule,
    UiKitModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
  ],
  declarations: [
    AuthnRedirectComponent,
    AuthnRedirectTeamsComponent,
    AuthnSignInComponent,
    SignupComponent,
    SignupRedirectComponent,
    AdminConsentRedirectComponent,
    SignInMicrosoftComponent,
  ],
  providers: [],

})
export class AuthModule {
  static forRoot(config: {
    redirectAfterLogin: string;
    assertOrganizationTag?: string
  }): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: 'REDIRECT_AFTER_LOGIN', useValue: config.redirectAfterLogin },
        { provide: 'ASSERT_ORGANIZATION_TAG', useValue: config.assertOrganizationTag },
      ],
    };
  }
}

