import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRespondResponse } from 'projects/@common/definitions/IRespondResponse';
import { EcoUrlService } from 'projects/@common/services/api/core/url.service';
import { DatasourcesApiService } from 'projects/@common/services/api/respond/datasources/datasources.api';
import { DatasourceUiRepresentation, IDatasourceFamily } from 'projects/@common/services/api/respond/datasources/datasources.definitions';

@Injectable({
  providedIn: 'root',
})
export class AdminDatasourcesApiService extends DatasourcesApiService {
  constructor(http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public getDatasourcesCatalog(): Promise<IRespondResponse<any>> {
    return this.http.get<IRespondResponse<any>>(`${this.host}/datasources/templates`).toPromise();
  }

  public getDatasources(organizationId: string): Promise<IRespondResponse<any>> {
    return this.http.get<IRespondResponse<any>>(`${this.host}/organizations/${organizationId}/datasources`).toPromise();
  }

  public describeDatasource(organizationId: string, datasourceId: string): Promise<DatasourceUiRepresentation> {
    return this.http.get<DatasourceUiRepresentation>(`${this.host}/organizations/${organizationId}/datasources/${datasourceId}`).toPromise();
  }

  public postDatasource(datasource: any, organizationId: string): Promise<IRespondResponse<any>> {
    return this.http.post<IRespondResponse<any>>(`${this.host}/organizations/${organizationId}/datasources`, datasource).toPromise();
  }

  public deleteDatasource(organizationId: string, datasourceId: string): Promise<IRespondResponse<any>> {
    return this.http.delete<IRespondResponse<any>>(`${this.host}/organizations/${organizationId}/datasources/${datasourceId}`).toPromise();
  }

  public activateDatasource(organizationId: string, datasourceId: string): Promise<IRespondResponse<any>> {
    return this.http.put<IRespondResponse<any>>(`${this.host}/organizations/${organizationId}/datasources/${datasourceId}/activate`, null).toPromise();
  }

  public getDatasourcesFamily(organizationId: string): Promise<IDatasourceFamily[]> {
    return this.http.get<IDatasourceFamily[]>(`${this.host}/organizations/${organizationId}/datasourceFamilies`).toPromise();
  }
}
