import { Directive } from '@angular/core';

@Directive()
export class ExpendableTableHover {
  public static readonly IS_HOVERED = 'isHovered';

  public onHoverEnter(item: any) {
    item[ExpendableTableHover.IS_HOVERED] = true;
  }

  public onHoverLeave(item: any) {
    item[ExpendableTableHover.IS_HOVERED] = false;
  }
}
