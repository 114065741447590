import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";

import { normalizeString } from "../../../services/utils";
import { AbstractAutocomplete } from "../AbstractAutocomplete";

export interface AutocompleteCustomValue {
  value: any;
  displayValue: string;
  image?: string;
  description?: string;
}

@Component({
  selector: 'custom-autocomplete',
  templateUrl: './custom-autocomplete.component.html',
  styleUrls: [ './custom-autocomplete.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class CustomAutocompleteComponent extends AbstractAutocomplete {
  @Input() public selectedValues: AutocompleteCustomValue[];

  @Input() public customValues: AutocompleteCustomValue[];

  @Input() public itemsToIgnore: AutocompleteCustomValue[];

  @Input() public oneItemAtATime: boolean;

  @Input() public canMultiSelect: boolean;

  @Input() public imageWithBorder: boolean;

  @Input() public maxHeight: boolean = false;

  @Input() public searchLimit: number = 50;

  @Input() public expandHeight = '20rem';

  @Input() public customNotFound: string;

  @Input() public locale: string;

  @Output() public elementClicked: EventEmitter<any> = new EventEmitter();

  @Output() public isSearching: EventEmitter<boolean> = new EventEmitter();

  public isSearchingValues = false;

  public customValuesToDisplay: AutocompleteCustomValue[] = [];

  constructor() {
    super();
  }

  public searchItems(query: string, searchID: string) {
    this.setItemsToIgnore();
    this.setIsSearching(true);
    const normalizedQuery = normalizeString(query);
    const exclusions = this.selectedValues.map((selectedValue) => selectedValue.value);
    this.customValuesToDisplay = this.customValues.filter((customValue) => !exclusions.includes(customValue.value) && normalizeString(customValue.displayValue).includes(normalizedQuery));
    this.setIsSearching(false);
  }

  public onValueSelected(customValue: AutocompleteCustomValue, event?: Event): void {
    if (!this.selectedValues.includes(customValue)) {
      this.elementClicked.emit({
        item: customValue,
        itemName: customValue.displayValue,
      });
      if (!this.oneItemAtATime) {
        this.selectedValues.push(customValue);
        const index = this.customValuesToDisplay.findIndex((item) => item.value === customValue.value);
        if (index !== -1) {
          // remove the selected value
          this.customValuesToDisplay.splice(index, 1);
        }
      }
    }
    // prevent closing the dropdown if its a click event

    if (this.oneItemAtATime) {
      event?.stopPropagation();
      event?.preventDefault();
    }
  }

  public onValueClick(clickedValue: AutocompleteCustomValue, elementIndex: number, mouseEvent: MouseEvent) {
    this.addRippleEffect(elementIndex, mouseEvent);
    this.onValueSelected(clickedValue, mouseEvent);
  }

  private setItemsToIgnore(): void {
    this.customValues = this.customValues.filter((item) => !this.itemsToIgnore.map((e) => e.value).includes(item.value));
  }

  private setIsSearching(isSearchingValues: boolean) {
    this.isSearchingValues = isSearchingValues;
    this.isSearching.emit(this.isSearchingValues);
  }
}
