<div class="ui-table-filter-tool ui-table-filter-tool--trigger" (click)="toggle()">
  <button
    ui-button
    class="ui-table-filter-tool--button filter-button"
    color="linkBordedRegular"
    modifier="gray"
    custom-icon="true"
    isSvg="true"
    icon="icon_filter"
  >
    {{(titlesDifferentThanOptions.includes(option?.value)
    ? option?.translatedWithTitleSuffix
    : option?.translated)
    }}
  </button>

  <ui-table-filter-tooltip
    class="ui-table-filter-tool--options"
    *ngIf="showOptions"
    [option]="option"
    [tableFilterData]="tableFilterData"
    [tooltipsList]="tooltipsList"
    [separatorsList]="separatorsList"
    (option)="handleOption($event)"
  ></ui-table-filter-tooltip>
</div>
