import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { Observable } from 'rxjs';
import { User } from 'projects/@common/definitions/user.definition';
import { MobileService } from 'projects/@common/services/mobile.service';
import { ExpendableTableHover } from './expendable-table-hover';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';

@Component({
  selector: 'users-table',
  templateUrl: './users-table.component.html',
  styleUrls: [ './tables.component.scss', './users-table.component.scss' ],
})
export class UsersTableComponent extends ExpendableTableHover implements OnInit {
  @Select(EcoSessionState.userGuid) public userGuid$: Observable<string>;

  @Input() public users: User[];

  @Input() private prevUsers: User[] = [];

  @Input() public owners: any[];

  @Input() public channels: any[];

  @Input() public isEditing = false;

  @Input() public isLoading = false;

  @Input() public showLinks = false;

  @Input() public showType = false;

  @Input() public showBadges = false;

  @Input() public showExpirationDate = false;

  @Input() public canDeleteOwner = false;

  @Input() public showRowSeparator = false;

  @Input() public canExpend = false;

  @Input() public isLoadingChannels = false;

  @Output() public usersChange = new EventEmitter();

  @Output() public onUserDelete = new EventEmitter();

  @Output() public onUserChannelUpdate = new EventEmitter();

  @Output() public onGuestUserExpirationDateUpdate = new EventEmitter();

  public direction: UiTableDirection = UiTableDirection.Asc;

  public directions = UiTableDirection;

  public isHovered = ExpendableTableHover.IS_HOVERED;

  public showRemoveSelfWarningPopup: boolean = false;

  private authUserGuid: string;

  constructor(public mobileService: MobileService) {
    super();
  }

  ngOnInit() {
    this.userGuid$.subscribe((value) => this.authUserGuid = value);
  }

  public isSelf(user: User): boolean {
    return this.isSameUser(user, this.authUserGuid);
  }

  public isPlatformUser(user: any): boolean {
    if ('isPlatformUser' in user) {
      return user.isPlatformUser;
    }
    if ('platformUser' in user) {
      return user.platformUser;
    }
    if ('isO365' in user) {
      return !user.isO365;
    }
  }

  public isGuestUser(user: any) {
    return user.isGuest || user.guestUser || user.isGuestUser;
  }

  public isPending(user: any) {
    return !!(this.isGuestUser(user) && !user?.isApproved);
  }

  public isApproved(user: any) {
    return !(user.isApproved !== undefined && !user.isApproved);
  }

  public isDisabled(user: any): boolean {
    return !!user['disabledAt'] || user['disabled'];
  }

  public isNotOnboarded(user: any): boolean {
    return (!!user['disabledAt'] || user['disabled']) && user.effectiveDate && new Date().getTime() < user.effectiveDate;
  }

  public isOwner(user: any) {
    return !!this.owners?.some((owner) => this.isSameUser(owner, user));
  }

  public toggleUser(user: any) {
    if (this.canExpend) {
      user.expended = !user.expended;
    }
  }

  public cancelRemoveSelf() {
    this.showRemoveSelfWarningPopup = false;
  }

  public confirmRemoveSelf() {
    this.showRemoveSelfWarningPopup = false;
    const userToDelete = this.users.find((user) => this.isSameUser(user, this.authUserGuid));
    if (typeof userToDelete === "object") {
      this.removeUser(userToDelete);
    }
  }

  public handleRemoveUserClicked(userToDelete: User) {
    const shouldWarnUser = this.isSelf(userToDelete) && this.prevUsers.find((user) => this.isSameUser(user, userToDelete));
    if (shouldWarnUser) {
      this.showRemoveSelfWarningPopup = true;
      return;
    }

    this.removeUser(userToDelete);
  }

  public removeUser(userToDelete: any) {
    this.users = this.users.filter((user) => !this.isSameUser(userToDelete, user));
    this.usersChange.emit(this.users);
    this.onUserDelete.emit(userToDelete);
  }

  public isChannelFromProfile(user: any, channel: any): boolean {
    return !!user?.profileChannels?.includes(channel.o365ChannelId);
  }

  public isChannelGuest(user: any, channel: any): boolean {
    return !!channel.guestUsers?.some((guest) => this.isSameUser(guest, user));
  }

  public isChannelMember(user: any, channel: any): boolean {
    return !!channel.members?.some((member) => this.isSameUser(member, user));
  }

  public isChannelOwner(user: any, channel: any): boolean {
    return !!channel.owners?.some((owner) => this.isSameUser(owner, user));
  }

  public isChannelAssigned(user: any, channel: any): boolean {
    return (
      this.isChannelGuest(user, channel) || this.isChannelMember(user, channel) || this.isChannelOwner(user, channel)
    );
  }

  public getAssignedChannels(user: any) {
    return this.channels?.filter((channel) => channel.isPrivate && this.isChannelAssigned(user, channel));
  }

  public getAssignableChannels(user: any) {
    const userAssignableChannels = [];

    if (this.channels) {
      for (const channel of this.channels) {
        if (channel.isPrivate) {
          if (this.isChannelFromProfile(user, channel) || this.isChannelOwner(user, channel)) {
            channel['CHANNEL_IS_NOT_ASSIGNABLE'] = true;
          }
          userAssignableChannels.push(channel);
        }
      }
    }
    return userAssignableChannels;
  }

  public sort() {
    if (this.direction === UiTableDirection.Asc) {
      this.direction = UiTableDirection.Desc;
    } else {
      this.direction = UiTableDirection.Asc;
    }

    this.users.sort((user1, user2) => {
      if (user1.firstName.toLowerCase() > user2.firstName.toLowerCase()) {
        return this.direction === UiTableDirection.Asc ? 1 : -1;
      }
      if (user1.firstName.toLowerCase() < user2.firstName.toLowerCase()) {
        return this.direction === UiTableDirection.Asc ? -1 : 1;
      }
      return 0;
    });
  }

  public updateUserChannel(isChannelAdded: boolean, channel: any, user: any) {
    this.onUserChannelUpdate.emit({
      isChannelAdded,
      channel,
      userGuid: user.guid,
      isGuestUser: user.isGuestUser,
    });
  }

  public updateGuestUserExpirationDate(expirationDate: number, user: any) {
    this.onGuestUserExpirationDateUpdate.emit({
      expirationDate,
      guid: user.guid,
    });
  }

  public isSameUser(user1: string | User, user2: string | User): boolean {
    // can receive a user.guid string, or the user object itself, as params.
    let user1Guid = user1;
    let user2Guid = user2;
    if (typeof user1 === "object") {
      user1Guid = user1.guid;
    }
    if (typeof user2 === "object") {
      user2Guid = user2.guid;
    }
    return user1Guid === user2Guid;
  }

  public canRemoveUser(user: any): boolean {
    return this.isApproved(user) &&
      !user['profiles']?.length &&
      !user['linkedGroups']?.length &&
      (this.canDeleteOwner || !this.isOwner(user));
  }
}
