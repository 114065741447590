import { Directive } from '@angular/core';

@Directive()
export class AbstractAutocomplete {
  protected _currentSearchID: string;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public searchItems(query: string, searchID: string, useIAMBackend = false) {}

  public addRippleEffect(elementIndex: number, mouseEvent: MouseEvent): void {
    const button = document.getElementById(`rowElement_${elementIndex}`) as HTMLDivElement;
    const buttonPosition = button.getBoundingClientRect();

    const circle = document.createElement('span');
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.classList.add('ripple');
    // eslint-disable-next-line no-multi-assign
    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${mouseEvent.clientX - (buttonPosition.x + radius)}px`;
    circle.style.top = `${mouseEvent.clientY - (buttonPosition.y + radius)}px`;

    const ripple = button.getElementsByClassName('ripple')[0];

    if (ripple) {
      ripple.remove();
    }

    button.appendChild(circle);
  }

  public hoverElement(element: any, isHovered: boolean) {
    element['isHovered'] = isHovered;
  }

  public set currentSearchID(searchID: string) {
    this._currentSearchID = searchID;
  }
}
