<ng-container [ngSwitch]="formConfig.typeOfForm">
  <ng-container *ngSwitchCase="uiTypeOfFormEnum.TEXT_INPUT">
    <ng-container *ngTemplateOutlet="input; context: { config: formConfig }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="uiTypeOfFormEnum.NUMBER_INPUT">
    <ng-container *ngTemplateOutlet="input; context: { config: formConfig }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="uiTypeOfFormEnum.OBJECT_TEXTAREA">
    <ng-container *ngTemplateOutlet="textarea; context: { config: formConfig }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="uiTypeOfFormEnum.ARRAY_TEXTAREA">
    <ng-container *ngTemplateOutlet="textarea; context: { config: formConfig }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="uiTypeOfFormEnum.CHECKBOX">
    <ng-container *ngTemplateOutlet="checkbox; context: { config: formConfig }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="uiTypeOfFormEnum.DROPDOWN">
    <ng-container *ngTemplateOutlet="dropdown; context: { config: formConfig }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="uiTypeOfFormEnum.MULTI_SELECT">
    <ng-container
      *ngTemplateOutlet="multiSelect; context: { config: formConfig }"
    >
    </ng-container>
  </ng-container>

  <ng-template #input let-config="config">
    <ui-field-group layout="vertical">
      <ui-field
        [required]="config.required"
        [label]="config.label | translatedObject"
        [maxlength]="
          config.typeOfForm === uiTypeOfFormEnum.NUMBER_INPUT ? 6 : null
        "
        [type]="
          config.typeOfForm === uiTypeOfFormEnum.NUMBER_INPUT
            ? 'number'
            : 'text'
        "
        [name]="config.controlName"
        [readonly]="readonly"
        [control]="config.controlName"
        [group]="config.group"
      >
      </ui-field>
      <label ui-label *ngIf="config.description">{{
        config.description | translatedObject
        }}</label>
    </ui-field-group>
  </ng-template>

  <ng-template #textarea let-config="config">
    <ui-field-group layout="vertical">
      <json-textarea
        [type]="
          config.typeOfForm === uiTypeOfFormEnum.OBJECT_TEXTAREA
            ? textareaTypeEnum.JSON
            : textareaTypeEnum.ARRAY
        "
        [controlName]="config.controlName"
        [formGroup]="config.group"
        [readonly]="readonly"
        [label]="config.label | translatedObject"
        [description]="config.description | translatedObject"
        [required]="config.required"
        [prettifyButton]="!readonly"
      >
      </json-textarea>
    </ui-field-group>
  </ng-template>

  <ng-template #checkbox let-config="config">
    <ui-field-group layout="vertical">
      <ui-checkbox
        [label]="config.label | translatedObject"
        [isEditable]="true"
        [controlName]="config.controlName"
        [group]="config.group"
      >
      </ui-checkbox>
      <label ui-label>{{ config.description | translatedObject }}</label>
    </ui-field-group>
  </ng-template>

  <ng-template #dropdown let-config="config">
    <ui-field-group layout="vertical">
      <select-with-label-value
        [label]="config.label | translatedObject"
        [description]="config.description"
        [name]="config.controlName"
        [required]="config.required"
        [controlName]="config.controlName"
        [group]="config.group"
        [values]="config.values"
        (onSelect)="onValueSelection($event)"
      >
      </select-with-label-value>
    </ui-field-group>
  </ng-template>

  <ng-template #multiSelect let-config="config">
    <ui-field-group layout="vertical">
      <dynamic-multi-simple-select
        [selectableValues]="config.values"
        [title]="config.label"
        [formGroup]="config.group"
        [controlName]="config.controlName"
      ></dynamic-multi-simple-select>
    </ui-field-group>
  </ng-template>
</ng-container>
