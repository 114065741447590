import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { UiTableDirection } from '../../ui-table/ui-table.component';
import { AbstractAutocomplete } from '../AbstractAutocomplete';
import { AutocompleteCustomValue } from '../custom-autocomplete/custom-autocomplete.component';

export interface AllSelectorCustomValue {
  value: string;
  displayValue: string
}

export interface OrganizationAutocompleteSearchResult {
  total: number;
  nextItem?: number;
  items: {
    id: string;
    name: string;
  }[];
}

@Component({
  selector: 'organization-autocomplete',
  templateUrl: './organization-autocomplete.component.html',
  styleUrls: [ './organization-autocomplete.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationAutocompleteComponent extends AbstractAutocomplete implements OnInit {
  @Input() public searchFunction: (args: any) => Promise<OrganizationAutocompleteSearchResult>;

  @Input() public selectedValues: AutocompleteCustomValue[];

  @Input() public customValues: AutocompleteCustomValue[];

  @Input() public oneItemAtATime: boolean;


  @Input() public allSelectorCustomValue?: AllSelectorCustomValue | null = null;

  @Output() public elementClicked: EventEmitter<any> = new EventEmitter();

  @Output() public isSearching: EventEmitter<boolean> = new EventEmitter();

  public isSearchingValues = false;

  public loading = false;

  public query = '';
  public from = 0;
  public size = 10;
  public total = 0;

  public customValuesToDisplay: AutocompleteCustomValue[] = [];

  private lastScrollTop = 0;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }
  ngOnInit(): void {
    this.applyAllSelector();
  }

  public loadMore() {
    if (!this.loading && this.customValuesToDisplay.length < this.total) {
      const exceptions = this.selectedValues.map((selectedValue) => selectedValue.displayValue?.toLowerCase());
      this.loading = true;

      this.searchFunction({
        order: UiTableDirection.Asc,
        searchName: this.query,
        size: this.size,
        from: this.from,
      })
        .then((organizations) => {
          this.customValuesToDisplay = this.customValuesToDisplay.concat(organizations.items
            .filter((item) => !exceptions.includes(item.id))
            .map((item) => ({
              value: item.id,
              displayValue: item.name,
            })));
          this.from = organizations?.nextItem || 0;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          this.cdr.detectChanges();
        });
    }
  }

  @HostListener('scroll', [ '$event.target' ])
  public onScroll(event: any) {
    const tableViewHeight = event.target.offsetHeight;
    const tableScrollHeight = event.target.scrollHeight;
    const scrollLocation = event.target.scrollTop;

    const limit = tableScrollHeight - tableViewHeight - 1;

    if (scrollLocation > limit && scrollLocation > this.lastScrollTop) {
      this.loadMore();
    }
    this.lastScrollTop = scrollLocation;
  }

  public searchItems(query: string, searchID: string) {
    this.query = query;
    this.from = 0;
    this.total = 0;

    this.setIsSearching(true);

    const exceptions = this.selectedValues.map((selectedValue) => selectedValue.displayValue?.toLowerCase());

    this.searchFunction({
      order: UiTableDirection.Asc,
      searchName: query,
      size: this.size,
      from: this.from,
    })
      .then((organizations) => {
        if (this._currentSearchID === searchID) {
          this.total = organizations.total;
          this.from = organizations?.nextItem || organizations.total;
          this.customValuesToDisplay = organizations.items
            .filter((item) => !exceptions.includes(item.id))
            .map((item) => ({
              value: item.id,
              displayValue: item.name,
            }));
          this.applyAllSelector();
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (this._currentSearchID === searchID) {
          this.setIsSearching(false);
        }
      });
  }

  private setIsSearching(isSearchingValues: boolean) {
    this.isSearchingValues = isSearchingValues;
    this.isSearching.emit(this.isSearchingValues);
  }

  public onValueClick(
    clickedValue: AutocompleteCustomValue,
    elementIndex: number,
    mouseEvent: MouseEvent
  ) {
    this.addRippleEffect(elementIndex, mouseEvent);
    this.onValueSelected(clickedValue, mouseEvent);
  }

  public onValueSelected(customValue: AutocompleteCustomValue, event?: Event): void {
    if (!customValue["isAlreadyClicked"]) {
      if (!this.oneItemAtATime) {
        this.selectedValues.push(customValue);
      }

      this.elementClicked.emit({
        item: customValue,
        itemName: customValue.displayValue,
      });
    }
    // prevent closing the dropdown if its a click event
    event?.stopPropagation();
    event?.preventDefault();
  }

  private applyAllSelector(): void {
    if (this.allSelectorCustomValue) {
      const allSelector: AutocompleteCustomValue = {
        value: this.allSelectorCustomValue.value,
        displayValue: this.allSelectorCustomValue.displayValue,
      };
      this.customValuesToDisplay = [ allSelector, ...this.customValuesToDisplay ];
    }
  }
}
