<ui-collapsable-card
  [cardTitle]="tableTitle"
  [headerIcon]="headerIcon"
  [avatarOfficeId]="avatarOfficeId"
  [isAvatar]="isAvatar"
  [dynamicTitle]="dynamicTitle"
  [spaceBetweenDynamicTitle]="spaceBetweenDynamicTitle"
  [numberItems]="numberItems"
  [isHeaderIconPng]="isHeaderIconPng"
  [isBigHeaderImage]="isBigHeaderImage"
  [isCollapsed]="isCollapsed"
  [isCollapsable]="isCollapsable"
  (onToggleCollapse)="onToggleCollapse.emit($event)"
>
  <ui-static-table
    [loading]="isLoading || !dataSource"
    [locale]="locale"
    [dataSource]="dataSource"
    [columnsDef]="columnsDef"
    [canSelectItems]="canSelectItems"
    [addPaddingOnFirst]="addPaddingOnFirst"
    [sortHeader]="sortHeader"
    [sort]="sort"
    [direction]="direction"
    [isCustomSorting]="isCustomSorting"
    (handleCustomSort)="onCustomSortEmit($event)"
    (on-row-click)="handleRowClick($event)"
    (checkbox-event)="handleCheckboxEvent($event.event, $event.item)"
    [expendedTemplate]="expendedTemplate"
  >
  </ui-static-table>

  <div class="table-special-widgets" *ngIf="canLoadMore">
    <ui-spinner *ngIf="isLoading"></ui-spinner>

    <button
      *ngIf="canLoadMore"
      ui-button
      color="linkBorded"
      [loading]="isLoadingMore"
      (click)="loadMore()"
    >
      {{ "common.showMore" | t : { locale: locale } }}
    </button>
  </div>
</ui-collapsable-card>
