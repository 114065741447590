import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableFilterData } from '../ui-table-filter-data';

@Component({
  selector: 'ui-table-filter-tooltip',
  templateUrl: './ui-table-filter-tooltip.component.html',
  styleUrls: [ './ui-table-filter-tooltip.component.scss' ],
})
export class UiTableFilterTooltip implements OnInit {
  @Input() public tooltipsList = [];
  @Input() public separatorsList = [];
  @Input() public optionIcons = {};
  @Input() public tableFilterData: TableFilterData;
  @Input('option') public selected: any;
  @Output('option') public optioned: EventEmitter<any> = new EventEmitter<any>();

  public hoveredOption = null;

  public options: string[];

  public ngOnInit() {
  }

  public selectOption(option) {
    this.optioned.emit(option);
  }

  public isSelectedOption(name: string): boolean {
    return this.selected?.value === name;
  }
}
