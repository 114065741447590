import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-toggle',
  templateUrl: './ui-toggle.component.html',
  styleUrls: [ './ui-toggle.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-toggle',
  },
})
export class UiToggle {
  @Input('toggled') public toggled: boolean;

  @Input('labelEnabled') public labelEnabled = '';

  @Input('labelDisabled') public labelDisabled = '';

  @Input('disabled') public disabled = false;

  @Input('changeColorWhenDisabled') public changeColorWhenDisabled = true;

  @Input('disabledWhenUntoggled') public disabledWhenUntoggled = true;

  @Input('switchRightSide') public switchRightSide = false;

  @Input('boldLabel') public boldLabel = true;

  @Input('ignoreLabelClicks') public ignoreLabelClicks = false;

  @Input() manualToggle = false;

  @Output('onClick') public onClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isMidAnimation = false;

  public isClassSet = false;

  public toggle(event: MouseEvent) {
    if (this.ignoreLabelClicks && event.target instanceof HTMLElement && event.target.classList.contains('label')) {
      return;
    }

    this.isMidAnimation = true;

    if (!this.disabled) {
      if (!this.manualToggle) {
        this.toggled = !this.toggled;
      }
      this.onClick.emit(this.toggled);
    }

    setTimeout(() => {
      this.isMidAnimation = false;
    }, 300);
  }

  public toggleState(toggled: boolean): void {
    this.toggled = toggled;
  }
}
