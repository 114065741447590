import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FontSizeTypeEnum } from './interface/font-size-type.enum';

@Component({
  selector: 'ui-checkbox',
  templateUrl: './ui-checkbox.component.html',
  styleUrls: [ './ui-checkbox.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-checkbox',
  },
})
export class UiCheckbox implements OnInit {
  @Input() public checked: boolean;

  @Input() public isEditable: boolean;

  @Input() public useFormValueForDisplay: boolean;

  @Input() public controlName: string;

  @Input() public group: UntypedFormGroup;

  @Input() public arrayName: string;

  @Input() public groupName: string;

  @Input() public label = '';

  @Input() public fontSizeType = FontSizeTypeEnum.NORMAL;

  @Output() public onCheck: EventEmitter<any> = new EventEmitter<any>();

  onCheckClick(isCheck: any) {
    if (this.onCheck) {
      this.onCheck.emit(isCheck.checked);
    }
  }

  ngOnInit(): void {
    // When not editable and bind to a form control, the checked attribute was not in sync with the value of the form control.
    if (this.group) {
      this.checked = this.group.get(this.controlName).value as boolean;
    }
  }
}
