import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { LanguageEnum } from "../../../interfaces/ILanguage";
import { DatePipeParams } from "../../../pipes/dt.pipe";

export enum UiStaticTableRowType {
  TEXT = 'text', // Show simple value directly from the object
  TRANSLATED_TEXT = 'translatedText', // Show simple value directly from the object
  TOOLTIP = 'tooltip', // Show the ui tooltip
  TEXT_WITH_TOOLTIP = 'textWithTooltip', // Show simple value directly from the object with a tooltip next to it
  DEEP_1_WITH_TOOLTIP = 'deep1WithTooltip', // Show simple value directly from the nested object with a tooltip next to it
  CONCATENATED_VALUES = 'concatenatedValues', // Show 2 simple2 value2 from the object separated by a space
  DEEP_1 = 'deep1', // Show a value from a nested object of the row object
  ENUM = 'enum', // Show a translated enum value by the use of a prefix
  FUNCTION = 'function', // Execute a function on the object value
  PIPED_ROW = 'pipedRow', // Execute a Pipe on the whole row (Pipes that need object as param)
  PIPED_FIELD = 'pipedField', // Execute a Pipe on the object value (Pipes that need a string or number as param)
  EXPENDABLE_ICON = 'expendableIcon', // Icon that make the row expend and show more data
  // PU SUPPORTÉ À CAUSE DE L'IMPORT DE F2S-INFRA
  // GROUP_TYPE = 'groupType', // Show the resource badge
  RESOURCE_AVATAR = 'resourceAvatar', // Show an avatar for a resource
  AVATAR = 'avatar', // Show an avatar
  AVATARS = 'avatars', // Show a list of avatars
  ENUM_WITH_ICON = 'enumWithIcon', // Use ui-static component to display eenum and icon
  ASYNC_WEBSITE_STATE = 'asyncwebsitestate', // ui-asyncwebsitestate component
  DYNAMIC_COMPONENT_HOST = 'dynamicComponentHost', // Pass any component that receives the whole row object as param
  DYNAMIC_COMPONENT_DEEP_1 = 'dynamicComponentDeep1', // Pass any component that receives a nested object of the row object as param
  SUB_HEADER = 'subHeader', // Header that encapsulate other headers
  CHECKBOX = 'checkbox', // Shows a checkbox
  ICON_WITH_LINK = 'iconWithLink', // Shows an icon and a link towards a webUrl
  INDICATOR_ARROW_WITH_TEXT = 'indicatorArrowWithText', // display a text or value followed by an indicator arrow
  ICON = 'icon', // simple icon with height of 24px
  TEXT_WITH_LINK = 'textWithLink', // Text with a link towards a webUrl (if a webUrl is specified)
  DATE = 'date', // Date with dt pipe
  DATE_AND_HOUR = 'dateAndHour', // Date with dt pipe
  DATE_AND_HOUR_AND_AGO = 'dateAndHourAndAgo', // same as DATE_AND_HOUR + dateAgo pipe (ex: 22 décembre 2022, 9:55 Il y a 5 minutes)
  DATE_AND_HOUR_AND_UNTIL = 'dateAndHourAndUntil',
  BOOLEAN = 'boolean', // Boolean to alternate between two values
  LIST = 'list', // Display an array of string on top of each other
  TRANSLATED_FIELD = 'translatedField', // Translate object that have their own translation inside them : { en: "", fr: "" }
  TRANSLATED_FIELD_LIST = 'translatedFieldList' // Display and translate an array of object that have their own translation inside them : [{ en: "", fr: "" }]
}

@Component({
  selector: 'ui-static-table-row',
  templateUrl: './ui-static-table-row.component.html',
  styleUrls: [ '../ui-static-table.component.scss', './ui-static-table-row.component.scss' ],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-static-table-row',
  },
})
export class UiStaticTableRow implements OnInit {
  @Input() public isIconShowing: boolean;

  @Input() public columnDef: any;

  @Input() public row: any;

  @Input() public hoveredRow: any;

  @Input() public smallRow = false;

  @Input() public canSelectItems = true;

  @Input() public locale = LanguageEnum.FRENCH;

  @Input() public componentEmitter: EventEmitter<any>;

  @Input() public bold: boolean;

  @Output('checkbox-event') public checkboxEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  public uiStaticTableRowType = UiStaticTableRowType;

  public dynamicComponentParam: any;

  public checkboxDisabled: boolean;

  public checkboxVisible: boolean;

  constructor() {
  }

  public get resourceTranslationParams(): any {
    return { fieldName: (this.row[this.columnDef.field] ? 'trueValue' : 'falseValue'), locale: this.locale };
  }

  public getDateParams(columnDef: any, withTime: false): any {
    const params: DatePipeParams = { locale: this.locale };
    if (withTime) {
      params.withTime = Boolean(withTime);
    }
    if (columnDef.mediumDate) {
      params.mediumDate = Boolean(columnDef.mediumDate);
    }
    return params;
  }

  public ngOnInit() {
    if (
      this.columnDef.type === UiStaticTableRowType.DYNAMIC_COMPONENT_HOST ||
      this.columnDef.type === UiStaticTableRowType.DYNAMIC_COMPONENT_DEEP_1) {
      const dynamicComponentParam = {
        component: this.columnDef.component,
        data: {},
      };

      if (this.columnDef.type === UiStaticTableRowType.DYNAMIC_COMPONENT_HOST) {
        dynamicComponentParam.data[this.columnDef.paramName] = this.columnDef.firstParamData
          ? this.row[this.columnDef.firstParamData]
          : this.row;
        dynamicComponentParam.data['emitter'] = this.componentEmitter;
      } else if (this.columnDef.type === UiStaticTableRowType.DYNAMIC_COMPONENT_DEEP_1) {
        dynamicComponentParam.data[this.columnDef.paramName] =
          this.row[(this.columnDef?.fields)[0]][(this.columnDef?.fields)[1]];
      }

      if (this.columnDef.secondParamName && this.columnDef.secondParamValue) {
        dynamicComponentParam.data[this.columnDef.secondParamName] = this.columnDef.secondParamValue;
      }

      this.dynamicComponentParam = dynamicComponentParam;
    }

    if (this.columnDef.type === UiStaticTableRowType.CHECKBOX) {
      this.checkboxDisabled = typeof this.columnDef.getCheckboxDisabled === "function"
        ? this.columnDef.getCheckboxDisabled(this.row)
        : false;
      this.checkboxVisible = typeof this.columnDef.getCheckboxVisible === "function"
        ? this.columnDef.getCheckboxVisible(this.row)
        : true;
    }

    this.isIconShowing = this.__isIconShowing;
  }

  public get __isIconShowing() {
    if (this.columnDef.type === UiStaticTableRowType.EXPENDABLE_ICON) {
      // Note: Depuis qu'on fait ceci, ca bypass la methode handleChevron() de la ui-static-table.component.ts
      return typeof this.columnDef.showIcon === "function"
        ? this.columnDef.showIcon(this.row)
        : this.columnDef.showIcon;
    }
    return this.isIconShowing;
  }

  // Pu supporté pcq couplé à 1000% sur f2s-infra
  // public getOfficeIcon(file: any): string {
  //   if (file.fileType === 'Folder') {
  //     return IconUtil.getFileIconFromFileExtension(null);
  //   }
  //   return IconUtil.getFileIconFromFileExtension(file.extension);
  // }

  public openUrl(webUrl: string): void {
    window.open(webUrl, '_blank');
  }

  public checkboxEvent(event: any, item: any, selectedField: string) {
    // On utilise pas le store de layout dans le uikit
    // this.store.dispatch(new HideDrawer());
    item[selectedField] = event;
    this.checkboxEventEmitter.emit({ event, item });
  }

  public specialCheckboxEvent(item: any, selectedField: string) {
    // On utilise pas le store de layout dans le uikit
    // this.store.dispatch(new HideDrawer());
    if (this.canSelectItems || item[selectedField]) {
      item[selectedField] = !item[selectedField];
      this.checkboxEvent(item[selectedField], item, selectedField);
    }
  }

  public dynamicTruncatedTooltip(el: HTMLElement, columnData: any): string {
    const isEllipsisActive = el && (el.offsetWidth < el.scrollWidth);
    if (isEllipsisActive && typeof columnData === 'string') {
      return columnData;
    }
    return "";
  }
}
