import {
  Component, Input, ViewEncapsulation
} from '@angular/core';
import { LanguageEnum } from '../../../interfaces/ILanguage';

@Component({
  selector: 'ui-static-table-empty',
  templateUrl: './ui-static-table-empty.component.html',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-static-table-empty',
  },
})
export class UiStaticTableEmpty {
  @Input('locale') public locale = LanguageEnum.FRENCH;
}
