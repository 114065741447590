import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { I18nService } from "@common/modules/i18n/i18n.service";
import { DrawerService } from "@common/modules/layout/components/drawer/services/drawer.service";
import { FormCreationService } from "@common/modules/shared/form-creation.service";
import { DatasourcesApiService } from "@common/services/api/respond/datasources/datasources.api";
import { Select, Store } from "@ngxs/store";
import { Eco } from "projects/@common/definitions/eco";
import { AppState } from "projects/@common/modules/layout/stores/app.state";
import { BehaviorSubject } from "rxjs";

import { DatasourceTemplateConfig } from "./datasourceTemplateConfig";

@Component({
  selector: 'datasource-creation-details',
  templateUrl: './datasource-creation-details.component.html',
  styleUrls: [ './datasource-creation-details.component.scss' ],
})
export class DatasourceCreationDetailsComponent extends DatasourceTemplateConfig implements OnInit {
  @Input()
  public data: {
    title: string;
    templates: any;
    organizationSelected: { name: string, id: string; };
  };

  public datasourceTypes: Array<string>;

  @Select(AppState.selectedOrganization) public selectedOrganization$: BehaviorSubject<Eco.IOrganization>;

  constructor (
    readonly i18n: I18nService,
    readonly formBuilder: UntypedFormBuilder,
    readonly formCreationService: FormCreationService,
    readonly drawerService: DrawerService,
    readonly datasourcesApiService: DatasourcesApiService,
    readonly store: Store
  ) {
    super(i18n, formBuilder, formCreationService, datasourcesApiService, drawerService, store);
  }

  ngOnInit(): void {
    this.datasourcesTemplateAndOrgList = this.data;
    this.datasourceTypes = this.templateImplementationDropdown;
  }
}
