import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { EcoUrlService } from '../../core/url.service';
import { IMonitor } from './usecase.definition';

export interface UpdateMonitorRequest {
  usecaseId: string;
  blacklisted?: boolean;
  blacklistedComment?: boolean;
  silenced?: boolean;
  silencedComment?: string;
}

@Injectable({
  providedIn: 'root',
})
export abstract class MonitorsApiService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.alerting;

  constructor (readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public abstract updateMonitor(organizationId: string, monitorId: string, request: UpdateMonitorRequest): Promise<IMonitor>;
}

