import { Component, Input } from "@angular/core";
import { alertingBadgeType } from "projects/@common/components/alerting-badge/alerting-badge.component";

@Component({
  selector: 'app-priority-table',
  templateUrl: './priority-table.component.html',
  styleUrls: [ './priority-table.component.scss' ],
})
export class PriorityTableComponent {
  @Input() public type: any & { priority: alertingBadgeType; };
}
