import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../services/I18nService';

@Injectable()
@Pipe({
  name: 't',
  pure: false,
})
export class TPipe implements PipeTransform {
  public constructor(protected readonly i18n: I18nService) { }

  public transform(key: string, params: any = {}, otherwise: string = key): string {
    return this.i18n.translate(key, params, otherwise);
  }
}
