<div
  *ngIf="!isSearchingValues"
  class="choices-list"
  (scroll)="onScroll($event)"
>
  <div
    *ngFor="let customValue of customValuesToDisplay; let index = index"
    class="hover choice separator accessible-choice"
    [id]="'rowElement_' + index"
    [attr.data-test-id]="loaded"
    (mousedown)="onValueClick(customValue, index, $event)"
  >
    <div class="choice-infos-container">
      <div class="choices-infos">
        {{ customValue.displayValue }}
      </div>
    </div>
  </div>
  <ng-container *ngIf="loading">
    <ui-spinner
      size="medium"
      modifier="secondary"
    ></ui-spinner>
  </ng-container>
</div>
