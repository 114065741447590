import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiEndpointPrefixEnum } from "projects/@common/definitions/eco-api";
import { environment } from "projects/@common/environments/environment";
import { EcoUrlService } from "projects/@common/services/api/core/url.service";
import { Observable } from "rxjs";


// Catches the request and change eco host if specified in environment file
@Injectable()
export class ApiHostInterceptor implements HttpInterceptor {
  constructor(private url: EcoUrlService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.ecoUrls.alertingLocalUrl && this._isApi(req.url, ApiEndpointPrefixEnum.alerting)) {
      req = this._changeHostname(req, environment.ecoUrls.alertingLocalUrl, ApiEndpointPrefixEnum.alerting);
    }
    if (environment.ecoUrls.automationLocalUrl && this._isApi(req.url, ApiEndpointPrefixEnum.automation)) {
      req = this._changeHostname(req, environment.ecoUrls.automationLocalUrl, ApiEndpointPrefixEnum.automation);
    }
    if (environment.ecoUrls.iamLocalUrl && this._isApi(req.url, ApiEndpointPrefixEnum.iam)) {
      req = this._changeHostname(req, environment.ecoUrls.iamLocalUrl, ApiEndpointPrefixEnum.iam);
    }
    if (environment.ecoUrls.connectorsLocalUrl && this._isApi(req.url, ApiEndpointPrefixEnum.detect_connectors)) {
      req = this._changeHostname(req, environment.ecoUrls.connectorsLocalUrl, ApiEndpointPrefixEnum.detect_connectors);
    }
    if (environment.ecoUrls.toolsLocalUrl && this._isApi(req.url, ApiEndpointPrefixEnum.tools)) {
      req = this._changeHostname(req, environment.ecoUrls.toolsLocalUrl, ApiEndpointPrefixEnum.tools);
    }
    if (environment.ecoUrls.sgLocalUrl && this._isApi(req.url, ApiEndpointPrefixEnum.sg)) {
      req = this._changeHostname(req, environment.ecoUrls.sgLocalUrl, ApiEndpointPrefixEnum.sg);
    }
    if (environment.ecoUrls.notificationLocalUrl && this._isApi(req.url, ApiEndpointPrefixEnum.notification)) {
      req = this._changeHostname(req, environment.ecoUrls.notificationLocalUrl, ApiEndpointPrefixEnum.notification);
    }
    if (environment.ecoUrls.requestsLocalUrl && this._isApi(req.url, ApiEndpointPrefixEnum.requests)) {
      req = this._changeHostname(req, environment.ecoUrls.requestsLocalUrl, ApiEndpointPrefixEnum.requests);
    }
    if (environment.ecoUrls.backtestLocalUrl && this._isApi(req.url, ApiEndpointPrefixEnum.backtest)) {
      req = this._changeHostname(req, environment.ecoUrls.backtestLocalUrl, ApiEndpointPrefixEnum.backtest);
    }

    return next.handle(req);
  }

  private _isEcoApi(url: string): boolean {
    return url.includes(this.url.baseUrl);
  }

  private _isApi(url: string, prefix: ApiEndpointPrefixEnum) {
    return this._isEcoApi(url) && url.includes(prefix);
  }

  private _changeHostname(request: HttpRequest<any>, hostname: string, toReplace: ApiEndpointPrefixEnum): HttpRequest<any> {
    const localUrl = new URL(hostname);
    const url = new URL(request.url);
    
    url.hostname = localUrl.hostname;
    url.protocol = localUrl.protocol;
    url.port = localUrl.port;
    url.pathname = `/public${url.pathname.replace(toReplace, '').replace('/public', '')}`;

    if (url.href.trimEnd().slice(-1) === '?') {
      url.href = url.href.trimEnd().slice(0, -1);
    }

    return request.clone({ url: url.href.toString() });
  }
}
