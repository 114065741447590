<table class="tables-table">
  <tr class="tables--headers">
    <th
      (click)="sort()"
      class="tables--headers--primary-column tables--headers--sortable"
    >
      <div>{{ 'securityGroups.title' | t }} ({{ securityGroups?.length }})</div>
      <div>
        <ui-icon
          [name]="direction === directions.Asc ? 'arrow-asc' : 'arrow-desc'"
          size="small"
          modifier="secondary-gray"
        >
        </ui-icon>
      </div>
    </th>
    <th *ngIf="isEditing"></th>
  </tr>
  <ng-container *ngIf="!isLoading && securityGroups?.length">
    <ng-container *ngFor="let group of securityGroups">
      <tr class="tr-hover">
        <td class="tables--name-part">
          <div class="users-table--avatar">
            <ui-avatar
              size="small"
              [user]="{firstName: group?.name, guid: group?.id}"
            ></ui-avatar>
          </div>
          <div class="tables--name-part--name users-table--name">
            <div
              [title]="group.name"
              class="margin-left"
            >
              <span>{{ group.name}}</span>
            </div>
            <div
              [title]="group?.description"
              class="margin-left elipsis"
            >
              <span class="secondary">{{ getDescription(group?.description)}}</span>
            </div>
          </div>
        </td>

        <td
          *ngIf="isEditing"
          class="delete-icon"
        >
          <ng-container>
            <ui-icon
              class="table-row-delete-bouton"
              name="close"
              modifier="on-header"
              size="small"
              (click)="removeSecurityGroup(group)"
            ></ui-icon>
          </ng-container>
        </td>
      </tr>
    </ng-container>
  </ng-container>
</table>

<ng-container *ngIf="isLoading">
  <ui-spinner
    class="table-loading-spinner"
    size="medium"
    modifier="secondary"
  ></ui-spinner>
</ng-container>

<ng-container *ngIf="!isLoading && securityGroups?.length <= 0">
  <span class="empty-table-message">{{ 'securityGroups.empty.table' | t }}</span>
</ng-container>