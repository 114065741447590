
import { EnvConfiguration } from 'projects/@common/definitions/env.definitions';
import { Stages } from 'projects/@common/definitions/stages';


export const environment: EnvConfiguration = {
  stage: Stages.DEV,
  admUrl: 'https://adm.dev.f2s.io',
  orgUrl: 'https://org.dev.f2s.io',
  ecoUrls: {
    defaultUrl: 'https://dev.eco.api.f2s.io',
  },
};
