import { Component, Input, OnInit } from '@angular/core';
import { Eco } from 'projects/@common/definitions/eco';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IamApiService } from 'projects/@common/services/api/iam/iam.api';

@Component({
  selector: 'organization-preferred-language',
  templateUrl: './organization-preferred-language.component.html',
  styleUrls: [ './organization-preferred-language.component.scss' ],
})
export class OrganizationPreferredLanguageComponent implements OnInit {
  @Input()
  public organizationEcoId: string;

  @Input()
  public organizationPreferredLanguage: Eco.Languages;

  public browserLocale: Eco.Languages;
  public preferredLanguage: Eco.Languages;

  constructor(
    private readonly i18n: I18nService,
    private readonly iamApiService: IamApiService
  ) { }

  ngOnInit(): void {
    this.browserLocale = this.i18n.currentLocale as Eco.Languages;

    if (this.organizationPreferredLanguage) {
      this.preferredLanguage = this.organizationPreferredLanguage;
    } else {
      this.fetchOrganizationPreferredLanguage(this.organizationEcoId).then((iamPreferredLanguage: Eco.Languages) => {
        this.preferredLanguage = iamPreferredLanguage;
      });
    }
  }

  private async fetchOrganizationPreferredLanguage(organizationEcoId: string): Promise<Eco.Languages> {
    if (organizationEcoId) {
      const iamOrgRes = await this.iamApiService.describeOrganization(organizationEcoId);
      return iamOrgRes.preferredLang;
    }
  }
}
