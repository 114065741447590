import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'enumPipe',
})
export class EnumPipe implements PipeTransform {
  transform(value: any): any {
    return Object.values(value);
  }
}
