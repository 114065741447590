import { Component, Input, OnInit } from '@angular/core';

export class TableScrollableColumnsContent {
  headerName: string;
  content: string[];
  footerName?: string;
}

@Component({
  selector: 'ui-table-scrollable-body',
  templateUrl: './ui-table-scrollable-body.component.html',
  styleUrls: [ './ui-table-scrollable-body.component.scss' ],
  host: {
    class: 'ui-table-scrollable-body',
  },
})
export class UiTableScrollableBodyComponent implements OnInit {
  @Input() public data: TableScrollableColumnsContent[] = [];
  @Input() public displayFooter: boolean = true;
  @Input() public maxContentHeight: string = '20rem';

  constructor() { }

  ngOnInit(): void {
    this.validateData();
  }

  private validateData(): void {
    if (this.data && this.data.length > 0) {
      const maxContentLength: number = Math.max(...this.data.map((column) => column.content.length));
      this.data.forEach((column) => {
        const padding: number = maxContentLength - column.content.length;
        if (padding > 0) {
          column.content = [ ...column.content, ...new Array(padding).fill('') ];
        }
      });
    }
  }
}
