<div class="background-dialog">
  <div
       class="dialog"
       [ngClass]="{
      'small': isSmall,
      'drawer-centered': isInDrawer && !mobileService.isMobile(),
      'window-centered': !isInDrawer && !mobileService.isMobile(),
      'mobile-centered': mobileService.isMobile(),
      'mobile-centered--fullscreen':
        mobileService.isMobile() && isFullMobileScreen && !mobileService.isIOS,
      'mobile-centered--fullscreen-ios':
        mobileService.isMobile() && isFullMobileScreen && mobileService.isIOS
    }">
    <section
      *ngIf="showDialogHeader"
      class="dialog--title">
      {{ title }}
    </section>

    <section
             class="dialog--content"
             [ngClass]="{
        'dialog--content--mobile':
          mobileService.isMobile() &&
          isFullMobileScreen &&
          !mobileService.isIOS,
        'dialog--content--mobile-ios':
          mobileService.isMobile() && isFullMobileScreen && mobileService.isIOS,
          'dialog--content--overflow': shouldContentOverflow
      }">
      <div class="dialog--content--data">
        <div *ngIf="message" [innerHTML]="message"></div>
        <ng-content></ng-content>
      </div>
    </section>

    <section class="buttons">
      <ng-container *ngIf="isLoading">
        <ui-spinner size="medium" modifier="secondary"></ui-spinner>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <button
                ui-button
                *ngIf="withActionButton"
                color="standard"
                (click)="actionClick()"
                [disabled]="actionButtonDisabledCondition"
                title="{{ (disabledReason ? disabledReason : actionButton)  }}">
          {{ actionButton }}
        </button>
        <button
                ui-button
                *ngIf="withCancelButton"
                color="secondary"
                title="{{ cancelButton  }}"
                (click)="cancelClick()">
          {{ cancelButton }}
        </button>
      </ng-container>
    </section>
  </div>
</div>
