<ui-title [name]="'datasources.tab.creation.configuration.title' | t">
  <ng-container slot="nextToLabel">{{
    templateConfig.templateName
  }}</ng-container>
</ui-title>

<label ui-label>{{ templateConfig.templateDescription }}</label>
<br />

<ng-container *ngFor="let config of uiFormCreationService.formsToBuild">
  <dynamic-form-template
    (valueSelection)="setListValue($event)"
    [formConfig]="config"
  ></dynamic-form-template>
</ng-container>
