import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LanguageEnum } from '../../../interfaces/ILanguage';

@Component({
  selector: 'ui-table-total-tool',
  templateUrl: './ui-table-total-tool.component.html',
  styleUrls: [ './ui-table-total-tool.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-table-total-tool',
  },
})
export class UiTableTotalTool {
  @Input('total') public total = 0;
  @Input('locale') public locale = LanguageEnum.FRENCH;
}
