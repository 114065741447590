import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { I18nState } from './state/i18n.state';
import { TPipe } from './t.pipe';
import { TranslatedObjectPipe } from './translatedObject.pipe';

@NgModule({
  imports: [
    NgxsModule.forFeature([ I18nState ]),
  ],
  providers: [
    TPipe,
    TranslatedObjectPipe,
  ],
  declarations: [
    TPipe,
    TranslatedObjectPipe,
  ],
  exports: [
    TPipe,
    TranslatedObjectPipe,
  ],
})
export class I18nModule {
}
