export enum DrawerPageEnum {
  datasourcesDrawer = 'datasources-drawer',
  descriptionUsecaseDrawer = 'usecase-description-drawer',
  respondOnboardOrganizationDrawer = 'respond-onboard-organization-drawer',
  descriptionOrganizationDrawer = 'organization-description-drawer',
  usecaseDrawer = 'usecase-drawer',
  createUsecaseDrawer = 'usecase-create-drawer',
  roleDrawer = 'role-details',
  updateOrganization = 'update-organization',
  createParameterDrawer = 'create-parameter-drawer',
  createSecretDrawer = 'create-secret-drawer',
  contextualHelp = 'contextual-help',
  userFilesDrawer = 'user-files-drawer',
  alertsDrawer = 'app-alert-drawer',
  opsManagerDrawer = 'ops-manager-drawer',
  connectorCreationDrawer = 'connector-creation-drawer',
  connectorDetailDrawer = 'connector-details-drawer',
  notificationDrawer = 'notification-drawer',
  requestDrawer = 'request-drawer',
  actifDrawer = 'app-actif-drawer',
  whitelistDrawer = 'respond-whitelist-drawer',
  filterDrawer = 'respond-filter-drawer',
  requestCreationDrawer = 'request-creation-drawer',
  sensitiveFileDrawer = 'sensitive-file-drawer',
  airPromptsDrawer = 'air-prompts-drawer',
  syslogHostsAssociationDrawer = 'syslog-hosts-association-drawer'
}
