<div
  class="type-container"
  [ngClass]="color ? color : ''"
  [class.type-container-padding]="withPadding"
>
  <img *ngIf="withLeftIcon && image != ''" src="{{ image }}" alt="" />

  <ui-avatar
    *ngIf="withLeftIcon && !image && !withBackgroundColor"
    [name]="name"
    size="smallest"
  ></ui-avatar>

  <span>
    {{ name | t:{locale:locale} }}
  </span>
</div>
