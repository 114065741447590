import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullname',
})
export class FullnamePipe implements PipeTransform {
  public transform(user: any | null): string | null {
    return user ? [ user.firstName, user.lastName ].join(' ') : null;
  }
}
