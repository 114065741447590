<table class="weekly-table">
  <thead>
    <tr>
      <th class="col-title" [class.disabled]="readOnly">{{"common.hours" | t}}</th>
        <th
          class="col-title"
          [class.disabled]="readOnly"
          *ngFor="let day of weekdays"
          (click)="!readOnly && toggleDaySelection(day.index)"
        >{{ day.label }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let hour of hours">
      <th [class.disabled]="readOnly" (click)="!readOnly && toggleHourSelection(hour.index)">{{ hour.label }}</th>
      <td
        *ngFor="let day of weekdays"
        class="custom-checkbox-cell-click-handler"
        [class.disabled]="readOnly"
        (mousedown)="!readOnly && setDragStartCell(day.index, hour.index)"
        (mouseup)="!readOnly && setDragEndCell(day.index, hour.index)"
      >
        <div class="custom-checkbox-container" [class.disabled]="readOnly">
          <div
            class="custom-checkbox-colored-zone"
            [class.checked]="isHourSelected(day.index, hour.index)"
          ></div>
        </div>
      </td>
    </tr>
  </tbody>
</table>