import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, ViewChild, ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Eco } from 'projects/@common/definitions/eco';
import { Observable } from 'rxjs/internal/Observable';
import { AppState, ResetPopover, SetPopover, UnsetPopover } from '../../../stores/app.state';

@Component({
  selector: 'topbar-organizations',
  templateUrl: './topbar-organizations.component.html',
  styleUrls: [ './topbar-organizations.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'topbar-organizations',
  },
})
export class TopbarOrganizations {
  @ViewChild(CdkConnectedOverlay, { static: false }) public overlay: CdkConnectedOverlay;
  @Select(AppState.isPopoverActive) public isPopoverActive$: Observable<(name: string) => boolean>;

  @Input('current-organization') public currentOrganization: Eco.IOrganization;

  public canSelectOrganization = false;

  public hostname = 'topbar-organizations';

  constructor (
    protected readonly ref: ElementRef,
    protected readonly store: Store,
    protected readonly router: Router
  ) {
  }

  public toggle(): void {
    this.isOpened() ? this.close() : this.open();
  }

  public open(): void {
    if (this.isOpened()) {
      return;
    }

    this.store.dispatch(new SetPopover({ name: this.hostname }));
  }

  public close(): void {
    if (!this.isOpened()) {
      return;
    }

    this.store.dispatch(new ResetPopover());
  }

  public isOpened(): boolean {
    return this.hostname === this.store.selectSnapshot((state) => state.app.popover);
  }

  @HostListener('document:click', [ '$event' ])
  public handleClickOut({ target }: Event): void {
    if (!this.isOpened()) {
      return;
    }

    if (
      this.isOpened() &&
      (this.ref.nativeElement.contains(target) || this.overlay.overlayRef.overlayElement.contains(target as Node))
    ) {
      return;
    }

    this.store.dispatch(new UnsetPopover({ name: this.hostname }));
  }
}
