import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input, HostListener, ElementRef } from '@angular/core';
import { UiButtonColor } from '../ui-button/ui-button.component';

@Component({
  selector: 'ui-actions-dropdown',
  templateUrl: './ui-actions-dropdown.component.html',
  styleUrls: [ './ui-actions-dropdown.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UiActionsDropdown {
  @Input() public descriptionButton: false;

  @Input() public buttonName: string;

  @Input() public color: UiButtonColor;

  @Input() public icon: string;

  @HostListener('document:click', [ '$event' ])
  clickOut(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showOptions = false;
    }
  }

  @HostListener('keydown.escape', [ '$event' ])
  onEscapeKey(event: KeyboardEvent) {
    if (this.showOptions) {
      event.stopPropagation();
      this.showOptions = false;
    }
  }

  public showOptions = false;

  constructor(private eRef: ElementRef) {}

  public toggleOptions() {
    this.showOptions = !this.showOptions;
  }
}
