<div *ngIf="isEditable">
  <div class="cards-container">
    <div *ngFor="let option of options">
      <div
           class="radio-button"
           [ngClass]="option.selected ? 'selected' : 'unselected'"
           (click)="onButtonClick(option)">
        <div class="image-container">
          <img [src]="option.image" />
        </div>
        {{ option.label }}
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isEditable">
  <div class="cards-container">
    <div *ngFor="let option of options">
      <div *ngIf="option.selected" class="radio-button">
        <div class="image-container">
          <img [src]="option.image" />
        </div>
        {{ option.label }}
      </div>
    </div>
  </div>
</div>