import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ui-user-tags',
  templateUrl: './ui-user-tags.component.html',
  styleUrls: [ './ui-user-tags.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'user-tags',
  },
})
export class UserTagsComponent {
  @Input() public users: any[];

  @Input() public readOnly = false;

  @Output() public onDeleteUser: EventEmitter<any> = new EventEmitter();

  constructor() {}

  public removeUser(user: any) {
    this.onDeleteUser.emit(user);
  }
}
