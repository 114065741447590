import { DatePipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { LanguageEnum } from '../interfaces/ILanguage';

@Injectable()
@Pipe({
  name: 'hours',
  pure: false,
})
export class HoursPipe implements PipeTransform {
  constructor(private datepipe: DatePipe) {
  }

  public transform(value: any, lang: LanguageEnum = LanguageEnum.FRENCH): string {
    if (!value) {
      return '';
    }
    return this.datepipe.transform(new Date(value), 'shortTime', null, lang === LanguageEnum.FRENCH ? 'fr-ca' : 'en');
  }
}
