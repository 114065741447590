import { Injectable } from '@angular/core';
import { BaseSidebar, navigationElement } from '@common/modules/layout/definitions/base-sidebar.service';
import { DisplayRequirements } from 'projects/@common/modules/display/display.service';


@Injectable()
export class AdmIamSidebar extends BaseSidebar {
  private elements: navigationElement[] = [
    { name: 'MD.IAM' },
    {
      rootAction: true,
      name: 'sidebar.navigation.iam.organizations',
      icon: 'icon_admin_menu_resources',
      route: '/organizations',
      permissions: [ 'organization_list' ],
    },
  ];

  constructor() {
    super();
  }

  public unsubscribe() {
  }

  public getElements() {
    return this.elements;
  }

  public getRequirements(): DisplayRequirements {
    return {};
  }
}
