import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { DatasourceCreationDetailsComponent } from './components/datasource-creation-details/datasource-creation-details.component';
import { DatasourceDynamicFormComponent } from './components/datasource-dynamic-form/datasource-dynamic-form.component';
import { DatasourceInfoDetailsComponent } from './components/datasource-info-details/datasource-info-details.component';
import { DatasourcesContainer } from './containers/datasources.container';
import { DatasourcesDrawerContainer } from './containers/drawer/datasources.drawer.component';
import { DatasourcesRoutingModule } from './datasources-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { UiKitModule } from '@ui-kit/uikit.module';
import { FeatureSharedModule } from '@common/modules/shared/feature.shared.module';
import { I18nModule } from '@common/modules/i18n/i18n.module';

@NgModule({
  declarations: [
    DatasourcesContainer,
    DatasourcesDrawerContainer,
    DatasourceCreationDetailsComponent,
    DatasourceDynamicFormComponent,
    DatasourceInfoDetailsComponent,
  ],
  imports: [
    CommonModule,
    DatasourcesRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgxsFormPluginModule,
    FeatureSharedModule,
    UiKitModule,
    I18nModule,
    SharedModule,
  ],
})
export class DatasourcesModule {}
