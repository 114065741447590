import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, OnInit, ViewEncapsulation } from "@angular/core";

import { prune } from "../../services/utils";
import { UiIconModifier, UiIconSize } from "../ui-icon/ui-icon.component";
import { UiSpinnerModifier } from "../ui-spinner/ui-spinner.component";


const name = 'ui-button';

export enum UiButtonColor {
  Sidenav = 'sidenav',
  SidenavAdmin = 'sidenavAdmin',
  SidenavAdminChild = 'sidenavAdminChild',
  Primary = 'primary',
  PrimaryFlat = 'primaryFlat',
  Standard = 'standard',
  Secondary = 'secondary',
  Dropdown = 'dropdown',
  SecondaryGray = 'secondary-gray',
  Link = 'link',
  LinkBorded = 'linkBorded',
  LinkFlat = 'linkFlat',
  ListAction = 'listAction',
  ListActionFlat = 'listActionFlat',
  DrawerCloseFlat = 'drawerCloseFlat',
  Topbar = 'topbar',
  DrawerTabs = 'drawerTabs',
  LinkFlatSecondary = 'linkFlatSecondary',
  Filter = 'filter',
  ValidationFlat = 'validationFlat',
  ErrorFlat = 'errorFlat',
  SnackbarFlatPrimary = 'snackbarFlatPrimary',
  SnackbarFlatWhite = 'snackbarFlatWhite',
  DashboardWidget = 'dashboardWidget',
  TransparentFlat = 'transparentFlat',
  WidgetTabsFlat = 'widgetTabsFlat',
  SecondaryActionFlat = 'secondaryActionFlat',
  MobileListFlat = 'mobileListFlat',
  ListActionFlatGray = 'listActionFlatGray',
  LightGreyFlat = 'lightGreyFlat',
  Split = 'split'
}

export enum UiButtonType {
  Button = 'button',
  Submit = 'submit',
  Reset = 'reset'
}

export enum UiButtonSize {
  Normal = 'normal',
  Slim = 'slim'
}

@Component({
  selector: 'button[ui-button]',
  templateUrl: './ui-button.component.html',
  styleUrls: [ './ui-button.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: false,
  host: {
    '[attr.disabled]': 'isDisabled || null',
    '[attr.aria-disabled]': 'isDisabled || null',
  },
})
export class UiButton implements OnInit, OnChanges {
  @Input('class') public class: string;

  @HostBinding('class') public classes: string;

  @HostBinding('attr.type') @Input('type') public htmlType: UiButtonType = UiButtonType.Button;

  @Input('color') public color: UiButtonColor = UiButtonColor.Secondary;

  @Input('suffix') public suffix: string;

  @Input('icon-margin') public iconMargin = null;

  @Input('size') public size: UiButtonSize = UiButtonSize.Normal;

  @Input('activeLink') public activeLink = false;

  @Input('custom-icon') public customIcon = false;

  @Input('custom-icon-width') public customIconWidth = null; // ex: 18px

  @Input('disabled') public disabled = false;

  @Input('wide') public wide = false;

  @Input('custom-icon-size') customIconSize: string;

  @Input('isSvg') isSvg = false;

  @Input('icon') public icon: string | null = null;

  @Input('iconRight') public iconRight: string | null = null;

  @Input('icon-size') public iconSize: UiIconSize = UiIconSize.Small;

  @Input('hide-icon') public hideIcon = false;

  @Input('ripple') public ripple = true;

  @Input('loading') public loading = false;

  @Input('isIconAtRight') public isIconAtRight = false;

  public iconModifier: UiIconModifier = UiIconModifier.OnBright;

  public spinnerModifier: UiSpinnerModifier = UiSpinnerModifier.OnBright;

  public isDisabled = false;

  public isLoading = false;

  public isMobile = false;

  public ngOnInit(): void {
    this.setup();
  }

  public ngOnChanges(): void {
    this.setup();
  }

  public setup(): void {
    switch (this.color) {
      case UiButtonColor.PrimaryFlat:
      case UiButtonColor.Dropdown:
      case UiButtonColor.Primary:
      case UiButtonColor.Secondary:
      case UiButtonColor.DrawerTabs:
        this.iconModifier = UiIconModifier.OnDark;
        this.spinnerModifier = UiSpinnerModifier.OnDark;
        break;
      case UiButtonColor.Filter:
        this.iconModifier = UiIconModifier.OnHighlight;
        break;
      case UiButtonColor.SecondaryGray:
      case UiButtonColor.LinkBorded:
      case UiButtonColor.DrawerCloseFlat:
        this.iconModifier = UiIconModifier.SecondaryGray;
        this.spinnerModifier = UiSpinnerModifier.Secondary;
        break;
      case UiButtonColor.Link:
      case UiButtonColor.SnackbarFlatPrimary:
        this.iconModifier = UiIconModifier.Primary;
        this.spinnerModifier = UiSpinnerModifier.Secondary;
        break;
      case UiButtonColor.SnackbarFlatWhite:
        this.iconModifier = UiIconModifier.White;
        this.spinnerModifier = UiSpinnerModifier.OnDark;
        break;
      case UiButtonColor.Standard:
      case UiButtonColor.LinkFlatSecondary:
        this.iconModifier = UiIconModifier.OnDark;
        this.spinnerModifier = UiSpinnerModifier.OnDark;
        break;
      case UiButtonColor.LightGreyFlat:
      case UiButtonColor.ListAction:
      case UiButtonColor.ListActionFlat:
        this.iconModifier = UiIconModifier.listAction;
        this.spinnerModifier = UiSpinnerModifier.OnDark;
        break;
      case UiButtonColor.ListActionFlatGray:
        this.iconModifier = UiIconModifier.Gray;
        this.spinnerModifier = UiSpinnerModifier.OnDark;
        break;
      case UiButtonColor.ValidationFlat:
        this.iconModifier = UiIconModifier.Validation;
        this.spinnerModifier = UiSpinnerModifier.OnBright;
        break;
      case UiButtonColor.ErrorFlat:
        this.iconModifier = UiIconModifier.Error;
        this.spinnerModifier = UiSpinnerModifier.OnBright;
        break;
      case UiButtonColor.Topbar:
        this.iconModifier = UiIconModifier.White;
        this.spinnerModifier = UiSpinnerModifier.OnDark;
        break;
      case UiButtonColor.Sidenav:
        this.iconModifier = UiIconModifier.Sidenav;
        break;
      case UiButtonColor.SidenavAdmin:
        this.iconModifier = UiIconModifier.SidenavAdmin;
        break;
      case UiButtonColor.DashboardWidget:
        this.iconModifier = UiIconModifier.DashboardWidget;
        break;
      case UiButtonColor.MobileListFlat:
        this.iconModifier = UiIconModifier.OnBright;
        this.spinnerModifier = UiSpinnerModifier.OnBright;
        break;
      default:
        this.iconModifier = UiIconModifier.OnBright;
        this.spinnerModifier = UiSpinnerModifier.OnBright;
    }

    this.isDisabled = this.disabled || this.loading;
    this.isLoading = this.loading && !this.disabled;

    const hasRipple = !this.disabled && !this.loading && this.ripple;

    const classes = {
      [name]: true,
      [`${name}_${this.color}`]: true,
      [`${name}_${this.size}`]: true,
      [`${name}_loading`]: this.isLoading,
      [`${name}_disabled`]: this.disabled,
      [`${name}_wide`]: this.wide,
      [`${name}_icon`]: !!this.icon,
      [`${name}_custom_icon`]: !!this.icon && this.customIcon && !this.isIconAtRight && this.size !== 'slim',
      [`${name}_ripple`]: hasRipple,
      [`${name}_ripple_${this.color}`]: hasRipple,
    };

    if (this.class) {
      classes[this.class] = true;
    }

    this.classes = Object.keys(prune(classes)).join(' ');
  }

  public get iconOpacity() {
    return this.hideIcon ? 0 : 1;
  }
}
