<div class="container">
  <div
    *ngIf="withIcon"
    class="img-wrapper"
    [class.wrapper-width]="wrapper"
  >
    <img
      [src]="incidentStatusIcon"
      alt="Statut"
      class="img-{{data?.status}}"
      [class.margin-right]="context === 'details'"
    >
  </div>
  <p [class.bold]="data?.updatedAfterLastDescribe">{{incidentStatusText | t}}</p>
</div>