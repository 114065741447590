import { DisplayRequirements } from "../../display/display.service";

export abstract class BaseSidebar {
  public abstract unsubscribe(): void;

  public abstract getElements();

  public abstract getRequirements(): DisplayRequirements;
}

export enum previewModeEnum {
  BETA = 'beta',
  PREVIEW = 'preview',
  EXPERIMENTAL = 'experimental',
  DEPRECATED = 'deprecated',
}

export enum OrgTypeEnum {
  ROOT = "root",
  VAR = "var",
  CUSTOMER = "customer"
}

export enum ServiceTypeEnum {
  // OLD
  RESPOND = "respond",
  ASSSIT_OPS = "assist_ops",
  GRC = "grc",

  // -----------------------------------------------

  // NEW
  PROACTIVE_CYBERSECURITY = "proactiveCybersecurity",
  DETECT_AND_RESPOND = "detectAndRespond"
}

export enum FeatureFlagsEnum {
  // AIR
  AIR_MANAGEMENT = 'airManagement',
  PROMPTS_MANAGEMENT = 'promptsManagement',

  // Cybersecrity Proactive
  SHARES_MANAGEMENT = "sharesManagement",
  RESOURCE_MANAGEMENT = "resourceManagement",
  GUESTS_MANAGEMENT = "guestsManagement",
  GUESTS_EXPIRATION_MANAGEMENT = "guestsExpirationManagement",
  DEVICE_MANAGEMENT = "deviceManagement",
  DEVICE_COMPLIANCE_NOTIFICATION = "deviceComplianceNotification",
  DLP_MANAGEMENT = "dlpManagement",
  TEAM_PROFILE = "teamProfile",

  // Alerting
  ADVANCED_QUERY_BUILDER = "advancedQueryBuilder",

  // Connectors
}

export interface navigationElement {
  name: string;
  rootAction?: boolean;
  icon?: string;
  route?: string;
  permissions?: Array<string>;
  orPermissions?: Array<string>;
  children?: Array<navigationElement>;
  state?: string;
  preview?: string;
  flags?: string[];
  orgType?: OrgTypeEnum[];
  services?: string[];
}
