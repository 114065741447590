<div
  class="snackbar-drawer-content"
  [class.snackbar-drawer-mobile]="mobileService.isMobile()"
>
  <div
    *ngFor="let snack of snacks$ | async; let last = last"
    class="snack"
    [ngClass]="{
      delicious: snack.taste === 'Delicious',
      inedible: snack.taste === 'Inedible',
      normal: snack.taste === 'Normal',
      show: !snack.hide,
      'last-element': last
    }"
  >
    <div class="snack-title">
      <b>{{ snack.message }}</b>
      <button
        ui-button
        [color]="
          snack.taste === 'Delicious'
            ? 'snackbarFlatPrimary'
            : 'snackbarFlatWhite'
        "
        icon="close-circle"
        (click)="throwSnackInTrash(snack)"
      ></button>
    </div>
    <div *ngIf="snack.secondMessage" class="second-message">
      {{ snack.secondMessage }}
    </div>
  </div>
</div>
