<div class="tag">
  <span class="tag--name">{{ hubSiteName }}</span>
  <ui-icon
    *ngIf="isEditing"
    class="tag--delete"
    size="medium"
    modifier="on-header"
    name="close-circle"
    (click)="deleteHubSite()"
  ></ui-icon>
</div>
