<div class="autocomplete--container">
  <label
    *ngIf="label"
    ui-label
    [name]="name"
    [required]="required"
  >{{ label }}</label>
  <div class="flex-box">
    <div [style]="
        includeResourceFilter &&
        autocompleteType === autocompleteTypes.RESOURCES
          ? 'width:60%'
          : 'width:100%'
      ">
      <div
        class="autocomplete-input"
        [ngClass]="{ disabled: disabled }"
      >
        <img
          *ngIf="showSearchIcon"
          class="autocomplete-input--icon"
          src="{{ 'assets/favicons/icon_search.svg' }}"
        />
        <input
          class="autocomplete--value"
          ui-input
          [class.autocomplete--value-error]="isInError"
          [disabled]="disabled"
          [class.disabled]="disabled"
          [name]="name"
          [(ngModel)]="searchQuery"
          [placeholder]="placeholder"
          (ngModelChange)="searchQueryUpdate.next($event)"
          (click)="onInputClick($event)"
          (focus)="onInputFocus()"
          (blur)="onBlurManager()"
          (input)="onInputTyping()"
          autocomplete="off"
          spellcheck="false"
          [maxlength]="getInputMaxLength()"
        />
        <img
          *ngIf="searchQuery !== '' && !disabled"
          class="close-margin"
          src="assets/favicons/icon_remove.svg"
          alt="X"
          (click)="onClearButtonClicked($event)"
        />
      </div>

      <ng-container *ngIf="showSuggestions && !disabled">
        <div [ngSwitch]="autocompleteType">
          <user-offline-autocomplete
            #autocompleteSuggestions
            *ngSwitchCase="autocompleteTypes.USERS_OFFLINE"
            [offlineUsers]="offlineUsers"
            [canMultiSelect]="canMultiSelect"
            [idsToIgnore]="itemsToIgnore"
            [locale]="locale"
            [expandHeight]="expandHeight"
            (elementClicked)="onItemClick($event.item, $event.itemName)"
            (loadedList)="loadedList.emit($event)"
          ></user-offline-autocomplete>
          <user-autocomplete
            [locale]="locale"
            #autocompleteSuggestions
            [searchFunction]="searchFunction"
            *ngSwitchCase="autocompleteTypes.USERS"
            [(selectedUsers)]="selectedItemsValue"
            (elementClicked)="onItemClick($event.item, $event.itemName)"
            [customNotFound]="customNotFound"
            [usersFilter]="filter"
            (isSearching)="isSearching = $event"
            [oneItemAtATime]="oneItemAtATime"
            [canMultiSelect]="canMultiSelect"
            [usersToIgnore]="itemsToIgnore"
            [expandHeight]="expandHeight"
            [disabledLabel]="disabledLabel"
            (loadedList)="loadedList.emit($event)"
          ></user-autocomplete>
          <custom-autocomplete
            #autocompleteSuggestions
            *ngSwitchCase="autocompleteTypes.CUSTOM"
            [searchLimit]="searchLimit"
            [(selectedValues)]="selectedItemsValue"
            (elementClicked)="onItemClick($event.item, $event.itemName)"
            [oneItemAtATime]="oneItemAtATime"
            [customValues]="customValues"
            [canMultiSelect]="canMultiSelect"
            [imageWithBorder]="imageWithBorder"
            [maxHeight]="maxHeight"
            (isSearching)="isSearching = $event"
            [expandHeight]="expandHeight"
            [locale]="locale"
            [customNotFound]="customNotFound"
            [itemsToIgnore]="itemsToIgnore"
          ></custom-autocomplete>
          <organization-autocomplete
            #autocompleteSuggestions
            *ngSwitchCase="autocompleteTypes.ORGANIZATIONS"
            [searchFunction]="searchFunction"
            [(selectedValues)]="selectedItemsValue"
            (elementClicked)="onItemClick($event.item, $event.itemName)"
            [oneItemAtATime]="oneItemAtATime"
            [customValues]="customValues"
            [canMultiSelect]="canMultiSelect"
            [imageWithBorder]="imageWithBorder"
            (isSearching)="isSearching = $event"
            [expandHeight]="expandHeight"
            [allSelectorCustomValue]="allSelectorCustomValue"
          ></organization-autocomplete>
          <resource-autocomplete
            #autocompleteSuggestions
            *ngSwitchCase="autocompleteTypes.RESOURCES"
            [searchFunction]="searchFunction"
            [(selectedResources)]="selectedItemsValue"
            (elementClicked)="onItemClick($event.item, $event.itemName)"
            [customNotFound]="customNotFound"
            (isSearching)="isSearching = $event"
            [oneItemAtATime]="oneItemAtATime"
            [canMultiSelect]="canMultiSelect"
            [groupsFilter]="filter"
            [minNumLicenses]="minNumLicenses"
            [profileGuid]="profileGuid"
            [rolesOnly]="filterRolesOnly"
            [searchOnlyUserResources]="searchOnlyUserResources"
            [locale]="locale"
            [expandHeight]="expandHeight"
          ></resource-autocomplete>
        </div>

        <div
          *ngIf="isSearching"
          class="choices-list"
        >
          <ui-spinner
            class="loading-animation"
            size="medium"
            modifier="secondary"
          ></ui-spinner>
        </div>
      </ng-container>
    </div>
  </div>
</div>
