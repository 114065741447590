import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";

@Component({
  selector: 'ui-table-search-tool',
  templateUrl: './ui-table-search-tool.component.html',
  styleUrls: [ './ui-table-search-tool.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-table-search-tool',
  },
})
export class UiTableSearchTool implements OnInit {
  @Input() private readonly DEBOUNCE_TIME = 300;

  @Input() public withIcon = true;

  @Input() public query: string | null = null;

  @Input() public placeholder = 'ui.table-search-tool.placeholder';

  @Input() public tooltipText: string = null;

  @Input() public tooltipPlacementRight: boolean;

  @Output('clear') public cleared: EventEmitter<void> = new EventEmitter<void>();

  @Output('query') public queried: EventEmitter<string | null> = new EventEmitter<string | null>();

  @HostListener('keydown.escape', [ '$event' ])
  onEscapeKey(event: KeyboardEvent) {
    if (this.hasValue) {
      event.stopPropagation();
      this.handleClear();
    }
  }

  public hasValue = false;

  public showTooltip: boolean;

  public debounceTimeout = null;

  public ngOnInit(): void {
    this.hasValue = !!this.query;
    this.setShowTooltip();
  }

  public handleClear(): void {
    this.hasValue = false;
    this.cleared.emit();
    setTimeout(() => {
      this.setShowTooltip();
    }, 1000);
  }

  public handleQuery({ target }): void {
    clearTimeout(this.debounceTimeout);

    this.debounceTimeout = setTimeout(() => {
      const { value } = target;

      this.hasValue = !!value.length;
      this.setShowTooltip();

      if (!value.length) {
        this.queried.emit(null);
        return;
      }

      this.queried.emit(value);
    }, this.DEBOUNCE_TIME);
  }

  private setShowTooltip(): void {
    this.showTooltip = this.tooltipText && !this.hasValue;
  }
}
