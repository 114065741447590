import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MobileService {
  public width = window.innerWidth;

  constructor() {
    window.addEventListener('resize', () => {
      this.width = window.innerWidth;
    });
  }


  public get currentWidth(): number {
    return this.width;
  }

  public get isIOS(): boolean {
    return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform);
  }

  public isMobile(width?: number) {
    const ua = navigator.userAgent;
    return (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua) ||
      this.width < (width || 720)
    );
  }

  public windowWidthSmallerThan(width: number): boolean {
    return this.isMobile(width);
  }
}
