import { ViewportRuler } from '@angular/cdk/overlay';
import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { UsersApiService } from '@common/services/api/sg/user/userApi.service';
import { TeamsAuthService } from '@common/services/teams-auth.service';
import { Select, Store } from '@ngxs/store';
import { IamApiService } from 'projects/@common/services/api/iam/iam.api';
import { IdentitiesApi } from 'projects/@common/services/api/iam/identities/identities.api';
import { NotificationsApiService } from 'projects/@common/services/api/notifications/notifications-api.service';
import { LocalStorageKeysEnum, StorageService } from 'projects/@common/services/storage.service';
import { Observable, Subscription } from 'rxjs';
import { MobileService } from '../../services/mobile.service';
import { EcoSessionState, SetUserNotificationEmail, SetVarMode } from '../session/state/session.state';
import { DrawerService } from './components/drawer/services/drawer.service';
import { DrawerState } from './components/drawer/stores/drawer.store';
import { EmailInitializerComponent } from './components/email-initializer/email-initializer.component';
import { OrgTypeEnum } from './definitions/base-sidebar.service';
import { AppState, AppStatus, ResetPopover, SetAppStatus, SetExpanded } from './stores/app.state';

export enum ConsoleType {
  SG = "sg",
  ORG = "org",
  ADM = "adm"
}
@Component({
  selector: 'section.private-layout',
  templateUrl: './private.layout.html',
  styleUrls: ['./private.layout.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PrivateLayout implements OnInit, OnDestroy {
  @ViewChild('drawer', { static: true }) drawer: MatSidenav;

  @Select(AppState.isReady) public isReady$: Observable<boolean>;

  @Select(AppState.title) public title$: Observable<string>;

  @Select(AppState.isExpanded) public isExpanded$: Observable<boolean>;

  @Select(DrawerState.isEditing) public isEditing$: Observable<boolean>;

  @Select(DrawerState.isExpanded) public isDrawerExpanded$: Observable<boolean>;

  @Select(DrawerState.isFullWidth) public isDrawerFullWidth$: Observable<boolean>;

  private routerEventsSubscription: Subscription;

  private ruleChangeSubscription: Subscription;

  private currentOrganizationSubscription: Subscription;

  public constructor(
    public mobileService: MobileService,
    private drawerService: DrawerService,
    private iam: IamApiService,
    private storageService: StorageService,
    private teamsAuthService: TeamsAuthService,
    private usersApiService: UsersApiService,
    protected readonly store: Store,
    protected readonly ruler: ViewportRuler,
    protected readonly router: Router,
    protected readonly notificationsApiService: NotificationsApiService,
    protected readonly dialog: MatDialog,
    protected readonly identityApi: IdentitiesApi,
    @Inject('DISABLE_HEADER') public disableHeader: any,
    @Inject('DISABLE_SIDEBAR') public disableSidebar: any,
    @Inject('CONSOLE_TYPE') public consoleType: ConsoleType
  ) {
    this.routerEventsSubscription = router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.drawerService.hideDrawer();
      }
    });
  }

  ngOnDestroy(): void {
    this.routerEventsSubscription?.unsubscribe();
    this.ruleChangeSubscription?.unsubscribe();
    this.currentOrganizationSubscription?.unsubscribe();
  }

  public async ngOnInit(): Promise<void> {
    this.mobileService.isMobile();
    this.ruleChangeSubscription = this.ruler.change().subscribe(this.handleResize.bind(this));

    const varOrg = this.store.selectSnapshot(EcoSessionState.organization).tags.includes(OrgTypeEnum.VAR);
    const result = await this.iam.describePermissions({
      queries: [
        {
          id: 'isVar',
          action: 'detect:ListOrganizations',
          resourceLabel: 'md:*::detect:organizations/all',
        },
      ],
    });

    await this.store.dispatch(new SetVarMode(result[0].shouldShow && varOrg && this.consoleType !== ConsoleType.SG)).toPromise();

    if (this.consoleType !== ConsoleType.SG) {
      const notificationIdentity = await this.notificationsApiService.getUserIdentity();

      if (!notificationIdentity.confirmed) {
        const idpIdentity = await this.identityApi.describeIdpIdentity();
        this.dialog.open(EmailInitializerComponent, {
          disableClose: true,
          width: '500px',
          panelClass: 'custom-dialog',
          data: {
            email: idpIdentity.mail,
          },
        });
      } else {
        await this.store.dispatch(new SetUserNotificationEmail(notificationIdentity.email)).toPromise();
      }
    }

    this.loadDefaultSidebarCollapsedState();

    await this.store.dispatch(new SetAppStatus({ status: AppStatus.Ready })).toPromise();

    if (TeamsAuthService.isInTeams) {
      this.updateUserNotificationLanguage();
    }
  }

  // Dans l'app team, on vient synchroniser la langue du Teams du user pour avoir la bonne durant les notifications du BOT.
  private updateUserNotificationLanguage(): void {
    const userTeamLanguage = this.teamsAuthService?.locale;

    if (!userTeamLanguage) {
      return;
    }

    const currentNotificationLanguage = this.storageService.getLocal(LocalStorageKeysEnum.NotificationLanguage);

    const userGuid = this.store.selectSnapshot(EcoSessionState.userGuid);
    let userLocale: { userId: string, locale: string; };

    if (currentNotificationLanguage) {
      try {
        userLocale = JSON.parse(currentNotificationLanguage);
      } catch (e) {
        userLocale = null;
      }
    }

    if (userLocale?.userId !== userGuid || userTeamLanguage !== userLocale.locale) {
      this.usersApiService.updateUserNotificationLanguage(userTeamLanguage)
        .subscribe(() => {
          const localeObject = { userId: userGuid, locale: userTeamLanguage };
          this.storageService.setLocal(LocalStorageKeysEnum.NotificationLanguage, JSON.stringify(localeObject));
        });

    }
  }

  public handleResize(): void {
    if (this.store.selectSnapshot((state) => state.app.popover) !== null) {
      this.store.dispatch(new ResetPopover()).toPromise();
    }
  }

  public async handleToggleMenu(): Promise<void> {
    const isExpanded = !this.store.selectSnapshot((state) => state.app.expanded);
    this.store.dispatch(new SetExpanded({ expanded: isExpanded }));
    this.storageService.setSidebarCollapsedState(!isExpanded);
  }

  private loadDefaultSidebarCollapsedState(): void {
    const isSidebarCollapsed = this.storageService.getSidebarCollapsedState();
    this.store.dispatch(new SetExpanded({ expanded: !isSidebarCollapsed }));
  }
}
