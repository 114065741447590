<div *ngIf="label" class="flex-box">
  <label ui-label>{{ label }}</label>
  <ng-content></ng-content>
</div>

<ng-container *ngIf="!isLoading">
  <div *ngIf="severity && !customText" [class]="severityChipClasses">
    {{ 'equipement.severity.' + severity.toLowerCase() }}
  </div>

  <div *ngIf="severity && customText" [class]="severityChipClasses">
    {{ customText }}
  </div>

  <div *ngIf="!severity">-</div>
</ng-container>

<ng-container *ngIf="isLoading">
  <div class="spinner">
    <ui-spinner size="small" modifier="secondary"></ui-spinner>
  </div>
</ng-container>