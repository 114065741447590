<autocomplete
  [required]="required"
  [label]="label"
  [placeholder]="'common.timezone' | t"
  [autocompleteType]="customAutocompleteType"
  [customValues]="timezoneOptions"
  [selectedItems]="selectedTimezones"
  [initItems]="selectedTimezones"
  [showInInputAtInit]="true"
  [canMultiSelect]="false"
  [oneItemAtATime]="true"
  (onItemSelected)="handleTimezoneSelection($event)"
></autocomplete>