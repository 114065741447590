<ng-container *ngIf="formDetails && formDetails.fields">
  <ng-container *ngFor="let field of formDetails.fields">
    <ui-dynamic-form-field
      [formGroup]="form"
      [field]="field"
      [locale]="locale"
      [readonly]="readonly"
    >
    </ui-dynamic-form-field>
  </ng-container>
</ng-container>
