import { Component, Input } from "@angular/core";

@Component({
  selector: 'app-edit-with-modal',
  templateUrl: './edit-with-modal.component.html',
  styleUrls: [ './edit-with-modal.component.scss' ],
})
export class EditWithModalComponent {
  @Input() noMargin = false;

  @Input() leftOrientation = false;

  private _isOpen = false;

  public get openState(): boolean {
    return this._isOpen;
  }

  constructor() { }

  public open(): void {
    this._isOpen = true;
  }

  public close(): void {
    this._isOpen = false;
  }

  public toggle(): void {
    this._isOpen = !this._isOpen;
  }

  public isOpen(): boolean {
    return this._isOpen;
  }
}


