<div class="ui-message-band--container">
  <div>
    {{ messageFirst }}
  </div>
  <div onclick="click" class="ui-message-band--clickable">
    {{ messageClickable }}
  </div>
  <div>
    {{ messageThird }}
  </div>
</div>
