import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/@common/environments/environment';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { SessionService } from 'projects/@common/modules/session/session.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TeamsAuthService } from '../../teams-auth.service';

@Injectable()
export class EcoErrorInterceptor implements HttpInterceptor {
  constructor (
    private session: SessionService,
    private notice: NoticeService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
  Observable<HttpEvent<any>> {
    if (!this._isEcoApi(req.url)) {
      return next.handle(req);
    }

    return next.handle(req)
      .pipe(catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          if (!TeamsAuthService.isInTeams) {
            this.notice.notifyUser(new Notice(`Invalid credentials`, NoticeLevels.ERROR));
          }

          this.session.invalidateSession();
          this.session.redirectToLogin();
        }
        error["trace"] = req.headers.get("X-Client-Trace-Id");
        console.log(`Trace: ${req.headers.get("X-Client-Trace-Id")}`);

        return throwError(error);
      }));
  }

  private _isEcoApi(url: string): boolean {
    return !!Object.values(environment.ecoUrls).find((ecoUrl) => url.includes(ecoUrl));
  }
}
