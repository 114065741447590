export enum ResourceTypeEnum {
  GROUP = 'GROUP',
  APPLICATION = 'APPLICATION',
  ROLE = 'ROLE',
  ONEDRIVE = 'ONEDRIVE'
}

export enum RequestStatusEnum {
  PENDING = 'PENDING',
  PENDING_OTHER = 'PENDING_OTHER',
  PENDING_MANAGER = 'PENDING_MANAGER',
  PENDING_OWNER = 'PENDING_OWNER',
  PENDING_ADMIN = 'PENDING_ADMIN',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  CREATED = 'CREATED',
  ERROR = 'ERROR'
}


export enum FileTypeEnum {
  FILE = 'File',
  FOLDER = 'Folder'
}

export enum GroupTypeEnum {
  LOCAL = 'local',
  O365FILES_LIBRARY = 'o365FilesLibrary',
  O365FILES_LIBRARY_I_AM_OWNER = 'o365FilesLibraryIAmOwner',
  O365LIBRARY = 'o365Library',
  O365LIBRARY_I_AM_OWNER = 'o365LibraryIAmOwner',
  O365SECURITYGROUP = 'o365SecurityGroup',
  O365OFFICEGROUP = 'o365OfficeGroup',
  O365OFFICETEAMS = 'o365OfficeTeams',
  O365CHANNEL = 'o365Channel',
  O365LICENSE = 'o365License',
  O365SITE = "o365Site",
  I_AM_MEMBER = 'i_am_member',
  I_AM_OWNER = 'i_am_owner',
  ALL = "allGroups",
  EXTERNAL = "external",
  PUBLIC = "public",
  MONITORING = "monitoring",
  WEB_SITE = "web_site",
  APPLICATION = "application",
  NOT_CHANNELS = 'notChannels',
  LICENSES_AVAILABLE = 'licensesAvailable'
}

export enum OfficeIconEnum {
  OFFICE_WORD_ICON = 'icon_file_word',
  OFFICE_PPT_ICON = 'icon_file_ppt',
  OFFICE_EXCEL_ICON = 'icon_file_excel',
  OFFICE_ONENOTE_ICON = 'icon_file_note',
  UNKNOWN_ICON = 'icon_file_empty',
  FOLDER_ICON = 'icon_folder',
  ZIP_ICON = 'icon_file_zip',
  PDF_ICON = 'icon_file_pdf',
  IMAGE_ICON = 'icon_file_image',
  DRAWIO_ICON = 'icon_file_drawsio',
  HTML_ICON = 'icon_file_html',
  TEXT_ICON = 'icon_file_text',
  ACROBAT_ICON = 'icon_file_acrobat',
  VIDEO_ICON = 'icon_file_video',
  LOOP_ICON = 'icon_loop'
}

export enum FileExtensionEnum {
  OFFICE_WORD_FILETYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  OFFICE_POWERPOINTDOCUMENT_FILETYPE = 'application/vnd.openxmlformats-officedocument.presentationml.document',
  OFFICE_EXCEL_FILETYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  OFFICE_POWERPOINTPRESENTATION_FILETYPE = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  OFFICE_ONENOTE_FILETYPE = 'application/msonenote',
  ZIP_FILETYPE = 'application/zip',
  LOOP = 'loop',
  OFFICE_WORD = 'docx',
  OFFICE_POWERPOINTDOCUMENT = 'pptx',
  OFFICE_EXCEL = 'xlsx',
  OFFICE_EXCEL_SHORT = 'xls',
  OFFICE_EXCEL_MIME = 'application/vnd.ms-excel',
  OFFICE_POWERPOINTPRESENTATION = 'pptm',
  OFFICE_ONENOTE = 'one',
  ZIP = 'zip',
  IMAGE_JPEG = 'image/jpeg',
  JPEG = 'jpeg',
  JPG = 'jpg',
  PNG = 'png',
  WEBP = 'webp',
  TIFF = 'tiff',
  PDF = 'pdf',
  GIF = 'gif',
  GIF_MIME = 'image/gif',
  DRAWIO = 'drawio',
  HTML = 'html',
  TEXT = 'txt',
  TEXT_MIME = 'text/plain',
  MP3 = 'mp3',
  MP4 = 'mp4',
  MP4_MIME = 'video/mp4',
  MOV = 'mov',
  AVI = 'avi',
  WMV = 'wmv',
  FLV = 'flv',
  WEBM = 'webm',
  MKV = 'mkv'
}

export class IconUtil {
  public static readonly ICON_PATH = 'assets/favicons/files/';

  public static readonly ICON_EXTENSION = '.svg';

  public static readonly MOBILE = 'mobile';

  public static readonly ANDROID = 'android';

  public static readonly IOS = 'ios';

  public static readonly IPHONEOS = 'iphone os';

  public static getDeviceIcon(deviceOS: string, browser: string): string {
    if (
      browser?.toLocaleLowerCase().includes(this.MOBILE) ||
      deviceOS?.toLocaleLowerCase().includes(this.ANDROID) ||
      deviceOS?.toLocaleLowerCase().includes(this.IOS) ||
      deviceOS?.toLocaleLowerCase().includes(this.IPHONEOS)
    ) {
      return 'mobile';
    }
    return 'monitor';
  }

  public static getFileIconFromFileExtension(type: string): string {
    let iconname = '';
    if (type && type !== FileTypeEnum.FOLDER) {
      switch (type.toLowerCase()) {
        case FileExtensionEnum.OFFICE_WORD_FILETYPE:
        case FileExtensionEnum.OFFICE_WORD:
          iconname = OfficeIconEnum.OFFICE_WORD_ICON;
          break;

        case FileExtensionEnum.OFFICE_EXCEL_FILETYPE:
        case FileExtensionEnum.OFFICE_EXCEL:
        case FileExtensionEnum.OFFICE_EXCEL_SHORT:
        case FileExtensionEnum.OFFICE_EXCEL_MIME:
          iconname = OfficeIconEnum.OFFICE_EXCEL_ICON;
          break;

        case FileExtensionEnum.OFFICE_POWERPOINTDOCUMENT_FILETYPE:
        case FileExtensionEnum.OFFICE_POWERPOINTPRESENTATION_FILETYPE:
        case FileExtensionEnum.OFFICE_POWERPOINTDOCUMENT:
        case FileExtensionEnum.OFFICE_POWERPOINTPRESENTATION:
          iconname = OfficeIconEnum.OFFICE_PPT_ICON;
          break;

        case FileExtensionEnum.OFFICE_ONENOTE_FILETYPE:
        case FileExtensionEnum.OFFICE_ONENOTE:
          iconname = OfficeIconEnum.OFFICE_ONENOTE_ICON;
          break;

        case FileExtensionEnum.ZIP_FILETYPE:
        case FileExtensionEnum.ZIP:
          iconname = OfficeIconEnum.ZIP_ICON;
          break;

        case FileExtensionEnum.IMAGE_JPEG:
        case FileExtensionEnum.JPEG:
        case FileExtensionEnum.JPG:
        case FileExtensionEnum.PNG:
        case FileExtensionEnum.TIFF:
        case FileExtensionEnum.GIF:
        case FileExtensionEnum.GIF_MIME:
        case FileExtensionEnum.WEBP:
          iconname = OfficeIconEnum.IMAGE_ICON;
          break;

        case FileExtensionEnum.PDF:
          iconname = OfficeIconEnum.ACROBAT_ICON;
          break;

        case FileExtensionEnum.DRAWIO:
          iconname = OfficeIconEnum.DRAWIO_ICON;
          break;

        case FileExtensionEnum.HTML:
          iconname = OfficeIconEnum.HTML_ICON;
          break;

        case FileExtensionEnum.TEXT:
        case FileExtensionEnum.TEXT_MIME:
          iconname = OfficeIconEnum.TEXT_ICON;
          break;

        case FileExtensionEnum.MP3:
        case FileExtensionEnum.MP4:
        case FileExtensionEnum.MP4_MIME:
        case FileExtensionEnum.MOV:
        case FileExtensionEnum.AVI:
        case FileExtensionEnum.WMV:
        case FileExtensionEnum.FLV:
        case FileExtensionEnum.WEBM:
        case FileExtensionEnum.MKV:
          iconname = OfficeIconEnum.VIDEO_ICON;
          break;

        case FileExtensionEnum.LOOP:
          iconname = OfficeIconEnum.LOOP_ICON;
          break;

        default:
          iconname = OfficeIconEnum.UNKNOWN_ICON;
          break;
      }
    } else {
      iconname = OfficeIconEnum.FOLDER_ICON;
    }

    return this.ICON_PATH + iconname + this.ICON_EXTENSION;
  }

  public static getResourceAvatarIcon(resource: any, withPrefix = true): string {
    if (resource?.logoUrl) {
      return resource?.logoUrl;
    }
    let iconPath = '';
    if (resource.resourceType === ResourceTypeEnum.ROLE) {
      iconPath = `icon_resource_avatar_role.svg`;
    } else if (resource.resourceType === ResourceTypeEnum.ONEDRIVE) {
      iconPath = `icon_OneDrive.svg`;
    } else {
      switch (resource.groupType) {
        case GroupTypeEnum.O365OFFICETEAMS:
          iconPath = `icon_resource_avatar_teams.svg`;
          break;

        case GroupTypeEnum.O365OFFICEGROUP:
          iconPath = `icon_resource_avatar_sharepoint.svg`;
          break;

        case GroupTypeEnum.O365LICENSE:
          iconPath = `icon_resource_avatar_license.svg`;
          break;

        case GroupTypeEnum.EXTERNAL:
          iconPath = `icon_resource_avatar_external.svg`;
          break;

        case GroupTypeEnum.WEB_SITE:
          iconPath = `icon_resource_avatar_website.svg`;
          break;

        case GroupTypeEnum.MONITORING:
          iconPath = `icon_resource_avatar_monitoring.svg`;
          break;

        case GroupTypeEnum.O365SECURITYGROUP:
          iconPath = `icon_resource_avatar_security_groups.svg`;
          break;

        case GroupTypeEnum.APPLICATION:
          iconPath = `icon_resource_avatar_application.svg`;
          break;

        case GroupTypeEnum.O365CHANNEL:
          iconPath = `icon_resource_avatar_private_channel.svg`;
          break;

        case GroupTypeEnum.O365SITE:
          iconPath = `icon_resource_avatar_office.svg`;
          break;

        default:
          return '';
      }
    }

    return withPrefix ? `assets/favicons/${iconPath}` : iconPath;
  }

  public static getRequestStatusIcon(requestStatus: RequestStatusEnum, canNoLongerTakeAction?: boolean) {
    if (canNoLongerTakeAction) {
      return 'favicons/icon_non_applicable';
    }

    switch (requestStatus) {
      case RequestStatusEnum.APPROVED:
        return 'favicons/icon_success';
      case RequestStatusEnum.DECLINED:
        return 'favicons/icon_stop';
      case RequestStatusEnum.CANCELED:
        return 'favicons/icon_non_applicable';
      case RequestStatusEnum.EXPIRED:
        return 'favicons/icon_timer';
      case RequestStatusEnum.ERROR:
        return 'favicons/icon_error';
      case RequestStatusEnum.CREATED:
      case RequestStatusEnum.PENDING:
      case RequestStatusEnum.PENDING_ADMIN:
      case RequestStatusEnum.PENDING_MANAGER:
      case RequestStatusEnum.PENDING_OTHER:
      case RequestStatusEnum.PENDING_OWNER:
        return 'favicons/icon_in_progress';
      default:
        return '';
    }
  }
}
