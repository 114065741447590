import { Pipe, PipeTransform } from '@angular/core';
import { LanguageEnum } from '../interfaces/ILanguage';

@Pipe({
  name: 'resourceTranslation',
  pure: false,
})
export class ResourceTranslationPipe implements PipeTransform {
  public constructor() {
  }

  public transform(resource: any, params: { fieldName: string, locale: LanguageEnum }): string | null {
    if (resource) {
      const localizedFieldName = `${params.fieldName}_${params.locale}`;
      return resource[localizedFieldName] ? resource[localizedFieldName] : resource[params.fieldName];
    }
    return ' ';
  }
}
