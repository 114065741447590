<div class="language-selector">
  <ng-container *ngFor="let option of languageOptions; let last = last">
    <ng-template
                 [ngTemplateOutlet]="languageOption"
                 [ngTemplateOutletContext]="{ option: option }"></ng-template>
    <p *ngIf="!last" class="language-selector--separator">|</p>
  </ng-container>
</div>

<ng-template #languageOption let-option="option">
  <p
     [ngClass]="{
      'language-selector--selectable': language !== option.language
    }"
     (click)="setLanguage(option.language)">
    {{ option.label }}
  </p>
</ng-template>