import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-modal',
  templateUrl: './ui-modal.component.html',
  styleUrls: [ './ui-modal.component.scss' ],
})
export class UiModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
