<div
  *ngFor="let popup of popups$ | async"
  class="mouse-popup-message"
  [ngStyle]="{
    top: popup?.location?.y - 30 + 'px',
    left: popup?.location?.x + 20 + 'px'
  }"
  [ngClass]="{
    show: !popup?.hide
  }"
>
  {{ popup?.message }}
</div>
