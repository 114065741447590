import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-hubsite-tag',
  templateUrl: './ui-hubsite-tag.component.html',
  styleUrls: [ './ui-hubsite-tag.component.scss' ],
})
export class HubsiteTagComponent {
  @Input() public hubSiteName: string;

  @Input() public isEditing: boolean;

  @Output() public onDeleteHubsite: EventEmitter<void> = new EventEmitter();

  constructor() {}

  public deleteHubSite() {
    this.onDeleteHubsite.emit();
  }
}
