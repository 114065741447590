import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { UiKitModule } from '@ui-kit/uikit.module';
import { Snackbar } from './implementations/snackbar/component/snackbar.component';
import { SnackbarDrawer } from './implementations/snackbar/drawer/snackbar-drawer.component';
import { SnackbarService } from './implementations/snackbar/snackbar.service';
import { SnackbarDrawerState } from './implementations/snackbar/state/snackbar-drawer.state';
import { SnackbarState } from './implementations/snackbar/state/snackbar.state';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      SnackbarState,
      SnackbarDrawerState,
    ]),
    UiKitModule,
  ],
  declarations: [
    Snackbar,
    SnackbarDrawer,
  ],
  exports: [
    Snackbar,
    SnackbarDrawer,
  ],
})
export class NoticeModule {
  constructor (snack: SnackbarService // Not used but injected to make sure the service is instantiated
  ) { }
}
