import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-table-shortid-search',
  templateUrl: './ui-table-shortid-search.component.html',
  styleUrls: [ './ui-table-shortid-search.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-table-shortid-search',
  },
})
export class UiTableShortidSearchComponent implements OnInit {
  public readonly MAX_LENGTH = 12;

  public query: string = null;

  public shortIdRegex: RegExp;

  private readonly DEBOUNCE_TIME = 700;

  private debounceTimeout = null;

  @Input() public defaultQuery: string = null;

  @Input() public prefix: string;

  @Input() public placeholder = 'ui.table-search-tool.placeholder';

  @Input() public removeSearchIcon = false;

  @Input() public useExactMatchRegex = false;

  @Output() public clearEvent = new EventEmitter<void>();

  @Output() public queryEvent = new EventEmitter<number>();

  @HostListener('keydown.escape', [ '$event' ])
  onEscapeKey(event: KeyboardEvent) {
    if (this.hasValue) {
      event.stopPropagation();
      this.handleClear();
    }
  }

  public ngOnInit(): void {
    this.initShortIdRegex(this.prefix);
    if (this.defaultQuery && this.shortIdRegex.test(this.defaultQuery)) {
      this.query = this.defaultQuery;
    }
  }

  public static cleanShortId(shortId: string): number {
    const cleanString = shortId?.replace(/\D+/g, ''); // Remove non-digit chars. Like ALR-00023786 --> 00023786
    if (!cleanString) {
      return null;  // Don't parse a falsy value to avoid the NaN return value
    }
    const cleanNumber = parseInt(cleanString); //  Ensure clearing leading 0's. Like 00023786 --> 23786
    return cleanNumber;
  }

  public get hasValue(): boolean {
    return Boolean(this.query?.length);
  }

  public get isValid(): boolean {
    return Boolean(this.hasValue && this.shortIdRegex.test(this.query));
  }

  public handleClear(): void {
    this.query = null;
    this.clearEvent.emit();
  }

  public handleQuery({ target }): void {
    clearTimeout(this.debounceTimeout);

    this.debounceTimeout = setTimeout(() => {
      if (this.isValid) {
        const shortIdNumber = this.getFormattedQuery();
        this.queryEvent.emit(shortIdNumber);
      } else if (!this.hasValue) {
        this.handleClear();
      }
    }, this.DEBOUNCE_TIME);
  }

  private initShortIdRegex(providedPrefix: string): void {
    if (!new RegExp("^[A-Z]{3}$").test(providedPrefix)) {
      throw new Error("Provide a valid ShortID prefix of 3 letters like 'ALR' | 'INC'.");
    }
    const looseMatchExpression = String.raw`(^${providedPrefix}-?\d{1,8}$)|(^\d{1,8})`;
    const exactMatchExpression = String.raw`(^${providedPrefix}-?\d{8}$)`;
    this.shortIdRegex = this.useExactMatchRegex ? new RegExp(exactMatchExpression) : new RegExp(looseMatchExpression, "i");
  }

  private getFormattedQuery(): number {
    return UiTableShortidSearchComponent.cleanShortId(this.query);
  }
}
