import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ContentChildren,
  EventEmitter,
  Output,
  QueryList,
  OnInit,
  ViewChild,
  Input
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { LanguageEnum } from '../../interfaces/ILanguage';
import { UiStep } from './ui-step.directive';

@Component({
  selector: 'ui-stepper',
  templateUrl: './ui-stepper.component.html',
  styleUrls: [ './ui-stepper.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: false,
})
export class UiStepper implements OnInit {
  @ContentChildren(UiStep) public steps: QueryList<UiStep>;

  @Output() public onCancel: EventEmitter<void> = new EventEmitter();

  @Output() public onSave: EventEmitter<void> = new EventEmitter();

  @ViewChild('stepper') private stepper: MatStepper;

  @Input() public stepperHeight = '100%';

  @Input() public isFormValid = false;

  @Input() public isSaving = false;

  @Input() public locale: LanguageEnum = LanguageEnum.FRENCH;

  public isLoadingButtons = true;

  public ngOnInit() {
    // Since we have conditions on buttons, Material Stepper takes a time to integrate de conditions and show all the buttons at the init.
    // We make it wait a little bit so the buttons don't move and bug.
    setTimeout(() => {
      this.isLoadingButtons = false;
    }, 100);
  }

  public cancel() {
    this.onCancel.emit();
  }

  public save() {
    this.onSave.emit();
  }

  public goBack() {
    this.stepper.previous();
  }

  public goForward() {
    this.stepper.next();
  }
}
