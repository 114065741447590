import { Injectable, OnDestroy } from '@angular/core';
import { CacheService, Providers, ProviderState, SimpleProvider } from '@microsoft/mgt-element';
import { interval, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { EcoSessionState } from '../modules/session/state/session.state';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class GraphToolkitService implements OnDestroy {
  private readonly INTERVAL = 10 /* minutes */ * 60 /* seconds */ * 1000 /* milliseconds */;

  private accessToken: string;

  private tokenSubscription: Subscription;
  @Select(EcoSessionState.accessToken) private _accessToken$: Observable<string>;

  constructor() {}

  ngOnDestroy() {
    this.tokenSubscription.unsubscribe();
  }

  public async activateGraphToolkit() {
    this._accessToken$.subscribe((token) => this.accessToken = token);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const provider = new SimpleProvider((scopes: string[]) => Promise.resolve(this.accessToken));

    provider.setState(ProviderState.SignedIn);

    Providers.globalProvider = provider;

    CacheService.config.isEnabled = false;

    this.refreshAccessToken();
  }

  private async getAccessToken() {
  }

  private refreshAccessToken() {
    if (!this.tokenSubscription) {
      this.tokenSubscription = interval(this.INTERVAL).subscribe(async () => {
        this.getAccessToken();
      });
    }
  }
}
