import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: [ './tag-list.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'tag-list',
  },
})
export class TagList implements OnInit {
  @Input() public label: string = null;

  @Input() public required: boolean;

  @Input() public isEditing: boolean;

  @Input() public tags: string[];

  @Input() public group: UntypedFormGroup;

  @Input() public controlName: string;

  @Input() public buttonLabel = 'common.action.add';

  @Input() public formatValue: (value: string) => string;

  @Input() public allowEmptyValue = true;

  public tagInputValue: string;

  public editableTags: string[];

  constructor() {}

  public ngOnInit() {
    if (this.tags == null) {
      this.tags = [];
    }
    this.editableTags = [ ...this.tags ];
  }

  public addTag() {
    const value = this.formatValue ? this.formatValue(this.tagInputValue) : this.tagInputValue;
    if (this.allowEmptyValue || value) {
      this.editableTags.push(value);
      this.tagInputValue = '';
      this.group.controls[this.controlName].setValue(this.editableTags);
    }
  }

  public deleteTag(deletedTag: string) {
    this.editableTags = this.editableTags.filter((tag) => tag !== deletedTag);
    this.group.controls[this.controlName].setValue(this.editableTags);
  }
}
