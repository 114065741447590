import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'th[resizable]',
  templateUrl: './ui-resizable-column.component.html',
  styleUrls: [ './ui-resizable-column.component.scss' ],
})
export class UiResizableColumnComponent implements OnInit {
  @HostBinding('style.width')
    width: string | null = null;

  @Input() public initialWidth: string | null = null;

  @HostBinding('style.min-width')
  @Input()
  public minWidth: string | null = null;

  @Input() isSortable = false;

  @Input() isResizable = true;

  ngOnInit() {
    this.width = this.initialWidth;
  }

  onResize(width: number) {
    this.width = `${width}px`;
  }
}
