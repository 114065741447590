import { Component, ElementRef, HostBinding, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { UiTabsActionsService } from '../services/ui-tabs-actions.service';

@Component({
  selector: 'ui-tabs-actions-content',
  templateUrl: './ui-tabs-actions-content.component.html',
  styleUrls: [ './ui-tabs-actions-content.component.scss' ],
  providers: [ UiTabsActionsService ],
  encapsulation: ViewEncapsulation.None,
})
export class UiTabsActionsContent {
  @HostBinding() public class = 'ui-tabs-actions-content';
  @Input() public tabsTitle: string;

  public areActionsShown = false;

  constructor(private readonly ref: ElementRef, private readonly uiTabsActionsService: UiTabsActionsService) {}

  @HostListener('document:click', [ '$event' ])
  public clickout(event: any) {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.uiTabsActionsService.hideActions();
    }
  }
}
