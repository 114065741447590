<ui-input-group [formGroup]="group">
  <ng-container
    *ngIf="arrayName"
    [formArrayName]="arrayName"
  >
    <ng-container [formGroupName]="groupName">

      <div class="flex-box">
        <label
          ui-label
          *ngIf="label"
          [name]="name"
          [required]="required"
        >
          {{ label }}
        </label>
        <ui-tooltip *ngIf="uiTooltip">{{uiTooltip}}</ui-tooltip>
      </div>

      <div class="date-picker">
        <input
          ui-input
          [name]="name"
          [formControlName]="control"
          [matDatepicker]="picker"
          (dateChange)="onDateChange($event)"
          [min]="minDate"
          [max]="maxDate"
          (click)="openMatPicker()"
          (keydown)="(false)"
          [disabled]="disabled"
          [readonly]="readonly"
          [placeholder]="placeholder"
        />
        <button
          ui-button
          class="calendar-button"
          [ngClass]="{'is-disabled': (disabled || readonly)}"
          (click)="openMatPicker()"
          custom-icon="true"
          icon="icon_calendar"
          [isSvg]="true"
          custom-icon-size="medium"
          size="small"
          color="listActionFlat"
        ></button>
      </div>

      <mat-datepicker
        #picker
        [disabled]="disabled"
        [readonly]="readonly"
      ></mat-datepicker>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!arrayName">
    <div class="flex-box">
      <label
        ui-label
        *ngIf="label"
        [name]="name"
        [required]="required"
      >
        {{ label }}
      </label>
      <ui-tooltip *ngIf="uiTooltip">{{uiTooltip}}</ui-tooltip>
    </div>

    <div class="date-picker">
      <input
        ui-input
        [name]="name"
        [formControlName]="control"
        [matDatepicker]="picker"
        (dateChange)="onDateChange($event)"
        [min]="minDate"
        [max]="maxDate"
        (click)="openMatPicker()"
        (keydown)="(false)"
        [disabled]="disabled"
        [readonly]="readonly"
        [placeholder]="placeholder"
      />
      <button
        ui-button
        class="calendar-button"
        [ngClass]="{'is-disabled': (disabled || readonly)}"
        (click)="openMatPicker()"
        custom-icon="true"
        icon="icon_calendar"
        [isSvg]="true"
        custom-icon-size="medium"
        size="small"
        color="listActionFlat"
      ></button>
      <button
        *ngIf="withClearButton"
        class="close-button"
        ui-button
        (click)="picker.select(null)"
        icon="close"
        size="small"
        color="drawerCloseFlat"
      ></button>
    </div>

    <mat-datepicker
      #picker
      [disabled]="disabled"
      [readonly]="readonly"
    ></mat-datepicker>
  </ng-container>
  <!-- <ng-container *ngIf="errorMessage">
    <div class="fielderror">{{ errorMessage }}</div>
  </ng-container> -->
</ui-input-group>
