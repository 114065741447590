import { Component, Input } from '@angular/core';
import { Incident } from 'projects/@common/services/api/respond/incidents/models/incident';

@Component({
  selector: 'incident-table-zoho-link',
  templateUrl: './incident-table-zoho-link.component.html',
  styleUrls: [ './incident-table-zoho-link.component.scss' ],
})
export class IncidentTableZohoLinkComponent {
  @Input() public incident: Incident;

  constructor() { }

  public get zohoLink(): string {
    return this.incident.zohoTicketUrl;
  }

  public get zohoTicketNumber(): string {
    return this.incident.zohoTicket;
  }
}
