/* eslint-disable no-use-before-define */
import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { CdkFooterRow } from '@angular/cdk/table';

@Component({
  selector: 'tr[ui-footer-row]',
  templateUrl: './ui-footer-row.component.html',
  host: {
    class: 'ui-table--footer-row',
    role: 'row',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ { provide: CdkFooterRow, useExisting: UiFooterRow } ],
})
export class UiFooterRow extends CdkFooterRow {}
