import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: [ './delete-button.component.scss' ],
})
export class DeleteButtonComponent {
  @Input() stopPropagation = false;
  @Output() clickEvent = new EventEmitter<MouseEvent>();

  public handleClicked(event: MouseEvent): void {
    this.clickEvent.emit(event);
    if (this.stopPropagation) {
      event.stopPropagation();
      event.preventDefault();
    }
  }
}
