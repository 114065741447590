import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

export enum NoticeLevels {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  SNACK = 'snack'
}

export class Notice {
  constructor(
    public readonly message: string,
    public readonly level: NoticeLevels,
    public readonly params?: {},
    public readonly secondMessage?: string,
    public readonly secondParams?: {},
    public readonly image?: string
  ) {
  }
}

// A Notice is used when we wish to inform the user of something
// This service decouples this idea from the snackbar implementation
@Injectable({
  providedIn: 'root',
})
export class NoticeService {
  private noticeSubject = new Subject<Notice>();

  public notifyUser(notice: Notice): void {
    this.noticeSubject.next(notice);
  }

  public get notices$(): Observable<Notice> {
    return this.noticeSubject.asObservable();
  }

  public success(message: string, params?: {}): void {
    this.notifyUser(new Notice(message, NoticeLevels.SUCCESS, params));
  }

  public error(message: string, params?: {}): void {
    this.notifyUser(new Notice(message, NoticeLevels.ERROR, params));
  }

  public info(message: string, params?: {}): void {
    this.notifyUser(new Notice(message, NoticeLevels.INFO, params));
  }

  public snack(message: string, params?: {}): void {
    this.notifyUser(new Notice(message, NoticeLevels.SNACK, params));
  }
}
