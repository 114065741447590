import { _DisposeViewRepeaterStrategy, _VIEW_REPEATER_STRATEGY } from '@angular/cdk/collections';
import { _COALESCED_STYLE_SCHEDULER, _CoalescedStyleScheduler } from '@angular/cdk/table';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { ApiHostInterceptor } from 'projects/@common/services/api/core/eco.api.host.interceptor';
import { EcoErrorInterceptor } from 'projects/@common/services/api/core/eco.error.interceptor';
import { getUikitModule } from 'projects/@common/utils/uikit.module.factory';
import { AccumulatorModule } from '../accumulator/accumulator.module';
import { I18nModule } from '../i18n/i18n.module';
import { NoticeModule } from '../notice/notice.module';
import { FeatureFlagInterceptor } from '../session/featureFlag.interceptor';
import { HmacInterceptor } from '../session/hmac.interceptor';
import { SessionModule } from '../session/session.module';
import { TraceInterceptor } from '../session/trace.interceptor';

@NgModule({
  imports: [
    I18nModule,
    HttpClientModule,
    BrowserModule,
    NoticeModule,
    AccumulatorModule,
    SessionModule,
    getUikitModule(),
    NgxsModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
  ],
  exports: [
    HttpClientModule,
    BrowserModule,
    NoticeModule,
    AccumulatorModule,
    SessionModule,
    I18nModule,
  ],
})

export class RootSharedModule {
  static getCommonProviders(): Provider[] {
    return [
      { provide: HTTP_INTERCEPTORS, useClass: ApiHostInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: FeatureFlagInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: TraceInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: HmacInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: EcoErrorInterceptor, multi: true },
      { provide: _VIEW_REPEATER_STRATEGY, useClass: _DisposeViewRepeaterStrategy },
      { provide: _COALESCED_STYLE_SCHEDULER, useClass: _CoalescedStyleScheduler },
    ];
  }
}
