<ui-input-group [formGroup]="group">
  <ng-container
    *ngIf="arrayName"
    [formArrayName]="arrayName"
  >
    <ng-container [formGroupName]="groupName">
      <div class="flex-box">
        <label
          ui-label
          [name]="name"
          [required]="required"
        >{{label}}</label>
        <ui-tooltip *ngIf="uiTooltip">{{uiTooltip}}</ui-tooltip>
      </div>
      <input
        *ngIf="!isTextAreaInput"
        ui-input
        type="text"
        [ngClass]="{
          'invalid': forceInvalid
        }"
        [name]="name"
        [formControlName]="control"
        [placeholder]="placeholder"
        [maxlength]="maxlength"
        [disabled]="disabled"
        [readonly]="readonly"
        [value]="value"
      >
      <textarea
        *ngIf="isTextAreaInput"
        [ngClass]="{
          'invalid': forceInvalid,
          'json-editor': jsonStyle
        }"
        ui-input
        [name]="name"
        [placeholder]="placeholder"
        [maxlength]="maxlength"
        [rows]="rowsNumber"
        [formControlName]="control"
      >
        {{ value }}
      </textarea>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!arrayName">
    <div class="flex-box">
      <label
        ui-label
        [name]="name"
        [required]="required"
      >{{label}}</label>
      <ui-tooltip *ngIf="uiTooltip">{{uiTooltip}}</ui-tooltip>
    </div>
    <input
      *ngIf="!isTextAreaInput"
      ui-input
      type="text"
      [ngClass]="{
        'invalid': forceInvalid
      }"
      [name]="name"
      [formControlName]="control"
      [placeholder]="placeholder"
      [maxlength]="maxlength"
      [disabled]="disabled"
      [readonly]="readonly"
      [value]="value"
    >
    <textarea
      *ngIf="isTextAreaInput"
      [ngClass]="{
        'invalid': forceInvalid,
        'json-editor': jsonStyle
      }"
      ui-input
      [name]="name"
      [placeholder]="placeholder"
      [maxlength]="maxlength"
      [disabled]="disabled"
      [readonly]="readonly"
      [rows]="rowsNumber"
      [formControlName]="control"
    ></textarea>
  </ng-container>

  <div
    *ngIf="errorMessage || description"
    class="flex-box space-between"
  >
    <div
      *ngIf="errorMessage"
      class="fielderror"
    >{{ errorMessage | t: {locale: locale} }}</div>
    <label
      class="description-field"
      *ngIf="description"
      ui-label
    >{{ description }}</label>
  </div>

</ui-input-group>