import { HttpClient } from '@angular/common/http';
import { UrlUtils } from '../../../../utils/utils';
import { EcoUrlService } from '../../core/url.service';
import { IIdentityApi, IdentityProviderTypes, List, ListSecurityGroupResponse, ListUsersResponse } from './identities.api.definitions';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';

export class VarIdentitiesApi extends EcoApi implements IIdentityApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.iam;
  constructor(
    http: HttpClient,
    url: EcoUrlService
  ) {
    super(http, url);
  }

  public async listUsers(organizationId: string, params: { order?: string; search?: string; filter?: string; }): Promise<List<ListUsersResponse, string | number, { idpType: IdentityProviderTypes; }>> {
    const url = `${this.host}/organizations/${organizationId}/identities/users?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<{ items: any[]; total: number; }>(url).toPromise();
  }
  public async listSecurityGroup(params: { order?: string; search?: string; filter?: string; }): Promise<List<ListSecurityGroupResponse, string | number, { idpType: IdentityProviderTypes; }>> {
    throw new Error('Method not implemented.');
  }
  public async  getUserByO365Id(identityId: string): Promise<ListUsersResponse> {
    throw new Error('Method not implemented.');
  }
}
