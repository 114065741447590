import { createHash, createHmac } from 'crypto';
import { NonceUtils } from './nonce.utils';
import { Buffer } from 'buffer';

export class EcoSignatureProvider {
  public readonly nonceUtils = new NonceUtils();

  public getEcoHeaders(
    req: {
      body: any,
      method: any,
      url: any,
    },
    key: {
      accessKey: string,
      secretKey: string;
    },
    options?: {
      ttlSeconds: number;
    }
  ) {
    const expires = new Date();
    expires.setSeconds(new Date().getUTCSeconds() + (options?.ttlSeconds || 15));

    const nonce = this.nonceUtils.generateNonce();

    const stuffToSign: { [key: string]: string | void; } = {
      content: (req.body && JSON.stringify(req.body) !== '{}') ? this.computeMd5(JSON.stringify(req.body)) : undefined,
      expires: expires.getTime().toString(),
      method: req.method,
      nonce,
      url: req.url,
    };

    // Order the keys in the object to ensure consistent hashing result
    const ordered = Object.keys(stuffToSign)
      .sort()
      .reduce((obj, key) => {
        obj[key] = stuffToSign[key];
        return obj;
      }, {} as { [key: string]: string | void; });

    const authorization = `${'ECO'} ${key.accessKey}::${this.sign(JSON.stringify(ordered), key.secretKey)}`;

    return {
      'Authorization': authorization,
      'eco-nonce': nonce,
      'eco-expires': expires.getTime().toString(),
    };
  }

  private computeMd5(payload: string): string {
    const hash = createHash('md5');
    hash.update(Buffer.from(payload));
    return hash.digest('base64');
  }

  private sign(payload: string, secretKey: string): string {
    const buffer = Buffer.from(secretKey, 'base64');
    const hmac = createHmac('sha256', buffer);
    const digest = hmac.update(payload).digest('base64');
    return digest;
  }
}
