<div [ngStyle]="{ height: stepperHeight }">
  <mat-horizontal-stepper labelPosition="bottom" #stepper>
    <mat-step *ngFor="let step of steps; let first = first; let last = last">
      <ng-template matStepLabel>{{ step.stepName }}</ng-template>

      <div class="stepper-container">
        <ng-container [ngTemplateOutlet]="step.templateRef"></ng-container>
      </div>

      <div class="stepper-buttons">
        <ui-spinner *ngIf="isLoadingButtons || isSaving"></ui-spinner>

        <ng-container *ngIf="!isLoadingButtons && !isSaving">
          <button
                  *ngIf="!first && step.canGoBack"
                  ui-button
                  color="secondary"
                  matStepperPrevious>
            {{ 'common.action.previous' | t:{locale:locale} }}
          </button>
          <button
                  *ngIf="!last && step.canGoForward"
                  ui-button
                  color="secondary"
                  matStepperNext>
            {{ 'common.action.next' | t:{locale:locale} }}
          </button>
          <button
                  *ngIf="step.saveButton"
                  ui-button
                  color="standard"
                  [disabled]="!isFormValid"
                  (click)="save()">
            {{ step.saveButton }}
          </button>
          <div class="cancel-button">
            <button ui-button color="secondary" (click)="cancel()">
              {{ 'common.action.cancel' | t:{locale:locale} }}
            </button>
          </div>
        </ng-container>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>