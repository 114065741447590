import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Select } from "@ngxs/store";
import { MobileService } from "projects/@common/services/mobile.service";
import { Observable, Subscription } from "rxjs";

import { DrawerService } from "./services/drawer.service";
import { ComponentData, DrawerState } from "./stores/drawer.store";

// import { ContextualHelpDrawer } from 'app/modules/md-content-management/contextual-help/containers/contextual-help-drawer/contextual-help-drawer.container';
@Component({
  selector: 'drawer',
  templateUrl: './drawer.component.html',
  styleUrls: [ './drawer.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'drawer',
  },
})
export class Drawer implements OnInit, OnDestroy {
  // private readonly SPECIAL_DRAWERS: any[] = [ContextualHelpDrawer];

  @Select(DrawerState.componentData) public componentData$: Observable<ComponentData>;

  @Select(DrawerState.returnedData) public returnedData$: Observable<any>;

  @Select(DrawerState.isEditing) public isEditing$: Observable<boolean>;

  @Select(DrawerState.isFullWidth) public isFullWidth$: Observable<boolean>;

  public isEditing = false;
  public isFullWidth = false;
  private componentSubscription: Subscription;
  private editingSubscription: Subscription;
  private fullWidthSubscription: Subscription;

  public dynamicComponent: any;
  public isSpecialDrawer = false;

  public constructor(public mobileService: MobileService, private drawerService: DrawerService, private eRef: ElementRef) { }

  @HostListener('document:keyup', [ '$event' ])
  handleKeyboardEvent(event: KeyboardEvent) {
    const key = event.key;
    if (event.altKey) {
      if (key === 'ArrowUp') {
        this.emitListNavigationPrevious();
      }
      if (key === 'ArrowDown') {
        this.emitListNavigationNext();
      }
    } else if (key === 'Escape') {
      const target = event.target as HTMLElement;
      const hasActionButtons = Boolean(target?.closest("drawer")?.querySelector("ui-form-actions.visible"));
      const isInputElemFocused = [ "INPUT", "TEXTAREA" ].includes(target?.tagName);
      const shouldPreventClosingDrawer = this.isEditing || (hasActionButtons && isInputElemFocused);
      if (!shouldPreventClosingDrawer) {
        event.stopPropagation();
        this.drawerService.hideDrawer();
      }
    }
  }

  public ngOnInit() {
    this.componentSubscription = this.componentData$.subscribe((componentData) => {
      if (componentData) {
        this.getComponent(componentData);
      }
    });
    this.editingSubscription = this.isEditing$.subscribe((value) => this.isEditing = value);
    this.fullWidthSubscription = this.isFullWidth$.subscribe((value) =>  this.isFullWidth = value);
  }

  public ngOnDestroy() {
    this.componentSubscription?.unsubscribe();
    this.editingSubscription?.unsubscribe();
    this.fullWidthSubscription?.unsubscribe();
  }

  public close() {
    this.drawerService.previousDrawer();
  }

  public getComponent(componentData: any) {
    const dynamicComponentParam = {
      component: this.drawerService.component,
      data: {
        data: componentData.data,
      },
    };

    this.dynamicComponent = dynamicComponentParam;
    // this.isSpecialDrawer = this.SPECIAL_DRAWERS.includes(this.dynamicComponent.component);
  }

  public toggleFullWidth() {
    this.drawerService.toggleFullWdith();
  }

  public emitListNavigationPrevious() {
    this.drawerService.emitListNavigationEvent(-1);
  }

  public emitListNavigationNext() {
    this.drawerService.emitListNavigationEvent(1);
  }
}
