import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EcoUrlService } from 'projects/@common/services/api/core/url.service';
import { MonitorsApiService, UpdateMonitorRequest } from 'projects/@common/services/api/respond/usecase/monitors.api';
import { IMonitor } from 'projects/@common/services/api/respond/usecase/usecase.definition';

@Injectable({
  providedIn: 'root',
})
export class AdminMonitorsApiService extends MonitorsApiService {
  constructor (http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public updateMonitor(organizationId: string, monitorId: string, request: UpdateMonitorRequest): Promise<IMonitor> {
    const url = `${super.host}/organizations/${organizationId}/monitors/${monitorId}`;
    return this.http.put<any>(url, request).toPromise();
  }
}
