import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'ui-confirmations-buttons',
  templateUrl: './ui-confirmations-buttons.component.html',
  styleUrls: [ './ui-confirmations-buttons.component.scss' ],
})
export class UiConfirmationsButtonsComponent {
  @Input()
    marginTop: boolean = false;

  @Input()
    isConfirmEnabled: boolean = true;

  @Input()
    notEnabledTooltip: string;

  @Output()
    confirmationEvent: EventEmitter<boolean> = new EventEmitter();

  public apply(e: PointerEvent): void {
    e.stopPropagation();
    if (this.isConfirmEnabled) {
      this.confirmationEvent.emit(true);
    }
  }

  public cancel(e: PointerEvent): void {
    e.stopPropagation();
    this.confirmationEvent.emit(false);
  }
}
