import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: '[toolbar-collapsable-element]',
  templateUrl: './toolbar-collapsable-element.component.html',
})
export class ToolbarCollapsableElementComponent implements OnInit {
  @ViewChild(TemplateRef, { static: true }) public template: TemplateRef<any>;

  @Input() public elementTitle: string;
  @Input() public showCheckbox: boolean = false;
  @Input() public checkboxLabel: string;
  @Input() public checkboxTooltip: string;
  @Input() public checkboxDefaultChecked: boolean = false;

  @Output() public onCheckboxChanged = new EventEmitter<boolean>();

  public checkboxValue: boolean = false;

  ngOnInit() {
    if (this.showCheckbox) {
      this.checkboxValue = this.checkboxDefaultChecked;
    }
  }

  public emitCheckboxChanged(event: boolean) {
    this.onCheckboxChanged.emit(event);
  }

  public resetCheckbox() {
    this.checkboxValue = false;
  }
}
