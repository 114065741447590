export const BYTE_MASK = BigInt(0xff);
export const BITS_IN_BYTE = BigInt(8);
export const POWER_OF_TWO_BYTE = BigInt(3);
export const ZERO = BigInt(0);

export class BytesUtils {
  public static numberToByteArray(number: bigint | number, bytes?: number): number[] {
    const byteArray: number[] = [];
    let bigNumber = BigInt(number);

    while (bigNumber > ZERO) {
      const byte = Number(bigNumber & BYTE_MASK);
      byteArray.push(byte);
      bigNumber >>= BITS_IN_BYTE;
    }

    return bytes == null ? byteArray : Array(bytes).fill(0).concat(byteArray).slice(byteArray.length);
  }

  public static byteArrayToNumber(byteArray: number[]): bigint {
    let value = ZERO;
    for (let byteIndex = byteArray.length - 1; byteIndex >= 0; byteIndex--) {
      value <<= BITS_IN_BYTE;
      value |= BigInt(byteArray[byteIndex]);
    }

    return value;
  }

  public static nearestBitsQtyForByte(number: bigint): bigint {
    // Else for a multiple of 8 bits the result will be 8 bits higher
    number--;
    // Rounded division by 8
    number >>= POWER_OF_TWO_BYTE;
    // So we get the higher nearest instead of the lower one
    number++;
    // Multiply by 8
    number <<= POWER_OF_TWO_BYTE;
    return number;
  }
}
