<div
  class="widget-container"
  [ngClass]="{ withBottomMargin: withBottomMargin }"
>
  <div class="widget-container--title-section">
    <div>
      <div class="widget-container--title-section--with-tooltip">
        <img
          *ngIf="withIcon"
          class="widget-container--title-section--icon"
          [src]="'/assets/favicons/' + iconName + '.svg'"
        />
        <widget-title [title]="title"></widget-title>
        <div *ngIf="withMobileTitleTooltip && mobileService.isMobile()">
          <ui-tooltip [isSmall]="true">
            <ng-content select="[mobile-tooltip-content]"></ng-content>
          </ui-tooltip>
        </div>
        <div *ngIf="withTitleTooltip">
          <ui-tooltip [isSmall]="true">
            <ng-content select="[tooltip-content]"></ng-content>
          </ui-tooltip>
        </div>
      </div>
      <div *ngIf="subtitle" class="widget-container--subtitle">
        {{ subtitle }}
      </div>
    </div>
    <widget-time-range
      *ngIf="withTimeRange"
      [timeRanges]="timeRanges"
      [selectedTimeRange]="timeRangesDefault"
      [locale]="locale"
      (selectedTimeRangeChange)="selectedTimerange.emit($event)"
    ></widget-time-range>
  </div>
  <div
    [ngClass]="{ withPadding: withPadding }"
    class="widget-container--content-section"
  >
    <ng-content></ng-content>
  </div>
</div>
