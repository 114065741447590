import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "projects/@common/environments/environment";
import { Observable } from "rxjs";

import { EcoSignatureService } from "../../services/api/core/eco.signature.service";


// Catches the request and adds md.eco signature to it
@Injectable()
export class HmacInterceptor implements HttpInterceptor {
  constructor(private service: EcoSignatureService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Only sign requests made to md.eco
    if (!this._isEcoApi(req.url)) {
      return next.handle(req);
    }

    const ecoHeaders = this.service.getEcoHeaders(req);
    const signed = req.clone({ setHeaders: { ...ecoHeaders } });
    return next.handle(signed);
  }

  private _isEcoApi(url: string): boolean {
    return !!Object.values(environment.ecoUrls).find((ecoUrl) => url.includes(ecoUrl));
  }
}
