import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'topbar-logo',
  templateUrl: './topbar-logo.component.html',
  styleUrls: [ './topbar-logo.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'topbar-logo',
  },
})
export class TopbarLogo {
  @Input('isExpanded') public isExpanded: boolean;
}
