/* eslint-disable no-use-before-define */
import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { CdkHeaderRow } from '@angular/cdk/table';

@Component({
  selector: 'tr[ui-header-row]',
  templateUrl: './ui-header-row.component.html',
  host: {
    class: 'ui-table--header-row',
    role: 'row',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ { provide: CdkHeaderRow, useExisting: UiHeaderRow } ],
})
export class UiHeaderRow extends CdkHeaderRow {}
