import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as uuid from "uuid";

@Component({
  selector: 'ui-label-input-with-modify',
  templateUrl: './ui-label-input-with-modify.component.html',
  styleUrls: [ './ui-label-input-with-modify.component.scss' ],
})
export class UiLabelInputWithModifyComponent implements OnInit {
  @Input() public label: string;

  @Input() public locale: string = 'fr';

  @Input() public value: number | string;

  @Input() public onError: boolean;

  @Input() public normalInput = true;

  @Input() public textareaInput = false;

  @Input() public emptyPlaceholder = '';

  @Input() public placeholder = '';

  @Input() public errorMessage = '';

  @Input() public hasLabel = true;

  @Input() isEditing = false;

  @Input() disabled = false;

  @Input() buttonsMarginTop = false;

  @Input() maxlength = null;

  @Input() public inputStyle: any = {
    'font-size': '14px',
    'line-height': '24px',
    "white-space": "pre-wrap",
  };
  @Input() public showUnsavedWarning: boolean = false;

  @Output() public onValueChange: EventEmitter<string | number> = new EventEmitter();
  @Output() public hasPendingChangeEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() public isEdittingEvent: EventEmitter<boolean> = new EventEmitter();


  public iteration: string = uuid.v4();

  public valueBinder: number | string;

  public isHovering: boolean = false;

  ngOnInit(): void {
  }

  public get isDifferent(): boolean {
    return this.value !== this.valueBinder;
  }

  public get showEmptyPlaceholder(): boolean {
    return this.value?.toString()?.length < 1;
  }

  public handleConfirmationEvent(isConfirmed: boolean): void {
    if (isConfirmed) {
      this.changeValue();
    } else {
      this.closeEdit();
    }
  }

  public closeEdit(): void {
    this.isEditing = false;
    this.isHovering = false;
    this.isEdittingEvent.emit(this.isEditing);
  }

  public openEdit(): void {
    this.valueBinder = this.value;
    this.isEditing = true;
    this.isEdittingEvent.emit(this.isEditing);
  }

  public changeValue(): void {
    this.value = this.valueBinder;
    this.onValueChange.emit(this.valueBinder);
    this.closeEdit();
  }

  public handleInputEvent(): void {
    this.emitPendingChangeEvent();
  }

  private emitPendingChangeEvent(): void {
    this.hasPendingChangeEvent.emit(this.isDifferent);
  }
}
