import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { clone } from "projects/@common/utils/utils";

export enum Accumulatables {
  REQUESTS_LIST,
  GROUP_USERS_LIST,
  CHANNEL_LIST,
  GROUP_RESOURCES_LIST,
  GROUP_CREATION_OWNERS_CHANGE,
  ROLE_LIST,
  IAM_ORGANIZATIONS_LIST,
  USER_ROLES,
  IAM_ROLE_LIST,
  IAM_PROFILE_LIST,
  ORGANIZATIONS_LIST,
  COMPLIANCES_CHECKS,
  RESPOND_ORGANIZATIONS_LIST,
  STACKS_LIST,
  COMPLIANCE_CHECK_OWNER_NOTIFICATION,
  ASSIST_OPS_PROFILES_LIST,
  LIST_REFRESHER,
  PARAMETERS_LIST,
  SECRETS_LIST,
  FEATURE_SERVICE_REFRESHER
}

export interface Accumulator {
  accumulatable: Accumulatables;
  accumulator: number;
}

export interface AccumulatorsModel {
  accumulators: Accumulator[];
}

export class Accumulate {
  static readonly type = '[Accumulators] Update accumulator';

  public constructor(public payload: { accumulatable: Accumulatables; }) { }
}

export const initialState: AccumulatorsModel = {
  accumulators: [],
};

@State<AccumulatorsModel>({
  name: 'accumulators',
  defaults: clone(initialState),
})
@Injectable()
export class AccumulatorsState {
  public constructor() { }

  @Selector()
  public static accumulators(state: AccumulatorsModel): Accumulator[] {
    return state.accumulators;
  }

  @Action(Accumulate)
  public async accumulate(ctx: StateContext<AccumulatorsModel>, { payload }: Accumulate) {
    const accumulators: Accumulator[] = clone(ctx.getState().accumulators);
    const existingAccumulator = accumulators
      .filter((accumulator) => accumulator.accumulatable === payload.accumulatable)
      .shift();

    if (existingAccumulator) {
      existingAccumulator.accumulator++;
    } else {
      accumulators.push({ accumulatable: payload.accumulatable, accumulator: 1 });
    }

    ctx.patchState({
      accumulators,
    });
  }
}
