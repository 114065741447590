<div
  *ngFor="let snack of snacks$ | async"
  class="snack"
  [ngClass]="{
    delicious: snack.taste === 'Delicious',
    inedible: snack.taste === 'Inedible',
    normal: snack.taste === 'Normal',
    snacky: snack.taste === 'Snacky',
    'snacky-snack': snack.taste === 'Snacky',
    show: !snack.hide
  }"
>
  <div class="left-section">
    <img *ngIf="snack.image" class="img-snackbar" [src]="'assets/favicons/' + snack.image">
    <div>
      <div class="snack-title">
        <b
          [ngClass]="{snackyMessage: snack.taste === 'Snacky'}"
          class="message"
        >{{ snack.message }}</b>
      </div>
      <div
        *ngIf="snack.secondMessage"
        class="second-message"
        [innerHTML]="snack.secondMessage"
      ></div>
    </div>
  </div>
  <button
      *ngIf="snack.taste !== 'Snacky'"
      ui-button
      [color]="getButtonColor(snack)"
      icon="close-circle"
      (click)="throwSnackInTrash(snack)"
  ></button>
</div>