import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { debounceTime } from 'rxjs/operators';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { UiSelectAutocompleteOptionComponent } from './ui-select-autocomplete-option/ui-select-autocomplete-option.component';

@Component({
  selector: 'ui-select-autocomplete',
  templateUrl: './ui-select-autocomplete.component.html',
  styleUrls: [ './ui-select-autocomplete.component.scss' ],
})
export class UiSelectAutocompleteComponent implements OnInit, OnDestroy {
  @ViewChild(CdkPortal, { static: false }) overlayTemplate;

  @ViewChild('inputField', { static: false }) inputRef: ElementRef;

  @ViewChildren(UiSelectAutocompleteOptionComponent) optionComponents: QueryList<UiSelectAutocompleteOptionComponent>;

  @Input() value: any;

  @Input() displayValueKey: string;

  @Input() uniqueIdValueKey = 'guid';

  @Input() data: Observable<any[]>;

  @Input() label: string;

  @Input() placeholder: string;

  @Input() status: Observable<string> = of('idle'); // makes an Observable out of a constant

  @Input() optionTemplate;

  @Input() inputStyle = {};

  @Output() queryEmitter = new EventEmitter();

  @Output('valueChange') valueChangeEmitter = new EventEmitter();

  private debouncedQueryEmitter = new Subject<string>();

  private keyManager: ActiveDescendantKeyManager<UiSelectAutocompleteOptionComponent>;

  private optionsComponentsChangesSubscription: Subscription;

  private backdropClickSubscription: Subscription;

  private debounceQuerySubscription: Subscription;

  readonly DEBOUNCE_TIME = 300;

  overlayRef: OverlayRef;

  query = '';

  showing = false;

  constructor(public overlay: Overlay, private changeDetectorRef: ChangeDetectorRef) {}

  public ngOnDestroy() {
    this.optionsComponentsChangesSubscription?.unsubscribe();
    this.backdropClickSubscription?.unsubscribe();
    this.debounceQuerySubscription?.unsubscribe();
  }

  public ngOnInit(): void {
    this.initializeDebouncer();
    this.query = '';
    this.queryEmitter.emit(this.query);
  }

  public get isValid(): boolean {
    return !!this.value;
  }

  public showOverlay() {
    this.optionsComponentsChangesSubscription = this.optionComponents.changes.subscribe(() => {
      this.initializeKeyManager();
      this.changeDetectorRef.detectChanges();
    });
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.overlayRef.attach(this.overlayTemplate);
    this.syncWidth();
    this.backdropClickSubscription = this.overlayRef.backdropClick().subscribe(() => this.hideOverlay());
    this.showing = true;
  }

  public hideOverlay() {
    this.overlayRef.detach();
    this.showing = false;
  }

  @HostListener('window:resize')
  public onWinResize() {
    this.syncWidth();
  }

  public onClear() {
    this.query = '';
    this.value = {};
    this.queryEmitter.emit(this.query);
    this.valueChangeEmitter.emit(this.value);
  }

  public onQueryInputChange(event) {
    this.query = event.target.value;
    this.debouncedQueryEmitter.next(this.query);
  }

  public onOptionClick(optionComponent: UiSelectAutocompleteOptionComponent) {
    this.keyManager.setActiveItem(optionComponent);
    this.value = optionComponent.value;
    this.valueChangeEmitter.emit(this.value);
    this.hideOverlay();
  }

  public getDisplayValue() {
    if (this.value) {
      return this.value[this.displayValueKey];
    }
    return null;
  }

  public onKeyDown(event: KeyboardEvent) {
    if ([ 'Enter', ' ', 'ArrowDown', 'Down', 'ArrowUp', 'Up' ].indexOf(event.key) > -1) {
      if (!this.showing) {
        this.showOverlay();
        return;
      }
      if (!this.optionComponents.toArray().length) {
        event.preventDefault();
        return;
      }
    }

    if (event.key === 'Enter' || event.key === ' ') {
      this.keyManager.onKeydown(event);
      this.value = this.keyManager.activeItem.value;
      this.valueChangeEmitter.emit(this.value);
      this.hideOverlay();
    } else if (event.key === 'Escape' || event.key === 'Esc') {
      this.hideOverlay();
    } else if (
      [ 'ArrowUp', 'Up', 'ArrowDown', 'Down', 'ArrowRight', 'Right', 'ArrowLeft', 'Left' ].indexOf(event.key) > -1
    ) {
      this.keyManager.onKeydown(event);
      this.value = this.keyManager.activeItem.value;
    } else if (event.key === 'PageUp' || event.key === 'PageDown' || event.key === 'Tab') {
      event.preventDefault();
    }
  }

  public isEmptyObject(obj) {
    if (obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    }
    return false;
  }

  private initializeKeyManager() {
    this.keyManager = new ActiveDescendantKeyManager(this.optionComponents)
      .withHorizontalOrientation('ltr')
      .withVerticalOrientation()
      .withWrap();

    this.activeOptionComponent
      ? this.keyManager.setActiveItem(this.activeOptionComponent)
      : this.keyManager.setFirstItemActive();
  }

  private get activeOptionComponent() {
    if (this.value) {
      return this.optionComponents.toArray().find((component) => this.value[this.uniqueIdValueKey] === component.value[this.uniqueIdValueKey]);
    }
    return null;
  }

  private initializeDebouncer() {
    this.debounceQuerySubscription = this.debouncedQueryEmitter
      .pipe(debounceTime(this.DEBOUNCE_TIME))
      .subscribe((query) => {
        this.queryEmitter.emit(query);
      });
  }

  private syncWidth() {
    if (!this.overlayRef) {
      return;
    }
    const refRect = this.inputRef.nativeElement.getBoundingClientRect();
    this.overlayRef.updateSize({ width: refRect.width });
  }

  protected getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.inputRef)
      .withPush(false)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
      ]);

    return new OverlayConfig({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });
  }
}
