import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { IRespondResponse } from 'projects/@common/definitions/IRespondResponse';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { AppState } from 'projects/@common/modules/layout/stores/app.state';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { ResponseUtils } from 'projects/@common/utils/response-utils';
import { UrlUtils } from 'projects/@common/utils/utils';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { EcoUrlService } from '../core/url.service';
import { IIncidentMessage } from '../respond/incidents/incidents.definitions';
import { SseClient } from './sseClient.service';

export interface NotificationIdentity {
  id: string;
  email?: string;
  confirmed: boolean;
}

interface GetImagePresignedUrlRequestDto {
  filename: string;
  organizationId?: string;
  conversationId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsApiService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.notification;

  @Select(AppState.isReady) public isReady: Observable<boolean>;

  public eventObservable = new Subject<any>();

  public getObservable(): Observable<any> {
    return this.eventObservable.asObservable();
  }

  constructor(readonly http: HttpClient, url: EcoUrlService, private sseClient: SseClient, protected store: Store) {
    super(http, url);
    this.isReady.subscribe((readyState) => {
      if (readyState) {
        try {
          this.getSseConnection().subscribe((event) => {
            if (event.type !== 'error') {
              this.eventObservable.next(event);
            }
          });
        } catch (e) {
          console.log('Connection error:', e);
        }
      }
    });
  }

  public async uploadToS3(file: Blob, filename: string, conversationId: string, organizationId: string): Promise<string> {
    const request: GetImagePresignedUrlRequestDto = {
      filename: this.getFullFilename(filename),
      conversationId,
      organizationId,
    };

    const response = await this.getPresignedUrl(request);

    await this.uploadImageToCloud(response.uploadPresignedUrl, file, this.getHeaders(file.type)).toPromise();

    return response.downloadPresignedUrl;
  }

  public getPresignedUrl(request: GetImagePresignedUrlRequestDto): any {
    if (this.isVarMode) {
      const url = `${this.host}/organizations/${request.organizationId}/conversations/${request.conversationId}/images/${request.filename}`;
      return this.http.get<any>(url).toPromise();
    }
    const url = `${this.host}/me/conversations/${request.conversationId}/images/${request.filename}`;
    return this.http.get<any>(url).toPromise();
  }

  public async markNotifAsRead(notification: any): Promise<any> {
    const url = `${this.host}/notifications`;
    return this.http.put<any>(url, { notificationId: notification.notificationId, seen: true }).toPromise();
  }

  public getSseConnection(): Observable<any> {
    const url = `${this.host}/notifications/events`;
    return this.sseClient.stream(url, null, {});
  }

  public async listNotifications(params: {
    from?: number;
    order?: string;
    size?: number;
    searchText?: string;
    channels?: string[];
  }): Promise<any> {
    const url = `${this.host}/notifications?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url).toPromise();
  }

  public async getUserIdentity(): Promise<NotificationIdentity> {
    const url = `${this.host}/me/identities`;
    return this.http.get<NotificationIdentity>(url).toPromise();
  }

  public async updateEmail(email: string): Promise<NotificationIdentity> {
    const url = `${this.host}/me/identities/email`;
    return this.http.post<NotificationIdentity>(url, {
      email,
    }).toPromise();
  }

  public describeIncidentConversation(organizationId: string, conversationId: string, params?: any): Observable<ResponseUtils<IIncidentMessage>> {
    if (this.isVarMode) {
      const url = `${this.host}/organizations/${organizationId}/conversations/${conversationId}/messages?${UrlUtils.jsonToQueryParams(params)}`;
      return this.http.get<IRespondResponse<IIncidentMessage>>(url)
        .pipe(map((response) => new ResponseUtils<IIncidentMessage>(response)));
    }
    const url = `${this.host}/me/conversations/${conversationId}/messages?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<IRespondResponse<IIncidentMessage>>(url)
      .pipe(map((response) => new ResponseUtils<IIncidentMessage>(response)));
  }

  public getIncidentMessage(organizationId: string, conversationId: string, params: {messageId: string}): Observable<IIncidentMessage> {
    if (this.isVarMode) {
      const url = `${this.host}/organizations/${organizationId}/conversations/${conversationId}/message?${UrlUtils.jsonToQueryParams(params)}`;
      return this.http.get<IIncidentMessage>(url)
    }
  }

  public postIncidentMessage(organizationId: string, conversationid: string, params?: any): Observable<IIncidentMessage[]> {
    if (this.isVarMode) {
      const url = `${this.host}/organizations/${organizationId}/conversations/${conversationid}/messages`;
      return this.http.post<IIncidentMessage[]>(url, params);
    }
    const url = `${this.host}/me/conversations/${conversationid}/messages`;
    return this.http.post<IIncidentMessage[]>(url, params);
  }

  public uploadImageToCloud(url: string, data: any, headers: HttpHeaders): Observable<any> {
    return this.http.put<any>(url, data, { headers });
  }

  private get isVarMode(): boolean {
    return !!this.store.selectSnapshot(EcoSessionState.varMode);
  }

  private getFullFilename(filename: string): string {
    return encodeURIComponent(`${Date.now()}-${filename}`);
  }

  private getHeaders(contentType: string): HttpHeaders {
    return new HttpHeaders({ 'Content-Type': contentType });
  }
}
