import { Component, Input, OnInit } from '@angular/core';

export enum DotIndicatorColorEnum {
  NONE = 'none',
  HIGH = 'high',
  MODERATE = 'moderate',
  LOW = 'low'
}

@Component({
  selector: 'ui-dot-indicator',
  templateUrl: './ui-dot-indicator.component.html',
  styleUrls: [ './ui-dot-indicator.component.scss' ],
})
export class UiDotIndicatorComponent implements OnInit {
  @Input() riskInfo: any;

  public classes: string;

  public ngOnInit() {
    // Need a onInit that does the same thing as the onChanges
    // Because this component is called dynamicaly and ngOnChanges is not called in that case
    this.initialize();
  }

  public ngOnChanges() {
    this.initialize();
  }

  private initialize() {
    this.classes = `circle-risk-indicator ${this.riskInfo.risk}-risk-circle`;
  }
}
