import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

@Component({
  selector: 'ui-form-array-checkbox-table',
  templateUrl: './ui-form-array-checkbox-table.component.html',
  styleUrls: [ './ui-form-array-checkbox-table.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-form-array-checkbox-table',
  },
})
export class UiFormArrayCheckboxTable {
  @Input('form-group') public formGroup: UntypedFormGroup;

  @Input('form-array') public formArray: UntypedFormArray;

  @Input('variables') public variables: string[];

  @Input('elements') public elements: string[];

  @Input('form-array-name') public formArrayName: string;

  @Input('isEditing') public isEditing: boolean;

  @Input('values') public values: any;
}
