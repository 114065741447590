import { HttpClient } from '@angular/common/http';
import { UrlUtils } from '../../../../utils/utils';
import { EcoUrlService } from '../../core/url.service';
import { DescribeIdpIdentity, IIdentityApi, IdentityProviderTypes, List, ListSecurityGroupResponse, ListUsersResponse } from './identities.api.definitions';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';

export class CustomerIdentitiesApi extends EcoApi implements IIdentityApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.iam;
  constructor(
    http: HttpClient,
    url: EcoUrlService
  ) {
    super(http, url);
  }

  public async listUsers(organizationId: string, params: { order?: string; search?: string; filter?: string; }): Promise<List<ListUsersResponse, string | number, { idpType: IdentityProviderTypes; }>> {
    const url = `${this.host}/identities/users?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<{ items: any[]; total: number; }>(url).toPromise();
  }

  public async listSecurityGroup(params: { order?: string; search?: string; filter?: string; } = {}): Promise<List<ListSecurityGroupResponse, number | string, { idpType: IdentityProviderTypes; }>> {
    const url = `${this.host}/identities/securityGroup?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<{ items: any[]; total: number; }>(url).toPromise();
  }

  public async getUserByO365Id(identityId: string): Promise<ListUsersResponse> {
    const url = `${this.host}/identities/${identityId}`;
    return this.http.get<ListUsersResponse>(url).toPromise();
  }

  public async describeIdpIdentity(): Promise<DescribeIdpIdentity> {
    const url = `${this.host}/identities/me/idp`;
    return this.http.get<DescribeIdpIdentity>(url).toPromise();
  }
}
