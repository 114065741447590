import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { IAutomationOrganization } from "@common/services/api/respond/automation-organization/automation-organization.definitions";
import { Autocomplete, AutocompleteTypes } from "@ui-kit/components/autocomplete/autocomplete.component";
import { AutocompleteCustomValue } from "@ui-kit/components/autocomplete/custom-autocomplete/custom-autocomplete.component";


@Component({
  selector: 'organization-filter-autocomplete',
  templateUrl: './organization-filter-autocomplete.component.html',
  styleUrls: [ './organization-filter-autocomplete.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class OrganizationFilterAutocompleteComponent implements OnInit {
  @ViewChild('autocomplete') autocomplete!: Autocomplete;

  @Input()
  public organizations: IAutomationOrganization[];

  @Input()
  public showLabel = true;

  @Input()
  public initValuesSelected: string[];

  @Output()
  public organizationChanged: EventEmitter<IAutomationOrganization> = new EventEmitter();

  @Output()
  public organizationClear = new EventEmitter();

  public autocompleteType = AutocompleteTypes.CUSTOM;

  public organizationKeyValuePairs: AutocompleteCustomValue[] = [];

  public selectedItems: AutocompleteCustomValue[] = [];

  public isLoaded = false;

  private debounceTimeout = null;
  private DEBOUNCE_TIME = 300;

  ngOnInit(): void {
    this.organizationKeyValuePairs = this.organizations.map((org) => ({
      value: org.id,
      displayValue: org.name,
    }));

    if (this.initValuesSelected) {
      this.selectedItems = this.organizationKeyValuePairs.filter((org) => this.initValuesSelected.includes(org.value));
    }

    this.isLoaded = true;
  }

  public handleUserSelection(value: any): void {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      if (!value) {
        this.organizationChanged.emit(null);
        return;
      }
      const org = this.organizations.find((o) => o.id === value.value);
      this.organizationChanged.emit(org);
    }, this.DEBOUNCE_TIME);
  }

  public clearSelected(): void {
    this.autocomplete.clearSearchQuery();
  }
}
