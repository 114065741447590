import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { LanguageEnum } from "../../interfaces/ILanguage";

export enum UploadFolderEnum {
  INCIDENTS = 'INCIDENTS'
}

export interface FroalaOptions {
  key?: string;
  heightMin?: number;
  charCounterMax?: number;
  charCounterCount?: boolean;
  fileUpload?: boolean;
  attribution?: boolean;
  videoResponsive?: boolean;
  theme?: string;
  listAdvancedTypes?: boolean;
  toolbarButtons?: Array<Array<string>>;
  language?: string;
  fontFamily?: Record<string, unknown>;
  fontSize?: Array<string>;
  imageResize?: boolean;
  tabSpaces?: number;
  colorsBackground?: Array<string>;
  colorsText?: Array<string>;
  imageUploadURL?: any;
  events?: any;
  placeholderText?: string;
}

const DEFAULT_OPTION = {
  key: 'oc1F2vB2D1I2A3B1A5mEZXQUVJb1EZf1IWIAUKLJZMBQuD3E2D1F1C4G1I4F1B11C8==',
  heightMin: 100,
  charCounterMax: 2000,
  charCounterCount: true,
  fileUpload: false,
  attribution: false,
  listAdvancedTypes: true,
  quickInsertTags: '',
  toolbarButtons: [
    [ 'fullscreen', 'formatOL', 'formatUL', 'bold', 'italic', 'underline' ],
    [ 'specialCharacters', 'clearFormatting' ],
    [ 'spellChecker' ],
  ],
  language: '',
};
@Component({
  selector: 'html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: [ './html-editor.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class HtmlEditorComponent implements OnInit {
  @Input() group: UntypedFormGroup;
  @Input() controlName: string;
  @Input() maxInput: number;
  @Input() initialValue: string;
  @Input() htmlData: string;
  @Input() placeholder: string;
  @Input() handleImage = true;
  @Input() handleLink = true;
  @Input() isEditing = true;
  @Input() label: string;
  @Input() options: any = {};
  @Input() locale: string;
  @Input() showUnsavedWarning: boolean = false;
  @Input() editorEventHandlers: { [key: string]: Function } = {};
  @Input() useNgModel: boolean = false;
  @Input() ngModel: string;
  @Output() ngModelChange = new EventEmitter<string>();

  public editor: any;

  constructor() {
  }

  ngOnInit(): void {
    this.handleFroalaOptions();
    this.handleImageUploader();
    this.handleEditorTranslation();
  }

  public get value(): string {
    return this.group.get(this.controlName).value;
  }

  public get hasChanged(): boolean {
    return this.initialValue !== this.value;
  }

  public valueOnChange(value: string): void {
    this.ngModelChange.emit(value);
  }

  private handleFroalaOptions(): void {
    if (this.options) {
      this.options.toolbarButtons = [ ...new Set(DEFAULT_OPTION.toolbarButtons.concat(this.options.toolbarButtons).flat(Infinity)) ];
      this.options = { ...DEFAULT_OPTION, ...this.options };
    } else {
      this.options = { ...DEFAULT_OPTION };
    }
    this.options.charCounterMax = this.maxInput || 2000;
  }

  private handleImageUploader(): void {
    if (this.handleImage) {
      this.options.toolbarButtons.push('insertImage');
      this.options.imageUpload = true;
      this.options.imageResize = true;
      this.options.imageMaxSize = 5 * 1024 * 1024;
      this.options.imageEditButtons = [ 'imageDisplay', 'imageAlign', 'imageAlt', 'imageSize', 'imageRemove' ];
    } else if (this.handleLink) {
      this.options.toolbarButtons.push('insertLink');
    }

    this.options.events = {
      ...this.editorEventHandlers,
      initialized: (e: any) => {
        // Need to get the editor on the initialize
        this.editor = e._editor;
      },
    };
  }

  private handleEditorTranslation(): void {
    this.options.language = this.locale || LanguageEnum.FRENCH;
  }
}
