import { Directive, HostListener, Input, OnDestroy } from "@angular/core";

@Directive({
  selector: '[tooltip]',
})
export class UiTooltipDirective implements OnDestroy {
  @Input() tooltip = "";
  @Input() tooltipDelay = 200;
  @Input() tooltipBackgroundWhite = false;
  @Input() tooltipBiggerPadding = false;
  @Input() useUIToolTipFormat = false;

  private domElement: HTMLElement;
  private initTimer: NodeJS.Timeout;
  private selfDestroyTimer: NodeJS.Timeout;
  private classSelector = "tooltip-directive-dom-container";
  private selfDestroyMs = 30000;

  constructor() {
  }

  ngOnDestroy(): void {
    this.deleteTooltip();
  }

  @HostListener('mouseenter', [ '$event' ]) onMouseEnter(mouseEvent: MouseEvent) {
    this.initTooltip(mouseEvent);
  }

  @HostListener('mousemove', [ '$event' ]) onMouseMove(mouseEvent: MouseEvent) {
    this.setPosition(mouseEvent);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.deleteTooltip();
  }

  @HostListener('mousedown') onClick() {
    this.deleteTooltip();
  }


  private get isTooltipValid(): boolean {
    return this.tooltip?.length > 0;
  }

  private initTooltip(mouseEvent: MouseEvent): void {
    if (this.isTooltipValid) {
      this.createDOMElement();
      this.setPosition(mouseEvent);
      this.initTimer = setTimeout(() => this.showTooltip(), this.tooltipDelay);
      this.selfDestroyTimer = setTimeout(() => this.deleteTooltip(), this.selfDestroyMs);
    }
  }

  private deleteTooltip(): void {
    this.initTimer && clearTimeout(this.initTimer);
    this.selfDestroyTimer && clearTimeout(this.selfDestroyTimer);
    if (this.domElement) {
      this.domElement.remove();
      this.domElement = null;
    }
  }

  private createDOMElement(): void {
    this.domElement = document.createElement('div');
    this.domElement.onclick = () => this.deleteTooltip();
    this.domElement.innerHTML = this.tooltip;
    this.domElement.classList.add(this.classSelector);
    this.domElement.style.position = "absolute";
    this.domElement.style.height = "max-content";
    this.domElement.style.width = "max-content";
    this.domElement.style.maxWidth = "350px";
    this.domElement.style.padding = "3px 7px";
    this.domElement.style.zIndex = "99999";
    this.domElement.style.backgroundColor = "#404c5b";
    this.domElement.style.color = "#fff";
    this.domElement.style.borderRadius = "6px";
    this.domElement.style.boxShadow = "0 2px 3px rgba(0, 0, 0, 0.4)";
    this.domElement.style.fontSize = "12px";
    this.domElement.style.visibility = "hidden";
    this.domElement.style.fontFamily = "Source Sans Pro, sans-serif";
    if (this.tooltipBackgroundWhite) {
      this.domElement.style.backgroundColor = "#fff";
      this.domElement.style.color = "#313942";
    }
    if (this.useUIToolTipFormat) {
      this.domElement.style.backgroundColor = '#f5fbfb';
      this.domElement.style.color = '#2d7681';
      this.domElement.style.padding = '15px';
      this.domElement.style.fontSize = '14px' ;
    }
    if (this.tooltipBiggerPadding) {
      this.domElement.style.padding = "8px 16px";
    }
    document.body.appendChild(this.domElement);
  }

  private showTooltip(): void {
    this.domElement.style.visibility = "visible";
  }

  private setPosition(mouseEvent: MouseEvent): void {
    if (this.isTooltipValid && this.domElement) {
      const x = this.getLeftPosition(mouseEvent.x);
      const y = this.getTopPosition(mouseEvent.y);
      this.domElement.style.left = `${x}px`;
      this.domElement.style.top = `${y}px`;
    }
  }

  private getOverflowX(mouseX: number): number {
    const tooltipRightX = mouseX + this.domElement.offsetWidth;
    const overflowX = tooltipRightX - document.documentElement.offsetWidth;
    return overflowX > 0 ? overflowX : 0;
  }

  private getOverflowY(mouseY: number): number {
    const tooltipBottomY = mouseY + this.domElement.offsetHeight + 24;
    const overflowY = tooltipBottomY - document.documentElement.offsetHeight;
    return overflowY > 0 ? overflowY : 0;
  }

  private getLeftPosition(mouseX: number): number {
    const overflowX = this.getOverflowX(mouseX);
    return mouseX - (overflowX + 6);
  }

  private getTopPosition(mouseY: number): number {
    let top = mouseY + 24;
    const overflowY = this.getOverflowY(mouseY);
    if (overflowY > 0) {
      top = mouseY - (this.domElement.offsetHeight + 12);
    }
    return top;
  }
}
