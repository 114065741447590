import { Component, Input } from "@angular/core";
import { IncidentStatus } from "projects/@common/services/api/respond/incidents/incidents.definitions";

@Component({
  selector: 'app-incident-status',
  templateUrl: './incident-status.component.html',
  styleUrls: [ './incident-status.component.scss' ],
})
export class IncidentStatusComponent {
  @Input()
    data: any & { status: IncidentStatus; breachOfConfidentiality: boolean };

  @Input()
    wrapper = true;

  @Input()
    withIcon = true;

  @Input()
    context: 'table' | 'details' = 'table';

  public get incidentStatusIcon(): string {
    switch (this.data?.status) {
      case IncidentStatus.CLOSED:
      case IncidentStatus.CLOSED_TEST:
      case IncidentStatus.CLOSED_FALSE_POSITIVE:
      case IncidentStatus.CLOSED_EXPECTED:
        return 'assets/favicons/icon_incident_status_closed.svg';
      case IncidentStatus.FINALIZING:
        return 'assets/favicons/icon_incident_status_finalizing.svg';
      default:
        return 'assets/favicons/icon_incident_status_open.svg';
    }
  }

  public get incidentStatusText(): string {
    return `incidents.container.page.filter.status.${this.data?.status}.filter`;
  }
}
