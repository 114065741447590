import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { TextareaTypeEnum } from "@ui-kit/components/ui-json-textarea/ui-json-textarea.component";
import { Eco } from "projects/@common/definitions/eco";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { FormErrorUtils } from "projects/@common/utils/formErrors-utils";
import { DynamicFormFieldType } from "../dynamic-form/ui-dynamic-form.component";
import { DynamicFormField } from "../models/dynamicFormField.model";

@Component({
  selector: 'ui-dynamic-form-field',
  templateUrl: './ui-dynamic-form-field.component.html',
  styleUrls: [ './ui-dynamic-form-field.component.scss' ],
})
export class DynamicFormFieldComponent {
  @Input() formGroup: UntypedFormGroup;
  @Input() field: DynamicFormField;
  @Input() public locale: Eco.Languages = Eco.Languages.FR;
  @Input() readonly = false;

  public errorUtils: FormErrorUtils;

  public dynamicFormFieldType = DynamicFormFieldType;

  public textareaTypeEnum = TextareaTypeEnum;

  constructor(readonly i18n: I18nService) {
    this.errorUtils = new FormErrorUtils(i18n);
  }


  public onNumberInputValueChanged(event: string): void {
    this.formGroup.patchValue({
      [this.field.fieldName]: event === "" ? "" : +event,
    });
  }
}
