<div class="sidebar-top">
  <section class="sidebar-top--header">
    <div class="sidebar-top--header--left-part">
      <div class="sidebar-top--header--avatar">
        <!-- <ui-avatar [user]="user" size="medium"></ui-avatar> -->
        <div class="sidebar-top--header--avatar--border"></div>
      </div>

      <div class="sidebar-top--header--infos">
        <div>
          <!-- {{ user | fullname }} -->
        </div>
        <div
          class="sidebar-top--header--see-profile"
          (click)="seeUserProfile()"
        >
          {{ 'sidebar.navigation.mobile.seeMyProfile' | t }}
        </div>
      </div>
    </div>

    <div (click)="closeMenu()">
      <img src="assets/favicons/icon_remove.svg" />
    </div>
  </section>

  <section class="sidebar-top--buttons">
    <!-- <div *hasModuleAccess="['assist.ops']">
      <mobile-sidebar-button
        icon="notification"
        buttonName="sidebar.navigation.mobile.notifications"
        [indicator]="indicators.notices"
        (click)="openNotificationDrawer()"
      ></mobile-sidebar-button>
    </div> -->

    <ng-container>
      <!-- <div *hasPermission="['isOfficeRead']">
        <mobile-sidebar-button
          icon="email"
          nameSideIcon="open_external"
          buttonName="sidebar.navigation.mobile.outlook"
          [indicator]="indicators.emails"
          (click)="blankToOutlook()"
        ></mobile-sidebar-button>
      </div> -->
    </ng-container>

    <div>
      <!-- <mobile-sidebar-button
        icon="logout"
        buttonName="sidebar.navigation.mobile.logout"
        (click)="logOut()"
      ></mobile-sidebar-button> -->
    </div>
  </section>
</div>
