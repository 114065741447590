<ui-click-out-handler
  *ngIf="showDropdown"
  (clickEvent)="toggleDropdown()"
></ui-click-out-handler>

<div class="ui-table-date-tool">
  <div
    class="ui-table-date-tool--trigger"
    (click)="toggleDropdown()"
  >
    <button
      ui-button
      class="date-button"
      color="linkBordedRegular"
      modifier="gray"
      custom-icon="true"
      isSvg="true"
      icon="icon_calendar"
    >
      {{ appliedTriggerButtonLabel || defaultTriggerButtonLabel }}
    </button>
  </div>

  <div
    *ngIf="showDropdown"
    class="ui-table-date-tool--dropdown"
    [ngClass]="{
        'ui-table-date-tool--dropdown-lower': displayDropdownBelowButton(), 
        'ui-table-date-tool--dropdown-upper': !displayDropdownBelowButton(),
        'ui-table-date-tool--dropdown-left': displayDropdownToLeft
      }"
  >

    <div class="preset-date-filters">
      <button
        *ngFor="let presetOption of presetOptions"
        ui-button
        class="preset-filter-button"
        [ngClass]="{'is-selected' : currentTriggerButtonLabel === presetOption.translatedLabel}"
        color="transparentFlat"
        (click)="onClickPresetDateFilterButton(presetOption)"
      >
        {{ presetOption.translatedLabel }}
      </button>
    </div>

    <div
      class="custom-date-filters"
      *ngIf="showCustomDatetimePickers"
    >
      <button
        ui-button
        class="custom-date-filters--button"
        [ngClass]="{'is-selected' : isUsingCustomTimePeriod}"
        color="transparentFlat"
        (click)="onClickCustomTimePeriodFilterButton()"
      >
        {{ "ui.table-date-tool.periods.custom" | t:{locale:locale, maxDaysInCustomRange:maxDaysInCustomRange} }}
      </button>

      <div class="custom-date-filters--date-select-container date-from">
        <ui-datetime-picker
          #datetimePickerStart
          [defaultTimestamp]="customTimePeriod.timestampFrom || null"
          [minDate]="getMinDate('dateFrom')"
          [maxDate]="getMaxDate('dateFrom')"
          [withHours]="withHours"
          [showNowButton]="true"
          [showResetButton]="customTimePeriod.timestampFrom > 0"
          [label]="'ui.table-date-tool.custom.labels.start_date' | t: { locale: locale }"
          [locale]="locale"
          (timestampChangedEvent)="onChangeCustomTimePeriod($event, customTimePeriod.changeEventSource.DatetimePickerStart)"
        ></ui-datetime-picker>
      </div>

      <div class="custom-date-filters--date-select-container date-to">
        <ui-datetime-picker
          #datetimePickerEnd
          [defaultTimestamp]="customTimePeriod.timestampTo || null"
          [minDate]="getMinDate('dateTo')"
          [maxDate]="getMaxDate('dateTo')"
          [withHours]="withHours"
          [showNowButton]="true"
          [showResetButton]="customTimePeriod.timestampTo > 0"
          [label]="'ui.table-date-tool.custom.labels.end_date' | t: { locale: locale }"
          [locale]="locale"
          (timestampChangedEvent)="onChangeCustomTimePeriod($event, customTimePeriod.changeEventSource.DatetimePickerEnd)"
        ></ui-datetime-picker>
      </div>
    </div>

    <div class="action-buttons-container">

      <div class="apply-filter-button-container">
        <button
          class="apply-filter-button"
          ui-button
          color="standard"
          icon="check"
          icon-size="small"
          [disabled]="!isApplyButtonAvailable"
          (click)="applyChanges()"
        >
          {{ "ui.table-date-tool.actions.apply" | t: {locale: locale} }}
        </button>
      </div>

      <div class="reset-filter-button-container">
        <button
          class="reset-filter-button"
          ui-button
          color="transparentFlat"
          icon="close"
          icon-size="small"
          [disabled]="!appliedTimePeriod && !currentTimePeriod"
          (click)="onClickResetButton()"
        >
          {{ "ui.table-date-tool.actions.clear" | t: {locale: locale} }}
        </button>
      </div>
    </div>
  </div>
</div>