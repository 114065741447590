import { Injectable } from "@angular/core";

import { I18nService } from "../i18n.service";

@Injectable({
  providedIn: 'root',
})
export class StaticTableDataMapper {
  constructor(private i18n: I18nService) {
  }

  public translate(columnsDefinition: any[], datasource: any): any {
    const columns = columnsDefinition.map((column) => ({
      ...column,
      label: this.i18n.translate(column.label),
      tooltipText: column.tooltipText ? this.i18n.translate(column.tooltipText) : null,
    }));
    const enumColumns = columns.filter((column) => column.enumPrefix);
    const translationKeyPrefixColumns = columns.filter((column) => column.translationKeyPrefix);
    for (const data of datasource) {
      [ ...enumColumns, ...translationKeyPrefixColumns ].forEach((column) => {
        const translationKey = this.getTranslationKey(data, column);
        data[`${column.field}Translated`] = this.i18n.translate(translationKey);
      });
    }

    return [ columns, datasource ];
  }

  private getTranslationKey(data: any, column: { translationKeyPrefix?: string, enumPrefix?: string, enumSuffix?: string, value?: any, field?: any }) {
    const prefix = column.translationKeyPrefix ? column.translationKeyPrefix : column.enumPrefix;
    const accessor = column.value ? column.value : column.field;
    return `${prefix}${data[accessor]}${column.enumSuffix || ''}`;
  }
}
