import {
  CategoryAxis,
  ColumnSeries,
  DateAxis,
  DurationAxis,
  LabelBullet,
  Legend,
  LineSeries,
  PieChart,
  PieSeries3D,
  ValueAxis,
  XYChart,
  XYCursor
} from '@amcharts/amcharts4/charts';
import { Label, Scrollbar, TimeUnit, addLicense, color, create, percent } from '@amcharts/amcharts4/core';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { I18nService } from '../../services/I18nService';

export enum ChartTypeEnum {
  XY = 'XYChart',
  PIE = 'PieChart',
  DONUT = 'DonutChart',
  CHORD_DIAGRAM = 'ChordDiagram',
  XY_HISTOGRAM = 'XYHistogramChart',
  LINE = 'LineChart',
  SIMPLE_FILLED_MINI_CHART = 'SimpleFilledMiniChart',
  SIMPLE_LINE_SHADED_MINI_CHART = 'SimpleLineShadedMiniChart'
}

export interface IAxisFormattingSettings {
  horizontalAxis?: {
    dateFormats?: {
      timeUnit: TimeUnit;
      value: string;
    };
    gridIntervals?: {
      timeUnit: TimeUnit;
      count: number;
    }[];
    baseInterval?: {
      timeUnit: TimeUnit;
      count: number;
    };
    tooltipDateFormat?: string;
  };
}

export interface IAxisValueFormattingSettings {
  numberFormat?: string;
  durationFormat?: {
    baseUnit?: TimeUnit;
  }
}

@Component({
  selector: 'dynamic-chart',
  templateUrl: './dynamic-chart.component.html',
  styleUrls: [ './dynamic-chart.component.scss' ],
})
export class DynamicChart implements AfterViewInit, OnDestroy, OnChanges {
  private readonly XY_CHART_HEIGHT = '225px';

  private readonly TOOLTIP_COLOR = '#404C5B';

  private readonly XY_SERIES_CORNER_RADIUS = 6;

  private readonly XY_SERIES_HEIGHT = 20;

  @Input() public data: any;

  @Input() public type: string;

  @Input() public name: string;

  @Input() public key: string;

  @Input() public title: string;

  @Input() public series: string[];

  @Input() public legend: any;

  @Input() public innerSeriesLabel: any;

  @Input() public colors: any;

  @Input() public pieChartHeight = '205px';

  @Input() public pieChartWidth = '380px';

  @Input() public pieChartRadius: any;

  @Input() public pieChartInnerRadius: number;

  @Input() public importantLine: string;

  @Input() public legendTemplate: string;

  @Input() public legendSecondColumnTemplate: string;

  @Input() public axisFormattingSettings: IAxisFormattingSettings;

  @Input() public hasLegendRedirection = false;

  @Input() public chartWidth: string;

  @Input() public locale = 'fr';

  @Input() public strokeColor = "#FFF";

  @Input() public strokeWidth = 1;

  @Input() public showTicksAndLabels = false;

  @Input() public axisXRotation: number = 0;

  @Input() public displayScrollbarX: boolean = false;

  @Input() public cursorBehavior: "zoomX" | "zoomY" | "zoomXY" | "selectX" | "selectY" | "selectXY" | "panX" | "panY" | "panXY" | "none" = 'selectX';

  @Input() public mouseWheelBehavior: "zoomX" | "zoomY" | "zoomXY" | "panX" | "panY" | "panXY" | "none" = 'none';

  @Input() public valueFormatter: IAxisValueFormattingSettings;

  @Output() redirectionValue: EventEmitter<any> = new EventEmitter<any>();

  @Output() afterRendering: EventEmitter<any> = new EventEmitter<any>();

  public chart: any;

  constructor (private readonly i18nService: I18nService) { }

  public ngOnDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.makeChart();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.data?.isFirstChange()) {
      if (this.chart) {
        this.chart.dispose();
      }
      this.makeChart();
    }
  }

  private makeChart() {
    addLicense('CH259911983');

    let chart = null;
    switch (this.type) {
      case ChartTypeEnum.PIE:
        chart = this.makePieChart();
        break;
      case ChartTypeEnum.XY:
        chart = this.makeXYChart();
        break;
      case ChartTypeEnum.XY_HISTOGRAM:
        chart = this.makeXYHistogramChart();
        break;
      case ChartTypeEnum.DONUT:
        chart = this.makeDonutChart();
        break;
      case ChartTypeEnum.LINE:
        chart = this.makeLineChart();
        break;
      case ChartTypeEnum.SIMPLE_FILLED_MINI_CHART:
        chart = this.makeSimpleFilledMiniChart();
        break;
      case ChartTypeEnum.SIMPLE_LINE_SHADED_MINI_CHART:
        chart = this.makeSimpleLineShadedMiniChart();
        break;
      default:
        console.log(`Unknown chart type: ${this.type}`);
        break;
    }

    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.data = this.data;

    chart.responsive.enabled = true;

    if (this.valueFormatter?.numberFormat) {
      chart.numberFormatter.numberFormat = this.valueFormatter?.numberFormat;
    }

    if (this.title) {
      const title = chart.titles.create();
      title.text = this.title;
      title.marginBottom = 30;
      title.align = "center";
    }

    if (this.displayScrollbarX) {
      chart.scrollbarX = new Scrollbar();
      chart.scrollbarX.parent = chart.bottomAxesContainer;
    }

    this.chart = chart;

    this.afterRendering.emit(this.chart);
  }

  private makeLineChart(): any {
    const chart = create(`${this.name}_ID`, XYChart);

    chart.mouseWheelBehavior = this.mouseWheelBehavior;

    const dateAxis = chart.xAxes.push(new DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.axisFills.template.disabled = true;
    dateAxis.renderer.ticks.template.disabled = true;
    dateAxis.renderer.labels.template.rotation = this.axisXRotation;

    if (this.axisFormattingSettings?.horizontalAxis?.dateFormats) {
      dateAxis.dateFormats.setKey(
        this.axisFormattingSettings.horizontalAxis.dateFormats.timeUnit,
        this.axisFormattingSettings.horizontalAxis.dateFormats.value
      );
      dateAxis.periodChangeDateFormats.setKey(
        this.axisFormattingSettings.horizontalAxis.dateFormats.timeUnit,
        this.axisFormattingSettings.horizontalAxis.dateFormats.value
      );
    }
    if (this.axisFormattingSettings?.horizontalAxis?.gridIntervals?.length) {
      dateAxis.gridIntervals.setAll(this.axisFormattingSettings.horizontalAxis.gridIntervals);
    }
    if (this.axisFormattingSettings?.horizontalAxis?.baseInterval) {
      dateAxis.baseInterval = this.axisFormattingSettings.horizontalAxis.baseInterval;
    }
    if (this.axisFormattingSettings?.horizontalAxis?.tooltipDateFormat) {
      dateAxis.tooltipDateFormat = this.axisFormattingSettings.horizontalAxis.tooltipDateFormat;
    }

    let valueAxis;
    if (this.valueFormatter?.durationFormat) {
      valueAxis = chart.yAxes.push(new DurationAxis());
      if (this.valueFormatter?.durationFormat?.baseUnit) {
        valueAxis.baseUnit = this.valueFormatter?.durationFormat.baseUnit;
      }
      valueAxis.durationFormatter.durationFormats = {
        millisecond: {
          millisecond: "SSS'ms'",
          second: "ss's'",
          minute: "mm'm'",
          hour: "hh'h'",
          day: "hh'h'",
          week: "hh'h'",
          month: `dd'${this.i18nService.translate('day')}s'`,
          year: `dd'${this.i18nService.translate('day')}s'`,
        },
      };
    } else {
      valueAxis = chart.yAxes.push(new ValueAxis());
    }
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;

    const axisTooltip = dateAxis.tooltip;
    axisTooltip.background.fill = color(this.TOOLTIP_COLOR);
    axisTooltip.background.strokeWidth = 0;
    axisTooltip.background.cornerRadius = 3;
    axisTooltip.dy = 5;

    const valueAxisTooltip = valueAxis.tooltip;
    valueAxisTooltip.background.fill = color(this.TOOLTIP_COLOR);
    valueAxisTooltip.background.strokeWidth = 0;
    valueAxisTooltip.background.cornerRadius = 3;

    if (this.legend) {
      chart.legend = new Legend();
      chart.legend.fontSize = 14;
    }

    for (const serie of this.series) {
      const series = chart.series.push(new LineSeries());
      series.dataFields.dateX = this.key;
      series.dataFields.valueY = serie;
      series.strokeWidth = 2;

      if (this.series.length === 1 || serie === this.importantLine) {
        chart.cursor = new XYCursor();
        chart.cursor.behavior = this.cursorBehavior;
        chart.cursor.xAxis = dateAxis;

        if (this.valueFormatter?.durationFormat) {
          series.tooltipText = '{valueY.formatDuration()}';
        } else {
          series.tooltipText = '{valueY}';
        }
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.strokeWidth = 0;
        series.tooltip.background.cornerRadius = 3;
        series.tooltip.background.fill = color(this.TOOLTIP_COLOR);
      }

      if (this.colors && this.colors[serie]) {
        series.stroke = color(this.colors[serie]);
      }

      if (this.legend && this.legend[serie]) {
        series.legendSettings.labelText = this.legend[serie];
      }
    }

    chart.svgContainer.htmlElement.style.height = this.XY_CHART_HEIGHT;

    return chart;
  }

  private makePieChart(): any {
    const chart = create(`${this.name}_ID`, PieChart);
    for (const serie of this.series) {
      const series = chart.series.push(new PieSeries3D());
      series.dataFields.value = serie;
      series.dataFields.category = this.key;

      series.slices.template.stroke = color(this.strokeColor);
      series.slices.template.strokeWidth = this.strokeWidth;
      series.slices.template.strokeOpacity = 1;
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = color(this.TOOLTIP_COLOR);
    }

    chart.svgContainer.htmlElement.style.height = this.pieChartHeight;

    return chart;
  }

  private makeDonutChart(): any {
    const chart = create(`${this.name}_ID`, PieChart);
    chart.innerRadius = percent(this.pieChartInnerRadius || 30);
    if (this.pieChartRadius) {
      chart.radius = this.pieChartRadius;
    }

    for (const serie of this.series) {
      const series = chart.series.push(new PieSeries3D());
      series.dataFields.value = serie;
      series.dataFields.category = this.key;
      series.slices.template.stroke = color(this.strokeColor);
      series.slices.template.strokeWidth = this.strokeWidth;
      series.slices.template.strokeOpacity = 1;
      series.slices.template.propertyFields.fill = 'color';
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = color(this.TOOLTIP_COLOR);

      if (!this.showTicksAndLabels) {
        series.labels.template.disabled = true;
        series.ticks.template.disabled = true;
      } else {
        series.labels.template.maxWidth = 130;
        series.labels.template.wrap = true;
      }

      series.slices.template.cursorOverStyle = [
        {
          property: 'cursor',
          value: 'pointer',
        },
      ];

      if (this.innerSeriesLabel && this.innerSeriesLabel[serie]) {
        const label = series.createChild(Label);
        label.text = '{values.value.sum}';
        label.horizontalCenter = 'middle';
        label.verticalCenter = 'middle';
        label.fontSize = 22;
      }
    }

    if (this.legend) {
      chart.legend = new Legend();
      chart.legend.position = 'right';
      chart.legend.valign = 'middle';
      chart.legend.useDefaultMarker = true;
      chart.legend.itemContainers.template.paddingTop = 5;
      chart.legend.itemContainers.template.paddingBottom = 5;
      chart.legend.fontSize = 14;

      chart.numberFormatter.numberFormat = '#.';

      if (this.legendTemplate) {
        chart.legend.labels.template.text = this.legendTemplate;
        chart.legend.valueLabels.template.text = '';
      }

      if (this.legendSecondColumnTemplate) {
        chart.legend.valueLabels.template.text = this.legendSecondColumnTemplate;
      }

      if (this.hasLegendRedirection) {
        chart.legend.itemContainers.template.togglable = false;
        chart.legend.itemContainers.template.events.on('hit', (event) => {
          const labelValue = event.target.dataItem['name'];
          this.redirectionValue.emit(labelValue);
        });
      }

      const marker = chart.legend.markers.template.children.getIndex(0);
      marker['cornerRadius'](12, 12, 12, 12);
      marker.strokeWidth = 2;
      marker.strokeOpacity = 1;
      marker.stroke = color('#ccc');

      const markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 15;
      markerTemplate.height = 15;
    }

    chart.svgContainer.htmlElement.style.height = this.pieChartHeight;
    chart.svgContainer.htmlElement.style.width = this.pieChartWidth;

    return chart;
  }

  private makeXYChart(): any {
    const chart = create(`${this.name}_ID`, XYChart);

    const categoryAxis = chart.yAxes.push(new CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = this.key;
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.disabled = true;

    const valueAxis = chart.xAxes.push(new ValueAxis());
    valueAxis.min = 0;

    for (const serie of this.series) {
      const series = chart.series.push(new ColumnSeries());
      series.dataFields.categoryY = this.key;
      series.dataFields.valueX = serie;
      series.columns.template.strokeOpacity = 0;
      series.columns.template.column.cornerRadiusBottomRight = this.XY_SERIES_CORNER_RADIUS;
      series.columns.template.column.cornerRadiusTopRight = this.XY_SERIES_CORNER_RADIUS;
      series.columns.template.height = this.XY_SERIES_HEIGHT;
      series.columns.template.tooltipText = `{valueX.value} ${this.i18nService.translate('dashboard.admin.files'), { locale: this.locale as any }}`;
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = color(this.TOOLTIP_COLOR);

      const labelBullet = series.bullets.push(new LabelBullet());
      labelBullet.label.horizontalCenter = 'left';
      labelBullet.label.dx = 10;
      labelBullet.label.text = '{values.valueX.workingValue.formatNumber("#.")}';
      labelBullet.locationX = 1;

      categoryAxis.sortBySeries = series;
    }

    chart.svgContainer.htmlElement.style.height = this.XY_CHART_HEIGHT;

    return chart;
  }

  private makeXYHistogramChart(): any {
    const colors = [ '#C0362C', '#FF8642', '#F4DC85', '#816C5B', '#C3B7AC', '#668D3C' ];

    // Create axes
    const chart = create(`${this.name}_ID`, XYChart);

    // Create axes
    const dateAxis = chart.xAxes.push(new DateAxis());
    dateAxis.renderer.minGridDistance = 60;
    dateAxis.logarithmic = true;
    dateAxis.renderer.labels.template.rotation = this.axisXRotation;

    const valueAxis = chart.yAxes.push(new ValueAxis());
    // valueAxis.logarithmic = true;
    valueAxis.renderer.minGridDistance = 20;

    chart.yAxes.push(new ValueAxis());

    if (this.legend) {
      // Set legends
      chart.legend = new Legend();
    }

    // Create series
    let index = 0;
    for (const serie of this.series) {
      const series = chart.series.push(new LineSeries());
      series.dataFields.valueY = serie;
      series.dataFields.dateX = 'key';
      series.tooltipText = '{value}';
      series.tooltip.pointerOrientation = 'vertical';
      series.tensionX = 0.8;
      series.strokeWidth = 3;
      series.stroke = color(colors[index]); // red

      if (this.legend && this.legend[serie]) {
        series.legendSettings.labelText = this.legend[serie].name[this.locale];
      }

      // var bullet = series.bullets.push(new am4charts.CircleBullet());
      // bullet.circle.fill = am4core.color("#fff");
      // bullet.circle.strokeWidth = 3;

      index++;
    }

    // Add cursor
    chart.cursor = new XYCursor();
    chart.cursor.fullWidthLineX = true;
    chart.cursor.xAxis = dateAxis;
    chart.cursor.lineX.strokeWidth = 0;
    chart.cursor.lineX.fill = color('#000');
    chart.cursor.lineX.fillOpacity = 0.1;

    // Add a guide
    const range = valueAxis.axisRanges.create();
    range.value = 90.4;
    range.grid.stroke = color('#396478');
    range.grid.strokeWidth = 1;
    range.grid.strokeOpacity = 1;
    range.grid.strokeDasharray = '3,3';
    range.label.inside = true;
    range.label.text = 'Average';
    range.label.fill = range.grid.stroke;
    range.label.verticalCenter = 'bottom';

    chart.svgContainer.htmlElement.style.height = this.XY_CHART_HEIGHT;

    return chart;
  }

  private makeSimpleFilledMiniChart(): any {
    const chart = create(`${this.name}_ID`, XYChart);

    const dateAxis = chart.xAxes.push(new DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.labels.template.disabled = true;
    dateAxis.renderer.labels.template.rotation = this.axisXRotation;

    const valueAxis = chart.yAxes.push(new ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;

    for (const serie of this.series) {
      const series = chart.series.push(new LineSeries());
      series.dataFields.dateX = this.key;
      series.dataFields.valueY = serie;
      series.strokeWidth = 3;
      series.fillOpacity = 1;
    }

    chart.svgContainer.htmlElement.style.height = '60px';

    return chart;
  }

  private makeSimpleLineShadedMiniChart(): any {
    const chart = create(`${this.name}_ID`, XYChart);

    const dateAxis = chart.xAxes.push(new DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.labels.template.disabled = true;
    dateAxis.renderer.labels.template.rotation = this.axisXRotation;

    const valueAxis = chart.yAxes.push(new ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;

    for (const serie of this.series) {
      const series = chart.series.push(new LineSeries());
      series.dataFields.dateX = this.key;
      series.dataFields.valueY = serie;
      series.strokeWidth = 1.5;
      series.fillOpacity = 0.5;
    }

    chart.svgContainer.htmlElement.style.height = '27px';

    return chart;
  }
}
