import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'ui-select-single',
  templateUrl: './ui-select-single.component.html',
  styleUrls: [ './ui-select-single.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-select-single',
  },
})
export class UiSelectSingle implements OnInit {
  @Input('options') options: any[];

  @Input('fieldStyle') fieldStyle = {};

  @Input('placeholder') placeholder: string;

  @Input('displayValueKey') key: string;

  @Input('label') label: string;

  @Output('valueChange') valueChange = new EventEmitter();

  @Input() showOptions = true;

  @Input() displayInitialValue = false;

  @Input() initialValue: any;

  @Input() readonly: boolean = false;

  value: any;

  @HostListener('document:click', [ '$event' ])
  clickOut(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showOptions = false;
    }
  }

  @HostListener('keydown.escape', [ '$event' ])
  onEscapeKey(event: KeyboardEvent) {
    if (this.showOptions) {
      event.stopPropagation();
      this.showOptions = false;
    }
  }

  @Input('value') set valueSetter(val) {
    this.value = val || {};
  }

  constructor(private eRef: ElementRef) { }

  ngOnInit(): void {
    if (this.displayInitialValue) {
      this.valueSetter = this.initialValue;
    }
  }

  public onOptionClick(option: any) {
    this.value = option;
    this.valueChange.emit(option);
  }

  public toggleOptions() {
    if (!this.readonly) {
      this.showOptions = !this.showOptions;
    }
  }
}
