<div
  *ngIf="users.length > 0 && !isSearchingUsers"
  class="choices-list"
  [ngStyle]="{ height: expandHeight }"
  uiAccessibleChoiceListDirective
  [choices]="users"
  (choiceSelectedEmitter)="onUserSelected($event.choice)"
>
  <ng-container *ngFor="let user of users; let index = index">
    <div [ngClass]="{ 'multi-select-autocomplete': canMultiSelect }" [attr.disabled]="user.disabled">
      <div
        [id]="'rowElement_' + index"
        [class.hover]="!user.disabled"
        class="choice separator accessible-choice"
        (mousedown)="onUserClick(user, index, $event, user.disabled)"
        [ngClass]="{ 'disabled-user-name': user.disabled }"
        [tooltip]="
          user.disabled
            ?  (disabledLabel | t : { locale: locale })
            : ''
        "
        [tooltipDelay]="100"
        (mouseenter)="hoverElement(user, !user.disabled)"
        (mouseleave)="hoverElement(user, false)"
      >
        <div class="choice-infos-row">
          <div class="choice-infos-container">
            <div class="row-icon">
              <ui-avatar size="small" [user]="user"></ui-avatar>
              <div
                *ngIf="user['isHovered']"
                class="hovered-element-row"
              >
                <ui-icon
                  class="hovered-element-row--add-icon"
                  name="plus"
                  modifier="white"
                  size="medium"
                ></ui-icon>
              </div>
            </div>

            <div class="choices-infos">
              <div>
                {{ user | fullname }}{{user.disabled ? '***' : ''}}
              </div>
              <div class="email-part">
                {{ user.mail || user.email || user.description || "-" }}
              </div>
            </div>
            <user-not-onboarded-icon
              *ngIf="isNotOnboarded(user)"
              [effectiveDate]="user.effectiveDate"
              [locale]="locale"
            ></user-not-onboarded-icon>
          </div>
          <div *ngIf="showUserType" class="user-type" >
            <div *ngIf="user.guestUser; else logoType">
              {{ "user-autocomplete.guest" | t : { locale: locale } }}
            </div>
            <ng-template #logoType>
              <img
                class="user-type-image"
                *ngIf="user.platformUser"
                [src]="'assets/images/logof2s.png'"
                alt="platform"
              />
              <img
                class="user-type-image"
                *ngIf="!user.platformUser"
                [src]="'assets/images/office365/o365.png'"
                alt="platform"
              />
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div
  *ngIf="users.length < 1 && !isSearchingUsers"
  class="choices-list no-items"
>
  <span *ngIf="!customNotFound"  class="no-items--label">
    <i>{{ "user-autocomplete.notFound" | t : { locale: locale } }}</i>
  </span>

  <ng-content *ngIf="customNotFound"></ng-content>
</div>
