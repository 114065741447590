import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngxs/store";
import { ModalService } from "@ui-kit/services/modal.service";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { SetUserNotificationEmail } from "projects/@common/modules/session/state/session.state";
import { NotificationsApiService } from "projects/@common/services/api/notifications/notifications-api.service";
import { CustomValidators } from "projects/@common/utils/validators";

@Component({
  selector: 'email-initializer',
  templateUrl: './email-initializer.component.html',
  styleUrls: [ './email-initializer.component.scss' ],
})
export class EmailInitializerComponent implements OnInit {
  public isEditing = false;
  public forceUpdate = false;

  public formGroup: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { email: string },
    protected readonly notificationsApiService: NotificationsApiService,
    protected readonly modalService: ModalService,
    protected readonly formBuilder: UntypedFormBuilder,
    public readonly i18n: I18nService,
    protected readonly store: Store
  ) {
    this.formGroup = this.formBuilder.group({
      email: this.formBuilder.control('', [ Validators.required, CustomValidators.validateEmailAddress ]),
    });
  }
  ngOnInit(): void {
    // If there's no email set on the identify, we force the user to set one.
    if (!this.data.email) {
      this.toggleEditing();
      this.forceUpdate = true;
    }
  }

  public get toogleButtonText(): string {
    return this.i18n.translate(this.isEditing ? 'common.action.cancel' : 'common.action.update');
  }

  public async saveCurrentEmail(): Promise<void> {
    this.save(this.data.email);
  }

  public async saveNewEmail(): Promise<void> {
    const values = this.formGroup.getRawValue();
    this.save(values['email']);
  }

  public toggleEditing(): void {
    this.isEditing = !this.isEditing;
  }

  public closeDialog(): void {
    this.modalService.closeDialog();
  }

  private async save(email): Promise<void> {
    try {
      await this.notificationsApiService.updateEmail(email);
      await this.store.dispatch(new SetUserNotificationEmail(email)).toPromise();
    } catch (error) {
      console.log(error);
    } finally {
      this.modalService.closeDialog();
    }
  }
}
