import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'main-content',
  templateUrl: './main-content.component.html',
  styleUrls: [ './main-content.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'main-content',
  },
})
export class MainContent {}
