import { randomBytes } from 'crypto';
import { BytesUtils, ZERO, BITS_IN_BYTE } from './bytes.utils';

export type Config = {
  sizeBytes: number;
  timeBytes: number;
  incrementBytes: number;
  getTime: () => number;
  getRandomBytes: (sizeBytes: number) => number[];
};

export const DEFAULT_SIZE_BYTES = 32;
export const DEFAULT_TIME_BYTES = 6;
export const DEFAULT_INCREMENT_BYTES = 2;
export const DEFAULT_GET_TIME = Date.now;
export const DEFAULT_GET_RANDOM_BYTES = (sizeBytes: number) => [ ...randomBytes(sizeBytes) ];

export class NonceUtils {
  private _sizeBytes: number;
  private _incrementBytes: number;
  private _timeBytes: number;
  private _randomBytes: number;

  private incrementMaxValue: bigint;
  private randomBits: bigint;
  private incrementBits: bigint;
  private timeBits: bigint;

  protected nonceIncrement = ZERO;
  protected lastIncrement = 0;

  public getTime: () => number;
  public getRandomBytes: (sizeBytes: number) => number[];

  public constructor(config?: Partial<Config>) {
    this._sizeBytes = config?.sizeBytes == null ? DEFAULT_SIZE_BYTES : config.sizeBytes;
    this._incrementBytes = config?.incrementBytes == null ? DEFAULT_INCREMENT_BYTES : config.incrementBytes;
    this._timeBytes = config?.timeBytes == null ? DEFAULT_TIME_BYTES : config.timeBytes;
    this.getTime = config?.getTime == null ? DEFAULT_GET_TIME : config.getTime;
    this.getRandomBytes = config?.getRandomBytes == null ? DEFAULT_GET_RANDOM_BYTES : config.getRandomBytes;
    this.clean();
  }

  public get sizeBytes() {
    return this._sizeBytes;
  }

  public set sizeBytes(bytes: number) {
    this._sizeBytes = bytes;
    this.clean();
  }

  public get incrementBytes() {
    return this._incrementBytes;
  }

  public set incrementBytes(bytes: number) {
    this._incrementBytes = bytes;
    this.clean();
  }

  public get timeBytes() {
    return this._timeBytes;
  }

  public set timeBytes(bytes: number) {
    this._timeBytes = bytes;
    this.clean();
  }

  public get randomBytes() {
    return this._randomBytes;
  }

  /**
     * Generates a unique nonce. It's time based (thus increasing), semi-random with duplicate protection.
     * @returns a unique stringified number
     */
  public generateNonce(): string {
    const now = this.getTime();

    const time = this.getNonceTime(now);
    const increment = this.getNonceIncrement(now);
    const random = this.getNonceRandomValue();

    let nonce = time;

    nonce <<= this.incrementBits;
    nonce |= increment;

    nonce <<= this.randomBits;
    nonce |= random;

    return nonce.toString();
  }

  /**
     * Generates a unique nonce. It's time based (thus increasing), semi-random with duplicate protection.
     * @returns a unique stringified number
     */
  public static generateNonce(config?: Partial<Config>): string {
    return new NonceUtils(config).generateNonce();
  }

  private clean() {
    this._randomBytes = this.sizeBytes - this.incrementBytes - this.timeBytes;
    if (this.sizeBytes < 0 || this.incrementBytes < 0 || this.timeBytes < 0) {
      throw new Error('Cannot set a negative amount of bytes.');
    }
    if (this.randomBytes < 0) {
      throw new Error(`Total size (${this.sizeBytes}) cannot be smaller than increment bytes (${this.incrementBytes}) + time bytes (${this.timeBytes})`);
    }

    this.incrementBits = BigInt(this.incrementBytes) * BITS_IN_BYTE;
    this.randomBits = BigInt(this.randomBytes) * BITS_IN_BYTE;
    this.timeBits = BigInt(this.timeBytes) * BITS_IN_BYTE;
    this.incrementMaxValue = BigInt(2 ** (this.incrementBytes * 8));
  }

  private getNonceTime(now: number): bigint {
    // When the system updates its time it may go back by a few milliseconds
    // An alternative would be to use performance.now(), but it's 50% slower
    if (now < this.lastIncrement) {
      now = this.lastIncrement;
    }

    const nowBits = BigInt(now.toString(2).length);
    const bigNow = BigInt(now);

    // If too big we remove the first bits to only keep the most significant ones
    const truncateBits = nowBits - this.timeBits;
    return truncateBits > 0 ? bigNow >> BytesUtils.nearestBitsQtyForByte(nowBits - this.timeBits) : bigNow;
  }

  private getNonceIncrement(now: number): bigint {
    if (now > this.lastIncrement) {
      this.nonceIncrement = ZERO;
      this.lastIncrement = now;
    } else {
      this.nonceIncrement++;
      this.nonceIncrement %= this.incrementMaxValue;
    }

    return this.nonceIncrement;
  }

  private getNonceRandomValue(): bigint {
    const randomBytes = this.getRandomBytes(this.randomBytes);
    return BytesUtils.byteArrayToNumber(randomBytes);
  }
}
