import { AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import * as uuid from 'uuid';
import { LanguageEnum } from '../../interfaces/ILanguage';

import { GroupTypeEnum } from '../../util/icon-util';
import { AbstractAutocomplete } from './AbstractAutocomplete';
import { AllSelectorCustomValue } from "./organization-autocomplete/organization-autocomplete.component";

export enum AutocompleteTypes {
  SECURITY_GROUP,
  USERS,
  USERS_OFFLINE,
  RESOURCES,
  PROFILES,
  STRING,
  ORGANIZATIONS,
  CUSTOM
}

export enum StringAutocompleteTypes {
  OTHER,
  DEPARTMENT,
  JOB_TITLE,
  HUB_SITE
}

enum ResourcesTypeEnum {
  ALL = 'allResources',
  APPLICATION = 'application',
  TEAMS = 'teams',
  O365 = 'groupO365',
  LICENSES_AVAILABLE = 'licensesAvailable',
  EXTERNAL = 'groupExternal',
  WEB_SITE = 'web_site',
  MONITORING = 'groupMonitoring',
  O365SECURITY = 'securityGroup',
  ROLE = 'role'
}

@Component({
  selector: 'autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: [ './autocomplete.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'autocomplete',
  },
})
export class Autocomplete
implements AfterViewInit, OnDestroy, AfterContentInit, OnChanges {
  @Input() public searchFunction: (args: any) => Promise<any>;
  @Input() public searchLimit = 50;
  @Input() public disabled = false;
  @Input() public oneItemAtATime = false;
  @Input() public showInInputAtInit = false;
  @Input() public canMultiSelect = false;
  @Input() public label: string = null;
  @Input() public name: string = null;
  @Input() public showUserType = false;
  @Input() public required: boolean;
  @Input() public customNotFound: boolean;
  @Input() public filter: any;
  @Input() public placeholder: string;
  @Input() public autocompleteType: AutocompleteTypes;
  @Input() public userPermission: any;
  @Input() public searchOnlyUserResources: boolean;
  @Input() public showSearchIcon = true;
  @Input() public isPermissive = false;
  @Input() public stringAutocompleteType: StringAutocompleteTypes;
  @Input() public customValues: any[] = [];
  @Input() public inputMaxLength: string;
  @Input() public groupGuid: string;
  @Input() public includeResourceFilter = false;
  @Input() public itemsToIgnore: any[] = [];
  @Input() public minNumLicenses = 0;
  @Input() public profileGuid = '';
  @Input() public imageWithBorder: boolean;
  @Input() public useIAMBackend = false;
  @Input() public initItems: any[] = [];
  @Input() public maxHeight: boolean = false;
  @Input() public locale: LanguageEnum = LanguageEnum.FRENCH;
  @Input() public expandHeight = '20rem';
  @Input() public toggleDropdownAfterAllSelection = false;
  @Input() public preserveSearchQuery = false;
  @Input() public preserveSearchQueryWithOneItemAtTime = true;
  @Input() public allSelectorCustomValue?: AllSelectorCustomValue | null = null;
  @Input() public isInError: boolean = false;

  // Specific for user offline type
  @Input() public offlineUsers: { name: string; o365UserId: string }[] = [];
  @Input() public disabledLabel = 'user-autocomplete.disabled';

  @Output() selectedItemsChange = new EventEmitter();
  @Output() public isSearchCleared: EventEmitter<void> = new EventEmitter();
  @Output() public onItemSelected: EventEmitter<any> = new EventEmitter();
  @Output() public onTextValueChanged: EventEmitter<string> = new EventEmitter();
  @Output() loadedList: EventEmitter<any> = new EventEmitter();
  public filterRolesOnly = false;
  public selectedItemsValue: any[] = [];
  public searchQuery = '';
  public searchQueryUpdate = new Subject<string>();
  public isSearching = false;
  public showSuggestions = false;
  public autocompleteTypes = AutocompleteTypes;
  public resourcesType = ResourcesTypeEnum;
  @ViewChild('autocompleteSuggestions', { static: false })
  private autocompleteSuggestions: AbstractAutocomplete;
  private readonly DEBOUNCE_TIME = 400;
  private autocompleteSearchTimeout: any;
  private searchQueryUpdateSubscription: Subscription;

  constructor(private eRef: ElementRef) {
  }

  @Input()
  get selectedItems(): any[] {
    return this.selectedItemsValue;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set selectedItems(value: any[]) {
    this.selectedItemsValue = value;
    this.selectedItemsChange.emit(this.selectedItemsValue);
  }

  @HostListener('keydown.escape', [ '$event' ])
  onEscapeKey(event: KeyboardEvent) {
    event.stopPropagation();
    if (this.showSuggestions) {
      this.toggleDropdown();
    } else if (this.searchQuery) {
      this.clearSearchQuery();
    }
  }
  @HostListener('document:click', [ '$event' ])
  onClickEvent(event: MouseEvent) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeDropdown();
    }
  }

  public ngOnDestroy() {
    this.searchQueryUpdateSubscription?.unsubscribe();
  }

  public ngAfterContentInit() {
    this.initItems.forEach((item) => {
      if (!this.selectedItemsValue.includes(item)) {
        this.selectedItemsValue.push(item);
      }
    });
    if (this.oneItemAtATime && this.showInInputAtInit) {
      const selectedItem = this.selectedItems?.[0];
      this.searchQuery = selectedItem ? this.getItemName(selectedItem) : '';
    }
  }

  public ngAfterViewInit() {
    this.searchQueryUpdateSubscription = this.searchQueryUpdate.subscribe((value) => {
      this.onItemSelected.emit(null);
      this.onTextValueChanged.emit(value);
      this.searchItems(value, this.DEBOUNCE_TIME);
    });
  }

  public ngOnChanges(modified: SimpleChanges) {
    if (modified?.iniItems) {
      if (!modified?.iniItems.isFirstChange()) {
        if (
          modified.iniItems.currentValue.find((element) => element.displayValue === this.searchQuery)
        ) {
          if (this.oneItemAtATime && this.showInInputAtInit) {
            if (modified.iniItems.currentValue.length > 0) {
              this.searchQuery = this.getItemName(modified.iniItems.currentValue[0]);
            }
          }
        } else {
          this.clearSearchQuery();
        }
      }
    }
  }

  public onInputClick(event) {
    if (event.stopPropagation) event.stopPropagation();
    this.handleShowSuggestionDropdown();
  }

  public onInputFocus() {
    this.handleShowSuggestionDropdown();
  }

  public onInputTyping() {
    this.handleShowSuggestionDropdown();
  }

  public onClearButtonClicked(event: PointerEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.clearSearchQuery();
    this.focusSearchInput();
  }

  public toggleDropdown() {
    if (this.showSuggestions) {
      this.closeDropdown();
    } else {
      this.openDropdown();
    }
  }

  public closeDropdown() {
    this.showSuggestions = false;
  }

  public openDropdown() {
    this.showSuggestions = true;
  }

  public handleShowSuggestionDropdown() {
    if (!this.showSuggestions) {
      this.toggleDropdown();
      this.searchItems(this.searchQuery, 1);
    }
  }

  public onItemClick(item: any, itemName: string) {
    if (!this.canMultiSelect || this.toggleDropdownAfterAllSelection) {
      this.toggleDropdown();
    }

    if (this.oneItemAtATime && !this.canMultiSelect) {
      this.searchQuery = itemName;
    } else if (!this.preserveSearchQuery) {
      this.searchQuery = '';
    }

    if (!this.preserveSearchQueryWithOneItemAtTime) {
      this.searchQuery = '';
    }

    this.onItemSelected.emit(item);

    // prevent the autocomplete input to lose the focus so that it remains usable
    if (!this.oneItemAtATime) {
      this.focusSearchInput();
    }
  }

  public clearSearchQuery() {
    this.searchQuery = '';
    this.isSearchCleared.emit();
    this.onTextValueChanged.next(this.searchQuery);

    if (this.oneItemAtATime && !this.canMultiSelect) {
      this.selectedItems = [];

      this.onItemSelected.emit(null);
    }
  }

  public onBlurManager() {
    if (this.isPermissive) {
      this.onItemSelected.emit(this.searchQuery);
    }
  }

  public getInputMaxLength(): string {
    if (this.inputMaxLength) {
      return this.inputMaxLength;
    }
    switch (this.stringAutocompleteType) {
      case StringAutocompleteTypes.DEPARTMENT:
        return '64';
      case StringAutocompleteTypes.JOB_TITLE:
        return '200';
      default:
        return '';
    }
  }

  public handleProfileResourcesFilter(filter: ResourcesTypeEnum) {
    switch (filter) {
      case this.resourcesType.TEAMS:
        this.filterRolesOnly = false;
        this.filter = GroupTypeEnum.O365OFFICETEAMS;
        break;
      case this.resourcesType.O365:
        this.filterRolesOnly = false;
        this.filter = GroupTypeEnum.O365OFFICEGROUP;
        break;
      case this.resourcesType.O365SECURITY:
        this.filterRolesOnly = false;
        this.filter = GroupTypeEnum.O365SECURITYGROUP;
        break;
      case this.resourcesType.LICENSES_AVAILABLE:
        this.filterRolesOnly = false;
        this.filter = GroupTypeEnum.LICENSES_AVAILABLE;
        break;
      case this.resourcesType.EXTERNAL:
        this.filterRolesOnly = false;
        this.filter = GroupTypeEnum.EXTERNAL;
        break;
      case this.resourcesType.WEB_SITE:
        this.filterRolesOnly = false;
        this.filter = GroupTypeEnum.WEB_SITE;
        break;
      case this.resourcesType.MONITORING:
        this.filterRolesOnly = false;
        this.filter = GroupTypeEnum.MONITORING;
        break;
      case this.resourcesType.APPLICATION:
        this.filterRolesOnly = false;
        this.filter = GroupTypeEnum.APPLICATION;
        break;
      case this.resourcesType.ROLE:
        this.filterRolesOnly = true;
        this.filter = null;
        break;
      default:
      case this.resourcesType.ALL:
        this.filterRolesOnly = false;
        this.filter = null;
        break;
    }
  }

  private searchItems(searchQuery: string, debounceTime: number) {
    clearTimeout(this.autocompleteSearchTimeout);

    this.autocompleteSearchTimeout = setTimeout(() => {
      const searchID = uuid.v4();
      if (this.autocompleteSuggestions) {
        this.autocompleteSuggestions.currentSearchID = searchID;
        this.autocompleteSuggestions.searchItems(
          searchQuery,
          searchID,
          this.useIAMBackend
        );
      }
    }, debounceTime);
  }

  private getItemName(item: any): string {
    if (!item) return '';

    switch (this.autocompleteType) {
      case AutocompleteTypes.USERS:
        return `${item.firstName} ${item.lastName}`;

      case AutocompleteTypes.RESOURCES:
        return item.displayName;

      case AutocompleteTypes.PROFILES:
        return item.name;

      case AutocompleteTypes.STRING:
        return item;

      case AutocompleteTypes.CUSTOM:
        return item.displayValue;

      case AutocompleteTypes.ORGANIZATIONS:
        return item.displayValue;

      default:
        return '';
    }
  }

  private focusSearchInput() {
    this.eRef.nativeElement
      ?.querySelector('input.autocomplete--value')
      ?.focus();
    this.onInputFocus();
  }
}
