export const ADM_APP_PAGES = {
  mdIam: {
    organizations: {
      pageTitleTranslationKey: 'iam.organizations.list.title',
    },
  },
  mdRespond: {
    dashboard: {
      pageTitleTranslationKey: 'respond.dashboard.title',
    },
    reports: {
      pageTitleTranslationKey: 'respond.reports.title',
    },
    kpi: {
      pageTitleTranslationKey: 'respond.kpi.title',
    },
    usecases: {
      pageTitleTranslationKey: 'org_usecase.title',
    },
    datasources: {
      pageTitleTranslationKey: 'datasources.title',
    },
    organizations: {
      pageTitleTranslationKey: 'respond.organizations.title',
    },
    playbooks: {
      pageTitleTranslationKey: 'sidebar.navigation.respond.playbooks',
    },
    usecasesCatalog: {
      pageTitleTranslationKey: 'sidebar.navigation.respond.usecases.catalog',
    },
    alerts: {
      pageTitleTranslationKey: 'alerts.container.page.title',
    },
    incidents: {
      pageTitleTranslationKey: 'incidents.container.page.title',
    },
    communication: {
      pageTitleTranslationKey: 'respond.communication.title',
    },
    actifs: {
      pageTitleTranslationKey: 'actifs.page.title',
    },
    whitelists: {
      pageTitleTranslationKey: 'whitelists.page.title',
    },
    filters: {
      pageTitleTranslationKey: 'filters.page.title',
    },
    queries: {
      pageTitleTranslationKey: 'respond.queries.title',
    },
    histories: {
      pageTitleTranslationKey: 'histories.page.title',
    },
  },
  mdDetect: {
    connectors: {
      pageTitleTranslationKey: 'connectors.container.page.title',
    },
    hosts: {
      pageTitleTranslationKey: 'hosts.container.page.title',
    },
  },
  mdTools: {
    parameterStore: {
      pageTitleTranslationKey: 'parameter.title',
    },
    secretsStore: {
      pageTitleTranslationKey: 'secrets.title',
    },
  },
  mdAIR: {
    incidentList: {
      pageTitleTranslationKey: 'AR.page.title',
    },
  },
};
