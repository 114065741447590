import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { clone } from '../../../services/utils';

export interface DialogStoreModel {
  shown: boolean;
}

export const initialState: DialogStoreModel = {
  shown: false,
};

export class HideDialog {
  static readonly type = '[Dialog] Hide';
}

export class ShowDialog {
  static readonly type = '[Dialog] Show Component';
}

// DEP - store
@State<DialogStoreModel>({
  name: 'dialog',
  defaults: clone(initialState),
})
@Injectable()
export class DialogState {
  public constructor() {}

  @Selector()
  public static isShown(state: DialogStoreModel): boolean {
    return state.shown;
  }

  @Action(ShowDialog)
  public show(ctx: StateContext<DialogStoreModel>): void {
    // ctx.dispatch(new SetTopbarState({ disabled: true }));
    ctx.patchState({
      shown: true,
    });
  }

  @Action(HideDialog)
  public hide(ctx: StateContext<DialogStoreModel>): void {
    // ctx.dispatch(new SetTopbarState({ disabled: false }));
    ctx.patchState({
      shown: false,
    });
  }
}
