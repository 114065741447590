import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { clone } from 'projects/@common/utils/utils';
import { I18nService } from '../../i18n/i18n.service';

const POPUP_TIMER = 1500;
const POPUP_DESTROY_TIMER = 100;
const MAX_RANDOM_ID = 999999;

export interface MousePopupLocation {
  x: number;
  y: number;
}

export interface MousePopup {
  id: number;
  message: string;
  location: MousePopupLocation;
  hide?: boolean;
}

export interface MousePopupStoreModel {
  popup: MousePopup;
}

export const initialState: MousePopupStoreModel = {
  popup: null,
};

export class PopupOnMouseLocation {
  static readonly type = '[Mouse Popup] Popup on mouse location';

  public constructor(public message: string, public params: MousePopupLocation = null) {}
}

@State<MousePopupStoreModel>({
  name: 'mousePopup',
  defaults: clone(initialState),
})
@Injectable()
export class MousePopupState {
  public constructor(private i18nService: I18nService) {}

  @Selector()
  public static popups(state: MousePopupStoreModel): MousePopup[] {
    return [ state.popup ];
  }

  @Action(PopupOnMouseLocation)
  public popupOnMouseLocation(ctx: StateContext<MousePopupStoreModel>, action: PopupOnMouseLocation): void {
    const popupId = Math.floor(Math.random() * Math.floor(MAX_RANDOM_ID));

    const popup: MousePopup = {
      id: popupId,
      message: this.i18nService.translate(action.message),
      location: action.params,
    };

    ctx.patchState({
      popup,
    });

    setTimeout(() => this.preparePopupForDestruction(ctx, popupId), POPUP_TIMER);
  }

  public preparePopupForDestruction(ctx: StateContext<MousePopupStoreModel>, popupId: number): void {
    const popup = { ...ctx.getState().popup };

    if (popupId === popup?.id) {
      popup.hide = true;

      ctx.patchState({
        popup,
      });

      setTimeout(() => this.destroyPopup(ctx, popupId), POPUP_DESTROY_TIMER);
    }
  }

  public destroyPopup(ctx: StateContext<MousePopupStoreModel>, popupId: number): void {
    const popup = { ...ctx.getState().popup };

    if (popupId === popup?.id) {
      ctx.patchState({
        popup: null,
      });
    }
  }
}
