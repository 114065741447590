import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewEncapsulation } from '@angular/core';
import { LanguageEnum } from '../../interfaces/ILanguage';
import { UiTableDirection } from '../ui-table/ui-table.component';
import { UiStaticTableRowType } from './ui-static-table-row/ui-static-table-row.component';

@Component({
  selector: 'ui-static-table',
  templateUrl: './ui-static-table.component.html',
  styleUrls: [ './ui-static-table.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-static-table',
  },
})
export class UiStaticTable implements OnChanges {
  @Input() public sort: string = null;
  @Input() public sortHeader: string = null;
  @Input() public direction: UiTableDirection = UiTableDirection.None;
  @Input() public subject = '';
  @Input() public dataSource: any[] = [];
  @Input() public columnsDef: any[] = [];
  @Input() public addPaddingOnFirst = true;
  @Input() public defaultBackgroundColor = true;
  @Input() public hoverable = true;
  @Input() public twoHeaders = false;
  @Input() public smallRow = false;
  @Input() public isCustomSorting = false;
  @Input() public expendedTemplate: TemplateRef<any>;
  @Input() public emitClickEventOnExpandedRow: boolean;
  @Input() public canSelectItems = true;
  @Input() public canSelectOnlyOne = false;
  @Input() public dynamicTable = false;
  @Input() public isSelectingOnlyOneAtATime = true;
  @Input() public locale: string = LanguageEnum.FRENCH;
  @Input() public loading = false;
  @Input() public useEmptyMessage = true;
  @Input() public fieldNameToCheckForBoldRow: string;

  @Output('on-row-click') public onRowClickEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output('checkbox-event') public checkboxEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output('checkbox-select-all-event') public checkboxSelectAllEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('component-emitter') public componentEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() public handleCustomSort: EventEmitter<any> = new EventEmitter<any>();

  public ctx: any[] = [];
  public sortColumn: string;
  public sortParent: string;
  public sortDirection: UiTableDirection;
  public sortedDatasource: any[] = [];
  public uiStaticTableRowType = UiStaticTableRowType;
  public hoveredRow: any;
  public clickedRows: string[] = [];

  constructor(private cdr: ChangeDetectorRef) {
  }

  public get columns(): string[] {
    const out: string[] = [];

    if (this.columnsDef) {
      for (const def of this.columnsDef) {
        if (!def.colspan && !def.rowspan) {
          out.push(def.field);
        }
      }
    }

    return out;
  }

  public get topColumns(): string[] {
    const out: string[] = [];

    if (this.columnsDef) {
      for (const def of this.columnsDef) {
        if (def.rowspan || def.colspan) {
          out.push(def.field);
        }
      }
    }

    return out;
  }

  public get rowColumns(): string[] {
    const out: string[] = [];

    if (this.columnsDef) {
      for (const def of this.columnsDef) {
        if (!def.colspan) {
          out.push(def.field);
        }
      }
    }

    return out;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = this.dataSource?.filter((data) => !!data);
    this.sortColumn = this.sort;
    this.sortParent = this.sortHeader;
    this.sortDirection = this.direction;

    if (this.isCustomSorting) {
      this.sortedDatasource = [ ...this.dataSource ];
    } else {
      this.doSort(this.sortColumn, this.sortParent, this.sortDirection);
    }

    if (this.dataSource?.length) {
      this.clickedRows = [];
    }

    this.cdr.detectChanges();
  }

  /* eslint-disable-next-line @typescript-eslint/ban-types */
  public handleSort: Function = async (sort: any): Promise<void> => {
    let columnNameToSort = sort.name;
    this.sortColumn = sort.name;
    this.sortDirection = sort.direction;

    const fields = this.columnsDef.find((columnDef) => columnDef.field === sort.name).fields || null;
    if (fields != null) {
      this.sortParent = fields[0];
      columnNameToSort = fields[1];
    } else {
      this.sortParent = null;
    }

    if (this.isCustomSorting) {
      this.handleCustomSort.emit({
        sortColumn: columnNameToSort,
        sortDirection: this.sortDirection,
        sortParent: this.sortParent,
      });
    } else {
      this.doSort(columnNameToSort, this.sortParent, this.sortDirection);
    }
  };

  public onRowClick(item: any) {
    this.clickedRows = [];
    if (this.expendedTemplate && !item.__isNotExpandable) {
      this.toggleRowSelection(item);
      if (this.emitClickEventOnExpandedRow) {
        this.onRowClickEmitter.emit({ ...item });
      }
    } else {
      this.onRowClickEmitter.emit({ ...item });
    }
  }

  private toggleRowSelection(item: any) {
    for (const data of this.dataSource) {
      if (this.isSelectingOnlyOneAtATime && data !== item) {
        data.__selected = false;
      } else if (data === item) {
        item.__selected = !item.__selected;
      }
    }

    setTimeout(() => {
      this.cdr.detectChanges();
    }, 0);
  }

  public unCheck(item: any): void {
    for (const data of this.sortedDatasource) {
      if (data !== item) {
        data.__Selected = false;
        data.isSelected = false;
      }
    }
  }

  private doSort(sort: string, parent: string, direction: string) {
    if (this.dataSource) {
      this.sortedDatasource = [ ...this.dataSource ];
      this.sortedDatasource.sort((obj1, obj2) => UiStaticTable.genericSortFunction(sort, parent, direction, obj1, obj2, this.locale));
    }
  }

  public getCount() {
    if (this.dataSource) {
      return this.dataSource.length;
    }
    return 0;
  }

  public checkboxEvent(event: any) {
    if (this.canSelectOnlyOne) {
      this.unCheck(event.item);
    }

    this.setClickedRow(event);
    this.checkboxEventEmitter.emit(event);
  }

  public checkboxSelectAllEvent(event: boolean) {
    this.checkboxSelectAllEventEmitter.emit(event);
  }

  public handleChevron(row: any, columnDef: any): boolean {
    if (row.__selected) {
      return true;
    }
    if (!columnDef.showIcon) {
      return false;
    }
    if (columnDef.showIconOnHover && columnDef.showIcon) {
      return row.isIconShowing;
    }

    return true;
  }

  public onRowHover(row: any, value: boolean): void {
    row['isIconShowing'] = value;
    this.cdr.detectChanges();
  }

  // tslint:disable-next-line:member-ordering
  public static genericSortFunction(sort: string, parent: string, direction: string, obj1: any, obj2: any, locale: string): any {
    let value1 = (parent ? obj1[parent][sort] : obj1[sort]) || '';
    let value2 = (parent ? obj2[parent][sort] : obj2[sort]) || '';

    if (!isNaN(+value1) && !isNaN(+value2)) {
      if (direction === 'asc') {
        return +value1 - +value2;
      }
      return -(+value1 - +value2);
    }

    if (typeof value1 !== 'string' && value1[locale]) {
      value1 = value1[locale];
    }

    if (typeof value2 !== 'string' && value2[locale]) {
      value2 = value2[locale];
    }

    if (direction === 'asc') {
      return `${value1}`.localeCompare(`${value2}`);
    }
    return `${value2}`.localeCompare(`${value1}`);
  }

  public hasClickedRow(rowId: string): boolean {
    return this.clickedRows.some((clickedElement) => clickedElement === rowId);
  }

  private setClickedRow(event: any): void {
    if (this.canSelectOnlyOne) {
      this.clickedRows = [];
    }

    if (event.event) {
      this.clickedRows.push(event.item.id);
    } else {
      this.clickedRows = this.clickedRows.filter((row) => row !== event.item.id);
    }
  }
}
