import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-popover',
  templateUrl: './ui-popover.component.html',
  styleUrls: [ './ui-popover.component.scss' ],
})
export class UiPopoverComponent {
  @Input() public value: string | number;

  @Input() public popoverTitle: string;

  @Input() public popoverValues: string[];

  @Input() public isLeftAligned = false;
}
