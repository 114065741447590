import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { prune } from '../../services/utils';

export enum SeverityEnum {
  NA = 'na',
  OK = 'ok',
  LOW = 'low',
  MODERATE = 'moderate',
  HIGH = 'high',
  DISASTER = 'disaster'
}

@Component({
  selector: 'ui-severity-chip',
  templateUrl: './ui-severity-chip.component.html',
  styleUrls: [ './ui-severity-chip.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-severity-chip',
  },
})
export class UiSeverityChip implements OnChanges {
  @Input() severity: SeverityEnum = null;

  @Input() isInvertedColor = false;

  @Input() sameSizeForAll = false;

  @Input() label: string;

  @Input() isLoading = false;

  @Input() customText = null;

  @Input() smallSize: boolean;

  public severityChipClasses: any;

  constructor() {}

  public ngOnChanges() {
    this.setupClasses();
  }

  private setupClasses() {
    if (this.severity) {
      const classes = {
        'severity-chip': true,
        [`severity-chip_${this.severity}`]: true,
        [`severity-chip_${this.severity}_normal-colors`]: !this.isInvertedColor,
        [`severity-chip_${this.severity}_inverted-colors`]: this.isInvertedColor,
        'severity-chip--same-size-for-all': this.sameSizeForAll,
        'small': this.smallSize,
      };

      this.severityChipClasses = Object.keys(prune(classes)).join(' ');
    }
  }
}
