import { Component, ViewEncapsulation } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { UiButtonColor } from '@ui-kit/components/ui-button/ui-button.component';
import { Observable } from 'rxjs';
import { Snack, SnackbarState, SnackTaste, ThrowSnackInTrash } from '../state/snackbar.state';

@Component({
  selector: 'snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: [ './snackbar.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'snackbar',
  },
})
export class Snackbar {
  @Select(SnackbarState.snacks) public snacks$: Observable<Snack[]>;

  constructor(private store: Store) {
  }

  public throwSnackInTrash(snack: Snack) {
    this.store.dispatch(new ThrowSnackInTrash(snack));
  }

  public getButtonColor(snack: Snack): UiButtonColor {
    if (snack.taste === SnackTaste.DELICIOUS) {
      return UiButtonColor.SnackbarFlatPrimary;
    }
    return UiButtonColor.SnackbarFlatWhite;
  }
}
