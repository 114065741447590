import { Component, ViewEncapsulation, HostBinding } from '@angular/core';

@Component({
  selector: 'form[ui-form]',
  templateUrl: './ui-form.component.html',
  styleUrls: [ './ui-form.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-form',
  },
})
export class UiForm {
  @HostBinding('attr.novalidate') public novalidate = false;
}
