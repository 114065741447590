<div class="wrapper">
  <ng-container *ngIf="isSortable; else notSortable">
    <div class="content" ui-header-sort>
      <ng-content
        select="ui-static-table-sortable-header"
        ngProjectAs="static-table-header-content"
      ></ng-content>
    </div>
  </ng-container>

  <ng-template #notSortable>
    <div class="content">
      <ng-content
        select="ui-static-table-not-sortable-header"
        ngProjectAs="static-table-header-content"
      ></ng-content>
    </div>
  </ng-template>
  <div *ngIf="isResizable" class="bar" (onResize)="onResize($event)"></div>
</div>
