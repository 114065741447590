import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ITranslatedField } from "projects/@common/definitions/ITranslatedField";


@Component({
  selector: 'dynamic-multi-simple-select',
  templateUrl: './dynamic-multi-simple-select.component.html',
  styleUrls: [ './dynamic-multi-simple-select.component.scss' ],
})
export class DynamicMultiSimpleSelectComponent {
  @Input() public selectableValues: { label: ITranslatedField; value: any; }[];

  @Input() public label: string;

  @Input() public title: string;

  @Input() controlName: string;

  @Input() formGroup: UntypedFormGroup;

  public setValueInForm(value: any[]): void {
    this.formGroup.get(this.controlName).setValue(value);
  }
}
