import { Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { LanguageEnum } from '../../interfaces/ILanguage';
import { I18nService } from '../../services/I18nService';

export interface ITreeBranch {
  name: string;
  branches?: ITreeBranch[];
  isOpened?: boolean;
  isInvalid?: boolean;
}

@Component({
  selector: 'ui-tree',
  templateUrl: './ui-tree.component.html',
  styleUrls: [ './ui-tree.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-tree',
  },
})
export class UiTree implements OnChanges {
  @Input() tree: ITreeBranch[] = [];

  @Input() openIcon: string;

  @Input() closeIcon: string;

  @Input() isEditing = false;

  @Input() openAll = false;

  @Input() isTopOfTree = true;

  @Input() locale = LanguageEnum.FRENCH;

  @Output() onChanges: EventEmitter<void> = new EventEmitter();

  constructor(private readonly i18n: I18nService) {}

  public ngOnChanges() {
    if (this.openAll || this.isEditing) {
      this.openBranches(this.tree);
    } else {
      this.closeBranches(this.tree);
    }
  }

  public toggleBranch(branch: ITreeBranch) {
    if (branch.branches?.length && !this.isEditing) {
      branch.isOpened = !branch.isOpened;
    }
  }

  public openBranches(branches: ITreeBranch[]) {
    for (const branch of branches) {
      if (branch.branches) {
        branch.isOpened = true;
        this.openBranches(branch.branches);
      }
    }
  }

  public closeBranches(branches: ITreeBranch[]) {
    for (const branch of branches) {
      if (branch.branches) {
        branch.isOpened = false;
        this.closeBranches(branch.branches);
      }
    }
  }

  public validate(): void {
    for (const branch of this.tree) {
      if (this.tree.filter((otherBranch) => otherBranch.name === branch.name).length > 1 || branch.name === '') {
        branch.isInvalid = true;
      } else {
        branch.isInvalid = false;
      }
    }
    this.onChanges.emit();
  }

  public addBranch(tree: ITreeBranch[]): void {
    let name = this.i18n.translate('group.creation.template.number.folders.singular');
    if (tree.filter((other) => other.name === name).length) {
      let counter = 1;
      // eslint-disable-next-line no-loop-func
      while (tree.filter((branch) => branch.name === `${name} (${counter})`).length) {
        counter++;
      }
      name = `${name} (${counter})`;
    }

    tree.push({
      name,
      branches: [],
      isOpened: true,
    });
    this.onChanges.emit();
  }

  public removeBranches(branch: ITreeBranch): void {
    const index = this.tree.indexOf(branch);
    if (index > -1) {
      this.tree.splice(index, 1);
    }
    this.onChanges.emit();
  }

  public static isTreeValid(tree?: ITreeBranch[]): boolean {
    let valid = true;
    if (tree) {
      for (const branch of tree) {
        if (tree.filter((otherBranch) => otherBranch.name === branch.name).length > 1 || branch.name === '') {
          return false;
        }
        valid = valid && UiTree.isTreeValid(branch.branches);
      }
    }
    return valid;
  }

  public static convertToTree(datas: any[], nameName: string, branchesName: string): ITreeBranch[] {
    const tree: ITreeBranch[] = [];
    if (datas?.length) {
      for (const data of datas) {
        tree.push({
          name: data[nameName].trim().replace(/\s+/g, ' '),
          branches: this.convertToTree(data[branchesName], nameName, branchesName),
        });
      }
    }

    return tree;
  }
}
