<div
  class="column-header"
  [ngClass]="{
    'column-header--sub-header': isSubHeader
  }"
>
  <div class="column-header--elements">
    <div [ngClass]="{ 'delta-header-title': columnDef.isDelta }"></div>
    <div>
      {{
      dynamicTable
      ? (columnDef)
      : (columnDef.label)
      }}
      <div
        *ngIf="columnDef.headerIcon"
        class="header-icon--container"
        [ngStyle]="{ width: columnDef.headerIconContainerWidth || '24px' }"
      >
        <div class="header-icon">
          <img
            [src]="'assets/favicons/' + columnDef.headerIcon + '.svg'"
            [ngStyle]="{
              width: columnDef.headerIconWidth,
              'margin-bottom': columnDef.headerIconMarginBottom
            }"
          />
        </div>
      </div>
      <ng-container *ngIf="columnDef.showItemsCount">({{ count }})</ng-container>
      <ng-container *ngIf="columnDef.checkboxSelectAll">
        <mat-checkbox
          [checked]="allSelected"
          [disabled]="!count"
          (change)="checkboxSelectAllEvent($event.checked)"
        >
        </mat-checkbox>
      </ng-container>
    </div>
    <ui-tooltip
      class="table-column-tooltip"
      *ngIf="columnDef.tooltip"
      [isSmall]="true"
      [data]="columnDef.tooltip"
    ></ui-tooltip>
  </div>
</div>