import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-list',
  templateUrl: './ui-list.component.html',
  styleUrls: [ './ui-list.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-list',
  },
})
export class UiList {
  @Input() public label: string;

  @Input() public emptyMessage: string;

  @Input() public elements: string[];
}
