<table
  ui-table
  multiTemplateDataRows
  [dataSource]="sortedDatasource"
  [handleSort]="handleSort"
  [direction]="sortDirection"
  [sortBy]="sortColumn"
  [hoverable]="hoverable"
>
  <ng-container
    *ngFor="let columnDef of columnsDef; let first = first"
    [uiColumnDef]="columnDef.field"
  >
    <th
      ui-header-cell
      *uiHeaderCellDef
      resizable
      [initialWidth]="columnDef.width"
      [minWidth]="columnDef.minWidth"
      [isResizable]="!(columnDef.isResizable === false)"
      [isSortable]="!columnDef.noSorting"
      [class.firstItem]="first && addPaddingOnFirst"
      [attr.colspan]="columnDef.colspan"
      [attr.rowspan]="columnDef.rowspan"
      [ngClass]="{
        'first-group-column': columnDef.firstColumnOfHeaderGroup,
        'last-group-column': columnDef.lastColumnOfHeaderGroup,
        'sub-header': columnDef.type === 'subHeader'
      }"
    >
      <ui-static-table-header
        ngProjectAs="ui-static-table-sortable-header"
        [attr.data-test-id]="'header_' + columnDef.field"
        [columnDef]="columnDef"
        [dataSource]="dataSource"
        [dynamicTable]="dynamicTable"
        [isSubHeader]="columnDef.type === 'subHeader'"
        (checkbox-select-all-event)="checkboxSelectAllEvent($event)"
      ></ui-static-table-header>
      <ui-static-table-header
        ngProjectAs="ui-static-table-not-sortable-header"
        [attr.data-test-id]="'header_' + columnDef.field"
        [ui-static-table-not-sortable-header]
        [columnDef]="columnDef"
        [dataSource]="dataSource"
        [dynamicTable]="dynamicTable"
        [isSubHeader]="columnDef.type === 'subHeader'"
        (checkbox-select-all-event)="checkboxSelectAllEvent($event)"
      ></ui-static-table-header>
    </th>

    <td
      style="border-bottom: 0px"
      ui-cell
      *uiCellDef="let row"
      [class.firstItem]="first && addPaddingOnFirst"
      [class.small-row]="smallRow"
      [ngClass]="{
        'first-group-column': columnDef.firstColumnOfHeaderGroup,
        'last-group-column': columnDef.lastColumnOfHeaderGroup,
        '-noHover': !hoverable || (expendedTemplate && row.__isNotExpandable),
        'expended-row': row.__selected,
        'clicked-row': hasClickedRow(row.id)
      }"
      (mouseover)="hoveredRow = row; onRowHover(row, true)"
      (mouseleave)="hoveredRow = null; onRowHover(row, false)"
    >
      <ui-static-table-row
        [componentEmitter]="componentEmitter"
        [row]="row"
        [columnDef]="columnDef"
        [hoveredRow]="hoveredRow"
        [ngClass]="{ isContentCentered: columnDef.isContentCentered }"
        (checkbox-event)="checkboxEvent($event)"
        [smallRow]="smallRow"
        [isIconShowing]="handleChevron(row, columnDef)"
        [canSelectItems]="canSelectItems"
        [locale]="locale"
        [bold]="row[fieldNameToCheckForBoldRow]"
      ></ui-static-table-row>
    </td>
  </ng-container>

  <ng-container uiColumnDef="expendable">
    <th ui-header-cell *uiHeaderCellDef></th>
    <td
      ui-cell
      *uiCellDef="let row; let i = index"
      class="-expendedData"
      [attr.colspan]="columns?.length"
    >
      <ng-container
        *ngTemplateOutlet="
          expendedTemplate;
          context: { row: row, isSelect: row.__selected }
        "
      ></ng-container>
    </td>
  </ng-container>

  <ng-container *ngIf="twoHeaders">
    <tr ui-header-row *uiHeaderRowDef="topColumns"></tr>
  </ng-container>
  <tr ui-header-row *uiHeaderRowDef="columns"></tr>
  <tbody>
    <tr
      ui-row
      *uiRowDef="let row; columns: rowColumns"
      [class.highlighted]="row.__highlighted"
      (click)="onRowClick(row)"
    ></tr>
    <tr
      ui-row
      *uiRowDef="let row; columns: ['expendable']"
      [ngClass]="row.__selected ? 'expended' : 'collapsed'"
    ></tr>
  </tbody>
</table>

<div
  class="infinite empty-row"
  *ngIf="!loading && (!dataSource || dataSource?.length === 0) && useEmptyMessage"
>
  <ui-static-table-empty [locale]="locale"></ui-static-table-empty>
</div>

<ui-spinner class="spinner" *ngIf="loading" size="medium"></ui-spinner>
