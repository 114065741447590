<div class="time-range">
  <div *ngIf="mobileService.isMobile(); else notMobile" class="mobile-filter">
    <img
      src="assets/favicons/icon_filter.svg"
      alt="filter"
      (click)="toggleSelection()"
    />
  </div>

  <ng-template #notMobile>
    <button
      ui-button
      color="dashboardWidget"
      icon="chevron-down"
      [isIconAtRight]="true"
      (click)="toggleSelection()"
    >
      {{ ('dashboard.admin.timeRange.' + selectedTimeRange + '.filter') | t:{ locale: locale } }}
    </button>
  </ng-template>

  <ui-card
    *ngIf="showTimeRanges"
    class="time-range--selection"
    [withShadow]="true"
    [withPadding]="false"
  >
    <div
      *ngFor="let timeRange of timeRanges | enumPipe"
      class="time-range--selection--choice"
      [class.selected-time-range]="timeRange === selectedTimeRange"
      (click)="onTimeRangeSelection(timeRange)"
    >
      {{ ('dashboard.admin.timeRange.' + timeRange + '.filter') | t:{ locale: locale } }}
    </div>
  </ui-card>
</div>
