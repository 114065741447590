<div
  #tooltipIcon
  class="toolTip"
  [ngClass]="{
    'toolTip--small': isSmall && !isBig,
    'toolTip--normal': isSmall === isBig,
    'toolTip--big': isBig && !isSmall
  }"
  (click)="$event.stopPropagation()"
  (mouseenter)="!mobileService.isMobile() ? onTooltipClick() : ''"
  (mouseleave)="!mobileService.isMobile() ? onCloseTooltipClick() : ''"
>
  <img
    *ngIf="isSmall && !isBig"
    class="small-size"
    [src]="icon ? icon : SMALL_INFO_ICON"
    alt="infoIcon"
    (click)="onTooltipClick()"
  />
  <img
    *ngIf="!isSmall && isBig"
    class="big-size"
    [src]="icon ? icon : INFO_ICON"
    alt="infoIcon"
    (click)="onTooltipClick()"
  />
  <img
    *ngIf="isSmall === isBig"
    class="normal-size"
    [src]="icon ? icon : INFO_ICON"
    alt="infoIcon"
    (click)="onTooltipClick()"
  />

  <ng-container *ngIf="showTooltip">
    <ng-container *ngTemplateOutlet="tooltip"></ng-container>
  </ng-container>
</div>
<div
  *ngIf="mobileService.isMobile() && showTooltip"
  class="toolTip--backdrop"
  (click)="$event.stopPropagation()"
></div>

<ng-template #tooltip>
  <div
    #tooltipMessage
    id="tooltipMessage"
    class="toolTipText"
    [ngClass]="{
      'toolTipText--desktop': !mobileService.isMobile(),
      'toolTipText--mobile': mobileService.isMobile()
    }"
    [ngStyle]="
      !mobileService.isMobile() && {
        left: positionLeft,
        top: positionTop,
        width: width || '275px'
      }
    "
  >
    <div class="toolTip-container">
      <div *ngIf="mobileService.isMobile()" class="toolTipText--header">
        <div>
          <img
            class="close-button"
            [src]="CLOSE_ICON"
            alt="X"
            (click)="onCloseTooltipClick()"
          />
        </div>
      </div>

      <div *ngIf="data; else useContent" [innerHTML]="data"></div>
      <ng-template #useContent>
        <ng-content></ng-content>
      </ng-template>
    </div>
  </div>
</ng-template>
