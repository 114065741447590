import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from "@angular/core";

import { ToolbarCollapsableElementComponent } from "./toolbar-collapsable-element/toolbar-collapsable-element.component";

@Component({
  selector: 'ui-toolbar-filter',
  templateUrl: './toolbar-filter.component.html',
  styleUrls: [ './toolbar-filter.component.scss' ],
})
export class ToolbarFilterComponent implements AfterContentInit {
  @ContentChildren(ToolbarCollapsableElementComponent) filtersInsideCollapsableArea: QueryList<ToolbarCollapsableElementComponent>;

  @Input() public locale: string;

  @Input() public total: number;

  @Input() public showEraseAll: boolean = true;

  @Input() public activatedFiltersNumber = 0;

  @Input() public isLoading: boolean;

  @Input() public areFiltersExpanded = false;

  @Output() public onApply = new EventEmitter<void>();

  @Output() public onCancel = new EventEmitter<void>();

  @Output() public onClearAllFilters = new EventEmitter<void>();

  public filters: ToolbarCollapsableElementComponent[];

  constructor() {
  }

  public ngAfterContentInit() {
    this.filters = this.filtersInsideCollapsableArea.toArray();
  }

  public toggleFilters() {
    this.areFiltersExpanded = !this.areFiltersExpanded;
  }

  public clearAllMobileFilters() {
    this.onClearAllFilters.emit();
    this.toggleFilters();
  }

  public filterCheckboxChanged(event: boolean, filterInsideCollapsableArea: ToolbarCollapsableElementComponent) {
    filterInsideCollapsableArea.emitCheckboxChanged(event);
  }

  public resetFiltersCheckboxes() {
    this.filtersInsideCollapsableArea.forEach((filter) => filter.resetCheckbox());
  }
}
