<div
  class="combobox-container"
  (focusout)="closeDropdown()"
>
  <div class="combobox-input-wrapper">
    <input
      #comboboxInputElem
      class="combobox-input"
      type="text"
      ui-input
      [disabled]="disabled"
      [(ngModel)]="inputValue"
      (change)="handleValueChange($event.target.value)"
      (input)="handleInputTyping($event.target.value)"
      (focus)="openDropdown()"
    >

    <div
      class="combobox-input-chevron"
      [class.disabled]="disabled"
      (mousedown)="toggleDropdown(); $event.preventDefault(); $event.stopPropagation();"
    >
      <ui-icon
        modifier="on-header"
        [name]="'chevron-down'"
        size="medium"
      >
      </ui-icon>
    </div>
  </div>

  <div
    *ngIf="isDropdownVisible"
    class="combobox-dropdown"
  >
    <ng-container *ngIf="mappedDropdownOptions.length > 0; else noOptionsTemplate;">
      <div
        *ngFor="let option of mappedDropdownOptions"
        class="combobox-dropdown--option"
        [class.isIgnored]="option.isIgnored"
        [class.isExactMatch]="option.isExactMatch"
        (mousedown)="handleDropdownOptionClick(option); $event.preventDefault(); $event.stopPropagation();"
      >
        {{option.value}}
      </div>
    </ng-container>
    <ng-template #noOptionsTemplate>
      <div
        class="combobox-dropdown--option"
        (mousedown)="closeDropdown(); $event.preventDefault(); $event.stopPropagation();"
      >
        {{'combobox.nodata' | t}}
      </div>
    </ng-template>
  </div>
</div>