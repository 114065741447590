import { Injectable } from "@angular/core";

import { EnvConfiguration } from "../definitions/env.definitions";
import { environment } from "../environments/environment";


export interface Configuration {
}

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  public readonly config: Configuration & Partial<EnvConfiguration>;

  constructor() {
    this.config = { ...environment };
  }
}

