<ui-select
  [label]="label"
  [name]="name"
  [required]="required"
  [control]="controlName"
  [group]="group"
  [options]="optionsLabel"
  (optionClick)="emitSelection($event)"
>
</ui-select>
<label ui-label *ngIf="description">{{ description | translatedObject }}</label>
