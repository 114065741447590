<div class="carousel-main-section">
  <div class="slides-container" id="slides-container">
    <ng-content>

    </ng-content>
  </div>
</div>
<div class="carousel-footer">
  <div class="protected-container">
    <p class="text">> {{ 'dashboard.dialog.carousel.protected.by' | t: { locale: locale } }} </p>
    <img class="image" src="assets/favicons/icon_mondata.svg">
  </div>
  <div>
    <p>{{ pagination }}</p>
  </div>
  <div>
    <button
      *ngIf="canDisplayPreviousButton"
      ui-button
      color="secondary"
      (click)="goToPrevious()">
      {{ 'common.action.previous' | t:{ locale: locale } }}
    </button>
    <button
      *ngIf="canDisplayNextButton"
      ui-button
      color="standard"
      (click)="goToNext()">
      {{ 'common.action.next' | t:{ locale: locale } }}
    </button>
    <button
      *ngIf="canDisplayFinishButton && !learnMoreFinish"
      ui-button
      color="standard"
      (click)="closeDialog()">
      {{ 'common.action.finish' | t:{ locale: locale } }}
    </button>
    <a *ngIf="canDisplayFinishButton && learnMoreFinish" href="https://mondata.ai/en/md-eco-platform/proactive-cybersecurity/">
      <button ui-button color="standard" class="learn-more">
        {{ 'learn.more' | t: { locale: locale } }}
      </button>
    </a>
  </div>
</div>
