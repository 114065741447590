export class SimpleOption {
  public isSelected = false;

  public value: string | number;

  public label: string;

  constructor(data: { label: string; value: any }) {
    this.value = data.value;
    this.label = data.label;
  }

  public checkOption(): void {
    this.isSelected = !this.isSelected;
  }
}
