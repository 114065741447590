import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LanguageEnum } from '../../interfaces/ILanguage';
import { TimeUtil } from '../../services/time-util';
import { parseIntSafe } from '../../services/utils';
import { UiDatePicker } from '../ui-date-picker/ui-date-picker.component';
import { UiTimePicker } from '../ui-time-picker/ui-time-picker.component';

@Component({
  selector: 'ui-datetime-picker',
  templateUrl: './ui-datetime-picker.component.html',
  styleUrls: [ './ui-datetime-picker.component.scss' ],
})
export class UiDatetimePickerComponent implements OnInit {
  @Input()
  public defaultTimestamp: number = null; // ex: Date.now() --> 1670963795897

  @Input()
  public minDate: Date;

  @Input()
  public maxDate: Date;

  @Input()
  public withHours: boolean = true;

  @Input()
  public isReadonly: boolean = false;

  @Input()
  public isDisabled: boolean = false;

  @Input()
  public showNowButton: boolean = true;

  @Input()
  public showResetButton: boolean = true;

  @Input()
  public label: string = null;

  @Input()
  public locale = LanguageEnum.FRENCH;

  @Input()
  public required: boolean = false;

  @Input()
  public uiTooltip: string = null;

  @Output()
  public timestampChangedEvent = new EventEmitter<number>();

  @ViewChild("datePicker")
  public datePicker: UiDatePicker;

  @ViewChild("timePicker")
  public timePicker: UiTimePicker;

  public dateMs: number = 0; // ms value of the DATE without the time value (ex: ms value of 2022-12-13 at 0h 0m 0s 0ms)
  public timeMs: number = 0; // ms value of the TIME without the date value (ex: ms value of 5h 30min)

  constructor() { }

  ngOnInit(): void {
    this.setDefaultState();
  }

  public get currentTimestamp(): number {
    const safeDateMs = parseIntSafe(this.dateMs);
    const safeTimeMs = parseIntSafe(this.timeMs);
    return safeDateMs + safeTimeMs;
  }

  public reset(): void {
    this.setState(null);
    this.datePicker?.reset();
    this.timePicker?.reset();
    this.emitChangeEvent();
  }

  public setNow(): void {
    this.setState(Date.now());
    this.datePicker?.setValueNow();
    this.timePicker?.setValueNow();
    this.emitChangeEvent();
  }

  public setTimestamp(timestamp: number): void {
    this.setState(timestamp);
    this.datePicker?.setTimestamp(timestamp);
    this.timePicker?.setTimestamp(timestamp);
    this.emitChangeEvent();
  }

  public setDefaultState(): void {
    this.setState(this.defaultTimestamp);
    this.datePicker?.setDefaultState();
    this.timePicker?.setDefaultState();
  }

  public handleDateChangeEvent(dateMilliseconds: number): void {
    this.dateMs = dateMilliseconds;
    this.emitChangeEvent();
  }

  public handleTimeChangeEvent(timeMilliseconds: number): void {
    this.timeMs = timeMilliseconds;
    this.emitChangeEvent();
  }

  public handleClickNow() {
    this.setNow();
  }

  public handleClickReset() {
    this.reset();
  }

  private setState(timestamp: number | null): void {
    const safeTimestamp = parseIntSafe(timestamp);
    if (safeTimestamp && safeTimestamp > TimeUtil.dayDuration) {
      this.dateMs = TimeUtil.getDateMsValueFromTimestamp(safeTimestamp);
      this.timeMs = TimeUtil.getTimeMsValueFromTimestamp(safeTimestamp);
    } else {
      this.dateMs = 0;
      this.timeMs = 0;
    }
  }

  private emitChangeEvent(): void {
    this.timestampChangedEvent.emit(this.currentTimestamp);
  }
}
