<span
  *ngIf="isUserOnboarded(effectiveDate)"
  class="effective-date-icon"
  [title]="translation.description[locale]"
>
  <img
    class="effective-date-icon"
    src="./assets/favicons/icon_timer.png"
    alt=""
  />
</span>
