import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { TableFilterData } from './ui-table-filter-data';

export interface ITableFilterData {
  value: any;
  translated: string;
  translatedTooltip: string;
  translatedWithTitleSuffix: string;
  isDefault: boolean;
}

@Component({
  selector: 'ui-table-filter-tool',
  templateUrl: './ui-table-filter-tool.component.html',
  styleUrls: [ './ui-table-filter-tool.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class UiTableFilterTool implements OnChanges {
  @Input() public tableFilterData: TableFilterData;

  @Input() public titlesDifferentThanOptions: any[] = [];

  @Input() public tooltipsList = [];

  @Input() public separatorsList = [];

  @Output('select') public selected: EventEmitter<any> = new EventEmitter<any>();

  public option: ITableFilterData;

  public showOptions = false;

  @HostListener('document:click', [ '$event' ])
  clickOut(event: any) {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.showOptions = false;
    }
  }

  @HostListener('keydown.escape', [ '$event' ])
  onEscapeKey(event: KeyboardEvent) {
    if (this.showOptions) {
      event.stopPropagation();
      this.toggle();
    }
  }

  public constructor(protected readonly ref: ElementRef) {}

  public ngOnChanges(): void {
    this.option = this.tableFilterData?.defaultData;
  }

  public toggle() {
    this.showOptions = !this.showOptions;
  }

  public handleOption(option: ITableFilterData): void {
    this.tableFilterData.setNewDefaultData(option);
    this.option = this.tableFilterData?.defaultData;
    this.selected.emit(option.value);
    this.showOptions = false;
  }
}
