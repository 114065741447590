import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-anchor',
  templateUrl: './ui-anchor.component.html',
  styleUrls: [ './ui-anchor.component.scss' ],
})
export class UiAnchor {
  @Input() public label = '';

  @Output() public onClick = new EventEmitter<void>();
}
