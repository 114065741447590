import { Component, Input } from "@angular/core";

export enum SeverityLevelEnum {
  LEVEL_1 = 1,
  LEVEL_2 = 2,
  LEVEL_3 = 3,
  LEVEL_4 = 4,
  LEVEL_5 = 5
}

@Component({
  selector: 'severity-badge',
  templateUrl: './severity-badge.component.html',
  styleUrls: [ './severity-badge.component.scss' ],
})
export class SeverityBadgeComponent {
  @Input()
    noMargin = false;

  @Input()
    data: any & { severity: SeverityLevelEnum; };

  public get severityText(): string {
    return `common.severity.${this.data.severity}.label`;
  }

  public get isSeverityUrgency(): boolean {
    return this.data.severity === SeverityLevelEnum.LEVEL_1;
  }
}
