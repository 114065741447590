<div
  *ngIf="usersToDisplay.length > 0"
  class="choices-list"
  [ngStyle]="{ height: expandHeight }"
  uiAccessibleChoiceListDirective
  [choices]="usersToDisplay"
  (choiceSelectedEmitter)="onUserSelected($event.choice)"
>
  <ng-container *ngFor="let user of usersToDisplay; let index = index">
    <div [ngClass]="{ 'multi-select-autocomplete': canMultiSelect }">
      <div
        [id]="'rowElement_' + index"
        [class.hover]="true"
        class="choice separator accessible-choice"
        (mousedown)="onUserClick(user, index, $event)"
        (mouseenter)="hoverElement(user, true)"
        (mouseleave)="hoverElement(user, false)"
      >
        <div class="choice-infos-row">
          <div class="choice-infos-container">
            <div class="row-icon">
              <ui-avatar size="small" [user]="user"></ui-avatar>
              <div
                *ngIf="user['isHovered']"
                class="hovered-element-row"
              >
                <ui-icon
                  class="hovered-element-row--add-icon"
                  name="plus"
                  modifier="white"
                  size="medium"
                ></ui-icon>
              </div>
            </div>

            <div class="choices-infos">
              <span>{{ user.name }}</span>
              <span class="custom-value-description">{{ user?.mail || '-' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div
  *ngIf="usersToDisplay.length < 1"
  class="choices-list no-items"
>
  <span class="no-items--label">
    <i>{{ "user-autocomplete.notFound" | t : { locale: locale } }}</i>
  </span>
</div>
