<div class="tag">
  <ng-container *ngIf="!user">
    <ui-avatar
      *ngIf="showAvatar"
      size="tiny"
      [name]="userDisplayName"
    ></ui-avatar>
    <span class="tag--name"> {{ userDisplayName }} </span>
    <ui-icon
      *ngIf="!readOnly"
      class="tag--delete"
      size="medium"
      modifier="on-header"
      name="close-circle"
      (click)="deleteUser()"
    ></ui-icon>
  </ng-container>

  <ng-container *ngIf="user">
    <ui-avatar
      *ngIf="showAvatar"
      size="tiny"
      [name]="name"
      [user]="user"
    ></ui-avatar>
    <span class="tag--name"> {{ name }} </span>
    <ui-icon
      *ngIf="!readOnly"
      class="tag--delete"
      size="medium"
      modifier="on-header"
      name="close-circle"
      (click)="deleteUser()"
    ></ui-icon>
  </ng-container>
</div>