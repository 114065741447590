import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimeUtil } from '../../services/time-util';

export interface IDurationTime {
  hours: number,
  minutes: number,
  viewValue?: string,
}

@Component({
  selector: 'ui-duration-time-picker',
  templateUrl: './ui-duration-time-picker.component.html',
  styleUrls: [ './ui-duration-time-picker.component.scss' ],
})
export class UiDurationTimePickerComponent implements OnInit {
  @Input()
  public defaultDurationMs: number = null;

  @Input()
  public isReadonly: boolean = false;

  @Input()
  public isDisabled: boolean = false;

  @Input()
  public fullWidth: boolean = false;

  @Output()
  public millisecondsChangedEvent = new EventEmitter<number>();

  public readonly MAX_HOURS: number = 999.99;
  public readonly MAX_MINUTES: number = 999.99 * 60;
  public readonly MAX_SECONDS: number = 999.99 * 60 * 60;
  public readonly MAX_MILLISECONDS: number = 999.99 * 60 * 60 * 1000;

  public readonly HOURS_REGEX = new RegExp(/^(?<hours>\d{0,3}\.\d{1,2}|\d{1,3})h?$/, "i"); // https://regex101.com/r/2wuCFS/3
  public readonly MINUTES_REGEX = new RegExp(/^(?<minutes>\d{1,5})(?:m(?:in)?\.?)$/, "i"); // https://regex101.com/r/GywViu/4

  public duration: IDurationTime;

  constructor() { }

  ngOnInit(): void {
    this.setDefaultState();
  }

  public get totalMilliseconds(): number {
    const hoursMs = TimeUtil.convertHoursToMilliseconds(this.duration.hours);
    const minutesMs = TimeUtil.convertMinutesToMilliseconds(this.duration.minutes);
    const totalMilliseconds = hoursMs + minutesMs;
    return this.clamp(totalMilliseconds, 0, this.MAX_MILLISECONDS);
  }

  public setDurationFromMilliseconds(milliseconds: number | null): void {
    if (milliseconds === null) {
      this.duration = {
        hours: 0,
        minutes: 0,
        viewValue: null,
      };
    } else {
      const { hours, minutes } = this.getValidHoursAndMinutes(milliseconds);
      this.duration = {
        hours,
        minutes,
        viewValue: this.generateViewValue(hours, minutes),
      };
    }
  }

  public setDefaultState(): void {
    this.setDurationFromMilliseconds(this.defaultDurationMs);
  }

  public validateDurationTime(): void {
    const { hours, minutes } = this.getValidHoursAndMinutes(this.totalMilliseconds);
    this.duration.hours = hours;
    this.duration.minutes = minutes;
    this.duration.viewValue = this.generateViewValue(hours, minutes);
  }

  public handleInputChange(inputValue: string): void {
    const parsedDuration = this.parseUserInputValue(inputValue);
    this.duration = {
      hours: parsedDuration.hours,
      minutes: parsedDuration.minutes,
      viewValue: inputValue, // keep unchanged value to prevent modifying the input while the user types
    };
    this.emitMillisecondsChangedEvent();
  }

  public handleInputBlur(): void {
    this.validateDurationTime();
  }

  private emitMillisecondsChangedEvent(): void {
    this.millisecondsChangedEvent.emit(this.totalMilliseconds);
  }

  private parseUserInputValue(inputValue: string): IDurationTime {
    let hours = 0;
    let minutes = 0;
    if (inputValue) {
      const matchHoursRegex = inputValue.match(this.HOURS_REGEX);
      if (matchHoursRegex) {
        hours = this.cleanDecimal(matchHoursRegex.groups.hours);
      }

      const matchMinutesRegex = inputValue.match(this.MINUTES_REGEX);
      if (matchMinutesRegex) {
        minutes = this.cleanDecimal(matchMinutesRegex.groups.minutes);
      }

      if (!matchHoursRegex && !matchMinutesRegex) {
        const [ hourString, minuteString ] = inputValue.split(/h\s*|\s/);
        if (hourString) {
          hours = this.cleanDecimal(hourString);
        }
        if (minuteString) {
          minutes = this.cleanDecimal(minuteString);
        }
      }
    }
    return {
      hours: this.clamp(hours, 0, this.MAX_HOURS),
      minutes: this.clamp(minutes, 0, this.MAX_MINUTES),
    };
  }

  private cleanDecimal(str: string): number {
    if (!str) {
      return 0;
    }

    // Remove all non-numeric characters and replace all commas with dots
    str = str.replace(/[^0-9.,]/g, '')?.replace(/,/g, '.');

    if (str === '' || str === '.') {
      return 0;
    }

    return parseFloat(str);
  }

  private clamp(value: number, min: number, max: number): number {
    // return value restricted between min anx max.
    return Math.min(Math.max(value, min), max);
  }

  private generateViewValue(hours: number, minutes: number): string {
    if (hours && minutes) {
      return `${hours}h ${minutes}m`;
    } else if (hours) {
      return `${hours}h`;
    } else if (minutes) {
      return `${minutes}m`;
    }
    return "0h";
  }

  private getValidHoursAndMinutes(milliseconds: number): IDurationTime {
    // Calculate the number of hours by dividing the milliseconds by the number of milliseconds per hour
    const hours = Math.floor(milliseconds / TimeUtil.hourDuration);

    // Calculate the number of minutes by dividing the remaining milliseconds by the number of milliseconds per minute
    const remainingMilliseconds = milliseconds % TimeUtil.hourDuration;
    const minutes = Math.floor(remainingMilliseconds / TimeUtil.minuteDuration);

    return {
      hours: this.clamp(hours, 0, this.MAX_HOURS),
      minutes: this.clamp(minutes, 0, this.MAX_MINUTES),
    };
  }
}
