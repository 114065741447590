<ui-field
          *ngIf="isEditing"
          [label]="label"
          [locale]="locale"
          [name]="name"
          [type]="type"
          [control]="control"
          [placeholder]="placeholder"
          [mask]="mask"
          [value]="value"
          [readonly]="readonly"
          [disabled]="disabled"
          [required]="required"
          [group]="group"
          [arrayName]="arrayName"
          [groupName]="groupName"
          [maxlength]="maxlength"
          [forceInvalid]="forceInvalid"
          [functionToExecuteOnInput]="functionToExecuteOnInput"
          [errorMessage]="errorMessage"
          (inputValue)="onInput.emit($event)">
  <ui-tooltip *ngIf="tooltipText">
    <div>
      {{ tooltipText }}
    </div>
  </ui-tooltip>
</ui-field>
<ng-content></ng-content>
<ui-static
           *ngIf="!isEditing"
           [label]="label"
           [value]="value"
           [iconName]="iconName"
           [iconModifier]="iconModifier">
  <ui-tooltip *ngIf="tooltipText">
    <div>
      {{ tooltipText }}
    </div>
  </ui-tooltip>
</ui-static>
