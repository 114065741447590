import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnInit,
  Input
} from '@angular/core';
import { Select } from '@ngxs/store';
import { MediaQueryService } from 'projects/@common/services/mediaquery/mediaquery.service';
import { MobileService } from 'projects/@common/services/mobile.service';
import { Observable } from 'rxjs';
import { DrawerState } from '../../drawer/stores/drawer.store';

@Component({
  selector: 'topbar-toggle',
  templateUrl: './topbar-toggle.component.html',
  styleUrls: [ './topbar-toggle.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'topbar-toggle',
  },
})
export class TopbarToggle implements OnInit {
  @Input('indicators') public indicators = { emails: 0, notices: 0 };

  @Output('toggle') public toggled: EventEmitter<void> = new EventEmitter<void>();

  @Select(DrawerState.isExpanded) public isDrawerExpanded$: Observable<boolean>;

  public constructor(public readonly mq: MediaQueryService, public mobileService: MobileService) {}

  public handleClick(): void {
    this.toggled.emit();
  }

  ngOnInit(): void {
    this.mobileService.isMobile();
  }
}
