import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { DisplayDirective } from './display.directive';
import { DisplayState } from './state/display.state';


@NgModule({
  imports: [
    NgxsModule.forFeature([ DisplayState ]),
  ],
  declarations: [
    DisplayDirective,
  ],
  exports: [ DisplayDirective ],
})
export class DisplayModule {
  static forRoot(config: {
    permissionsMapping: any;
  }): ModuleWithProviders<DisplayModule> {
    return {
      ngModule: DisplayModule,
      providers: [
        { provide: 'PERMISSIONS_MAPPING', useValue: config.permissionsMapping },
      ],
    };
  }
}

