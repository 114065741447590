<div
  class="container"
  [class.with-border]="withBorder"
>
  <div class="container--content">
    <img
      *ngIf="logo"
      alt="'indicator logo'"
      class="container--content--logo"
      [src]="'assets/favicons/' + logo + '.svg'"
    />
    <div
      class="container--content--big-number-wrapper"
      [class.margin-left]="!logo"
    >
    </div>

    <div class="container--content--description">
      <span class="container--content--description--small"> {{ createdAt | dt : getDateParams() }}</span>
      <span class="container--content--description--bold">{{name}}</span>
      <ui-tooltip *ngIf="description">{{description}}</ui-tooltip>
    </div>
  </div>
</div>