<div
  *ngIf="warningMessages?.length"
  class="warning-messages"
  [ngClass]="{
    'center-text': centerText,
    'yellow': color === colors.YELLOW,
    'rounded-border': roundedBorder,
    'with-icon': showIcon
  }"
>
  <img
    *ngIf="showIcon"
    src="assets/favicons/icon_warning_rounded.svg"
    alt=""
    width="18px"
    height="18px"
  >

  <div *ngIf="!showBulletPoint || warningMessages.length === 1">
    <div *ngFor="let warningMessage of warningMessages" class="warning-messages--message"
    > {{ warningMessage.messageKey }}
    </div>
  </div>
  <ul *ngIf="showBulletPoint && warningMessages.length > 1">
    <li *ngFor="let warningMessage of warningMessages" class="warning-messages--message"> 
      {{ warningMessage.messageKey }}
    </li>
  </ul>
</div>