import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'external-links-dialog',
  templateUrl: './external-link-dialog.component.html',
  styleUrls: ['./external-link-dialog.component.scss'],
})
export class ExternalLinkDialog {
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  public closeModal(value: boolean): void {
    if (this.data.callback) {
      this.data.callback({ value });
    }
    this.dialogRef.close();
  }

  public get truncatedUrl(): string {
    const truncateLength = 50;
    return this.data.url.slice(0, truncateLength) + (this.data.url.length > truncateLength ? '...' : '');
  }

}
