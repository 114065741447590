<header 
    class="ui-table-filter-tooltip"
    uiAccessibleChoiceListDirective 
    [choices]="tableFilterData.options"
    (choiceSelectedEmitter)="selectOption($event.choice)"
>
  <ng-container *ngFor="let option of tableFilterData.options">
    <div
         (mouseover)="hoveredOption = option"
         (mouseleave)="hoveredOption = null">
      <ui-icon
               *ngIf="optionIcons[option]"
               class="margin--small--left"
               [class.selectedOption]="isSelectedOption(option.value)"
               modifier="gray"
               [name]="optionIcons[option]"></ui-icon>
      <button
              ui-button
              class="accessible-choice"
              color="dropdown"
              [ripple]="false"
              [class.selectedOption]="isSelectedOption(option.value)"
              (click)="selectOption(option)">
        <div class="child-element">
          {{ option.translated }}
        </div>
      </button>
      <ui-tooltip
                  *ngIf="tooltipsList.includes(option) && hoveredOption === option"
                  icon="assets/favicons/icon_info_small.svg"
                  [isSmall]="true">
        {{ option.translatedTooltip }}
      </ui-tooltip>
    </div>
    <div
         *ngIf="separatorsList.includes(option)"
         class="tooltip-separator"></div>
  </ng-container>
</header>
