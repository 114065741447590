<div class="multi-select-options-selector">
  <div *ngFor="let option of optionList" class="multi-select-options-selector--option">
    <div class="multi-select-options-selector--option--checkbox">
      <ui-checkbox
        [isEditable]="true"
        [checked]="option.isSelected"
        (onCheck)="checkOption(option)"
      ></ui-checkbox>
    </div>
    {{ option.label }}
    <div
      class="multi-select-options-selector--option--foreground"
      (click)="checkOption(option)"
    ></div>
  </div>
</div>
