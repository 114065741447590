import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';

export enum Breakpoint {
  XSmall = '(max-width: 619.99px)',
  Small = '(min-width: 620px) and (max-width: 1023.99px)',
  Medium = '(min-width: 1024px) and (max-width: 1439.99px)',
  Large = '(min-width: 1440px) and (max-width: 1919.99px)',
  XLarge = '(min-width: 1920px)',
  SmallUp = '(min-width: 600px)'
}

@Injectable({
  providedIn: 'root',
})
export class MediaQueryService {
  public constructor(protected readonly observer: BreakpointObserver) {}

  public $smallUp(): Observable<BreakpointState> {
    return this.observer.observe([ Breakpoint.SmallUp ]);
  }
}
