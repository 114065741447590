<span class="ui-button--loader" *ngIf="isLoading">
  <ui-spinner size="small" [modifier]="spinnerModifier"></ui-spinner>
</span>
<span
  else
  class="ui-button--icon"
  [ngClass]="{ 'hide-when-loading': isLoading }"
  *ngIf="!!icon && !isIconAtRight"
>
  <ng-container *ngIf="!customIcon">
    <ui-icon
      class="ui-button--picto"
      [name]="icon"
      [modifier]="iconModifier"
      [size]="iconSize"
      [ngStyle]="{ 'opacity': iconOpacity }"
      [ngStyle]="iconMargin ? {'margin': iconMargin}: null"
    ></ui-icon>
  </ng-container>
  <ng-container *ngIf="customIcon">
    <img
      class="customIcon"
      [ngClass]="customIconSize"
      [ngStyle]="{
        'margin': iconMargin ? iconMargin : null,
        'width': customIconWidth ? customIconWidth : null
      }"
      [src]="
        'assets/favicons/' +
        icon +
        (activeLink ? '_active' : '') +
        (isSvg ? '.svg' : '.png')
      "
      alt=""
    />
  </ng-container>
</span>
<span class="ui-button--text" [ngClass]="{ 'hide-when-loading': isLoading }">
  <ng-content></ng-content>
  <p *ngIf="suffix">{{suffix}}</p>
</span>
<span
  else
  class="ui-button--icon"
  [ngClass]="{ 'hide-when-loading': isLoading }"
  *ngIf="(!!icon && isIconAtRight) || !!iconRight"
>
  <ng-container *ngIf="!customIcon">
    <ui-icon
      class="ui-button--picto"
      [name]="icon"
      [modifier]="iconModifier"
      [size]="iconSize"
      [ngStyle]="{ opacity: iconOpacity }"
    ></ui-icon>
  </ng-container>
  <ng-container *ngIf="customIcon">
    <img
      class="customIcon"
      [src]="
        'assets/favicons/' +
        (iconRight || icon) +
        (activeLink ? '_active' : '') +
        (isSvg ? '.svg' : '.png')
      "
      alt=""
    />
  </ng-container>
</span>
