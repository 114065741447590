import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ui-input-group',
  templateUrl: './ui-input-group.component.html',
  styleUrls: [ './ui-input-group.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-input-group',
  },
})
export class UiInputGroup {}
