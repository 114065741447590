import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'ui-title',
  templateUrl: './ui-title.component.html',
  styleUrls: [ './ui-title.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-title',
  },
})
export class UiTitle {
  // can't be name title because it will put an html title tag on it
  @Input('name') public title: string;

  @Input() public afterSpace = true;

  @Input() public bottomBorder = true;

  @Input() public largeFont = false;

  @Input() public uiTooltipMessage: string;
}
