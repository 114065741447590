import { Component, ViewEncapsulation, Input, ChangeDetectorRef, OnInit } from '@angular/core';
import { UiTabsActionsService } from './services/ui-tabs-actions.service';

@Component({
  selector: 'ui-tabs-actions',
  templateUrl: './ui-tabs-actions.component.html',
  styleUrls: [ './ui-tabs-actions.component.scss' ],
  providers: [ UiTabsActionsService ],
  encapsulation: ViewEncapsulation.None,
})
export class UiTabsActions implements OnInit {
  @Input() public tabsTitle: string;

  public areActionsShown = false;

  constructor(private readonly cdr: ChangeDetectorRef, private readonly uiTabsActionsService: UiTabsActionsService) {}

  public ngOnInit() {
    this.uiTabsActionsService.areActionsShown.subscribe((areActionsShown) => {
      this.areActionsShown = areActionsShown;
      this.cdr.detectChanges();
    });
  }
}
