import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { CookDeliciousSnack, CookInedibleSnack, CookNormalSnack, CookSnackySnack } from './state/snackbar.state';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService implements OnDestroy {
  private noticeSubscription: Subscription;

  constructor(
    noticeService: NoticeService,
    private store: Store
  ) {
    this.noticeSubscription = noticeService.notices$.subscribe((notice) => {
      this.handleNotice(notice);
    });
  }

  ngOnDestroy(): void {
    this.noticeSubscription.unsubscribe();
  }

  handleNotice(notice: Notice) {
    switch (notice.level) {
      case NoticeLevels.INFO:
        this.store.dispatch(new CookNormalSnack(notice.message, notice.params, notice.secondMessage, notice.secondParams, notice.image));
        return;

      case NoticeLevels.ERROR:
        this.store.dispatch(new CookInedibleSnack(notice.message, notice.params, notice.secondMessage, notice.secondParams, notice.image));
        return;

      case NoticeLevels.SUCCESS:
        this.store.dispatch(new CookDeliciousSnack(notice.message, notice.params, notice.secondMessage, notice.secondParams, notice.image));
        return;

      case NoticeLevels.SNACK:
        this.store.dispatch(new CookSnackySnack(notice.message, notice.params, notice.secondMessage, notice.secondParams, notice.image));
        return;
    }
  }
}
