import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { randomBytes } from 'crypto';
import { TextMaskConfig } from 'angular2-text-mask';
import { UntypedFormGroup } from '@angular/forms';
import { LanguageEnum } from '../../interfaces/ILanguage';
import { UiInputType } from '../ui-input/ui-input.component';

@Component({
  selector: 'ui-switch-field-static',
  templateUrl: './ui-switch-field-static.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-switch-field-static',
  },
})
export class UiSwitchFieldStatic {
  @Input('label') public label: string;

  @Input('locale') public locale: string = LanguageEnum.FRENCH;

  @Input('name') public name: string = randomBytes(4).toString('hex');

  @Input('value') public value: number | string;

  @Input('type') public type: UiInputType = UiInputType.Text;

  @Input('placeholder') public placeholder: string;

  @Input('mask') public mask: TextMaskConfig;

  @Input('required') public required = false;

  @Input('readonly') public readonly = false;

  @Input('disabled') public disabled = false;

  @Input('group') public group: UntypedFormGroup;

  @Input('control') public control: string = this.name;

  @Input('arrayName') public arrayName: string;

  @Input('groupName') public groupName: string;

  @Input('isEditing') public isEditing = false;

  @Input('maxlength') public maxlength = '';

  @Input('forceInvalid') public forceInvalid = false;

  @Input('functionToExecuteOnInput') public functionToExecuteOnInput: (arg0: string) => string;

  @Input('iconName') public iconName = '';

  @Input('iconModifier') public iconModifier = '';

  @Input('errorMessage') public errorMessage: string = null;

  @Input() public tooltipText: string;

  @Output() public onInput: EventEmitter<string> = new EventEmitter<string>();
}
