import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "projects/@common/environments/environment";
import { Observable } from "rxjs";
import { randomId } from "projects/@common/utils/utils";


// Catches the request and adds md.eco signature to it
@Injectable()
export class TraceInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Only sign requests made to md.eco
    if (!this._isEcoApi(req.url)) {
      return next.handle(req);
    }

    const traced = req.clone({ setHeaders: { "X-Client-Trace-Id" : randomId(16) } });
    return next.handle(traced);
  }

  private _isEcoApi(url: string): boolean {
    return !!Object.values(environment.ecoUrls).find((ecoUrl) => url.includes(ecoUrl));
  }
}