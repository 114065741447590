import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'jsonPrettier',
})
export class JsonPrettierPipe implements PipeTransform {
  transform(value: string, spaces: number = 2): string {
    if (this.isJsonString(value)) {
      return JSON.stringify(JSON.parse(value), null, spaces);
    }
  }

  private isJsonString(string: string) {
    try {
      JSON.parse(string);
    } catch (e) {
      return false;
    }
    return true;
  }
}
