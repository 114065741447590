<div class="flex-box">
  <label ui-label>{{ label }}</label>
  <ng-content></ng-content>
</div>

<div [class.ui-static--value-with-image]="iconName || user">
  <ng-container *ngIf="iconName && iconSide === iconSideEnum.LEFT">
    <ng-container *ngTemplateOutlet="icon"></ng-container>
  </ng-container>

  <ui-avatar
    *ngIf="user"
    class="ui-static--avatar"
    [user]="user"
    size="tiny"
  ></ui-avatar>

  <div class="ui-static--value" [class.ui-static--with-break-line]="allowLineBreak">
    <ng-container *ngIf="htmlValue"><div [innerHTML]="htmlValue"></div></ng-container>
    <ng-container *ngIf="value">{{ value?.toString()?.length < 1 ? '-' : value }}</ng-container>
  </div>

  <ng-container *ngIf="iconName && iconSide === iconSideEnum.RIGHT">
    <ng-container *ngTemplateOutlet="icon"></ng-container>
  </ng-container>
</div>

<ng-template #icon>
  <ui-icon
    *ngIf="!isCustomIcon; else customIcon"
    [name]="iconName"
    size="small"
    [modifier]="iconModifier"></ui-icon>
</ng-template>

<ng-template #customIcon>
  <img
    class="ui-static--image"
    [ngClass]="{
      'ui-static--image--left': iconSide === iconSideEnum.LEFT,
      'ui-static--image--right': iconSide === iconSideEnum.RIGHT
    }"
    [ngStyle]="{ height: iconSize ? iconSize : 'auto' }"
    [src]="'assets/' + iconName + (isSvg ? '.svg' : '.png')"
    [alt]="iconName"/>
</ng-template>
