import { randomBytes } from 'crypto';
import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'ui-switch-text-static',
  templateUrl: './ui-switch-text-static.component.html',
  styleUrls: [ './ui-switch-text-static.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-switch-text-static',
  },
})
export class UiSwitchTextStatic {
  @Input('label') public label: string;

  @Input('name') public name: string = randomBytes(4).toString('hex');

  @Input('value') public value: string;

  @Input('placeholder') public placeholder = '';

  @Input('required') public required = false;

  @Input('readonly') public readonly = false;

  @Input('disabled') public disabled = false;

  @Input('group') public group: UntypedFormGroup;

  @Input('control') public control: string = this.name;

  @Input('arrayName') public arrayName: string;

  @Input('groupName') public groupName: string;

  @Input('maxlength') public maxlength = '';

  @Input('forceInvalid') public forceInvalid = false;

  @Input('rowsNumber') public rowsNumber = 4;

  @Input('isEditing') public isEditing = false;
}
