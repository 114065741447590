import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiKitModule } from '@ui-kit/uikit.module';
import { ComponentsModule } from 'projects/@common/components/components.module';
import { getUikitModule } from 'projects/@common/utils/uikit.module.factory';
import { DisplayModule } from '../display/display.module';
import { I18nModule } from '../i18n/i18n.module';
import { Detail } from '../layout/components/detail/detail.component';
import { Page } from '../layout/components/page/page.component';

const uikitModule = getUikitModule();

@NgModule({
  imports: [
    CommonModule,
    DisplayModule,
    I18nModule,
    UiKitModule,
    ComponentsModule,
  ],
  declarations: [ Page, Detail ],
  exports: [
    Page, // Enable feature modules to use the page component
    Detail,
    DisplayModule, // Enable feature modules to use conditional display directive,
    ComponentsModule,
  ],
})
export class FeatureSharedModule {
  static commonImports() {
    return [ uikitModule, CommonModule, I18nModule, FeatureSharedModule ];
  }
}
