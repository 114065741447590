import { TimeUtil } from "../../../services/time-util";

export enum CustomTimePeriodChangeEventSource {
  DatetimePickerStart = "DatetimePickerStart",
  DatetimePickerEnd = "DatetimePickerEnd"
}

export class CustomTimePeriod {
  public timestampFrom = 0;
  public timestampTo = 0;

  public changeEventSource = CustomTimePeriodChangeEventSource;

  constructor() {
  }

  public resetStart(): void {
    this.timestampFrom = null;
  }

  public resetEnd(): void {
    this.timestampTo = null;
  }

  public resetAll(): void {
    this.resetStart();
    this.resetEnd();
  }

  public get isValidStart(): boolean {
    return !this.timestampFrom || this.timestampFrom > TimeUtil.dayDuration;
  }

  public get isValidEnd(): boolean {
    return !this.timestampTo || this.timestampTo > TimeUtil.dayDuration;
  }

  public onChangeCustomTimePeriod(value: number, source: CustomTimePeriodChangeEventSource): void {
    switch (source) {
      case CustomTimePeriodChangeEventSource.DatetimePickerStart:
        this.timestampFrom = value;
        break;
      case CustomTimePeriodChangeEventSource.DatetimePickerEnd:
        this.timestampTo = value;
        break;
    }
  }
}
