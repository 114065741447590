<svg
  class="ui-spinner--vector"
  [ngClass]="classes"
  viewBox="0 0 32 32"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <circle
    class="ui-spinner--vector-path"
    [id]="hash"
    fill="none"
    stroke-dasharray="100.531"
    stroke-width="2"
    stroke-linecap="round"
    cx="16"
    cy="16"
    r="14"
  />
  <animate
    [attr.xlink:href]="'#' + hash"
    attributeName="stroke-dashoffset"
    calcMode="spline"
    values="100.5309649149; 25.1327412287; 100.5309649149"
    keyTimes="0; 0.5; 1"
    keySplines=".4 .1 0 .2; .3 .1 .5 0"
    dur="1.5s"
    repeatCount="indefinite"
  />
  <animateTransform
    [attr.xlink:href]="'#' + hash"
    attributeName="transform"
    attributeType="XML"
    type="rotate"
    calcMode="spline"
    values="0 16 16; 135 16 16; 450 16 16"
    keyTimes="0; 0.5; 1"
    keySplines=".4 .1 0 .2; .3 .1 .5 0"
    dur="1.5s"
    repeatCount="indefinite"
  />
  <animateTransform
    [attr.xlink:href]="'#' + hash"
    attributeName="transform"
    attributeType="XML"
    type="rotate"
    additive="sum"
    calcMode="spline"
    from="0 16 16"
    to="270 16 16"
    keySplines=".4 .1 0 .2; .3 .1 .5 0"
    dur="1.5s"
    repeatCount="indefinite"
  />
</svg>
