import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiEndpointPrefixEnum, EcoApi } from "../../../../definitions/eco-api";
import { EcoUrlService } from "../../core/url.service";
import {
  IListIncidentsResolutionStatusesRequest,
  IListIncidentsResolutionStatusesResponse,
  IListPlaybooksIncidentsCountRequest,
  IListPlaybooksIncidentsCountResponse
} from "./dashboard.definitions";
import { IRespondResponse } from "projects/@common/definitions/IRespondResponse";

@Injectable({
  providedIn: 'root',
})
export abstract class DashboardApi extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.alerting;

  constructor(http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public abstract listPlaybooksIncidentsCount(organizationId: string, queryParam?: IListPlaybooksIncidentsCountRequest): Promise<IRespondResponse<IListPlaybooksIncidentsCountResponse>>;
  public abstract listIncidentsResolutionStatuses(organizationId: string, queryParam?: IListIncidentsResolutionStatusesRequest): Promise<IListIncidentsResolutionStatusesResponse>;
}
