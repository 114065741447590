import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { AccumulatorsState } from './accumulator.store';

@NgModule({
  imports: [
    NgxsModule.forFeature([ AccumulatorsState ]),
  ],
  declarations: [],
  exports: [],
})
export class AccumulatorModule {}


