<button
  class="ui-menu-options-button"
  ui-button
  icon="ico_dots"
  [matMenuTriggerFor]="menu"
  [isSvg]="true"
  [custom-icon]="true"
  (mousedown)="openMenu(); $event.preventDefault(); $event.stopPropagation();"
  (menuOpened)="emitMenuOpened()"
  (menuClosed)="emitMenuClosed()"
></button>

<mat-menu #menu="matMenu" class="mat-menu">
  <ng-template matMenuContent>

    <ng-container *ngFor="let option of menuOptions; trackBy:trackByMenuOptionId">

      <a
        *ngIf="option.routerLink"
        class="ui-menu-options-popup-router-link"
        [routerLink]="option.routerLink"
        [queryParams]="option.queryParams"
        [queryParamsHandling]="option.queryParamsHandling"
      >
        <button
          class="ui-menu-options-popup-option"
          mat-menu-item
        >
          <img [src]="option.iconPath" />
          <span>{{ option.translation }}</span>
        </button>
      </a>

      <button
        *ngIf="!option.routerLink"
        class="ui-menu-options-popup-option"
        mat-menu-item
        (mousedown)="emitMenuOptionClicked(option); closeMenu(); $event.preventDefault(); $event.stopPropagation();"
      >
        <img [src]="option.iconPath" />
        <span>{{ option.translation }}</span>
      </button>

    </ng-container>

  </ng-template>
</mat-menu>


<ng-template
  #menuItemContent
  let-option
>
  <button
    class="ui-menu-options-popup-option"
    mat-menu-item
    (mousedown)="emitMenuOptionClicked(option); closeMenu(); $event.preventDefault(); $event.stopPropagation();"
  >
    <img [src]="option.iconPath" />
    <span>{{ option.translation }}</span>
  </button>
</ng-template>