<button
  [attr.data-test-id]="'topbar_user_menu'"
  class="topbar-user--button"
  #trigger
  #origin="cdkOverlayOrigin"
  cdk-overlay-origin
  ui-button
  color="topbar"
  (click)="toggle()"
  [tooltip]="'topbar.user.popover.actions.account'|t"
>
  <span class="topbar-user--name">{{
    (userName$ | async) || ('common.unkown' | t)
  }}</span>
</button>

<ng-template
  cdk-connected-overlay
  cdkConnectedOverlayLockPosition
  [cdkConnectedOverlayOffsetY]="offsetY"
  [cdkConnectedOverlayOffsetX]="offsetX"
  [cdkConnectedOverlayPositions]="positions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="(isPopoverActive$ | async)(hostname)"
  (detach)="close()"
>
  <topbar-user-popover></topbar-user-popover>
</ng-template>
