import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { LanguageEnum } from '../../interfaces/ILanguage';

@Component({
  selector: 'user-not-onboarded-icon',
  templateUrl: './user-not-onboarded-icon.component.html',
  styleUrls: [ './user-not-onboarded-icon.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'user-not-onboarded-icon',
  },
})
export class UserNotOnboardedIcon implements OnInit {
  @Input() public effectiveDate: number;
  @Input() public locale: LanguageEnum;


  public translation = { description: { fr: '', en: '' } };

  public constructor(private datepipe: DatePipe) {
  }

  public ngOnInit(): void {
    this.translation.description = {
      fr: `Sera effectif le ${this.formatEffectiveDate(this.effectiveDate)}`,
      en: `Will be effective on ${this.formatEffectiveDate(this.effectiveDate)}`,
    };
  }

  public isUserOnboarded(userEffectiveDate: number): boolean {
    return userEffectiveDate > Date.now();
  }

  public formatEffectiveDate(userEffectiveDate: number): string {
    return this.datepipe.transform(
      new Date(userEffectiveDate),
      'longDate',
      null,
      this.locale === 'fr' ? 'fr-ca' : 'en'
    );
  }
}
