import { Location } from "@angular/common";
import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { EcoSessionState } from "@common/modules/session/state/session.state";
import { Select, Store } from "@ngxs/store";
import { Autocomplete, AutocompleteTypes } from "@ui-kit/components/autocomplete/autocomplete.component";
import { AutocompleteCustomValue } from "@ui-kit/components/autocomplete/custom-autocomplete/custom-autocomplete.component";
import { AllSelectorCustomValue, OrganizationAutocompleteSearchResult } from "@ui-kit/components/autocomplete/organization-autocomplete/organization-autocomplete.component";
import { UiTableDirection } from "@ui-kit/components/ui-table/ui-table.component";
import { ConnectorsApiOrganizationService } from "projects/@common/services/api/detect/organizations/connectors-api-organizations";
import { IamApiService } from "projects/@common/services/api/iam/iam.api";
import { OrganizationParametersFilters } from "projects/@common/services/definitions/fetchOrganizations.definition";
import { MobileService } from "projects/@common/services/mobile.service";
import { BehaviorSubject, Observable } from "rxjs";
import { AppState, ClearSelectedOrganization, SetExpanded, SetSelectedOrganization, UpdateAppTitle } from "../../stores/app.state";

export enum previewModeEnum {
  BETA = 'beta',
  PREVIEW = 'preview',
  EXPERIMENTAL = 'experimental',
  DEPRECATED = 'deprecated'
}

export interface IOrgSelectorOrganization {
  id: string;
  name: string;
}

export enum SearchMode {
  RESPOND = "respond",
  IAM = "iam"
}

@Component({
  selector: '[page]',
  templateUrl: './page.component.html',
  styleUrls: [ './page.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'page',
  },
})
export class Page implements OnInit {
  @Input() public appPagePaths: string[] = [];

  @Input() public previewMode?: previewModeEnum;

  @Input() public specialPageName: string;

  @Input() public withPageHeader = true;

  @Input() public contextualHelp = '';

  @Input() public returnIcon = false;

  @Input() public returnPath: string = null;

  @Input() public titleExtra: string;

  @Input() public tooltipTitle: string;

  @Input() public isEditing = false;

  @Input() public isSaving = false;

  @Input() public isValid = true;

  @Input() public hasTopSection = false;

  @Input() public actionButtonText = 'common.action.save';

  @Input() public useMobileHeader = false;

  @Input() public searchMode: SearchMode = SearchMode.RESPOND;

  @Input() public allSelectorCustomValue?: AllSelectorCustomValue | null = null;

  @Input() public disableOrganizationSelector = false;

  @Output() onScrollToBottom: EventEmitter<void> = new EventEmitter();

  @Output() onAction = new EventEmitter<void>();

  @Output() onCancel = new EventEmitter<void>();

  @Output() public organizationChanged: EventEmitter<IOrgSelectorOrganization> = new EventEmitter();

  @Select(AppState.title) public title$: Observable<string>;

  @Select(AppState.isExpanded) public isSideBarExpanded$: Observable<boolean>;

  @Select(AppState.selectedOrganization) public selectedOrganization$: BehaviorSubject<IOrgSelectorOrganization>;

  @ViewChild('autocomplete') autocomplete!: Autocomplete;

  public showReturnValidationDialog = false;

  public appPage?: any;

  public organizationSubscription: any;
  public autocompleteTypes = AutocompleteTypes;
  public organizationInit = false;
  public organizationSelected = false;
  public organizationSubscribed = true;
  public initItems: Array<AutocompleteCustomValue> = [];
  public searchOrganizationFunction: (args: any) => Promise<any>;
  public showOrgSelector = false;

  private lastScrollTop = 0;

  private isVarMode: boolean = false;

  public constructor(
    protected readonly store: Store,
    public readonly mobileService: MobileService,
    private readonly location: Location,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly iamApiService: IamApiService,
    private readonly connectorsApiOrganizationService: ConnectorsApiOrganizationService,
    @Inject('PAGES_ENUM') private pages: any,
    @Inject('DISABLE_SIDEBAR') public disableSidebar: boolean
  ) {
    this.isVarMode = this.store.selectSnapshot(EcoSessionState.varMode);
  }

  public ngOnInit() {
    // Only showing the organisation selector when the user's organization is a VAR organization and if the type of console is adm or org.
    if (!this.disableOrganizationSelector && this.isVarMode) {
      this.showOrgSelector = true;
    }

    switch (this.searchMode) {
      case SearchMode.RESPOND:
        this.searchOrganizationFunction = this.searchRespondOrganizationsFunction;
        break;
      case SearchMode.IAM:
        this.searchOrganizationFunction = this.searchIamOrganizationsFunction;
        break;
    }

    this.extractAppPage();
    this.setPageName();

    // should fetch org detail from path param based on SearchMode.
    if (this.showOrgSelector) {
      if (this.allSelectorCustomValue) {
        this.initItems.push(this.allSelectorCustomValue);
      }

      const organizationEcoId = this.route.snapshot.paramMap.get('organizationEcoId');

      if (organizationEcoId) {
        if (this.searchMode === SearchMode.IAM) {
          this.iamApiService.describeOrganization(organizationEcoId).then((organization) => {
            this.store.dispatch(new SetSelectedOrganization({ selectedOrganization: { id: organizationEcoId, name: organization.name } }));
            this.organizationChanged.emit({ id: organizationEcoId, name: organization.name });
            this.initItems = [ { value: organizationEcoId, displayValue: organization.name } ];
            this.organizationInit = true;
          });
        } else {
          this.connectorsApiOrganizationService.describeOrganization(organizationEcoId).then((organization) => {
            this.store.dispatch(new SetSelectedOrganization({ selectedOrganization: { id: organizationEcoId, name: organization.name } }));
            this.organizationChanged.emit({ id: organizationEcoId, name: organization.name });
            this.initItems = [ { value: organizationEcoId, displayValue: organization.name } ];
            this.organizationInit = true;
          });
        }

        this.organizationSubscribed = true;
        this.organizationSelected = true;
        return;
      }
      this.loadOrganizations();
    }
  }

  public ngOnChanges(): void {
    this.setPageName();
  }

  async ngOnDestroy() {
    if (this.organizationSubscription) {
      this.organizationSubscription.complete();
    }
  }

  public async backdropClick() {
    this.store.dispatch(new SetExpanded({ expanded: false }));
  }

  public goToPreviousLocation() {
    if (this.returnPath) {
      this.router.navigateByUrl(this.returnPath);
    } else {
      this.location.back();
    }
  }

  public handleContextualHelpClick() {
    if (this.appPage?.guid && this.appPage?.hasPublishedVersion) {
      // this.drawerService.replaceCurrentDrawer(ContextualHelpDrawer, {
      //   component: DrawerPageEnum.contextualHelp,
      //   data: { guid: this.appPage.guid }
      // });
    }
  }

  @HostListener('scroll', [ '$event.target' ])
  onScroll(event: any) {
    const tableViewHeight = event.target.offsetHeight;
    const tableScrollHeight = event.target.scrollHeight;
    const scrollLocation = event.target.scrollTop;

    const limit = tableScrollHeight - tableViewHeight - 200;

    if (scrollLocation > limit && scrollLocation > this.lastScrollTop) {
      this.onScrollToBottom.emit();
    }
    this.lastScrollTop = scrollLocation;
  }

  public handleOrganizationSelection(selectedOrganization: { value: string, displayValue: string }): Promise<void> {
    if (!selectedOrganization) {
      if (this.organizationSelected) {
        this.store.dispatch(new ClearSelectedOrganization());
        this.organizationChanged.emit(null);
        this.handleURLOrganizationEcoId(null);
      }
      return;
    }

    this.organizationSelected = true;
    this.organizationSubscribed = true;
    const organizationEcoId = selectedOrganization.value;
    const organizationName = selectedOrganization.displayValue;
    this.store.dispatch(new SetSelectedOrganization({
      selectedOrganization: {
        id: organizationEcoId,
        name: organizationName,
      },
    }));
    this.organizationChanged.emit({ id: organizationEcoId, name: organizationName });
    this.handleURLOrganizationEcoId(organizationEcoId);
  }

  public clearSelectedOrganization(): void {
    this.store.dispatch(new ClearSelectedOrganization());
    this.autocomplete.clearSearchQuery();
    this.handleURLOrganizationEcoId(null);
  }

  public handleURLOrganizationEcoId(organizationEcoId?: string): void {
    // adds or remove the organizationEcoId from the URL
    const ecoIdRegex = /[A-Z0-9]{26}/;
    const urlParts = this.location.path().split('/').filter((part) => !ecoIdRegex.test(part));
    if (organizationEcoId && (!this.allSelectorCustomValue || organizationEcoId !== this.allSelectorCustomValue.value)) {
      // insert the organizationEcoId (ex: respond/{organizationEcoId}/...)
      urlParts.splice(2, 0, organizationEcoId);
    }
    const newPath = urlParts.join('/');
    this.location.replaceState(newPath);
  }

  public searchRespondOrganizationsFunction = async (params: OrganizationParametersFilters): Promise<OrganizationAutocompleteSearchResult> => {
    const result = await this.connectorsApiOrganizationService.getManagedOrganizations(params);
    return {
      total: result.total,
      nextItem: result.nextItem,
      items: result.items.map((org) => ({
        id: org.organization_eco_id,
        name: org.name,
      })),
    };
  };

  private extractAppPage(): void {
    let appPage: any;
    for (const pagePath of this.appPagePaths) {
      appPage = appPage != null ? appPage[pagePath] : this.pages[pagePath];
    }
    this.appPage = appPage;
  }

  private loadOrganizations(): void {
    this.organizationSubscription = this.selectedOrganization$.subscribe((cachedOrg) => {
      if (cachedOrg && !this.organizationSelected) {
        this.searchOrganizationFunction({
          order: UiTableDirection.Asc,
          searchName: cachedOrg.name,
          size: 20,
          from: 0,
        })
          .then((organizations) => {
            const organizationAvailable = !!organizations.items.find((org) => org.id === cachedOrg.id);

            if (organizationAvailable) {
              this.initItems.push({ value: cachedOrg.id, displayValue: cachedOrg.name });
              this.organizationChanged.emit(cachedOrg);
              this.organizationSubscribed = true;
              this.organizationSelected = true;
              this.handleURLOrganizationEcoId(cachedOrg.id);
            } else {
              this.store.dispatch(new ClearSelectedOrganization());
              this.organizationSubscribed = false;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.organizationInit = true;
          });
      } else {
        this.organizationInit = true;
      }
    });
  }

  private setPageName(): void {
    let pageName = this.specialPageName ? this.specialPageName : this.appPage?.pageTitleTranslationKey;
    pageName = pageName != null ? pageName : '';

    setTimeout(() => {
      this.store.dispatch(new UpdateAppTitle({ key: pageName }));
    });
  }

  private searchIamOrganizationsFunction = (params?: {
    from?: number;
    size?: number;
    searchName?: string;
    order?: string;
    includeManagerOrganization?: boolean;
  }): Promise<any> => {
    params['includeManagerOrganization'] = true;
    return this.iamApiService.listManagedOrganizations(params);
  };
}
