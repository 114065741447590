import { ComponentType } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(public dialog: MatDialog) { }

  public openDialog<T, U>(component: ComponentType<T>, data?: U, closeOnClickOut?: boolean): MatDialogRef<T, U> {
    return this.dialog.open<T, U>(component, {
      width: 'max-content',
      height: 'max-content',
      maxWidth: '90vw',
      maxHeight: '90vh',
      hasBackdrop: true,
      backdropClass: 'adm-modal-backdrop-class',
      panelClass: 'modal-service-dialog-modal',
      disableClose: Boolean(!closeOnClickOut),
      data,
    });
  }

  public closeDialog(): void {
    this.dialog.closeAll();
  }
}
