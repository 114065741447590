import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Eco } from 'projects/@common/definitions/eco';

@Component({
  selector: 'usecase-affected-resources',
  templateUrl: './usecase-affected-resources.component.html',
  styleUrls: [ './usecase-affected-resources.component.scss' ],
})
export class UsecaseAffectedResourcesComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() controlName: string = "affectedResources";
  @Input() value: string;
  @Input() isEditing: boolean;
  @Input() locale: Eco.Languages;
  @Input() showLabel: boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.formGroup.get(this.controlName).setValue(this.value);
  }
}
