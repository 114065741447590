<table class="tables-table">
  <tr class="tables--headers">
    <th *ngIf="canExpend" class="tables--headers--expendable"></th>
    <th
      (click)="sort()"
      class="tables--headers--primary-column tables--headers--sortable"
    >
      <div>{{ 'users.title' | t }} ({{ users.length }})</div>
      <div>
        <ui-icon
          [name]="direction === directions.Asc ? 'arrow-asc' : 'arrow-desc'"
          size="small"
          modifier="secondary-gray"
        >
        </ui-icon>
      </div>
    </th>
    <th *ngIf="showLinks">{{ 'group.users.source' | t }}</th>
    <th *ngIf="showType">
      <div>
        {{ 'users.list.columns.type.name' | t }}
      </div>
    </th>
    <th *ngIf="isEditing"></th>
  </tr>

  <ng-container *ngIf="!isLoading && users?.length">
    <ng-container *ngFor="let user of users">
      <tr
        [ngClass]="{
          'tables--row-separator': showRowSeparator && !user['expended'],
          'tables--expendable-row': canExpend,
          'table-hovered-background': user[isHovered]
        }"
        (click)="toggleUser(user)"
        [title]="isDisabled(user) ? ('user.detail.isDisabled' | t) : ''"
        (mouseenter)="onHoverEnter(user)"
        (mouseleave)="onHoverLeave(user)"
      >
        <td *ngIf="canExpend" class="tables--headers--expendable">
          <img
            *ngIf="!mobileService.isMobile()"
            [src]="
              'assets/favicons/icon_chevron_' +
              (user['expended'] ? 'open' : 'close') +
              '.svg'
            "
          />
        </td>
        <td class="tables--name-part">
          <div class="users-table--avatar">
            <ui-avatar size="small" [user]="user"></ui-avatar>
          </div>
          <div class="tables--name-part--name users-table--name">
            <div [title]="user | fullname">
              <span [class.users-table--disabled-user]="isDisabled(user)">{{
                user | fullname
                }}</span>
              <ng-container *ngIf="showBadges">
                <span *ngIf="isSelf(user)" class="badge self">{{
                  'ressource.badge.self' | t
                  }}</span>
                <span *ngIf="isGuestUser(user)" class="badge guest">{{
                  'common.guest' | t
                  }}</span>
                <span *ngIf="isPending(user)" class="badge pending">{{
                  'alerts.list.columns.status.PENDING' | t
                  }}</span>
                <span *ngIf="isOwner(user)" class="badge owner">{{
                  'ressource.badge.owner' | t
                  }}</span>
              </ng-container>
            </div>
            <span
              class="tables--name-part--secondary-info"
              [title]="user?.mail"
            >
              {{ user?.mail }}
            </span>

            <ng-container
              *ngIf="showExpirationDate && user['expirationDate']"
            >
              <ui-expiration-time
                *ngIf="!isEditing"
                [expirationTime]="user['expirationDate']"
                [hasSmallPadding]="true"
              ></ui-expiration-time>
              <ng-container *ngIf="isEditing">
                <guest-user-expiration-date
                  [user]="user"
                  (onExpirationDateUpdate)="
                      updateGuestUserExpirationDate($event, user)
                    "
                >
                </guest-user-expiration-date>
              </ng-container>
            </ng-container>

            <div
              *ngIf="isPending(user) && !canExpend"
              class="pending-invitation"
            >
              <span class="guest-small-text--primary">{{
                'group.waiting.approbation.message' | t
                }}</span>
            </div>
          </div>
          <user-not-onboarded-icon
            *ngIf="isNotOnboarded(user)"
            [effectiveDate]="user.effectiveDate"
          ></user-not-onboarded-icon>
        </td>

        <td *ngIf="showLinks" class="users-information">
          <ui-popover
            *ngIf="user['profiles']?.length > 0"
            [value]="
              user['profiles']?.length +
              ' ' +
              ('group.associate.count.profile' +
                (user['profiles']?.length > 1 ? 's' : '') | t)
            "
            popoverTitle="group.associate.profiles"
            [popoverValues]="user['profiles']"
          >
          </ui-popover>
          <ui-popover
            *ngIf="user['linkedGroups']?.length > 0"
            [value]="
              user['linkedGroups']?.length +
              ' ' +
              ('group.associate.count.securityGroup' +
                (user['linkedGroups']?.length > 1 ? 's' : '') | t)
            "
            popoverTitle="group.associate.securityGroups"
            [popoverValues]="user['linkedGroups']"
          >
          </ui-popover>
        </td>

        <td *ngIf="showType">
          <div>
            <img
              class="users-table--type"
              *ngIf="isPlatformUser(user)"
              [src]="'assets/images/logof2s.png'"
              alt="platform"
            />
            <img
              class="users-table--type"
              *ngIf="!isPlatformUser(user)"
              [src]="'assets/images/office365/o365.png'"
              alt="platform"
            />
          </div>
        </td>

        <td *ngIf="isEditing" class="delete-icon">
          <ng-container *ngIf="canRemoveUser(user)">
            <ui-icon
              class="table-row-delete-bouton"
              name="close"
              modifier="on-header"
              size="small"
              (click)="handleRemoveUserClicked(user)"
            ></ui-icon>
          </ng-container>
        </td>
      </tr>

      <tr
        *ngIf="user['expended']"
        class="tables--expended"
        (mouseenter)="onHoverEnter(user)"
        (mouseleave)="onHoverLeave(user)"
        [class.table-hovered-background]="user[isHovered]"
      >
        <td colspan="4" class="expended-users">
          <div *ngIf="isPending(user)" class="pending-invitation">
            <span class="guest-small-text--primary">{{
              'group.waiting.approbation.message' | t
              }}</span>
          </div>
        </td>
      </tr>
    </ng-container>
  </ng-container>
</table>

<ng-container *ngIf="isLoading">
  <ui-spinner
    class="table-loading-spinner"
    size="medium"
    modifier="secondary"
  ></ui-spinner>
</ng-container>

<ng-container *ngIf="!isLoading && users?.length <= 0">
  <span class="empty-table-message">{{ 'users.table.no.users' | t }}</span>
</ng-container>

<ui-modal *ngIf="showRemoveSelfWarningPopup" class="remove-self-warning-popup">
  <div class="remove-self-warning-popup--wrapper">
    <h2>{{'iam.profiles.tab.members.warningRemoveSelfPopup.title' | t}}</h2>
    <p>{{'iam.profiles.tab.members.warningRemoveSelfPopup.message' | t}}</p>
    <div class="remove-self-warning-popup--actions-container">
      <button ui-button color="standard" (click)="confirmRemoveSelf()">{{ 'common.boolean.true' | t }}</button>
      <button ui-button color="secondary" (click)="cancelRemoveSelf()">{{ 'common.boolean.false' | t }}</button>
    </div>
  </div>
</ui-modal>