import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { Eco } from 'projects/@common/definitions/eco';
import { Observable } from 'rxjs/internal/Observable';
import { EcoSignatureProvider } from '@md.eco/iam.sig.provider';

// Watches session state for hmac key changes
// Uses the right keys to sign requests with
@Injectable({
  providedIn: 'root',
})
export class EcoSignatureService {
  @Select(EcoSessionState.hmac) private _hmac$: Observable<Eco.IHmacKeys>;

  private keys: Eco.IHmacKeys;

  private provider = new EcoSignatureProvider();

  constructor() {
    this._hmac$.subscribe((keys) => {
      this.keys = keys;
    });
  }

  public getEcoHeaders(req: {
    body: any,
    method: string,
    urlWithParams: string,
  }): Eco.IRequestHeaders {
    // If there is no registered keys, just dont attempt signature
    if (!this.keys.accessKey) {
      return {};
    }

    // Adapt the angular req object to the expected format
    const method = req.method.toLowerCase();
    const url = this._getUrlWithoutHostname(req.urlWithParams);

    const headers = this.provider.getEcoHeaders({ body: req.body, method, url }, this.keys);
    return headers;
  }

  private _getUrlWithoutHostname(urlWithHostname: string) {
    const url = new URL(urlWithHostname);
    return url.pathname + url.search; // Important to add query parameters
  }
}
