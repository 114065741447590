import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Eco } from 'projects/@common/definitions/eco';
import { I18nService } from './i18n.service';

@Injectable()
@Pipe({
  name: 'translatedObject',
  pure: true,
})
export class TranslatedObjectPipe implements PipeTransform {
  public constructor(protected readonly i18n: I18nService) {
  }

  private readonly languages = Object.values(Eco.Languages);

  public transform(object: any, language?: Eco.Languages): string {
    let transformed = null;
    if (object) {
      const locale = language ? language : this.i18n.currentLocale;
      if (object[locale]) {
        transformed = object[locale];
      } else {
        const fallbackLocale = this.languages.find((lang) => lang !== locale);
        transformed = object[fallbackLocale];
      }
    }
    return transformed;
  }
}
