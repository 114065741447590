import { Injectable } from "@angular/core";

export enum LocalStorageKeysEnum {
  NotificationLanguage = 'NotificationLanguage'
}

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public setLocal(key: string, data: string) {
    localStorage.setItem(key, data);
  }

  public getLocal(key: string): string {
    return localStorage.getItem(key);
  }

  public setSession(key: string, data: string) {
    sessionStorage.setItem(key, data);
  }

  public getSession(key: string): string {
    return sessionStorage.getItem(key);
  }

  public prepareSessionForTabSwitch(): void {
    const session = this.getSession('eco.iam.data');

    if (session) {
      localStorage.setItem('eco.iam.data', session);
    }
  }

  public getLocalStorageSession(): string {
    const session = this.getSession('eco.iam.data');
    return session ? session : '';
  }

  public removeLocalSession(): void {
    localStorage.removeItem('eco.iam.data');
  }

  public getSidebarCollapsedState(): boolean {
    const isCollapsed = this.getLocal('isSibebarCollapsed');
    return isCollapsed === 'true';
  }

  public setSidebarCollapsedState(isCollapsed: boolean): void {
    this.setLocal('isSibebarCollapsed', isCollapsed.toString());
  }
}
