import { I18nService } from '../modules/i18n/i18n.service';

interface IErrorConfig {
  errors: Record<string, boolean>;
  dirty: boolean;
}

export class FormErrorUtils {
  constructor(private readonly i18nService: I18nService) { }

  public getErrorMessage(errorConfig: IErrorConfig): string {
    if (!(errorConfig.dirty && errorConfig.errors)) return null;

    const errorList = [];

    for (const key in errorConfig.errors) {
      if (errorConfig.errors[key]) errorList.push(key);
    }

    return this.i18nService.translate(`form.validation.${errorList.shift()}`);
  }
}
