import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UiInputType } from '../ui-input/ui-input.component';

@Component({
  selector: 'ui-switch-text-area-static',
  templateUrl: './ui-switch-text-area-static.component.html',
  styleUrls: [ './ui-switch-text-area-static.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-switch-text-area-static',
  },
})
export class UiSwitchTextAreaStatic {
  @Input('label') public label: string;

  @Input('required') public required = false;

  @Input('value') public value: number | string;

  @Input('type') public type: UiInputType = UiInputType.Text;

  @Input('group') public group: UntypedFormGroup;

  @Input('control') public control: string;

  @Input('arrayName') public arrayName: string;

  @Input('groupName') public groupName: string;

  @Input('isEditing') public isEditing = false;

  @Input('maxlength') public maxlength = '';

  @Input('rowsNumber') public rowsNumber = 10;
}
