import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[ui-step]',
})
export class UiStep {
  @Input() public stepName = '';

  @Input() public saveButton: string;

  @Input() public canGoBack = true;

  @Input() public canGoForward = true;

  constructor(public templateRef: TemplateRef<any>) {}
}
