import { randomBytes } from 'crypto';
import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import equal from "lodash.isequal";
import template from "lodash.template";


export function pick(obj: any, whitelist: string[]): any {
  return Object.entries(obj)
    .filter(([ key ]) => whitelist.includes(key))
    .reduce((accumulator, [ key, val ]) => Object.assign(accumulator, { [key]: val }), {});
}

export function omit(obj: any, blacklist: string[]): any {
  return Object.entries(obj)
    .filter(([ key ]) => !blacklist.includes(key))
    .reduce((accumulator, [ key, val ]) => Object.assign(accumulator, { [key]: val }), {});
}

/**
 * Prunes an object from null, false or empty strings values.
 * @params the object to be pruned
 * @return pruned object
 */
export function prune(obj: any): any {
  return Object.entries(obj)
    .filter(([ , value ]) => Boolean(value))
    .reduce((accumulator, [ key, val ]) => Object.assign(accumulator, { [key]: val }), {});
}

export function pruneObject(object: any): any {
  const isArray = Array.isArray(object);
  for (const k of Object.keys(object)) {
    if (Array.isArray(object[k]) && object[k].length === 0) {
      delete object[k];
    }
    if (object[k] === null) {
      if (isArray) {
        object.splice(k as any, 1);
      } else {
        delete object[k];
      }
    } else if (typeof object[k] === 'object') {
      pruneObject(object[k]);
    }
    if (isArray && object.length.toString() === k) {
      pruneObject(object);
    }
  }

  return object;
}

export function snake(str: string): string {
  const result = str.replace(/[A-Z\u00C0-\u00D6\u00D8-\u00DE]/g, (match) => `_${match.toLowerCase()}`);
  return str[0] === str[0].toUpperCase() ? result.substring(1) : result;
}

export function isObject(o: any): boolean {
  return o != null && typeof o === 'object';
}

export function groupBy(array: any[], key) {
  return array.reduce((accumulator, currentValue) => {
    accumulator[currentValue[key]] = (accumulator[currentValue[key]] || []).concat(currentValue);
    return accumulator;
  }, {});
}

export class UrlUtils {
  public static jsonToQueryParams(json: any): string {
    if (!json) {
      return "";
    }
    return Object.keys(json)
      .filter((key) => json[key] !== undefined && json[key] !== null)
      .filter((key) => UrlUtils.nonEmptyArray(json, key))
      .map((key) => `${key}=${encodeURIComponent(json[key])}`)
      .join('&');
  }

  public static stripEmptyQueryParams(path: string): string {
    return path.charAt(path.length - 1) === '?' ? path.slice(0, -1) : path;
  }


  private static nonEmptyArray(json: object, key: string) {
    if (Array.isArray(json[key])) {
      const nonEmptyArray = json[key].filter((value) => value);
      if (nonEmptyArray.length > 0) {
        return true;
      }
    } else {
      return true;
    }
    return false;
  }
}

export function getHttpErrorMessage(err: any): string {
  if (typeof err === "string") {
    return err;
  }
  if (typeof err === "object") {
    if (err.hasOwnProperty("message") && typeof err.message === "string") {
      return err.message;
    }
    if (err.hasOwnProperty("error") && err.error.hasOwnProperty("message")) {
      if (Array.isArray(err.error.message)) {
        return err.error.message.join(", ");
      }
      return err.error.message;
    }
    if (err.error.code) {
      return `common.error.${err.code}`;
    }
  }
  return err;
}

export function toTitleCase(value: string): string {
  // alex desroches -> Alex Desroches
  return value.replace(/\b(\S)/g, (t) => t.toUpperCase());
}

export function toTitleCaseName(str: string): string {
  // pier-étienne famille -> Pier-Étienne Famille
  return str.toLowerCase().replace(/(?:^|[\s-/])([a-zA-Z]|[à-ü]|[À-Ü])/g, (t) => t.toUpperCase());
}

export function normalizeString(str: string): string {
  // CAfÉ bRûlé -> cafe brule
  if (typeof str !== "string") return "";
  return str.trim()?.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
}

export function sortObjectKeys(obj: any) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(sortObjectKeys);
  }
  const sortedObj: any = {};
  Object.keys(obj).sort().forEach((key) => {
    sortedObj[key] = sortObjectKeys(obj[key]);
  });
  return sortedObj;
}

export function randomId(length : number = 8): string {
  return randomBytes(length).toString('hex');
}

export function mapArrayToObject(array: any[], keyProp: string, valueProp: string): { [key: string]: string } {
  if (!array) {
    return {};
  }
  return array
    .sort((a, b) => get(a, keyProp).localeCompare(get(b, keyProp)))
    .reduce((obj, item) => {
      const key = get(item, keyProp);
      const value = get(item, valueProp);
      obj[key] = value;
      return obj;
    }, {});
}

export const clone = cloneDeep;

export const select = get;

export const tmpl = template;

export const isEqual = equal;
