import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { UiColumnDef } from '../../../directives/ui-column-def.directive';
import { UiTable, UiTableDirection, UiTableSort } from '../ui-table.component';

@Component({
  selector: '[ui-header-sort], ui-header-sort',
  templateUrl: './ui-header-sort.component.html',
  styleUrls: [ './ui-header-sort.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    '(click)': 'handleClick()',
  },
})
export class UiHeaderSort {
  public constructor(
    @Inject('UI_SORT_HEADER_COLUMN_DEF') public readonly columnDef: UiColumnDef,
    public readonly table: UiTable<any>
  ) {}

  get direction(): UiTableDirection {
    const { direction, sortBy } = this.table;
    const { columnName } = this.columnDef;

    if (sortBy !== columnName) {
      return UiTableDirection.None;
    }

    return direction;
  }

  public handleClick(): void {
    const { sortBy } = this.table;
    const { columnName } = this.columnDef;
    let { direction } = this.table;

    if (!this.table) {
      return;
    }

    if (sortBy === columnName && direction === UiTableDirection.Desc) {
      direction = UiTableDirection.Asc;
    } else if (sortBy === columnName && direction === UiTableDirection.Asc) {
      direction = UiTableDirection.Desc;
    } else {
      direction = UiTableDirection.Asc;
    }

    this.table.handleSort({
      name: columnName,
      direction,
    } as UiTableSort);
  }
}
