import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'ui-select-with-label-value',
  templateUrl: './ui-select-with-label-value.component.html',
})
export class UiSelectWithLabelValue {
  @Input() label: string;

  @Input() description: string;

  @Input() name: string;

  @Input() required: boolean;

  @Input() controlName: string;

  @Input() group: UntypedFormGroup;

  @Input() values?: { value: string; displayValue: string }[];

  @Input() readonly: boolean = false;

  @Output() onValueChanged = new EventEmitter<{ controlName: string; value: string }>();


  public emitSelection(event: string): void {
    this.onValueChanged.emit({ controlName: this.controlName, value: event });
  }
}
