<div class="ui-table-sort-tool" (click)="toggle()">
  <button
    ui-button
    class="ui-table-sort-tool--button sort-button"
    modifier="on-highlight"
    color="linkBordedRegular"
    [icon]="'arrow-' + direction"
    [wide]="true"
  ></button>

  <ui-table-filter-tooltip
    class="ui-table-sort-tool--options"
    *ngIf="showOptions"
    prefix="table.sort."
    [option]="direction"
    [optionsEnum]="options"
    [optionIcons]="optionIcons"
    (option)="handleOption($event)"
  ></ui-table-filter-tooltip>
</div>
