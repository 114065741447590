import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { GroupTypeEnum, IconUtil, ResourceTypeEnum } from '../../../util/icon-util';
import { UiTableDirection } from '../../ui-table/ui-table.component';
import { AbstractAutocomplete } from '../AbstractAutocomplete';

@Component({
  selector: 'resource-autocomplete',
  templateUrl: './resource-autocomplete.component.html',
  styleUrls: [ '../autocomplete.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'resource-autocomplete',
  },
})
export class ResourceAutocomplete extends AbstractAutocomplete {
  @Input() public searchFunction: (args: any) => Promise<any>;

  @Input() public searchLimit = 50;

  @Input() public customNotFound: boolean;

  @Input() public oneItemAtATime = false;

  @Input() public canMultiSelect = false;

  @Input() public groupsFilter: GroupTypeEnum;

  @Input() public rolesOnly = false;

  @Input() public searchOnlyUserResources: boolean = false;

  @Input() public minNumLicenses = 0;

  @Input() public profileGuid = '';

  @Input() public locale: string;

  @Input() public resourceToIgnore: string[] = [];

  @Input()
  get selectedResources() {
    return this.selectedResourcesValue;
  }

  @Input() public expandHeight = '20rem';

  @Output() public isSearching: EventEmitter<boolean> = new EventEmitter();

  @Output() public elementClicked: EventEmitter<any> = new EventEmitter();

  @Output() selectedResourcesChange = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set selectedResources(value) {
    this.selectedResourcesValue = value;
    this.selectedResourcesChange.emit(this.selectedResourcesValue);
  }

  public selectedResourcesValue: any[] = [];

  public resources: any[] = [];

  public isSearchingResources = false;

  public groupTypeEnum = GroupTypeEnum;

  public resourceTypeEnum = ResourceTypeEnum;

  constructor() {
    super();
  }

  public searchItems(query: string, searchID: string) {
    this.setIsSearching(true);

    // We don't want the backend to return the already selected resources
    const exceptions: string[] = this.selectedResources.map((selectedResource) => selectedResource.guid);

    if (this.searchOnlyUserResources) {
      this.searchUserResources(query, searchID, exceptions);
    } else {
      this.searchOrgResources(query, searchID, exceptions);
    }
  }

  private searchOrgResources(query: string, searchID: string, exceptions: string[]) {
    this.searchFunction({
      order: UiTableDirection.Asc,
      search: query,
      groupTypes: GroupTypeEnum.O365OFFICEGROUP,
    })
      .then((resources) => {
        this.resources = resources.filter((resource) => !this.selectedResources.find((selected) => selected.id === resource.id));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (this._currentSearchID === searchID) {
          this.setIsSearching(false);
        }
      });
  }

  private searchUserResources(query: string, searchID: string, exceptions: string[]) {

  }

  public onResourceClick(clickedResource: any, elementIndex: number, mouseEvent: MouseEvent) {
    if (!clickedResource['isAlreadyClicked']) {
      this.addRippleEffect(elementIndex, mouseEvent);
      if (!this.oneItemAtATime) {
        this.selectedResourcesValue.push(clickedResource);
      }

      if (this.canMultiSelect) {
        clickedResource['isAlreadyClicked'] = true;

        // The timeout is mandatory. Without the timeout, the resources are modified before the click event complete its trigger
        // This has as a consequence to detect a click outside the component since the element is not there anymore at the end
        // and it closes the dropdown
        const filteredResources = this.resources.filter((resource) => resource.id !== clickedResource.id);
        this.resources = filteredResources;
      }

      this.elementClicked.emit({
        item: clickedResource,
        itemName: clickedResource.displayName,
      });
    }
    mouseEvent?.stopPropagation();
    mouseEvent?.preventDefault();
  }

  private setIsSearching(isSearching: boolean) {
    this.isSearchingResources = isSearching;
    this.isSearching.emit(this.isSearchingResources);
  }

  public getResourceIcon(resource: any): string {
    resource['groupType'] = resource.type;
    return IconUtil.getResourceAvatarIcon(resource);
  }
}
