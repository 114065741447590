<div
  class="button-box"
  [ngClass]="{'margin-top':marginTop}"
>
  <div
    class="button-box--action-button accept-button"
    [class.is-confirm-enabled]="isConfirmEnabled"
    [tooltip]="!isConfirmEnabled && notEnabledTooltip"
    (click)="apply($event)"
  >
    <img
      src="../../assets/favicons/icon_conforme.svg"
      alt="accept"
    />
  </div>
  <div
    class="button-box--action-button decline-button"
    (click)="cancel($event)"
  >
    <img
      src="../../assets/favicons/icon_stop_sharing.svg"
      alt="cancel"
    />
  </div>
</div>