import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";

import { Eco } from "projects/@common/definitions/eco";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { DynamicForm } from "../models/dynamicForm.model";
import { pruneObject } from "@common/utils/utils";

export const DynamicFormFieldType = {
  DROPDOWN: 'dropdown',
  TEXT_INPUT: 'textInput',
  NUMBER_INPUT: 'numberInput',
  CHECKBOX: 'checkbox',
  OBJECT_TEXTAREA: 'objectTextarea',
  ARRAY_TEXTAREA: 'arrayTextarea',
  MULTI_SELECT: 'multiSelect',
  SECRET: 'secret',
  DYNAMIC_FORM: 'dynamicForm',
};

export type DynamicFormFieldType = typeof DynamicFormFieldType[keyof typeof DynamicFormFieldType];

@Component({
  selector: 'ui-dynamic-form',
  templateUrl: './ui-dynamic-form.component.html',
  styleUrls: [ './ui-dynamic-form.component.scss' ],
})
export class DynamicFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() name: string;
  @Input() formDetails: DynamicForm;
  @Input() readonly = false;

  protected form: UntypedFormGroup;

  constructor(
    readonly formBuilder: UntypedFormBuilder,
    readonly i18n: I18nService
  ) { }

  public get locale(): Eco.Languages {
    return this.i18n.currentLocale as Eco.Languages;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formDetails && !changes.formDetails.firstChange) {
      this.clearSubForm();
      this.buildFormGroup();
    }
  }

  ngOnInit(): void {
    this.buildFormGroup();
  }

  ngOnDestroy(): void {
    this.clearSubForm();
  }

  public getValues(): any {
    if (this.formDetails.fields) {
      const values =  pruneObject(this.form.getRawValue());

      for (const field of this.formDetails.fields.filter((element) => element.type === DynamicFormFieldType.ARRAY_TEXTAREA)) {
        if (values[field.fieldName] === "") {
          // Removing manually if it's empty
          delete values[field.fieldName];
        } else {
          // Parsing value since it must be sent as a JSON.
          values[field.fieldName] = JSON.parse(values[field.fieldName]);
        }
      }

      // Getting values from sub parser's form
      for (const field of this.formDetails.fields.filter((element) => element.type === DynamicFormFieldType.DYNAMIC_FORM)) {
        const tempValues = (this.form.controls[field.fieldName] as UntypedFormGroup).getRawValue();
        values[field.fieldName] = Object.keys(tempValues).map((key) => ({
          id: key.split('-')[0], // AVRO-1 -> AVRO
          config: tempValues[key],
        }));
      }

      return values;
    }
    return null;
  }

  protected buildFormGroup(): void {
    this.form = this.formBuilder.group({});

    if (this.formDetails.fields) {
      for (const field of this.formDetails.fields) {
        const validators: ValidatorFn[] = [];
        field.required ? validators.push(Validators.required) : null;
        field?.options?.customValidators.forEach((customValidator) => validators.push(customValidator));

        if (field.type === DynamicFormFieldType.DYNAMIC_FORM) {
          const subForm = this.formBuilder.group({});
          this.form.addControl(field.fieldName, subForm);
        } else {
          this.form.addControl(field.fieldName, new UntypedFormControl(typeof field.value !== 'undefined' ? field.value : this.getDefaultFormValueInput(field.type), validators));
        }
      }

      this.formDetails.formGroup.addControl(this.name, this.form);
    } else {
      this.formDetails.formGroup.addControl(this.name, new UntypedFormControl());
    }
  }

  private clearSubForm(): void {
    this.formDetails.formGroup.removeControl(this.name);
  }

  protected getDefaultFormValueInput(type: DynamicFormFieldType): any {
    if (type === DynamicFormFieldType.ARRAY_TEXTAREA) {
      return "";
    }

    return null;
  }
}
