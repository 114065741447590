import { DatasourceTemplateUI, DatasourceImplementations } from "@common/services/api/respond/datasources/datasources.definitions";

export class DatasourcesTemplates {
  private templates: Array<DatasourceTemplateUI>;

  constructor(templates: Array<DatasourceTemplateUI>) {
    this.templates = [ ...templates ];
  }

  public getTemplateByType(implementation: DatasourceImplementations): DatasourceTemplateUI {
    return this.templates.find((template) => template.implementation === implementation);
  }

  public getTemplateType(): Array<DatasourceImplementations | string> {
    return this.templates.filter((template) => !!template.implementation).map((value) => value.implementation);
  }
}
