import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from '../../services/modal.service';

export interface IJustificationConfirmationData {
  title?: string;
  text?: string;
  currentLang: string;
  needsJustification?: boolean;
  justificationLabel?: string;
  justificationConfirmationLabel?: string;
  justificationConfirmationPlaceholder?: string;
  confirmationText: string;
  cancelText?: string;
  callback?: any;
  confirmationFieldText?: { fr: string, en: string; };
}

const DELETE = 'delete';
const SUPPRIMER = 'supprimer';

@Component({
  selector: 'ui-justification-confirmation-modal',
  templateUrl: './justification-confirmation-modal.component.html',
  styleUrls: [ './justification-confirmation-modal.component.scss' ],
})
export class JustificationConfirmationModalComponent implements OnInit {
  @Input() public confirmationData: IJustificationConfirmationData;
  @Input() public needsJustification = true;
  @Input() public forceInvalid = false;
  @Input() closeOnCompletion = true;

  public formGroup: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IJustificationConfirmationData,
    public modalService: ModalService,
    public formBuilder: UntypedFormBuilder
  ) {
    if (!this.confirmationData) {
      this.confirmationData = data;
      this.needsJustification = this.confirmationData.hasOwnProperty('needsJustification')
        ? this.confirmationData?.needsJustification
        : this.needsJustification;
    }
  }

  public get paragraphLines(): string[] {
    return this.confirmationData.text.split('\n');
  }

  public get isConfirmationtextValid(): boolean {
    const regex = this.confirmationData.confirmationFieldText[this.confirmationData.currentLang];
    return new RegExp(`^${regex}$`, 'i').test(this.formGroup.get('confirmationText').value);
  }

  ngOnInit(): void {
    this.initForm();
  }

  public initForm(): void {
    this.formGroup = this.formBuilder.group({
      justification: [ null, this.needsJustification ? Validators.required : null ],
      confirmationText: [ null ],
    });
  }

  public closeModal(value: boolean): void {
    if (this.confirmationData.callback) {
      this.confirmationData.callback({
        value,
        justification: this.formGroup.get('justification').value,
      });
    }
    if (this.closeOnCompletion) {
      this.modalService.closeDialog();
    }
  }
}
