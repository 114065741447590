import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Accumulate, Accumulatables } from "@common/modules/accumulator/accumulator.store";
import { I18nService } from "@common/modules/i18n/i18n.service";
import { DrawerService } from "@common/modules/layout/components/drawer/services/drawer.service";
import { CookDeliciousSnack, CookInedibleSnack } from "@common/modules/notice/implementations/snackbar/state/snackbar.state";
import { FormCreationService } from "@common/modules/shared/form-creation.service";
import { DatasourcesApiService } from "@common/services/api/respond/datasources/datasources.api";
import { DatasourceTemplateUI, DatasourceImplementations, ConfigurationBaseTemplate, DatasourceConfigurationParameterTypes } from "@common/services/api/respond/datasources/datasources.definitions";
import { JsonUtils } from "@common/utils/json-utils";
import { CustomValidators } from "@common/utils/validators";
import { Store } from "@ngxs/store";
import { DatasourcesTemplates } from "../../models/datasourceTemplates";

export class DatasourceTemplateConfig {
  public formGroup: UntypedFormGroup;

  public dropdownMap = new Map<string, any>();

  public arrayAndObjKeyList: Array<string> = [];

  public implementation: UntypedFormGroup = new UntypedFormGroup({ type: new UntypedFormControl('') });

  public organization: UntypedFormGroup = new UntypedFormGroup({ organization: new UntypedFormControl('') });

  public activate: UntypedFormGroup = new UntypedFormGroup({ activate: new UntypedFormControl('') });

  public organizationId: string;

  private currentTemplateUsed: DatasourceTemplateUI;

  protected templates: DatasourcesTemplates;

  constructor(
    readonly i18n: I18nService,
    readonly formBuilder: UntypedFormBuilder,
    readonly formCreationService: FormCreationService,
    readonly datasourcesApiService: DatasourcesApiService,
    readonly drawerService: DrawerService,
    readonly store: Store
  ) {
    this.initFormGroup();
  }

  public set datasourcesTemplateAndOrgList(data: any) {
    this.organization.get('organization').setValue(data.organizationSelected.name);
    this.organizationId = data.organizationSelected.id;
    this.templates = data.templates;
  }

  public get templateImplementationDropdown(): string[] {
    return this.templates.getTemplateType();
  }

  public get templateName(): string {
    return this.currentTemplateUsed.name;
  }

  public get templateDescription(): string {
    return this.currentTemplateUsed.description[this.i18n.currentLocale];
  }

  public get isCurrentTemplateAvailable(): boolean {
    return !!this.currentTemplateUsed;
  }

  public get isFormValid(): boolean {
    return this.formGroup.valid && Object.keys(this.formGroup.value).length !== 0;
  }

  public buildTemplate(event: DatasourceImplementations): void {
    this.resetCurrentFormToBuild();
    this.setCurrentTemplate(event);
    this.createForms();
  }

  public setListValue(key: string, value: string): void {
    this.dropdownMap.set(key, value);
  }

  public createForms(): void {
    const config = this.currentTemplateUsed.configurationTemplate;

    for (const key in config) {
      if (key) {
        this.addControl(key, config[key]);
      }
    }
  }

  public resetCurrentFormToBuild(): void {
    this.dropdownMap.clear();
    this.arrayAndObjKeyList = [];
    this.initFormGroup();
    this.formCreationService.resetCurrentForms();
    this.currentTemplateUsed = null;
  }

  public save(): void {
    const baseObject = this.formGroup.value;
    this.dropdownMap.forEach((value, key) => (baseObject[key] = value));

    this.arrayAndObjKeyList.forEach((key) => {
      if (this.formGroup.get(key).value) {
        const valueToSave = JSON.parse(this.formGroup.get(key).value);
        baseObject[key] = valueToSave;
      }
    });
    const dataToSave = Object.fromEntries(Object.entries(baseObject).filter(([ _, value ]) => value != null));

    if (dataToSave.replication) {
      dataToSave.replication = +dataToSave.replication;
    }

    this.datasourcesApiService
      .postDatasource(
        {
          implementation: this.currentTemplateUsed.implementation,
          config: dataToSave,
          deploy: this.activate.get('activate').value,
        },
        this.organizationId
      )
      .then(() => {
        this.store.dispatch(new CookDeliciousSnack('datasources.tab.creation.configuration.template.success'));
        this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.LIST_REFRESHER }));
        this.drawerService.previousDrawer();
      })
      .catch((error) => {
        const message = Array.isArray(error.response.data.message)
          ? `${error.response.data.error}: ${error.response.data.message[0]}`
          : error.response.data.message;
        this.store.dispatch(new CookInedibleSnack(message));
      });
  }

  public cancel(): void {
    this.resetCurrentFormToBuild();
    this.drawerService.previousDrawer();
  }

  public setDropDownValue(value: string, controlName: string): void {
    this.formGroup.get(controlName).setValue(value);
  }

  public validateAndPrettify(formControlName: string): void {
    const control = this.formGroup.get(formControlName) as AbstractControl;
    if (control.valid) {
      control.setValue(JsonUtils.prettifyJson(control.value));
    }
  }

  public getFormError(formControlName: string): { invalid: boolean; } {
    const control = this.formGroup.get(formControlName);

    return {
      invalid: control.invalid && control.touched,
    };
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({});
    this.formCreationService.currentformGroup = this.formGroup;
  }

  private addControl(key: string, config: ConfigurationBaseTemplate): void {
    this.formGroup.addControl(key, new UntypedFormControl(''));
    this.addValidations(this.formGroup.get(key), config);
    this.setDefault(key, config);
    this.formCreationService.generateForm(key, config);

    if (
      config.type === DatasourceConfigurationParameterTypes.OBJECT ||
      config.type === DatasourceConfigurationParameterTypes.STRING_LIST
    ) {
      this.arrayAndObjKeyList.push(key);
    }
  }

  private addValidations(control: AbstractControl, config: ConfigurationBaseTemplate): void {
    if (config.required) {
      control.setValidators(Validators.required);
    }

    const customValidation = this.getValidationByType(config.type);
    if (control.validator) {
      control.setValidators([ customValidation, control.validator ]);
    } else {
      control.setValidators([ customValidation ]);
    }
    control.updateValueAndValidity();
  }

  private getValidationByType(type: DatasourceConfigurationParameterTypes): any {
    switch (type) {
      case DatasourceConfigurationParameterTypes.NUMBER:
        return CustomValidators.numberValidator;
      case DatasourceConfigurationParameterTypes.STRING_LIST:
        return CustomValidators.isArrayOfString;
      case DatasourceConfigurationParameterTypes.OBJECT:
        return CustomValidators.isValidJson;
      default:
        return CustomValidators.defaultValidator;
    }
  }

  private setDefault(key: string, config: ConfigurationBaseTemplate): void {
    if (config.default && (Array.isArray(config.default) || typeof config?.default === 'object')) {
      this.setArrayAndObjectDefault(config, key);
    } else if (config.default && config.values) {
      this.setListDefault(config, key);
    } else if (config.default) {
      this.formGroup.get(key).setValue(config.default);
    } else if (config.type === DatasourceConfigurationParameterTypes.BOOLEAN && !config.default) {
      this.formGroup.get(key).setValue(false);
    }
  }

  private setArrayAndObjectDefault(config: ConfigurationBaseTemplate, key: string): void {
    this.formGroup.get(key).setValue(JsonUtils.prettifyJson(JSON.stringify(config.default)));
  }

  private setListDefault(config: ConfigurationBaseTemplate, key: string): void {
    const defaultOption = config.values.find((option) => option.value === config.default);
    this.formGroup.get(key).setValue(defaultOption.label[this.i18n.currentLocale]);
    this.setListValue(key, defaultOption.value);
  }

  private setCurrentTemplate(event: DatasourceImplementations): void {
    this.currentTemplateUsed = this.templates.getTemplateByType(event);
  }
}
