import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { DisplayRequirements, DisplayService } from '../display/display.service';
import { SessionService } from '../session/session.service';

// Can be used in a route as such in order to block a user from loading the route
// {
// path: 'organizations',
// loadChildren: () => import('./iam/organizations/adm.organizations.module').then(m => m.AdmOrganizationModule),
// canLoad: [DisplayGuard],
// data: {
//     requirements: {
//         flags: ["NAME_OF_THE_FEAtURE_FLAG"],
//         service: ["respond"]
//     }
// }
// }

@Injectable({
  providedIn: 'root',
})
export class DisplayGuard implements CanLoad {
  constructor(private service: DisplayService, private router: Router, private sessionsService: SessionService) { }

  canLoad(route: Route, _): boolean {
    const requirements: DisplayRequirements = route?.data?.requirements;
    this.sessionsService.loadBrowserSessionIfNeeded();

    if (requirements) {
      const decision = this.service.meetsRequirements(requirements);

      if (decision) {
        return true;
      }
    }


    this.router.navigate([ '/' ]);

    return false;
  }
}
