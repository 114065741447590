import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { LanguageEnum } from '../../interfaces/ILanguage';
import { IMobileService } from '../../interfaces/IMobileService';

export enum DynamicQueryTimeRangeEnum {
  LAST_24_HOURS = 'last24Hour',
  LAST_72_HOURS = 'last72Hour',
  LAST_7_DAYS = 'last7Days',
  LAST_30_DAYS = 'last30Days',
  LAST_90_DAYS = 'last90Days',
  CURRENT_WEEK = 'currentWeek',
  PREVIOUS_WEEK = 'previousWeek',
  LAST_4_WEEKS = 'last4Weeks',
  CURRENT_MONTH = 'currentMonth',
  PREVIOUS_MONTH = 'previousMonth',
  LAST_52_WEEKS = 'last52Weeks'
}

export enum DefaultTimeRangeEnum {
  LAST_24_HOURS = DynamicQueryTimeRangeEnum.LAST_24_HOURS,
  LAST_72_HOURS = DynamicQueryTimeRangeEnum.LAST_72_HOURS,
  LAST_7_DAYS = DynamicQueryTimeRangeEnum.LAST_7_DAYS
}

@Component({
  selector: 'widget-container',
  templateUrl: './widget-container.component.html',
  styleUrls: [ './widget-container.component.scss' ],
})
export class WidgetContainer {
  @Input() title;

  @Input() subtitle;

  @Input() withIcon = false;

  @Input() iconName = '';

  @Input() withTitleTooltip = false;

  @Input() withMobileTitleTooltip = false;

  @Input() withTimeRange = false;

  @Input() timeRanges = DefaultTimeRangeEnum;

  @Input() timeRangesDefault = DefaultTimeRangeEnum.LAST_7_DAYS;

  @Input() withBottomMargin = false;

  @Input() withPadding = false;

  @Input() locale: LanguageEnum;

  @Output() selectedTimerange = new EventEmitter<DynamicQueryTimeRangeEnum>();

  constructor(@Inject("MobileService") public readonly mobileService: IMobileService) {}
}
