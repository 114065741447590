<div class="toolbar-header">
  <div class="left-side-container">
    <ng-content select="[leftSide]"></ng-content>
  </div>

  <div class="right-side">
    <ui-table-total-tool
      *ngIf="total != null"
      [total]="total"
    ></ui-table-total-tool>
    <ng-content></ng-content>

    <div
      class="filter-toggle"
      (click)="toggleFilters()"
      [ngClass]="{ 'filter-toggle--expanded': areFiltersExpanded }"
    >
      <div class="filter-toggle--button-elements">
        <div class="filter-toggle--button-elements--name">
          <img src="assets/favicons/icon_filter.svg" />
          {{ 'toolbar.filters.collapsable.button.name' | t: {locale: locale} }}
        </div>

        <ui-badge
          [value]="activatedFiltersNumber"
          [isHidden]="activatedFiltersNumber < 1"
        ></ui-badge>

        <img [src]="'assets/favicons/icon_chevron_' +(areFiltersExpanded ? 'up' : 'open') + '.svg'" />
      </div>
    </div>
  </div>
</div>

<div
  class="filters-container"
  [ngClass]="
    areFiltersExpanded
      ? 'filters-container--expanded'
      : 'filters-container--collapsed'"
>
  <div
    class="expandable-box"
    [ngStyle]="areFiltersExpanded ? {overflow: 'unset'} : {overflow: 'hidden'}"
  >
    <section class="expandable-box--header">
      <img src="assets/favicons/icon_filter.svg" />
      {{ 'toolbar.filters.collapsable.button.name' | t: {locale: locale} }}
      <ui-anchor
        [label]="'toolbar.filters.eraseAll.button.name' | t: {locale: locale}"
        *ngIf="showEraseAll"
        (onClick)="onClearAllFilters.emit()"
      ></ui-anchor>
    </section>

    <section class="expandable-box--filters">
      <div *ngFor="let filterInsideCollapsableArea of filtersInsideCollapsableArea">
        <div class="flex-box space-between">
          <label
            ui-label
            *ngIf="filterInsideCollapsableArea.elementTitle"
          >
            {{ filterInsideCollapsableArea.elementTitle }}
          </label>
          <div
            class="filter-checkbox-container"
            *ngIf="filterInsideCollapsableArea.showCheckbox"
            [tooltip]="filterInsideCollapsableArea.checkboxTooltip || 'toolbar.filters.collapsable.checkbox.exclude.tooltip'|t: {locale: locale}"
          >
            <label ui-label>
              {{ filterInsideCollapsableArea.checkboxLabel || 'toolbar.filters.collapsable.checkbox.exclude'|t: {locale: locale}
              }}
            </label>
            <mat-checkbox
              style="padding-bottom: 2px;"
              [(ngModel)]="filterInsideCollapsableArea.checkboxValue"
              (change)="filterCheckboxChanged($event.checked, filterInsideCollapsableArea)"
            >
            </mat-checkbox>
          </div>
        </div>
        <ng-template [ngTemplateOutlet]="filterInsideCollapsableArea.template"></ng-template>
      </div>
    </section>
  </div>
</div>