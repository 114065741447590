import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import { LanguageEnum } from '../../interfaces/ILanguage';

@Component({
  selector: 'ui-carousel',
  templateUrl: './ui-carousel.component.html',
  styleUrls: ['./ui-carousel.component.scss'],
})
export class UiCarouselComponent implements OnInit {
  @Input() locale: LanguageEnum;
  @Input() learnMoreFinish = false;

  @Output() closeDialogEmitter: EventEmitter<void> = new EventEmitter();

  @ContentChildren('slide') slides: QueryList<'slide'>;

  public numberOfSlides = 1;

  public currentSlideIndex = 0;

  private sliderContainer: HTMLElement;

  constructor() {
  }

  public get canDisplayPreviousButton(): boolean {
    return this.currentSlideIndex > 0;
  }

  public get canDisplayNextButton(): boolean {
    return this.currentSlideIndex < this.numberOfSlides - 1;
  }

  public get canDisplayFinishButton(): boolean {
    return !this.canDisplayNextButton;
  }

  public get pagination(): string {
    return `${this.currentSlideIndex + 1} / ${this.numberOfSlides}`;
  }

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.numberOfSlides = this.slides.length;
    this.setStyle();
    this.onSlideChange();
  }

  public goToPrevious(): void {
    this.currentSlideIndex -= 1;
    this.onSlideChange();
  }

  public goToNext(): void {
    this.currentSlideIndex += 1;
    this.onSlideChange();
  }

  public closeDialog(): void {
    this.closeDialogEmitter.emit();
  }

  private setStyle(): void {
    this.sliderContainer = document.getElementById('slides-container');
    this.sliderContainer.style.width = `${this.numberOfSlides}00%`;
  }

  private onSlideChange(): void {
    const translation = (100 / this.numberOfSlides) * this.currentSlideIndex;
    this.sliderContainer.style.transform = `translateX(-${translation}%)`;
  }
}
