import { EcoHttpClient } from '@md.eco/eco-http';
import { ExpositionLevel } from 'projects/console-adm/src/app/iam/organizations/components/feature-exposition-level/feature-exposition-level.component';

export namespace Eco {
  export enum Languages {
    FR = 'fr',
    EN = 'en'
  }

  export interface IOrganization {
    id: string;
    managerOrganizationId?: string;
    managerRoleId?: string;
    name: string;
    tags?: string[];
    services?: string[];
    onboardingAdmins?: string[];
    preferredLang?: Eco.Languages;
    expositionLevel?: ExpositionLevel
  }

  export interface ISessionState {
    accessKey?: string;
    secretKey?: string;
    expiresAt?: number;
    organization?: IOrganization;
    client?: EcoHttpClient;
    microsoft?: {
      accessToken?: string;
      idToken?: string;
    };
    user?: any;
    notificationEmail?: string;
    loginMatrix?: { [key: string]: { [key: string]: string } };
    featureFlags?: { [key:string]: boolean };
    services?: { [key:string]: boolean };
    varMode?: boolean;
  }

  export interface IHmacKeys {
    accessKey: string;
    secretKey: string;
  }

  export interface IRequestHeaders {
    Authorization?: string;
    'eco-nonce'?: string;
    'eco-expires'?: string;
  }

  export interface LoginResponse {
    secretKey: string;
    accessKey: string;
    expiresAt: number;
    identityId: string;
    idToken: string;
    accessToken: string;
    organization: {
      id: string;
      managerOrganizationId?: string;
      name: string;
      tags: string[];
    };
    services: string[];
  }

  export type List<T> = { items: T[]; nextItem: number; total: number; };

  export interface IFetchResult<I> {
    total: number;
    items: I[];
    nextToken: string ;
    nextItem: number;
  }

  export interface PermissionsMapping {
    // Key is the element identifier and is arbitrary
    // is used to identify a UI element like a button or a page
    [ids: string]: {
      // Action is the md.eco action as it is defined in the service apis and in the IAM policies
      action: string;

      // ResourceLabel is the id of the resource(s) associated with the element suffixed by either /any or /all
      // This field can be left blank it if does not apply
      resourceLabel?: string;
    };
  }
}
