import { Component, OnDestroy, OnInit } from "@angular/core";
import { AccumulatorsState, Accumulator, Accumulatables } from "@common/modules/accumulator/accumulator.store";
import { StaticTableDataMapper } from "@common/modules/i18n/component-wrapper/static-table-data-mapper.service";
import { I18nService } from "@common/modules/i18n/i18n.service";
import { DrawerPageEnum } from "@common/modules/layout/components/drawer/drawerPage.enum";
import { DrawerService } from "@common/modules/layout/components/drawer/services/drawer.service";
import { NoticeService, Notice, NoticeLevels } from "@common/modules/notice/notice.service";
import { DatasourcesApiService } from "@common/services/api/respond/datasources/datasources.api";
import { DatasourceStatus, DatasourceUiRepresentation } from "@common/services/api/respond/datasources/datasources.definitions";
import { Select } from "@ngxs/store";
import { DialogActions } from "@ui-kit/components/ui-dialog/ui-dialog.component";
import { UiStaticTableRowType } from "@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component";
import { TableFilterData } from "@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data";
import { SearchMode } from "projects/@common/modules/layout/components/page/page.component";
import { AutomationOrganizationsService } from "projects/@common/services/api/respond/automation-organization/automation-organizations.api";
import { Observable, Subscription } from "rxjs";

import { DatasourceInfoDetailsComponent } from "../components/datasource-info-details/datasource-info-details.component";
import { DatasourcesTemplates } from "../models/datasourceTemplates";
import { DatasourcesDrawerContainer } from "./drawer/datasources.drawer.component";

export interface DatasourceListRepresentation {
  id: string;
  type: string;
  updatedAt: number;
  createdAt: number;
  organizationId: string;
  implementation: string;
  config: any;
  status: DatasourceStatus;
  dataTypes: string;
}

@Component({
  selector: 'app-datasources.container',
  templateUrl: './datasources.container.html',
  styleUrls: [ './datasources.container.scss' ],
})
export class DatasourcesContainer implements OnInit, OnDestroy {
  public total = 0;
  public isTemplateLoading = false;
  public isOrganizationLoading = false;
  public isDatasourceLoading = false;
  public isDeletingDatasourceLoading = false;
  public isActivatingDatasourceLoading = false;
  public organizationList: Record<string, string> = {};
  public currentOrganizationName: string;
  public organizationGuid: string;
  public tableFilterData: TableFilterData;
  public datasource: Array<DatasourceListRepresentation>;
  public datasourceTemplates: DatasourcesTemplates;
  public currentDatasourceSelected: any;
  public showDeleteDialog = false;
  public showActivateDialog = false;
  public deleteDatasourceMessage: string;
  public activateDatasourceMessage: string;
  public searchMode = SearchMode.RESPOND;

  @Select(AccumulatorsState.accumulators) private accumulators$: Observable<Accumulator[]>;
  private selectedTag = '__Selected';
  public USECASES_COLUMN_DEF = [
    {
      label: '',
      field: 'selected',
      selected: this.selectedTag,
      type: UiStaticTableRowType.CHECKBOX,
      width: '50px',
      isResizable: false,
    },
    {
      label: 'datasources.table.column.type',
      field: 'dataTypes',
      type: UiStaticTableRowType.TEXT,
      width: '25%',
    },
    {
      label: 'datasources.table.column.implementation',
      field: 'implementation',
      type: UiStaticTableRowType.TEXT,
      width: '25%',
    },
    {
      label: 'datasources.table.column.status',
      field: 'status',
      type: UiStaticTableRowType.ENUM_WITH_ICON,
      enumPrefix: 'datasources.table.column.status.',
      iconName: this.getIsActivatedIcon,
      customIcon: true,
      isSvg: true,
      iconSide: 'left',
      width: '200px',
    },
    {
      label: 'org_usecase.table.column.activated_at',
      field: 'createdAt',
      type: UiStaticTableRowType.DATE,
      width: '200px',
    },
    {
      label: 'org_usecase.table.column.updated_at',
      field: 'updatedAt',
      type: UiStaticTableRowType.DATE,
      width: '200px',
    },
  ];
  private accumulatorSubscription: Subscription;

  private currentAccumulatorCount: number;

  constructor(
    public readonly i18n: I18nService,
    private readonly datasourcesService: DatasourcesApiService,
    private readonly drawerService: DrawerService,
    private readonly noticeService: NoticeService,
    private staticTableDataFactory: StaticTableDataMapper,
    public automationOrganizationsService: AutomationOrganizationsService
  ) {
  }

  ngOnInit(): void {
    this.getDatasourceTemplate();
    this.initAccumulatorSubscription();

    [ this.USECASES_COLUMN_DEF ] = this.staticTableDataFactory.translate(this.USECASES_COLUMN_DEF, []);
  }

  ngOnDestroy(): void {
    this.accumulatorSubscription?.unsubscribe();
  }

  public async organizationChanged(org): Promise<void> {
    if (org) {
      await this.automationOrganizationsService.describeOrganization(org.id).then((respondOrg) => {
        this.currentOrganizationName = respondOrg.name;
        this.organizationGuid = respondOrg.id;
      });
      this.handleRefresh();
    } else {
      this.currentOrganizationName = null;
      this.organizationGuid = null;
    }
  }

  public createDatasource(): void {
    this.drawerService.replaceCurrentDrawer(DatasourcesDrawerContainer, {
      component: DrawerPageEnum.datasourcesDrawer,
      data: {
        title: this.i18n.translate('datasources.tab.creation'),
        templates: this.datasourceTemplates,
        organizationSelected: { name: this.currentOrganizationName, id: this.organizationGuid },
      },
    });
  }

  public handleRowClick(datasource: DatasourceUiRepresentation): void {
    this.drawerService.replaceCurrentDrawer(DatasourceInfoDetailsComponent, {
      component: DrawerPageEnum.descriptionUsecaseDrawer,
      data: {
        title: `${datasource.type} datasource`,
        datasource,
        currentOrganization: this.organizationGuid,
      },
    });
  }

  public handleRefresh(): void {
    this.loadDatasourceByOrganization(this.organizationGuid);
  }

  public handleSelected(selected: any): void {
    if (selected.event) {
      this.currentDatasourceSelected = selected.item;
    } else {
      this.currentDatasourceSelected = null;
    }
  }

  public openDeleteDialog(): void {
    this.deleteDatasourceMessage = this.i18n.translate('datasources.table.action.delete.dialog.message', {
      id: this.currentDatasourceSelected.id,
    });
    this.showActivateDialog = false;
    this.showDeleteDialog = true;
  }

  public openActivateDialog(): void {
    this.activateDatasourceMessage = this.i18n.translate('datasources.table.action.activate.dialog.message', {
      id: this.currentDatasourceSelected.id,
    });
    this.showDeleteDialog = false;
    this.showActivateDialog = true;
  }

  public deleteDatasource(event: DialogActions): void {
    if (event === DialogActions.ACTION) {
      this.isDeletingDatasourceLoading = true;
      this.datasourcesService
        .deleteDatasource(this.organizationGuid, this.currentDatasourceSelected.id)
        .then(() => {
          this.noticeService.notifyUser(new Notice(`datasources.success.delete.message`, NoticeLevels.SUCCESS));
        })
        .catch((error) => {
          const message = Array.isArray(error.response.data.message)
            ? `${error.response.data.error}: ${error.response.data.message[0]}`
            : error.response.data.message;
          this.noticeService.notifyUser(new Notice(message, NoticeLevels.ERROR));
        })
        .finally(() => {
          this.isDeletingDatasourceLoading = false;
          this.drawerService.hideDrawer();
          this.showDeleteDialog = false;
          this.handleRefresh();
        });
    } else {
      this.showDeleteDialog = false;
    }
  }

  public activateDatasource(event: DialogActions): void {
    if (event === DialogActions.ACTION) {
      this.isActivatingDatasourceLoading = true;
      this.datasourcesService
        .activateDatasource(this.organizationGuid, this.currentDatasourceSelected.id)
        .then(() => {
          this.noticeService.notifyUser(new Notice(`datasources.success.activate.message`, NoticeLevels.SUCCESS));
        })
        .catch((error) => {
          const message = Array.isArray(error.response.data.message)
            ? `${error.response.data.error}: ${error.response.data.message[0]}`
            : error.response.data.message;
          this.noticeService.notifyUser(new Notice(message, NoticeLevels.ERROR));
        })
        .finally(() => {
          this.isActivatingDatasourceLoading = false;
          this.drawerService.hideDrawer();
          this.showActivateDialog = false;
          this.handleRefresh();
        });
    } else {
      this.showActivateDialog = false;
    }
  }

  public isSelectedDatasourceActivatable(): boolean {
    return this.currentDatasourceSelected?.status === DatasourceStatus.PENDING;
  }

  public getIsActivatedIcon(datasource: DatasourceListRepresentation | DatasourceUiRepresentation): string {
    return datasource.status === DatasourceStatus.DEPLOYED ? 'favicons/icon_status_on' : 'favicons/icon_status_off';
  }

  private initAccumulatorSubscription(): void {
    this.accumulatorSubscription = this.accumulators$.subscribe((accumulators) => {
      const count = accumulators
        .filter((accumulator) => accumulator.accumulatable === Accumulatables.LIST_REFRESHER)
        .shift()?.accumulator;
      if (count !== this.currentAccumulatorCount && this.organizationGuid) {
        this.handleRefresh();
        this.currentAccumulatorCount = count;
      }
    });
  }

  private loadDatasourceByOrganization(organizationId: string): void {
    this.isDatasourceLoading = true;
    this.datasource = [];

    this.datasourcesService
      .getDatasources(organizationId)
      .then((datasourceList) => {
        this.total = datasourceList.total;
        this.datasource = datasourceList.items.map((datasource: DatasourceUiRepresentation): DatasourceListRepresentation => ({
          ...datasource,
          dataTypes: datasource.dataTypes?.join(', '),
        }));
        [ this.USECASES_COLUMN_DEF, this.datasource ] = this.staticTableDataFactory.translate(this.USECASES_COLUMN_DEF, this.datasource);
      })
      .finally(() => (this.isDatasourceLoading = false));
  }

  private getDatasourceTemplate(): void {
    this.datasourcesService
      .getDatasourcesCatalog()
      .then((res) => {
        this.isTemplateLoading = true;
        this.datasourceTemplates = new DatasourcesTemplates(res.items.map((item) => item.template));
      })
      .finally(() => (this.isTemplateLoading = false));
  }
}
