import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { Stages } from "@common/definitions/stages";
import { environment } from "@common/environments/environment";
import { TestSignInComponent } from "@common/modules/auth/components/testSignin/test-sign-in.component";
import { AuthnRedirectComponent } from "projects/@common/modules/auth/components/redirect/authn-redirect.component";
import { AuthnSignInComponent } from "projects/@common/modules/auth/components/signin/sign-in.component";
import { SignupComponent } from "projects/@common/modules/auth/components/signup/sign-up.component";
import { DisplayGuard } from 'projects/@common/modules/guards/display.guard';
import { ImagesBankComponent } from "projects/@common/modules/layout/components/images-bank/images-bank.component";
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';
import { PrivateLayout } from "projects/@common/modules/layout/private.layout";
import { AuthGuard } from "projects/@common/modules/session/auth.guard";

const routes: Routes = [
  {
    path: 'IMAGES',
    component: ImagesBankComponent,
  },
  {
    path: 'signin',
    component: AuthnSignInComponent,
  },
  {
    path: 'test',
    component: TestSignInComponent,
    canActivate: [() => environment.stage !== Stages.PROD]
  },
  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'login/office',
    component: AuthnRedirectComponent,
  },
  {
    path: '',
    component: PrivateLayout,
    canActivate: [ AuthGuard ],
    children: [
      {
        path: 'organizations',
        loadChildren: () => import('./iam/organizations/adm.organizations.module').then((m) => m.AdmOrganizationModule),
      },
      {
        path: 'respond/dashboard',
        loadChildren: () => import('./respond/dashboard/adm.respond-dashboard.module').then((m) => m.AdmRespondDashboardModule),
      },
      {
        path: 'respond/kpi',
        loadChildren: () => import('./respond/kpi/kpi.module').then((m) => m.KpiModule),
      },
      {
        path: 'respond/:organizationEcoId/dashboard',
        loadChildren: () => import('./respond/dashboard/adm.respond-dashboard.module').then((m) => m.AdmRespondDashboardModule),
      },
      {
        path: 'respond/reports',
        loadChildren: () => import('./respond/periodic-reports/adm.reports.module').then((m) => m.AdminReportsModule),
      },
      {
        path: 'respond/:organizationEcoId/reports',
        loadChildren: () => import('./respond/periodic-reports/adm.reports.module').then((m) => m.AdminReportsModule),
      },
      {
        path: 'respond/usecases',
        loadChildren: () => import('./respond/usecases/usecases-adm.module').then((m) => m.UsecasesAdmModule),
      },
      {
        path: 'respond/:organizationEcoId/usecases',
        loadChildren: () => import('./respond/usecases/usecases-adm.module').then((m) => m.UsecasesAdmModule),
      },
      {
        path: 'respond/datasources',
        loadChildren: () => import('./respond/datasources/datasources.module').then((m) => m.DatasourcesModule),
      },
      {
        path: 'respond/:organizationEcoId/datasources',
        loadChildren: () => import('./respond/datasources/datasources.module').then((m) => m.DatasourcesModule),
      },
      {
        path: 'respond/organizations',
        loadChildren: () => import('./respond/organizations/organizations.module').then((m) => m.OrganizationsModule),
      },
      {
        path: 'respond/playbooks',
        loadChildren: () => import('./respond/playbooks/playbooks.module').then((m) => m.PlaybooksModule),

      },
      {
        path: 'respond/:organizationEcoId/playbooks',
        loadChildren: () => import('./respond/playbooks/playbooks.module').then((m) => m.PlaybooksModule),
      },
      {
        path: 'respond/alerts',
        loadChildren: () => import('./respond/alerts/alerts.module').then((m) => m.AlertsModule),
      },
      {
        path: 'respond/incidents',
        loadChildren: () => import('./respond/incidents/incidents.module').then((m) => m.IncidentsModule),
      },
      {
        path: 'respond/escalation',
        loadChildren: () => import('./respond/communication/adm-communication.module').then((m) => m.AdmCommunicationModule),
      },
      {
        path: 'respond/:organizationEcoId/escalation',
        loadChildren: () => import('./respond/communication/adm-communication.module').then((m) => m.AdmCommunicationModule),
      },
      {
        path: 'respond/:organizationEcoId/incidents',
        loadChildren: () => import('./respond/incidents/incidents.module').then((m) => m.IncidentsModule),
      },
      {
        path: 'respond/assets',
        loadChildren: () => import('./respond/actifs/adm-actifs.module').then((m) => m.AdmActifsModule),
      },
      {
        path: 'respond/:organizationEcoId/assets',
        loadChildren: () => import('./respond/actifs/adm-actifs.module').then((m) => m.AdmActifsModule),
      },
      {
        path: 'respond/allowlists',
        loadChildren: () => import('./respond/whitelists/adm-whitelists.module').then((m) => m.AdmWhitelistsModule),
      },
      {
        path: 'respond/filters',
        loadChildren: () => import('./respond/filters/adm-filters.module').then((m) => m.AdmFiltersModule),
      },
      {
        path: 'respond/:organizationEcoId/allowlists',
        loadChildren: () => import('./respond/whitelists/adm-whitelists.module').then((m) => m.AdmWhitelistsModule),
      },
      {
        path: 'respond/:organizationEcoId/filters',
        loadChildren: () => import('./respond/filters/adm-filters.module').then((m) => m.AdmFiltersModule),
      },
      {
        path: 'respond/usecases',
        loadChildren: () => import('./respond/usecases/usecases-adm.module').then((m) => m.UsecasesAdmModule),
      },
      {
        path: 'tools/parameters',
        loadChildren: () => import('./tools/parameter-store/parameter-store.module').then((m) => m.ParameterStoreModule),
      },
      {
        path: 'tools/:organizationEcoId/parameters',
        loadChildren: () => import('./tools/parameter-store/parameter-store.module').then((m) => m.ParameterStoreModule),
      },
      {
        path: 'tools/secrets',
        loadChildren: () => import('./tools/secret-store/secret-store.module').then((m) => m.SecretStoreModule),
      },
      {
        path: 'tools/:organizationEcoId/secrets',
        loadChildren: () => import('./tools/secret-store/secret-store.module').then((m) => m.SecretStoreModule),
      },
      {
        path: 'detect/connectors',
        loadChildren: () => import('./detect/connectors/connectors.module').then((m) => m.ConnectorsModule),
      },
      {
        path: 'detect/:organizationEcoId/connectors',
        loadChildren: () => import('./detect/connectors/connectors.module').then((m) => m.ConnectorsModule),
      },
      {
        path: 'detect/hosts',
        loadChildren: () => import('./detect/hosts/hosts.module').then((m) => m.HostsModule),
      },
      {
        path: 'detect/:organizationEcoId/hosts',
        loadChildren: () => import('./detect/hosts/hosts.module').then((m) => m.HostsModule),
      },
      {
        path: "respond/histories",
        loadChildren: () => import('./respond/histories/adm.histories.module').then((m) => m.AdmHistoriesModule),
      },
      {
        path: "respond/:organizationEcoId/histories",
        loadChildren: () => import('./respond/histories/adm.histories.module').then((m) => m.AdmHistoriesModule),
      },
      {
        path: "respond/queries",
        loadChildren: () => import('./respond/queries/adm.queries.module').then((m) => m.AdmQueriesModule),
      },
      {
        path: "respond/:organizationEcoId/queries",
        loadChildren: () => import('./respond/queries/adm.queries.module').then((m) => m.AdmQueriesModule),
      },
      {
        path: "air",
        loadChildren: () => import('./ai-resolution/ai-resolution.module').then((m) => m.AiResolutionModule),
        canLoad: [ DisplayGuard ],
        data: {
          requirements: {
            flags: [ FeaturesEnum.AIR_MANAGEMENT ],
          },
        },
      },
      {
        path: "sg/launchOperation",
        loadChildren: () => import('./saine-gestion/operation-launching/operation-launching.module').then((m) => m.OperationLaunchingModule),
      }
    ],
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
})
export class AdmAppRoutingModule {
}
