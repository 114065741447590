import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { IRespondResponse } from '../../../../definitions/IRespondResponse';
import { EcoUrlService } from '../../core/url.service';
import { DatasourceUiRepresentation, IDatasourceFamily } from './datasources.definitions';

@Injectable({
  providedIn: 'root',
})
export abstract class DatasourcesApiService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.automation;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public abstract getDatasourcesCatalog(): Promise<IRespondResponse<any>>;

  public abstract getDatasources(organizationId: string): Promise<IRespondResponse<any>>;

  public abstract describeDatasource(organizationId: string, datasourceId: string): Promise<DatasourceUiRepresentation>;

  public abstract postDatasource(datasource: any, organizationId: string): Promise<IRespondResponse<any>>;

  public abstract deleteDatasource(organizationId: string, datasourceId: string): Promise<IRespondResponse<any>>;

  public abstract activateDatasource(organizationId: string, datasourceId: string): Promise<IRespondResponse<any>>;

  public abstract getDatasourcesFamily(organizationId: string): Promise<IDatasourceFamily[]>;
}
