import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-user-tag',
  templateUrl: './ui-user-tag.component.html',
  styleUrls: [ './ui-user-tag.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'user-tag',
  },
})
export class UserTagComponent implements OnInit, OnChanges {
  @Input() public user: any;

  @Input() public readOnly = false;

  @Input() public userDisplayName = '';

  @Input() public keyNameToDisplay = 'displayName';

  @Input() public showAvatar = true;

  @Output() public onDeleteUser: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  public get name(): string {
    let fullName = null;
    if (this.user.firstName) {
      fullName = this.user.firstName;

      if (this.user.lastName) {
        fullName = `${this.user.firstName} ${this.user.lastName}`;
      }
    }

    return this.user.name ? this.user.name : fullName || this.user?.[this.keyNameToDisplay] || '-';
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(): void {
    this.init();
  }

  public deleteUser() {
    this.onDeleteUser.emit(this.user);
  }

  private init() {
    if (!this.userDisplayName && this.user) {
      this.userDisplayName = `${this.user.firstName} ${this.user.lastName}`;
    }
  }
}
