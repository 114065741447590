import { Pipe, PipeTransform } from '@angular/core';
import { LanguageEnum } from '../interfaces/ILanguage';
import { I18nService } from '@common/modules/i18n/i18n.service';

interface PipeParams {
  timeOnly: boolean;
  locale: LanguageEnum;
}

@Pipe({
  name: 'dateUntil',
})
export class DateUntilPipe implements PipeTransform {
  constructor(private i18nService: I18nService) { }

  public transform(value: any, params: PipeParams): string {
    if (!value) {
      return value;
    }

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const targetDate = new Date(value);
    targetDate.setHours(0, 0, 0, 0);

    const differenceInMilliseconds = targetDate.getTime() - currentDate.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    const differenceInMonths = differenceInDays / 30; // Approximate number of days in a month

    if (differenceInDays >= 30) {
      return this.i18nService.translate('interval.until.months', { months: Math.floor(differenceInMonths) });
    } else if (differenceInMilliseconds === 0) {
      return this.i18nService.translate('interval.until.today');
    } else if (differenceInDays < 0) {
      return this.i18nService.translate('interval.until.expired');
    } else {
      return this.i18nService.translate('interval.until.days', { days: Math.floor(differenceInDays) });
    }
  }
}

