import { Component, Input, OnChanges } from "@angular/core";
import { SeverityBadge } from "@ui-kit/components/ui-severity-badge/severityBadge";
import { AlertPriorityEnum } from "projects/@common/services/api/respond/alerts/alerts.definitions";

export type alertingBadgeType = AlertPriorityEnum;

@Component({
  selector: 'alerting-badge',
  templateUrl: './alerting-badge.component.html',
})
export class AlertingBadgeComponent implements OnChanges {
  @Input()
    type: alertingBadgeType;

  private _severityBadge: SeverityBadge;

  ngOnChanges(): void {
    this.setBadge();
  }

  public get severityBadge(): SeverityBadge {
    return this._severityBadge;
  }

  private setBadge(): void {
    switch (this.type) {
      case AlertPriorityEnum.P1:
        this._severityBadge = new SeverityBadge('P1', '#de3618', 'white');
        break;
      case AlertPriorityEnum.P2:
        this._severityBadge = new SeverityBadge('P2', '#ff7800', 'white');
        break;
      case AlertPriorityEnum.P3:
        this._severityBadge = new SeverityBadge('P3', '#fdc431', 'white');
        break;
      case AlertPriorityEnum.P4:
        this._severityBadge = new SeverityBadge('P4', '#5fc1cd', 'white');
        break;
    }
  }
}
