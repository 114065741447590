<ui-text-field
               *ngIf="isEditing"
               [label]="label"
               [name]="name"
               [control]="control"
               [placeholder]="placeholder"
               [rowsNumber]="rowsNumber"
               [value]="value"
               [readonly]="readonly"
               [disabled]="disabled"
               [required]="required"
               [group]="group"
               [arrayName]="arrayName"
               [groupName]="groupName"
               [maxlength]="maxlength"
               [forceInvalid]="forceInvalid">
</ui-text-field>

<ng-container *ngIf="!isEditing">
  <label ui-label>{{ label }}</label>
  <p>{{ value?.length < 1 ? ("-") : value }}</p>
</ng-container>
