/* eslint-disable no-use-before-define */
import { Directive } from '@angular/core';
import { CdkHeaderRowDef } from '@angular/cdk/table';

@Directive({
  selector: '[uiHeaderRowDef]',
  providers: [ { provide: CdkHeaderRowDef, useExisting: UiHeaderRowDef } ],
  inputs: [ 'columns: uiHeaderRowDef' ],
})
export class UiHeaderRowDef extends CdkHeaderRowDef {
  static ngAcceptInputType_sticky: boolean | string | null | undefined;
}
