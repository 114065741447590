import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { MobileService } from "projects/@common/services/mobile.service";
import { clone } from "projects/@common/utils/utils";

export interface ComponentData {
  component: string;
  data: any;
  state: any;
  index: number;
}

export interface ReturnedData {
  class: string;
  data: any;
}

export interface StoreModel {
  tabName: string;
  isEditing: boolean;
  isFullWidth: boolean;
  expanded: boolean;
  componentData: ComponentData;
  returnedData: ReturnedData;
  components: ComponentData[];
}

export interface ComponentInfo {
  component: string;
  data: any & {
    emptyHeader?: boolean
    showListNavigationButtons?: boolean
  };
}

export const initialState: StoreModel = {
  tabName: '',
  isEditing: false,
  isFullWidth: false,
  expanded: false,
  componentData: null,
  returnedData: null,
  components: [],
};

export class HideDrawer {
  static readonly type = '[Drawer] Hide';
}

export class SetDrawerIsEditing {
  static readonly type = '[App] Set drawer is editing';

  public constructor(public payload: { isEditing: boolean; tabName?: string }) {
  }
}

export class PreviousComponent {
  static readonly type = '[Drawer] Previous component';
}

export class SetComponentState {
  static readonly type = '[Drawer] Set Component State';

  public constructor(public payload: { state: any; index: number }) {
  }
}

export class ShowComponent {
  static readonly type = '[Drawer] Show Component';

  public constructor(public payload: ComponentInfo) {
  }
}

export class EditComponentData {
  static readonly type = '[Drawer] Edit Component Data';

  public constructor(public payload: ComponentInfo) {
  }
}

export class ReplaceComponent {
  static readonly type = '[Drawer] Replace Component';

  public constructor(public payload: ComponentInfo) {
  }
}

export class SendPreviousData {
  static readonly type = '[Drawer] Send data from previous component';

  public constructor(public payload: { data: any; class: string }) {
  }
}

export class ToggleDrawerFullWdith {
  static readonly type = '[Drawer] Toggle the drawer width between full-width or standard-width.';
}

@State<StoreModel>({
  name: 'drawer',
  defaults: clone(initialState),
})
@Injectable()
export class DrawerState {
  public constructor(private store: Store, private mobileService: MobileService) {
  }

  @Selector()
  public static isExpanded(state: StoreModel): boolean {
    return state.expanded;
  }

  @Selector()
  public static isEditing(state: StoreModel): boolean {
    return state.isEditing;
  }

  @Selector()
  public static isFullWidth(state: StoreModel): boolean {
    return state.isFullWidth;
  }

  @Selector()
  public static tabName(state: StoreModel): string {
    return state.tabName;
  }

  @Selector()
  public static componentData(state: StoreModel): ComponentData {
    return state.componentData;
  }

  @Selector()
  public static returnedData(state: StoreModel): any {
    return state.returnedData;
  }

  @Action(SetDrawerIsEditing)
  public setDrawerIsEditing(ctx: StateContext<StoreModel>, { payload }: SetDrawerIsEditing): void {
    const { isEditing, tabName } = payload;
    ctx.patchState({
      isEditing,
      tabName,
    });
  }

  @Action(ToggleDrawerFullWdith)
  public toggleDrawerFullWidth(ctx: StateContext<StoreModel>, action: ToggleDrawerFullWdith): void {
    ctx.patchState({
      isFullWidth: !Boolean(ctx.getState().isFullWidth),
    });
  }

  @Action(SetComponentState)
  public async setComponentState(ctx: StateContext<StoreModel>, action: SetComponentState) {
    const stack = [ ...ctx.getState().components ];
    if ((action.payload.index || action.payload.index === 0) && action.payload.index < stack.length) {
      stack[action.payload.index] = clone(stack[action.payload.index]);
      stack[action.payload.index].state = action.payload.state;
    }

    ctx.patchState({
      components: stack,
    });
  }

  @Action(ShowComponent)
  public async showComponent(ctx: StateContext<StoreModel>, action: ShowComponent) {
    const componentData = {
      component: action.payload.component,
      data: action.payload.data,
      index: ctx.getState().components.length,
      state: null,
    };
    const stack = [ ...ctx.getState().components ];
    stack.push(componentData);

    ctx.patchState({
      expanded: true,
      componentData,
      returnedData: null,
      components: stack,
    });
    if (this.mobileService.isMobile()) {
      this.store.dispatch(new SetDrawerIsEditing({
        isEditing: false,
      }));
    }
  }

  @Action(EditComponentData)
  public async editComponentData(ctx: StateContext<StoreModel>, action: ShowComponent) {
    const componentData = {
      component: action.payload.component,
      data: action.payload.data,
      index: ctx.getState().components.length,
      state: null,
    };
    const stack = [ ...ctx.getState().components ];

    ctx.patchState({
      expanded: true,
      componentData,
      returnedData: null,
      components: stack,
    });
  }

  @Action(ReplaceComponent)
  public async replaceComponent(ctx: StateContext<StoreModel>, action: ReplaceComponent) {
    this.previousComponent(ctx);
    this.showComponent(ctx, action);
  }

  @Action(HideDrawer)
  public hide(ctx: StateContext<StoreModel>): void {
    ctx.patchState({
      expanded: false,
      componentData: null,
      returnedData: null,
      components: [],
      isEditing: false,
    });
  }

  @Action(PreviousComponent)
  public previousComponent(ctx: StateContext<StoreModel>): void {
    const stack = [ ...ctx.getState().components ];
    stack.pop();

    if (stack.length > 0) {
      ctx.patchState({
        componentData: stack[stack.length - 1],
        components: stack,
        isEditing: false,
      });
    } else {
      this.hide(ctx);
    }
  }

  @Action(SendPreviousData)
  public sendPreviousData(ctx: StateContext<StoreModel>, action: SendPreviousData): void {
    const returnedData: ReturnedData = {
      data: action.payload.data,
      class: action.payload.class,
    };
    ctx.patchState({
      returnedData,
    });
  }
}
