import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { normalizeString } from 'projects/@common/utils/utils';

interface IDropdownOption {
  value: string;
  isIgnored: boolean;
  isExactMatch: boolean;
}

@Component({
  selector: 'combobox-input',
  templateUrl: './combobox-input.component.html',
  styleUrls: [ './combobox-input.component.scss' ],
})
export class ComboboxInputComponent implements OnInit {
  @Input() defaultValue: string = "";
  @Input() dropdownOptions: string[] = [];
  @Input() dropdownOptionsToIgnore: string[] = [];
  @Input() disabled: boolean = false;

  @Output() valueChangeEvent = new EventEmitter<string>();

  public inputValue: string = "";
  public isDropdownVisible: boolean = false;
  public mappedDropdownOptions: IDropdownOption[] = [];

  constructor(private readonly _elementRef: ElementRef) { }

  ngOnInit(): void {
    this.inputValue = this.defaultValue ?? null;
  }

  @HostListener('keydown.escape', [ '$event' ])
  onEscapeKey(event: KeyboardEvent) {
    if (this.isDropdownVisible) {
      this.closeDropdown();
    } else if (this.inputValue) {
      this.handleValueChange(null);
    }
  }

  @HostListener('document:click', [ '$event.target' ])
  public handleClickOut(targetElement: HTMLElement) {
    if (this.isDropdownVisible) {
      const clickedInside = this._elementRef.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.closeDropdown();
      }
    }
  }

  public get hasExactMatch(): boolean {
    return this.mappedDropdownOptions.some((option: IDropdownOption) => option.isExactMatch);
  }

  public handleValueChange(value: string): void {
    this.inputValue = value;
    this.valueChangeEvent.emit(this.inputValue);
  }

  public handleInputTyping(value: string): void {
    this.setMappedDropdownOptions(false);
    if (!this.mappedDropdownOptions.some((option: IDropdownOption) => this.stringMatches(option.value, value))) {
      this.closeDropdown();
    }
  }

  public handleDropdownOptionClick(option: IDropdownOption): void {
    this.handleValueChange(option.value);
    this.setMappedDropdownOptions(false);
    this.toggleDropdown();
  }

  public toggleDropdown(): void {
    if (this.isDropdownVisible) {
      this.closeDropdown();
    } else if (!this.disabled) {
      this.openDropdown();
    }
  }

  public openDropdown(): void {
    this.setMappedDropdownOptions(true);
    this.isDropdownVisible = true;
    setTimeout(() => {
      const exactMatchOptionElement = document.querySelector(".combobox-dropdown--option.isExactMatch") as HTMLElement;
      exactMatchOptionElement?.scrollIntoView({ behavior: "auto", block: "nearest", inline: "nearest" });
    }, 50);
  }

  public closeDropdown(): void {
    this.isDropdownVisible = false;
  }

  private stringMatches(str1: string, str2: string): boolean {
    return normalizeString(str1).includes(normalizeString(str2));
  }

  private setMappedDropdownOptions(showAll: boolean): void {
    this.mappedDropdownOptions = this.dropdownOptions
      .map<IDropdownOption>((option: string) => ({
      value: option,
      isExactMatch: option === this.inputValue,
      isIgnored: this.dropdownOptionsToIgnore.includes(option),
    }))
      .filter((option: IDropdownOption) => !option.isIgnored)
      .filter((option: IDropdownOption) => showAll || !this.inputValue || option.isExactMatch || this.stringMatches(option.value, this.inputValue))
      .sort((a: IDropdownOption, b: IDropdownOption) => a.value.localeCompare(b.value));
  }
}
