<header class="topbar-user-popover--header">
  <ui-static
    [label]="'common.user' | t"
    [value]="user.preferred_username || user.name"
  ></ui-static>
  <ng-container *ngIf="showEmailAddress">
    <div *ngIf="!isEditingEmail" class="topbar-user-popover--header--email--static">
      <ui-static
        [label]="'common.email' | t"
        [value]="currentEmail"
      ></ui-static>
      <button
      class="topbar-user-popover--header--email--static--button"
        ui-button
        icon="icon_edit"
        [custom-icon]="true"
        [isSvg]="true"
        icon-size="small"
        color="listActionFlat"
        (click)="editEmailIconClicked($event)"
      ></button>
    </div>
    <div *ngIf="isEditingEmail" class="topbar-user-popover--header--email-editing">
      <ui-field
        [required]="true"
        [label]="'email-initializer.email.label' | t"
        [control]="'email'"
        [locale]="i18n.currentLocale"
        [placeholder]="'email-initializer.email.placeholder' | t"
        [group]="formGroup"
      ></ui-field>
      <ui-confirmations-buttons
        [isConfirmEnabled]="formGroup.valid"
        (confirmationEvent)="handleEmailConfirmation($event)"
      ></ui-confirmations-buttons>
    </div>
  </ng-container>
  <ui-static
    [label]="'common.organization' | t"
    [value]="organization.name"
  ></ui-static>
</header>

<span class="topbar-user-popover--separator"></span>

<div class="topbar-user-popover--actions">

  <div class="topbar-user-popover--actions--button-group">
    <button
      (click)="toggleLocale()"
      ui-button
      cdk-overlay-origin
      #trigger
      #origin="cdkOverlayOrigin"
      [ripple]="false"
      [disabled]="false"
      color="linkFlat"
      icon="web"
      icon-size="medium"
      icon-margin="0 8px 0 0"
      [tooltip]="'topbar.user.popover.actions.locale.tooltip'|t:{locale:currentLocale}"
    >
      {{(currentLocale === 'fr' ? 'English' : 'Français')}}
    </button>

    <button
      *display="{ permissions: ['can_create_incident'] }"
      (click)="copyHmac()"
      ui-button
      cdk-overlay-origin
      #trigger
      #origin="cdkOverlayOrigin"
      [ripple]="false"
      [disabled]="false"
      color="linkFlat"
      icon="key"
      icon-size="medium"
      icon-margin="0 8px 0 0"
      [tooltip]="'topbar.user.popover.actions.key.tooltip'|t"
    >
      {{ 'topbar.user.popover.actions.key' | t }}
    </button>
  </div>

  <span class="topbar-user-popover--separator"></span>

  <div class="topbar-user-popover--actions--button-group">
    <button
      (click)="logout()"
      ui-button
      cdk-overlay-origin
      #trigger
      #origin="cdkOverlayOrigin"
      [attr.data-test-id]="'topbar_user_menu_logout'"
      [ripple]="false"
      [disabled]="false"
      color="linkFlat"
      icon="logout"
      icon-size="medium"
      icon-margin="0 8px 0 0"
      [tooltip]="'topbar.user.popover.actions.signout.tooltip'|t"
    >
      {{ 'topbar.user.popover.actions.signout' | t }}
    </button>
  </div>
</div>