import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

export type IUiMenuOptions = {
  id: string;
  iconPath: string;
  translation: string;
  routerLink?: string;
  queryParams?: { [key: string]: string };
  queryParamsHandling?: "merge" | "preserve" | "";
};

@Component({
  selector: 'ui-menu-options',
  templateUrl: './ui-menu-options.component.html',
  styleUrls: [ './ui-menu-options.component.scss' ],
})
export class UiMenuOptionsComponent implements OnInit {
  @Input() menuOptions: IUiMenuOptions[];

  @Output() menuOptionClickEvent = new EventEmitter<IUiMenuOptions>();
  @Output() menuOpenedEvent = new EventEmitter<void>();
  @Output() menuClosedEvent = new EventEmitter<void>();

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  public showOptionsMenu = false;

  constructor() { }

  ngOnInit(): void {
  }

  public trackByMenuOptionId(index, item) {
    return item.id;
  }

  public openMenu(): void {
    this.trigger.openMenu();
  }

  public closeMenu(): void {
    this.trigger.closeMenu();
  }

  public emitMenuOptionClicked(option: IUiMenuOptions) {
    this.menuOptionClickEvent.emit(option);
  }

  public emitMenuOpened(): void {
    this.menuOpenedEvent.emit();
  }

  public emitMenuClosed(): void {
    this.menuClosedEvent.emit();
  }
}
