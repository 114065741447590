import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Eco } from 'projects/@common/definitions/eco';
import { IamApiService } from 'projects/@common/services/api/iam/iam.api';
import { IamApi } from 'projects/@common/services/api/iam/iam.api.definitions';
import {
  InvalidateData,
  LoadDecisionsFromBrowser,
  SetPermissions
} from './state/display.state';

export type Decisions = { [ids: string]: boolean; };

export type DisplayRequirements = {
  permissions?: string[];
  orPermissions?: string[];
  flags?: string[];
  orgType?: string[];
  services?: string[];
};

@Injectable({
  providedIn: 'root',
})
export class DisplayService {
  constructor(
    @Inject('PERMISSIONS_MAPPING') private mapping: Eco.PermissionsMapping,
    private readonly iam: IamApiService,
    private readonly store: Store
  ) { }

  public async loadPermissions() {
    const request = this.getPermissionRequest();
    const permissionsResponse = await this.iam.describePermissions(request);
    this.updatePermissions(permissionsResponse);
  }

  public async loadDataFromBrowserIfNeeded() {
    this.store.dispatch(new LoadDecisionsFromBrowser());
  }

  public invalidateData() {
    this.store.dispatch(new InvalidateData());
  }

  public meetsRequirements(requirements: DisplayRequirements): boolean {
    const requirementsMet = [];

    if (requirements.permissions?.length) {
      const permissionsDecisions = this.store.selectSnapshot((state) => state.display);
      requirementsMet.push(this.validateDecisions(requirements.permissions, permissionsDecisions));
    }

    if (requirements.orPermissions?.length) {
      const permissionsDecisions = this.store.selectSnapshot((state) => state.display);
      requirementsMet.push(this.validateOrDecisions(requirements.orPermissions, permissionsDecisions));
    }

    if (requirements.flags?.length) {
      const flagsDecisions = this.store.selectSnapshot((state) => state.eco.featureFlags);
      requirementsMet.push(this.validateDecisions(requirements.flags, flagsDecisions));
    }

    if (requirements.orgType?.length) {
      const orgTypes = this.store.selectSnapshot((state) => state.eco.organization.tags);
      requirementsMet.push(this.validateDecisions(
        requirements.orgType,
        Object.assign({}, ...orgTypes.map((type) => ({ [type]: true })))
      ));
    }

    // For now, they are the same as the feature flags, but they will already be split for when they are ready!
    if (requirements.services) {
      const servicesDecisions = this.store.selectSnapshot((state) => state.eco.services);
      requirementsMet.push(this.validateDecisions(requirements.services, servicesDecisions));
    }

    return requirementsMet.every((requirement) => requirement === true);
  }

  private validateDecisions(requirements: string[], decisions: Decisions): boolean {
    return requirements.every((id) => decisions[id]);
  }

  private validateOrDecisions(requirements: string[], decisions: Decisions): boolean {
    return requirements.some((id) => decisions[id]);
  }

  private updatePermissions(res: IamApi.DescribePermissions.response) {
    const decisions: Decisions = {};
    res.forEach((decision) => (decisions[decision.id] = decision.shouldShow));
    this.store.dispatch(new SetPermissions(decisions));
  }

  private getPermissionRequest(): IamApi.DescribePermissions.request {
    const request: IamApi.DescribePermissions.request = { queries: [] };

    for (const [ key, value ] of Object.entries(this.mapping)) {
      request.queries.push({
        id: key,
        action: value.action,
        resourceLabel: value.resourceLabel,
      });
    }

    return request;
  }
}
