import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';


interface IEmitValue {
  choice: any;
  index: number;
  nativeEvent: Event;
}

type VanillaJsQuerySelector = string;


@Directive({
  selector: '[uiAccessibleChoiceListDirective]',
})
export class UiAccessibleChoiceListDirective {
  @Output()
  public choiceSelectedEmitter: EventEmitter<IEmitValue> = new EventEmitter();

  @Input()
  private choices: any[] = [];

  @Input()
  private choiceQuerySelector: VanillaJsQuerySelector = ".accessible-choice";

  private highlightedChoice: any = null;

  private get currentIndex() {
    return this.choices?.findIndex((choice) => choice === this.highlightedChoice);
  }

  constructor(private eRef: ElementRef) { }

  @HostListener('document:keydown.enter', [ '$event' ])
  onEnterKey(event: KeyboardEvent): boolean {
    if (!this.highlightedChoice) {
      this.highlightedChoice = this.choices?.[0];
    }
    if (this.highlightedChoice) {
      const emitData = { choice: this.highlightedChoice, index: this.currentIndex, nativeEvent: event };
      this.choiceSelectedEmitter.emit(emitData);
    }
    return false;
  }

  @HostListener('document:keydown.arrowdown', [ '$event' ])
  onArrowDownKey(event: KeyboardEvent): boolean {
    this.highlightNextSuggestion("down");
    return false;
  }

  @HostListener('document:keydown.arrowup', [ '$event' ])
  onArrowUpKey(event: KeyboardEvent): boolean {
    this.highlightNextSuggestion("up");
    return false;
  }

  private applyDOMChanges(currentIndex: number, nextIndex: number): void {
    const choicesNodeList = this.eRef.nativeElement?.querySelectorAll(this.choiceQuerySelector) as NodeListOf<HTMLElement>;
    choicesNodeList?.forEach((choiceHTMLElement, i) => {
      if (i === currentIndex) {
        choiceHTMLElement.style.fontWeight = "";
        choiceHTMLElement.style.backgroundColor = "";
      } else if (i === nextIndex) {
        choiceHTMLElement.style.fontWeight = "bold";
        choiceHTMLElement.style.backgroundColor = "#f9fafb";

        if (document.activeElement?.tagName !== "INPUT") {
          choiceHTMLElement.focus();
        } else {
          choiceHTMLElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
      }
    });
  }

  private highlightNextSuggestion(direction: "up" | "down"): void {
    if (this.choices?.length > 0) {
      let nextIndex = 0;

      const currentIndex = this.currentIndex;
      const maxIndex = this.choices.length - 1;
      if (direction === "down") {
        const incremented = currentIndex + 1;
        nextIndex = (incremented > maxIndex) ? 0 : incremented;
      } else if (direction === "up") {
        const decremented = currentIndex - 1;
        nextIndex = (decremented < 0) ? maxIndex : decremented;
      }
      this.highlightedChoice = this.choices[nextIndex];

      this.applyDOMChanges(currentIndex, nextIndex);
    }
  }
}

/*
Instructions:
- Add the uiAccessibleChoiceListDirective directive on the parent.
- Pass the [choices] data and the function to call when (choiceSelectedEmitter) is emitted.
- On the child that renders the *ngFor, add the class "accessible-choice"

Usage example in a template file:
<div
    uiAccessibleChoiceListDirective
    [choices]="multiSelectData.options"
    (choiceSelectedEmitter)="checkOption($event.choice)"
>
   <div
      *ngFor="let option of multiSelectData.options;
      class="accessible-choice"
    >
      // div content...
  </div>
</div>
*/
