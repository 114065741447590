import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from '../../services/modal.service';

export interface IConfirmationModal {
  title: string;
  text: string;
  confirmationText?: string;
  cancelText?: string;
  callback?: any;
}

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: [ './confirmation-modal.component.scss' ],
})
export class ConfirmationModalComponent {
  public confirmationData: IConfirmationModal;

  public get paragraphLines(): string[] {
    return this.confirmationData.text.split('\n');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IConfirmationModal,
    public modalService: ModalService
  ) {
    this.confirmationData = data;
  }

  public closeModal(value: boolean): void {
    if (this.confirmationData.callback) {
      this.confirmationData.callback(value);
    }
    this.modalService.closeDialog();
  }
}
