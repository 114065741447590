import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'ui-collapsable-dropdown',
  templateUrl: './ui-collapsable-dropdown.component.html',
  styleUrls: [ './ui-collapsable-dropdown.component.scss' ],
})
export class UiCollapsableDropDownComponent {
  @Input() public isCollapsable = true;

  @Input() public isCollapsed = true;

  @Input() public title: string;

  @HostListener('document:keydown.escape', [ '$event' ])
  onEscapeKey(event: KeyboardEvent) {
    if (!this.isCollapsed) {
      event.stopPropagation();
      this.toggleCollapse();
    }
  }

  public toggleCollapse() {
    if (this.isCollapsable) {
      this.isCollapsed = !this.isCollapsed;
    }
  }
}
