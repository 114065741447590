import { Injectable } from "@angular/core";
import { DisplayRequirements } from "projects/@common/modules/display/display.service";
import { BaseSidebar, OrgTypeEnum, navigationElement, previewModeEnum } from "projects/@common/modules/layout/definitions/base-sidebar.service";

@Injectable()
export class AdmDetectSidebar extends BaseSidebar {
  private elements: navigationElement[] = [
    { name: 'MD.DETECT' },
    {
      rootAction: true,
      name: 'sidebar.navigation.detect.connectors',
      icon: 'sidebar/icon_menu_connectors',
      route: '/detect/connectors',
      permissions: [ 'can_list_detect_connectors' ],
      orgType: [ OrgTypeEnum.VAR, OrgTypeEnum.CUSTOMER ],
    },
    {
      rootAction: true,
      name: 'sidebar.navigation.detect.hosts',
      icon: 'sidebar/icon_menu_mdedge',
      route: '/detect/hosts',
      permissions: [ 'can_list_detect_hosts' ],
      preview: previewModeEnum.PREVIEW,
      orgType: [ OrgTypeEnum.VAR, OrgTypeEnum.CUSTOMER ],
    },
  ];

  constructor() {
    super();
  }

  public unsubscribe() {
  }

  public getElements() {
    return this.elements;
  }

  public getRequirements(): DisplayRequirements {
    return {};
  }
}
