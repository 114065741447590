import { Component, Input, OnInit } from "@angular/core";

import { LanguageEnum } from "../../interfaces/ILanguage";
import { I18nService } from "../../services/I18nService";

type LanguageTranslationType = "Français" | "Anglais" | "French" | "English";

type IconNameType = "flag_fr" | "flag_en";

@Component({
  selector: 'ui-preferred-language-flag',
  templateUrl: './ui-preferred-language-flag.component.html',
  styleUrls: [ './ui-preferred-language-flag.component.scss' ],
})
export class UiPreferredLanguageFlagComponent implements OnInit {
  @Input() private userLocale: LanguageEnum;
  @Input() private preferredLanguage: LanguageEnum;

  public iconName: IconNameType;
  public tooltipMessage: string;

  constructor(private readonly i18n: I18nService) {
  }

  ngOnInit(): void {
    this.iconName = this.getIconName();
    this.tooltipMessage = this.getTooltipMessage();
  }

  private getIconName(): IconNameType {
    return `flag_${this.preferredLanguage}` as IconNameType;
  }

  private getTooltipMessage(): string {
    const lang = this.i18n.translate(`common.language.${this.preferredLanguage}`, { locale: this.userLocale }) as LanguageTranslationType;
    return this.i18n.translate('common.language.communication', { locale: this.userLocale, language: lang });
  }
}

/*
Shows a preferred language flag icon, with a localized tooltip message in the connected user's locale.
*/
