import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-collapsable-toggle-image',
  templateUrl: './ui-collapsable-toggle-image.component.html',
  styleUrls: [ './ui-collapsable-toggle-image.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class UiCollapsableToggleImage {
  @Input() isCollapsed = true;

  @Input() size = 'medium';
}
