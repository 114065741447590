import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: '[ui-mobile-card]',
  templateUrl: './ui-mobile-card.component.html',
  styleUrls: [ './ui-mobile-card.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class UiMobileCard {
  @HostBinding() public class = 'ui-mobile-card';
}
