import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { IamApiService } from 'projects/@common/services/api/iam/iam.api';
import { TeamsAuthService } from '../../../../services/teams-auth.service';
import { JwtTokenUtil } from '../../../../utils/jwt-token-util';
import { EcoSessionState } from '../../../session/state/session.state';

@Component({
  selector: 'authn-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class AuthnSignInComponent implements OnInit {
  public capslockOn: boolean;

  public isSigninIn = false;

  public loginForm: UntypedFormGroup;

  public afterSignInRedirect = null;

  public afterSignInRedirectQueryParam = null;

  public targetOrganization: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private iamApi: IamApiService,
    private teamsAuthService: TeamsAuthService,
    protected readonly store: Store
  ) {
  }

  @HostListener('window:click', ['$event'])
  public onClick(event) {
    this.capslockOn = event.getModifierState && event.getModifierState('CapsLock');
  }

  @HostListener('window:keydown', ['$event'])
  public onKeyDown(event) {
    this.capslockOn = event.getModifierState && event.getModifierState('CapsLock');
  }

  @HostListener('window:keyup', ['$event'])
  public onKeyUp(event) {
    this.capslockOn = event.getModifierState && event.getModifierState('CapsLock');
  }

  public async ngOnInit() {
    await this.initTeams();
    const userEmail = this.route.snapshot.queryParams.user;

    if (userEmail) {
      this.isSigninIn = true;
      this.doLogin(userEmail);
    }
    this.createLoginForm();

    if (this.store.selectSnapshot(EcoSessionState.authentified)) {
      this.router.navigate(['dashboard']);
      return;
    }
  }

  public createLoginForm() {
    const rememberMe = localStorage.getItem('rememberMe');
    let email = '';
    if (rememberMe) {
      email = localStorage.getItem('email');
    }
    this.loginForm = this.formBuilder.group({
      username: [email, Validators.required],
      rememberMe,
    });
  }

  public login() {
    this.isSigninIn = true;
    const username = this.loginForm.get('username').value;
    const rememberMe = this.loginForm.get('rememberMe').value;

    if (rememberMe) {
      localStorage.setItem('email', username);
      localStorage.setItem('rememberMe', 'true');
    } else {
      localStorage.removeItem('email');
      localStorage.removeItem('rememberMe');
    }

    this.doLogin(username);
  }

  private doLogin(username: string) {
    this.iamApi
      .authorizationUrl({
        email: username,
        login_hint: username,
        redirectUrl: `${window.location.origin}/login/office`,
        state: JSON.stringify({
          email: username,
        }),
      })
      .then((response) => {
        window.location.href = response.authorizationUrl;
      })
      .catch(() => {
        this.isSigninIn = false;
      });
  }


  private async initTeams() {
    if (TeamsAuthService.isInTeams) {
      const token = await this.teamsAuthService.init();

      const email = JwtTokenUtil.decodeJwtToken(token)?.upn;
      this.router.navigate([`/login/office`], { queryParams: { jwt: token, state: JSON.stringify({ email }) } });
    }
  }
}
