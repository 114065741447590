import { IRespondResponse } from "../definitions/IRespondResponse";

export class ResponseUtils<T> {
  private readonly _respondResponse: IRespondResponse<T>;

  constructor(respondResponse: IRespondResponse<T>) {
    this._respondResponse = respondResponse;
  }

  public getItems(): T[] {
    return this._respondResponse.items;
  }

  public getTotalItems(): number {
    return this._respondResponse.total;
  }

  public getNextItem(): number {
    return this._respondResponse.nextItem;
  }

  public canLoadMore(): boolean {
    return this._respondResponse.nextItem < this._respondResponse.total;
  }

  public getMetadata(): any {
    return this._respondResponse.metadata;
  }
}
