import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { StorageService } from 'projects/@common/services/storage.service';
import { SessionService } from "./session.service";
import { EcoSessionState } from "./state/session.state";

@Injectable()
export class AuthGuard implements CanActivate {
  @Select(EcoSessionState.authentified) public isAuthentified: Observable<boolean>;

  public constructor(
    protected readonly store: Store,
    private sessionsService: SessionService,
    private router: Router,
    private storageService: StorageService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    // Save redirect for after the signing
    if (!this.store.selectSnapshot(EcoSessionState.authentified)) {
      this.storageService.setLocal('redirect', state.url);
    }

    return new Observable<boolean>((observer) => {
      this.sessionsService.loadBrowserSessionIfNeeded();
      this.isAuthentified.subscribe((value) => {
        if (value) {
          observer.next(true);
          observer.complete();
        } else {
          sessionStorage.clear();
          this.router.navigate(['/signin']);
          observer.next(false);
        }
      });
    });
  }
}
