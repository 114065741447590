import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TextMaskConfig } from 'angular2-text-mask';
import { randomBytes } from 'crypto';
import { Subscription } from 'rxjs';
import { LanguageEnum } from '../../interfaces/ILanguage';
import { UiInputType } from '../ui-input/ui-input.component';

@Component({
  selector: 'ui-field',
  templateUrl: './ui-field.component.html',
  styleUrls: [ './ui-field.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-field',
  },
})
export class UiField implements OnInit, OnChanges {
  @Input('label') public label: string;

  @Input('name') public name: string = randomBytes(4).toString('hex');

  @Input('value') public value: number | string;

  @Input('type') public type: UiInputType = UiInputType.Text;

  @Input('placeholder') public placeholder: string;

  @Input('mask') public mask: TextMaskConfig;

  @Input('required') public required = false;

  @Input('readonly') public readonly = false;

  @Input('disabled') public disabled = false;

  @Input('group') public group: UntypedFormGroup;

  @Input('control') public control: string = this.name;

  @Input('arrayName') public arrayName: string;

  @Input('groupName') public groupName: string;

  @Input('maxlength') public maxlength = '';

  @Input('forceInvalid') public forceInvalid = false;

  @Input('enableAutocomplete') public enableAutocomplete = false;

  @Input('functionToExecuteOnInput') public functionToExecuteOnInput: (arg0: string) => string;

  @Output('focus') public focus: EventEmitter<void> = new EventEmitter();

  @Output('blur') public blur: EventEmitter<void> = new EventEmitter();

  @Output('inputValue') public inputValue: EventEmitter<string> = new EventEmitter();

  @Input('errorMessage') public errorMessage: string = null;

  @Input('showErrorMessage') public showErrorMessage: boolean = true;

  @Input('description') public description: string;

  @Input() public locale: LanguageEnum | string = LanguageEnum.FRENCH;

  maxLengthError: number;

  inputSubscription: Subscription;

  executeInputedFunction(input: string) {
    if (this.functionToExecuteOnInput) {
      const modifiedInput = this.functionToExecuteOnInput(input);
      if (input !== modifiedInput) {
        this.group.get(this.control).setValue(modifiedInput);
      }
    }
  }

  ngOnInit() {
    try {
      if (this.group?.get(this.control)) {
        this.inputSubscription = this.group.get(this.control).valueChanges.subscribe(() => {
          if (
            this.group?.get(this.control)?.errors &&
            Object.entries(this.group.get(this.control).errors)[0][0] !== 'required'
          ) {
            if (this.group.get(this.control)?.errors.maxlength?.requiredLength) {
              this.maxLengthError = this.group.get(this.control)?.errors.maxlength.requiredLength;
            }
            if (this.showErrorMessage) {
              this.errorMessage = `form.validation.${Object.entries(this.group.get(this.control).errors)[0][0]}`;
            }
          } else {
            this.errorMessage = null;
          }
        });
      }
    } catch (error) {
      console.log(`ERROR on error handling: ${error}`);
    }
  }

  ngOnChanges() {
    try {
      if (
        this.group?.get(this.control)?.errors &&
        Object.entries(this.group.get(this.control).errors)[0][0] !== 'required'
      ) {
        if (this.group.get(this.control)?.errors.maxlength?.requiredLength) {
          this.maxLengthError = this.group.get(this.control)?.errors.maxlength.requiredLength;
        }
        if (this.showErrorMessage) {
          this.errorMessage = `form.validation.${Object.entries(this.group.get(this.control).errors)[0][0]}`;
        }
      } else {
        this.errorMessage = null;
      }
    } catch (error) {
      console.log(`ERROR on error handling: ${error}`);
    }
  }

  ngOnDestroy() {
    this.inputSubscription?.unsubscribe();
  }

  // Helper to get formgroup content!
  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  onInputValue(event: any) {
    this.inputValue.emit(event.target.value);
  }

  onFocus() {
    this.focus.emit();
  }

  onBlur() {
    this.blur.emit();
  }
}
