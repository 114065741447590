<ng-container [ngSwitch]="field.type">
  <ng-container *ngSwitchCase="dynamicFormFieldType.TEXT_INPUT">
    <ng-container *ngTemplateOutlet="input; context: { config: field }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="dynamicFormFieldType.NUMBER_INPUT">
    <ng-container *ngTemplateOutlet="input; context: { config: field }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="dynamicFormFieldType.OBJECT_TEXTAREA">
    <ng-container *ngTemplateOutlet="textarea; context: { config: field }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="dynamicFormFieldType.ARRAY_TEXTAREA">
    <ng-container *ngTemplateOutlet="textarea; context: { config: field }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="dynamicFormFieldType.CHECKBOX">
    <ng-container *ngTemplateOutlet="checkbox; context: { config: field }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="dynamicFormFieldType.DROPDOWN">
    <ng-container *ngTemplateOutlet="dropdown; context: { config: field }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="dynamicFormFieldType.SECRET">
    <ng-container *ngTemplateOutlet="secret; context: { config: field }">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="dynamicFormFieldType.MULTI_SELECT">
    <ng-container *ngTemplateOutlet="multiSelect; context: { config: field }">
    </ng-container>
  </ng-container>

  <ng-template
               #input
               let-config="config">
    <ui-field-group layout="vertical">
      <ui-field
                [readonly]="readonly"
                [errorMessage]="errorUtils.getErrorMessage(formGroup.get(field.fieldName))"
                [required]="config.required && !readonly"
                [label]="config.label | translatedObjectLocale :locale"
                [maxlength]="config.maxlength || null"
                [type]="
          field.type === dynamicFormFieldType.NUMBER_INPUT
            ? 'number'
            : 'text'
        "
          [name]="field.fieldName"
          [control]="field.fieldName"
          [placeholder]="field.placeholder | translatedObjectLocale :locale"
          [group]="formGroup"
          (inputValue)="field.type === dynamicFormFieldType.NUMBER_INPUT ? onNumberInputValueChanged($event) : null"
      >
        <ui-tooltip *ngIf="config.tooltip">{{config.tooltip | translatedObjectLocale :locale}}</ui-tooltip>
      </ui-field>
      <label
             ui-label
             *ngIf="config.description">{{
        config.description | translatedObjectLocale :locale
        }}</label>
    </ui-field-group>
  </ng-template>

  <ng-template
               #textarea
               let-config="config">
    <ui-field-group layout="vertical">
      <ui-json-textarea
        [readonly]="readonly"
        [locale]="locale"
                     [type]="
          field.type === dynamicFormFieldType.OBJECT_TEXTAREA
            ? textareaTypeEnum.JSON
            : textareaTypeEnum.ARRAY
        "
                     [controlName]="field.fieldName"
                     [formGroup]="formGroup"
                     [label]="config.label"
                     [description]="config.description"
                     [placeholder]="config.placeholder"
                     [required]="config.required && !readonly"
      >
      </ui-json-textarea>
    </ui-field-group>
  </ng-template>

  <ng-template
               #checkbox
               let-config="config">
    <ui-field-group layout="vertical">
      <ui-checkbox
        [isEditable]="!readonly"
        [label]="config.label | translatedObjectLocale :locale"
        [controlName]="field.fieldName"
        [group]="formGroup">
      </ui-checkbox>
      <label ui-label>{{ config.description | translatedObjectLocale :locale }}</label>
    </ui-field-group>
  </ng-template>

  <ng-template
               #dropdown
               let-config="config">
    <ui-field-group layout="vertical">
      <ui-select-with-label-value
        [readonly]="readonly"
        [label]="config.label | translatedObjectLocale :locale"
        [description]="config.description | translatedObjectLocale :locale"
        [name]="field.fieldName"
        [required]="config.required && !readonly"
        [controlName]="field.fieldName"
        [group]="formGroup"
        [values]="config.values"
      >
      </ui-select-with-label-value>
    </ui-field-group>
  </ng-template>

  <ng-template #secret let-config="config">
    <ui-field-group layout="vertical">
      <ui-select
      [label]="config.label | translatedObjectLocale :locale"
      [name]="field.fieldName"
      [placeholder]="(!config.values?.length ? 'dynamic-form-field.secret.disabled' : 'dynamic-form-field.secret.placeholder') | t: {locale: locale}"
      [required]="config.required && !readonly"
      [control]="field.fieldName"
      [group]="formGroup"
      [options]="config.values"
      [disabled]="readonly"
    >
    </ui-select>
    </ui-field-group>
  </ng-template>

</ng-container>
