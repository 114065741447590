import { NgModule } from "@angular/core";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsModule } from "@ngxs/store";
import { AuthModule } from "projects/@common/modules/auth/auth.module";
import { DisplayModule } from "projects/@common/modules/display/display.module";
import { PrivateModule } from "projects/@common/modules/layout/private.module";
import { RootSharedModule } from "projects/@common/modules/shared/root.shared.module";
import { DatasourcesApiService } from "projects/@common/services/api/respond/datasources/datasources.api";
import { MonitorsApiService } from 'projects/@common/services/api/respond/usecase/monitors.api';
import { AdmDetectSidebar } from "../configuration/adm.detect.sidebar.service";

import { ConsoleType } from 'projects/@common/modules/layout/private.layout';
import { DashboardApi } from "projects/@common/services/api/respond/dashboard/dashboard.api";
import { AdminMonitorsApiService } from "projects/@respond/usecases/api/adm-monitor.api";
import { AdmAirSidebar } from '../configuration/adm.air.sidebar.service';
import { AdmIamSidebar } from "../configuration/adm.iam.sidebar.service";
import { ADM_APP_PAGES } from "../configuration/adm.pages.enum";
import { AdmPermissionsMapping } from "../configuration/adm.permission.mapping";
import { AdmRespondSidebar } from "../configuration/adm.respond.sidebar.service";
import { AdmToolsSidebarService } from "../configuration/adm.tools.sidebar.service";
import { AdmAppComponent } from "./adm.app.component";
import { AdmAppRoutingModule } from "./adm.app.routing.module";
import { AdminDashboardApi } from "./respond/dashboard/api/adm-dashboard.api";
import { AdminDatasourcesApiService } from "./respond/datasources/api/adm-datasources.api";
import { DatasourcesModule } from "./respond/datasources/datasources.module";

@NgModule({
  declarations: [
    AdmAppComponent,
  ],
  imports: [
    RootSharedModule,
    AdmAppRoutingModule,
    DatasourcesModule,
    AuthModule.forRoot({ redirectAfterLogin: '', assertOrganizationTag: 'root' }),
    DisplayModule.forRoot({
      permissionsMapping: AdmPermissionsMapping,
    }),
    PrivateModule.inject({
      iamSideBar: new AdmIamSidebar(),
      respondSidebar: new AdmRespondSidebar(),
      detectSidebar: new AdmDetectSidebar(),
      toolsSidebar: new AdmToolsSidebarService(),
      airSidebar: new AdmAirSidebar(),
      pagesEnum: ADM_APP_PAGES,
      consoleType: ConsoleType.ADM,
    }),
    NgxsModule.forRoot([]),
    NgxsReduxDevtoolsPluginModule.forRoot()
  ],
  providers: [
    ...RootSharedModule.getCommonProviders(),
    { provide: DashboardApi, useClass: AdminDashboardApi },
    { provide: DatasourcesApiService, useClass: AdminDatasourcesApiService },
    { provide: MonitorsApiService, useClass: AdminMonitorsApiService },
  ],
  bootstrap: [ AdmAppComponent ],
})
export class AdmModule { }
