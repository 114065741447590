import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';

type ExpandCardMenuOption = {
  id: MenuOptionIdsEnum;
  iconPath: string;
  translationKey: string;
};

enum MenuOptionIdsEnum {
  edit = "edit",
  delete = "delete"
}

export type ExtraIconHeader = {
  path: string;
  tooltipText: string;
};

@Component({
  selector: 'escalation-expand-card',
  templateUrl: './escalation-expand-card.component.html',
  styleUrls: [ './escalation-expand-card.component.scss' ],
})

export class EscalationExpandCardComponent implements OnChanges {
  @Input() title: string; // the main title on the card header
  @Input() useEditableTitle: boolean; // when in edit mode, the title will be an input
  @Input() titleIconPath: string; // main icon next to the title
  @Input() iconSize: string = "24px";
  @Input() extraHeaderIcons: ExtraIconHeader[]; // optional icons between the title and the action buttons
  @Input() description: string; // optional description under the header

  @Input() isExpanded: boolean; // whether the card is expanded or collapsed
  @Input() preventCollapse: boolean; // should the card be prevented from collapsing
  @Input() startInEditingMode: boolean; // on init the card will be in edit mode
  @Input() isConfirmButtonEnabled: boolean; // whether the confirm button is enabled or disabled (useful for edit validations)
  @Input() confirmButtonDisabledTooltip: string; // tooltip to show when the confirm button is disabled (if you need to explain why it's disabled)
  @Input() stickyColor: boolean; // should the background color used with mouse hover be used all the time

  @Input() showActionButtons: boolean = true; // whether to show the action buttons or not
  @Input() useMenuButton: boolean; // whether to show the menu button or not. When a menu is used, the action buttons are hidden within the menu
  @Input() useDeleteButton: boolean; // whether to show the delete button or not

  @Input() templateReadonly: TemplateRef<any>; // template to show when the card is not in edit mode
  @Input() templateEditing: TemplateRef<any>; // template to show when the card is in edit mode

  @Output() toggleExpandEvent = new EventEmitter<boolean>();
  @Output() toggleEditEvent = new EventEmitter<boolean>();
  @Output() saveEvent = new EventEmitter<void>();
  @Output() cancelEvent = new EventEmitter<void>();
  @Output() deleteEvent = new EventEmitter<void>();
  @Output() menuOptionClickEvent = new EventEmitter<ExpandCardMenuOption>();
  @Output() titleChangeEvent = new EventEmitter<string>();

  public isEditing = false;
  public showOptionsMenu = false;
  public menuOptions: ExpandCardMenuOption[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.useMenuButton || changes.useDeleteButton) {
      this.initMenuOptions();
    }
    if (changes.startInEditingMode?.currentValue === true && !this.isEditing) {
      setTimeout(() => {
        this.toggleEdit();
      }, 10);
      if (this.useEditableTitle) {
        setTimeout(() => {
          document.getElementById("titleInput")?.focus();
        }, 50);
      }
    }
  }

  public toggleExpand(value: boolean) {
    this.isExpanded = value;
    this.toggleExpandEvent.emit(this.isExpanded);
  }

  public toggleEdit() {
    this.isEditing = !this.isEditing;
    this.toggleEditEvent.emit(this.isEditing);
  }

  public toggleOptionsMenu(): void {
    this.showOptionsMenu = !this.showOptionsMenu;
  }

  public handleEditingConfirmation(value: boolean) {
    if (value) {
      this.saveEvent.emit();
    } else {
      this.cancelEvent.emit();
    }
    this.toggleEdit();
  }

  public handleDeleteClicked() {
    this.deleteEvent.emit();
  }

  public handleMenuOptionClicked(option: ExpandCardMenuOption) {
    if (option.id === MenuOptionIdsEnum.edit) {
      this.toggleEdit();
    } else if (option.id === MenuOptionIdsEnum.delete) {
      this.handleDeleteClicked();
    }
    this.menuOptionClickEvent.emit(option);
    this.toggleOptionsMenu();
  }

  public handleTitleChanged(value: string) {
    this.titleChangeEvent.emit(value);
  }

  public trackByMenuOptionId(index: number, item: ExpandCardMenuOption) {
    return item.id;
  }

  private initMenuOptions(): void {
    this.menuOptions = [
      {
        id: MenuOptionIdsEnum.edit,
        iconPath: 'assets/favicons/icon_modify_disabled.svg',
        translationKey: 'common.action.edit',
      },
    ];

    if (this.useDeleteButton) {
      this.menuOptions.push({
        id: MenuOptionIdsEnum.delete,
        iconPath: 'assets/favicons/icon_delete_gray.svg',
        translationKey: 'common.action.delete',
      });
    }
  }
}
