<div
  *ngIf="!isSearchingValues && customValuesToDisplay?.length"
  class="choices-list max-height"
  [ngStyle]="{ height: expandHeight }"
  [class.max-height-define]="maxHeight"
  uiAccessibleChoiceListDirective
  [choices]="customValuesToDisplay"
  (choiceSelectedEmitter)="onValueSelected($event.choice)"
>
  <ng-container>
    <div
      *ngFor="let customValue of customValuesToDisplay | slice: 0 : searchLimit; let index = index"
      class="hover choice separator accessible-choice"
      [id]="'rowElement_' + index"
      (mousedown)="onValueClick(customValue, index, $event)"
    >
      <div class="choice-infos-container">
        <img
          *ngIf="customValue.image"
          class="custom-value-image"
          [ngClass]="{ 'custom-value-image--border': imageWithBorder }"
          [src]="customValue.image"
          [alt]="customValue.displayValue + 'Image'"
        />
        <div class="choices-infos">
          <span>{{ customValue.displayValue }}</span>
          <ng-container *ngIf="customValue.description">
            <span class="custom-value-description">{{ customValue.description }}</span>
          </ng-container>
          <ng-container *ngIf="customValue.applications">
            <div class="descriptions">
              <span
                class="description"
                *ngFor="let applicationName of customValue.applications;"
              >{{applicationName}}
              </span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  </div>

<div *ngIf="!isSearchingValues && !customValuesToDisplay?.length" class="choices-list no-items">
  <span class="no-items--label">
    <i *ngIf="!customNotFound">{{ 'group.detail.owners.notFound.name' | t: { locale: locale } }}</i>
    <i *ngIf="customNotFound">{{ customNotFound }}</i>
  </span>
</div>
