import { Component, Input, ViewEncapsulation } from '@angular/core';

export interface IWarningMessage {
  messageKey: string;
  messageTranslationParams?: any;
}

export enum WarningColors {
  RED = "red",
  YELLOW = "yellow"
}

@Component({
  selector: 'ui-warning',
  templateUrl: './ui-warning.component.html',
  styleUrls: [ './ui-warning.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-warning',
  },
})
export class UiWarning {
  @Input() warningMessages: IWarningMessage[] = [];
  @Input() color = WarningColors.RED;
  @Input() centerText = true;
  @Input() roundedBorder = false;
  @Input() showIcon = false;
  @Input() showBulletPoint = false;

  public colors = WarningColors;
}
