import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';

@Component({
  selector: 'json-table',
  templateUrl: './json-table.component.html',
  styleUrls: [ './json-table.component.scss' ],
})
export class JsonTableComponent implements OnInit {
  @Input() public jsonString: string;
  @Input() public formatDate: Boolean = false;

  public keyValuePairs: Array<{ key: string, value: string }>;

  constructor(
    private readonly datepipe: DatePipe,
    private readonly i18n: I18nService,
    private readonly noticeService: NoticeService
  ) { }

  ngOnInit(): void {
    this.parseJsonToKeyValuePairs();
  }

  public copyToClipboard(value: string): void {
    navigator.clipboard.writeText(value);
    this.noticeService.notifyUser(new Notice('common.action.copied', NoticeLevels.SNACK));
  }

  private flattenObject(obj: Object, prefix = '', result = {}): Object {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const newKey = prefix ? `${prefix}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          this.flattenObject(obj[key], newKey, result);
        } else {
          let value = obj[key];
          if (this.formatDate && (typeof(value) === "string" || typeof(value) === "number")) {
            try {
              value = this.formatWithDatePipe(value);
            } catch {}
          }
          result[newKey] = value;
        }
      }
    }
    return result;
  }

  private parseJsonToKeyValuePairs(): void {
    if (this.jsonString) {
      const jsonObject = this.flattenObject(JSON.parse(this.jsonString));
      const keyValuePairs = Object.entries(jsonObject).map(([ key, value ]) => ({ key, value }));
      keyValuePairs.sort((a, b) => a.key.localeCompare(b.key));
      this.keyValuePairs = keyValuePairs;
    }
  }

  private formatWithDatePipe(value: string | number) {
    const actualDate = new Date();
    const date = new Date(value);
    if (Math.abs(actualDate.getTime() - date.getTime()) < 15778800000) {
      const formattedDate = this.datepipe.transform(date, "longDate", null, this.i18n.currentLocale === 'fr' ? 'fr-ca' : 'en');
      const separator = this.i18n.currentLocale === 'fr' ? 'à' : 'at';
      const time = this.datepipe.transform(date, "HH:mm:ss.SSS");

      return `${formattedDate} ${separator} ${time}`;
    }
    return value;
  }
}
