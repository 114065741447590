import { Component, Input, OnInit } from "@angular/core";

import { SeverityBadge } from "./severityBadge";


@Component({
  selector: 'ui-severity-badge',
  templateUrl: './ui-severity-badge.component.html',
  styleUrls: [ './ui-severity-badge.component.scss' ],
})
export class UiSeverityBadgeComponent implements OnInit {
  @Input()
    severityBadge: SeverityBadge;

  constructor() { }

  ngOnInit(): void {
  }
}
