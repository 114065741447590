import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { EcoSessionState } from './state/session.state';
import { AuthGuard } from './auth.guard';

@NgModule({
  imports: [
    NgxsModule.forFeature([ EcoSessionState ]),
  ],
  declarations: [],
  exports: [],
  providers: [
    AuthGuard,
  ],
})
export class SessionModule {
}

