import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-toggle-list',
  templateUrl: './ui-toggle-list.component.html',
  styleUrls: [ './ui-toggle-list.component.scss' ],
})
export class UiToggleListComponent implements OnInit {
  @Input() public toggleChoices: any[] = [];

  @Input() public actionRequiredAmount: number;

  @Output('onOptionClick') onOptionClick = new EventEmitter();

  ngOnInit(): void {
    this.toggleChoices[0].selected = true;
    this.onOptionClick.emit(this.toggleChoices[0]);
  }

  onButtonClick(optionClicked: any) {
    for (const toggle of this.toggleChoices) {
      toggle.selected = false;
    }
    optionClicked.selected = true;
    this.onOptionClick.emit(optionClicked);
  }
}
