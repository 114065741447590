import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { Subscription } from "rxjs";

import { prune } from "../../services/utils";
import { UiTab } from "./ui-tab.component";
import { UiTabsActionsService } from "./ui-tabs-actions/services/ui-tabs-actions.service";

const name = 'ui-tabs';

export enum UiTabsColor {
  Secondary = 'secondary',
  Primary = 'primary',
  ListAction = 'listAction',
  DrawerTabs = 'drawerTabs',
  Highlight = 'highlight',
  Transparent = 'transparent',
  lightTabs = 'lightTabs',
  widgetTabs = 'widgetTabs',
  lightGrey = 'lightGrey',
  TeamsTabs = 'teamsTabs',
  DashboardTabs = 'dashboardTabs'
}

@Component({
  selector: 'ui-tabs',
  templateUrl: './ui-tabs.component.html',
  styleUrls: [ './ui-tabs.component.scss' ],
  providers: [ UiTabsActionsService ],
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: false,
})
export class UiTabs implements OnInit, OnChanges, OnDestroy {
  @Input() public class: string;

  @Input() public color: UiTabsColor;

  @Input() public borderBelowHeader = false;

  @Input() public hasSmallHeader = false;

  @Input() public hasNoLeftPadding = false;

  @Input() public tabsTitle: string;

  @Input() public hasWhiteBackroundColor: boolean = true;

  @Input() public buttonContainerHasWhiteBackroundColor: boolean = false;

  @Input() public buttonContainerHasBottomMargin: boolean = false;

  @Input() public defaultSelectedTabIndex = 0;

  @Input() public defaultSelectedTabName = "";

  @Output() public tabClicked: EventEmitter<UiTab> = new EventEmitter<UiTab>();

  @Output() public onActionButtonClicked: EventEmitter<void> = new EventEmitter();

  @HostBinding('class') public classes: string;

  // NONONO on importe pas ca dans le uikit remplacez moi ca par un input
  // @Select(DrawerState.isEditing) public isEditing$: Observable<boolean>;

  public tabs: UiTab[] = [];

  public underscore: string;

  private editingInputSubscription: Subscription;

  constructor(private readonly cdr: ChangeDetectorRef, readonly uiTabsActionsService: UiTabsActionsService) { }

  public ngOnDestroy() {
    this.editingInputSubscription?.unsubscribe();
  }

  public ngOnInit(): void {
    this.setup();
    this.selectDefaultTab();
  }

  public ngOnChanges(): void {
    this.setup();
  }

  public get maxIndex(): number {
    return this.tabs.length - 1;
  }

  public setup(): void {
    switch (this.color) {
      case UiTabsColor.Primary:
      case UiTabsColor.Secondary:
        this.underscore = `${name}--underscore-primary`;
        break;
      case UiTabsColor.lightGrey:
      case UiTabsColor.ListAction:
        this.underscore = `${name}--underscore-list-action`;
        break;
      case UiTabsColor.DrawerTabs:
      case UiTabsColor.lightTabs:
        this.underscore = `${name}--underscore-drawer`;
        break;
      case UiTabsColor.Highlight:
        this.underscore = `${name}--underscore-highlight`;
        break;
      case UiTabsColor.Transparent:
        this.underscore = `${name}--underscore-primary`;
        break;
      case UiTabsColor.widgetTabs:
        this.underscore = `${name}--underscore-widget`;
        break;
      case UiTabsColor.TeamsTabs:
        this.underscore = `${name}--underscore-app-teams`;
        break;
      case UiTabsColor.DashboardTabs:
        this.underscore = `${name}--underscore-dashboard`;
        break;
      default:
        this.underscore = `${name}--underscore-primary`;
    }

    const classes = {
      [name]: true,
      [`${name}_${this.color}`]: true,
    };

    if (this.class) {
      classes[this.class] = true;
    }

    this.classes = Object.keys(prune(classes)).join(' ');
  }

  public refresh() {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 1);
  }

  public selectTabIndex(index: number) {
    setTimeout(() => {
      try {
        this.clearActiveTab();

        if (index < this.tabs.length && index >= 0) {
          this.tabs[index].active = true;
        } else {
          this.tabs[0].active = true;
        }

        this.cdr.detectChanges();
      } catch (e) { }
    }, 1);
  }

  public getSelectedTabIndex(): number {
    let index = 0;
    for (const tab of this.tabs) {
      if (tab.active) {
        return index;
      }
      index++;
    }
    return -1;
  }

  public getSelectedTabName(): string {
    return this.tabs.find((tab) => !!tab.active)?.tabName;
  }

  public selectTab(selection: UiTab, shouldEmitEvent = true) {
    this.clearActiveTab();
    selection.active = true;
    if (shouldEmitEvent) {
      this.tabClicked.emit(selection);
    }
  }

  public selectTabName(selection: string) {
    setTimeout(() => {
      this.clearActiveTab();

      this.tabs[this.tabs.map((e) => e.tabName).indexOf(selection)].active = true;
      this.cdr.detectChanges();
    }, 1);
  }

  public addTab(tab: UiTab) {
    if (this.tabs.length === 0) {
      tab.active = true;
    }
    this.tabs.push(tab);
    this.refresh();
  }

  public removeTab(tabToRemove: UiTab) {
    this.tabs = this.tabs.filter((item) => item.id !== tabToRemove.id);
    if (this.tabs.length > 0) {
      this.selectTab(this.tabs[0], false);
    }
  }

  public onActionsClick() {
    this.uiTabsActionsService.showActions();
  }

  public selectNextTab() {
    const index = this.getSelectedTabIndex();
    const nextIndex = index < this.maxIndex && index >= 0 ? index + 1 : 0;
    const tab = this.tabs[nextIndex];
    this.selectTab(tab);
  }

  public selectPreviousTab() {
    const index = this.getSelectedTabIndex();
    const previousIndex = index - 1 < 0 ? this.maxIndex : index - 1;
    const tab = this.tabs[previousIndex];
    this.selectTab(tab);
  }

  private clearActiveTab() {
    this.tabs.forEach((tab) => {
      tab.active = false;
    });
  }

  private selectDefaultTab() {
    if (this.defaultSelectedTabName) {
      this.selectTabName(this.defaultSelectedTabName);
    } else {
      this.selectTabIndex(this.defaultSelectedTabIndex);
    }
  }
}
