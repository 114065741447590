import { OrganizationPublicInfo } from "projects/@common/definitions/organization-public-info.definition";
import { TimestampTypes } from "projects/@respond/incident/components/incidents-details/components/chronology-tab/definitions/chronologyTimestamp.definitions";

import { RequestDirection } from "projects/@common/definitions/consoles.enum";
import { ResponseUtils } from "projects/@common/utils/response-utils";
import { Observable } from "rxjs";
import { IAlert } from "../alerts/alerts.definitions";
import { GetIncidentAlertsRequest } from "../alerts/models/getIncidentAlertsRequest";
import { IEntityRepresentation } from "../entities/entities.definition";
import { IDescribeIncidentTask, IincidentTaskMessage, IncidentDescription, IncidentTaskStatus } from "../incidentTasks/incidentTasks.definitions";
import { IDaysTypeEnum, IWorkShiftEnum } from "../kpi/kpi.definitions";
import { IObservable } from "../observables/observables.definition";
import { PlaybookTaskPhases } from "../playbooks/playbooks.definitions";
import { Whitelist } from "../whitelists/models/Whitelist";
import { IWhitelistRepresentation } from "../whitelists/whitelists.definitions";
import { IncidentStatsHistoryDto, IncidentStatsHistoryRequest } from "./models/getIncidentStatsHistory.definition";
import { Incident } from "./models/incident";
import { IncidentsStats } from "./models/incidentsStats";
import { EscalationTimeChoicesEnum } from "../communication/communication.definitions";


export enum IncidentTaskPhases {
  IDENTIFICATION = 'IDENTIFICATION',
  CONTAINMENT = 'CONTAINMENT',
  REMEDIATION = 'REMEDIATION',
  RECOVERY = 'RECOVERY',
  POSTMORTEM = 'POSTMORTEM'
}

export interface ICreateIncidentRequest {
  alertIds: string[];
  organizationId: string;
  playbookId: string;
  ownerId: string;
  name: string;
  summary: string;
}

export interface IUpdateIncidentRequest {
  id: string;
  name?: string;
  status?: IncidentStatus;
  additionalInfo?: string;
  severity?: number;
  zohoTicket?: string;
  zohoTicketUrl?: string;
  summary?: string;
  collaborationTime?: number;
  breachOfConfidentiality?: boolean;
  affectedResources?: string;
}

export interface UpdateIncidentTimestampRequest {
  id: string;
  incidentId: string;
  timestamp: number;
  incidentTimestamp?: IIncidentTimestamp;
}

export interface DeleteIncidentTimestampRequest {
  id: string;
  incidentId: string;
}

export interface CreateIncidentTimestampRequest {
  type: TimestampTypes;
  timestamp: number;
  incidentId: string;
}

export interface IListIncidentsFilters {
  language?: string;
  ownerIds?: string[];
  connectors?: string[];
  incidentId?: string;
  organizationIds?: string[];
  usecaseId?: string;
  incidentName?: string;
  shortId?: string;
  priorities?: number[];
  severities?: number[];
  dateFrom?: number;
  dateTo?: number;
  useUpdatedAtDate?: boolean;
  statuses?: IncidentFilterStatusEnum[];
  indicators?: IncidentFilterIndicatorsEnum[];
  dayTypes?: IDaysTypeEnum[];
  workShifts?: IWorkShiftEnum[];
  from?: number;
  size?: number;
  observable?: string;
}

export interface IListIncidentRequest {
  language?: string;
  ownerIds?: string[];
  connectors?: string[];
  incidentId?: string;
  organizationIds?: string[];
  usecaseIds?: string[];
  incidentName?: string;
  shortId?: string;
  priorities?: number[];
  severities?: number[];
  dateFrom?: number;
  dateTo?: number;
  useUpdatedAtDate?: boolean;
  statuses?: IncidentFilterStatusEnum[];
  dayTypes?: IDaysTypeEnum[];
  workShifts?: IWorkShiftEnum[];
  from?: number;
  size?: number;
  breachOfConfidentiality?: boolean;
  withAsset?: boolean;
  withWhitelistedAlert?: boolean;
  direction?: RequestDirection.desc;
  orderBy?: ListIncidentsOrderByEnum;
  observable?: string;
}

export interface ListOwnersResponse {
  ownerId: string;
  ownerName: string;
}

export enum ListIncidentsOrderByEnum {
  name = 'name',
  shortId = 'shortId',
  priority = 'priority',
  severity = 'severity',
  createdAt = 'createdAt',
  updatedAtAt = 'updatedAtAt',
  status = 'status',
  organization = 'organization', // back-end enum
  organizationName = 'organizationName', // front-end filter enum
  ownerName = 'ownerName',
  orderUpdatedAtStatus = 'orderUpdatedAtStatus'
}

export enum IncidentPriorityEnumDisplay {
  P1 = '1',
  P2 = '2',
  P3 = '3',
  P4 = '4'
}

export enum IncidentPriorityEnum {
  P1 = 1,
  P2 = 2,
  P3 = 3,
  P4 = 4
}

export type IRemoveAlertFromIncidentResponse = {
  completed: boolean;
  error?: RemoveAlertFromIncidentErrorEnum;
};

export enum RemoveAlertFromIncidentErrorEnum {
  LAST_ALERT = 'LAST_ALERT'
}

export enum IncidentFilterIndicatorsEnum {
  CRITICAL_ASSET = 'CRITICAL_ASSET',
  CONFIDENTIALITY_BREACH = 'CONFIDENTIALITY_BREACH',
  WHITELISTED_ALERT = 'WHITELISTED_ALERT'
}

export enum IncidentStatus {
  OPEN = 'OPEN',
  FINALIZING = 'FINALIZING',
  CLOSED_FALSE_POSITIVE = 'CLOSED_FALSE_POSITIVE',
  CLOSED_TEST = 'CLOSED_TEST',
  CLOSED_EXPECTED = 'CLOSED_EXPECTED',
  CLOSED = 'CLOSED',
  ABANDONED = 'ABANDONED'
}

export enum IncidentFilterStatusEnum {
  OPEN = 'OPEN',
  FINALIZING = 'FINALIZING',
  CLOSED_FALSE_POSITIVE = 'CLOSED_FALSE_POSITIVE',
  CLOSED_TEST = 'CLOSED_TEST',
  CLOSED_EXPECTED = 'CLOSED_EXPECTED',
  CLOSED = 'CLOSED'
}

export enum IncidentCloseStatus {
  CLOSED_FALSE_POSITIVE = 'CLOSED_FALSE_POSITIVE',
  CLOSED_TEST = 'CLOSED_TEST',
  CLOSED_EXPECTED = 'CLOSED_EXPECTED',
  CLOSED = 'CLOSED'
}

export enum ResolveIncidentAction {
  CLOSE_FALSE_POSITIVE = 'CLOSE_FALSE_POSITIVE',
  CLOSE_TEST = 'CLOSE_TEST',
  CLOSE_EXPECTED = 'CLOSE_EXPECTED',
  CLOSE = 'CLOSE',
  REOPEN = 'REOPEN'
}

export interface IIncidentTimestamp {
  id?: string;
  incidentId?: string;
  type: TimestampTypes;
  timestamp: number;
}

export interface IIncidentSystemTimestamp {
  startedAt: number;
  closedAt: number;
}

export interface IIncidentSystemTimestamps {
  IDENTIFICATION: IIncidentSystemTimestamp;
  CONTAINMENT: IIncidentSystemTimestamp;
  REMEDIATION: IIncidentSystemTimestamp;
  RECOVERY: IIncidentSystemTimestamp;
  POSTMORTEM: IIncidentSystemTimestamp;
}

export type IIncidenthistoryTimestamp = {
  action: string;
  timestamp: number;
};

export interface IDescribeIncidentResponse {
  incident: IIncident;
  organization: OrganizationPublicInfo;
  tasks: IIncidentTask[];
  firstAlertTimestamp: number;
  timestamps: IIncidentTimestamp[];
  historyTimestamps: IIncidenthistoryTimestamp[];
  resolveIncidentActions: ResolveIncidentAction[];
  phaseTimestamps: IIncidentSystemTimestamps;
  reportAvailable?: boolean;
  whitelistedAlertCount?: number;
  whitelists?: IWhitelistRepresentation[];
  analysts?: IIncidentAnalyst[];
  usecaseIds?: string[];
  escalationScheduleHtml?: string;
}

export interface IExportIncidentsResponse {
  incident: IIncident;
  organization: OrganizationPublicInfo;
  firstAlertTimestamp: number;
  timestamps: IIncidentTimestamp[];
  historyTimestamps: IIncidenthistoryTimestamp[];
  phaseTimestamps: IIncidentSystemTimestamps;
  whitelistedAlertCount?: number;
  usecases: { id: string; templateId: string, usecaseCatalogId: string; datasourceType: string, affectedResources: string }[];
}

export interface IIncidentTask {
  incidentId: string;
  taskId: string;
  taskVersion: number;
  ignored: boolean;
  hidden: boolean;
  order: number;
  phase: PlaybookTaskPhases;
  status: any;

  playbookTask: Partial<any>;
}

export interface IIncident {
  id: string;
  shortId: string;
  name: string;
  status: IncidentStatus;
  priority: number;
  severity: number;

  organizationId: string;
  usecaseIds: string[];
  playbookId: string;

  summary: string;
  additionalInfo: string;

  playbookParams: any;
  zohoTicket?: string;
  zohoTicketUrl?: string;

  ownerId: string;
  ownerName: string;
  createdById: string;
  createdByName: string;
  organizationName: string;

  createdAt?: number;
  updatedAt?: number;
  firstAlertTimestamp?: number;
  clientNotifiedAt?: number;
  containedAt?: number;
  remediatedAt?: number;

  closedAt?: number;
  manualClosedAt?: number;
  lastClosedAt?: number;
  lastManualClosedAt?: number;

  tasks: IDescribeIncidentTask[];

  timestamps?: IIncidentTimestamp[];
  phaseTimestamps: IIncidentSystemTimestamps;
  collaborationTime?: number; // in minutes

  organizationEcoId?: string;

  closeStatus?: IncidentCloseStatus;
  resolveIncidentActions?: ResolveIncidentAction[];

  breachOfConfidentiality?: boolean;

  analysts?: IIncidentAnalyst[];
  whitelistedAlertCount?: number;
  whitelists?: Whitelist[];
  alertCount?: number;
  assetCount?: number;

  affectedResources?: string;

  conversationId?: string;
  clientLastAccessedAt?: number;
  socLastAccessedAt?: number;

  timeToNotify?: number;
  timeToFinalize?: number;
  firstEventTimestamp?: number | null;
  isLate: boolean;
}

export type IIncidentStats = {
  organizationId: string;
  organizationName?: string;
  open: number;
  closed: number;
  closedFalsePositive: number;
  closedTest: number;
};

export interface IUpdateIncidentStatusRequest {
  id: string;
  status: ResolveIncidentAction;
  collaborationTime?: number;
  severity?: number;
  comment?: string;
  manualClosedAt?: number;
  summary?: string;
  justifications?: IJustification[];
}

export interface IJustification {
  type: string;
  key: string;
  value: string;
}

export interface ISocAnalystUser {
  id: string; // id de notre base de donnée
  displayName: string;
  mail: string;
  idpIdentifier: string; // unique id du user selon le identity provider (ex: microsoft user id)
}
export interface IUpdateIncidentPriorityRequest {
  id: string;
  priority: IncidentPriorityEnum;
  comment: string;
}

export type IUpdateIncidentStatusResponse = IDescribeIncidentResponse;

export interface IAddIncidentAnalystRequest {
  incidentId: string;
  userId: string;
}

export interface IDeleteIncidentAnalystRequest {
  incidentId: string;
  userId: string;
}

export interface IIncidentAnalyst {
  userId: string;
  displayName: string;
};

export interface IIncidentEscalationList {
  anytime?: IIncidentEscalationListLevels;
  businessHours?: IIncidentEscalationListLevels;
  offHours?: IIncidentEscalationListLevels;
};

export interface IIncidentEscalationListLevels {
  id: string;
  name: string;
  escalationLevels: EscalationLevel[];
}

export interface EscalationLevel {
  level: number;
  contacts: EscalationLevelContact[];
  delay?: EscalationTimeChoicesEnum;
  nextBusinessDay?: boolean;
  notifyAt?: string;
  remindAt?: Date;
}

export interface SelectedContactCommunicationMethods {
  id: string;
  type: CommunicationMethodType;
  properties: MethodType;
}

export type MethodType = EmailMethod | OpsGenieMethod | OtherMethod | SMSMethod | PhoneCallMethod;

export interface EmailMethod {
  email: string;
}

export interface OpsGenieMethod {
  // secretId?: string;
  // responders?: string;
}

export interface OtherMethod {
  note: string;
}

export interface PhoneCallMethod {
  phoneNumber: string;
  ext?: string;
}

export interface SMSMethod {
  phoneNumber: string;
}

export interface EscalationLevelContact {
  id: string;
  name: string;
  selectedCommunicationMethods: SelectedContactCommunicationMethods[];
}

export enum CommunicationMethodType {
  SMS = "SMS",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  OPSGENIE = "OPSGENIE",
  OTHER = "OTHER"
}

export enum EscalationListScheduleEnum {
  ANYTIME = 'anytime',
  BUSINESS_HOURS = 'businessHours',
  OFF_HOURS = 'offHours'
}

export type IListEscalationParametersRequest = {
  organizationId: string;
};

export interface IComputedEscalation extends ICannedMessage {
  currentLevel: number;
  escalatedAt: number;
  incidentId: string;
  levels: EscalationLevel[];
  remindAt: string;
  status: string;
  title: string;
}

export interface ICannedMessage {
  cannedMessage: string
}

export interface IIncidentMessage {
  id: string;
  conversationId: string;
  senderId: string;
  senderName: string;
  content: string;
  sentAt: number;
  metadata: any;
}

export interface IIncidentApi {
  listIncidents(request: IListIncidentRequest): Observable<ResponseUtils<IIncident>>;

  createIncident(request: ICreateIncidentRequest): Promise<IIncident>;

  updateIncident(organizationId: string, request: IUpdateIncidentRequest): Observable<IDescribeIncidentResponse>;

  getOwners(): Observable<ListOwnersResponse[]>;

  getIncidentFromAlert(organizationId: string, alertId: string): Promise<IDescribeIncidentResponse>;

  getAlertsFromIncident(request: GetIncidentAlertsRequest): Observable<ResponseUtils<IAlert>>;

  describeIncident(organizationId: string, incidentId: string): Observable<Incident>;

  getIncidentsStats(): Observable<IncidentsStats[]>;

  getIncidentsStatsHistory(request: IncidentStatsHistoryRequest): Promise<IncidentStatsHistoryDto[]>;

  associateAlerts(organizationId: string, incidentId: string, alertIds: string[], comment?: string): Promise<IIncident>;

  dissociateAlert(organizationId: string, alertId: string): Promise<IRemoveAlertFromIncidentResponse>;

  updateIncidentTimestamp(organizationId: string, request: UpdateIncidentTimestampRequest): Observable<IDescribeIncidentResponse>;

  createIncidentTimestamp(organizationId: string, request: CreateIncidentTimestampRequest): Observable<IDescribeIncidentResponse>;

  deleteIncidentTimestamp(organizationId: string, request: DeleteIncidentTimestampRequest): Observable<IDescribeIncidentResponse>;

  updateIncidentStatus(organizationId: string, request: IUpdateIncidentStatusRequest): Observable<IDescribeIncidentResponse>;

  updateIncidentPriority(organizationId: string, request: IUpdateIncidentPriorityRequest): Observable<IDescribeIncidentResponse>;

  addIncidentAnalyst(organizationId: string, request: IAddIncidentAnalystRequest): Observable<IDescribeIncidentResponse>;

  deleteIncidentAnalyst(organizationId: string, request: IDeleteIncidentAnalystRequest): Observable<IDescribeIncidentResponse>;

  exportIncidents(request: IListIncidentRequest): Observable<ResponseUtils<IExportIncidentsResponse>>;

  listSimilarItems(organizationId: string, request: ListIncidentSimilarItemsRequest): Observable<ResponseUtils<IncidentSimilarityItem>>;

  listIncidentActions(organizationId: string, incidentId: string): Observable<ResponseUtils<IIncidentActionRepresentation>>;

  listIncidentObservables(organizationId: string, incidentId: string): Observable<ResponseUtils<IObservable>>;

  listIncidentEntities(organizationId: string, incidentId: string): Observable<ResponseUtils<IEntityRepresentation>>;

  updateTaskStatus(organizationId: string, request: { incidentId: string, id: string, status: IncidentTaskStatus; }): Observable<IncidentDescription>;

  updateTask(organizationId: string, request: { incidentId: string, id: string, comment: string; }): Observable<IncidentDescription>;

  postTaskObservables(organizationId: string, request: IPostTaskObservablesRequest): Observable<IDescribeIncidentResponse>;

  postTaskEntities(organizationId: string, request: IPostTaskEntitiesRequest): Observable<IDescribeIncidentResponse>;

  postTaskActions(organizationId: string, request: IPostTaskActionsRequest): Observable<IDescribeIncidentResponse>;

  deleteTaskObservables(organizationId: string, request: IDeleteTaskObservablesRequest): Observable<IDescribeIncidentResponse>;

  deleteTaskEntities(organizationId: string, request: IDeleteTaskEntitiesRequest): Observable<IDescribeIncidentResponse>;

  deleteTaskActions(organizationId: string, request: IDeleteTaskActionsRequest): Observable<IDescribeIncidentResponse>;

  deleteIncidentAction(organizationId: string, request: IDeleteIncidentActionRequest): Observable<IDescribeIncidentResponse>;
}

export type ListIncidentSimilarItemsRequest = {
  incidentId: string;
  sameOrganization?: boolean;
  type?: SimilarityItemTypeEnum;
  from?: number;
  size?: number;
};

enum SimilarityItemTypeEnum {
  ALERTS = 'alerts',
  INCIDENTS = 'incidents'
};

export type IncidentSimilarityItem = {
  type: SimilarityItemTypeEnum;
  name: string;
  createdAt: number;
  status: string;
  shortId: string;
  id: string;
  score: number;
  canAssociate: boolean;
  sameOrganization: boolean;
  samePlaybook: boolean;
  sameUsecase: boolean;
} & OrganizationPublicInfo;

export enum ActionCreatedBy {
  PIPELINE = 'PIPELINE',
  USER = 'USER'
}

export interface IIncidentActionRepresentation {
  id?: string;
  action: string; // action name to find in toolbox available actions list
  incidentId: string;
  entityId?: string;
  name: string;
  description: string;
  entities: any;
  payload: any;
  parameters: any;
  observables: any[];
  observablesConfig: any;
  createdBy: ActionCreatedBy;
  createdAt?: number;
  updatedAt?: number;
  justification?: string;
}

export interface IPostTaskObservablesRequest {
  incidentId: string;
  incidentTaskId: string;
  incidentObservableId: string;
  action?: "add";
}

export interface IPostTaskEntitiesRequest {
  incidentId: string;
  incidentTaskId: string;
  incidentEntityId: string;
  action?: "add";
}

export interface IPostTaskActionsRequest {
  incidentId: string;
  incidentTaskId: string;
  incidentActionId: string;
  action?: "add";
}

export type IPostTaskMessagesRequest = IincidentTaskMessage;
export interface IDeleteTaskObservablesRequest {
  incidentId: string;
  incidentTaskId: string;
  incidentObservableId: string;
  action?: "remove";
}

export interface IDeleteTaskActionsRequest {
  incidentId: string;
  incidentTaskId: string;
  incidentActionId: string;
  action?: "remove";
}

export interface IDeleteTaskEntitiesRequest {
  incidentId: string;
  incidentTaskId: string;
  incidentEntityId: string;
  action?: "remove";
}

export interface IDeleteTaskMessageRequest {
  incidentId: string;
  incidentTaskId: string;
  incidentMessageId: string;
  action?: "remove";
}

export interface IDeleteIncidentActionRequest {
  incidentId: string;
  incidentActionId: string;
}

export interface IUpdateIncidentActionRequest {
  incidentAction: IIncidentActionRepresentation;
  incidentId: string;
}
