import { Directive, Input, OnChanges, OnDestroy } from "@angular/core";
import { Store } from "@ngxs/store";

import { SetComponentState } from "./stores/drawer.store";

@Directive()
export class AbstractDrawer implements OnChanges, OnDestroy {
  @Input('state') public state: any = null;

  @Input('previousDrawerData') public previousDrawerData: any = null;

  @Input('index') public index: number = null;

  constructor(public _store: Store) {}

  public ngOnChanges() {
    if (this.state) {
      this.setState(this.state);
    }
  }

  public ngOnDestroy() {
    this._store.dispatch(new SetComponentState({
      state: this.getState(),
      index: this.index,
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setState(_state: any): void {}

  public getState(): any {
    return {};
  }
}
