import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { I18nService } from '../i18n/i18n.service';
import {
  ConfigurationBaseTemplate,
  DatasourceConfigurationParameterTypes
} from '../../services/api/respond/datasources/datasources.definitions';
import { DatasourceFormInterface, UiTypeOfFormEnum } from 'projects/console-adm/src/app/shared/dynamic-form-template/dynamic-form-template.component';

@Injectable({
  providedIn: 'root',
})
export class FormCreationService {
  public formsToBuild: Array<DatasourceFormInterface> = [];

  public formGroup: UntypedFormGroup;

  constructor(private i18n: I18nService) {}

  public set currentformGroup(value: UntypedFormGroup) {
    this.formGroup = value;
  }

  public generateForm(key: string, config: ConfigurationBaseTemplate): void {
    this.formsToBuild.push(this.uiFormMapper(key, config));
  }

  public resetCurrentForms(): void {
    this.formsToBuild = [];
  }

  public getOrganizationIdFromName(organizationName: string, organizationList: Record<string, string>): string {
    for (const organization in organizationList) {
      if (organizationList[organization] === organizationName) {
        return organization;
      }
    }
  }

  private uiFormMapper(key: string, config: ConfigurationBaseTemplate): DatasourceFormInterface {
    let initialValue = { label: null, value: null };
    const typeOfForm = this.getTypeOfForm(config);

    if (typeOfForm === UiTypeOfFormEnum.DROPDOWN && config.default) {
      initialValue = {
        value: config.default,
        label: config.values.find((item) => item.value === config.default).label[this.i18n.currentLocale],
      };
    }

    return {
      label: config.label,
      description: config.description,
      required: config.required,
      controlName: key,
      typeOfForm,
      initialValue,
      group: this.formGroup,
      values: config?.values?.map((value) => ({
        label: value.label[this.i18n.currentLocale],
        value: value.value,
      })),
    };
  }

  private getTypeOfForm(config: ConfigurationBaseTemplate): UiTypeOfFormEnum {
    switch (true) {
      case config.type === DatasourceConfigurationParameterTypes.STRING && !!config.values:
        return UiTypeOfFormEnum.DROPDOWN;
      case config.type === DatasourceConfigurationParameterTypes.STRING && !config.values:
        return UiTypeOfFormEnum.TEXT_INPUT;
      case config.type === DatasourceConfigurationParameterTypes.BOOLEAN:
        return UiTypeOfFormEnum.CHECKBOX;
      case config.type === DatasourceConfigurationParameterTypes.NUMBER:
        return UiTypeOfFormEnum.NUMBER_INPUT;
      case config.type === DatasourceConfigurationParameterTypes.OBJECT:
        return UiTypeOfFormEnum.OBJECT_TEXTAREA;
      case config.type === DatasourceConfigurationParameterTypes.STRING_LIST:
        return UiTypeOfFormEnum.ARRAY_TEXTAREA;
      case config.type === DatasourceConfigurationParameterTypes.CHECKBOX:
        return UiTypeOfFormEnum.MULTI_SELECT;
      default:
        break;
    }
  }
}
