import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, OnInit, ViewEncapsulation } from "@angular/core";

import { prune } from "../../services/utils";

export const name = 'ui-badge';

export enum UiBadgeColor {
  Warning = 'warning'
}

@Component({
  selector: 'ui-badge',
  templateUrl: './ui-badge.component.html',
  styleUrls: [ './ui-badge.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UiBadge implements OnInit, OnChanges {
  @Input() public class: string;

  @HostBinding('class') public classes: string;

  @Input() public value: string | number | null = null;

  @Input() public max: number | null = null;

  @Input() public isDot = false;

  @Input() public isHidden = false;

  @Input() public withBorder = false;

  @Input() public color: UiBadgeColor = UiBadgeColor.Warning;

  public content: number | string | null;

  public get contentClasses(): any {
    return {
      [`${name}--content_${this.color}`]: true,
      [`${name}--content_dot`]: this.isDot,
      [`with-border`]: this.withBorder,
      [`without-border`]: !this.withBorder,
    };
  }

  public ngOnInit(): void {
    this.setup();
  }

  public ngOnChanges(): void {
    this.setup();
  }

  public setup(): void {
    this.content = this.computeContent();

    const classes = {
      [name]: true,
      [`${name}_hidden`]: this.isHidden,
    };

    if (this.class) {
      classes[this.class] = true;
    }

    this.classes = Object.keys(prune(classes)).join(' ');
  }

  public computeContent(): string | number | null {
    if (this.isDot) {
      return null;
    }

    const { value, max } = this;

    if (typeof value === 'number' && typeof max === 'number') {
      return max < value ? `${max}+` : value;
    }

    return value;
  }
}
