<div
  *ngIf="resources?.length != 0 && !isSearchingResources"
  class="choices-list"
  [ngStyle]='{"height": expandHeight}'
>
  <ng-container *ngFor="let resource of resources; let index = index; last as isLastChoice">
    <div [ngClass]="{ 'multi-select-autocomplete': canMultiSelect }">
      <div
        [id]="'rowElement_' + index"
        class="choice separator"
        (mousedown)="onResourceClick(resource, index, $event)"
        [ngClass]="{ 'choice--separator': !isLastChoice }"
        (mouseenter)="hoverElement(resource, true)"
        (mouseleave)="hoverElement(resource, false)"
      >
        <div class="choice-infos-row cursor">
          <div class="choice-infos-container-resource">
            <div class="row-icon">
              <img class="resource-type-avatar" [src]="getResourceIcon(resource)"/>
              <div *ngIf="resource['isHovered']" class="hovered-element-row">
                <ui-icon class="hovered-element-row--add-icon" name="plus" modifier="white" size="medium"></ui-icon>
              </div>
            </div>

            <div class="choices-infos">
              <div>{{ resource.displayName }}</div>
              <div *ngIf="groupsFilter !== groupTypeEnum.APPLICATION" class="email-part">
                {{ resource.description }}
              </div>
              <div *ngIf=" groupsFilter === groupTypeEnum.APPLICATION && resource.externalUrl" class="email-part">
                {{ resource.externalUrl }}
              </div>
            </div>
          </div>
          <span *ngIf="resource.isOwner" class="badge owner">{{ 'ressource.badge.owner' | t: {locale:locale} }}</span>
          <span *ngIf="!resource.isOwner" class="badge owner">{{ 'ressource.badge.member' | t: {locale:locale} }}</span>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div
  *ngIf="resources?.length === 0 && !isSearchingResources"
  class="choices-list no-items"
>
  <span
    *ngIf="!customNotFound"
    class="no-items--label"
  >
    <i>{{ 'group.detail.owners.notFound.name' | t:{locale:locale} }}</i>
  </span>

  <ng-content *ngIf="customNotFound"></ng-content>
</div>
