import { Component, Input } from "@angular/core";
import { LanguageEnum } from "../../interfaces/ILanguage";
import { DatePipeParams } from "../../pipes/dt.pipe";

@Component({
  selector: 'indicator-card',
  templateUrl: './indicator-card.component.html',
  styleUrls: [ './indicator-card.component.scss' ],
})
export class IndicatorCardComponent {
  @Input()
    logo?: string;

  @Input()
    name: string;

  @Input()
    createdAt: number;

  @Input()
    description: string;

  @Input()
    withBorder = false;

  @Input() public locale = LanguageEnum.FRENCH;

  public getDateParams(): any {
    const params: DatePipeParams = { locale: this.locale };
    params.mediumDate = true;
    return params;
  }
}

