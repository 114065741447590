<div class="confirmation-modal-container">
  <h2>{{ 'external-link.dialog.title' | t }}</h2>
  <p>
    {{ 'external-link.dialog.text.1' | t }}<a class="inactiveLink" [href]="data.url">{{ truncatedUrl }}</a
    >{{ 'external-link.dialog.text.2' | t }}<br /><br />
    {{ 'external-link.dialog.text.3' | t }}<br /><br />
    {{ 'external-link.dialog.text.4' | t }}
  </p>
  <div class="button-wrapper">
    <button ui-button color="standard" (click)="closeModal(true)">
      {{ 'external-link.dialog.button.accept' | t }}
    </button>
    <button ui-button (click)="closeModal(false)">{{ 'external-link.dialog.button.cancel' | t }}</button>
  </div>
</div>
