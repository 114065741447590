import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ApiEndpointPrefixEnum } from 'projects/@common/definitions/eco-api';
import { EcoUrlService } from '../../core/url.service';
import { CustomerIdentitiesApi } from './customer-identities.api';
import { DescribeIdpIdentity, IIdentityApi, IdentityProviderTypes, List, ListSecurityGroupResponse, ListUsersResponse } from './identities.api.definitions';
import { VarIdentitiesApi } from './var-identities.api';

@Injectable({
  providedIn: 'root',
})
export class IdentitiesApi implements IIdentityApi {
  protected prefix: ApiEndpointPrefixEnum = ApiEndpointPrefixEnum.iam;

  private customerService: CustomerIdentitiesApi;
  private varService: VarIdentitiesApi;

  constructor(
    protected store: Store,
    http: HttpClient,
    url: EcoUrlService
  ) {
    this.customerService = new CustomerIdentitiesApi(http, url);
    this.varService = new VarIdentitiesApi(http, url);
  }

  public async listUsers(organizationId: string, params: { order?: string; search?: string; filter?: string; }): Promise<List<ListUsersResponse, number | string, { idpType: IdentityProviderTypes; }>> {
    if (this.isVarMode) {
      return this.varService.listUsers(organizationId, params);
    }
    return this.customerService.listUsers(organizationId, params);
  }

  public async listSecurityGroup(params: { order?: string; search?: string; filter?: string; }): Promise<List<ListSecurityGroupResponse, number | string, { idpType: IdentityProviderTypes; }>> {
    if (this.isVarMode) {
      return this.varService.listSecurityGroup(params);
    }
    return this.customerService.listSecurityGroup(params);
  }

  public async getUserByO365Id(identityId: string): Promise<ListUsersResponse> {
    if (this.isVarMode) {
      return this.varService.getUserByO365Id(identityId);
    }
    return this.customerService.getUserByO365Id(identityId);
  }

  public async describeIdpIdentity(): Promise<DescribeIdpIdentity> {
    return this.customerService.describeIdpIdentity();
  }

  private get isVarMode(): boolean {
    return false;
  }
}
