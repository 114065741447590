import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { StorageService } from 'projects/@common/services/storage.service';
import { Decisions } from '../display.service';


export class SetPermissions {
  static readonly type = '[Display] SetPermissions';
  constructor(public payload: Decisions) { }
}

export class LoadDecisionsFromBrowser {
  static readonly type = '[Display] LoadDecisionsFromBrowser';
}

export class InvalidateData {
  static readonly type = '[Display] Invalidate data';
}

const defaultState = { 'always_hidden': false };

@State<Decisions>({
  name: 'display',
  defaults: defaultState,
})
@Injectable()
export class DisplayState {
  private readonly STORAGE_KEY = 'eco.display';

  constructor(private storage: StorageService) { }

  private isEmpty(state: Decisions) {
    return Object.keys(state).length <= Object.keys(defaultState).length;
  }

  @Selector()
  static permissions(state: Decisions) {
    return state;
  }

  @Action(SetPermissions)
  login(ctx: StateContext<Decisions>, action: SetPermissions) {
    ctx.patchState(action.payload);
    this.storage.setLocal(this.STORAGE_KEY, JSON.stringify(ctx.getState()));
  }

  @Action(LoadDecisionsFromBrowser)
  loadDecisionsFromBrowser(ctx: StateContext<Decisions>, action: SetPermissions) {
    const existing = this.storage.getLocal(this.STORAGE_KEY);
    if (existing && this.isEmpty(ctx.getState())) {
      const parsed = JSON.parse(existing);
      ctx.patchState(parsed);
    }
  }

  @Action(InvalidateData)
  invalidate(ctx: StateContext<Decisions>, action: InvalidateData) {
    ctx.setState(defaultState);
    this.storage.setLocal(this.STORAGE_KEY, '{}');
  }
}

