import { Injectable } from '@angular/core';
import { BrowserUtils } from '@azure/msal-browser';
import * as microsoftTeams from "@microsoft/teams-js";
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';

@Injectable({ providedIn: 'root' })
export class TeamsAuthService {
  public tabRoute: string;
  public locale: string;
  public context: microsoftTeams.app.Context;

  public static get isInTeams(): boolean {
    if (BrowserUtils.isInIframe()) {
      return true;
    }

    const microsoftTeamsLib = microsoftTeams || window["microsoftTeams"];
    if (!microsoftTeamsLib) {
      return false; // the Microsoft Teams library is for some reason not loaded
    }

    return !!((window.parent === window.self && (window as any).nativeInterface) ||
      window.name === "embedded-page-container" ||
      window.name === "extension-tab-frame");
  }

  public async init(): Promise<string> {
    await microsoftTeams.app.initialize();
    this.context = await microsoftTeams.app.getContext();
    this.tabRoute = this.context.page.id;
    this.locale = this.context.app.locale.includes('en') ? LanguageEnum.ENGLISH : LanguageEnum.FRENCH;
    return microsoftTeams.authentication.getAuthToken();
  }
}
