import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sign-in-microsoft',
  templateUrl: './sign-in-microsoft.component.html',
  styleUrls: [ './sign-in-microsoft.component.scss' ],
})
export class SignInMicrosoftComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
