<div class="relative" (click)="handleClick()">
  <button
    ui-button
    class="topbar-toggle--button"
    color="topbar"
    icon="menu"
    icon-size="medium"
    [class.not-visible]="
      (isDrawerExpanded$ | async) && mobileService.isMobile()
    "
  ></button>

  <ui-badge
    *ngIf="mobileService.isMobile()"
    class="topbar-notices--indicator"
    [class.not-visible]="
      (isDrawerExpanded$ | async) && mobileService.isMobile()
    "
    [isHidden]="(indicators.emails && indicators.notices) <= 0"
    [withBorder]="true"
  ></ui-badge>
</div>
