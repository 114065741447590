import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

export enum IconSideEnum {
  LEFT = 'left',
  RIGHT = 'right'
}

@Component({
  selector: 'ui-static',
  templateUrl: './ui-static.component.html',
  styleUrls: [ './ui-static.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-static',
  },
})
export class UiStatic implements OnInit {
  @Input() public label: string;

  @Input() public value: any;

  @Input() public htmlValue: any;

  @Input() public iconName: string;

  @Input() public iconModifier = 'primary';

  @Input() public isCustomIcon = false;

  @Input() public iconSide = IconSideEnum.LEFT;

  @Input() public isSvg = false;

  @Input() public iconSize: string;

  @Input() public user: any;

  @Input() public allowLineBreak = false;

  public iconSideEnum = IconSideEnum;

  constructor() { }

  public ngOnInit() {
    if (this.user && !this.value) {
      this.value = `${this.user.firstName} ${this.user.lastName}`;
    }
  }
}
