import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'mobile-top-sidebar',
  templateUrl: './mobile-top-sidebar.component.html',
  styleUrls: [ './mobile-top-sidebar.component.scss' ],
  host: {
    role: 'navigation',
  },
})
export class MobileTopSidebar implements OnInit {
  @Input() public indicators = { emails: 0, notices: 0 };

  @Output() public menuToggled: EventEmitter<void> = new EventEmitter();

  // public user: any;

  constructor(private readonly router: Router) {}

  public ngOnInit() {
    // this.user = this.store.selectSnapshot(state => state.session.user);
  }

  public blankToOutlook(): void {
    window.open('https://outlook.office365.com', '_blank');
  }

  public openNotificationDrawer() {
    this.closeMenu();

    // this.drawerService.showDrawer(NoticesList, {
    //   component: DrawerPageEnum.notificationDrawer,
    //   data: ''
    // });
  }

  public seeUserProfile() {
    // const organization = this.store.selectSnapshot(state => state.session.organization.name);

    // this.drawerService.showDrawer(AccountDrawer, {
    //   component: DrawerPageEnum.accountDrawer,
    //   data: { user: this.user, title: this.i18n.translate('account-drawer', { organization }) }
    // });
  }

  public closeMenu() {
    this.menuToggled.emit();
  }

  public logOut() {
    this.router.navigate([ 'signout' ]);
  }
}
