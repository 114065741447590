import { Component, ViewEncapsulation } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { MobileService } from 'projects/@common/services/mobile.service';
import { Observable } from 'rxjs';
import { DrawerSnack, SnackbarDrawerState, ThrowDrawerSnackInTrash } from '../state/snackbar-drawer.state';

@Component({
  selector: 'snackbar-drawer',
  templateUrl: './snackbar-drawer.component.html',
  styleUrls: [ './snackbar-drawer.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'snackbar-drawer',
  },
})
export class SnackbarDrawer {
  @Select(SnackbarDrawerState.snacks) public snacks$: Observable<DrawerSnack[]>;

  constructor(private store: Store, public mobileService: MobileService) {}

  public throwSnackInTrash(snack: DrawerSnack) {
    this.store.dispatch(new ThrowDrawerSnackInTrash(snack));
  }
}
