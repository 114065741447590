<!-- Hours picker -->
<div class="hour time-unit">
  <input
    #hourInputRef
    ui-input
    uiNumberOnlyInput
    class="hour-input time-unit--input"
    type="number"
    placeholder="hh"
    autocomplete="off"
    [disabled]="isDisabled"
    [readonly]="isReadonly"
    [attr.min]="0"
    [attr.max]="23"
    [ngModel]="hour?.viewValue"
    (ngModelChange)="setHour($event)"
    (focus)="handleInputFocus(inputTypes.HOURS, $event)"
    (blur)="handleInputBlur()"
  >
  <!-- Selectable options for hours -->
  <div
    *ngIf="showHourOptions && visibleSelectOptions?.length"
    class="time-unit--options-container"
    #hourOptionsRef
  >
    <ng-container *ngFor="let option of visibleSelectOptions">
      <div
        class="time-unit--options-container--option"
        [ngClass]="{'selected': option.value === hour?.value}"
        (mousedown)="handleHourOptionSelected(option)"
      >
        {{ option.viewValue }}
      </div>
    </ng-container>
  </div>
</div>

<span class="separator">:</span>

<!-- Minutes picker  -->
<div class="minute time-unit">
  <input
    #minuteInputRef
    ui-input
    uiNumberOnlyInput
    class="minute-input time-unit--input"
    type="number"
    placeholder="mm"
    autocomplete="off"
    [disabled]="isDisabled"
    [readonly]="isReadonly"
    [attr.min]="0"
    [attr.max]="59"
    [ngModel]="minute?.viewValue"
    (ngModelChange)="setMinute($event)"
    (focus)="handleInputFocus(inputTypes.MINUTES, $event)"
    (blur)="handleInputBlur()"
  >
  <!-- Selectable options for minutes  -->
  <div
    *ngIf="showMinuteOptions && visibleSelectOptions?.length"
    class="time-unit--options-container"
    #minuteOptionsRef
  >
    <ng-container *ngFor="let option of visibleSelectOptions">
      <div
        class="time-unit--options-container--option"
        [ngClass]="{'selected': option.value === minute?.value}"
        (mousedown)="handleMinuteOptionSelected(option)"
      >
        {{ option.viewValue }}
      </div>
    </ng-container>
  </div>
</div>