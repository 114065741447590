<div
  (mousedown)="copyValue()"
  class="copy-container"
>

  <div class="injected-content">
    <ng-content></ng-content>
  </div>

  <button
    class="copy-icon-button"
    ui-button
    size="normal"
    color="listActionFlatGray"
    [icon]="'copy'"
    [title]="('common.action.copy' | t)"
    [disabled]="!valueToCopy"
  ></button>

  <span
    class="tooltip"
    [class.showTooltip]="showTooltip"
  >{{'common.action.copied.short' | t}}</span>
</div>