import { Component } from "@angular/core";
import { Notice, NoticeLevels, NoticeService } from "projects/@common/modules/notice/notice.service";
import { clone } from "projects/@common/utils/utils";

import images from './images.json';

@Component({
  selector: 'images-bank',
  templateUrl: './images-bank.component.html',
  styleUrls: [ './images-bank.component.scss' ],
})
export class ImagesBankComponent {
  public filteredImages = clone(images);

  constructor(private noticeService: NoticeService) {
  }

  public async copyPath(image: string): Promise<void> {
    await navigator.clipboard.writeText(image);
    this.noticeService.notifyUser(new Notice(`${image} copied to clipboard.`, NoticeLevels.INFO));
  }

  public search(text: string) {
    this.filteredImages = images.filter((image) => image.includes(text));
  }
}
