import { Component, Input } from '@angular/core';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { FormCreationService } from '@common/modules/shared/form-creation.service';
import { DatasourceTemplateConfig } from '../datasource-creation-details/datasourceTemplateConfig';

@Component({
  selector: 'datasource-dynamic-form',
  templateUrl: './datasource-dynamic-form.component.html',
  styleUrls: [ './datasource-dynamic-form.component.scss' ],
})
export class DatasourceDynamicFormComponent {
  @Input()
  public templateConfig: DatasourceTemplateConfig;

  constructor(protected readonly i18n: I18nService, public uiFormCreationService: FormCreationService) {}

  setListValue(event: { controlName: string; value: any }): void {
    this.templateConfig.setListValue(event.controlName, event.value);
  }
}
