<ng-container *ngIf="isEditing; else static" [formGroup]="group">
  <label ui-label [required]="required">{{ label }}</label>

  <ng-container *ngIf="arrayName; else noArray" [formArrayName]="arrayName">
    <ng-container [formGroupName]="groupName">
      <textarea [rows]="rowsNumber" [formControlName]="control">
        {{ value }}
      </textarea>
    </ng-container>
  </ng-container>

  <ng-template #noArray>
    <textarea [rows]="rowsNumber" [formControlName]="control">
      {{ value }}
    </textarea>
  </ng-template>
</ng-container>

<ng-template #static>
  <label ui-label>{{ label }}</label>
  <pre>{{ value }}</pre>
</ng-template>