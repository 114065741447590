<div *ngIf="isLoaded">
  <autocomplete
    #autocomplete
    class="field-margin"
    [canMultiSelect]="false"
    [oneItemAtATime]="true"
    [label]="showLabel ? ('common.organization' | t) : null"
    [placeholder]="'common.search' | t"
    name="autocomplete"
    [initItems]="selectedItems"
    [showInInputAtInit]="true"
    [customValues]="organizationKeyValuePairs"
    [(selectedItems)]="selectedItems"
    [autocompleteType]="autocompleteType"
    (onItemSelected)="handleUserSelection($event)"
    (isSearchCleared)="organizationClear.emit()"
  ></autocomplete>
</div>