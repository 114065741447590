<div
  class="card-container"
  [ngStyle]="customHeaderStyle ? customHeaderStyle : null"
  [class.background-color-hover]="colorOnHover"
>
  <div
    class="card-header-content"
    [class.blue-background]="isOpen && stickyColor"
  >
    <button
      class="header"
      [class.no-pointer-cursor]="isOpen && preventCollapse"
      (click)="toggle()"
    >
      <ui-icon
        class="collapsable-card--header--chevron"
        modifier="on-header"
        [name]="!isOpen ? 'chevron-right' : 'chevron-down'"
        size="medium"
      ></ui-icon>
      <div class="header-content">
        <ng-content select="[header]"></ng-content>
      </div>
    </button>
    <div class="header-options">
      <ng-content select="[options]"></ng-content>
    </div>
  </div>
  <div
    class="content"
    *ngIf="isOpen"
  >
    <ng-content select="[content]"></ng-content>
  </div>
</div>