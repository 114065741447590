import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { LanguageEnum } from '../../../interfaces/ILanguage';
import { UiTableDirection } from '../../ui-table/ui-table.component';
import { AbstractAutocomplete } from '../AbstractAutocomplete';

interface User {
  // TODO: Standardize the User interface; adjust the front-end type to match the IAM back-end.
  // this was temporarily copied from /eco.webui/workspace/projects/@common/definitions/user.definition.ts
  // until we have more time to address this.
  guid: string;
  mail: string;
  firstName: string;
  lastName: null;
  o365UserId: string;
  officeUser: boolean;

  [otherProps: string]: unknown;
}

interface IamListUsersResponse {
  // copied from /eco.webui/workspace/projects/@common/services/api/iam/iam.api.definitions.ts
  total: number;
  nextItem: number;
  metadata: { idpType: string; };
  items: IdpUser[];
}

interface IdpUser {
  // copied from /svc.iam/app/src/modules/idp/identityProvider.ts
  id: string;
  displayName: string;
  mail: string;
  upn: string; // (userPrincipalName)
  type?: UserTypeEnum;
  users?: User[];
  [otherProps: string]: unknown;
}

export enum UserTypeEnum {
  GUEST = 'Guest',
  MEMBER = 'Member',
  SECURITY_GROUP = 'SecurityGroup'
}

@Component({
  selector: 'user-autocomplete',
  templateUrl: './user-autocomplete.component.html',
  styleUrls: [
    '../autocomplete.component.scss',
    './user-autocomplete.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'user-autocomplete',
  },
})
export class UserAutocomplete extends AbstractAutocomplete {
  @Input() public searchFunction: (args: any) => Promise<any>;
  @Input() public customNotFound: boolean;
  @Input() public usersFilter = null;
  @Input() public oneItemAtATime = false;
  @Input() public canMultiSelect = false;
  @Input() public showUserType = false;
  @Input() public usersToIgnore: any[] = [];
  @Input() public locale: LanguageEnum = LanguageEnum.FRENCH;
  @Input() public expandHeight = '20rem';
  @Input() public disabledLabel = 'user-autocomplete.disabled';

  @Output() public isSearching: EventEmitter<boolean> = new EventEmitter();
  @Output() public elementClicked: EventEmitter<any> = new EventEmitter();
  @Output() selectedUsersChange = new EventEmitter();
  @Output() loadedList = new EventEmitter();

  public selectedUsersValue: User[] = [];
  public users: User[] = [];
  public userSearchQuery = '';
  public userSearchQueryUpdate = new Subject<string>();
  public isSearchingUsers = false;

  constructor(private store: Store) {
    super();
  }

  @Input()
  get selectedUsers(): User[] {
    return this.selectedUsersValue;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set selectedUsers(value) {
    this.selectedUsersValue = value;
    this.selectedUsersChange.emit(this.selectedUsersValue);
  }

  public searchItems(query: string, searchID: string, useIAMBackend?: boolean) {
    this.setIsSearching(true);

    const exceptions: string[] = this.usersToIgnore.map((user) => user.guid || user.id);

    this.searchFunction({
      order: UiTableDirection.Asc,
      search: query,
      filter: this.usersFilter,
    })
      .then((users: IamListUsersResponse) => {
        if (this._currentSearchID === searchID) {
          this.loadedList.emit(users);
          this.users = users.items
            .filter((item) => !exceptions.includes(item.id))
            .map((user: any) =>
              // TODO: clarify the User interface and if the  API backend ListUsersResponse should return the mail property.
              // see project svc.iam listUsers.usecase.ts
              ({
                description: user?.description,
                guid: user.id,
                mail:
                  user.mail ||
                  user.upn ||
                  user.users?.map((user) => user.mail).toString().replace(',', ', '),
                firstName: user.displayName,
                lastName: null,
                userId: user?.mail, // TODO: this is null in the API so its currently useless
                o365UserId: user.id,
                identityId: user?.identityId,
                officeUser: true,
                type: user.type,
                disabled: user.disabled,
              } as User));
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (this._currentSearchID === searchID) {
          this.setIsSearching(false);
        }
      });
  }

  public onUserSelected(user: any, event?: MouseEvent): void {
    if (!user.isAlreadyClicked) {
      // Some components use IGroupUser instead of IUser
      user.userGuid = user.guid; // why not always use user.guid?
      if (!user?.mail) {
        user.mail = user.userId; // why? the user email should be defined at this point rather than doing this assignation
      }

      this.selectedUsersValue.push(user);

      if (this.canMultiSelect) {
        user.isAlreadyClicked = true;
        const filteredUsers = this.users.filter((u) => u.guid !== user.guid);
        this.users = filteredUsers;
      }

      this.elementClicked.emit({
        item: user,
        itemName: `${user.firstName}`,
      });
    }
    // prevent closing the dropdown if its a click event
    event?.stopPropagation();
    event?.preventDefault();
  }

  public onUserClick(
    clickedUser: any,
    elementIndex: number,
    mouseEvent: MouseEvent,
    userDisabled: boolean
  ) {
    if (!userDisabled) {
      this.onUserSelected(clickedUser, mouseEvent);
      this.addRippleEffect(elementIndex, mouseEvent);
    } else {
      event?.stopPropagation();
      event?.preventDefault();
    }
  }

  public isNotOnboarded(user: any): boolean {
    return (
      (!!user['disabledAt'] || user['disabled']) &&
      user.effectiveDate &&
      new Date().getTime() < user.effectiveDate
    );
  }

  private setIsSearching(isSearching: boolean) {
    this.isSearchingUsers = isSearching;
    this.isSearching.emit(this.isSearchingUsers);
  }
}
