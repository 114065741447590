import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";

import { SimpleOption } from "./simple-option";

export interface IOption {
  isSelected: boolean;
  value: string | number;
}

@Component({
  selector: 'ui-simple-multi-select',
  templateUrl: './ui-simple-multi-select.component.html',
  styleUrls: [ './ui-simple-multi-select.component.scss' ],
})
export class UiSimpleMultiSelectComponent implements OnInit {
  @Input() public selectableValues: { label: string; value: any }[];

  @Input() public title: string;

  @Output() public onOptionSelect: EventEmitter<any[]> = new EventEmitter();

  private selectedActions: SimpleOption[] = [];

  public optionList: SimpleOption[] = [];

  public showOptions = false;

  public constructor(private readonly ref: ElementRef) {}

  @HostListener('document:click', [ '$event' ])
  clickout(event: any) {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.showOptions = false;
    }
  }

  ngOnInit(): void {
    this.optionList = this.selectableValues?.map((value) => new SimpleOption(value));
  }

  public toggle() {
    this.showOptions = !this.showOptions;
  }

  public checkOption(option: SimpleOption): void {
    if (!option.isSelected) {
      option.checkOption();
      this.selectedActions.push(option);
    } else {
      option.checkOption();
      this.selectedActions.splice(
        this.selectedActions.findIndex((value) => value.value === option.value),
        1
      );
    }

    this.onOptionSelect.emit(this.selectedActions.map((selectedOption) => selectedOption.value));
  }
}
