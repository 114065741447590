import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'select-with-label-value',
  templateUrl: './select-with-label-value.component.html',
})
export class SelectWithLabelValueComponent {
  @Input() label: string;

  @Input() description: string;

  @Input() name: string;

  @Input() required: boolean;

  @Input() controlName: string;

  @Input() group: UntypedFormGroup;

  @Input() values?: { value: string; label: string }[];

  @Output() onSelect = new EventEmitter<{ controlName: string; value: string }>();

  public get optionsLabel(): string[] {
    return this.values.map((option) => option.label);
  }

  public emitSelection(event: string): void {
    this.onSelect.emit({ controlName: this.controlName, value: this.getValuefromLabel(event) });
  }

  private getValuefromLabel(label: string): string {
    return this.values.find((option) => option.label === label).value;
  }
}
