<div
  *ngIf="(componentData$ | async) && !isSpecialDrawer"
  class="full-height"
>

  <div
    class="drawer--top"
    *ngIf="!(componentData$ | async).data.emptyHeader"
  >
    <div
      *ngIf="(componentData$ | async).data.title; else defaultTitle"
      class="drawer--title"
    >
      {{ (componentData$ | async).data.title }}
    </div>

    <ng-template #defaultTitle>
      <div class="drawer--title">
        {{ (componentData$ | async).component | t }}
      </div>
    </ng-template>

    <div
      *ngIf="(componentData$ | async).data.number"
      class="drawer--number"
    >
      #{{ (componentData$ | async).data.number }}
    </div>
    <div class="drawer--buttons">
      <ng-container *ngIf="(componentData$ | async).data.showListNavigationButtons">
        <button
          ui-button
          class="drawer--buttons--previous"
          color="drawerCloseFlat"
          icon="left"
          icon-size="medium"
          [title]="'drawer.buttons.previous' | t"
          (click)="emitListNavigationPrevious()"
        >
        </button>
        <button
          ui-button
          class="drawer--buttons--next"
          color="drawerCloseFlat"
          icon="right"
          icon-size="medium"
          [title]="'drawer.buttons.next' | t"
          (click)="emitListNavigationNext()"
        >
        </button>
      </ng-container>
      <button
        *ngIf="!mobileService.isMobile()"
        [title]="'drawer.toggleFullWidth.tooltip' | t"
        class="drawer--buttons--toggleFullWidth"
        ui-button
        color="drawerCloseFlat"
        [icon]="isFullWidth ? 'unExpand' : 'expand'"
        icon-size="medium"
        (click)="toggleFullWidth()"
      ></button>
      <div *ngIf="!(isEditing$ | async)">
        <button
          [title]="'drawer.close.tooltip' | t"
          class="drawer--buttons--close"
          ui-button
          color="drawerCloseFlat"
          icon="close"
          icon-size="medium"
          (click)="close()"
        ></button>
      </div>
    </div>
  </div>

  <div class="full-height">
    <dynamic-component
      *ngIf="(componentData$ | async).component"
      [dynamicComponent]="dynamicComponent"
    ></dynamic-component>
  </div>
</div>

<div
  *ngIf="(componentData$ | async) && isSpecialDrawer"
  class="full-height"
>
  <dynamic-component
    *ngIf="(componentData$ | async).component"
    [dynamicComponent]="dynamicComponent"
  ></dynamic-component>
</div>
