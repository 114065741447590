import {
  OnInit,
  OnChanges,
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  HostBinding
} from '@angular/core';

import { CdkTable } from '@angular/cdk/table';
import { prune } from '../../services/utils';

const name = 'ui-table';

export enum UiTableDirection {
  Asc = 'asc',
  Desc = 'desc',
  None = 'none'
}

export interface UiTableSort {
  name: string | null;
  direction: UiTableDirection;
}

@Component({
  selector: 'table[ui-table]',
  templateUrl: './ui-table.component.html',
  styleUrls: [ './ui-table.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ { provide: CdkTable } ],
  host: {
    class: 'ui-table',
  },
})
export class UiTable<T> extends CdkTable<T> implements OnInit, OnChanges {
  @Input() public class: string;

  @HostBinding('class') public classes = name;

  @HostBinding('attr.width') public width = '100%';

  @Input() public hoverable = true;

  @Input() public sortBy: string = null;

  @Input() public direction: UiTableDirection = UiTableDirection.None;

  @Input() public handleSort: (sort: UiTableSort) => void;

  protected stickyCssClass = 'ui-table--sticky';

  static ngAcceptInputType_multiTemplateDataRows: boolean | string | null | undefined;

  public ngOnInit(): void {
    this.setup();
    super.ngOnInit();
  }

  public ngOnChanges(): void {
    this.setup();
  }

  public setup(): void {
    const classes = {
      [name]: true,
      [`${name}_hoverable`]: this.hoverable,
    };

    if (this.class) {
      classes[this.class] = true;
    }

    this.classes = Object.keys(prune(classes)).join(' ');
  }
}
