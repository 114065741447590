/* eslint-disable no-use-before-define */
import { Directive, Input, OnInit } from '@angular/core';
import { CdkColumnDef } from '@angular/cdk/table';

@Directive({
  selector: '[uiColumnDef]',
  providers: [
    { provide: CdkColumnDef, useExisting: UiColumnDef },
    { provide: 'UI_SORT_HEADER_COLUMN_DEF', useExisting: UiColumnDef },
  ],
})
export class UiColumnDef extends CdkColumnDef implements OnInit {
  @Input('uiColumnDef') columnName: string;

  static ngAcceptInputType_sticky: boolean | string | null | undefined;

  static ngAcceptInputType_stickyEnd: boolean | string | null | undefined;

  ngOnInit(): void {
    this.name = this.columnName;
  }
}
