<sg-expendable-card
  [preventCollapse]="preventCollapse && isExpanded"
  [isOpen]="isExpanded"
  [stickyColor]="stickyColor"
  (toggleOpenEvent)="toggleExpand($event)"
>
  <div
    header
    class="card-title"
  >
    <div class="title-icon-container">
      <img
        class="title-icon"
        alt="Calendar"
        [src]="titleIconPath"
        [style.height]="iconSize"
      >
    </div>
    <ng-container>
      <span
        *ngIf="!useEditableTitle || !isEditing"
        class="title-text"
      >{{title}}</span>
      <span
        *ngIf="useEditableTitle && isEditing"
        class="title-text editing-mode"
      >
        <input
          ui-input
          name="titleInput"
          type="text"
          [placeholder]="'escalation-expand-card.title.placeholder' | t"
          [ngModel]="title"
          (ngModelChange)="handleTitleChanged($event)"
        >
      </span>
    </ng-container>
    <div
      class="header-extra-icons"
      *ngIf="extraHeaderIcons"
    >
      <img
        *ngFor="let icon of extraHeaderIcons"
        [style.height]="iconSize"
        [src]="icon.path"
        [tooltip]="icon.tooltipText"
        alt=""
      >
    </div>
  </div>

  <div
    options
    class="card-actions-buttons"
  >
    <ng-container *ngIf="showActionButtons">

      <!-- Action Buttons are within a menu (triple-dots icon) -->
      <ng-container *ngIf="useMenuButton; else noMenuTemplate;">
        <ng-container *ngIf="!isEditing">
          <ng-container *ngTemplateOutlet="optionsMenuTemplate; context: {showOptionsMenu: showOptionsMenu}"></ng-container>
        </ng-container>
        <ng-container *ngIf="isEditing">
          <ng-container *ngTemplateOutlet="editButtonTemplate; context: {isEditing: isEditing}"></ng-container>
        </ng-container>
      </ng-container>

      <!-- Action Buttons are clickable directly on the header without a menu -->
      <ng-template #noMenuTemplate>
        <ng-container *ngIf="useDeleteButton && !isEditing">
          <ng-container *ngTemplateOutlet="deleteButtonTemplate"></ng-container>
        </ng-container>
        <ng-container *ngTemplateOutlet="editButtonTemplate; context: {isEditing: isEditing}"></ng-container>
      </ng-template>

    </ng-container>
  </div>

  <div
    content
    class="card-content"
  >
    <p
      *ngIf="description"
      class="card-description"
    >{{description}}</p>

    <ng-container *ngIf="!isEditing; then templateReadonly; else templateEditing"></ng-container>
  </div>
</sg-expendable-card>



<ng-template
  #editButtonTemplate
  let-isEditing="isEditing"
>
  <edit-button
    *ngIf="!isEditing"
    (clickEvent)="toggleEdit(); $event.stopPropagation()"
  ></edit-button>
  <ui-confirmations-buttons
    *ngIf="isEditing"
    [isConfirmEnabled]="isConfirmButtonEnabled"
    [notEnabledTooltip]="confirmButtonDisabledTooltip"
    (confirmationEvent)="handleEditingConfirmation($event)"
  ></ui-confirmations-buttons>
</ng-template>



<ng-template #deleteButtonTemplate>
  <delete-button (clickEvent)="handleDeleteClicked(); $event.stopPropagation()"></delete-button>
</ng-template>



<ng-template
  #optionsMenuTemplate
  let-showOptionsMenu="showOptionsMenu"
>
  <div class="options-menu-container">
    <button
      #menuButton
      class="options-menu-button"
      ui-button
      icon="ico_dots"
      [isSvg]="true"
      [custom-icon]="true"
      (click)="toggleOptionsMenu()"
    ></button>

    <div
      #popupMenu
      *ngIf="showOptionsMenu"
      class="options-menu-popup"
      (click)="toggleOptionsMenu()"
    >
      <div
        *ngFor="let option of menuOptions; trackBy:trackByMenuOptionId"
        class="options-menu-popup-option"
        (click)="handleMenuOptionClicked(option)"
      >
        <img [src]="option.iconPath" />
        <span>{{ option.translationKey | t }}</span>
      </div>
    </div>

    <ui-click-out-handler
      *ngIf="showOptionsMenu"
      (clickEvent)="toggleOptionsMenu()"
    ></ui-click-out-handler>
  </div>
</ng-template>